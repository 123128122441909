import Delegate from '../../../components/governance/delegate';
import { useContract } from '../../../contexts/contract/contract-context';

const DelegatePage = () => {
  const { contract, delegatedToAddress } = useContract();

  return <Delegate contract={{ ...contract, delegatedToAddress }} />;
};

export default DelegatePage;
