import {
  convertBaseUnitsToDecimal,
  FEATURE,
  formatValue,
  numberToPercent,
  toNumber,
  unixToLocalDateTime,
  useAccount,
} from '@tokensoft-web/common-utils';
import { useDistributor } from '../../contexts/distributor/distributor-context';
import {
  getDistributorInterfaces,
  isContinuousVestingType,
  isTrancheVestingType,
} from '../../utils/abi';

const ClaimVestingDetails = () => {
  const { event, distributor } = useDistributor();
  const { account } = useAccount();
  const { continuousVesting, trancheVesting } = distributor;

  const hideVestingScheduleOverride = !!event?.features?.includes(
    FEATURE.CLAIM_HIDE_VESTING_SCHEDULE,
  );
  const interfaces = getDistributorInterfaces(distributor);
  const showVestingSchedule =
    !hideVestingScheduleOverride &&
    (isContinuousVestingType(interfaces) || isTrancheVestingType(interfaces));
  const totalAllocations = toNumber(distributor.proofAmount || '0');

  if (!showVestingSchedule) {
    return null;
  }

  const renderContinuous = () => {
    return (
      <div className='pl-6'>
        <div>
          Vesting Starts:{' '}
          {unixToLocalDateTime(continuousVesting.start, account?.timezone)}
        </div>
        <div>
          Claims Open:{' '}
          {unixToLocalDateTime(continuousVesting.cliff, account?.timezone)}
        </div>
        <div>
          Vesting Ends:{' '}
          {unixToLocalDateTime(continuousVesting.end, account?.timezone)}
        </div>
      </div>
    );
  };

  const renderTranche = () => {
    const tranches = trancheVesting?.tranches;

    const sortedRecords = [...tranches].sort(
      (d1, d2) =>
        new Date(unixToLocalDateTime(d1?.time, account?.timezone)).getTime() -
        new Date(unixToLocalDateTime(d2?.time, account?.timezone)).getTime(),
    );

    return (
      <div className='pl-6'>
        {distributor.vestingDescription && (
          <p>{distributor.vestingDescription}</p>
        )}
        <p className='mt-2 mb-2 font-bold'>Unlock Schedule:</p>
        {/* @ts-ignore */}
        {tranches &&
          sortedRecords.map((tranche, i) => {
            const percent = numberToPercent(tranche.vestedFraction);
            const allocation = (percent * totalAllocations) / 100;
            const tokens = formatValue(
              convertBaseUnitsToDecimal(allocation, distributor.tokenDecimals),
              { commas: true },
            );
            return (
              <div className='leading-6 flex flex-row justify-between' key={i}>
                <div>
                  {unixToLocalDateTime(tranche.time, account?.timezone)}
                </div>
                <div>
                  {tokens} {distributor.tokenSymbol} ({percent}%)
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <>
      <span className='font-bold text-lg'>Vesting Schedule</span>
      <div className='py-4'>
        {isTrancheVestingType(interfaces)
          ? renderTranche()
          : renderContinuous()}
      </div>
    </>
  );
};

export default ClaimVestingDetails;
