import { EVENT_TYPE } from '@tokensoft-web/common-utils';
import { CreateEventPayload } from '../../../@types/event';
import { DOCUMENT_CATEGORY } from '../../../utils/enums';
import './event-create-document.css';
import { LaunchEventWizard } from './launch-event-wizard';

export const LaunchIdentityEventWizard = () => {
  const eventType = EVENT_TYPE.IDENTITY;
  const documentCategory = DOCUMENT_CATEGORY.COMPLIANCE_DOCUMENTS;

  /**
   * Assign the registration start/end to the actual event start/end
   * Because identity events only consist of registration, the start/end
   * times are the same.
   */
  function addIdentitySpecificFields(
    payload: CreateEventPayload,
  ): CreateEventPayload {
    payload.startTime = payload.registrationStartTime;
    payload.endTime = payload.registrationEndTime;

    return payload;
  }

  return (
    <LaunchEventWizard
      eventType={eventType}
      documentCategory={documentCategory}
      adjustEventCreationFields={addIdentitySpecificFields}
    />
  );
};
