import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDistributorApiClient } from '../client/distributor-api-client';

export const useGetDelegates = () => {
  const client = useDistributorApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`delegates/search`, {
        method: 'post',
        data,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['delegates'] }),
  });
};

export const useFindDelegate = () => {
  const client = useDistributorApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`delegates/find`, {
        method: 'post',
        data,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['delegates'] }),
  });
};
