import { PageHeader, Stacked } from '@tokensoft-web/common-ui';
import { EVENT_TYPE } from '@tokensoft-web/common-utils';
import { useNavigate } from 'react-router-dom';
import { EventDetails } from '../../../components/admin/event/event-details';
import { EventDistributorSummary } from '../../../components/admin/event/event-distributor-summary';
import { EventSaleSummary } from '../../../components/admin/event/event-sale-summary';
import { EventUsersContainer } from '../../../components/admin/event/event-users-container';
import { useEvent } from '../../../contexts/event/event-context';
import { useProject } from '../../../contexts/project/project-context';

const EventDashboardPage = () => {
  const { event } = useEvent();
  const { project } = useProject();
  const navigate = useNavigate();

  return (
    <>
      <PageHeader
        title={`Event Dashboard`}
        breadcrumb={
          <>
            <a
              className='cursor-pointer'
              onClick={() => {
                navigate(`/admin/project/${project?.id}`);
              }}
            >
              &lt; {project?.name}
            </a>{' '}
            &lt; Event Dashboard
          </>
        }
      />

      <Stacked>
        <EventDetails />

        {event.type === EVENT_TYPE.DISTRIBUTE ? (
          <EventDistributorSummary event={event} />
        ) : null}

        {[EVENT_TYPE.RAISE, EVENT_TYPE.SALE].includes(event.type) ? (
          <EventSaleSummary event={event} />
        ) : null}

        <EventUsersContainer />
      </Stacked>
    </>
  );
};

export default EventDashboardPage;
