import { CreateDocument } from '@tokensoft-web/common-utils';
import { createContext, useContext } from 'react';

interface DocumentUploadContextProps {
  isUSRestricted: boolean;
  documentData: CreateDocument | null;
  setDocumentData: Function;
  handleOnSave: Function;
  handleDeleteSection: Function;
  hideStepFooter: boolean;
  eventType: string;
}

export const DocumentUploadContext =
  createContext<DocumentUploadContextProps>(undefined);
DocumentUploadContext.displayName = 'DocumentUploadContext';

export const useDocumentUpload = (): DocumentUploadContextProps => {
  return useContext(DocumentUploadContext);
};
