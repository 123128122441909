import { Col } from '@tokensoft-web/common-ui';
import { TIER_ID, useAuth } from '@tokensoft-web/common-utils';
import { useEffect } from 'react';
import ClaimIneligible from '../../components/claims/claim-ineligible';
import EventBodyWrapper from '../../components/event/event-body-wrapper';
import EventContent from '../../components/event/event-content';
import EventHeader from '../../components/event/event-header';
import EventHeaderWrapper from '../../components/event/event-header-wrapper';
import KycRestriction from '../../components/feature/kyc-restriction';
import { useSale } from '../../contexts/sale/sale-context';
import { PAYMENT_STEP } from '../../utils/enums';
import FairQueueStep from './fair-queue-step';
import PurchaseConfirmStep from './purchase-confirm-step';
import PurchaseStep from './purchase-step';
import ReceiptStep from './receipt-step';
import TermsStep from './terms-step';

const CurrentSale = () => {
  const { sale, step, setStep, documentsToAcceptOrSign } = useSale();
  const {
    user: { walletAddress },
  } = useAuth();

  const event = sale?.event;
  const description = event?.contentBodyOpen;
  const contentHeaderTitle = event?.contentHeaderTitle;
  const contentHeaderSubtitle = event?.contentHeaderSubtitle;
  const contentHeaderImage = event?.contentHeaderImage;
  const contentHeaderFontColor = event?.contentHeaderFontColor;
  const contentHeaderBgColor = event?.contentHeaderBgColor;
  const contentHeaderBgImage = event?.contentHeaderBgImage;
  const themeLayoutVersion = event?.themeLayoutVersion;

  useEffect(() => {
    if (walletAddress) {
      setStep(PAYMENT_STEP.TERMS);
    }
  }, [walletAddress]);

  const renderSaleDetail = (step: PAYMENT_STEP) => {
    switch (step) {
      case PAYMENT_STEP.FAIR_QUEUE:
        return <FairQueueStep />;
      case PAYMENT_STEP.PURCHASE:
        return <PurchaseStep />;
      case PAYMENT_STEP.CONFIRMATION:
        return <PurchaseConfirmStep />;
      case PAYMENT_STEP.RECEIPT:
        return <ReceiptStep />;
      case PAYMENT_STEP.TERMS:
      default:
        return documentsToAcceptOrSign?.length > 0 ? (
          <TermsStep />
        ) : (
          <PurchaseStep />
        );
    }
  };

  if (event?.eventUsers?.length) {
    if (
      event.eventUsers[0].restrictions?.kycRestricted &&
      event.kycRestriction !== TIER_ID.NONE
    ) {
      return (
        <Col className={'py-14 px-8'}>
          <KycRestriction />
        </Col>
      );
    }

    if (event.eventUsers[0].restrictions?.walletAddressRestricted) {
      return (
        <Col className={'py-14 px-8'}>
          <ClaimIneligible />
        </Col>
      );
    }
  }

  return (
    <Col gap={10}>
      <EventHeaderWrapper
        backgroundColor={contentHeaderBgColor}
        backgroundImg={contentHeaderBgImage}
      >
        <EventHeader
          headerTitle={contentHeaderTitle}
          headerSubtitle={contentHeaderSubtitle}
          contentHeaderImage={contentHeaderImage}
          contentHeaderFontColor={contentHeaderFontColor}
          themeLayoutVersion={themeLayoutVersion}
        />
      </EventHeaderWrapper>

      <EventBodyWrapper>
        <EventContent bodyContent={description} />

        {renderSaleDetail(step)}
      </EventBodyWrapper>
    </Col>
  );
};

export default CurrentSale;
