import classNames from 'classnames';

/* Coerce tailwind into including classnames are derived dynamically in this component */
export const ALLOWED_CLASSES = {
  flex: ['flex-1', 'flex-auto', 'flex-none'],
  textAlign: [
    'text-left',
    'text-center',
    'text-right',
    'text-start',
    'text-end',
    'text-justify',
  ],
  justify: [
    'justify-normal',
    'justify-start',
    'justify-end',
    'justify-center',
    'justify-between',
    'justify-around',
    'justify-evenly',
    'justify-stretch',
  ],
  items: [
    'items-start',
    'items-end',
    'items-center',
    'items-baseline',
    'items-stretch',
  ],
  width: [
    'w-0',
    'w-auto',
    'w-1/2',
    'w-1/3',
    'w-2/3',
    'w-1/4',
    'w-2/4',
    'w-3/4',
    'w-1/5',
    'w-2/5',
    'w-3/5',
    'w-4/5',
    'w-full',
    'w-screen',
    'w-min',
    'w-max',
    'w-fit',
  ],
  gap: [
    'gap-0',
    'gap-x-0',
    'gap-y-0',
    'gap-2',
    'gap-x-2',
    'gap-y-2',
    'gap-2.5',
    'gap-x-2.5',
    'gap-y-2.5',
    'gap-5',
    'gap-x-5',
    'gap-y-5',
    'gap-10',
    'gap-x-10',
    'gap-y-10',
    'gap-12',
    'gap-x-12',
    'gap-y-12',
  ],
};

export const Flex = ({
  id = '',
  className = '',
  direction = 'col',
  textAlign = null,
  gap = 0,
  xgap = null,
  ygap = null,
  nowrap = null,
  wrap = true,
  width = 'full',
  place = null,
  xalign = null,
  yalign = null,
  onClick = () => {},
  children,
  ...restProps
}) => {
  let appliedPlace = place;
  let appliedXAlign = xalign;
  let appliedYAlign = yalign;
  let appliedGap = gap;

  if (xgap !== null || ygap !== null) {
    appliedGap = null;
  }

  // support for place values like between-center so we can
  // control justify and align all in one
  const placeTokens = place?.split('-');
  if (placeTokens?.length > 1) {
    appliedXAlign = placeTokens[0];
    appliedYAlign = placeTokens[1];
    appliedPlace = null;
  }

  return (
    <div
      id={id}
      onClick={onClick}
      className={classNames(
        'flex',
        width ? `w-${width}` : '',
        direction === 'col' ? `flex-col` : '',
        direction === 'row' ? `flex-row` : '',
        appliedGap !== null ? `gap-${appliedGap}` : '',
        xgap !== null ? `gap-x-${xgap}` : '',
        ygap !== null ? `gap-y-${ygap}` : '',
        appliedXAlign && direction === 'row' ? `justify-${appliedXAlign}` : '',
        appliedXAlign && direction === 'col' ? `items-${appliedXAlign}` : '',
        appliedYAlign && direction === 'row' ? `items-${appliedYAlign}` : '',
        appliedYAlign && direction === 'col' ? `justify-${appliedYAlign}` : '',
        wrap && !nowrap ? 'flex-wrap' : '',
        nowrap ? 'flex-nowrap' : '',
        textAlign ? `text-${textAlign}` : '',
        appliedPlace ? `items-${appliedPlace} justify-${appliedPlace}` : '',
        className,
      )}
      {...restProps}
    >
      {children}
    </div>
  );
};
