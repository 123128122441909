import classNames from 'classnames';
import { useWizard } from 'react-use-wizard';
import { Button } from '../button/button';
import { Text } from '../typography/text';

export const BackButton = ({
  disabled = false,
  label = 'Back',
  icon = <></>,
  className = '',
  onClick,
  ...restProps
}) => {
  const { previousStep, isLoading } = useWizard();

  return (
    <Button
      fill={'outline'}
      className={classNames('md:w-36 w-full', className)}
      onClick={(e) => (onClick ? onClick(e) : previousStep())}
      disabled={disabled || isLoading}
      {...restProps}
    >
      {icon}
      <Text>{label}</Text>
    </Button>
  );
};
