import { Card, FileUpload, UploadedDoc } from '@tokensoft-web/common-ui';
import { ENTITY_TYPE, QUALIFICATION_METHOD } from '@tokensoft-web/common-utils';

export enum DOCUMENT_UPLOAD_TYPE {
  ACCREDITATION = 'Accreditation',
  ENTITY = 'Entity',
}

const renderDocUploadText = (
  type: DOCUMENT_UPLOAD_TYPE,
  {
    qualificationMethod,
    entityType,
  }: {
    qualificationMethod: QUALIFICATION_METHOD;
    entityType: ENTITY_TYPE;
  },
) => {
  if (!type) {
    return <p className='pb-2'>Please upload any relevant documents</p>;
  }

  switch (type) {
    case DOCUMENT_UPLOAD_TYPE.ACCREDITATION:
      switch (qualificationMethod) {
        case QUALIFICATION_METHOD.LETTER:
          return (
            <>
              <p className='pb-2'>
                Please upload a signed letter from a registered broker-dealer,
                an SEC-registered investment adviser, a licensed attorney, or a
                certified public accountant certifying you as an accredited
                investor
              </p>
              <p className='pb-2'>
                The letter <strong>cannot be older than 90 days</strong> from
                the date of submission
              </p>
            </>
          );
        case QUALIFICATION_METHOD.INCOME:
        default:
          return (
            <>
              <p className='pb-2'>Please upload relevant documents showing:</p>
              <p className='pl-4'>
                1. Your gross income exceeded $200,000 (individually) or
                $300,000 (with a spouse/partner) for the past two years, and
              </p>
              <p className='pl-4 pb-2'>
                2. You reasonably expect to have the same income level in the
                current year
              </p>
              <p className='pb-2'>
                Suggested documents: W2, 1099, K-1 or equivalent for the past
                two years, or certified tax returns. The documents{' '}
                <strong>cannot be older than 90 days</strong> from date of
                submission, with some exceptions (e.g., documents proving
                ownership, tax returns, etc.)
              </p>
            </>
          );

        case QUALIFICATION_METHOD.NET_WORTH:
          return (
            <>
              <p className='pb-2'>
                Please upload relevant documents showing your net worth
                excluding your primary residence exceeds $1,000,000
                (individually or with spouse/partner)
              </p>
              <p className='pb-2'>
                Suggested documents: bank, brokerage, or other security holdings
                statements, certificates of deposit, tax assessments, and
                appraisal reports issued by third parties. The documents{' '}
                <strong>cannot be older than 90 days</strong> from date of
                submission, with some exceptions (e.g., documents proving
                ownership, tax returns, etc.)
              </p>
            </>
          );

        case QUALIFICATION_METHOD.PROFESSIONAL:
          return (
            <>
              <p className='pb-2'>
                Please upload relevant documents showing you are an investment
                professional in good standing holding a Series 7, Series 65
                and/or Series 82 license
              </p>
              <p className='pb-2'>
                Suggested documents: licensure documentation. The documents{' '}
                <strong>cannot be older than 90 days</strong> from date of
                submission, with some exceptions (e.g., documents proving
                ownership, tax returns, etc.)
              </p>
            </>
          );

        case QUALIFICATION_METHOD.ENTITY_NET_WORTH:
          return (
            <>
              <p className='pb-2'>
                Please upload relevant documents showing you are an entity with
                total assets or investments in excess of $5,000,000 and have not
                been formed for the purpose of acquiring token interests
              </p>
              <p className='pb-2'>
                Suggested documents: bank statements, balance sheet, and
                articles of organization/operating agreement. The documents{' '}
                <strong>cannot be older than 90 days</strong> from date of
                submission, with some exceptions (e.g., documents proving
                ownership, tax returns, etc.)
              </p>
            </>
          );

        case QUALIFICATION_METHOD.TRUST_NET_WORTH:
          return (
            <>
              <p className='pb-2'>
                Please upload relevant documents showing you are a trust (other
                than a business trust) with total assets in excess of $5,000,000
                which was not formed for the purpose of acquiring the Token
                Interests
              </p>
              <p className='pb-2'>
                Suggested documents: bank statements, balance sheet, articles of
                organization, operating agreement, and accredited investor
                documentation for the knowledgeable individual (or other
                documentation proving qualified decision-maker status). The
                documents <strong>cannot be older than 90 days</strong> from
                date of submission, with some exceptions (e.g., documents
                proving ownership, tax returns, etc.)
              </p>
            </>
          );

        case QUALIFICATION_METHOD.FAMILY_CLIENT:
          return (
            <>
              <p className='pb-2'>
                Please upload relevant documents showing you are a family client
                whose investments are directed by a Qualifying Family Office
              </p>
              <p className='pb-2'>
                Suggested documents: certification letter from licensed
                broker-dealer, attorney, CPA, or registered investment advisor
                attesting to entity's accreditation status, or evidence of
                investment holdings via third party statements, balance sheet,
                and financial statements. The documents{' '}
                <strong>cannot be older than 90 days</strong> from date of
                submission, with some exceptions (e.g., documents proving
                ownership, tax returns, etc.)
              </p>
            </>
          );

        case QUALIFICATION_METHOD.FAMILY_OFFICE:
          return (
            <>
              <p className='pb-2'>
                Please upload relevant documents showing you are a family office
                and you were not formed for the specific purpose of acquiring
                the securities offered
              </p>
              <p className='pb-2'>
                Suggested documents: certification letter from licensed
                broker-dealer, attorney, CPA, or registered investment advisor
                attesting to entity's accreditation status, or evidence of
                investment holdings via third party statements, balance sheet,
                and financial statements. The documents{' '}
                <strong>cannot be older than 90 days</strong> from date of
                submission, with some exceptions (e.g., documents proving
                ownership, tax returns, etc.)
              </p>
            </>
          );

        case QUALIFICATION_METHOD.ACCREDITED_OWNERS:
          return (
            <>
              <p className='pb-2'>
                Please upload relevant documents showing each owner is an
                accredited investor
              </p>
              <p className='pb-2'>
                Suggested documents: accredited investor verification documents
                for each owner (certification letter or appropriate credit
                reports, third party statements, etc.), and an official list of
                current owners. The documents{' '}
                <strong>cannot be older than 90 days</strong> from date of
                submission, with some exceptions (e.g., documents proving
                ownership, tax returns, etc.)
              </p>
            </>
          );
      }
    case DOCUMENT_UPLOAD_TYPE.ENTITY:
      switch (entityType) {
        case ENTITY_TYPE.COMPANY:
          return (
            <>
              <p className='pb-2'>
                Please upload certified copies of the following:
              </p>
              <ul className='list-disc ml-4 pb-2'>
                <li>Certificate of Incorporation</li>
                <li>Memorandum and Articles of Association (or equivalent)</li>
                <li>
                  Register of Directors and Register of Shareholders or a
                  current, dated Certificate of Incumbency reflecting this
                  information
                </li>
              </ul>
              <p className='pb-2'>
                Also provide identification documentation for all directors and
                shareholders who hold a 20% or more legal or beneficial interest
              </p>
            </>
          );
        case ENTITY_TYPE.PARTNERSHIP:
        default:
          return (
            <>
              <p className='pb-2'>
                Please upload certified copies of the following:
              </p>
              <ul className='list-disc ml-4 pb-2'>
                <li>Certificate of Registration</li>
                <li>Registry of Partners</li>
                <li>Partnership Agreement</li>
              </ul>
              <p className='pb-2'>
                Also provide identification documentation regarding the general
                partner(s) and confirmation that the general partner(s) has full
                control. Otherwise, due diligence is also required on
                partners/authorized signatories with control
              </p>
            </>
          );

        case ENTITY_TYPE.TRUST:
          return (
            <>
              <p className='pb-2'>
                Please upload a certified copy of the Trust Deed, together with
                any amendments thereto
              </p>
              <p className='pb-2'>
                Also provide identitification documentation for any person
                exercising ultimate effective control over the trust
              </p>
            </>
          );

        case ENTITY_TYPE.NON_PROFIT:
          return (
            <>
              <p className='pb-2'>
                Please upload certified copies of the following:
              </p>
              <ul className='list-disc ml-4 pb-2'>
                <li>Certificate of Incorporation</li>
                <li>Memorandum and Articles of Association (or equivalent)</li>
                <li>
                  Register of Directors and register of Shareholders or a
                  current dated Certificate of Incumbency reflecting this
                  information
                </li>
              </ul>
              <p className='pb-2'>
                Also provide identity documentation for two signatories
              </p>
            </>
          );
      }
  }
};

export const DocumentUploadPanel = ({
  type,
  qualificationMethod,
  entityType,
  onFileChange,
  docsUploaded,
}: {
  type?: DOCUMENT_UPLOAD_TYPE;
  qualificationMethod?: QUALIFICATION_METHOD;
  entityType?: ENTITY_TYPE;
  onFileChange: Function;
  docsUploaded: UploadedDoc[];
}) => {
  return (
    <Card title={`${type || 'Upload'} Documents`}>
      {renderDocUploadText(type, { qualificationMethod, entityType })}
      <FileUpload
        uploadUrl='accounts/documents'
        onFileChange={onFileChange}
        docsUploaded={docsUploaded}
      />
      <p className='text-xs text-neutral-light pt-2'>
        * By providing information, you represent and warrant that it is true,
        complete, and correct.
      </p>
    </Card>
  );
};
