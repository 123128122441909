import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTradingApiClient } from '../client/trading-api-client';
import { useAuth } from '../context/auth/auth-context';

export const useTradeCompleted = () => {
  const client = useTradingApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => {
      return client(`trade/completed`, {
        method: 'post',
        data: data,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['trade'] });
    },
  });
};

export const useTradeFailed = () => {
  const {
    user: { authId },
  } = useAuth();
  const client = useTradingApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return client(`trade/failed`, {
        method: 'post',
        data: { authId },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['trade'] });
    },
  });
};

export const useGetTokenInfo = () => {
  const client = useTradingApiClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(
        `tokens/info?networkId=${data.networkId}&tokenAddress=${data.tokenAddress}`,
        {
          method: 'get',
        },
      );
    },
  });
};
