import {
  ButtonRow,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
} from '@tokensoft-web/common-ui';
import {
  useAcceptEventDocuments,
  useAnalytics,
  useAuth,
  useToast,
} from '@tokensoft-web/common-utils';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EventDocuments from '../../components/event/event-documents';
import { useSale } from '../../contexts/sale/sale-context';
import { PAYMENT_STEP } from '../../utils/enums';

const TermsStep = () => {
  const { showErrorToast } = useToast();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { sale, setStep, documentsToAcceptOrSign, refresh } = useSale();
  useAnalytics(`/sale/${sale.id}/terms`);

  const { mutate: accept, isLoading: acceptLoading } =
    useAcceptEventDocuments();

  const [acceptedDocs, setAcceptedDocs] = useState(false);

  const handleNext = () => {
    const eventDocumentVersions = documentsToAcceptOrSign.map(
      (doc) => doc.eventDocumentVersion,
    );
    accept(
      {
        eventId: sale.event.id,
        documents: eventDocumentVersions,
      },
      {
        onSuccess: () => {
          setStep(PAYMENT_STEP.PURCHASE);
        },
        onError: (error) => showErrorToast({ description: error.message }),
      },
    );
  };

  return (
    <>
      <Card className='mb-8'>
        <CardHeader>
          <CardTitle>Terms & Conditions</CardTitle>
        </CardHeader>

        <CardBody>
          <Col gap={5}>
            <EventDocuments
              documents={documentsToAcceptOrSign}
              setAcceptedDocs={setAcceptedDocs}
              eventType={sale.event?.type}
              requiresSignature={false}
            />
          </Col>
        </CardBody>

        <CardFooter>
          <ButtonRow place={'between'}>
            <button
              onClick={() => navigate('/dashboard')}
              className='btn btn-outline-primary'
            >
              Cancel
            </button>
            <button
              onClick={() => handleNext()}
              disabled={!acceptedDocs || acceptLoading || !isAuthenticated}
              className='btn btn-primary'
            >
              Next
            </button>
          </ButtonRow>
        </CardFooter>
      </Card>
    </>
  );
};

export default TermsStep;
