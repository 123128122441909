import {
  ButtonRow,
  LoadingIndicator,
  PageHeader,
  UploadedDoc,
} from '@tokensoft-web/common-ui';
import {
  AccountDetails,
  INVESTOR_TYPE,
  KYC_TIER_EVENT,
  TIER_ID,
  TIER_NAME,
  VERIFICATION_STATUS,
  getKycTier,
  useAccount,
  useAnalytics,
  useAuth,
  useCreateAccountKycTierEvent,
  useSetAccountDetails,
  useSetKyc,
  useUpdateAccountKycTier,
} from '@tokensoft-web/common-utils';
import { useEffect, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { initialAccountDetailsState } from '../../services/account-service';
import { AdditionalInformationPanel } from '../additional-information-panel';
import {
  DOCUMENT_UPLOAD_TYPE,
  DocumentUploadPanel,
} from '../document-upload-panel';
import { BasicInformationPanel } from '../profile/basic-information-panel';
import { EntityInformationPanel } from '../profile/entity-information-panel';
import { EntityTypePanel } from '../profile/entity-type-panel';
import { ReviewPanel } from '../profile/review-panel';
import { SumsubPanel } from '../profile/sumsub-panel';
import { ApplicationTypePanel } from './application-type-panel';

enum PageState {
  FORM = 'form',
  REVIEW = 'review',
  ENTITY_DOCS = 'entity docs',
  SUMSUB = 'sumsub',
}

export const IdentityForm = () => {
  const navigate = useNavigate();
  const {
    user: { authId },
    isAuthenticated,
  } = useAuth();
  const { account, tier1Status, refetch } = useAccount();

  const {
    mutate: saveAccountDetails,
    error: setAccountDetailsError,
    isPending: isLoadingSetAccountDetails,
    isSuccess: isSetAccountDetailsSuccess,
  } = useSetAccountDetails(authId, account?.id);

  const {
    mutate: setKyc,
    isPending: isLoadingSetKyc,
    isSuccess: isSetKycSuccess,
    error: setKycError,
  } = useSetKyc(account?.id);

  const {
    mutate: createAccountKycTierEvent,
    error: createAccountKycTierEventError,
  } = useCreateAccountKycTierEvent();

  const {
    mutate: updateAccountKycTier,
    isPending: updateAccountKycTierLoading,
    isSuccess: isUpdateAccountKycTierSuccess,
  } = useUpdateAccountKycTier();

  const [accountDetails, setAccountDetails] = useState<AccountDetails>(null);
  const [formValid, setFormValid] = useState(false);
  const [entityFormValid, setEntityFormValid] = useState(false);
  const [pageState, setPageState] = useState(PageState.FORM);
  const [docsUploaded, setDocsUploaded] = useState<UploadedDoc[]>([]);
  const [notes, setNotes] = useState('');

  useAnalytics('/account/identity/' + pageState.toLowerCase());

  const documentIds = docsUploaded.map(({ documentId }) => ({
    id: documentId,
  }));
  const { id: accountKycTierId } =
    account?.kyc.tiers.find(
      (tier) => tier.kycTier.id === TIER_ID.IDENTITY_KYC_TIER_ID,
    ) || {};

  const canReview =
    pageState === PageState.FORM &&
    formValid &&
    (accountDetails?.investorType === INVESTOR_TYPE.MYSELF ||
      (accountDetails?.investorType === INVESTOR_TYPE.ENTITY &&
        entityFormValid));

  const canSubmit =
    pageState === PageState.REVIEW &&
    !isLoadingSetAccountDetails &&
    !isLoadingSetKyc;

  const errorMessage =
    setAccountDetailsError || setKycError || createAccountKycTierEventError;

  const submitProfile = () => {
    // 1) Submit account details
    saveAccountDetails(accountDetails);
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/account');
    } else if (account) {
      if (account.idvApplicantId) {
        setPageState(PageState.SUMSUB);
        return;
      }

      // Prefill address form if information already exists on the user
      const existingAccountDetails = initialAccountDetailsState(account);
      if (pageState === PageState.FORM && existingAccountDetails) {
        setAccountDetails(existingAccountDetails);
      }
    }
  }, [account, isAuthenticated]);

  useEffect(() => {
    // 2) Call set kyc after account details has finished submitting
    if (isSetAccountDetailsSuccess) {
      setKyc();
    }
  }, [isSetAccountDetailsSuccess]);

  useEffect(() => {
    // 3) Show documents when both calls have been successful
    if (isSetAccountDetailsSuccess && isSetKycSuccess) {
      account.investorType === INVESTOR_TYPE.ENTITY
        ? setPageState(PageState.ENTITY_DOCS)
        : setPageState(PageState.SUMSUB);
    }
  }, [isSetAccountDetailsSuccess, isSetKycSuccess]);

  useEffect(() => {
    if (isUpdateAccountKycTierSuccess) {
      setPageState(PageState.SUMSUB);
    }
  }, [isUpdateAccountKycTierSuccess]);

  const handleSumsubFailed = () => {
    console.log('Handling failed event');
    refetch();
    navigate('failed');
  };

  const handleSumsubSubmitted = (idvApplicantId) => {
    console.log('Handling submitted event ', idvApplicantId);
    const kycTier = getKycTier(account?.kyc.tiers, TIER_NAME.IDENTITY);
    createAccountKycTierEvent({
      tier: kycTier,
      event: KYC_TIER_EVENT.INITIAL_CHECK,
      idvApplicantId: idvApplicantId,
    });
    refetch();
    navigate('submitted');
  };

  const handleSumsubComplete = () => {
    console.log('Handling completed event', tier1Status);
    refetch();
    if (tier1Status === VERIFICATION_STATUS.PENDING) {
      navigate('pending');
    } else if (tier1Status === VERIFICATION_STATUS.FAILED) {
      navigate('failed');
    } else if (tier1Status === VERIFICATION_STATUS.COMPLETED) {
      navigate('completed');
    }
  };

  const renderPrimaryActions = () => {
    if (pageState === PageState.FORM && !canReview) {
      return (
        <button className='btn btn-primary' disabled={true}>
          Review
        </button>
      );
    } else if (pageState === PageState.FORM && canReview) {
      return (
        <button
          className='btn btn-primary'
          onClick={() => setPageState(PageState.REVIEW)}
        >
          Review
        </button>
      );
    } else if (pageState === PageState.REVIEW && canSubmit) {
      return (
        <button className='btn btn-primary' onClick={submitProfile}>
          Next
        </button>
      );
    } else if (pageState === PageState.REVIEW && !canSubmit) {
      return (
        <button className='btn btn-primary' disabled={true}>
          <div className='flex flex-row justify-center items-center'>
            <div className='animate-spin'>
              <AiOutlineLoading3Quarters size={16} />
            </div>
            <span className='pl-2'>Next</span>
          </div>
        </button>
      );
    } else if (pageState === PageState.ENTITY_DOCS) {
      return (
        <button
          className='btn btn-primary'
          onClick={() => {
            updateAccountKycTier({
              accountKycTierId,
              data: {
                account: {
                  id: account.id,
                  entity_type: accountDetails.entityType,
                },
                message: notes || 'Entity documentation submitted for review',
                documents: documentIds,
              },
            });
          }}
          disabled={updateAccountKycTierLoading || docsUploaded.length === 0}
        >
          {updateAccountKycTierLoading ? (
            <div className='flex flex-row justify-center items-center'>
              <div className='animate-spin'>
                <AiOutlineLoading3Quarters size={16} />
              </div>
              <span className='pl-2'>Submitting...</span>
            </div>
          ) : (
            <span className=''>Submit</span>
          )}
        </button>
      );
    }

    return <></>;
  };

  const renderSecondaryActions = () => {
    if (pageState === PageState.FORM) {
      return (
        <Link className='btn btn-outline-dark' to='/account'>
          Cancel
        </Link>
      );
    } else if (pageState === PageState.REVIEW && accountDetails) {
      return (
        <button
          className='btn btn-outline-dark'
          onClick={() => setPageState(PageState.FORM)}
        >
          Edit Profile
        </button>
      );
    }

    return <></>;
  };

  const renderContent = () => {
    if (pageState === PageState.FORM) {
      return (
        <>
          <BasicInformationPanel
            accountDetails={accountDetails}
            onChange={(a: AccountDetails) =>
              setAccountDetails({ ...accountDetails, ...a })
            }
            onValidChange={(v: boolean) => setFormValid(v)}
          />
          <ApplicationTypePanel
            accountDetails={accountDetails}
            onChange={(a: AccountDetails) => {
              setAccountDetails({ ...accountDetails, ...a });
            }}
          />
          {accountDetails?.investorType === INVESTOR_TYPE.ENTITY && (
            <>
              <EntityInformationPanel
                accountDetails={accountDetails}
                onChange={(a: AccountDetails) =>
                  setAccountDetails({ ...accountDetails, ...a })
                }
                onValidChange={(v: boolean) => setEntityFormValid(v)}
              />
            </>
          )}
        </>
      );
    } else if (pageState === PageState.REVIEW && accountDetails) {
      return (
        <>
          <ReviewPanel accountDetails={{ ...accountDetails }}></ReviewPanel>
        </>
      );
    } else if (pageState === PageState.SUMSUB) {
      return (
        <>
          <SumsubPanel
            user={account}
            onComplete={() => handleSumsubComplete()}
            onFailed={() => handleSumsubFailed()}
            onSubmitted={(idvApplicantId) =>
              handleSumsubSubmitted(idvApplicantId)
            }
          />
        </>
      );
    } else if (pageState === PageState.ENTITY_DOCS) {
      return (
        <>
          <EntityTypePanel
            accountDetails={accountDetails}
            onChange={(a: AccountDetails) =>
              setAccountDetails({ ...accountDetails, ...a })
            }
          />
          <DocumentUploadPanel
            type={DOCUMENT_UPLOAD_TYPE.ENTITY}
            entityType={accountDetails.entityType}
            onFileChange={setDocsUploaded}
            docsUploaded={docsUploaded}
          />
          <AdditionalInformationPanel
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </>
      );
    }

    return <></>;
  };

  return !account ? (
    <LoadingIndicator />
  ) : (
    <>
      <PageHeader title={'Identity Verification'} />

      <div data-test-id='profile' className='grid gap-6 grid-cols-1'>
        {renderContent()}

        {errorMessage && (
          <div className='pb-3'>
            <p className='text-danger-medium text-center'>
              {/* @ts-ignore */}
              {errorMessage.message}
            </p>
          </div>
        )}

        <ButtonRow place={'between'}>
          <div className='px-3 pb-2 md:pb-0'>{renderSecondaryActions()}</div>
          <div className='px-3 pb-2 md:pb-0'>{renderPrimaryActions()}</div>
        </ButtonRow>
      </div>
    </>
  );
};
