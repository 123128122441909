import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  EditIcon,
  Row,
  Section,
  Stacked,
  Stretched,
  TableData,
  TableHeader,
  Text,
  TextLink,
} from '@tokensoft-web/common-ui';
import {
  formatValue,
  getIPFSLink,
  useGetSaleParticipantsSummary,
} from '@tokensoft-web/common-utils';
import { useState } from 'react';
import { VscLinkExternal } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';

export const SaleParticipatesSummary = ({ context, className = '' }) => {
  const navigate = useNavigate();
  const { data: saleParticipantsData } = useGetSaleParticipantsSummary(
    context?.event.id,
  );
  const [isOpen, setIsOpen] = useState(!Object.keys(context).includes('id'));

  const key = 'sale-participates-summary';

  const formattedTotalParticipants = saleParticipantsData?.totalParticipants
    ? formatValue(saleParticipantsData?.totalParticipants, { commas: true })
    : '-';

  return (
    <Card className={className} id={`${key}-card`}>
      <CardHeader place={'center'}>
        <CardTitle
          open={isOpen}
          onOpen={context.id ? setIsOpen : null}
          data-testid={`${key}-title`}
        >
          Participants
        </CardTitle>
        {context.id ? (
          <TextLink
            width={'w-fit'}
            onClick={() => {
              navigate(
                `/admin/project/${context.event.project.id}/event/${context.event.id}/sale/participants`,
              );
            }}
          >
            <EditIcon />
            <Text>Edit Details</Text>
          </TextLink>
        ) : null}
      </CardHeader>

      <CardBody open={isOpen}>
        <Stacked>
          {context.id && saleParticipantsData ? (
            <Section gap={5} place={'start'}>
              <Stretched gap={5}>
                <Stretched ygap={5}>
                  <Col gap={2.5} className={'w-full md:w-1/2'}>
                    <TableHeader>Total Participants</TableHeader>
                    {saleParticipantsData?.totalParticipants ? (
                      <Row nowrap gap={2} yalign={'center'}>
                        <TableData data-testid={`${key}-participants-data`}>
                          {formattedTotalParticipants} Addresses
                        </TableData>
                        <a
                          href={getIPFSLink(saleParticipantsData?.uri)}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <VscLinkExternal size={14} color={'var(--primary)'} />
                        </a>
                      </Row>
                    ) : (
                      <TableData data-testid={`${key}-participants-data`}>
                        {'-'}
                      </TableData>
                    )}
                  </Col>
                </Stretched>

                <Stretched>
                  <Col gap={2.5} width={'1/2'} className={'hidden md:block'}>
                    <TableHeader>&nbsp;</TableHeader>
                    <TableData>&nbsp;</TableData>
                  </Col>

                  <Col gap={2.5} width={'1/2'} className={'hidden md:block'}>
                    <TableHeader>&nbsp;</TableHeader>
                    <TableData>&nbsp;</TableData>
                  </Col>
                </Stretched>
              </Stretched>
            </Section>
          ) : null}
        </Stacked>
      </CardBody>
    </Card>
  );
};
