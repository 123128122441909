import { BackButton, SaveDiskIcon } from '@tokensoft-web/common-ui';
import { useToast } from '@tokensoft-web/common-utils';
import { useWizard } from 'react-use-wizard';
import { useDocumentUpload } from '../../../../contexts/event/document-upload-context';
import {
  validateDocumentSignatureInfo,
  validateDocumentUploadinfo,
} from '../../../../utils/form-validation';

const DocumentUploadSaveButton = ({}) => {
  const { previousStep } = useWizard();
  const { documentData, handleOnSave } = useDocumentUpload();
  const { eventType } = documentData;

  const documentUploadInfoValid = validateDocumentUploadinfo(documentData);
  const documentSignatureInfoValid = documentData.signatureRequired
    ? validateDocumentSignatureInfo(eventType, documentData)
    : true;

  const { showErrorToast } = useToast();

  const handleOnClick = () => {
    if (!documentUploadInfoValid) {
      showErrorToast({
        description: 'Please fill out the required document information.',
      });
      return;
    }

    if (!documentSignatureInfoValid) {
      showErrorToast({
        description: 'Please fill out the required signature information.',
      });
      return;
    }

    handleOnSave();
    previousStep();
  };

  return (
    <BackButton
      icon={<SaveDiskIcon fill={'currentColor'} size={14} />}
      label={'Save'}
      onClick={handleOnClick}
    />
  );
};

export default DocumentUploadSaveButton;
