import { useToast } from '../context/toast/toast-context';

export const useClipboard = () => {
  const { showSuccessToast } = useToast();

  const copyToClipboard = (
    text: string,
    description: string = 'Copied to clipboard',
  ) => {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(text);
      showSuccessToast({ description: description });
    }
  };

  return { copyToClipboard };
};
