import {
  BackButton,
  ButtonRow,
  ContinueButton,
  LoadingIndicator,
  PageSubtitle,
  PageTitle,
  Step,
  StepFooter,
  StepHeader,
  SwitchChainWarning,
} from '@tokensoft-web/common-ui';
import {
  useGetDistributor,
  useNetworks,
  useWallet,
} from '@tokensoft-web/common-utils';
import { useGetContractOwner } from '@tokensoft-web/common-utils/src/service/contract-service';
import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wizard } from 'react-use-wizard';
import DistributorOwner from '../../../components/claims/distributor-owner';
import { useEvent } from '../../../contexts/event/event-context';
import {
  getContinuousVestingMerkleABI,
  getTrancheVestingMerkleABI,
} from '../../../utils/abi';

const WizardContext = createContext(null);

export const EditDistributorOwnerWizard = () => {
  const navigate = useNavigate();
  const { event } = useEvent();
  const { connectedChainId } = useWallet();
  const { getNetworkDetails } = useNetworks();
  const { distributor, loading } = useGetDistributor(event.distributor?.id);
  const [wizardState, setWizardState] = useState<any>({});

  let contractAbi;
  if (distributor) {
    contractAbi = distributor.continuousVesting
      ? getContinuousVestingMerkleABI()
      : getTrancheVestingMerkleABI();
  }

  const contractAddress = distributor?.id;
  const chainId = distributor?.chainId;
  const { owner, isPending } = useGetContractOwner(
    contractAddress,
    chainId,
    contractAbi,
  );

  useEffect(() => {
    if (distributor) {
      setWizardState({
        ...distributor,
        advanced: {
          ...distributor.advanced,
          owner: {
            id: owner,
          },
        },
      });
    }
  }, [distributor, owner]);

  const goBack = () => {
    navigate(-1);
  };

  const handleDone = () => {
    goBack();
  };

  const handleCancel = () => {
    goBack();
  };

  if (!loading && distributor && distributor.chainId !== connectedChainId) {
    const correctNetworkDetails = getNetworkDetails(distributor.chainId);
    return (
      <SwitchChainWarning
        chainId={distributor.chainId}
        subtitle={`Please connect to the ${correctNetworkDetails.name} network to continue.`}
      />
    );
  }

  return (
    <WizardContext.Provider
      value={{
        wizardState,
        setWizardState,
      }}
    >
      <Wizard>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <Step>
            <StepHeader>
              <PageTitle>Owner &amp; Recipient</PageTitle>
              <PageSubtitle>
                The owner of the distributor is always defaulted to the wallet
                address of the admin who set it up. If you would like to change
                it or your sweep recipient you can do that below in two separate
                transactions.
              </PageSubtitle>
            </StepHeader>

            <DistributorOwner
              context={wizardState}
              setContext={setWizardState}
            />

            <StepFooter>
              <ButtonRow place='between'>
                <BackButton label={'Cancel'} onClick={handleCancel} />
                <ContinueButton label={'Done'} onClick={handleDone} />
              </ButtonRow>
            </StepFooter>
          </Step>
        )}
      </Wizard>
    </WizardContext.Provider>
  );
};
