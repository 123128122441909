import classNames from 'classnames';
import { Text } from './text';

export const EventTitle = ({ className = '', children, ...restProps }) => {
  return (
    <Text
      className={classNames('text-6xl font-bold leading-16', className)}
      data-testid='event-banner-title'
      {...restProps}
    >
      {children}
    </Text>
  );
};
