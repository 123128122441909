/* document */
import SumsubWebSdk from '@sumsub/websdk-react';
import { LoadingIndicator, User } from '@tokensoft-web/common-ui';
import {
  useAuth,
  useGenerateAccessToken,
  useGenerateCustomAccessToken,
} from '@tokensoft-web/common-utils';
import React, { FC, useEffect } from 'react';

type SumsubPanelProps = {
  onComplete: Function;
  onFailed: Function;
  onSubmitted: Function;
  user: User;
};
export const SumsubPanel: FC<SumsubPanelProps> = ({
  onComplete,
  onFailed,
  onSubmitted,
  user,
}) => {
  const {
    user: { authId },
  } = useAuth();
  const { mutate: generateSDKToken, data: sdkToken } = useGenerateAccessToken(
    authId,
    user?.id,
  );
  const { mutate: generateCustomSDKToken, data: sdkCustomToken } =
    useGenerateCustomAccessToken(authId, user?.id, `EARNM-KYC-level`);
  const [idvApplicantId, setIdvApplicantId] = React.useState(null);
  const [lastMessageReceived, setLastMessageReceived] = React.useState(null);

  useEffect(() => {
    // Grab temporary access token
    const referrer = `${window?.location?.origin}/*`;
    if (window.location.origin === 'https://earnm.tokensoft.io') {
      generateCustomSDKToken(referrer, `EARNM-KYC-level`);
      return;
    }
    generateSDKToken(referrer);
  }, []);

  const messageHandler = (type, payload) => {
    setLastMessageReceived({ type, payload });

    if (type === 'idCheck.onApplicantLoaded') {
      setIdvApplicantId(payload.applicantId);
    }
  };

  useEffect(() => {
    console.log('calling use effect', { idvApplicantId, lastMessageReceived });
    if (idvApplicantId && lastMessageReceived) {
      if (lastMessageReceived.type === 'idCheck.applicantStatus') {
        if (
          idvApplicantId &&
          lastMessageReceived.payload.reviewStatus === 'completed'
        ) {
          if (
            lastMessageReceived.payload.reviewResult.reviewAnswer === 'GREEN'
          ) {
            console.log('calling onComplete function');
            return onComplete && onComplete();
          } else if (
            lastMessageReceived.payload.reviewResult.reviewAnswer === 'RED' &&
            lastMessageReceived.payload.reviewResult.reviewRejectType ===
              'FINAL'
          ) {
            console.log('calling onFailed function');
            return onFailed && onFailed();
          }
        } else {
          if (
            idvApplicantId &&
            lastMessageReceived.payload.reviewStatus === 'pending'
          ) {
            return onComplete && onComplete();
          }
        }
      }

      if (
        lastMessageReceived.type === 'idCheck.onApplicantSubmitted' ||
        lastMessageReceived.type === 'idCheck.onApplicantResubmitted'
      ) {
        console.log('calling onSubmitted function');
        return onSubmitted && onSubmitted(idvApplicantId);
      }
    }
  }, [idvApplicantId, lastMessageReceived]);

  const errorHandler = (error) => {
    console.log('>>>> Error received: ', error);
  };

  const getConfig = () => {
    return {
      uiConf: {
        customCssStr:
          ':root {\n  --font-family-base: Inter, sans-serif;\n  --primary-color: #6b7280;\n  --primary: #0350d7;\n  --neutral-light: #e5e7eb;\n  --high-contrast: #111827;\n  --default-contrast: #6b7280;\n  --font-size-title: 24px;}\n\n.sumsub-logo {\n  display:none;\n}\n\nbody{\n  font-family: var(--font-family-base);\n}\n\n.round-icon, #loader .round-icon {\n  background-image: linear-gradient(204deg,var(--primary),var(--primary));\n}\n\n.step.success .bullet {\n  background-color: var(--primary);\n  border-color: var(--primary);\n}\n\nsection.content {\n  border: 1px solid var(--neutral-light);\n  border-radius: 0.25rem;\n  box-shadow: 1px 1px 1px rgba(17, 24, 39, 0.1);\n}\n\na {\n  color: var(--primary);\n}\n\nbutton {\n  font-family: var(--font-family-base);\n  border-radius: 0.25rem;\n}\n\nbutton.submit, button.back {\n  text-align: center;\n  color: #fff;\n  background-color: var(--primary);\n  font-size: 1rem;\n  font-weight: 600;\n  text-transform: capitalize;\n  padding: 0.8125rem 1.25rem;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n}',
      },
    };
  };

  if (!sdkToken && !sdkCustomToken) {
    return <LoadingIndicator />;
  }

  if (sdkToken) {
    return (
      <div id='documents' data-testid={'sumsub-container'}>
        <SumsubWebSdk
          config={getConfig()}
          accessToken={sdkToken.token}
          expirationHandler={generateSDKToken}
          onMessage={messageHandler}
          onError={errorHandler}
        />
      </div>
    );
  }

  if (sdkCustomToken) {
    return (
      <div id='documents' data-testid={'sumsub-container'}>
        <SumsubWebSdk
          config={getConfig()}
          accessToken={sdkCustomToken.token}
          expirationHandler={generateCustomSDKToken}
          onMessage={messageHandler}
          onError={errorHandler}
        />
      </div>
    );
  }
};
