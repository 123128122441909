import * as Collapsible from '@radix-ui/react-collapsible';
import { DocumentLink, Text } from '@tokensoft-web/common-ui';
import { useAuth, utcToLocalDate } from '@tokensoft-web/common-utils';
import { useState } from 'react';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import { useDistributor } from '../../contexts/distributor/distributor-context';

const DistributorDocuments = () => {
  const { event } = useDistributor();
  const {
    user: { token },
  } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  let documents;
  if (event?.eventUsers?.length) {
    documents = event?.eventUsers[0]?.documents.filter(
      (doc) =>
        (!doc.eventDocumentVersion.requiresSignature && doc.acceptedAt) ||
        (doc.eventDocumentVersion.requiresSignature &&
          doc.signedAt &&
          doc.generatedAt),
    );
  }

  if (!documents?.length) {
    return null;
  }

  return (
    <Collapsible.Root open={isOpen}>
      <div
        className={'flex items-center font-bold text-lg'}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Collapsible.Trigger asChild>
          {isOpen ? (
            <FaMinusCircle className='mr-2 self-center' />
          ) : (
            <FaPlusCircle className='mr-2 self-center' />
          )}
        </Collapsible.Trigger>

        <span>
          Documents{' '}
          <span className={'text-xs font-normal mt-1.5'}>
            &nbsp;&nbsp;* Documents may take up to 24 hours to appear.
          </span>
        </span>
      </div>
      <Collapsible.Content>
        <div className={'py-4'}>
          {documents && documents.length > 0 ? (
            <>
              {documents.map((record, i) => {
                return (
                  <Text>
                    <DocumentLink document={record} />
                    {record.signedAt ? (
                      <span className={'text-sm'}>
                        {' '}
                        (Signed {utcToLocalDate(record.signedAt)})
                      </span>
                    ) : record.acceptedAt ? (
                      <span className={'text-sm'}>
                        {' '}
                        (Accepted {utcToLocalDate(record.acceptedAt)})
                      </span>
                    ) : null}
                  </Text>
                );
              })}
            </>
          ) : (
            <span className='block pl-6'>No documents found.</span>
          )}
        </div>
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

export default DistributorDocuments;
