import { CardTitle, LoadingIndicator } from '@tokensoft-web/common-ui';
import { useProject } from '../../../contexts/project/project-context';
import { StatusIcon } from './status-icon';

export const ProjectSetup = () => {
  const {
    loading,
    view,
    setView,
    hasApprovedComplianceDocs,
    hasSetProjectDetails,
    authorizedSigner,
  } = useProject();

  return (
    <>
      <CardTitle className='card-header'>Project Setup</CardTitle>
      {loading ? (
        <div className={'mb-12'}>
          <LoadingIndicator />
        </div>
      ) : (
        <div className='px-4 pb-4'>
          <ul className={'metismenu'}>
            <li
              className={
                view === 'project-details' ? 'bg-cool-grey-light px-4' : 'px-4'
              }
            >
              <a onClick={() => setView('project-details')}>
                <StatusIcon status={hasSetProjectDetails} />
                <span className={'text-neutral-extra-dark font-normal'}>
                  Project details
                </span>
              </a>
            </li>

            <li
              className={
                view === 'project-authorized-signer'
                  ? 'bg-cool-grey-light px-4'
                  : 'px-4'
              }
            >
              <a onClick={() => setView('project-authorized-signer')}>
                <StatusIcon status={authorizedSigner?.tier1Status} />
                <span className={'text-neutral-extra-dark font-normal'}>
                  Authorized signer
                </span>
              </a>
            </li>

            <li
              className={
                view === 'project-compliance'
                  ? 'bg-cool-grey-light px-4'
                  : 'px-4'
              }
            >
              <a onClick={() => setView('project-compliance')}>
                <StatusIcon status={hasApprovedComplianceDocs} />
                <span className={'text-neutral-extra-dark font-normal'}>
                  Compliance documents
                </span>
              </a>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};
