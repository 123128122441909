import { Wallet } from '@tokensoft-web/common-utils';

export const isSmartContractWallet = (
  wallets: Wallet[],
  walletAddress: string,
) => {
  if (!wallets) {
    return false;
  }

  const foundWallet = wallets.find(
    (wallet) =>
      wallet?.walletAddress?.toLowerCase() === walletAddress?.toLowerCase(),
  );
  return foundWallet ? foundWallet.smartContract : false;
};
