import { ConnectButton } from '@rainbow-me/rainbowkit';
import {
  Alert,
  Col,
  Row,
  TokensoftPoweredByIcon,
} from '@tokensoft-web/common-ui';
import { Layout } from '@tokensoft-web/common-ui/src/components/ui/layout/layout';
import {
  getIPFSLink,
  useAnalytics,
  useConfiguration,
} from '@tokensoft-web/common-utils';
import classNames from 'classnames';
import {
  FaDiscord,
  FaExclamationCircle,
  FaGlobe,
  FaTelegram,
} from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { LayoutType } from '../../utils/enums';
import './connect.css';

const ConnectPage = () => {
  const { pathname } = useLocation();
  const { configuration } = useConfiguration();
  useAnalytics('/welcome');

  let twitter = 'https://twitter.com/tokensoftinc';
  let website = 'https://www.tokensoft.io';
  let telegram = 'https://t.me/tokensoft';
  let discord = 'https://discord.gg/tokensoft';

  if (configuration?.project) {
    website = configuration.project.website || null;
    twitter = configuration.project.twitter || null;
    telegram = configuration.project.telegram || null;
    discord = configuration.project.discord || null;
  }

  const TwitterIcon = () => {
    return (
      <div>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 512 512'
          style={{ width: '16px', height: '16px' }}
        >
          <path d='M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z' />
        </svg>
      </div>
    );
  };

  const isRegistration = pathname.startsWith('/admin/register');

  const renderWelcomeContent = () => {
    let welcomeTitle = isRegistration
      ? "Let's Get Started"
      : 'Delivering Integrity and Compliance';
    let welcomeSubtitle = isRegistration
      ? 'Thank you for choosing Tokensoft.  Connect a wallet to setup your project.'
      : 'Enterprise services & tools for the leading blockchain foundations.';
    let containerClass = configuration?.project?.isProjectThemeModified
      ? 'domain-content'
      : 'tokensoft-content';
    let welcomeLayoutVersion = null;
    let welcomeLogo = null;
    let welcomeTitleAlignment = null;

    if (configuration?.project) {
      welcomeTitle = configuration?.project?.content?.welcomeTitle || 'Welcome';
      welcomeSubtitle =
        configuration?.project?.content?.welcomeSubtitle ||
        'Connect a wallet to get started.';
      welcomeLayoutVersion =
        configuration?.project?.content?.welcomeLayoutVersion;
      welcomeLogo = configuration?.project?.content?.welcomeLogo;

      switch (configuration?.project?.content?.welcomeLayoutVersion) {
        case LayoutType.TITLE_ICON_CENTERED:
          welcomeTitleAlignment = 'text-center';
          break;
        case LayoutType.TITLE_ONLY_100_CENTERED:
          welcomeTitleAlignment = 'text-center';
          break;
        case LayoutType.TITLE_ONLY_100_LEFT:
          welcomeTitleAlignment = 'text-left';
          break;
        case LayoutType.TITLE_ONLY_100_RIGHT:
          welcomeTitleAlignment = 'text-right';
          break;
      }
    }

    return (
      <>
        {process.env.REACT_APP_PROCESS_ENV !== 'production' ? (
          <Alert
            type='alert-neutral'
            className={'header-alert w-full square-borders !fixed top-1'}
            icon={<FaExclamationCircle className={'danger'} />}
          >
            <div className='flex items-center w-full'>
              This site is used for testing purposes only. Do not submit
              personal information.
            </div>
          </Alert>
        ) : null}

        <div
          className={classNames(
            `welcome-content welcome-color ${containerClass}`,
          )}
        >
          <div
            className={classNames(
              `flex flex-col ${
                configuration?.project?.content?.welcomeAlignment ||
                'center-center'
              } w-full px-32`,
            )}
          >
            {configuration?.project?.isProjectThemeModified &&
            configuration?.project?.content?.welcomeBody ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: configuration?.project?.content?.welcomeBody || null,
                }}
              ></div>
            ) : (
              <>
                <div className={'w-full'}>
                  {welcomeLayoutVersion === LayoutType.TITLE_ICON_CENTERED ? (
                    <img
                      src={getIPFSLink(welcomeLogo)}
                      className={'w-40 mx-auto'}
                    />
                  ) : null}
                  <div
                    className={classNames(
                      'mb-5 welcome-title',
                      welcomeTitleAlignment,
                    )}
                  >
                    {welcomeTitle}
                  </div>
                  <div
                    className={classNames(
                      'welcome-subtitle',
                      welcomeTitleAlignment,
                    )}
                  >
                    {welcomeSubtitle}
                  </div>
                </div>
              </>
            )}

            <Col className={'mt-12'} xalign='center'>
              {configuration?.project?.hidden ? (
                <></>
              ) : (
                <div className={'mt-4'}>
                  <ConnectButton />
                </div>
              )}

              {configuration?.project ? (
                <div className={'mt-5'}>
                  <TokensoftPoweredByIcon />
                </div>
              ) : null}
            </Col>
          </div>

          <Row gap={4} place='center' className={'mt-5'}>
            {website ? (
              <div>
                <a
                  className={'social-link'}
                  href={website}
                  target={'_blank'}
                  rel='noreferrer'
                >
                  <FaGlobe />
                </a>
              </div>
            ) : null}
            {twitter ? (
              <div>
                <a
                  className={'social-link'}
                  href={twitter}
                  target={'_blank'}
                  rel='noreferrer'
                >
                  <TwitterIcon />
                </a>
              </div>
            ) : null}
            {telegram ? (
              <div>
                <a
                  className={'social-link'}
                  href={telegram}
                  target={'_blank'}
                  rel='noreferrer'
                >
                  <FaTelegram />
                </a>
              </div>
            ) : null}
            {discord ? (
              <div>
                <a
                  className={'social-link'}
                  href={discord}
                  target={'_blank'}
                  rel='noreferrer'
                >
                  <FaDiscord />
                </a>
              </div>
            ) : null}
          </Row>
        </div>
      </>
    );
  };

  return (
    <Layout fullWidth>
      <div className='welcome-content-body'>{renderWelcomeContent()}</div>
    </Layout>
  );
};

export default ConnectPage;
