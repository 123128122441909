import {
  LoadingIndicator,
  PageHeader,
  PermissionDeniedError,
} from '@tokensoft-web/common-ui';
import {
  FEATURE,
  FEATURE_RESTRICTION,
  useAccount,
  useFeature,
} from '@tokensoft-web/common-utils';
import GlobalRestriction from '../../components/feature/global-restriction';
import KycRestriction from '../../components/feature/kyc-restriction';
import RegionRestriction from '../../components/feature/region-restriction';
import WalletAddressRestriction from '../../components/feature/wallet-address-restriction';
import WhitelabelRestriction from '../../components/feature/whitelabel-restriction';
import Projects from '../../components/project/projects';

const AdminPage = () => {
  const { getFeatureRestriction } = useFeature();
  const { account, loading } = useAccount();
  const restriction = getFeatureRestriction(FEATURE.ADMIN);

  if (restriction) {
    switch (restriction) {
      case FEATURE_RESTRICTION.GLOBAL_RESTRICTION:
        return <GlobalRestriction />;
      case FEATURE_RESTRICTION.WHITELABEL_RESTRICTION:
        return <WhitelabelRestriction />;
      case FEATURE_RESTRICTION.KYC_RESTRICTION:
        return <KycRestriction />;
      case FEATURE_RESTRICTION.REGION_RESTRICTION:
        return <RegionRestriction />;
      case FEATURE_RESTRICTION.WALLET_ADDRESS_RESTRICTION:
        return <WalletAddressRestriction />;
    }
  }

  if (loading) {
    return <LoadingIndicator text={'Loading'} />;
  }

  if (!account?.projects?.length) {
    return (
      <PermissionDeniedError
        message={'You are not a member of any active projects.'}
      />
    );
  }

  return (
    <>
      <PageHeader title={'My Projects'} />

      <Projects adminOnly={true} />
    </>
  );
};

export default AdminPage;
