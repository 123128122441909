import * as Sentry from '@sentry/react';
import {
  AnimatedCheckmark,
  Button,
  Col,
  CrosschainPendingIcon,
  FailIcon,
  FrontArrowIcon,
  LoadingIndicator,
  ModalTitle,
  Row,
  Text,
} from '@tokensoft-web/common-ui';
import { useNetworks, useToast } from '@tokensoft-web/common-utils';
import { getTxUrl } from '@tokensoft-web/common-utils/src/util/network';
import { useEffect, useState } from 'react';
import { FaRegTimesCircle } from 'react-icons/fa';
import { VscLinkExternal } from 'react-icons/vsc';
import ModalStep from '../../../components/modal-step/modal-step';
import {
  useUpdateDistributorMerkleRoot,
  useUpdateDistributorUri,
} from '../../../utils/distributor';
import { VESTING_TYPE } from '../../../utils/enums';

export enum EDIT_DISTRIBUTOR_PARTICIPANTS_MODAL_STEP {
  UPDATE_CONTRACT = 'UPDATE_CONTRACT',
  ON_CHAIN_CONFIG = 'ON_CHAIN_CONFIG',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

interface StepProps {
  id: EDIT_DISTRIBUTOR_PARTICIPANTS_MODAL_STEP;
  title: string;
  subtitle: string;
  description: string;
  submitTitle: string;
}

const steps: StepProps[] = [
  {
    id: EDIT_DISTRIBUTOR_PARTICIPANTS_MODAL_STEP.UPDATE_CONTRACT,
    title: 'Updating Distribution Contract',
    subtitle: 'Update Contract',
    description:
      'Please select continue to update your distribution contract. You will be prompted to sign a message in your metamask.',
    submitTitle: 'Submit & Continue',
  },
  {
    id: EDIT_DISTRIBUTOR_PARTICIPANTS_MODAL_STEP.ON_CHAIN_CONFIG,
    title: 'Updating Distribution Configuration',
    subtitle: 'On-chain Config',
    description:
      'Please select submit to update your distribution configuration. You will be prompted to sign a message in your metamask.',
    submitTitle: 'Submit',
  },
];

const completedStep: StepProps = {
  id: EDIT_DISTRIBUTOR_PARTICIPANTS_MODAL_STEP.COMPLETED,
  title: 'Update Successful!',
  subtitle: '',
  description:
    "You have successfully updated your distributor contract. You're ready to proceed with your event dashboard.",
  submitTitle: 'Finish',
};

const errorStep: StepProps = {
  id: EDIT_DISTRIBUTOR_PARTICIPANTS_MODAL_STEP.ERROR,
  title: 'Failed to Connect',
  subtitle: '',
  description: 'An error occurred while updating your contract.',
  submitTitle: 'Try Again',
};

interface Props {
  vestingType: VESTING_TYPE;
  distributor: any;
  merkleRoot: string;
  ipfsUri: string;
  onExit: () => void;
  onFinish: () => void;
}

export const UpdateDistributorParticipantsSigningModal = ({
  vestingType,
  distributor,
  merkleRoot,
  ipfsUri,
  onExit,
  onFinish,
}: Props) => {
  const { showErrorToast, showSuccessToast } = useToast();
  const { getNetworkDetails } = useNetworks();

  const [currentStep, setCurrentStep] = useState<StepProps>(steps[0]);

  const {
    error: updateDistributorMerkleRootError,
    write: sendUpdateDistributorMerkleRoot,
    isLoading: updateDistributorMerkleRootLoading,
    data: updateDistributorMerkleRootReceipt,
  } = useUpdateDistributorMerkleRoot();

  const {
    error: updateDistributorUriError,
    write: sendUpdateDistributorUri,
    isLoading: updateDistributorUriLoading,
    data: updateDistributorUriReceipt,
  } = useUpdateDistributorUri();

  const [sendingUpdateForSigning, setSendingUpdateForSigning] = useState(false);

  const isCompleted =
    currentStep.id === EDIT_DISTRIBUTOR_PARTICIPANTS_MODAL_STEP.COMPLETED;
  const isError = updateDistributorMerkleRootError || updateDistributorUriError;

  const updateDistributorMerkleRoot = () => {
    const args = [merkleRoot];
    return sendUpdateDistributorMerkleRoot(
      vestingType,
      distributor.chainId,
      distributor.id,
      args,
    );
  };

  const updateDistributorUri = () => {
    const contractAddress = distributor?.id;
    const args = [ipfsUri];
    return sendUpdateDistributorUri(distributor.chainId, contractAddress, args);
  };

  const handleNextClick = async () => {
    if (currentStep.id === EDIT_DISTRIBUTOR_PARTICIPANTS_MODAL_STEP.COMPLETED) {
      onFinish();
      return;
    }

    if (currentStep.id === EDIT_DISTRIBUTOR_PARTICIPANTS_MODAL_STEP.ERROR) {
      setCurrentStep(steps[0]);
      return;
    }

    if (sendingUpdateForSigning) return;

    setSendingUpdateForSigning(true);
    try {
      const promise: Promise<any> =
        currentStep.id ===
        EDIT_DISTRIBUTOR_PARTICIPANTS_MODAL_STEP.UPDATE_CONTRACT
          ? updateDistributorMerkleRoot()
          : updateDistributorUri();

      const result = await promise;
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      showErrorToast({ description: e.toString() });
      return;
    } finally {
      setSendingUpdateForSigning(false);
    }
  };

  const goToNextStep = () => {
    const currentIndex = steps.findIndex((step) => step.id === currentStep.id);

    if (currentIndex === steps.length - 1) {
      setCurrentStep(completedStep);
      return;
    }

    setCurrentStep(steps[currentIndex + 1]);
  };

  const showSuccessfulTransaction = (txUrl: string, message?: string) => {
    showSuccessToast({
      description: (
        <div className='flex flex-row'>
          {message ? message : 'Successfully submitted transaction.'}
          <a
            target='_blank'
            rel='noreferrer'
            href={txUrl}
            className='w-[30px] flex items-center justify-center text-white'
            onClick={(e) => e.stopPropagation()}
          >
            <VscLinkExternal color='white' />
          </a>
        </div>
      ),
    });
  };

  // On update distributor merkle root success,
  // show success toast and go to next step
  useEffect(() => {
    if (
      updateDistributorMerkleRootReceipt &&
      updateDistributorMerkleRootReceipt.status === 'success'
    ) {
      const txUrl = getTxUrl(
        updateDistributorMerkleRootReceipt.transactionHash,
        getNetworkDetails(distributor.chainId),
      );

      showSuccessfulTransaction(txUrl);
      goToNextStep();
    }
  }, [updateDistributorMerkleRootReceipt]);

  // On update distributor uri success,
  // show success toast and go to next step
  useEffect(() => {
    if (
      updateDistributorUriReceipt &&
      updateDistributorUriReceipt.status === 'success'
    ) {
      const txUrl = getTxUrl(
        updateDistributorUriReceipt.transactionHash,
        getNetworkDetails(distributor.chainId),
      );

      showSuccessfulTransaction(txUrl, 'Successfully updated participants.');
      goToNextStep();
    }
  }, [updateDistributorUriReceipt]);

  // On update distributor merkle root error, show error toast
  useEffect(() => {
    if (updateDistributorMerkleRootError) {
      Sentry.captureException(updateDistributorMerkleRootError);
      console.error(updateDistributorMerkleRootError);

      showErrorToast({ description: updateDistributorMerkleRootError.message });
      setCurrentStep(errorStep);
    }
  }, [updateDistributorMerkleRootError]);

  // On update distributor uri error, show error toast
  useEffect(() => {
    if (updateDistributorUriError) {
      Sentry.captureException(updateDistributorUriError);
      console.error(updateDistributorUriError);

      showErrorToast({ description: updateDistributorUriError.message });
      setCurrentStep(errorStep);
    }
  }, [updateDistributorUriError]);

  const loading =
    sendingUpdateForSigning ||
    updateDistributorMerkleRootLoading ||
    updateDistributorUriLoading;

  const loadingMessage = sendingUpdateForSigning
    ? 'Submitting your request...'
    : 'Updating your contract. This may take a minute...';

  const confirmExit = () => {
    if (window.confirm('Are you sure you want to exit?')) {
      onExit();
    }
  };

  return (
    <Col place='center' className='relative' gap={5}>
      <div onClick={confirmExit} className='absolute top-0 right-0 w-[20px]'>
        <FaRegTimesCircle className='text-neutral-medium cursor-pointer' />
      </div>
      {isCompleted ? (
        isError ? (
          <FailIcon />
        ) : (
          <AnimatedCheckmark />
        )
      ) : (
        <CrosschainPendingIcon />
      )}
      <ModalTitle>{currentStep.title}</ModalTitle>
      {!isError && !isCompleted && (
        <ModalStep
          activeStep={steps.findIndex((step) => step.id === currentStep.id) + 1}
          dividerWidth='w-0'
          steps={steps.map((step) => step.subtitle)}
        />
      )}
      <Text textAlign='center'>
        {loading ? loadingMessage : currentStep.description}
      </Text>
      <Row>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <Button
            className='w-full'
            onClick={handleNextClick}
            disabled={loading}
          >
            <Row place='center' gap={2}>
              <Text>{currentStep.submitTitle}</Text>
              <FrontArrowIcon />
            </Row>
          </Button>
        )}
      </Row>
    </Col>
  );
};
