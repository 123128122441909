import { Card, IconLogo, LoadingIndicator } from '@tokensoft-web/common-ui';
import {
  convertBaseUnitsToDecimal,
  formatValue,
  getIPFSLink,
  getResourceUri,
  getValidIPFSHash,
  useAccount,
  useAuth,
  useNetworks,
} from '@tokensoft-web/common-utils';
import classNames from 'classnames';
import DynamicFont from 'react-dynamic-font';
import { GiToken } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import { getClaimsStartDate, getTotalUserClaimable } from '../../utils/claim';
import { VESTING_TYPE } from '../../utils/enums';

interface DistributorEventCardProps {
  event: any;
  loading?: boolean;
}

const DistributorEventCard = ({
  event,
  loading,
}: DistributorEventCardProps) => {
  const { account } = useAccount();
  const { getNetworkDetails } = useNetworks();
  const { logoUrl, name } = getNetworkDetails(event.networkId);
  const {
    user: { walletAddress },
  } = useAuth();
  const claimableFixedNumber =
    event.distributor?.vestingType === VESTING_TYPE.CONTINUOUS ? 4 : 2;
  const eventId = event?.event?.id || event.id;

  const claimUrl = `/event/${eventId}`;
  const totalUserClaimable = getTotalUserClaimable(event, walletAddress);
  const tokenLogoUri = getIPFSLink(
    event.distributor?.logo?.uri || event.distributor?.logo?.cid,
  );

  const startDateFormatted = () => {
    if (loading) {
      return <LoadingIndicator className={'no-padding'}></LoadingIndicator>;
    }

    return getClaimsStartDate(event, account);
  };

  const totalUserClaimableFormatted = () => {
    if (loading) {
      return <LoadingIndicator className={'no-padding'}></LoadingIndicator>;
    }

    return event.distributor?.token_decimals
      ? formatValue(
          convertBaseUnitsToDecimal(
            totalUserClaimable,
            event.distributor.token_decimals,
            claimableFixedNumber,
          ),
          { commas: true },
        )
      : '-';
  };

  const eventLogo = event.project?.theme?.logoSmall;
  const logo = getValidIPFSHash(eventLogo)
    ? getResourceUri(eventLogo)
    : eventLogo;

  return (
    <Card className={'hover-card small-padding claim-card'}>
      <div
        className={classNames(
          `grid grid-cols-2 md:grid-cols-3 xl:grid-cols-8 gap-x-8 gap-y-4 cursor-pointer h-full`,
        )}
      >
        <div className={`flex flew-row space-x-2 items-center col-span-3`}>
          <Link to={claimUrl}>
            <IconLogo className='large' logo={logo} />
          </Link>
          <div className={'flex flex-col self-center '} onClick={() => {}}>
            <Link
              className={`font-semibold high-contrast-link no-underline cursor-pointer`}
              to={`/event/${eventId}`}
            >
              {event.name.toUpperCase()}
            </Link>
          </div>
        </div>

        <Link
          to={claimUrl}
          className={classNames(
            'flex flex-col col-span-3 md:col-span-2 cursor-pointer',
          )}
        >
          <div className='mb-2'>
            <span className='text-xs uppercase text-neutral-medium'>
              Network
            </span>
          </div>
          <div className={'flex flex-row items-center'}>
            {logoUrl && (
              <div className='logo pr-2'>
                <img
                  className='min-w-[24px] w-[24px] min-h-[24px] h-[24px]'
                  src={logoUrl}
                />
              </div>
            )}
            <div className='text-sm high-contrast'>
              {name === 'Unknown' ? '-' : name}
            </div>
          </div>
        </Link>

        <Link
          to={claimUrl}
          className={classNames(
            'flex flex-col col-span-3 md:col-span-1 cursor-pointer',
          )}
        >
          <div className='mb-2'>
            <span className='text-xs uppercase text-neutral-medium'>
              Start Date
            </span>
          </div>
          <div className={'flex flex-row items-center'}>
            <div className='text-sm high-contrast'>{startDateFormatted()}</div>
          </div>
        </Link>

        <Link
          to={claimUrl}
          className={classNames(
            'flex flex-col col-span-3 md:col-span-2 cursor-pointer',
          )}
        >
          <div className='mb-2'>
            <span className='text-xs uppercase text-neutral-medium'>
              Total Allocation
            </span>
          </div>
          <div className={'flex flex-row items-center'}>
            {tokenLogoUri ? (
              <img
                className='rounded-full w-8 h-8 cursor-pointer'
                src={tokenLogoUri}
              />
            ) : (
              <div className='allocation-icon-background rounded-full p-1 cursor-pointer'>
                <GiToken color='white' />
              </div>
            )}
            <div className='high-contrast ml-2 text-sm overflow-hidden'>
              <DynamicFont content={totalUserClaimableFormatted()} />
            </div>
          </div>
        </Link>
      </div>
    </Card>
  );
};

export default DistributorEventCard;
