import { Card, RadioInput } from '@tokensoft-web/common-ui';
import { AccountDetails, ENTITY_TYPE } from '@tokensoft-web/common-utils';
import { FC, useEffect } from 'react';

type EntityTypePanelProps = {
  accountDetails: AccountDetails;
  onChange: (AccountDetails) => void;
};

export const EntityTypePanel: FC<EntityTypePanelProps> = ({
  accountDetails,
  onChange,
}) => {
  // provide a default value if nothing is set
  useEffect(() => {
    if (accountDetails && !accountDetails.entityType) {
      onChange({ ...accountDetails, entityType: ENTITY_TYPE.COMPANY });
    }
  }, [accountDetails]);

  return (
    <div id='entity-type'>
      <Card title='Entity Type'>
        <p className='pb-4'>What type of entity is it?</p>

        <div className='pb-4'>
          <RadioInput
            name='entityType'
            checked={accountDetails?.entityType === ENTITY_TYPE.COMPANY}
            onClick={() =>
              onChange({ ...accountDetails, entityType: ENTITY_TYPE.COMPANY })
            }
          >
            Corporation
          </RadioInput>
          <RadioInput
            name='entityType'
            checked={accountDetails?.entityType === ENTITY_TYPE.PARTNERSHIP}
            onClick={() =>
              onChange({
                ...accountDetails,
                entityType: ENTITY_TYPE.PARTNERSHIP,
              })
            }
          >
            Partnership
          </RadioInput>
          <RadioInput
            name='entityType'
            checked={accountDetails?.entityType === ENTITY_TYPE.TRUST}
            onClick={() =>
              onChange({ ...accountDetails, entityType: ENTITY_TYPE.TRUST })
            }
          >
            Trust
          </RadioInput>
          <RadioInput
            name='entityType'
            checked={accountDetails?.entityType === ENTITY_TYPE.NON_PROFIT}
            onClick={() =>
              onChange({
                ...accountDetails,
                entityType: ENTITY_TYPE.NON_PROFIT,
              })
            }
          >
            Non-profit
          </RadioInput>
        </div>
      </Card>
    </div>
  );
};

export default EntityTypePanel;
