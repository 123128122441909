import { Col, Stretched, Text, Title } from '@tokensoft-web/common-ui';

interface ConnextDetailsHeaderTextProps {
  isSmartContract: boolean;
}

const ConnextDetailsHeaderText = ({
  isSmartContract,
}: ConnextDetailsHeaderTextProps) => {
  const isEOA = !isSmartContract;

  return (
    <Col gap={6}>
      <Title>Welcome to the First Crosschain Airdrop!</Title>
      <Stretched gap={10}>
        <Col>
          <Text>
            During the claim process, you’ll have the opportunity to select
            which chain you’d like to receive your NEXT tokens on. To get
            started, simply:
          </Text>
          <ul className='list-disc'>
            <li className='ml-6'>
              <Text>Click Claim.</Text>
            </li>
            {isEOA && (
              <li className='ml-6'>
                <Text>Select your chain.</Text>
              </li>
            )}
            <li className='ml-6'>
              <Text>
                And sign two messages (one to initiate the claim and the other
                to confirm your delegation).
              </Text>
            </li>
          </ul>
          <br />
          <Text> That’s all there is to it!</Text>
        </Col>
      </Stretched>
    </Col>
  );
};

export default ConnextDetailsHeaderText;
