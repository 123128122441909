import CanvasJSReact from '@canvasjs/react-charts';
import { deepMerge } from '@tokensoft-web/common-utils';
import { useEffect, useState } from 'react';
import './charts.css';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const LineChart = ({
  options,
  props = { width: '100%', height: '400px' },
}) => {
  const [lineChartOptions, setLineChartOptions] = useState(null);

  useEffect(() => {
    if (!options) {
      return;
    }

    setLineChartOptions(
      deepMerge(
        {
          animationEnabled: true,
          axisX: {
            gridColor: '#E5E7EB',
            labelFontColor: '#6B7280',
            labelFontSize: 12,
            labelFontWeight: '400',
            labelFontFamily: 'Inter',
            crosshair: {
              enabled: true,
            },
          },
          axisY: {
            lineColor: '#6B7280',
            tickColor: '#6B7280',
            labelFontColor: '#6B7280',
            gridColor: '#E5E7EB',
            includeZero: true,
          },
          axisY2: {
            lineColor: '#6B7280',
            tickColor: '#6B7280',
            labelFontColor: '#6B7280',
            gridColor: '#E5E7EB',
            includeZero: true,
          },
          legend: {
            fontSize: 14,
            fontColor: '#111827',
            fontWeight: 'bold',
            fontFamily: 'Inter',
            verticalAlign: 'top',
            markerMargin: 8,
            itemWidth: 200,
          },
        },
        options,
      ),
    );
  }, [options]);

  // console.log('Rendering chart with merged options', lineChartOptions)

  return <CanvasJSChart options={lineChartOptions} containerProps={props} />;
};
