import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useReadContract } from 'wagmi';
import { useDistributorApiClient } from '../client/distributor-api-client';
import { useAuth } from '../context/auth/auth-context';
import { useWallet } from '../context/wallet/wallet-context';
import { useAsync } from '../hook/async';

export const useGetContracts = () => {
  const client = useDistributorApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`contracts/search`, {
        method: 'post',
        data,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['contracts'] }),
  });
};

export const useGetContract = (contractId: string) => {
  const {
    user: { walletAddress },
  } = useAuth();
  const { connectedChainId } = useWallet();
  const client = useDistributorApiClient();
  const { run, data } = useAsync();
  const [contract, setContract] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(null);

  useEffect(() => {
    if (!reload) {
      return;
    }

    run(
      client(`contracts/find`, {
        method: 'post',
        data: {
          contractId: `${contractId}-${connectedChainId}`,
          walletAddress: walletAddress,
          includeHidden: false,
        },
      }),
    );
  }, [reload]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data?.contract) {
      //const formattedDistributor = formatDistributor(data.distributor);
      //ask Aaron about this
      setContract(data.contract);
    }

    setLoading(false);
  }, [data]);

  const refresh = () => {
    setContract(null);
    setLoading(true);
    setReload(new Date());
  };

  useEffect(() => {
    if (walletAddress) {
      refresh();
    }
  }, [walletAddress]);

  return { contract: contract, loading: loading, refresh: refresh };
};

export const useFindContract = () => {
  const client = useDistributorApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`contracts/find`, {
        method: 'post',
        data,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['delegates'] }),
  });
};

export const useGetContractOwner = (
  address: `0x${string}`,
  chainId: number,
  abi: any,
) => {
  const { data: owner, ...rest } = useReadContract({
    address,
    chainId,
    abi,
    functionName: 'owner',
  });

  const ownerAddr = owner as `0x${string}` | undefined;

  return {
    owner: ownerAddr,
    ...rest,
  };
};

/**
 * Direct fetch from contract, returns array with indices:
 * 0 recipient          address :  0xF9E6aC3D391408784a2E6F7036048d5023366D21
 * 1 merkleRoot         bytes32 :  0xcfa1485fff5405b1a08335ca19774ae3f62dab3e4f6c30cdffac610aa7d2cc56
 * 2 saleMaximum        uint256 :  500000000000
 * 3 userMaximum        uint256 :  50000000000
 * 4 purchaseMinimum    uint256 :  1000000000
 * 5 startTime          uint256 :  1717250400
 * 6 endTime            uint256 :  1722520800
 * 7 maxQueueTime       uint256 :  0
 * 8 URI                string  :  ipfs://QmYuFz4kP7TCdrHYMSTnjHbJx6TCByakxzXyYakGVrx9TB
 */
export const useGetContractConfig = (
  address: `0x${string}`,
  chainId: number,
  abi: any,
) => {
  const { data: config, ...rest } = useReadContract({
    address,
    chainId,
    abi,
    functionName: 'config',
  });

  return {
    config,
    ...rest,
  };
};
