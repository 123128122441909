import { useAccount } from '../context/account/account-context';
import { useConfiguration } from '../context/configuration/configuration-context';

export const useFeature = () => {
  const { configuration, loading: configLoading } = useConfiguration();
  const { configuration: accountConfiguration, loading: accountLoading } =
    useAccount();

  const isFeatureEnabled = (name: string) => {
    if (!configuration) {
      return false;
    }

    // first check the global configuration
    const feature = configuration?.features?.find((f) => f.name === name);
    if (!feature?.enabled) {
      return false;
    }

    // next check the project configuration
    const projectFeature = configuration?.project?.features?.find(
      (f) => f.name === name,
    );
    if (projectFeature) {
      return projectFeature.enabled;
    }

    // finally, check the account specific configuration
    const accountFeature = accountConfiguration?.features?.find(
      (f) => f.name === name,
    );
    return accountFeature?.enabled;
  };

  const getFeatureRestriction = (name: string) => {
    if (!configuration) {
      return false;
    }

    // first check the global configuration
    const feature = configuration?.features?.find((f) => f.name === name);
    if (feature?.restriction) {
      return feature.restriction;
    }

    // next check the account specific configuration
    const accountFeature = accountConfiguration?.features?.find(
      (f) => f.name === name,
    );
    return accountFeature?.restriction;
  };

  return {
    isFeatureEnabled: isFeatureEnabled,
    getFeatureRestriction: getFeatureRestriction,
    isLoading: configLoading || accountLoading,
  };
};
