import { Button, LoadingIndicator, Text } from '@tokensoft-web/common-ui';
import { EVENT_STATUS, Event } from '@tokensoft-web/common-utils';
import { useEffect, useState } from 'react';
import { useGetEventsSummary } from '../../services/event-summary-service';
import { EventCard } from '../admin/event/event-card';
import './events.css';

const Events = () => {
  const { results: events, loading, loadMore } = useGetEventsSummary();
  const [sortedEvents, setSortedEvents] = useState<Event[]>([]);

  useEffect(() => {
    if (events?.length > 0) {
      sortEvents();
    }
  }, [events]);

  const sortEvents = () => {
    const _sortedEvents = events.sort((a, b) => {
      if (a.status === b.status) {
        switch (a.status) {
          case EVENT_STATUS.CURRENT:
            // sort current sales by start time, most recent first
            return parseInt(b.startTime) - parseInt(a.startTime);
          case EVENT_STATUS.UPCOMING:
            // sort upcoming sales by start time, soonest first
            return parseInt(a.startTime) - parseInt(b.startTime);
          case EVENT_STATUS.COMPLETED:
          default:
            // sort completed sales by end time, most recent first
            return parseInt(b.endTime) - parseInt(a.endTime);
        }
      } else {
        switch (a.status) {
          case EVENT_STATUS.CURRENT:
            return -1; // "a" should come before "b"
          case EVENT_STATUS.COMPLETED:
            return 1; // "a" should come after "b"
          case EVENT_STATUS.UPCOMING:
          default:
            return b.status === EVENT_STATUS.CURRENT ? 1 : -1; // "a" depends on the status of "b"
        }
      }
    });

    setSortedEvents(_sortedEvents);
  };

  return (
    <>
      {events?.length > 0 ? (
        <>
          <div className='grid gap-4 grid-cols-1'>
            {sortedEvents.map((event, i) => {
              return (
                <div key={i}>
                  <EventCard event={event} eventUrl={`/event/${event.id}`} />
                </div>
              );
            })}
          </div>
        </>
      ) : null}

      {!loading && sortedEvents.length === 0 ? (
        <>
          <Text>
            There aren't any active or upcoming events right now. Please check
            back soon.
          </Text>
        </>
      ) : null}

      {loading ? (
        <LoadingIndicator text='Loading Events' />
      ) : loadMore.canLoadMore ? (
        <div className='flex justify-center'>
          <Button
            className={'w-full'}
            fill={'outline'}
            onClick={loadMore.getMoreEvents}
          >
            Load More
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default Events;
