import { Alert } from '@tokensoft-web/common-ui';
import {
  FEATURE,
  getTxUrl,
  lt,
  useModal,
  useNetworks,
  useToast,
  useWallet,
} from '@tokensoft-web/common-utils';
import { useEffect, useState } from 'react';
import { FaRegTimesCircle } from 'react-icons/fa';
import { VscLinkExternal } from 'react-icons/vsc';
import {
  getDistributorInterfaces,
  getSatelliteContractForChainId,
  isSatelliteContract,
} from '../../utils/abi';
import ClaimButton from './claim-button';
import ClaimModalStep from './claim-modal-step';
import './claim-modal.css';
import SubmitClaimSuccess from './submit-claim-success';

interface SubmitClaimModalProps {
  event: any;
  distributor: any;
  isSmartContractWallet: boolean;
  claimableAmount: number;
  formattedClaimableAmount: number;
  onMinedTransaction: Function;
  delegateSaved?: any;
  onDelegateFinished?: Function;
  correctNetworkChainId: number;
}

enum CLAIM_STEP {
  CLAIM = 'CLAIM',
  SUCCESS = 'SUCCESS',
}

const SubmitClaimModal = ({
  event,
  distributor,
  isSmartContractWallet,
  claimableAmount,
  formattedClaimableAmount,
  onMinedTransaction,
  delegateSaved,
  onDelegateFinished,
  correctNetworkChainId,
}: SubmitClaimModalProps) => {
  const [pending, setPending] = useState(false);
  const [claimStep, setClaimStep] = useState<CLAIM_STEP>(CLAIM_STEP.CLAIM);
  const [claimChainId, setClaimChainId] = useState(null);
  const [claimSymbol, setClaimSymbol] = useState(null);
  const [claimDistributorAddress, setClaimDistributorAddress] = useState(null);
  const [isCrossChainTransaction, setIsCrossChainTransaction] = useState(false);
  const [claimProof, setClaimProof] = useState(null);
  const [proofIndex, setProofIndex] = useState(null);
  const [proofAmount, setProofAmount] = useState(null);
  const [claimInterfaces, setClaimInterfaces] = useState(null);

  const { closeModal } = useModal();
  const { showErrorToast } = useToast();
  const [pendingTxHash, setPendingTxHash] = useState(null);
  const { connectedChainId } = useWallet();
  const { getNetworkDetails } = useNetworks();
  const networkDetails = getNetworkDetails(connectedChainId);

  const interfaces = getDistributorInterfaces(distributor);
  const isConnectedToDistributorChain =
    distributor.chainId === connectedChainId;

  const isConnextFlow = !!event?.features?.includes(
    FEATURE.CLAIM_CONNEXT_LAYOUT,
  );
  const userOnCorrectNetwork =
    isConnextFlow && !isSmartContractWallet
      ? true
      : connectedChainId === correctNetworkChainId;

  useEffect(() => {
    setClaimStep(CLAIM_STEP.CLAIM);
  }, []);

  useEffect(() => {
    if (distributor) {
      setClaimSymbol(distributor.tokenSymbol);
      setProofIndex(distributor?.proofIndex);
      setProofAmount(distributor?.proofAmount);

      if (isSmartContractWallet && !isConnectedToDistributorChain) {
        // if connext and smart contract and satellite contract, the interface Id is different
        const satelliteContract = getSatelliteContractForChainId(
          distributor,
          connectedChainId,
        );

        if (satelliteContract) {
          const authorization = distributor?.authorization;
          const proof = authorization.proof || distributor?.proof;

          // use the chainId, interface id from the satellite contract
          setClaimChainId(satelliteContract.networkId);
          // sanitize the distributor address ${address}-${networkId} to ${address}
          setClaimDistributorAddress(satelliteContract.id.split('-')[0]);
          setClaimProof(proof);
          setClaimInterfaces(satelliteContract.interfaces);
          setIsCrossChainTransaction(true);
          return;
        }
      }

      setClaimChainId(distributor.chainId);
      setClaimDistributorAddress(distributor.id);
      setClaimProof(distributor?.proof);
      setClaimInterfaces(interfaces);
    }
  }, [distributor, isSmartContractWallet]);

  const handleSubmitTransaction = (txHash: string) => {
    setPendingTxHash(txHash);
    setClaimStep(CLAIM_STEP.SUCCESS);
  };

  return (
    <div className='px-4'>
      {claimStep === CLAIM_STEP.SUCCESS ? (
        <SubmitClaimSuccess
          interfaces={interfaces}
          transactionHash={pendingTxHash}
          delegateSaved={delegateSaved}
          event={event}
          distributor={distributor}
          claimableAmount={formattedClaimableAmount}
          network={networkDetails}
          onDelegateFinished={onDelegateFinished}
          onMinedTransaction={onMinedTransaction}
          isSatellite={isSatelliteContract(claimInterfaces)}
          isCrossChainTransaction={isCrossChainTransaction}
          onClaimError={(errorMessage) => {
            setClaimStep(CLAIM_STEP.CLAIM);
            console.error(errorMessage);
            showErrorToast({
              description:
                'The transaction could not be completed, please try again later.',
            });
          }}
        />
      ) : (
        <>
          <div className='flex flex-col mt-4'>
            <div className='flex flex-row justify-between'>
              <span className='w-[20px]'></span>
              <span className='text-2xl font-bold text-center'>
                Submit Claim
              </span>
              <div
                onClick={() => !pending && closeModal()}
                className='w-[20px]'
              >
                {!pending && (
                  <FaRegTimesCircle className='text-neutral-medium cursor-pointer' />
                )}
              </div>
            </div>

            <ClaimModalStep onStep={1} />
            <p className='text-center mt-6'>
              {`You are set to claim `}
              <span className='font-bold'>{formattedClaimableAmount}</span>
              {` ${distributor.tokenSymbol} tokens on the ${networkDetails.name} Network.`}
            </p>
          </div>
          {pending && isSmartContractWallet && (
            <div className='mt-4'>
              <Alert type='alert-info light'>
                We recognize you are claiming with a smart contract wallet. If
                your claim appears to be processing for several minutes, please
                try refreshing the page.
              </Alert>
            </div>
          )}
          {pendingTxHash && (
            <Alert type='alert-success' className='mt-4 leading-5'>
              <div className='flex flex-row items-center text-white'>
                Your claim is being submitted. View your transaction here
                <a
                  target='_blank'
                  rel='noreferrer'
                  href={getTxUrl(
                    pendingTxHash,
                    getNetworkDetails(distributor.chainId),
                  )}
                  className='self-center ml-2'
                >
                  <VscLinkExternal color='white' />
                </a>
              </div>
            </Alert>
          )}
          <div className='my-6 w-full'>
            <ClaimButton
              className={'btn btn-primary btn-lg w-full py-3'}
              claimData={{
                chainId: claimChainId,
                symbol: claimSymbol,
                distributorAddress: claimDistributorAddress,
                proof: claimProof,
                proofIndex: proofIndex,
                proofAmount: proofAmount,
                saleId: '',
                interfaces: claimInterfaces,
              }}
              text={'Claim'}
              features={event?.features}
              userOnCorrectNetwork={userOnCorrectNetwork}
              onPendingState={(isPending) => {
                setPending(isPending);
              }}
              disabled={!claimableAmount || lt(claimableAmount, 0)}
              onSubmitTransaction={handleSubmitTransaction}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SubmitClaimModal;
