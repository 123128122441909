import { CrosschainPendingIcon } from '@tokensoft-web/common-ui';
import ClaimModalStep from '../claims/claim-modal-step';

interface DelegatingModalProps {
  delegatingToSelf: any;
  delegateByInputWallet: any;
  delegateSaved: any;
  delegateToAddressOrENS: any;
  delegateInfo: any;
  showSteps?: boolean;
}

const DelegatingModal = ({
  delegatingToSelf,
  delegateByInputWallet,
  delegateSaved,
  delegateToAddressOrENS,
  delegateInfo,
  showSteps = true,
}: DelegatingModalProps) => {
  return (
    <div className='px-3'>
      <div className='sm:w-[380px] md:w-[476px]'></div>
      <div className='flex flex-row mt-4 justify-center'>
        <CrosschainPendingIcon />
      </div>
      <div className='mt-4 text-center'>
        <span className='text-2xl font-bold'>Processing Transaction</span>
      </div>
      {showSteps && <ClaimModalStep onStep={1} />}
      <div className='mt-8 text-center'>
        <span>{`Your Votes are being delegated to: ${
          delegatingToSelf
            ? 'Yourself'
            : delegateByInputWallet
              ? delegateSaved
                ? delegateSaved.name
                : delegateToAddressOrENS
              : delegateInfo
                ? delegateInfo.name
                : delegateToAddressOrENS
        }`}</span>
      </div>
      <div className='mt-8 mb-3'>
        <button className='w-full btn btn-primary' disabled>
          <div className='flex flex-row justify-center items-center'>
            <span>Processing...</span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default DelegatingModal;
