import { Alert, Row } from '@tokensoft-web/common-ui';
import {
  FEATURE,
  getTxUrl,
  useAccount,
  useAnalytics,
  useAuth,
  useNetworks,
  useSubmitRelayerClaim,
  useToast,
  useWallet,
} from '@tokensoft-web/common-utils';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { IoChevronDownCircleOutline } from 'react-icons/io5';
import { VscLinkExternal } from 'react-icons/vsc';
import { encodeFunctionData } from 'viem';
import { useWalletClient } from 'wagmi';
import { getSignatureHash } from '../../services/ethers-service';
import {
  isContinuousVestingType,
  isCrosschainDistributorType,
  isIPriceTierVesting,
  isSatelliteContract,
  isTrancheVestingType,
} from '../../utils/abi';
import { getClaimFunctionDetails, useSubmitClaim } from '../../utils/claim';
import { Sale } from '../../utils/interface';
import { isSmartContractWallet } from '../../utils/wallet';

const ClaimButton = ({
  className,
  claimData,
  features,
  userOnCorrectNetwork,
  sale,
  disabled,
  onMinedTransaction,
  onSubmitTransaction,
  onPendingState,
  text,
}: {
  className?: string;
  key?: number;
  userOnCorrectNetwork?: boolean;
  claimData: {
    chainId: any;
    symbol: string;
    distributorAddress: string;
    saleId?: string;
    interfaces?: any[];
    proof?: string[];
    proofIndex?: number;
    proofAmount?: string;
    recipientDomain?: number;
    beneficiary?: string;
    beneficiaryDomain?: number;
  };
  features?: any[];
  sale?: Sale;
  disabled?: boolean;
  onPendingState?: Function;
  text?: string;
  onMinedTransaction?: Function;
  onSubmitTransaction?: Function;
}) => {
  const [waitingForTransaction, setWaitingForTransaction] = useState(false);
  const [networkingError, setNetworkingError] = useState(false);
  const {
    chainId,
    symbol,
    distributorAddress,
    interfaces,
    proof,
    proofIndex,
    proofAmount,
    recipientDomain,
    beneficiary,
    beneficiaryDomain,
  } = claimData;
  const { connectedChainId, switchChain } = useWallet();
  const {
    user: { walletAddress },
  } = useAuth();
  const { account } = useAccount();
  const { getNetworkDetails } = useNetworks();
  const { showErrorToast, showInfoToast } = useToast();
  const { pageEvent } = useAnalytics();
  const isSmartContract = isSmartContractWallet(
    account?.wallets,
    walletAddress,
  );

  const isCrossChain = isCrosschainDistributorType(interfaces);
  const isSatellite = isSatelliteContract(interfaces);

  const { data: walletClient } = useWalletClient();

  const getDefaultText = (_symbol) =>
    !!_symbol ? `Claim ${_symbol}` : 'Claim Tokens';

  const {
    error,
    isLoading,
    isSubmitting,
    isError,
    transferId,
    write: sendSubmitClaim,
    data: submitClaimReceipt,
  } = useSubmitClaim(interfaces, isSmartContract, walletAddress);

  const { mutate: submitRelayerClaim, isPending: isLoadingSubmitRelayerClaim } =
    useSubmitRelayerClaim();

  useEffect(() => {
    if (submitClaimReceipt) {
      if (submitClaimReceipt.status === 'success') {
        onMinedTransaction && onMinedTransaction(submitClaimReceipt);

        if (sale) {
          pageEvent('purchase', 'submitClaim');
        } else {
          pageEvent('claim', 'submitClaim');
        }
      }
    }
  }, [submitClaimReceipt]);

  useEffect(() => {
    if (error) {
      showErrorToast({
        description: error.toString(),
      });
    }
  }, [error]);

  const showInvalidNetwork = () => {
    const networkDetails = getNetworkDetails(chainId);

    showErrorToast({
      description: (
        <span>
          Please connect to the {networkDetails.name} network to claim your{' '}
          {symbol} tokens
        </span>
      ),
    });
  };

  const getArgsForClaimInterfaceId = async (interfaces: string[]) => {
    if (isSatellite) {
      // Uses the `msg.sender` and `this.domain` to initiate a claim on the distributor via
      // Connext.xcall(...) that sends the funds to the caller on this chain. Should validate
      // the proof despite not enforcing it to fail here rather than on the distributor chain.
      // function initiateClaim(
      //   uint256 _amount,
      //   bytes32[] calldata _proof
      // ) external;
      return [proofAmount, proof];
    }

    if (isCrossChain) {
      if (isSmartContract) {
        // Handles messages dispatched by the `Satellite` contract on the same chain.
        // function claimByMerkleProof(
        //   address beneficiary,
        //   uint256 amount,
        //   bytes32[] memory proof
        // ) external onlySatellite;
        return [walletAddress, proofAmount, proof];
      } else {
        // Handles messages dispatched by EOA user on any chain, to any chain.
        // function claimBySignature(
        //   address recipient,
        //   uint32 recipientDomain,
        //   address beneficiary,
        //   uint32 beneficiaryDomain,
        //   uint256 amount,
        //   bytes calldata signature,
        //   bytes32[] memory proof
        // ) external;
        const txData = [
          { name: 'recipient', type: 'address', value: walletAddress },
          { name: 'recipientDomain', type: 'uint32', value: recipientDomain },
          { name: 'beneficiary', type: 'address', value: beneficiary },
          {
            name: 'beneficiaryDomain',
            type: 'uint32',
            value: beneficiaryDomain,
          },
          { name: 'amount', type: 'uint256', value: proofAmount },
        ];

        const hash = getSignatureHash(txData);
        try {
          const signature = await walletClient.signMessage({
            account: walletAddress,
            message: { raw: hash },
          });

          return [
            walletAddress,
            recipientDomain,
            beneficiary,
            beneficiaryDomain,
            proofAmount,
            signature,
            proof,
          ];
        } catch (e) {
          throw new Error(e.message);
        }
      }
    }

    if (
      isContinuousVestingType(interfaces) ||
      isTrancheVestingType(interfaces) ||
      isIPriceTierVesting(interfaces)
    ) {
      return [proofIndex, walletAddress, proofAmount, proof];
    }

    return [walletAddress];
  };

  const submitClaim = async () => {
    onPendingState && onPendingState(false);
    setNetworkingError(false);
    const isConnextFlow = !!features?.includes(FEATURE.CLAIM_CONNEXT_LAYOUT);
    const isSmartContract = isSmartContractWallet(
      account?.wallets,
      walletAddress,
    );

    if (!userOnCorrectNetwork) {
      return showInvalidNetwork();
    }

    onPendingState && onPendingState(true);
    setWaitingForTransaction(true);

    try {
      const argsData = await getArgsForClaimInterfaceId(interfaces);

      if (isConnextFlow && !isSmartContract) {
        const { abi, functionName } = getClaimFunctionDetails({
          isCrossChain,
          isSatellite,
          isSmartContractwallet: isSmartContract,
        });

        const encodedData = encodeFunctionData({
          abi,
          functionName,
          args: argsData,
        });

        showInfoToast({
          description: <div className='flex flex-row'>Submitting claim.</div>,
        });

        submitRelayerClaim(
          {
            distributorId: distributorAddress,
            chainId: chainId,
            data: encodedData,
          },
          {
            onSuccess: (data) => {
              onPendingState && onPendingState(false);
              setWaitingForTransaction(false);
              onSubmitTransaction && onSubmitTransaction(data?.transactionHash);
            },
            onError: (error) => {
              onPendingState && onPendingState(false);
              const message = error.message?.message || error.message;
              setWaitingForTransaction(false);
              setNetworkingError(message);
              console.error(message);
              showErrorToast({
                description:
                  'The transaction could not be completed, please try again later.',
              });
            },
          },
        );
        console.log(`submitClaim`, argsData);
        return;
      }

      const transactionHash = await sendSubmitClaim(
        connectedChainId,
        distributorAddress,
        argsData,
      );
      setWaitingForTransaction(false);

      if (transactionHash) {
        onSubmitTransaction && onSubmitTransaction(transactionHash);

        showInfoToast({
          description: (
            <div className='flex flex-row'>
              Submitting claim.
              <a
                target='_blank'
                rel='noreferrer'
                href={getTxUrl(
                  transactionHash,
                  getNetworkDetails(connectedChainId),
                )}
                className='w-[30px] flex items-center justify-center text-white'
                onClick={(e) => e.stopPropagation()}
              >
                <VscLinkExternal color='white' />
              </a>
            </div>
          ),
        });
      }
    } catch (e) {
      onPendingState && onPendingState(false);
      setWaitingForTransaction(false);
      console.error(e.message);
      showErrorToast({
        description:
          'The transaction could not be completed, please try again later.',
      });
    }
  };

  const disableClaimButton =
    isLoading ||
    isLoadingSubmitRelayerClaim ||
    disabled ||
    !proofAmount ||
    !switchChain ||
    isSubmitting ||
    waitingForTransaction;
  const showLoadingIndicator =
    (waitingForTransaction ||
      isLoading ||
      isLoadingSubmitRelayerClaim ||
      isSubmitting) &&
    !isError;

  return (
    <>
      {networkingError && (
        <div className='my-4'>
          <Alert type='alert-info light'>
            <Row yalign={'center'}>
              <span>{networkingError}</span>
            </Row>
          </Alert>
        </div>
      )}
      <button
        className={classNames('btn btn-primary btn-xs', className)}
        onClick={submitClaim}
        disabled={disableClaimButton}
      >
        {showLoadingIndicator ? (
          <div className='flex flex-row justify-center items-center'>
            <div className='animate-spin'>
              <AiOutlineLoading3Quarters size={16} />
            </div>
            <span className='pl-2'>Claiming...</span>
          </div>
        ) : (
          <div className='flex flex-row justify-center items-center'>
            <IoChevronDownCircleOutline className='text-xl mr-1' />
            <span className='self-center font-semibold'>
              {text || getDefaultText(symbol)}
            </span>
          </div>
        )}
      </button>
    </>
  );
};

export default ClaimButton;
