import { Helmet } from 'react-helmet';

export const AnalyticsConfiguration = () => {
  return (
    <>
      {/* we can use this later to add the tag mannager call if we want to integrate tracking analysis with tag manager */}
      <Helmet></Helmet>
    </>
  );
};
