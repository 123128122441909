export const isEmptyObject = (obj: null | undefined | Object): boolean => {
  if (!obj) return true;

  return Object.keys(obj).length === 0;
};

export const hasSomeTruthyValue = (obj: null | undefined | Object): boolean => {
  if (!obj) return false;

  return Object.values(obj).some((v) => !!v);
};

/**
 * Filter duplicates out of array based on primary key
 */
export const uniqueValues = (arr) => {
  return arr.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
};

/**
 * Performs a deep merge of objects and returns new object. Does not modify
 * objects (immutable) and merges arrays via concatenation.
 *
 * @param {...object} objects - Objects to merge
 * @returns {object} New object with merged key/values
 */
export const deepMerge = (...objects) => {
  const isObject = (obj) => obj && typeof obj === 'object';

  return objects.reduce(
    (prev, obj) => {
      Object.keys(obj).forEach((key) => {
        const pVal = prev[key];
        const oVal = obj[key];

        if (Array.isArray(pVal) && Array.isArray(oVal)) {
          prev[key] = pVal.concat(...oVal);
        }
        else if (isObject(pVal) && isObject(oVal)) {
          prev[key] = deepMerge(pVal, oVal);
        }
        else {
          prev[key] = oVal;
        }
      });

      return prev;
    },
    {},
  );
};
