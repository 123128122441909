import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useEventApiClient } from '../client/event-api-client';
import { useAuth } from '../context/auth/auth-context';
import { EVENT_TYPE } from '../enum/event-type';
import { useAsync } from '../hook/async';
import { EVENT_STATUS } from '../type/event-status';
import { getUtcNow, isAfter, isBefore } from '../util/date-time';
import { gt, min, mult, sub } from '../util/number';
import { uniqueValues } from '../util/object';
import { EventPrivacyConfiguration } from './../../../payment/src/components/admin/event/event-visibility-options';
import { useGetConfiguration } from './configuration-service';
import { formatDistributor } from './distributor-service';

export const useEventEligibility = (eventId: string, enabled: boolean) => {
  const client = useEventApiClient();
  const {
    user: { walletAddress },
  } = useAuth();

  return useQuery({
    enabled: !!(eventId && enabled),
    queryKey: ['eventId', eventId],
    queryFn: () =>
      client(
        `events/${eventId}/eligibility?cacheId=${eventId}_${walletAddress}`,
      ),
  });
};

export const useGetEvents = (
  eventTypes?: EVENT_TYPE[],
  autoload: boolean = true,
) => {
  const { configuration } = useGetConfiguration();
  const {
    user: { walletAddress },
  } = useAuth();
  const client = useEventApiClient();
  const { run, data } = useAsync();
  const [searchResults, setSearchResults] = useState([]);
  const [moreResults, setMoreResults] = useState(null);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [reload, setReload] = useState(null);

  const PAGE_SIZE: number = 10;

  /**
   * Build API request
   */
  const getClient = (request: any) => {
    let url = 'events/search';

    if (request.nextLink) {
      url += '?' + request.nextLink.split('?')[1];
    } else {
      url += `?offset=0&queryLimit=${PAGE_SIZE}`;
    }

    return client(url, {
      method: 'post',
      data: request.searchRequest,
    });
  };

  useEffect(() => {
    if (!reload) {
      return;
    }

    /* Setup default query parameters */
    run(
      getClient({
        searchRequest: {
          projectId: configuration?.project?.id,
          eventTypes: eventTypes,
        },
      }),
    );
  }, [reload]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data?.events?.length > 0) {
      console.log('Adding new events search results', data);

      const formattedEvents = formatEvents(data);
      setSearchResults(uniqueValues(searchResults.concat(formattedEvents)));

      if (data.nextLink && autoload) {
        setMoreResults(data);
      } else {
        setLoadingComplete(true);
      }
    } else {
      setLoadingComplete(true);
    }
  }, [data]);

  /**
   * Fetch additional page of data
   */
  useEffect(() => {
    if (moreResults) {
      console.log('Fetching more events results:', moreResults);
      run(getClient(moreResults));
    }
  }, [moreResults]);

  const refresh = () => {
    setSearchResults([]);
    setMoreResults(null);
    setLoadingComplete(false);
    setReload(new Date());
  };

  const getMoreEvents = () => {
    setLoadingComplete(false);
    if (data.nextLink) {
      setMoreResults({
        nextLink: data.nextLink,
        searchRequest: {
          projectId: configuration?.project?.id,
          eventTypes: eventTypes,
        },
      });
    }
  };

  useEffect(() => {
    if (configuration && walletAddress) {
      refresh();
    }
  }, [configuration, walletAddress]);

  return {
    results: searchResults,
    loading: !loadingComplete,
    refresh: refresh,
    loadMore: { canLoadMore: !!data?.nextLink, getMoreEvents },
  };
};

export const useGetEvent = (eventId: any) => {
  const client = useEventApiClient();
  const result = useQuery({
    enabled: !!eventId,
    queryKey: ['event', eventId],
    queryFn: () => client(`events/${eventId}`),
  });

  return {
    ...result,
    event: result?.data ? formatEvent(result.data) : undefined,
    loading: result?.isLoading,
    refresh: result?.refetch,
  };
};

export const useGetEventSummary = (eventId: any) => {
  const client = useEventApiClient();
  const result = useQuery({
    enabled: !!eventId,
    queryKey: ['eventSummary', eventId],
    queryFn: () => client(`events/${eventId}/summary`),
  });

  return {
    ...result,
    eventSummary: result?.data?.eventSummary,
    loading: result?.isLoading,
    refresh: result?.refetch,
  };
};

export const useGetMeEvent = (eventId: any) => {
  const client = useEventApiClient();
  const result = useQuery({
    enabled: !!eventId,
    queryKey: ['eventMe', eventId],
    queryFn: () => client(`events/${eventId}/me`),
  });

  return {
    ...result,
    event: result?.data ? formatEvent(result.data) : undefined,
    loading: result?.isLoading,
    refresh: result?.refetch,
  };
};

const formatEvents = (data: any) => {
  return data?.events.map((event) => {
    return formatEvent(event);
  });
};

export const formatEvent = (event: any) => {
  let formattedEvent: any = {
    ...event,
  };

  formattedEvent.status = getEventStatus(event);

  formattedEvent.isRegistrationOpen =
    event?.registrationStartTime &&
    isAfter(getUtcNow(), event.registrationStartTime) &&
    (!event?.registrationEndTime ||
      isBefore(getUtcNow(), event.registrationEndTime));

  if (event.distributor) {
    const distributor = formatDistributor(event.distributor);
    formattedEvent = {
      ...formattedEvent,
      distributor: distributor,
    };
  }

  return formattedEvent;
};

export const getEventStatus = (event: any): EVENT_STATUS => {
  const { startTime, endTime } = event;

  let status = EVENT_STATUS.COMPLETED;
  const now = dayjs();

  if (!startTime && !endTime) {
    return EVENT_STATUS.UPCOMING;
  }

  if (now.isBefore(dayjs(startTime))) {
    status = EVENT_STATUS.UPCOMING;
  } else {
    status = EVENT_STATUS.CURRENT;
    if (now.isAfter(dayjs(endTime))) {
      status = EVENT_STATUS.COMPLETED;
    }
  }

  if (isSaleCapMet(event.sale)) {
    status = EVENT_STATUS.COMPLETED;
  }

  return status;
};

const isSaleCapMet = (sale: any) => {
  if (!sale?.id) {
    return false;
  }

  const limit = sub(
    sale.saleMaximum,
    min(mult(5, sale.userMaximum), mult(0.005, sale.saleMaximum)),
  );
  return gt(sale.purchaseTotal, limit);
};

export const useUpdateEvent = () => {
  const client = useEventApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`events/${data.id}`, {
        method: 'put',
        data,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
  });
};

export const useCreateReferralEvents = () => {
  const client = useEventApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`events/${data.event_id}/referral-codes`, {
        method: 'post',
        data,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
  });
};

export const useUpdateReferralEvents = () => {
  const client = useEventApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`events/${data.event_id}/referral-codes/${data.id}`, {
        method: 'put',
        data,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
  });
};

export const useCreateEvent = () => {
  const client = useEventApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`events/`, {
        method: 'post',
        data,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
  });
};

export const useGetUsersEvent = () => {
  const client = useEventApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`events/users/search`, {
        method: 'post',
        data,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
  });
};

export const useDeleteUserEvent = () => {
  const client = useEventApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`events/${data.eventId}/users/${data.userId}`, {
        method: 'delete',
        data,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
  });
};

export const useRequestAccess = () => {
  const client = useEventApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`events/${data.id}/request-access`, {
        method: 'post',
        data,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
  });
};

export const useGetUserEventsByAuthIdAndProjectId = () => {
  const client = useEventApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(
        `admin/projects/${data.projectId}/users/${data.authId}/events`,
        {
          method: 'get',
        },
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
  });
};

export interface EventParticipantsValidationRequestBody {
  uri: string;
  isWalletRequired: boolean;
  isEmailRequired: boolean;
}

export const useValidatePrivateEventParticipants = () => {
  const client = useEventApiClient();

  return useMutation({
    mutationFn: (data: EventParticipantsValidationRequestBody) => {
      return client(`events/participants/validate`, {
        method: 'post',
        data,
      });
    },
  });
};

export const useGetEventPrivacyConfiguration = (eventId: number): any => {
  const client = useEventApiClient();

  return useQuery({
    enabled: !!eventId,
    queryKey: ['eventPrivacyConfiguration', eventId],
    queryFn: () => client(`events/${eventId}/privacy`),
  });
};

interface UpdateEventPrivacyConfigurationRequest {
  eventId: number;
  privacyConfiguration: EventPrivacyConfiguration;
}

export const useUpdateEventPrivacyConfiguration = () => {
  const client = useEventApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables: UpdateEventPrivacyConfigurationRequest) => {
      const { eventId, privacyConfiguration } = variables;
      return client(`events/${eventId}/privacy`, {
        method: 'put',
        data: privacyConfiguration,
      });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['eventPrivacyConfiguration'],
      }),
  });
};
