import { EVENT_TYPE } from '@tokensoft-web/common-utils';
import { DOCUMENT_CATEGORY } from '../../../utils/enums';
import './event-create-document.css';
import { LaunchEventWizard } from './launch-event-wizard';

export const LaunchSaleEventWizard = () => {
  const eventType = EVENT_TYPE.RAISE;
  const documentCategory = DOCUMENT_CATEGORY.PURCHASE_AGREEMENTS;

  return (
    <LaunchEventWizard
      eventType={eventType}
      documentCategory={documentCategory}
    />
  );
};
