import {
  Button,
  ButtonRow,
  Card,
  EventCountdown,
  Row,
  Stacked,
} from '@tokensoft-web/common-ui';
import {
  localToUtcDateTime,
  useAccount,
  useModal,
} from '@tokensoft-web/common-utils';
import classNames from 'classnames';
import { useState } from 'react';
import EventBodyWrapper from '../../../components/event/event-body-wrapper';
import EventHeader from '../../../components/event/event-header';
import EventHeaderWrapper from '../../../components/event/event-header-wrapper';
import EventContent from '../../event/event-content';

export const PreviewPage = ({
  context,
  onContinue,
}: {
  context: any;
  onContinue?: Function;
}) => {
  const { closeModal } = useModal();
  const { account } = useAccount();
  const [bodyContentDisplay, setBodyContentDisplay] = useState(
    'contentBodyUpcoming',
  );

  const startTime = localToUtcDateTime(context?.startTime, account?.timezone);

  const onContinueHandler = () => {
    onContinue && onContinue();
    closeModal();
  };

  return (
    <Stacked className='mt-10'>
      <Card>
        <Row className='mb-3'>
          {context.contentBodyTabs.map((tab, index) => (
            <Button
              className={classNames(
                'btn-sm btn-tab',
                tab?.value === bodyContentDisplay && 'active',
              )}
              onClick={() => setBodyContentDisplay(tab.value)}
              key={index}
            >
              {tab.label}
            </Button>
          ))}
        </Row>
        <Stacked>
          <EventHeaderWrapper
            backgroundColor={context?.headerBgColor}
            backgroundImg={context.contentHeaderBgImage}
          >
            <EventHeader
              headerTitle={context?.contentHeaderTitle}
              headerSubtitle={context?.contentHeaderSubtitle}
              contentHeaderImage={context?.contentHeaderImage}
              contentHeaderFontColor={context?.contentHeaderFontColor}
              themeLayoutVersion={context?.themeLayoutVersion}
            />
          </EventHeaderWrapper>

          <EventBodyWrapper>
            {bodyContentDisplay === 'contentBodyUpcoming' ? (
              <EventCountdown
                id={'event-countdown-top'}
                startTime={startTime}
                className={''}
                hideEventStatus={true}
              />
            ) : null}

            <EventContent bodyContent={context?.[bodyContentDisplay]} />
          </EventBodyWrapper>
        </Stacked>
      </Card>
      <ButtonRow>
        <Button
          className={'w-full'}
          fill={'outline'}
          onClick={() => closeModal()}
        >
          Continue Editing
        </Button>
        <Button className={'w-full'} onClick={() => onContinueHandler()}>
          Approve & Continue
        </Button>
      </ButtonRow>
    </Stacked>
  );
};
