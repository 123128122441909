import { useMutation } from '@tanstack/react-query';
import { usePaymentApiClient } from '../client/payment-api-client';

export const useCreateS3ProjectResource = (projectId: string) => {
  const client = usePaymentApiClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`resources/s3/project/${projectId}`, {
        method: 'post',
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
  });
};

export const useCreateImageResource = () => {
  const client = usePaymentApiClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`resources`, {
        method: 'post',
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
  });
};
