import classNames from 'classnames';
import { useWizard } from 'react-use-wizard';
import { Button } from '../button/button';
import { Text } from '../typography/text';

export const GoToStepButton = ({
  disabled = false,
  label = 'Continue',
  icon = <></>,
  className = '',
  stepToGo,
  ...restProps
}) => {
  const { goToStep, isLoading } = useWizard();

  return (
    <Button
      className={classNames('md:w-36 w-full', className)}
      onClick={(e) => goToStep(stepToGo)}
      disabled={disabled || isLoading}
      {...restProps}
    >
      <Text>{label}</Text>
      {icon}
    </Button>
  );
};
