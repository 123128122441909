import { ButtonRow, FrontArrowIcon } from '@tokensoft-web/common-ui';
import { useNavigate } from 'react-router-dom';
import { useDistributor } from '../../contexts/distributor/distributor-context';
import { isDistributorDelegationEnabled } from '../../utils/abi';

const ClaimFooter = () => {
  const navigate = useNavigate();
  const { distributor } = useDistributor();
  const delegationEnabled = isDistributorDelegationEnabled(distributor);

  return (
    <>
      <div data-testid='claim-footer' className={'mt-10 w-full'}>
        <ButtonRow place={'between'}>
          {distributor.distributionRecords &&
          distributor.distributionRecords.length !== 0 &&
          delegationEnabled ? (
            <button
              data-testid='claim-footer-governance-action'
              className='btn btn-primary btn-lg ml-auto'
              onClick={() => navigate('/governance')}
            >
              <div className='flex flex-row items-center'>
                <span className='mr-2'>Go to Governance</span>
                <FrontArrowIcon />
              </div>
            </button>
          ) : null}
        </ButtonRow>
      </div>
    </>
  );
};

export default ClaimFooter;
