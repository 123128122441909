import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { Flex } from '../layout/container/flex';
import './squircle.css';

interface SquircleProps {
  children?: ReactNode;
  className?: any;
}

export const Squircle: FC<SquircleProps> = ({ children, className }) => {
  const classes = classNames(
    'squircle inner flex items-center justify-center p-2',
    className,
  );

  return (
    <>
      <Flex place='center'>
        <Flex
          place={'center'}
          className='squircle outer p-1'
          style={{
            clipPath: 'url(#squircleClip)',
          }}
        >
          <Flex place={'center'}>
            <div
              className={classes}
              style={{
                clipPath: 'url(#squircleClip)',
              }}
            >
              {children}
            </div>
          </Flex>
        </Flex>
      </Flex>

      <svg width='10' height='10' viewBox='0 0 10 10'>
        <clipPath id='squircleClip' clipPathUnits='objectBoundingBox'>
          <path
            fill='red'
            stroke='none'
            d='M 0,0.5 C 0,0 0,0 0.5,0 S 1,0 1,0.5 1,1 0.5,1 0,1 0,0.5'
          />
        </clipPath>
      </svg>
    </>
  );
};
