import {
  EligibilityStatus,
  KycStatus,
  NetworkAddress,
} from '@tokensoft-web/common-ui';
import {
  EVENT_TYPE,
  TIER_NAME,
  VERIFICATION_STATUS,
  getVerificationStatusForTier,
  isVerificationStarted,
  useGetUsersEvent,
} from '@tokensoft-web/common-utils';
import { AiFillEye } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import { EventUsersTableHeader } from '../components/admin/event/event-users-table-header';
import { USER_EVENT_TABLE_COLUMNS } from '../utils/enums';

export const generateColumnsCSVDownload = (columnsData) => {
  const columns = columnsData.map((column) => {
    return {
      Header: column.headerText,
      accessor: column.id || column.accessorKey,
    };
  });

  columns.push({
    Header: 'POLKADOT WALLET ADDRESS',
    accessor: 'polkadotWalletAddress',
  });

  return columns;
};

export const EVENT_USER_FILTER_TYPE = {
  TEXT: 'TEXT',
  SELECT: 'SELECT',
  SEARCH_BAR: 'SEARCH_BAR',
};

export enum EVENT_USER_TABLE_CONFIG_TYPES {
  IDENTITY = 'IDENTITY',
}

export const EVENT_USER_TABLE_RELATIONSHIP = {
  IDENTITY: [
    {
      type: EVENT_TYPE.IDENTITY,
      text: 'Users',
      tableConfig: EVENT_USER_TABLE_CONFIG_TYPES.IDENTITY,
    },
  ],
  RAISE: [
    {
      type: EVENT_TYPE.IDENTITY,
      text: 'Users',
      tableConfig: EVENT_USER_TABLE_CONFIG_TYPES.IDENTITY,
    },
  ],
  OTC: [
    {
      type: EVENT_TYPE.OTC,
      text: 'Users',
      tableConfig: EVENT_USER_TABLE_CONFIG_TYPES.IDENTITY,
    },
  ],
  DISTRIBUTE: [
    {
      type: EVENT_TYPE.IDENTITY,
      text: 'Users',
      tableConfig: EVENT_USER_TABLE_CONFIG_TYPES.IDENTITY,
    },
  ],
  GRANT: [
    {
      type: EVENT_TYPE.GRANT,
      text: 'Users',
      tableConfig: EVENT_USER_TABLE_CONFIG_TYPES.IDENTITY,
    },
  ],
  HACK: [
    {
      type: EVENT_TYPE.HACK,
      text: 'Users',
      tableConfig: EVENT_USER_TABLE_CONFIG_TYPES.IDENTITY,
    },
  ],
  SALE: [
    {
      type: EVENT_TYPE.IDENTITY,
      text: 'Users',
      tableConfig: EVENT_USER_TABLE_CONFIG_TYPES.IDENTITY,
    },
  ],
};

export const EVENT_USER_TABLE_CONFIG = {
  IDENTITY: {
    getData: useGetUsersEvent,
    columnsData: (orderBy, handleOrderByChange, disabled) => [
      {
        accessorFn: (row) => row.authId,
        id: 'openDetails',
        cell: (info) => {
          const navigate = useNavigate();
          const { id, eventId } = useParams();
          if (info.getValue()) {
            return (
              <div
                onClick={() =>
                  navigate(
                    `/admin/project/${id}/event/${eventId}/user/${info.getValue()}`,
                  )
                }
                className='text-primary-medium hover:cursor-pointer'
              >
                <AiFillEye size={20} />
              </div>
            );
          } else {
            return <div></div>;
          }
        },
        header: () => <div></div>,
        headerText: 'WALLET',
        footer: (props) => props.column.id,
        size: 30,
      },
      {
        accessorFn: (row) => row.walletAddress,
        id: 'walletAddress',
        cell: (info) => <NetworkAddress address={info.getValue()} />,
        header: () => (
          <EventUsersTableHeader
            content={'WALLET'}
            orderKey='wallet'
            orderList={orderBy}
            orderModify={handleOrderByChange}
            disabled={disabled}
          />
        ),
        headerText: 'WALLET',
        footer: (props) => props.column.id,
        size: 160,
      },

      {
        accessorKey: 'name',
        accessorFn: (row) => row.name,
        cell: (info) => (
          <div className='truncate w-40 capitalize'>
            {info.getValue() || '-'}
          </div>
        ),
        header: () => (
          <EventUsersTableHeader
            content={'NAME'}
            orderKey='name'
            orderList={orderBy}
            orderModify={handleOrderByChange}
            disabled={disabled}
          />
        ),
        footer: (props) => props.column.id,
        headerText: 'NAME',
        size: 70,
        piiBlur: true,
      },
      {
        accessorKey: 'email',
        cell: (info) => info.getValue() || '-',
        header: () => (
          <EventUsersTableHeader
            content={'EMAIL'}
            orderKey='email'
            orderList={orderBy}
            orderModify={handleOrderByChange}
            disabled={disabled}
          />
        ),
        footer: (props) => props.column.id,
        headerText: 'EMAIL',
        size: 120,
        piiBlur: true,
      },
      {
        accessorKey: 'identity',
        // @ts-ignore
        cell: (info) => <KycStatus status={info.getValue()} />,
        header: () => <EventUsersTableHeader content={'IDENTITY'} />,
        footer: (props) => props.column.id,
        headerText: 'IDENTITY',
        size: 100,
        piiBlur: true,
      },
      {
        accessorKey: 'accreditation',
        // @ts-ignore
        cell: (info) => <KycStatus status={info.getValue()} />,
        header: () => <EventUsersTableHeader content={'ACCREDITATION'} />,
        footer: (props) => props.column.id,
        headerText: 'ACCREDITATION',
        size: 120,
        piiBlur: true,
      },
      {
        accessorKey: 'country',
        cell: (info) => <div className=''>{info.getValue() || '-'}</div>,
        header: () => (
          <EventUsersTableHeader
            content={'COUNTRY'}
            orderKey='country'
            orderList={orderBy}
            orderModify={handleOrderByChange}
            disabled={disabled}
          />
        ),
        footer: (props) => props.column.id,
        headerText: 'COUNTRY',
        size: 120,
        piiBlur: true,
      },
      {
        accessorKey: 'eligible',
        cell: (info) => (
          <EligibilityStatus status={info.getValue()}></EligibilityStatus>
        ),
        header: () => <EventUsersTableHeader content={'ELIGIBLE'} />,
        footer: (props) => props.column.id,
        headerText: 'ELIGIBLE',
        size: 120,
      },
    ],
    handleTableData: (data) => {
      return data.accounts.map((acc) => {
        const usAccount = acc.addresses[0].country === 'US';
        const tier1Status = getVerificationStatusForTier(
          acc.kyc.tiers,
          TIER_NAME.IDENTITY,
        );
        const isTier1Started = isVerificationStarted(
          acc.kyc.tiers,
          TIER_NAME.IDENTITY,
        );
        const isTier1Completed = tier1Status === VERIFICATION_STATUS.COMPLETED;

        const tier3Status = getVerificationStatusForTier(
          acc.kyc.tiers,
          TIER_NAME.ACCREDITATION,
        );
        const isTier3Completed = tier3Status === VERIFICATION_STATUS.COMPLETED;
        const isTier3Eligible = usAccount && isTier1Started;

        const kycComplete =
          isTier1Completed &&
          ((isTier3Eligible && isTier3Completed) || !isTier3Eligible);
        const consent = acc.project.userConsent.id;

        return {
          authId: acc.authId,
          email: consent ? acc.email || '-' : '<Private>@Server.com',
          country: consent ? acc.addresses[0].country : 'US',
          lastLoginAt: acc.lastLoginAt,
          accountType:
            acc.investorType == 'MYSELF' ? 'INDIVIDUAL' : acc.investorType,
          kyc: consent ? kycComplete : false,
          identity: consent ? tier1Status : false,
          accreditation: consent ? tier3Status : false,
          eventUserId: acc.eventUser.id,
          name: consent
            ? acc.firstName || acc.lastName
              ? `${acc.firstName} ${acc.lastName}`
              : '-'
            : '<Private>',
          walletAddress: acc.walletAddress,
          polkadotWalletAddress: acc.polkadotWalletAddress,
          referralCode: acc.eventUser.referralCode,
          eligible: acc.eligible,
          consent: consent,
        };
      });
    },
    orderBy: () => {
      return {
        name: {
          sqlOrders: (value) => {
            return [
              {
                name: USER_EVENT_TABLE_COLUMNS.FIRST_NAME,
                direction: value ? 'desc' : 'asc',
              },
              {
                name: USER_EVENT_TABLE_COLUMNS.LAST_NAME,
                direction: value ? 'desc' : 'asc',
              },
            ];
          },
          desc: null,
        },
        wallet: {
          sqlOrders: (value) => {
            return [
              {
                name: USER_EVENT_TABLE_COLUMNS.WALLET_ADDRESS,
                direction: value ? 'desc' : 'asc',
              },
            ];
          },
          desc: null,
        },
        email: {
          sqlOrders: (value) => {
            return [
              {
                name: USER_EVENT_TABLE_COLUMNS.EMAIL,
                direction: value ? 'desc' : 'asc',
              },
            ];
          },
          desc: null,
        },
        country: {
          sqlOrders: (value) => {
            return [
              {
                name: USER_EVENT_TABLE_COLUMNS.COUNTRY,
                direction: value ? 'desc' : 'asc',
              },
            ];
          },
          desc: null,
        },
        eligible: {
          sqlOrders: (value) => {
            return [
              {
                name: USER_EVENT_TABLE_COLUMNS.ELIGIBLE,
                direction: value ? 'desc' : 'asc',
              },
            ];
          },
          desc: null,
        },
      };
    },
    filterBy: (filterDataSet) => {
      return {
        wallet: {
          type: EVENT_USER_FILTER_TYPE.TEXT,
          inTableFilter: 'Wallet',
          searchTerm: null,
          sqlFilters: (value) => {
            return [
              {
                name: USER_EVENT_TABLE_COLUMNS.WALLET_ADDRESS,
                value,
              },
            ];
          },
        },
        identity: {
          type: EVENT_USER_FILTER_TYPE.SELECT,
          inTableFilter: 'Identity',
          searchTerm: null,
          data: {
            dataSet: filterDataSet.kycStatus,
          },
          sqlFilters: (value) => {
            return [
              {
                name: USER_EVENT_TABLE_COLUMNS.IDENTITY,
                value,
              },
            ];
          },
        },
        accreditation: {
          type: EVENT_USER_FILTER_TYPE.SELECT,
          inTableFilter: 'Accreditation',
          searchTerm: null,
          data: {
            dataSet: filterDataSet.kycStatus,
          },
          sqlFilters: (value) => {
            return [
              {
                name: USER_EVENT_TABLE_COLUMNS.ACCREDITATION,
                value,
              },
            ];
          },
        },
        country: {
          type: EVENT_USER_FILTER_TYPE.SELECT,
          inTableFilter: 'Country',
          data: {
            dataSet: filterDataSet.country,
          },
          searchTerm: null,
          sqlFilters: (value) => {
            return [
              {
                name: USER_EVENT_TABLE_COLUMNS.COUNTRY,
                value,
              },
            ];
          },
        },
      };
    },
  },
};
