import classNames from 'classnames';
import { Col } from '../layout/container/col';
import { Text } from '../typography/text';
import './loading-indicator.css';

interface LoadingIndicatorProps {
  id?: string;
  text?: string;
  className?: string;
  color?: string;
}

export const LoadingIndicator = ({
  id,
  text,
  className,
  color,
}: LoadingIndicatorProps) => {
  const key = id || 'loading-indicator';

  return (
    <Col
      data-testid={key}
      gap={2}
      place='center'
      className={classNames('loading-indicator pt-8', className)}
    >
      <div
        data-testid={`${key}-loader`}
        className='pulse-loader mx-auto'
        style={color ? { backgroundColor: color } : {}}
      ></div>
      {text && (
        <Text
          textAlign='center'
          style={color ? { color: color } : {}}
          data-testid={`${key}-text`}
        >
          {text}
        </Text>
      )}
    </Col>
  );
};
