import classNames from 'classnames';
import { Text } from './text';

export const SectionTitle = ({ className = '', children, ...restProps }) => {
  return (
    <Text
      className={classNames('text-lg font-bold high-contrast', className)}
      {...restProps}
    >
      {children}
    </Text>
  );
};
