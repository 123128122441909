import * as Popover from '@radix-ui/react-popover';
import {
  useNotifications,
  useRemoveAllNotifications,
  useRemoveNotification,
  useToast,
} from '@tokensoft-web/common-utils';
import { FaBell } from 'react-icons/fa';
import { BADGE, Badge } from '../../badge/badge';
import { NotificationListItem } from './notification-list-item/notification-list-item';
import './notification-popover.css';

export const NotificationPopover = () => {
  const { notifications } = useNotifications();
  const { showErrorToast } = useToast();

  const { mutateAsync: removeNotification } = useRemoveNotification();
  const { mutateAsync: removeAllNotifications } = useRemoveAllNotifications();

  const deleteNotification = async (id: string) => {
    try {
      await removeNotification(id);
    } catch (e) {
      showErrorToast({ description: 'Error clearing notification' });
    }
  };

  const deleteAllNotifications = async () => {
    try {
      await removeAllNotifications();
    } catch (e) {
      showErrorToast({ description: 'Error clearing notifications' });
    }
  };

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <div className='nav-link'>
          {notifications && notifications.length > 0 ? (
            <Badge
              name={BADGE.VALUE}
              className='cursor-pointer'
              value={notifications.length}
            >
              <a
                className='nav-link ai-icon'
                href='#'
                role='button'
                data-bs-toggle='dropdown'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='30'
                  height='30'
                  viewBox='0 0 30 30'
                  fill='none'
                >
                  <path
                    d='M14.9931 6.3907e-06C11.0167 6.3907e-06 7.20312 1.57963 4.39137 4.39138C1.57962 7.20312 0 11.0167 0 14.9931C0 18.9695 1.57962 22.783 4.39137 25.5948C7.20312 28.4065 11.0167 29.9862 14.9931 29.9862C15.4961 29.9862 15.9933 29.9605 16.4845 29.9132C20.3153 29.5338 23.8534 27.6955 26.366 24.779C28.8786 21.8624 30.1731 18.0912 29.9814 14.2465C29.7897 10.4017 28.1265 6.77796 25.3363 4.12585C22.5461 1.47373 18.8426 -0.00354565 14.9931 6.3907e-06ZM14.9931 23.8706C14.5103 23.87 14.0475 23.678 13.7061 23.3367C13.3647 22.9953 13.1727 22.5325 13.1722 22.0497H16.8139C16.8134 22.5325 16.6214 22.9953 16.28 23.3367C15.9387 23.678 15.4758 23.87 14.9931 23.8706ZM22.2766 21.1402H7.7096V20.2288L9.53047 18.4079V13.8528C9.53047 11.0554 11.0179 8.72361 13.6279 8.10218V7.48076C13.6279 7.1187 13.7717 6.77146 14.0278 6.51545C14.2838 6.25943 14.631 6.1156 14.9931 6.1156C15.3551 6.1156 15.7024 6.25943 15.9584 6.51545C16.2144 6.77146 16.3582 7.1187 16.3582 7.48076V8.10218C18.9682 8.72163 20.4557 11.0613 20.4557 13.8528V18.4079L22.2766 20.2288V21.1402Z'
                    fill='var(--cool-grey-medium)'
                  />
                </svg>
              </a>
            </Badge>
          ) : (
            <a
              className='nav-link ai-icon'
              href='#'
              role='button'
              data-bs-toggle='dropdown'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='30'
                height='30'
                viewBox='0 0 30 30'
                fill='none'
              >
                <path
                  d='M14.9931 6.3907e-06C11.0167 6.3907e-06 7.20312 1.57963 4.39137 4.39138C1.57962 7.20312 0 11.0167 0 14.9931C0 18.9695 1.57962 22.783 4.39137 25.5948C7.20312 28.4065 11.0167 29.9862 14.9931 29.9862C15.4961 29.9862 15.9933 29.9605 16.4845 29.9132C20.3153 29.5338 23.8534 27.6955 26.366 24.779C28.8786 21.8624 30.1731 18.0912 29.9814 14.2465C29.7897 10.4017 28.1265 6.77796 25.3363 4.12585C22.5461 1.47373 18.8426 -0.00354565 14.9931 6.3907e-06ZM14.9931 23.8706C14.5103 23.87 14.0475 23.678 13.7061 23.3367C13.3647 22.9953 13.1727 22.5325 13.1722 22.0497H16.8139C16.8134 22.5325 16.6214 22.9953 16.28 23.3367C15.9387 23.678 15.4758 23.87 14.9931 23.8706ZM22.2766 21.1402H7.7096V20.2288L9.53047 18.4079V13.8528C9.53047 11.0554 11.0179 8.72361 13.6279 8.10218V7.48076C13.6279 7.1187 13.7717 6.77146 14.0278 6.51545C14.2838 6.25943 14.631 6.1156 14.9931 6.1156C15.3551 6.1156 15.7024 6.25943 15.9584 6.51545C16.2144 6.77146 16.3582 7.1187 16.3582 7.48076V8.10218C18.9682 8.72163 20.4557 11.0613 20.4557 13.8528V18.4079L22.2766 20.2288V21.1402Z'
                  fill='var(--cool-grey-medium)'
                />
              </svg>
            </a>
          )}
        </div>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content className='notification-popover-panel' sideOffset={5}>
          <div className='notification-popover-container'>
            <div className='notification-items-container'>
              {notifications?.length > 0 && (
                <div
                  className='notification-clear-all'
                  onClick={deleteAllNotifications}
                >
                  Clear All
                </div>
              )}
              {notifications?.length > 0 ? (
                notifications.map((notification: any, i: number) => (
                  <NotificationListItem
                    key={i}
                    notification={notification}
                    onDelete={() => deleteNotification(notification.id)}
                  />
                ))
              ) : (
                <>
                  <div className='notification-no-records-found-container'>
                    <div className='notification-no-records-found-icon'>
                      <FaBell size={36} />
                    </div>
                    <p className='notification-no-records-found-text'>
                      No new notifications
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
