import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { SiweMessage } from 'siwe';
import {
  useSignMessage,
  useSwitchChain,
  useAccount as useWagmiAccount,
} from 'wagmi';
import {
  useGetNonce,
  useGetToken,
  useRefreshToken,
} from '../../service/authentication-service';
import { getProjectPrivacyDocumentsSync } from '../../service/project-service';
import { getSignStatement } from '../../util/project';
import { useAnalytics } from '../analytics/analytics-context';
import { useAuth } from '../auth/auth-context';
import { useConfiguration } from '../configuration/configuration-context';
import { useNetworks } from '../network/network-context';
import { WalletContext } from './wallet-context';

export interface WalletProviderProps {
  children?: ReactNode;
}

const useIsFirstRender = () => {
  const firstRender = useRef(true);
  useEffect(() => {
    firstRender.current = false;
  }, []);
  return firstRender.current;
};

export const WalletProvider: FC<WalletProviderProps> = ({ children }) => {
  const { configuration } = useConfiguration();
  const isFirstRender = useIsFirstRender();
  const { disconnect, user, authenticate } = useAuth();
  const { getNetworkDetails } = useNetworks();
  const { pageEvent } = useAnalytics();
  const { chain, address, isDisconnected, isConnected } = useWagmiAccount();
  const [supportedNetwork, setSupportedNetwork] = useState(true);
  const [addWalletMode, setAddWalletMode] = useState(false);
  const [provideAuthId, setProvideAuthId] = useState(false);
  const [lastMessage, setLastMessage] = useState<SiweMessage>(null);

  const {
    mutate: getNonce,
    data: nonceResponse,
    isPending: getNonceLoading,
  } = useGetNonce();

  const {
    data: signature,
    error: signatureError,
    signMessage,
    isPending: signaturePending,
  } = useSignMessage();

  const {
    mutate: getToken,
    data: tokenResponse,
    isSuccess: getTokenSuccess,
    isPending: getTokenLoading,
  } = useGetToken();

  const { mutate: refreshToken } = useRefreshToken();

  // exclude firing this on initial load
  // also: /me, /configuration, /notifications need to quiet down - see if we can use react query settings
  // like staleTime/cacheTime and use of invalidate/refetch queries
  // turn infura and alchemy providers back on in prod
  // eventually we want to make a key for each that is used just for the wagmi provider so we can track usage separately

  useEffect(() => {
    if (address && !isFirstRender) {
      if (!addWalletMode) {
        // when we aren't in add wallet mode, we support users switching between their known wallets without signing another message.
        if (isConnected && !getNonceLoading) {
          if (
            user.walletAddresses
              ?.map(({ walletAddress }) => walletAddress)
              .includes(address.toLowerCase())
          ) {
            // no need to sign a message if the user is connecting to one of their other wallets.
            console.log(
              'provider: connecting with an existing wallet address',
              address,
            );
            refreshToken(
              { walletAddress: address, token: user.token },
              {
                onSuccess: (data) => {
                  authenticate({
                    ...user,
                    walletAddress: address,
                    token: data.token,
                    walletAddresses: data.walletAddresses,
                  });
                },
              },
            );
          } else {
            // if we don't recognize the wallet we must force the user to reconnect
            console.log(
              'provider: connecting with unknown wallet address',
              address,
            );
            handleDisconnect();
          }
        }
      }
    }
  }, [address]);

  useEffect(() => {
    if (address && nonceResponse?.nonce) {
      console.log(
        'provider: requesting new signature with nonce',
        nonceResponse.nonce,
      );
      if (
        nonceResponse.nonce.length &&
        address !== user.walletAddress &&
        !signaturePending
      ) {
        const issuer = configuration?.project?.name;
        const documents = getProjectPrivacyDocumentsSync(
          configuration?.project?.id,
        );

        const message = new SiweMessage({
          domain: window.location.host,
          address: address,
          statement: getSignStatement(issuer, documents, window.location.host),
          uri: window.location.origin,
          version: '1',
          chainId: chain?.id,
          nonce: nonceResponse.nonce,
        });
        signMessage({ account: address, message: message.prepareMessage() });
        setLastMessage(message);
      }
    }
  }, [nonceResponse, address]);

  useEffect(() => {
    // signatures should only arrive when in add wallet mode
    if (signature && address) {
      console.log('provider: incoming signature response');
      if (address !== user.walletAddress && !getTokenLoading) {
        getToken({
          walletAddress: address,
          signature,
          message: lastMessage,
          nonce: nonceResponse.nonce,
          ...(provideAuthId && { authId: user.authId }),
        });
        setProvideAuthId(false);
      }
    }
  }, [signature]);

  useEffect(() => {
    // signature errors should only happen in add wallet mode
    if (signatureError) {
      console.log('provider: error signing message', signatureError);
      handleDisconnect();
    }
  }, [signatureError]);

  useEffect(() => {
    // token responses should only happen in add wallet mode
    if (tokenResponse) {
      console.log('provider: incoming token response');
      if (getTokenSuccess && address !== user.walletAddress) {
        authenticate({ ...tokenResponse, walletAddress: address });
      }
    }
  }, [tokenResponse]);

  useEffect(() => {
    if (isDisconnected) {
      console.log('provider: wallet address disconnected');
      handleDisconnect();
    }
  }, [isDisconnected]);

  /**
   * Handle changes to selected network
   */
  const { switchChain } = useSwitchChain({
    mutation: {
      onSuccess(data) {
        console.log('provider: switched to network', data?.id);
        pageEvent('wallet', 'changeNetwork', data?.id);
        const supportedNetworks = getNetworkDetails(data?.id);
        setSupportedNetwork(!!supportedNetworks?.id);
      },
    },
  });

  /**
   * Handle disconnect events
   */
  const handleDisconnect = async () => {
    try {
      console.log('provider: disconnecting...');
      disconnect();
    } catch (e) {
      console.log({ e });
    }
  };

  /**
   * Handle manual switch chain events
   */
  const handleChainSwitch = (
    chainId: number,
    options?: Parameters<typeof switchChain>[1],
  ) => {
    switchChain({ chainId }, options);
  };

  /**
   * Handle manual connect events
   */
  const handleConnect = async (transferWallet?: boolean) => {
    console.log(
      'provider: connecting wallet with transfer mode',
      transferWallet,
    );
    if (transferWallet) {
      setProvideAuthId(true);
    }

    return getNonce(address);
  };

  const handleEnableAddWalletMode = () => {
    console.log('provider: enabling add wallet mode');
    setAddWalletMode(true);
  };

  const handleDisableAddWalletMode = () => {
    console.log('disabling add wallet mode');
    setAddWalletMode(false);
  };

  return (
    <>
      <WalletContext.Provider
        value={{
          connectedChainId: chain?.id,
          supportedNetwork: supportedNetwork,
          account: address,
          network: chain,
          connectWallet: handleConnect,
          disconnectWallet: handleDisconnect,
          switchChain: handleChainSwitch,
          enableAddWalletMode: handleEnableAddWalletMode,
          disableAddWalletMode: handleDisableAddWalletMode,
        }}
      >
        {children}
      </WalletContext.Provider>
    </>
  );
};
