import { LoadingIndicator } from '@tokensoft-web/common-ui';
import { FC } from 'react';
import { Transaction } from '../../utils/interface';
import TransactionsTableRow from './transactions-table-row';

interface TransactionsTableProps {
  transactions: any[];
  purchasesLoading?: boolean;
  claimsLoading?: boolean;
}

const TransactionsTable: FC<TransactionsTableProps> = ({
  transactions,
  purchasesLoading,
  claimsLoading,
}) => {
  return (
    <table className='w-full'>
      <thead>
        <tr>
          <th
            className='text-left border-b high-contrast align-top inline-block lg:hidden'
            style={{ borderBottom: '0' }}
            colSpan={4}
          >
            Transactions
          </th>
          <th
            className='text-left border-b high-contrast align-top hidden lg:inline-block'
            style={{ width: '15%', paddingRight: '16px' }}
          >
            ID
          </th>
          <th
            className='text-left border-b high-contrast align-top hidden lg:inline-block'
            style={{ width: '40%', paddingRight: '16px' }}
          >
            Description
          </th>
          <th
            className='text-left border-b high-contrast align-top hidden lg:inline-block'
            style={{ width: '30%', paddingRight: '16px' }}
          >
            Date
          </th>
          <th
            className='text-left border-b high-contrast align-top hidden lg:inline-block'
            style={{ width: '15%', paddingRight: '16px' }}
          >
            Documents
          </th>
        </tr>
      </thead>
      <tbody>
        {transactions?.length > 0 ? (
          <>
            {transactions?.map((transaction: Transaction, i: number) => (
              <TransactionsTableRow key={i} transaction={transaction} />
            ))}
          </>
        ) : null}

        {(purchasesLoading || claimsLoading) && (
          <tr>
            <td colSpan={4}>
              <LoadingIndicator></LoadingIndicator>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default TransactionsTable;
