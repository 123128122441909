import {
  AnimatedCheckmark,
  Button,
  Col,
  ModalTitle,
  Row,
  Stacked,
  SweepIcon,
  TableData,
  Text,
} from '@tokensoft-web/common-ui';
import {
  convertBaseUnitsToDecimal,
  getTruncatedAddress,
  getTxUrl,
  useAuth,
  useGetSaleContractOwner,
  useModal,
  useNetworks,
  useToast,
  useWallet,
} from '@tokensoft-web/common-utils';
import { useGetContractConfig } from '@tokensoft-web/common-utils/src/service/contract-service';
import { useEffect, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { VscLinkExternal } from 'react-icons/vsc';
import { getFlatPriceSaleABI } from '../../../utils/abi';
import { getAddressUrl } from '../../../utils/network';
import { useSweepNative, useSweepToken } from '../../../utils/sale';

export enum FUND_TYPE {
  DEPOSIT,
  WITHDRAW,
}

interface SweepSaleTokenModalProps {
  sale: any;
  paymentMethod: any;
}

export const SweepSaleTokenModal = ({
  sale,
  paymentMethod,
}: SweepSaleTokenModalProps) => {
  const {
    user: { walletAddress },
  } = useAuth();
  const { getNetworkDetails } = useNetworks();
  const { closeModal, setModal, modal } = useModal();
  const { connectedChainId, switchChain } = useWallet();
  const [userOnCorrectNetwork, setUserOnCorrectNetwork] = useState(true);
  const {
    symbol: nativeSymbol,
    name: networkName,
    logoUri: networkLogo,
  } = getNetworkDetails(sale.networkId);
  const { showSuccessToast, showErrorToast } = useToast();
  const [amountInput, setAmountInput] = useState(null);
  const network = getNetworkDetails(sale.networkId);
  const saleOwner = useGetSaleContractOwner(sale);

  const {
    write: sendSweepNative,
    data: receiptNative,
    error: errorNative,
    isError: isErrorNative,
    isLoading: isLoadingNative,
    isSubmitting: isSubmittingNative,
  } = useSweepNative();

  const {
    write: sendSweepToken,
    data: receiptToken,
    error: errorToken,
    isError: isErrorToken,
    isLoading: isLoadingToken,
    isSubmitting: isSubmittingToken,
  } = useSweepToken();
  const receipt = receiptNative || receiptToken;

  useEffect(() => {
    if (receipt?.blockHash) {
      setModal({
        ...modal,
        disableClose: true,
      });
    }
  }, [receipt?.blockHash]);

  const handleCloseModal = () => {
    window.location.reload();
    closeModal();
  };

  useEffect(() => {
    if (connectedChainId) {
      setUserOnCorrectNetwork(connectedChainId === sale.networkId);
    }
  }, [connectedChainId]);

  const key = 'payment-method-balance';
  const title = paymentMethod.native ? 'Sweep Native Tokens' : 'Sweep Token';

  useEffect(() => {
    if (receipt) {
      let titleText = paymentMethod.native
        ? 'Sweep Native Tokens'
        : 'Sweep Token';
      showSuccessToast({
        description: (
          <div className='flex flex-row'>
            {`Successfully submitted ${titleText}.`}
            <a
              target='_blank'
              rel='noreferrer'
              href={getTxUrl(
                receipt.transactionHash,
                getNetworkDetails(sale.networkId),
              )}
              className='w-[30px] flex items-center justify-center text-white'
              onClick={(e) => e.stopPropagation()}
            >
              <VscLinkExternal color='white' />
            </a>
          </div>
        ),
      });
    }
  }, [receipt]);

  const handleAction = () => {
    if (paymentMethod.native) {
      sendSweepNative(sale.chainId, sale.id, sale.proof, sale.recipient);
    } else {
      sendSweepToken(sale.chainId, paymentMethod.token, sale.id, sale.proof);
    }
  };

  useEffect(() => {
    const error = errorNative || errorToken;
    if (error) {
      console.error(error);
      showErrorToast({
        description:
          'The transaction could not be completed, please try again later.',
      });
    }
  }, [errorNative, errorToken]);

  const { config, isPending: isConfigPending } = useGetContractConfig(
    sale.id,
    sale.chainId,
    getFlatPriceSaleABI(),
  );
  const feeRecipientAddress = config ? config[0] : '';

  const renderBody = () => {
    if (!userOnCorrectNetwork) {
      return (
        <Col gap={5} place={'center'}>
          <ModalTitle>{title}</ModalTitle>
          <Text>Please switch to the {network.name} network to continue.</Text>
          <Button
            color='primary'
            className='w-full'
            onClick={() => {
              switchChain(sale.networkId);
            }}
          >
            <Text>Switch Network</Text>
          </Button>
        </Col>
      );
    }

    if (receipt?.blockHash) {
      return (
        <Col gap={5} place={'center'}>
          <AnimatedCheckmark size={'large'} />
          <ModalTitle>{title}</ModalTitle>
          <Text width='3/4' textAlign={'center'}>
            Your request to {title.toLowerCase()} was a success.
          </Text>
          <Button
            color={'primary'}
            className='w-full'
            onClick={handleCloseModal}
          >
            <Text>Finish</Text>
          </Button>
        </Col>
      );
    }

    // Check if the owner address has been loaded
    if (saleOwner === null) {
      return (
        <Col gap={5} place={'center'} className="p-8">
          <ModalTitle>{title}</ModalTitle>
          <Text>Loading sale owner information...</Text>
        </Col>
      );
    }

    // For withdrawal, verify that the current user is the owner of the sale contract
    if (saleOwner.toLowerCase() !== walletAddress?.toLowerCase()) {
      return (
        <Col gap={5} place={'center'}>
          <ModalTitle>{title}</ModalTitle>
          <Text>
            You are not the owner of this Sale contract. Only the owner can
            sweep tokens.
          </Text>
          <Button
            color={'primary'}
            className='w-full'
            onClick={() => closeModal()}
          >
            <Text>Finish</Text>
          </Button>
        </Col>
      );
    }

    return (
      <Col place={'center'} className={'w-128 p-4'} gap={5}>
        <ModalTitle>{title}</ModalTitle>
        <div className='text-white flex justify-center items-center rounded-full bg-primary-medium w-20 h-20'>
          {<SweepIcon size={40} />}
        </div>
        <TableData data-testid={`${key}-total-data-sweep-modal`}>
          {isConfigPending ? (
            <span>---</span>
          ) : (
            <Row className='flex flex-row gap-1 justify-center'>
              You will be sweeping
              <span>
                {` ${convertBaseUnitsToDecimal(
                  paymentMethod.balance,
                  paymentMethod.decimals,
                )} ${paymentMethod.native ? nativeSymbol : paymentMethod.symbol}`}
              </span>
              <span>from sale contract</span>
              <span>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href={getAddressUrl(
                    sale.id,
                    getNetworkDetails(sale.networkId),
                  )}
                >
                  {getTruncatedAddress(sale.id)}
                </a>
              </span>
              <span>to wallet {getTruncatedAddress(feeRecipientAddress)}</span>
            </Row>
          )}
        </TableData>
        <Button
          className='w-full btn-primary'
          onClick={() => handleAction()}
          disabled={
            isLoadingToken ||
            isLoadingNative ||
            isSubmittingToken ||
            isSubmittingNative
          }
        >
          {isLoadingToken ||
          isLoadingNative ||
          isSubmittingToken ||
          isSubmittingNative ? (
            <>
              <div className='animate-spin'>
                <AiOutlineLoading3Quarters size={24} />
              </div>

              <Text>Sweeping</Text>
            </>
          ) : (
            <Text>Submit Sweep</Text>
          )}
        </Button>
      </Col>
    );
  };

  return <Stacked>{renderBody()}</Stacked>;
};
