import { useConfiguration } from '@tokensoft-web/common-utils';
import { FaDiscord, FaGlobe, FaTelegram } from 'react-icons/fa';
import './footer.css';

const TwitterIcon = () => {
  return (
    <div>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 512 512'
        style={{ width: '16px', height: '16px' }}
      >
        <path d='M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z' />
      </svg>
    </div>
  );
};

export const Footer = () => {
  const { configuration } = useConfiguration();

  let twitter = 'https://twitter.com/tokensoftinc';
  let website = 'https://www.tokensoft.io';
  let telegram = 'https://t.me/tokensoft';
  let discord = 'https://discord.gg/tokensoft';

  if (configuration?.project) {
    website = configuration.project.website || null;
    twitter = configuration.project.twitter || null;
    telegram = configuration.project.telegram || null;
    discord = configuration.project.discord || null;
  }

  return (
    <div className='footer'>
      <div className={'flex flex-row gap-4 justify-center'}>
        {website ? (
          <div>
            <a href={website} target={'_blank'} rel='noreferrer'>
              <FaGlobe />
            </a>
          </div>
        ) : null}
        {twitter ? (
          <div>
            <a href={twitter} target={'_blank'} rel='noreferrer'>
              <TwitterIcon />
            </a>
          </div>
        ) : null}
        {telegram ? (
          <div>
            <a href={telegram} target={'_blank'} rel='noreferrer'>
              <FaTelegram />
            </a>
          </div>
        ) : null}
        {discord ? (
          <div>
            <a href={discord} target={'_blank'} rel='noreferrer'>
              <FaDiscord />
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
};
