import {
  Button,
  Flex,
  PageHeader,
  Row,
  SectionTitle,
  Stacked,
  Stretched,
  Text,
} from '@tokensoft-web/common-ui';
import { useAnalytics } from '@tokensoft-web/common-utils';
import { useNavigate } from 'react-router-dom';
import Events from '../components/event/events';

const Dashboard = () => {
  useAnalytics('/dashboard');
  const navigate = useNavigate();

  return (
    <>
      <PageHeader title={'Dashboard'} />

      <Stacked>
        <Stretched xalign={'between'} ygap={5}>
          <Flex width={'full'} yalign={'center'}>
            <SectionTitle>Active & Upcoming Events</SectionTitle>
          </Flex>

          <Row className='justify-start md:justify-end' gap={2.5}>
            <Button size={'sm'} onClick={() => {}}>
              <>
                <Text>Events</Text>
              </>
            </Button>

            <Button
              fill={'outline'}
              size={'sm'}
              onClick={() => {
                navigate(`/purchases`);
              }}
            >
              <>
                <Text>Purchases</Text>
              </>
            </Button>
          </Row>
        </Stretched>

        <Events />
      </Stacked>
    </>
  );
};

export default Dashboard;
