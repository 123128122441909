import { Card, InputGroup } from '@tokensoft-web/common-ui';
import {
  AccountDetails,
  INVESTOR_TYPE,
  formatAccountDetailsAddress,
  isEntity,
  utcToLocalDate,
} from '@tokensoft-web/common-utils';
import { FC } from 'react';

type ReviewPanelProps = {
  accountDetails?: AccountDetails;
};
export const ReviewPanel: FC<ReviewPanelProps> = ({ accountDetails }) => {
  return (
    <>
      <Card title='Applicant Summary' className='vertical short'>
        <InputGroup
          label='Name'
          value={`${accountDetails?.firstName}${
            accountDetails?.middleName
              ? ' ' + accountDetails?.middleName + ' '
              : ' '
          }${accountDetails?.lastName}`}
          readonly={true}
        />

        <InputGroup
          label='Date of birth'
          value={utcToLocalDate(accountDetails?.dob)}
          readonly={true}
        />

        <InputGroup
          label='Phone number'
          value={accountDetails?.phoneNumber}
          readonly={true}
        />

        <InputGroup
          label='Email address'
          value={accountDetails?.email}
          readonly={true}
        />
        <InputGroup
          label='Permanent address'
          value={formatAccountDetailsAddress(
            accountDetails,
            INVESTOR_TYPE.MYSELF,
          ).join(' ')}
          readonly={true}
        />
      </Card>

      {isEntity(accountDetails) && (
        <Card title='Entity Summary' className='vertical short'>
          <InputGroup
            label='Name'
            value={accountDetails?.entityName}
            readonly={true}
          />
          {accountDetails?.entityDba && (
            <InputGroup
              label='DBA name'
              value={accountDetails?.entityDba}
              readonly={true}
            />
          )}
          <InputGroup
            label='Formation date'
            value={utcToLocalDate(accountDetails?.entityFormationDate)}
            readonly={true}
          />
          <InputGroup
            label='EIN / TIN'
            value={accountDetails?.entityTaxId}
            readonly={true}
          />
          <InputGroup
            label='Address'
            value={formatAccountDetailsAddress(
              accountDetails,
              INVESTOR_TYPE.ENTITY,
            ).join(' ')}
            readonly={true}
          />
        </Card>
      )}
    </>
  );
};
