import { FC } from 'react';
import './trading-icon.css';

interface TradingIconProps {
  className?: string;
}

export const TradingIcon: FC<TradingIconProps> = ({ className }) => {
  if (className?.includes('large')) {
    return (
      <svg
        className={className}
        width='40'
        height='40'
        viewBox='0 0 40 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M20 0C8.95439 0 0 8.95439 0 20C0 31.0456 8.95439 40 20 40C31.0456 40 40 31.0456 40 20C40 8.95439 31.0456 0 20 0ZM18.1788 29.5429C18.1788 30.2356 17.6176 30.7968 16.9249 30.7968C16.2322 30.7968 15.6709 30.2356 15.6709 29.5429V13.5676L11.9537 17.4305C11.4734 17.929 10.6806 17.9442 10.181 17.4649C9.68248 16.9845 9.66731 16.1917 10.1466 15.6922L16.0208 9.58944C16.3758 9.22136 16.9188 9.10507 17.3921 9.29619C17.8663 9.48731 18.1768 9.94742 18.1768 10.4591V29.545L18.1788 29.5429ZM29.8503 24.3088L23.9761 30.4116C23.6212 30.7797 23.0782 30.8959 22.6049 30.7048C22.1306 30.5137 21.8202 30.0536 21.8202 29.5419V10.4571C21.8202 9.76439 22.3814 9.20316 23.0741 9.20316C23.7668 9.20316 24.328 9.76439 24.328 10.4571V26.4334L28.0453 22.5705C28.5256 22.072 29.3184 22.0568 29.818 22.5362C30.3165 23.0165 30.3317 23.8093 29.8524 24.3088H29.8503Z' />
      </svg>
    );
  }

  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M9 0C4.02948 0 0 4.02948 0 9C0 13.9705 4.02948 18 9 18C13.9705 18 18 13.9705 18 9C18 4.02948 13.9705 0 9 0ZM8.18045 13.2943C8.18045 13.606 7.9279 13.8586 7.61619 13.8586C7.30448 13.8586 7.05193 13.606 7.05193 13.2943V6.10542L5.37916 7.84372C5.16301 8.06806 4.80625 8.07488 4.58145 7.85919C4.35711 7.64304 4.35029 7.28628 4.56598 7.06148L7.20937 4.31525C7.3691 4.14961 7.61346 4.09728 7.82642 4.18328C8.03984 4.26929 8.17954 4.47634 8.17954 4.70659V13.2952L8.18045 13.2943ZM13.4327 10.939L10.7893 13.6852C10.6295 13.8508 10.3852 13.9032 10.1722 13.8172C9.95879 13.7312 9.81909 13.5241 9.81909 13.2939V4.70568C9.81909 4.39397 10.0716 4.14142 10.3834 4.14142C10.6951 4.14142 10.9476 4.39397 10.9476 4.70568V11.895L12.6204 10.1567C12.8365 9.9324 13.1933 9.92557 13.4181 10.1413C13.6424 10.3574 13.6493 10.7142 13.4336 10.939H13.4327Z' />
    </svg>
  );
};
