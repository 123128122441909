import {
  AnimatedCheckmark,
  Button,
  Col,
  FrontArrowIcon,
  ModalTitle,
  PreparingIcon,
  Row,
  Text,
} from '@tokensoft-web/common-ui';
import {
  ETHEREUM_LOGO_URL,
  NETWORK_NAME,
  POLKADOT_LOGO_URL,
  User,
  useModal,
} from '@tokensoft-web/common-utils';
import classNames from 'classnames';
import { useState } from 'react';
import ModalStep from '../modal-step/modal-step';
import './add-wallet-modal.css';
import { DotAddWalletSelection } from './dot-add-wallet-selection';
import { EthAddWalletModal } from './eth-add-wallet-modal';

export enum MODAL_STEP {
  SELECTION = 'SELECTION',
  ETH = 'ETH',
  DOT = 'DOT',
  SUCCESS = 'SUCCESS',
}

export function AddWalletModal({
  onComplete,
  initialUser,
  onStep = MODAL_STEP.SELECTION,
}: {
  onComplete: Function;
  initialUser: User;
  onStep?: MODAL_STEP;
}) {
  const { closeModal } = useModal();
  const [modalStep, setModalStep] = useState<MODAL_STEP>(onStep);

  const blockchains = [
    {
      id: MODAL_STEP.ETH,
      logoUri: ETHEREUM_LOGO_URL,
      name: NETWORK_NAME.ETHEREUM,
    },
    {
      id: MODAL_STEP.DOT,
      logoUri: POLKADOT_LOGO_URL,
      name: NETWORK_NAME.POLKADOT,
    },
  ];
  const [selectedBlockchain, setSelectedBlockchain] = useState(blockchains[0]);

  const handleBlockchainSelect = (blockchain) => {
    setSelectedBlockchain(blockchain);
  };

  const handleNext = () => {
    if (selectedBlockchain.id === MODAL_STEP.ETH) {
      setModalStep(MODAL_STEP.ETH);
    }

    if (selectedBlockchain.id === MODAL_STEP.DOT) {
      setModalStep(MODAL_STEP.DOT);
    }
  };

  const handleAddWalletComplete = () => {
    onComplete && onComplete();
    setModalStep(MODAL_STEP.SUCCESS);
  };

  const renderModalStep = () => {
    if (modalStep === MODAL_STEP.SELECTION) {
      return (
        <>
          <PreparingIcon />
          <Row place='center'>
            <ModalTitle>Add New Wallet Address</ModalTitle>
          </Row>

          <div>
            <ModalStep
              activeStep={1}
              dividerWidth='w-0'
              steps={['Choose a Chain', 'Connect Wallet']}
            />
          </div>

          <Text textAlign='center'>
            Please select the chain of the wallet you would like to add to your
            profile.
          </Text>

          <Col width='full' gap={2.5}>
            {blockchains.map((blockchain, i) => (
              <Row
                key={i}
                gap={2.5}
                className={classNames(
                  'add-wallet-network-selection',
                  selectedBlockchain?.id === blockchain.id
                    ? 'add-wallet-selected-network'
                    : '',
                )}
                onClick={() => handleBlockchainSelect(blockchain)}
              >
                <img src={blockchain.logoUri} width='28' alt='' />
                <Text className='high-contrast'>{blockchain.name}</Text>
              </Row>
            ))}
          </Col>

          <Row>
            <Button className='w-full' onClick={handleNext}>
              <Row place='center' gap={2}>
                <Text>Next</Text>
                <FrontArrowIcon />
              </Row>
            </Button>
          </Row>
        </>
      );
    }

    if (modalStep === MODAL_STEP.DOT) {
      return (
        <>
          <PreparingIcon />
          <Row place='center'>
            <ModalTitle>Add New Wallet Address</ModalTitle>
          </Row>

          <div>
            <ModalStep
              activeStep={2}
              dividerWidth='w-0'
              steps={['Choose a Chain', 'Connect Wallet']}
            />
          </div>

          <DotAddWalletSelection onComplete={handleAddWalletComplete} />
        </>
      );
    }

    if (modalStep === MODAL_STEP.ETH) {
      return (
        <>
          <EthAddWalletModal
            onComplete={handleAddWalletComplete}
            initialUser={initialUser}
          />
        </>
      );
    }

    if (modalStep === MODAL_STEP.SUCCESS) {
      return (
        <>
          <AnimatedCheckmark />
          <Row place='center'>
            <ModalTitle>Wallet Successfully Added</ModalTitle>
          </Row>
          <Text>Thank you for adding your Polkadot wallet.</Text>
          <Row>
            <Button
              className='w-full'
              onClick={() => {
                closeModal();
              }}
            >
              <Row place='center'>
                <Text>Close</Text>
              </Row>
            </Button>
          </Row>
        </>
      );
    }
  };

  return (
    <Col gap={5} place='center'>
      {renderModalStep()}
    </Col>
  );
}
