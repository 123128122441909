import { LoadingIndicator } from '@tokensoft-web/common-ui';
import { useAccount, VERIFICATION_STATUS } from '@tokensoft-web/common-utils';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccreditationCompleted } from '../../components/accreditation/accreditation-completed';
import { AccreditationFailed } from '../../components/accreditation/accreditation-failed';
import { AccreditationForm } from '../../components/accreditation/accreditation-form';
import { AccreditationPending } from '../../components/accreditation/accreditation-pending';

const AccreditationPage = () => {
  const navigate = useNavigate();
  const { account, isTier3Eligible, tier3Status } = useAccount();

  useEffect(() => {
    // scroll to top of page
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (account && !isTier3Eligible) {
      navigate('/account');
    }
  }, [account]);

  if (!account) return <LoadingIndicator />;

  switch (tier3Status) {
    case VERIFICATION_STATUS.NONE:
    case VERIFICATION_STATUS.NEW:
    default:
      return <AccreditationForm />;
    case VERIFICATION_STATUS.COMPLETED:
      return <AccreditationCompleted />;
    case VERIFICATION_STATUS.FAILED:
      return <AccreditationFailed />;
    case VERIFICATION_STATUS.PENDING:
      return <AccreditationPending />;
  }
};

export default AccreditationPage;
