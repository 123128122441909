import axios, { ResponseType } from 'axios';

export const getValidIPFSHash = (hash: string): boolean => {
  // validate ipfs hash is prefixed with ipfs
  return hash && hash.startsWith('ipfs');
};

export const getIPFSResource = async (
  ipfsHash: string,
  reponseType: ResponseType = 'json',
): Promise<any> => {
  if (!ipfsHash || !getValidIPFSHash(ipfsHash)) {
    return null;
  }

  const ipfsURL = getIPFSLink(ipfsHash);
  try {
    const result = await axios.get(ipfsURL, { responseType: reponseType });
    return result.data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const getResourceUri = (uri: string) => {
  if (!uri || !getValidIPFSHash(uri)) {
    return uri;
  }
  const hash = getIPFSHash(uri);
  return `${process.env.REACT_APP_IPFS_GATEWAY}/${hash}`;
};

export const getIPFSHash = (ipfsAddress: string) => {
  if (!ipfsAddress || !getValidIPFSHash(ipfsAddress)) {
    return;
  }
  const split = ipfsAddress.split('ipfs://');
  const hash = split.length > 1 ? split[1] : ipfsAddress;
  return hash;
};

export const getIPFSLink = (ipfsAddress: string) => {
  if (!ipfsAddress || !getValidIPFSHash(ipfsAddress)) {
    return;
  }
  const hash = getIPFSHash(ipfsAddress);
  return `${process.env.REACT_APP_IPFS_GATEWAY}/${hash}`;
};

export const convertToIPFSLink = (ipfsAddress: string): string => {
  if (!ipfsAddress) {
    return '';
  }

  return 'ipfs://' + ipfsAddress;
};
