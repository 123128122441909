import {
  formatValue,
  useDistributorDeployed,
  useModal,
  useNetworks,
  usePrepareDistributor,
  useToast,
} from '@tokensoft-web/common-utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEPLOY_VESTING_TYPE_OPTIONS } from '../../../utils/enums';
import { getAddressUrl } from '../../../utils/network';
import DeployDistributorModal from './deploy-distributor-modal';
import DeployDistributorSuccessModal from './deploy-distributor-success-modal';
import PreparingDistributorModal from './preparing-distributor-modal';

interface CreateDistributorModalProps {
  context?: any;
  event?: any;
}

export enum DEPLOY_STATUS {
  NOT_STARTED,
  PREPARED,
  DEPLOYED,
}

const CreateDistributorModal = ({
  context,
  event,
}: CreateDistributorModalProps) => {
  const { showErrorToast } = useToast();
  const navigate = useNavigate();
  const { closeModal } = useModal();
  const { getNetworkDetails } = useNetworks();
  const { mutate: distributorDeployed } = useDistributorDeployed();

  const [deployStatus, setDeployStatus] = useState(
    context.deployStatus || DEPLOY_STATUS.NOT_STARTED,
  );
  const [preparedData, setPreparedData] = useState(null);
  const [receipt, setReceipt] = useState(context.deployReceipt || null);

  const { mutate: prepareDistributor } = usePrepareDistributor();

  useEffect(() => {
    prepareDistributor(
      {
        ...context,
        totalAllocations: formatValue(context.totalAllocations),
      },
      {
        onSuccess: (result) => {
          if (result.valid) {
            setPreparedData(result);
            setDeployStatus(DEPLOY_STATUS.PREPARED);
          } else {
            closeModal();
            setPreparedData(null);
            setDeployStatus(DEPLOY_STATUS.NOT_STARTED);
            showErrorToast({
              description:
                result.errorMessage ||
                'Error preparing distributor.  Please try again.',
            });
          }
        },
        onError: (error) => {
          closeModal();
          setPreparedData(null);
          setDeployStatus(DEPLOY_STATUS.NOT_STARTED);
          showErrorToast({
            description:
              error.toString() ||
              'Error preparing distributor.  Please try again.',
          });
        },
      },
    );
  }, []);

  return (
    <>
      {deployStatus === DEPLOY_STATUS.NOT_STARTED ? (
        <PreparingDistributorModal />
      ) : null}

      {deployStatus === DEPLOY_STATUS.PREPARED ? (
        <DeployDistributorModal
          preparedData={preparedData}
          vestingType={context.vestingType}
          onSuccess={async (receipt) => {
            setReceipt(receipt);

            let vestingType = context.vestingType;
            if (context.vestingType === DEPLOY_VESTING_TYPE_OPTIONS.INSTANT) {
              vestingType = DEPLOY_VESTING_TYPE_OPTIONS.CONTINUOUS;
            }

            await distributorDeployed({
              id: receipt.contractAddress,
              chainId: context?.networkId,
              startTime: context?.startTime,
              vestingType: vestingType.toUpperCase(),
              eventId: event.id,
            });
            setDeployStatus(DEPLOY_STATUS.DEPLOYED);
          }}
          onError={() => {
            showErrorToast({
              description: 'Error deploying distributor.  Please try again.',
            });
          }}
        />
      ) : null}

      {deployStatus === DEPLOY_STATUS.DEPLOYED ? (
        <DeployDistributorSuccessModal
          explorerUrl={getAddressUrl(
            receipt.contractAddress,
            getNetworkDetails(context.networkId),
          )}
          onFinish={() => {
            closeModal();
            navigate(
              `/admin/project/${event.project.id}/event/${event.id}/fund`,
            );
          }}
        />
      ) : null}
    </>
  );
};

export default CreateDistributorModal;
