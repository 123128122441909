import { Error, LoadingIndicator } from '@tokensoft-web/common-ui';
import {
  DEFAULT_EVENT_HEADER_BG_COLOR,
  DEFAULT_EVENT_HEADER_FONT_COLOR,
  isRestrictedByRegion,
  useAuth,
  useConfiguration,
  useGetMeEvent,
} from '@tokensoft-web/common-utils';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EventHeaderWrapper from '../../components/event/event-header-wrapper';
import RegionRestriction from '../../components/feature/region-restriction';
import { EventMeContext } from './event-me-context';

interface EventMeProviderProps {
  children?: ReactNode;
}

export enum EVENT_ME_VIEW {
  UPCOMING = 'upcoming',
  CLAIM = 'claim',
  SALE = 'sale',
  EVENT_DETAILS = 'event-details',
  CLOSED = 'closed',
}

export const EventMeProvider: FC<EventMeProviderProps> = ({ children }) => {
  const { eventId } = useParams();
  const [view, setView] = useState<EVENT_ME_VIEW>(EVENT_ME_VIEW.EVENT_DETAILS);
  const { event: event, loading: loading, refresh } = useGetMeEvent(eventId);
  const { configuration } = useConfiguration();
  const {
    user: { walletAddress },
  } = useAuth();
  const contentHeaderBgColor =
    event?.contentHeaderBgColor || DEFAULT_EVENT_HEADER_BG_COLOR;
  const contentHeaderBgImage = event?.contentHeaderBgImage;
  const contentHeaderFontColor =
    event?.contentHeaderFontColor || DEFAULT_EVENT_HEADER_FONT_COLOR;

  useEffect(() => {
    if (walletAddress) {
      refresh();
    }
  }, [walletAddress]);

  if (loading) {
    return (
      <EventHeaderWrapper
        backgroundColor={contentHeaderBgColor}
        backgroundImg={contentHeaderBgImage}
      >
        <LoadingIndicator text='Loading Event' color={contentHeaderFontColor} />
      </EventHeaderWrapper>
    );
  }

  if (!loading && !event) {
    return (
      <EventHeaderWrapper
        backgroundColor={contentHeaderBgColor}
        backgroundImg={contentHeaderBgImage}
      >
        <Error
          title='Event Not Found'
          message="It looks like this isn't a valid event."
          style={{ color: contentHeaderFontColor }}
        />
      </EventHeaderWrapper>
    );
  }

  let isBlocked = false;
  const isDev = process.env.REACT_APP_PROCESS_ENV === 'development';
  if (!isDev && configuration?.requestContext?.location) {
    isBlocked = isRestrictedByRegion(
      configuration?.requestContext?.location,
      event?.allowedRegions,
    );
  }

  if (isBlocked) {
    return (
      <EventHeaderWrapper
        backgroundColor={contentHeaderBgColor}
        backgroundImg={contentHeaderBgImage}
      >
        <RegionRestriction style={{ color: contentHeaderFontColor }} />
      </EventHeaderWrapper>
    );
  }

  return (
    <>
      <EventMeContext.Provider
        value={{
          event: event,
          loading: loading,
          view: view,
          setView: setView,
          refresh: refresh,
        }}
      >
        {children}
      </EventMeContext.Provider>
    </>
  );
};
