import { HelpText, Section, Stacked } from '@tokensoft-web/common-ui';
import { getTruncatedAddress, useAuth } from '@tokensoft-web/common-utils';
import { DistributorVestingSummary } from '../../claims/distributor-vesting-summary';
import TokenInfoCard from './token-info-card';

interface DistributorDeployProps {
  context: any;
  setContext: Function;
}

const DistributorDeploy = ({ context, setContext }: DistributorDeployProps) => {
  const {
    user: { walletAddress },
  } = useAuth();
  const formattedWalletAddress = getTruncatedAddress(walletAddress);

  const key = 'distributor-deploy';

  return (
    <Stacked data-testid={`${key}`}>
      <Section>
        <TokenInfoCard
          tokenInfo={context.tokenInfo}
          networkId={context.networkId}
          showSuccess={false}
        />
      </Section>

      <DistributorVestingSummary context={context}></DistributorVestingSummary>

      <HelpText data-testid={`${key}-help-text`}>
        The address ({formattedWalletAddress}) you use to launch the distributor
        will own the new distributor contract. You can adjust distributor
        settings after launch.
      </HelpText>
    </Stacked>
  );
};

export default DistributorDeploy;
