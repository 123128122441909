import { EVENT_STATUS } from './event-status';

export interface Event {
  id?: number;
  code?: string;
  networkId?: number;
  name: string;
  description: string;
  startTime: string;
  endTime?: string;
  status: EVENT_STATUS;
  type: string;
  userEligible?: boolean;
  project?: any;
  sale?: any;
  distributor?: any;
}

export interface CreateDocument {
  index?: number;
  documentTitle?: string;
  uploadedFile?: File;
  signatureRequired?: boolean;
  showDocumentTo?: DOCUMENT_SHOW_TO_TYPE;
  company?: string;
  companyName?: string;
  signerName?: string;
  signerTitle?: string;
  recipient?: string;
  addendum?: string;
}

export enum DOCUMENT_SHOW_TO_TYPE {
  ALL_ELIGIBLE_PERSONS = 'ALL_ELIGIBLE_PERSONS',
  OUTSIDE_US = 'OUTSIDE_US',
  US_ONLY = 'US_ONLY',
}
