import classNames from 'classnames';
import { Text } from './text';

export const PageTitle = ({ className = '', children, ...restProps }) => {
  return (
    <Text
      textAlign={'center'}
      className={classNames(
        'page-title text-3xl font-bold high-contrast',
        className,
      )}
      {...restProps}
    >
      {children}
    </Text>
  );
};
