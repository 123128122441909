import {
  useCreateS3ProjectResource,
  useToast,
} from '@tokensoft-web/common-utils';
import { useRef } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Text } from '../../../..';
import { Button } from '../button/button';
import { UploadIcon } from '../icon/upload-icon';

export interface FileUploadResult {
  key: string;
  contentType: string;
  filename: string;
  uri: string;
}

interface FileUploadButtonProps {
  projectId?: string;
  onClick?: () => void;
  disabled?: boolean;
  maxSize?: number;
  fileTypes?: string;
  onFileUploaded?: (result: FileUploadResult) => void;
  className?: string;
  children?: React.ReactNode;
}

export const FileUploadButton = ({
  projectId = null,
  onClick = null,
  disabled = false,
  maxSize = 2097152,
  fileTypes = '.pdf',
  onFileUploaded = null,
  className = '',
  children,
  ...restProps
}: FileUploadButtonProps) => {
  const { showErrorToast } = useToast();
  const { mutate: uploadS3Resource, isPending } =
    useCreateS3ProjectResource(projectId);
  const hiddenFileInput = useRef(null);

  const handleOnClick = (e) => {
    if (e) {
      e.preventDefault();
    }
    hiddenFileInput.current.click();
    onClick && onClick();
  };

  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];

    if (fileUploaded.size > maxSize) {
      hiddenFileInput.current.value = null;
      showErrorToast({ description: 'File size is too large' });
      return;
    }

    const formData = new FormData();
    formData.append('file', fileUploaded, fileUploaded.name);

    uploadS3Resource(formData, {
      onSuccess: (result) => {
        hiddenFileInput.current.value = null;
        onFileUploaded && onFileUploaded(result);
      },
      onError: (error) => {
        hiddenFileInput.current.value = null;
        showErrorToast({ description: error.message });
      },
    });
  };

  return (
    <>
      <Button
        fill='outline'
        className={className}
        onClick={handleOnClick}
        disabled={isPending || disabled}
        {...restProps}
      >
        {isPending ? (
          <>
            <div className='animate-spin'>
              <AiOutlineLoading3Quarters size={16} />
            </div>
            <Text>Uploading</Text>
          </>
        ) : children ? (
          children
        ) : (
          <>
            <UploadIcon />
            <Text>Upload File</Text>
          </>
        )}
      </Button>
      <input
        data-testid={'file-upload-button-file-input'}
        type='file'
        accept={fileTypes}
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display: 'none' }} // Make the file input element invisible
      />
    </>
  );
};
