import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  KYC_TIER_EVENT,
  Tier,
  useAccountApiClient,
} from '@tokensoft-web/common-utils';

export const useSetKyc = (accountId: string) => {
  const queryClient = useQueryClient();
  const client = useAccountApiClient();

  return useMutation({
    mutationFn: () => {
      const data = {
        account: {
          id: accountId,
        },
        kycTier: {
          id: '1',
        },
      };

      return client(`accounts/kyc/tiers`, {
        method: 'post',
        data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['account'] });
    },
  });
};

interface CreateAccountKycTierEventInput {
  tier: Tier;
  event: KYC_TIER_EVENT;
  idvApplicantId?: string;
}

export const useCreateAccountKycTierEvent = () => {
  const queryClient = useQueryClient();
  const client = useAccountApiClient();

  return useMutation({
    mutationFn: ({
      tier,
      event,
      idvApplicantId,
    }: CreateAccountKycTierEventInput) => {
      const data = {
        accountKycTier: {
          id: tier.id,
          account: {
            idvApplicantId: idvApplicantId,
          },
        },
        event,
      };

      return client(`accounts/kyc/tiers/events`, {
        method: 'post',
        data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['account'] });
    },
  });
};
