import {
  DEFAULT_THEME_VERSION,
  DEFAULT_TYPOGRAPHY,
  getResourceUri,
  getValidIPFSHash,
  useConfiguration,
} from '@tokensoft-web/common-utils';
import { Helmet } from 'react-helmet';
import slugify from 'react-slugify';

export const ThemeConfiguration = () => {
  const { configuration } = useConfiguration();

  return (
    <>
      <Helmet>
        <body
          data-typography={
            configuration?.project?.theme?.baseFont || DEFAULT_TYPOGRAPHY
          }
          data-theme-version={
            configuration?.project?.theme?.version || DEFAULT_THEME_VERSION
          }
          data-env={process.env.REACT_APP_PROCESS_ENV}
          data-project={slugify(configuration?.project?.name)}
        ></body>

        {configuration?.project?.theme ? (
          <style type='text/css'>
            {`
            :root {
              ${
                configuration.project.theme.primaryColor
                  ? `
                --primary: ${configuration.project.theme.primaryColor} !important;
                --primary-light: ${configuration.project.theme.primaryColor}1A  !important; /* 10% opacity */
                --primary-extra-light: ${configuration.project.theme.primaryColor}80  !important; /* 50% opacity */
              `
                  : ''
              }
              ${
                configuration.project.theme.secondaryColor
                  ? `
                --secondary: ${configuration.project.theme.secondaryColor} !important;
                --secondary-light: ${configuration.project.theme.secondaryColor}1A !important;  /* 10% opacity */
                --secondary-extra-light: ${configuration.project.theme.secondaryColor}80 !important; /* 50% opacity */
              `
                  : ''
              }
              ${
                configuration.project.theme.baseFont
                  ? `--font-family-base: ${configuration.project.theme.baseFont} !important;`
                  : ''
              }
              ${
                configuration.project.theme.baseFontSize
                  ? `--font-size-base: ${configuration.project.theme.baseFontSize} !important;`
                  : ''
              }
              ${
                configuration.project.theme.titleFont
                  ? `--font-family-title: ${configuration.project.theme.titleFont} !important;`
                  : ''
              }
              ${
                configuration.project.theme.titleFontSize
                  ? `--font-size-title: ${configuration.project.theme.titleFontSize} !important;`
                  : ''
              }
              ${
                configuration.project.theme.headerBgColor
                  ? `--header-background: ${configuration.project.theme.headerBgColor} !important;`
                  : ''
              }
              ${
                configuration.project.theme.sidebarBgColor
                  ? `--sidebar-background: ${configuration.project.theme.sidebarBgColor} !important;`
                  : ''
              }
              ${
                configuration.project.theme.contentBgColor
                  ? `--content-background: ${configuration.project.theme.contentBgColor} !important;`
                  : ''
              }
              ${
                configuration.project.theme.headerFontColor
                  ? `--header-font-color: ${configuration.project.theme.headerFontColor} !important;`
                  : ''
              }
              ${
                configuration.project.theme.sidebarFontColor
                  ? `--sidebar-font-color: ${configuration.project.theme.sidebarFontColor} !important;`
                  : ''
              }
              ${
                configuration.project.theme.containerBgColor
                  ? `--container-background: ${configuration.project.theme.containerBgColor} !important;`
                  : ''
              }
              ${
                configuration.project.content?.welcomeBgColor
                  ? `--welcome-content-background: ${configuration.project.content?.welcomeBgColor} !important;`
                  : ''
              }
              ${
                configuration.project.content?.welcomeFontColor
                  ? `--welcome-content-color: ${configuration.project.content?.welcomeFontColor} !important;`
                  : ''
              }    
            }

            ${
              configuration.project.content?.welcomeBgImage
                ? `
              .domain-content {
                  background-image: url("${
                    getValidIPFSHash(
                      configuration.project.content?.welcomeBgImage,
                    )
                      ? getResourceUri(
                          configuration.project.content?.welcomeBgImage,
                        )
                      : configuration.project.content?.welcomeBgImage
                  }") !important;
                  background-size: cover !important;
              }      
            `
                : ''
            }                
          `}
          </style>
        ) : null}
      </Helmet>
    </>
  );
};
