import { KYC_TIER_STATE } from '@tokensoft-web/common-utils';
import { createContext, useContext } from 'react';

interface ProfileContextProps {
  account: any;
  loading: boolean;
  view: string;
  setView: Function;
  hasVerifiedEmail: boolean;
  verificationEmailSent: boolean;
  tier1Status: string;
  isTier1Started: boolean;
  isTier1Finished: boolean;
  isTier1Completed: boolean;
  tier1CurrentState: KYC_TIER_STATE;
  tier3Status: string;
  isTier3Started: boolean;
  isTier3Finished: boolean;
  hasSetProfileDetails: boolean;
  isProfileSetupComplete: boolean;
  isTier3Eligible: boolean;
  isTier3Completed: boolean;
  tier3CurrentState: KYC_TIER_STATE;
}

export const ProfileContext = createContext<ProfileContextProps>(undefined);
ProfileContext.displayName = 'ProfileContext';

export const useProfile = (): ProfileContextProps => {
  return useContext(ProfileContext);
};
