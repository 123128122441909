import {
  Button,
  Card,
  Row,
  Section,
  SectionTitle,
  Stretched,
  Text,
} from '@tokensoft-web/common-ui';
import {
  convertBaseUnitsToDecimal,
  formatValue,
  useModal,
} from '@tokensoft-web/common-utils';
import { RiFundsLine } from 'react-icons/ri';
import FundDistributorModal, { FUND_TYPE } from './fund-distributor-modal';

export const DistributorFundAmounts = ({ context, className = '' }) => {
  const { showModal } = useModal();
  const requiredBalance = formatValue(
    convertBaseUnitsToDecimal(
      context.tokenInfo?.requiredBalance,
      context.tokenInfo?.tokenDecimals || 18,
    ),
    { commas: true },
  );
  const currentBalance = formatValue(
    convertBaseUnitsToDecimal(
      context.tokenInfo?.tokenBalance,
      context.tokenInfo?.tokenDecimals || 18,
    ),
    { commas: true },
  );

  const key = 'distributor-fund-amounts';

  const withdrawFunds = !context.tokenInfo?.requiresAdditionalFunding;
  const excess = context.tokenInfo?.excessAmount
    ? formatValue(
        convertBaseUnitsToDecimal(
          context.tokenInfo?.excessAmount,
          context.tokenInfo?.tokenDecimals || 18,
        ),
        { commas: true },
      )
    : false;
  const shortage = context.tokenInfo?.requiresAdditionalFunding
    ? formatValue(
        convertBaseUnitsToDecimal(
          context.tokenInfo?.additionalAmountToFund,
          context.tokenInfo?.tokenDecimals || 18,
        ),
        { commas: true },
      )
    : false;

  const showFundModal = () => {
    const fundType = withdrawFunds ? FUND_TYPE.WITHDRAW : FUND_TYPE.DEPOSIT;
    const amount = withdrawFunds
      ? context.tokenInfo?.excessAmount
      : context.tokenInfo?.additionalAmountToFund;
    const contractAddress = withdrawFunds
      ? context.tokenInfo?.id
      : context.tokenInfo?.token;
    const toAddress = withdrawFunds
      ? context.tokenInfo?.owner
      : context.tokenInfo?.id;

    showModal({
      className: 'md',
      content: (
        <FundDistributorModal
          onSuccess={() => context.refresh()}
          distributor={context.tokenInfo}
          token={context.tokenInfo?.token}
          fundType={fundType}
          amount={amount}
          toAddress={toAddress}
          contractAddress={contractAddress}
        />
      ),
    });
  };

  const renderBalanceText = () => {
    if (context.tokenInfo?.correctlyFunded) {
      return;
    }

    if (withdrawFunds) {
      return `(${excess} ${context.tokenInfo?.tokenSymbol} excess)`;
    }

    return (
      <span className='text-danger-medium'>
        ({shortage} {context.tokenInfo?.tokenSymbol} funding required)
      </span>
    );
  };

  const renderFundButton = () => {
    if (context.tokenInfo?.correctlyFunded) {
      return null;
    }

    if (context.tokenInfo?.requiresAdditionalFunding) {
      return (
        <Button size='xs' onClick={() => showFundModal()}>
          <div>
            <RiFundsLine size={20} />
          </div>
          <Text>Deposit Funds</Text>
        </Button>
      );
    }

    return (
      <Button size='xs' fill='outline' onClick={() => showFundModal()}>
        <div className='-scale-y-100'>
          <RiFundsLine size={20} />
        </div>
        <Text>Withdraw Excess Funds</Text>
      </Button>
    );
  };

  return (
    <Stretched gap={5}>
      <Card className={className} id={`${key}-current-card`}>
        <Section place={'start'}>
          <Row xalign={'between'}>
            <SectionTitle data-testid={`${key}-current-card-title`}>
              Current Distribution Balance
            </SectionTitle>
            {renderFundButton()}
          </Row>
          <Text data-testid={`${key}-current-card-value`}>
            {' '}
            {currentBalance} {context.tokenInfo?.tokenSymbol}{' '}
            {renderBalanceText()}
          </Text>
        </Section>
      </Card>
      <Card className={className} id={`${key}-required-card`}>
        <Section place={'start'}>
          <SectionTitle data-testid={`${key}-required-card-title`}>
            Required Distribution Balance
          </SectionTitle>
          <Text data-testid={`${key}-required-card-value`}>
            {' '}
            {requiredBalance} {context.tokenInfo?.tokenSymbol}
          </Text>
        </Section>
      </Card>
    </Stretched>
  );
};
