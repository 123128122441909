import { Card, IconLogo, LoadingIndicator } from '@tokensoft-web/common-ui';
import {
  getTruncatedAddress,
  useAuth,
  useClipboard,
  useNetworks,
} from '@tokensoft-web/common-utils';
import { AiOutlineCopy } from 'react-icons/ai';
import { useGetTransactionData } from '../../services/transaction-service';
import SaleClaimWrapper from '../sale/sale-claim-wrapper';
import TransactionsTable from './transactions-table';

const Transactions = () => {
  const {
    user: { walletAddress },
  } = useAuth();
  const { getNetworkDetails } = useNetworks();
  const { copyToClipboard } = useClipboard();
  const {
    transactionData: transactionData,
    claimsBySales: claimsBySales,
    claimableDistributors: claimableDistributors,
    loading: transactionDataLoading,
    purchasesLoading: purchasesLoading,
    claimsLoading: claimsLoading,
    refresh: refresh,
  } = useGetTransactionData();

  const handleMinedTransaction = async () => {
    refresh();
  };

  return (
    <>
      {Object.keys(transactionData)?.length > 0 && (
        <div className='grid gap-6 grid-cols-1'>
          {Object.values(transactionData)
            .map((transactions: any[]) => {
              return transactions.sort(function (a, b) {
                return (
                  new Date(b.createdAt).valueOf() -
                  new Date(a.createdAt).valueOf()
                );
              });
            })
            .map((transactions: any[], i) => {
              const sale: any = transactions[0].sale;
              const existingClaim = claimsBySales[sale.id];
              const { logoUri, name } = getNetworkDetails(sale.chainId);
              const eventName = sale.event?.name || sale.saleName;
              const logo = sale.event?.project?.theme?.logoSmall;

              return (
                <div key={i}>
                  <Card>
                    <div
                      className={
                        'grid grid-cols-3 sm:grid-cols-6 xl:grid-cols-12 gap-x-8 gap-y-4'
                      }
                    >
                      <div
                        className={`flex flew-row space-x-2 items-center col-span-3 sm:col-span-6 xl:col-span-8`}
                      >
                        <IconLogo className='large' logo={logo} />
                        <div className={'flex flex-col self-center'}>
                          <span className='font-semibold high-contrast'>
                            {eventName.toUpperCase()}
                          </span>
                        </div>
                      </div>

                      <div className='flex flex-col col-span-3 sm:col-span-2'>
                        <div className='mb-2'>
                          <span className='text-xs uppercase text-neutral-extra-dark'>
                            Network
                          </span>
                        </div>
                        <div className={'flex flex-row items-center'}>
                          {logoUri ? (
                            <div className='logo pr-2'>
                              <img
                                className='min-w-[24px] w-[24px] min-h-[24px] h-[24px]'
                                src={logoUri}
                              />
                            </div>
                          ) : null}
                          <div className='text-sm high-contrast'>{name}</div>
                        </div>
                      </div>

                      <div
                        className='flex flex-col col-span-3 sm:col-span-2'
                        onClick={() => copyToClipboard(sale.id)}
                      >
                        <div className='mb-2'>
                          <span className='text-xs uppercase text-neutral-medium'>
                            Address
                          </span>
                        </div>
                        <div className={'flex flex-row items-center'}>
                          <div className='text-sm high-contrast'>
                            {getTruncatedAddress(sale.id)}
                          </div>
                          <div className={'high-contrast ml-2 cursor-pointer'}>
                            <AiOutlineCopy size={16} />
                          </div>
                        </div>
                      </div>

                      <div
                        className={
                          'flex items-center justify-start md:justify-end pt-4 pr-2 md:pt-0 w-full md:w-1/5'
                        }
                      >
                        <SaleClaimWrapper
                          sale={sale}
                          walletAddress={walletAddress}
                          existingClaim={existingClaim}
                          onMinedTransaction={handleMinedTransaction}
                        />
                      </div>
                    </div>

                    <div className='py-4'>
                      <TransactionsTable
                        transactions={transactions}
                        purchasesLoading={purchasesLoading}
                        claimsLoading={claimsLoading}
                      />
                    </div>
                  </Card>
                </div>
              );
            })}
        </div>
      )}

      {!transactionDataLoading && Object.keys(transactionData).length === 0 && (
        <span className='block pt-4'>No purchases found.</span>
      )}

      {transactionDataLoading && <LoadingIndicator text='Loading Purchases' />}
    </>
  );
};

export default Transactions;
