import { Error, LoadingIndicator } from '@tokensoft-web/common-ui';
import {
  DEFAULT_EVENT_HEADER_BG_COLOR,
  DEFAULT_EVENT_HEADER_FONT_COLOR,
  FEATURE,
  getUtcNowUnix,
  useAccount,
  useAuth,
  useGetEventClaimsByWalletAddress,
  useGetRelayerClaimStatus,
  useModal,
} from '@tokensoft-web/common-utils';
import { FC, ReactNode, useEffect, useState } from 'react';
import EventHeaderWrapper from '../../components/event/event-header-wrapper';
import RegionRestriction from '../../components/feature/region-restriction';
import { useFairDelayTime } from '../../utils/claim';
import { connextDomainToChainId } from '../../utils/domain';
import { CLAIM_STEP } from '../../utils/enums';
import { isSmartContractWallet } from '../../utils/wallet';
import { useEvent } from '../event/event-me-context';
import { DistributorContext } from './distributor-context';

interface DistributorProviderProps {
  children?: ReactNode;
}

export const DistributorProvider: FC<DistributorProviderProps> = ({
  children,
}) => {
  const [delayRemaining, setDelayRemaining] = useState(0);
  const [distributor, setDistributor] = useState(null);
  const {
    user: { walletAddress },
  } = useAuth();
  const { event, loading } = useEvent();
  const { account } = useAccount();
  const { isOpen: isModalOpen } = useModal();

  const [delegateSaved, setDelegateSaved] = useState({});
  const [pendingTransaction, setPendingTransaction] = useState(null);
  const [completedTransaction, setCompletedTransaction] = useState(null);

  const [pageState, setPageState] = useState(null);

  // for connext EOA users we want to check to see if there are any pending transactions
  const isSmartContract = isSmartContractWallet(
    account?.wallets,
    walletAddress,
  );
  const isConnextFlow = !!event?.features?.includes(
    FEATURE.CLAIM_CONNEXT_LAYOUT,
  );
  const isPendingTransaction = pendingTransaction !== null;
  const isCompletedTransaction = completedTransaction != null;

  const getRelayerClaimStatusEnabled =
    pageState !== null &&
    pageState !== CLAIM_STEP.UPCOMING &&
    isConnextFlow &&
    !isSmartContract &&
    !isCompletedTransaction;
  const getEventClaimsEnabled =
    pageState !== null &&
    pageState !== CLAIM_STEP.UPCOMING &&
    isPendingTransaction &&
    !isModalOpen &&
    !isCompletedTransaction;

  const { data: getEventClaimsData } = useGetEventClaimsByWalletAddress(
    event?.id,
    getEventClaimsEnabled,
  );
  const { data: relayerClaimData, isLoading: relayerClaimDataLoading } =
    useGetRelayerClaimStatus(getRelayerClaimStatusEnabled, 0);

  const { delay } = useFairDelayTime(
    distributor?.id,
    distributor?.chainId,
    walletAddress,
  );

  const documentsToAcceptOrSign = event?.eventUsers[0]?.documents?.filter(
    (doc) => !doc.acceptedAt,
  );

  const contentHeaderBgColor =
    event?.contentHeaderBgColor || DEFAULT_EVENT_HEADER_BG_COLOR;
  const contentHeaderBgImage = event?.contentHeaderBgImage;
  const contentHeaderFontColor =
    event?.contentHeaderFontColor || DEFAULT_EVENT_HEADER_FONT_COLOR;

  useEffect(() => {
    if (event.distributor) {
      setDistributor(event.distributor);
    }
  }, [event]);

  // if the pending transaction is set, poll the backend for claim data until we see a new transaction arrive.
  // when the completed transaction is set the claim detail page will update from a pending state to a success state.
  useEffect(() => {
    if (getEventClaimsData) {
      if (pendingTransaction) {
        if (getEventClaimsData.claims?.length > 0) {
          // if the pending transaction is in the getEventClaimsData, then call onCompletedTransaction()
          const match = getEventClaimsData.claims.find(
            (claim) =>
              claim.transactionHash === pendingTransaction.transactionHash,
          );
          if (match) {
            onCompletedTransaction(match);
          } else {
            // otherwise, just generically check to see if the size of the event claims data array has changed
            const distributionRecords =
              distributor?.distributionRecords?.length > 0
                ? distributor.distributionRecords[0]
                : { claims: [] };
            let newTransactions = getEventClaimsData.claims.filter(
              (eventClaim) =>
                distributionRecords.claims?.findIndex(
                  (claim) =>
                    claim.transactionHash === eventClaim.transactionHash,
                ) < 0,
            );

            // overwrite the distributor's distribution records with the newest information
            distributor.distributionRecords = [getEventClaimsData];

            if (newTransactions && newTransactions.length > 0) {
              onCompletedTransaction(newTransactions[0]);
            }
          }
        }
      }
    }
  }, [getEventClaimsData]);

  // the connext relay endpoint will return a success if the claim transaction has executed.
  // for all other statuses we can treat it as a 'not found' condition.
  useEffect(() => {
    if (relayerClaimData) {
      const { status } = relayerClaimData;
      if (status === 'ExecSuccess') {
        onPendingTransaction(relayerClaimData);

        // redirect the user to the detail page
        if (
          pageState !== null &&
          pageState !== CLAIM_STEP.UPCOMING &&
          pageState !== CLAIM_STEP.CLAIM
        ) {
          setPageState(CLAIM_STEP.CLAIM);
        }
      }
    }
  }, [relayerClaimData]);

  useEffect(() => {
    if (distributor) {
      if (
        isConnextFlow &&
        isSmartContract &&
        pendingTransaction === null &&
        completedTransaction === null
      ) {
        const distributionRecords =
          distributor?.distributionRecords?.length > 0
            ? distributor.distributionRecords[0]
            : { claims: [] };
        const trxDetails =
          distributionRecords.claims.length > 0
            ? distributionRecords.claims[0]
            : null;
        onCompletedTransaction(trxDetails);
      }
    }
  }, [distributor]);

  useEffect(() => {
    if (distributor && delay) {
      const { continuousVesting, trancheVesting, sale } = distributor;
      let startTime;

      if (trancheVesting?.tranches?.length) {
        const tranche =
          distributor?.trancheVesting?.tranches[
            distributor?.trancheVesting.tranches?.length - 1
          ];
        startTime = tranche.time;
      } else if (continuousVesting) {
        startTime = continuousVesting.cliff;
      } else if (sale) {
        startTime = distributor.sale.startTime;
      } else {
        startTime = distributor.startTime;
      }

      const start = parseInt(startTime);
      const now = getUtcNowUnix();
      const time = start + delay - now + 90; // pad by 90 seconds
      setDelayRemaining(time > 0 ? time : 0);
    }
  }, [distributor, delay]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (delayRemaining > 0) {
        setDelayRemaining(delayRemaining - 1);
      }

      if (delayRemaining === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  });

  const onPendingTransaction = (receipt) => {
    const distributionRecords =
      distributor?.distributionRecords?.length > 0
        ? distributor.distributionRecords[0]
        : { claims: [] };
    const processedTransaction = distributionRecords.claims?.find(
      (claim) => claim.transactionHash === receipt.transactionHash,
    );

    // if our backend is already returning this information then we can stop polling and mark
    // the round trip as complete.
    if (processedTransaction) {
      return onCompletedTransaction(processedTransaction);
    }

    // otherwise start the polling process
    setCompletedTransaction(null);
    setPendingTransaction(receipt);
  };

  const onCompletedTransaction = (receipt) => {
    setCompletedTransaction(receipt);
    setPendingTransaction(null);
  };

  const getCorrectNetwork = () => {
    if (distributor?.domain) {
      return connextDomainToChainId(Number(distributor.domain));
    }

    return distributor?.chainId;
  };

  if (loading || relayerClaimDataLoading) {
    return (
      <EventHeaderWrapper
        backgroundColor={contentHeaderBgColor}
        backgroundImg={contentHeaderBgImage}
      >
        <LoadingIndicator text='Loading Event' color={contentHeaderFontColor} />
      </EventHeaderWrapper>
    );
  }

  if (!loading && !relayerClaimDataLoading && !distributor) {
    return (
      <EventHeaderWrapper
        backgroundColor={contentHeaderBgColor}
        backgroundImg={contentHeaderBgImage}
      >
        <Error
          title='Claim Not Found'
          message="It looks like this isn't a valid claim."
          style={{ color: contentHeaderFontColor }}
        />
      </EventHeaderWrapper>
    );
  }

  const correctNetworkChainId = getCorrectNetwork();

  if (event?.eventUsers?.length) {
    if (event.eventUsers[0].restrictions?.regionRestricted) {
      return (
        <EventHeaderWrapper
          backgroundColor={contentHeaderBgColor}
          backgroundImg={contentHeaderBgImage}
        >
          <RegionRestriction style={{ color: contentHeaderFontColor }} />
        </EventHeaderWrapper>
      );
    }
  }

  return (
    <>
      <DistributorContext.Provider
        value={{
          event: event,
          distributor: distributor,
          isLoading: loading,
          documentsToAcceptOrSign: documentsToAcceptOrSign,
          pageState: pageState,
          setPageState: setPageState,
          delegateSaved: delegateSaved,
          setDelegateSaved: setDelegateSaved,
          pendingTransaction: pendingTransaction,
          onPendingTransaction: onPendingTransaction,
          completedTransaction: completedTransaction,
          onCompletedTransaction: onCompletedTransaction,
          correctNetworkChainId: correctNetworkChainId,
          delay: {
            hasDelay: delayRemaining > 0,
            delayRemaining: delayRemaining || 0,
          },
        }}
      >
        {children}
      </DistributorContext.Provider>
    </>
  );
};
