import {
  Button,
  Col,
  Row,
  SweepIcon,
  TableData,
  Text,
} from '@tokensoft-web/common-ui';
import {
  convertBaseUnitsToDecimal,
  div,
  gt,
  useModal,
  useNetworks,
} from '@tokensoft-web/common-utils';
import { PaymentMethod } from '../../../utils/interface';
import { useExchangeRate } from '../../../utils/sale';
import { SweepSaleTokenModal } from '../sales/sweep-sale-token-modal';

export const PaymentMethodBalance = ({
  context,
  paymentMethod,
}: {
  context: any;
  paymentMethod: PaymentMethod;
}) => {
  const exchangeRate = useExchangeRate(context.networkId, paymentMethod);
  const { showModal, closeModal } = useModal();
  const { getNetworkDetails } = useNetworks();
  const {
    symbol: nativeSymbol,
    name: networkName,
    logoUri: networkLogo,
  } = getNetworkDetails(context.networkId);
  let nativeTokenPerSaleToken = div(context.price, exchangeRate, null, 6);

  const key = 'payment-method-balance';

  const openModal = (paymentMethod) => {
    showModal({
      content: (
        <SweepSaleTokenModal sale={context} paymentMethod={paymentMethod} />
      ),
    });
  };

  return (
    <Col gap={3} width={'1/4'}>
      <Row yalign={'center'} gap={2}>
        {
          <Text className='font-bold text-black'>{`${
            paymentMethod.native ? nativeSymbol : paymentMethod.symbol
          } Balance`}</Text>
        }
      </Row>
      <TableData
        data-testid={`${key}-total-data`}
      >{`${convertBaseUnitsToDecimal(
        paymentMethod.balance,
        paymentMethod.decimals,
      )} ${
        !paymentMethod.native ? paymentMethod.symbol : nativeSymbol
      }`}</TableData>
      <Button
        fill='outline'
        size={'sm'}
        className={'w-fit'}
        disabled={gt(paymentMethod.balance, 0) ? false : true}
        onClick={() => openModal(paymentMethod)}
      >
        <SweepIcon />
        Sweep Funds
      </Button>
    </Col>
  );
};
