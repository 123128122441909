import { InnerHtmlWrapper } from '@tokensoft-web/common-ui';

interface EventUpcomingBodyProps {
  bodyHeader?: string;
  bodyContent?: string;
}

const EventContent = ({ bodyContent }: EventUpcomingBodyProps) => {
  return <>{bodyContent ? <InnerHtmlWrapper content={bodyContent} /> : null}</>;
};

export default EventContent;
