import {
  utcToLocalDate,
  utcToRelativeLocalDateTimeToDate,
} from '@tokensoft-web/common-utils';
import { FC } from 'react';
import { AiFillProject } from 'react-icons/ai';
import { BsFillCalendar2EventFill } from 'react-icons/bs';
import {
  FaEnvelope,
  FaMoneyBillAlt,
  FaMoneyCheckAlt,
  FaTrashAlt,
  FaUserCircle,
  FaUserShield,
} from 'react-icons/fa';
import { GiReceiveMoney } from 'react-icons/gi';
import { TradingIcon } from '../../../icon/trading-icon';
import { Squircle } from '../../../squircle/squircle';
import './notification-list-item.css';

interface NotificationListItemProps {
  notification: any;
  onDelete: () => void;
}

export const NotificationListItem: FC<NotificationListItemProps> = ({
  notification,
  onDelete,
}) => {
  const renderIcon = (notification) => {
    switch (notification.type?.name.split('.')[0]) {
      case 'kyc':
        return <FaUserShield />;
      case 'kyc3':
        return <FaUserShield />;
      case 'purchase':
        return <FaMoneyBillAlt />;
      case 'claim':
        return <GiReceiveMoney />;
      case 'distribute':
        return <GiReceiveMoney />;
      case 'trade':
        return <TradingIcon className={'small'} />;
      case 'project':
        return <AiFillProject />;
      case 'event':
        return <BsFillCalendar2EventFill />;
      case 'account':
        return <FaUserCircle />;
      case 'billing':
        return <FaMoneyCheckAlt />;
      default:
        return <FaEnvelope />;
    }
  };

  const notificationsDaysOld = (date) => {
    let acceptedDate = [
      'few seconds',
      'minute',
      'minutes',
      'hour',
      'hours',
      'day',
      'days',
      'month',
      'months',
    ];

    let dateRelativeString = utcToRelativeLocalDateTimeToDate(date);
    let dateRelativeArray = dateRelativeString.split(' ');
    if (
      !acceptedDate.includes(dateRelativeArray[1]) ||
      (dateRelativeArray[1] === 'months' && parseInt(dateRelativeArray[0]) > 3)
    ) {
      return utcToLocalDate(date);
    }
    return dateRelativeString;
  };

  return (
    <div className='notification-item'>
      <div className='flex'>
        <Squircle className='notification-item-icon-squircle medium'>
          <div className='notification-item-icon'>
            {renderIcon(notification)}
          </div>
        </Squircle>
      </div>
      <div className='notification-item-content'>
        {notification.title && (
          <div className='notification-item-title'>
            <div className='notification-age'>
              {notificationsDaysOld(notification.createdAt)}
            </div>
            <p className='notification-item-title-text'>{notification.title}</p>
          </div>
        )}
        {notification.message && (
          <p
            className='notification-item-message'
            dangerouslySetInnerHTML={{
              __html: notification.message,
            }}
          ></p>
        )}
      </div>
      <button className='notification-item-trash' onClick={onDelete}>
        <FaTrashAlt />
      </button>
    </div>
  );
};
