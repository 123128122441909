import {
  ButtonRow,
  PageHeader,
  PageSubtitle,
  PageTitle,
} from '@tokensoft-web/common-ui';
import { Link } from 'react-router-dom';

const KycRestriction = ({ className = '', style = null }) => {
  return (
    <>
      <PageHeader className={className}>
        <PageTitle style={style}>Oops!</PageTitle>
        <PageSubtitle style={style}>
          Your profile is not complete. Please visit the My Profile page to
          finish the profile setup and identity verification process.
        </PageSubtitle>
      </PageHeader>

      <ButtonRow place={'center'}>
        <Link className='btn btn-primary' to='/account'>
          My Profile
        </Link>

        <Link className='btn btn-primary' to='/'>
          Dashboard
        </Link>
      </ButtonRow>
    </>
  );
};

export default KycRestriction;
