import classNames from 'classnames';

export const Text = ({
  className = '',
  breakAnywhere = null,
  width = null,
  textAlign = 'left',
  children,
  ...restProps
}) => {
  return (
    <div
      className={classNames(
        className,
        breakAnywhere ? 'break-anywhere' : '',
        textAlign ? `text-${textAlign}` : '',
        width ? `w-${width}` : '',
      )}
      {...restProps}
    >
      {children}
    </div>
  );
};
