import {
  Card,
  Col,
  PDFIcon,
  Row,
  Section,
  SectionTitle,
  Stacked,
  TableData,
  TableHeader,
  Text,
} from '@tokensoft-web/common-ui';
import {
  DEFAULT_BLOCKED_COUNTRIES,
  EventWizardState,
  complianceTierToComplianceSummary,
  editableToLocalDateTime,
  useAccount,
  walletRequirementsString,
} from '@tokensoft-web/common-utils';
import { AiFillEye } from 'react-icons/ai';
import { documentShowTypeToString } from '../../../contexts/event/document-upload-provider';
import EventPrivacyOverview from '../../../pages/admin/events/event-privacy-overview';

interface EventCreateConfirmationProps {
  wizardState: EventWizardState;
}

const EventCreateConfirmation = ({
  wizardState,
}: EventCreateConfirmationProps) => {
  const { account } = useAccount();

  const blockedCountries = [
    ...DEFAULT_BLOCKED_COUNTRIES,
    ...wizardState.blockedCountryList,
  ];

  return (
    <Stacked>
      <Section>
        <SectionTitle>Event Details</SectionTitle>
        <Card className='mt-5'>
          <Col gap={5}>
            <Col gap={2.5}>
              <TableHeader>Start Date</TableHeader>
              <TableData>
                {editableToLocalDateTime(
                  wizardState.startTime,
                  account?.timezone,
                )}
              </TableData>
            </Col>

            <Col gap={2.5}>
              <TableHeader>End Date</TableHeader>
              <TableData>
                {wizardState.endTime
                  ? editableToLocalDateTime(
                      wizardState.endTime,
                      account?.timezone,
                    )
                  : '-'}
              </TableData>
            </Col>

            <Col gap={2.5}>
              <TableHeader>Compliance Tier</TableHeader>
              <TableData>
                {complianceTierToComplianceSummary(wizardState.complianceTier)}
              </TableData>
            </Col>

            <Col gap={2.5}>
              <TableHeader>Wallet Requirements</TableHeader>
              <TableData>
                {walletRequirementsString(wizardState.walletRequirements)}
              </TableData>
            </Col>

            <Col gap={2.5}>
              <TableHeader>Blocked Regions</TableHeader>
              <div className='grid grid-cols-2 md:grid-cols-4 gap-2'>
                {blockedCountries.map((country, i) => (
                  <div key={i} className='col-span-1'>
                    <Text className=''>{country.label}</Text>
                  </div>
                ))}
              </div>
            </Col>
          </Col>
        </Card>
      </Section>

      {wizardState?.documents.length > 0 && (
        <Section>
          <SectionTitle>Event Documents</SectionTitle>
          {wizardState?.documents.map((section, i) => {
            return (
              <Row xalign={'between'} key={i} className='document-section'>
                <Row width={'fit'} gap={2} place='center'>
                  <PDFIcon />
                  <Text>
                    {section.documentTitle} - {section.uploadedFile?.filename}
                  </Text>
                </Row>
                <Row width={'fit'} gap={2} place='center'>
                  <span>
                    <AiFillEye fill='var(--primary)' size={22} />
                  </span>
                  <Text>
                    {documentShowTypeToString(section.showDocumentTo)}
                  </Text>
                </Row>
              </Row>
            );
          })}
        </Section>
      )}

      {wizardState.privacyConfiguration && (
        <EventPrivacyOverview
          privacyConfiguration={wizardState.privacyConfiguration}
        />
      )}
    </Stacked>
  );
};

export default EventCreateConfirmation;
