import { Error, LoadingIndicator } from '@tokensoft-web/common-ui';
import {
  DEFAULT_EVENT_HEADER_BG_COLOR,
  DEFAULT_EVENT_HEADER_FONT_COLOR,
  EVENT_STATUS,
  EVENT_TYPE,
  useAnalytics,
} from '@tokensoft-web/common-utils';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import EventClosed from '../../components/event/event-closed';
import EventDetails from '../../components/event/event-details';
import EventHeaderWrapper from '../../components/event/event-header-wrapper';
import EventUpcoming from '../../components/event/event-upcoming';
import { DistributorProvider } from '../../contexts/distributor/distributor-provider';
import { useEvent } from '../../contexts/event/event-me-context';
import { EVENT_ME_VIEW } from '../../contexts/event/event-me-provider';
import { SaleProvider } from '../../contexts/sale/sale-provider';
import Claim from '../../pages/claims/claim';
import Sale from '../../pages/sale/sale';

const Event = () => {
  const { eventId } = useParams();
  const { event, view, setView, loading: eventLoading } = useEvent();
  const { hash } = useLocation();

  const contentHeaderBgColor =
    event?.contentHeaderBgColor || DEFAULT_EVENT_HEADER_BG_COLOR;
  const contentHeaderBgImage = event?.contentHeaderBgImage;
  const contentHeaderFontColor =
    event?.contentHeaderFontColor || DEFAULT_EVENT_HEADER_FONT_COLOR;

  useAnalytics(`/event/${eventId}`);

  useEffect(() => {
    if (hash) {
      setView(hash.replace('#', ''));
    }
  }, [hash]);

  useEffect(() => {
    if (event) {
      const eventStatus = event?.status;
      const eventType = event?.type;

      if (eventStatus === EVENT_STATUS.UPCOMING) {
        setView(EVENT_ME_VIEW.UPCOMING);
      } else if (eventStatus === EVENT_STATUS.COMPLETED) {
        setView(EVENT_ME_VIEW.CLOSED);
      } else if (eventType === EVENT_TYPE.DISTRIBUTE) {
        setView(EVENT_ME_VIEW.CLAIM);
      } else if (
        eventType === EVENT_TYPE.SALE ||
        eventType === EVENT_TYPE.RAISE
      ) {
        setView(EVENT_ME_VIEW.SALE);
      }
    }
  }, [event]);

  const renderStep = () => {
    switch (view) {
      case EVENT_ME_VIEW.UPCOMING:
        return <EventUpcoming />;
      case EVENT_ME_VIEW.CLAIM:
        return (
          <DistributorProvider>
            <Claim />
          </DistributorProvider>
        );
      case EVENT_ME_VIEW.SALE:
        return (
          <SaleProvider>
            <Sale />
          </SaleProvider>
        );
      case EVENT_ME_VIEW.EVENT_DETAILS:
        return <EventDetails />;
      case EVENT_ME_VIEW.CLOSED:
        return <EventClosed />;
      default:
        return <EventUpcoming />;
    }
  };

  if (eventLoading) {
    return (
      <EventHeaderWrapper
        backgroundColor={contentHeaderBgColor}
        backgroundImg={contentHeaderBgImage}
      >
        <LoadingIndicator text='Loading Event' color={contentHeaderFontColor} />
      </EventHeaderWrapper>
    );
  }

  if (!eventLoading && (!event || event.hidden)) {
    return (
      <EventHeaderWrapper
        backgroundColor={contentHeaderBgColor}
        backgroundImg={contentHeaderBgImage}
      >
        <Error
          title='Event Not Found'
          message="It looks like this isn't a valid event."
          style={{ color: contentHeaderFontColor }}
        />
      </EventHeaderWrapper>
    );
  }

  return <>{renderStep()}</>;
};

export default Event;
