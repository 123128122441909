import ModalStep from '../modal-step/modal-step';

interface ClaimModalStepProps {
  onStep: number;
}

const ClaimModalStep = ({ onStep }: ClaimModalStepProps) => {
  const steps = ['Start Claim', 'Complete'];

  return (
    <div className='mt-6'>
      <ModalStep activeStep={onStep} steps={steps} />
    </div>
  );
};

export default ClaimModalStep;
