import { createContext, useContext } from 'react';

interface EventContextProps {
  event: any;
  loading: boolean;
  refresh: Function;
  hasSetEventDetails: boolean;
  isOnChainEvent: boolean;
}

export const EventContext = createContext<EventContextProps>(undefined);
EventContext.displayName = 'EventContext';

export const useEvent = (): EventContextProps => {
  return useContext(EventContext);
};
