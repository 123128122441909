import BigNumber from 'bignumber.js';

const DEFAULT_DECIMAL_PLACES = 20;

// prevent scientific notation
BigNumber.config({ EXPONENTIAL_AT: 1e9 });

/**
 * Convert number balance to a native balance in base units
 * @param n
 * @param tokenDecimals
 */
export const toBaseUnits = (
  n: string | number | BigNumber,
  tokenDecimals: number,
): string => {
  // Ensure there is no decimal place
  return new BigNumber(n)
    .multipliedBy(new BigNumber(10).exponentiatedBy(tokenDecimals))
    .toFixed(0);
};

/**
 * Convert the native balance to a decimal number
 * @param balance
 * @param tokenDecimals
 */
export const convertBaseUnitsToDecimal = (
  balance: string | number | BigNumber,
  tokenDecimals: number | string,
  toFixed?: number,
): string => {
  if (!balance) return '0';
  return new BigNumber(balance)
    .dividedBy(new BigNumber(10).exponentiatedBy(tokenDecimals))
    .toFixed(toFixed);
};

export const convertTokenToDollars = (
  tokenBalance: string | number | BigNumber,
  exchangeRate: string | number | BigNumber,
): string => {
  return new BigNumber(tokenBalance)
    .multipliedBy(new BigNumber(exchangeRate))
    .toFixed();
};

/**
 * Format value to 6 significant digits, but omit
 * any trailing zeroes after the decimal
 */
export const formatValue = (
  value: string | number | BigNumber,
  options?: { commas: boolean; decimalPlaces?: number },
) => {
  if (!value) {
    return '-';
  }

  return options?.commas
    ? new BigNumber(new BigNumber(value).toPrecision(6)).toFormat({
        decimalSeparator: '.',
        groupSeparator: ',',
        groupSize: 3,
        ...(options?.decimalPlaces && { decimalPlaces: options.decimalPlaces }),
      })
    : options?.decimalPlaces
      ? new BigNumber(new BigNumber(value).toPrecision(6)).toFixed(
          options.decimalPlaces,
        )
      : new BigNumber(new BigNumber(value).toPrecision(6)).toString();
};

export const fix = (
  value: number | string | BigNumber,
  precision?: number | string,
  format?: any,
) => {
  let fixedValue = value;

  if (precision) {
    fixedValue = new BigNumber(value).toFixed(+precision);
  } else {
    fixedValue = new BigNumber(value).toFixed();
  }

  if (format) {
    fixedValue = new BigNumber(fixedValue).toFormat(format);
  }

  return fixedValue;
};

export const fixAndTrim = (
  value: number | string | BigNumber,
  precision?: number | string,
) => {
  return new BigNumber(fix(value, precision)).toString();
};

export const exp = (
  base: number | string | BigNumber,
  raise: number | string | BigNumber,
  precision?: number | string,
) => {
  const value = new BigNumber(base).exponentiatedBy(new BigNumber(raise));
  return fixAndTrim(value, precision);
};

export const add = (
  addend1: number | string | BigNumber,
  addend2: number | string | BigNumber,
  precision?: number | string,
) => {
  const value = new BigNumber(addend1).plus(new BigNumber(addend2));
  return fixAndTrim(value, precision);
};

export const sub = (
  minuend: number | string | BigNumber,
  subtrahend: number | string | BigNumber,
  precision?: number | string,
) => {
  const value = new BigNumber(minuend).minus(new BigNumber(subtrahend));
  return fixAndTrim(value, precision);
};

export const mult = (
  multiplicand: number | string | BigNumber,
  multiplier: number | string | BigNumber,
  precision?: number | string,
) => {
  const value = new BigNumber(multiplicand).multipliedBy(
    new BigNumber(multiplier),
  );
  return fixAndTrim(value, precision);
};

export const div = (
  dividend: number | string | BigNumber,
  divisor: number | string | BigNumber,
  precision?: number | string,
  decimalPlaces?: number,
) => {
  if (decimalPlaces) {
    BigNumber.config({ DECIMAL_PLACES: decimalPlaces });
  }

  const value = new BigNumber(dividend).dividedBy(new BigNumber(divisor));
  const fixedValue = fixAndTrim(value, precision);

  if (decimalPlaces) {
    BigNumber.config({ DECIMAL_PLACES: DEFAULT_DECIMAL_PLACES });
  }

  return fixedValue;
};

export const gt = (
  value1: number | string | BigNumber,
  value2: number | string | BigNumber,
  decimalPlaces?: number,
) => {
  if (decimalPlaces) {
    return new BigNumber(
      new BigNumber(value1).toFixed(decimalPlaces),
    ).isGreaterThan(
      new BigNumber(new BigNumber(value2).toFixed(decimalPlaces)),
    );
  }

  return new BigNumber(value1).isGreaterThan(new BigNumber(value2));
};

export const gte = (
  value1: number | string | BigNumber,
  value2: number | string | BigNumber,
  decimalPlaces?: number,
) => {
  if (decimalPlaces) {
    return new BigNumber(
      new BigNumber(value1).toFixed(decimalPlaces),
    ).isGreaterThanOrEqualTo(
      new BigNumber(new BigNumber(value2).toFixed(decimalPlaces)),
    );
  }

  return new BigNumber(value1).isGreaterThanOrEqualTo(new BigNumber(value2));
};

export const lt = (
  value1: number | string | BigNumber,
  value2: number | string | BigNumber,
  decimalPlaces?: number,
) => {
  if (decimalPlaces) {
    return new BigNumber(
      new BigNumber(value1).toFixed(decimalPlaces),
    ).isLessThan(new BigNumber(new BigNumber(value2).toFixed(decimalPlaces)));
  }

  return new BigNumber(value1).isLessThan(new BigNumber(value2));
};

export const lte = (
  value1: number | string | BigNumber,
  value2: number | string | BigNumber,
  decimalPlaces?: number,
) => {
  if (decimalPlaces) {
    return new BigNumber(
      new BigNumber(value1).toFixed(decimalPlaces),
    ).isLessThanOrEqualTo(
      new BigNumber(new BigNumber(value2).toFixed(decimalPlaces)),
    );
  }

  return new BigNumber(value1).isLessThanOrEqualTo(new BigNumber(value2));
};

export const eq = (
  value1: number | string | BigNumber,
  value2: number | string | BigNumber,
) => {
  return new BigNumber(value1).eq(new BigNumber(value2));
};

export const ne = (
  value1: number | string | BigNumber,
  value2: number | string | BigNumber,
) => {
  return !new BigNumber(value1).eq(new BigNumber(value2));
};

export const min = (...values: BigNumber.Value[]) => {
  return BigNumber.min(...values).toNumber();
};

export const max = (...values: BigNumber.Value[]) => {
  return BigNumber.max(...values).toNumber();
};

export const sd = (value: number | string | BigNumber) => {
  return new BigNumber(value).sd(true);
};

export const toNumber = (value: number | string | BigNumber) => {
  return new BigNumber(value).toNumber();
};

export const numberToPercent = (num: string | number) => {
  return Number(num) / 100;
};
