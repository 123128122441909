import { createContext, useContext } from 'react';

interface EventMeContextProps {
  event: any;
  view: string;
  setView: Function;
  refresh: Function;
  loading: boolean;
}

export const EventMeContext = createContext<EventMeContextProps>(undefined);
EventMeContext.displayName = 'EventMeContext';

export const useEvent = (): EventMeContextProps => {
  return useContext(EventMeContext);
};
