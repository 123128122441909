import classNames from 'classnames';
import { Col } from './col';
import { Stacked } from './stacked';

export const MaxContentWidth = ({ className, children, ...restProps }) => {
  return (
    <Col className={'px-8'}>
      <Stacked
        className={classNames('mx-auto max-w-6xl', className)}
        {...restProps}
      >
        {children}
      </Stacked>
    </Col>
  );
};
