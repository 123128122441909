import { isEmptyObject, useAuth } from '@tokensoft-web/common-utils';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { Footer } from './footer/footer';
import { Header } from './header/header';
import { Sidebar } from './sidebar/sidebar';

interface LayoutProps {
  children: React.ReactNode;
  fullWidth?: boolean;
  className?: string;
}

export const Layout = ({
  children,
  className,
  fullWidth = false,
}: LayoutProps) => {
  const { user, isAuthenticated } = useAuth();

  return (
    <>
      {fullWidth ? (
        <Helmet>
          <body
            data-page-layout={`full-width`}
            data-auth={
              !isEmptyObject(user) ? 'authenticated' : 'unauthenticated'
            }
          ></body>
        </Helmet>
      ) : (
        <Helmet>
          <body
            data-page-layout={''}
            data-auth={
              !isEmptyObject(user) ? 'authenticated' : 'unauthenticated'
            }
          ></body>
        </Helmet>
      )}
      {isAuthenticated && (
        <>
          <Header />
          <Sidebar />
        </>
      )}

      <div className={classNames(className || '', 'content-body')}>
        <div
          className={classNames(
            'container-fluid',
            !isEmptyObject(user) ? 'max-w-6xl' : 'max-w-full',
          )}
        >
          {children}
          <Footer />
        </div>
      </div>
    </>
  );
};
