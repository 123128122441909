import { useNetworks } from '@tokensoft-web/common-utils';
import classNames from 'classnames';
import { Flex } from '../layout/container/flex';
import { Text } from '../typography/text';

export const NetworkIcon = ({
  chainId = null,
  showNetworkName = true,
  logoSize = 'lg',
  direction = 'row',
  ...restProps
}) => {
  const { getNetworkDetails } = useNetworks();

  const network = getNetworkDetails(chainId);
  const size = logoSize === 'lg' ? 36 : 24;

  return (
    <>
      <Flex gap={2.5} {...restProps} direction={direction}>
        {network ? (
          <img
            src={network.logoUri}
            width={size}
            height={size}
            alt={network.name}
            className={classNames(direction === 'col' ? 'm-auto' : '')}
          />
        ) : null}
        {showNetworkName ? (
          <Text textAlign={'right'}>
            {network ? network.name : 'Unknown Network'}
          </Text>
        ) : null}
      </Flex>
    </>
  );
};
