import classNames from 'classnames';
import { Text } from './text';

export const Title = ({
  className = '',
  textAlign = 'center',
  shrink = false,
  children,
  ...restProps
}) => {
  return (
    <Text
      textAlign={textAlign}
      className={classNames(
        'text-2xl font-bold high-contrast leading-8',
        className,
      )}
      {...restProps}
    >
      {children}
    </Text>
  );
};
