import {
  getResourceUri,
  getValidIPFSHash,
  useConfiguration,
} from '@tokensoft-web/common-utils';
import { Helmet } from 'react-helmet';

export const SeoConfiguration = () => {
  const { configuration } = useConfiguration();

  const title = 'Welcome';

  let name = 'Tokensoft';
  let description =
    'Welcome to Tokensoft - A Fair-launch platform for Web3 Projects & Communities';
  let logo =
    'https://media.tokensoft.io.s3-website-us-east-1.amazonaws.com/logos/tokensoft-meta-logo.jpg';
  let favicon = 'https://s3.amazonaws.com/media.tokensoft.io/logos/favicon.ico';

  if (configuration?.project) {
    name = configuration?.project?.seo?.title || name;
    description = configuration?.project?.seo?.description || description;
    logo = configuration?.project?.seo?.logo || logo;
    favicon = configuration?.project?.theme?.favicon
      ? getValidIPFSHash(configuration?.project?.theme?.favicon)
        ? getResourceUri(configuration?.project?.theme?.favicon)
        : configuration?.project?.theme?.favicon
      : favicon;
  }

  return (
    <>
      <Helmet titleTemplate={`%s | ${name}`} defaultTitle={title}>
        <meta charSet='utf-8' />
        <link rel='icon' href={favicon} />
        <link rel='apple-touch-icon' href={favicon} />

        <meta name='title' content={name} />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='theme-color' content='#000000' />
        <meta name='version' content={process.env.REACT_APP_VERSION} />
        <meta name='description' content={description} />

        <meta property='og:type' content='website' />
        <meta property='og:title' content={name} />
        <meta property='og:description' content={description} />
        <meta property='og:image' content={logo} />

        <meta property='twitter:title' content={name} />
        <meta property='twitter:description' content={description} />
        <meta property='twitter:image' content={logo} />
        <meta name='twitter:card' content='summary_large_image' />
      </Helmet>
    </>
  );
};
