import classNames from 'classnames';
import { Row } from '../layout/container/row';
import './button.css';

export const ButtonLink = ({
  className = '',
  href = null,
  target = null,
  color = 'primary',
  fill = null,
  size = null,
  disabled = false,
  children,
  ...restProps
}) => {
  return (
    <a
      href={href}
      target={target}
      rel='noreferrer'
      onClick={(e) => e.stopPropagation()}
      className={classNames(
        'btn truncate',
        !fill ? `btn-${color}` : '',
        fill ? `btn-${fill}-${color}` : '',
        size ? `btn-${size}` : '',
        disabled ? 'disabled' : '',
        className,
      )}
      {...restProps}
    >
      <Row nowrap gap={2.5} place={'center'}>
        {children}
      </Row>
    </a>
  );
};
