import {
  Col,
  HelpPopover,
  InfoIcon,
  Row,
  Text,
} from '@tokensoft-web/common-ui';
import { EVENT_TYPE } from '@tokensoft-web/common-utils';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useDocumentUpload } from '../../contexts/event/document-upload-context';
import './document-signature-container.css';
import { EditableDocumentField } from './editable-document-field';

export const DocumentSignatureContainer = () => {
  const { documentData, setDocumentData, eventType } = useDocumentUpload();

  const handleCompanyChanged = (value) => {
    setDocumentData({ ...documentData, company: value });
  };

  const handleCompanyNameChanged = (value) => {
    setDocumentData({ ...documentData, companyName: value });
  };

  const handleSignerNameChanged = (value) => {
    setDocumentData({ ...documentData, signerName: value });
  };

  const handleSignerTitleChanged = (value) => {
    setDocumentData({ ...documentData, signerTitle: value });
  };

  const handleRecipientChanged = (value) => {
    setDocumentData({ ...documentData, recipient: value });
  };

  const handleAddendumChanged = (value) => {
    setDocumentData({ ...documentData, addendum: value });
  };

  return (
    <div className='document-signature-container'>
      <Row className='document-signature-header text-white'>
        <GiHamburgerMenu />
        <Text className='text-xs ml-4'>Signature Page</Text>
      </Row>
      <Col className='document-signature-body high-contrast'>
        <Row className='mt-8'>
          <Text className='document-underline-text text-md md:text-xl'>
            IN WITNESS WHEREOF, the parties have executed this Agreement as of
          </Text>
          <span className='document-underline-text md:w-32 w-5'></span>
          <HelpPopover
            divClassName='document-underline-text'
            className='max-w-md'
            button={
              <div className='block cursor-pointer' onClick={() => {}}>
                <InfoIcon fill={'var(--neutral)'} />
              </div>
            }
          >
            <Text>The date the end user signs this document</Text>
          </HelpPopover>
          <span className='document-underline-text w-2'></span>
          <Text className='document-underline-text text-xl'>.</Text>
        </Row>

        <EditableDocumentField
          className={'mt-12'}
          value={documentData.company}
          uneditedText={'COMPANY'}
          textClassName={
            'document-underline-text uppercase font-semibold text-2xl'
          }
          popoverText={
            'The term used to describe your legal entity throughout the document'
          }
          onSave={(value) => handleCompanyChanged(value)}
          required
        />

        <div className='grid grid-cols-2 sm:grid-cols-6 gap-y-1	mt-8'>
          <Row className='col-span-2'>
            <Text className='text-2xl'>By:</Text>
          </Row>
          <EditableDocumentField
            className={'col-span-2 sm:col-span-4'}
            value={
              documentData.signerName ? `s/${documentData.signerName}` : ''
            }
            uneditedText={'s/Signer Name'}
            textClassName={'font-semibold text-xl'}
            popoverText={
              'The signer’s electronic signature (matches the signer’s name)'
            }
          />
        </div>

        <div className='grid grid-cols-2 sm:grid-cols-6 gap-y-1 mt-8'>
          <Row className='col-span-2'>
            <Text className='text-2xl'>Name: *</Text>
          </Row>
          <EditableDocumentField
            className={'col-span-2 sm:col-span-4'}
            value={documentData.signerName}
            uneditedText={'Signer Name'}
            textClassName={'font-semibold text-xl'}
            popoverText={'The signer’s name'}
            onSave={(value) => handleSignerNameChanged(value)}
          />
        </div>

        <div className='grid grid-cols-2 sm:grid-cols-6 gap-y-1 mt-8'>
          <Row className='col-span-2'>
            <Text className='text-2xl'>Title: *</Text>
          </Row>
          <EditableDocumentField
            className={'col-span-2 sm:col-span-4'}
            value={documentData.signerTitle}
            uneditedText={'Signer Title'}
            textClassName={'font-semibold text-xl'}
            popoverText={'The signer’s job title at your entity'}
            onSave={(value) => handleSignerTitleChanged(value)}
          />
        </div>

        <div className='grid grid-cols-2 sm:grid-cols-6 gap-y-1 mt-8'>
          <Row className='col-span-2'>
            <Text className='text-2xl'>Company: *</Text>
          </Row>
          <EditableDocumentField
            className={'col-span-2 sm:col-span-4'}
            value={documentData.companyName}
            uneditedText={'Company Name'}
            textClassName={'font-semibold text-xl'}
            popoverText={'The title of your entity'}
            onSave={(value) => handleCompanyNameChanged(value)}
          />
        </div>

        <Row className='mt-12' yalign={'center'}>
          <EditableDocumentField
            className={'col-span-2 sm:col-span-4'}
            value={documentData.recipient}
            uneditedText={'RECIPIENT'}
            textClassName={
              'document-underline-text uppercase font-semibold text-2xl'
            }
            popoverText='The term used to describe your counterparty throughout the document'
            onSave={(value) => handleRecipientChanged(value)}
          />
        </Row>

        <div className='grid grid-cols-4 md:grid-cols-6 mt-8'>
          <Row className='col-span-2'>
            <Text className='text-2xl'>By:</Text>
          </Row>
          <Row className='col-span-2'>
            <Text className='document-underline-text-light font-semibold w-40 text-2xl'>
              s/
            </Text>
            <HelpPopover
              divClassName='document-underline-text-light'
              className='max-w-md'
              button={
                <div className='block cursor-pointer' onClick={() => {}}>
                  <InfoIcon fill={'var(--neutral)'} />
                </div>
              }
            >
              <Text>We will fill this in with your counterparty's name.</Text>
            </HelpPopover>
          </Row>
        </div>

        <div className='grid grid-cols-4 md:grid-cols-6 mt-8'>
          <Row className='col-span-2'>
            <Text className='text-2xl'>Name:</Text>
          </Row>
          <Row className='col-span-2'>
            <Text className='document-underline-text-light font-semibold w-40 text-2xl'></Text>
            <HelpPopover
              divClassName='document-underline-text-light'
              className='max-w-md'
              button={
                <div className='block cursor-pointer' onClick={() => {}}>
                  <InfoIcon fill={'var(--neutral)'} />
                </div>
              }
            >
              <Text>We will fill this in with your counterparty's name.</Text>
            </HelpPopover>
          </Row>
        </div>

        {eventType === EVENT_TYPE.SALE || eventType === EVENT_TYPE.RAISE ? (
          <Col className='vertical relative'>
            <Row className='mt-12 mb-12' yalign={'center'}>
              <EditableDocumentField
                className={'col-span-2 sm:col-span-4'}
                value={documentData.addendum}
                uneditedText={'ADDENDUM'}
                textClassName={
                  'document-underline-text uppercase font-semibold text-2xl'
                }
                popoverText=' The label for the page(s) attached to the end of this document including purchase details'
                onSave={(value) => handleAddendumChanged(value)}
              />
            </Row>
            <Row>
              <Text className='text-2xl'>Confirmed Purchase Details</Text>
            </Row>
            <div className='grid grid-cols-4 md:grid-cols-6 mt-8'>
              <Row className='col-span-2'>
                <Text className='text-2xl'>Transaction ID:</Text>
              </Row>
              <Row className='col-span-2'>
                <Text className='document-underline-text-light font-semibold w-40 text-2xl'></Text>
                <HelpPopover
                  divClassName='document-underline-text-light'
                  className='max-w-md'
                  button={
                    <div className='block cursor-pointer' onClick={() => {}}>
                      <InfoIcon fill={'var(--neutral)'} />
                    </div>
                  }
                >
                  <Text>
                    We will fill this in with your purchase transaction details.
                  </Text>
                </HelpPopover>
              </Row>
            </div>
            <div className='grid grid-cols-4 md:grid-cols-6 mt-8'>
              <Row className='col-span-2'>
                <Text className='text-2xl'>Method:</Text>
              </Row>
              <Row className='col-span-2'>
                <Text className='document-underline-text-light font-semibold w-40 text-2xl'></Text>
                <HelpPopover
                  divClassName='document-underline-text-light'
                  className='max-w-md'
                  button={
                    <div className='block cursor-pointer' onClick={() => {}}>
                      <InfoIcon fill={'var(--neutral)'} />
                    </div>
                  }
                >
                  <Text>
                    We will fill this in with your purchase transaction details.
                  </Text>
                </HelpPopover>
              </Row>
            </div>
            <div className='grid grid-cols-4 md:grid-cols-6 mt-8'>
              <Row className='col-span-2'>
                <Text className='text-2xl'>You bought:</Text>
              </Row>
              <Row className='col-span-2'>
                <Text className='document-underline-text-light font-semibold w-40 text-2xl'></Text>
                <HelpPopover
                  divClassName='document-underline-text-light'
                  className='max-w-md'
                  button={
                    <div className='block cursor-pointer' onClick={() => {}}>
                      <InfoIcon fill={'var(--neutral)'} />
                    </div>
                  }
                >
                  <Text>
                    We will fill this in with your purchase transaction details.
                  </Text>
                </HelpPopover>
              </Row>
            </div>
            <div className='grid grid-cols-4 md:grid-cols-6 mt-8'>
              <Row className='col-span-2'>
                <Text className='text-2xl'>Contribution amount:</Text>
              </Row>
              <Row className='col-span-2'>
                <Text className='document-underline-text-light font-semibold w-40 text-2xl'></Text>
                <HelpPopover
                  divClassName='document-underline-text-light'
                  className='max-w-md'
                  button={
                    <div className='block cursor-pointer' onClick={() => {}}>
                      <InfoIcon fill={'var(--neutral)'} />
                    </div>
                  }
                >
                  <Text>
                    We will fill this in with your purchase transaction details.
                  </Text>
                </HelpPopover>
              </Row>
            </div>
            <div className='grid grid-cols-4 md:grid-cols-6 mt-8'>
              <Row className='col-span-2'>
                <Text className='text-2xl'>Receiving wallet:</Text>
              </Row>
              <Row className='col-span-2'>
                <Text className='document-underline-text-light font-semibold w-40 text-2xl'></Text>
                <HelpPopover
                  divClassName='document-underline-text-light'
                  className='max-w-md'
                  button={
                    <div className='block cursor-pointer' onClick={() => {}}>
                      <InfoIcon fill={'var(--neutral)'} />
                    </div>
                  }
                >
                  <Text>We will fill this in with your purchase wallet.</Text>
                </HelpPopover>
              </Row>
            </div>
          </Col>
        ) : null}
      </Col>
    </div>
  );
};
