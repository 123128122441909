import { Col } from '@tokensoft-web/common-ui';
import EventBodyWrapper from '../../components/event/event-body-wrapper';
import EventContent from '../../components/event/event-content';
import EventEligibility from '../../components/event/event-eligibility';
import EventHeader from '../../components/event/event-header';
import EventHeaderWrapper from '../../components/event/event-header-wrapper';
import { useSale } from '../../contexts/sale/sale-context';

const SaleDetail = () => {
  const { sale, refresh } = useSale();
  const event = sale?.event;

  const description = event?.contentBodyOpen;
  const contentHeaderImage = event?.contentHeaderImage;
  const contentHeaderFontColor = event?.contentHeaderFontColor;
  const contentHeaderBgColor = event?.contentHeaderBgColor;
  const contentHeaderSubtitle = event?.contentHeaderSubtitle;
  const contentHeaderTitle = event?.contentHeaderTitle;
  const contentHeaderBgImage = event?.contentHeaderBgImage;
  const themeLayoutVersion = event?.themeLayoutVersion;

  return (
    <Col gap={10}>
      <EventHeaderWrapper
        backgroundColor={contentHeaderBgColor}
        backgroundImg={contentHeaderBgImage}
      >
        <EventHeader
          headerTitle={contentHeaderTitle}
          headerSubtitle={contentHeaderSubtitle}
          contentHeaderImage={contentHeaderImage}
          contentHeaderFontColor={contentHeaderFontColor}
          themeLayoutVersion={themeLayoutVersion}
        />
      </EventHeaderWrapper>
      <EventBodyWrapper>
        <EventContent bodyContent={description} />

        <EventEligibility event={sale.event} refreshEvent={refresh} />
      </EventBodyWrapper>
    </Col>
  );
};

export default SaleDetail;
