import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  EditIcon,
  Row,
  Section,
  SectionTitle,
  Stacked,
  Stretched,
  TableData,
  TableHeader,
  Text,
  TextLink,
} from '@tokensoft-web/common-ui';
import {
  capitalize,
  convertBaseUnitsToDecimal,
  formatValue,
  getTruncatedAddress,
  isValidDate,
  unixToLocalDateTime,
  useAccount,
  useNetworks,
  utcToLocalDateTime,
} from '@tokensoft-web/common-utils';
import { useState } from 'react';
import { VscLinkExternal } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import { DEPLOY_VESTING_TYPE_OPTIONS } from '../../utils/enums';
import { getAddressUrl } from '../../utils/network';

export const DistributorVestingSummary = ({ context, className = '' }) => {
  const { account } = useAccount();
  const navigate = useNavigate();
  const { getNetworkDetails } = useNetworks();
  const [isOpen, setIsOpen] = useState(!Object.keys(context).includes('id'));

  const getVestingType = (data) => {
    const vestingType =
      data?.vestingType ?? DEPLOY_VESTING_TYPE_OPTIONS.CONTINUOUS;
    return capitalize(vestingType);
  };

  let formattedStartTime;
  let formattedCliffTime;
  let formattedEndTime;

  if (context?.unixStartTime) {
    formattedStartTime = unixToLocalDateTime(
      context.unixStartTime,
      account?.timezone,
    );
  } else {
    formattedStartTime = isValidDate(context.startTime)
      ? utcToLocalDateTime(context.startTime, account?.timezone)
      : '-';
  }

  if (context?.unixCliffTime) {
    formattedCliffTime = unixToLocalDateTime(
      context.unixCliffTime,
      account?.timezone,
    );
  } else {
    formattedCliffTime = context.cliffTime
      ? utcToLocalDateTime(context.cliffTime, account?.timezone)
      : '-';
  }

  if (context?.unixEndTime) {
    formattedEndTime = unixToLocalDateTime(
      context.unixEndTime,
      account?.timezone,
    );
  } else {
    formattedEndTime = context.endTime
      ? utcToLocalDateTime(context.endTime, account?.timezone)
      : '-';
  }

  const formattedVestingType = getVestingType(context);
  const formattedTotalParticipants = context?.totalParticipants
    ? `${formatValue(context?.totalParticipants, { commas: true })} addresses`
    : '-';
  const formattedTotalAllocations = context?.totalAllocations
    ? `${formatValue(
        convertBaseUnitsToDecimal(
          context?.totalAllocations,
          context?.tokenInfo?.decimals || 18,
          2,
        ),
        { commas: true },
      )} ${context.tokenInfo?.symbol}`
    : '-';

  const key = 'distributor-summary';

  return (
    <Card className={className} id={`${key}-card`}>
      <CardHeader place={'center'}>
        <CardTitle
          open={isOpen}
          onOpen={context.id ? setIsOpen : null}
          data-testid={`${key}-title`}
        >
          Distributor Details
        </CardTitle>
        {context.id ? (
          <TextLink
            width={'w-fit'}
            onClick={() => {
              navigate(
                `/admin/project/${context.event.project.id}/event/${context.event.id}/distributor/vesting`,
              );
            }}
          >
            <EditIcon />
            <Text>Edit Details</Text>
          </TextLink>
        ) : null}
      </CardHeader>

      <CardBody open={isOpen}>
        <Stacked>
          {context.id ? (
            <Section gap={5} place={'start'}>
              <SectionTitle data-testid={`${key}-contract-details-title`}>
                Contract Details
              </SectionTitle>
              <Stretched gap={5}>
                <Stretched ygap={5}>
                  <Col gap={2.5} className={'w-full md:w-1/2'}>
                    <TableHeader>Token</TableHeader>
                    <TextLink place={'start-center'}>
                      <a
                        target='_blank'
                        rel='noreferrer'
                        href={getAddressUrl(
                          context?.token,
                          getNetworkDetails(context.chainId),
                        )}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Row nowrap place={'start-center'} gap={2.5}>
                          <Text className={'font-normal'}>
                            {getTruncatedAddress(context?.token)}
                          </Text>
                          <VscLinkExternal />
                        </Row>
                      </a>
                    </TextLink>
                  </Col>

                  <Col gap={2.5} className={'w-full md:w-1/2'}>
                    <TableHeader>Distributor</TableHeader>
                    <TextLink place={'start-center'}>
                      <a
                        target='_blank'
                        rel='noreferrer'
                        href={getAddressUrl(
                          context?.id,
                          getNetworkDetails(context.chainId),
                        )}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Row nowrap place={'start-center'} gap={2.5}>
                          <Text className={'font-normal'}>
                            {getTruncatedAddress(context?.id)}
                          </Text>
                          <VscLinkExternal />
                        </Row>
                      </a>
                    </TextLink>
                  </Col>
                </Stretched>

                <Stretched>
                  <Col gap={2.5} width={'1/2'} className={'hidden md:block'}>
                    <TableHeader>&nbsp;</TableHeader>
                    <TableData>&nbsp;</TableData>
                  </Col>

                  <Col gap={2.5} width={'1/2'} className={'hidden md:block'}>
                    <TableHeader>&nbsp;</TableHeader>
                    <TableData>&nbsp;</TableData>
                  </Col>
                </Stretched>
              </Stretched>
            </Section>
          ) : null}

          {!context.id ? (
            <Section gap={5} place={'start'}>
              <SectionTitle data-testid={`${key}-participants-title`}>
                Participants
              </SectionTitle>
              <Stretched gap={5} place={'start'}>
                <Stretched ygap={5}>
                  <Col gap={2.5} className={'w-full md:w-1/2'}>
                    <TableHeader
                      data-testid={`${key}-total-participants-header`}
                    >
                      Total Participants
                    </TableHeader>
                    <TableData data-testid={`${key}-total-participants-data`}>
                      {formattedTotalParticipants}
                    </TableData>
                  </Col>

                  <Col gap={2.5} className={'w-full md:w-1/2'}>
                    <TableHeader data-testid={`${key}-total-amount-header`}>
                      Total Amount
                    </TableHeader>
                    <TableData data-testid={`${key}-total-amount-data`}>
                      {formattedTotalAllocations}
                    </TableData>
                  </Col>
                </Stretched>
                <Row nowrap></Row>
              </Stretched>
            </Section>
          ) : null}

          <Section gap={5} place={'start'}>
            <SectionTitle data-testid={`${key}-vesting-schedule-title`}>
              Vesting Schedule
            </SectionTitle>
            <Stretched gap={5} place={'start'}>
              <Stretched ygap={5}>
                <Col gap={2.5} className={'w-full md:w-1/2'}>
                  <TableHeader data-testid={`${key}-vesting-type-header`}>
                    Vesting Type
                  </TableHeader>
                  <TableData data-testid={`${key}-vesting-type-data`}>
                    {formattedVestingType}
                  </TableData>
                </Col>
                <Col gap={2.5} className={'w-full md:w-1/2'}>
                  <TableHeader data-testid={`${key}-start-date-header`}>
                    Start Date
                  </TableHeader>
                  <TableData data-testid={`${key}-start-date-data`}>
                    {formattedStartTime}
                  </TableData>
                </Col>
              </Stretched>

              {formattedVestingType !==
              capitalize(DEPLOY_VESTING_TYPE_OPTIONS.INSTANT) ? (
                <Stretched gap={5}>
                  <Col gap={2.5} className={'w-full md:w-1/2'}>
                    <TableHeader data-testid={`${key}-cliff-date-header`}>
                      Cliff Date
                    </TableHeader>
                    <TableData data-testid={`${key}-cliff-date-data`}>
                      {context.startTime !== context.cliffTime
                        ? formattedCliffTime
                        : 'n/a'}
                    </TableData>
                  </Col>

                  <Col gap={2.5} className={'w-full md:w-1/2'}>
                    <TableHeader data-testid={`${key}-end-date-header`}>
                      End Date
                    </TableHeader>
                    <TableData data-testid={`${key}-end-date-data`}>
                      {formattedEndTime}
                    </TableData>
                  </Col>
                </Stretched>
              ) : (
                <Row nowrap></Row>
              )}
            </Stretched>
          </Section>
        </Stacked>
      </CardBody>
    </Card>
  );
};
