import {
  BackButton,
  Button,
  ButtonRow,
  Col,
  LoadingIndicator,
  PageSubtitle,
  PageTitle,
  Step,
  StepFooter,
  StepHeader,
  Text,
  TextLink,
} from '@tokensoft-web/common-ui';
import { eq, gt, sub, useGetDistributor } from '@tokensoft-web/common-utils';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wizard } from 'react-use-wizard';
import DistributorFund from '../../../components/admin/distributors/distributor-fund';
import { useEvent } from '../../../contexts/event/event-context';

const WizardContext = createContext(null);

export const EditDistributorFundWizard = () => {
  const navigate = useNavigate();
  const { event } = useEvent();
  const { distributor, loading, refresh } = useGetDistributor(
    event.distributor?.id,
  );

  const [wizardState, setWizardState] = useState<any>({});

  useEffect(() => {
    if (distributor) {
      const additionalAmountToFund =
        distributor.tokenBalance &&
        sub(
          sub(distributor.totalAmount, distributor.claimed),
          distributor.tokenBalance,
        );
      const requiresAdditionalFunding = gt(additionalAmountToFund, 0);
      const requiredBalance = distributor.tokenBalance
        ? sub(distributor.totalAmount, distributor.claimed)
        : 0;
      const hasExcessFunds =
        distributor.tokenBalance &&
        gt(
          distributor.tokenBalance,
          sub(distributor.totalAmount, distributor.claimed),
        );
      const correctlyFunded =
        distributor.tokenBalance &&
        eq(
          distributor.tokenBalance,
          sub(distributor.totalAmount, distributor.claimed),
        );
      const excessAmount =
        hasExcessFunds &&
        sub(
          distributor.tokenBalance,
          sub(distributor.totalAmount, distributor.claimed),
        );

      setWizardState({
        ...event,
        refresh: refresh,
        tokenInfo: {
          ...distributor,
          totalSupply: distributor.tokenBalance,
          additionalAmountToFund: additionalAmountToFund,
          excessAmount: excessAmount,
          requiredBalance: requiredBalance,
          requiresAdditionalFunding: requiresAdditionalFunding,
          hasExcessFunds: hasExcessFunds,
          correctlyFunded: correctlyFunded,
          tokenBalance: distributor.tokenBalance,
          decimals: distributor.tokenDecimals,
          symbol: distributor.tokenSymbol,
        },
      });
    }
  }, [distributor]);

  const handleNavigateDashboard = () => {
    navigate(`/admin/project/${event.project.id}/event/${event.id}/`);
  };

  // Distributor has not yet been created on subgraph
  if (event && !event.distributor?.id) {
    return (
      <Col>
        <StepHeader>
          <PageTitle>Fund</PageTitle>
          <PageSubtitle>
            Your distributor contract has been deployed but it is not ready yet.
            Refresh or come back in a few minutes.
          </PageSubtitle>
        </StepHeader>
        <ButtonRow>
          <TextLink
            place={'end'}
            className='underline self-center'
            onClick={handleNavigateDashboard}
          >
            <Text>Dashboard</Text>
          </TextLink>
          <Button
            disabled={loading}
            onClick={() => {
              refresh();
            }}
          >
            {loading ? <Text>Refreshing...</Text> : <Text>Refresh</Text>}
          </Button>
        </ButtonRow>
      </Col>
    );
  }

  return (
    <WizardContext.Provider
      value={{
        wizardState,
        setWizardState,
      }}
    >
      <Wizard>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <Step>
            <StepHeader>
              <PageTitle>Fund</PageTitle>
              {wizardState.tokenInfo?.id ? (
                <PageSubtitle>
                  Your distributor contract is almost ready. Deposit tokens into
                  the distributor contract to complete setup. You can add more
                  funds as well as refund tokens at any time after initial
                  setup.
                </PageSubtitle>
              ) : (
                <PageSubtitle>
                  Your distributor contract has been deployed but it is not
                  ready yet. Please come back in a few minutes.
                </PageSubtitle>
              )}
            </StepHeader>

            {wizardState.tokenInfo?.id ? (
              <DistributorFund context={wizardState} />
            ) : null}

            <StepFooter>
              <ButtonRow place='end'>
                {wizardState?.tokenInfo?.requiresAdditionalFunding ? (
                  <TextLink
                    place={'end'}
                    className='underline self-center'
                    onClick={handleNavigateDashboard}
                  >
                    <Text>Fund Later & Continue</Text>
                  </TextLink>
                ) : null}
                <BackButton
                  label={'Dashboard'}
                  onClick={handleNavigateDashboard}
                />
              </ButtonRow>
            </StepFooter>
          </Step>
        )}
      </Wizard>
    </WizardContext.Provider>
  );
};

export const useWizardContext = () => {
  const context = useContext(WizardContext);
  if (!context) {
    throw new Error(
      'Wizard compound components cannot be rendered outside the Wizard Component',
    );
  }

  return context;
};
