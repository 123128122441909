import {
  getTxUrl,
  useNetworks,
  useToast,
  useWallet,
} from '@tokensoft-web/common-utils';
import { useEffect } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { IoChevronDownCircleOutline } from 'react-icons/io5';
import { VscLinkExternal } from 'react-icons/vsc';
import { PaymentMethod } from '../../utils/interface';
import { useBuyTransaction } from '../../utils/sale';

interface PurchaseNativeButtonProps {
  disabled: boolean;
  paymentMethod: PaymentMethod;
  toAddress: string;
  proof?: string[];
  value?: string;
  getReceipt?: Function;
  saleId: string;
}

const PurchaseNativeButton = ({
  disabled,
  paymentMethod,
  toAddress,
  proof,
  value,
  getReceipt,
  saleId,
}: PurchaseNativeButtonProps) => {
  const { showSuccessToast, showErrorToast } = useToast();
  const { connectedChainId, supportedNetwork } = useWallet();
  const { getNetworkDetails } = useNetworks();
  const {
    write: sendBuyTransaction,
    data: receipt,
    error,
    isError,
    isLoading,
    isSubmitting,
  } = useBuyTransaction();

  useEffect(() => {
    if (receipt) {
      if (receipt.status === 'success') {
        getReceipt({
          receipt: receipt.transactionHash,
          toAddress,
          fromAddress: receipt.from,
          paymentMethod: paymentMethod.symbol,
          value,
          saleId,
        });
      } else {
        showErrorToast({
          description: 'Error completing transaction.',
        });
      }
    }
  }, [receipt]);

  useEffect(() => {
    if (error) {
      showErrorToast({
        description: error.toString(),
      });
    }
  }, [error]);

  const purchaseNative = async () => {
    try {
      const transactionHash = await sendBuyTransaction(
        connectedChainId,
        paymentMethod,
        toAddress,
        value,
        proof,
      );

      if (transactionHash) {
        showSuccessToast({
          description: (
            <div className='flex flex-row'>
              Submitted transaction.
              <a
                target='_blank'
                rel='noreferrer'
                href={getTxUrl(
                  transactionHash,
                  getNetworkDetails(connectedChainId),
                )}
                className='w-[30px] flex items-center justify-center text-white'
                onClick={(e) => e.stopPropagation()}
              >
                <VscLinkExternal color='white' />
              </a>
            </div>
          ),
        });
      }
    } catch (e) {
      console.error(e.message);
      showErrorToast({
        description:
          'The transaction could not be completed, please try again later.',
      });
    }
  };

  const disablePurchaseButton =
    value === '0' || disabled || !supportedNetwork || isLoading || isSubmitting;
  const showLoadingIndicator = (isLoading || isSubmitting) && !isError;

  return (
    <>
      <button
        data-test-id='purchase-button'
        className={'btn btn-primary'}
        onClick={purchaseNative}
        disabled={disablePurchaseButton}
      >
        {showLoadingIndicator ? (
          <div className='flex flex-row'>
            <div className='animate-spin'>
              <AiOutlineLoading3Quarters size={24} />
            </div>
            <span className='pl-2'>Purchasing...</span>
          </div>
        ) : (
          <div className='flex flex-row justify-center'>
            <IoChevronDownCircleOutline className='text-xl mr-1 mt-[2px]' />
            <span className='self-center font-semibold'>{'Purchase'}</span>
          </div>
        )}
      </button>
    </>
  );
};

export default PurchaseNativeButton;
