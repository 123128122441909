import {
  Button,
  Col,
  ModalTitle,
  PreparingIcon,
  Stacked,
  Text,
} from '@tokensoft-web/common-ui';
import { isEmptyObject } from '@tokensoft-web/common-utils';
import { useEffect, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useDeployDistributor } from '../../../utils/distributor';

interface DeployDistributorModalProps {
  preparedData?: any;
  vestingType?: string;
  onSuccess?: Function;
  onError?: Function;
}

const DeployDistributorModal = ({
  preparedData,
  vestingType,
  onSuccess,
  onError,
}: DeployDistributorModalProps) => {
  const { deployedAddress, deployDistributor, receipt, error, isPending } =
    useDeployDistributor();
  const [submitted, setSubmitted] = useState(false);
  const key = 'deploy-distributor-modal';

  useEffect(() => {
    if (!isEmptyObject(receipt)) {
      console.log('Deploy distributor transaction receipt', receipt);
      if (receipt.status === 'success') {
        onSuccess &&
          onSuccess({ ...receipt, contractAddress: deployedAddress });
      }
    }
  }, [receipt]);

  useEffect(() => {
    if (!isEmptyObject(error)) {
      onError && onError(error.toString());
      setSubmitted(false);
    }
  }, [error]);

  useEffect(() => {
    setSubmitted(isPending);
  }, [isPending]);

  return (
    <Stacked>
      <Col data-testid={key} gap={5} place={'center'}>
        <PreparingIcon />
        <ModalTitle data-testid={`${key}-title`}>
          {'Deploy Your Distributor'}
        </ModalTitle>
        <Text data-testid={`${key}-subtitle`} width='3/4' textAlign={'center'}>
          Your distributor contract is ready to deploy! You’ll be able to update
          configuration after deployment.
        </Text>
      </Col>
      <Button
        data-testid={`${key}-button`}
        color={'primary'}
        onClick={() => deployDistributor(vestingType, preparedData)}
        disabled={submitted}
      >
        {submitted ? (
          <>
            <div className='animate-spin'>
              <AiOutlineLoading3Quarters size={24} />
            </div>

            <Text>Deploying...</Text>
          </>
        ) : (
          <Text>Deploy</Text>
        )}
      </Button>
    </Stacked>
  );
};

export default DeployDistributorModal;
