import { PaginationState } from '@tanstack/react-table';
import {
  Button,
  Card,
  LoadingIndicator,
  Row,
  TableComponent,
} from '@tokensoft-web/common-ui';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import {
  USER_PAGE_TABLE_CONFIG,
  USER_PAGE_TABLE_RELATIONSHIP,
} from '../../../services/users-page-table-service';

export const UserDetailsTables = ({ data }: { data: any }) => {
  const pageSizeList = [50, 100, 200];
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSizeList[0],
  });
  const userPageTablesRelationship = USER_PAGE_TABLE_RELATIONSHIP;
  const [tableConfigDisplaying, setTableConfigDisplaying] = useState(
    userPageTablesRelationship[0],
  );
  const [userPageTableConfig, setUserPageTableConfig] = useState(null);
  const [dataConfig, setDataConfig] = useState({});

  useEffect(() => {
    if (tableConfigDisplaying) {
      if (userPageTableConfig) {
        setUserPageTableConfig(null);
      } else {
        setUserPageTableConfig(
          USER_PAGE_TABLE_CONFIG[tableConfigDisplaying.tableConfig],
        );
      }
    }
  }, [tableConfigDisplaying]);

  useEffect(() => {
    if (!userPageTableConfig && tableConfigDisplaying) {
      setUserPageTableConfig(
        USER_PAGE_TABLE_CONFIG[tableConfigDisplaying.tableConfig],
      );
    }
    updateData();
  }, [userPageTableConfig]);

  useEffect(() => {
    if (data) {
      updateData();
    }
  }, [data]);

  const updateData = () => {
    if (userPageTableConfig) {
      let totalRecords = userPageTableConfig.totalRecords(data) || 0;
      setDataConfig({
        rows: userPageTableConfig.handleTableData(data),
        pages: Math.ceil(totalRecords / pageSize),
        totalRecords: totalRecords,
      });
    }
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  return (
    <Card className={classNames(data?.consent === false && 'blur')}>
      {data && userPageTableConfig && dataConfig ? (
        <div className='overflow-hidden'>
          <Row>
            {userPageTablesRelationship.map((report, index) => (
              <Button
                className={classNames(
                  'btn-sm btn-tab',
                  tableConfigDisplaying?.type === report?.type && 'active',
                )}
                onClick={() => setTableConfigDisplaying(report)}
                key={index}
              >
                {report.text}
              </Button>
            ))}
          </Row>
          <TableComponent
            pagination={pagination}
            config={dataConfig}
            columnsData={userPageTableConfig.columnsData([], () => {}, false)}
            setPagination={setPagination}
          />
        </div>
      ) : (
        <Row className='h-56' yalign={'center'}>
          <LoadingIndicator />
        </Row>
      )}
    </Card>
  );
};
