import {
  Col,
  HelpText,
  LoadingIndicator,
  TableComponent,
} from '@tokensoft-web/common-ui';
import {
  VERIFICATION_STATUS,
  countryList,
  useGetUsersEvent,
  useModal,
  useToast,
} from '@tokensoft-web/common-utils';
import './event-users.css';

import { PaginationState } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useProject } from '../../../contexts/project/project-context';
import { EventUsersSearch } from './event-users-search';

type Person = {
  eventUserId: string;
  name: string;
  address: string;
  firstName: string;
  email: string;
  country: string;
  accountType: string;
  walletAddress: string;
  referralCode: string;
  kyc: string;
  identity: string;
  accreditation: string;
  claims: string;
  purchases: string;
  consent: boolean;
};

type UserEvents = {
  rows: Person[];
  pages: number;
  totalRecords: number;
};

export const EventUsers = ({
  eventUsersTableConfig,
  loadingDataCompleted,
  event,
  handleEventUserParameters,
  handleExposeData,
}: {
  eventUsersTableConfig: any;
  loadingDataCompleted: Function;
  event: any;
  handleEventUserParameters: Function;
  handleExposeData: Function;
}) => {
  const { showErrorToast } = useToast();
  const [searchValue, setSearchValue] = useState('');
  const [usersEvent, setUsersEvent] = useState<UserEvents>();
  const { showModal } = useModal();
  const { project } = useProject();

  const pageSizeList = [50, 100, 200];

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSizeList[0],
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const handleEventUsersData = eventUsersTableConfig.handleTableData;

  const { mutate: getUsers, isPending: getUsersLoading } = useGetUsersEvent();

  const countryOptions = useMemo(
    () => [{ value: null, label: 'Select' }, ...countryList().getData()],
    [],
  );

  const filtersDataSet = {
    kycStatus: [
      {
        label: 'Select',
        value: null,
      },
      {
        label: 'Not Started',
        value: VERIFICATION_STATUS.NONE,
      },
      {
        label: 'Pending',
        value: VERIFICATION_STATUS.PENDING,
      },
      {
        label: 'Completed',
        value: VERIFICATION_STATUS.COMPLETED,
      },
      {
        label: 'Failed',
        value: VERIFICATION_STATUS.FAILED,
      },
    ],
    country: countryOptions,
  };

  const [filterBy, setFilterBy] = useState(null);
  const [orderBy, setOrderBy] = useState<any>(null);
  const [firstLoadIsEmtpy, setFirstLoadIsEmtpy] = useState(null);
  const [rowsCount, setRowsCount] = useState(null);

  useEffect(() => {
    if (eventUsersTableConfig) {
      setFilterBy(eventUsersTableConfig.filterBy(filtersDataSet));
      setOrderBy(eventUsersTableConfig.orderBy());
    }
  }, [eventUsersTableConfig]);

  // FUNCTIONS

  const handleFilterChange = (key, value) => {
    let temp = filterBy;
    temp[key].searchTerm = value;
    setFilterBy({ ...temp });
  };

  const handleOrderByChange = (key, value) => {
    let temp = orderBy;
    if (temp[key].desc !== null) {
      temp[key].desc = value;
    } else {
      Object.entries(temp).forEach(([keyObj, value]) => {
        temp[keyObj].desc = null;
      });
      temp[key].desc = value;
    }
    setOrderBy({ ...temp });
  };

  const handleUpdateUserTable = (page = pageIndex) => {
    loadingDataCompleted(false);
    setPagination({ pageIndex: page, pageSize: pageSize });
    getUsers(handleEventUserParameters(page * pageSize, pageSize), {
      onSuccess: (data) => {
        let rows = handleEventUsersData(data);
        setRowsCount(rows.length);
        if (firstLoadIsEmtpy === null) {
          setFirstLoadIsEmtpy(rows.length === 0);
        }
        setUsersEvent({
          rows,
          pages: Math.ceil(data.totalRecords / pageSize),
          totalRecords: data.totalRecords,
        });
        loadingDataCompleted(true);
      },
      onError: (error) => {
        setRowsCount(0);
        if (firstLoadIsEmtpy === null) {
          setFirstLoadIsEmtpy(true);
        }
        showErrorToast({ description: error.message });
        loadingDataCompleted(true);
      },
    });
  };

  // USE EFFECT

  useEffect(() => {
    if (pageIndex != null && pageSize != null && event) {
      handleUpdateUserTable();
    }
  }, [pageIndex, pageSize]);

  useEffect(() => {
    if (orderBy) {
      handleUpdateUserTable();
    }
  }, [orderBy]);

  useEffect(() => {
    handleExposeData({
      orderBy: orderBy ? orderBy : [],
      filterBy: filterBy ? filterBy : [],
      searchValue: searchValue ? searchValue : '',
      firstLoadIsEmtpy: firstLoadIsEmtpy !== null ? firstLoadIsEmtpy : null,
      rowsCount: rowsCount ? rowsCount : 0,
    });
  }, [orderBy, filterBy, searchValue, firstLoadIsEmtpy, rowsCount]);

  const handleSetSearchValue = (value) => {
    setSearchValue(value);
  };

  const getDisclaimerText = () => {
    if (project?.domains[0].domainName) {
      return (
        <>
          * Once users register on{' '}
          <span className='text-primary-medium'>
            {project?.domains[0].domainName}
          </span>{' '}
          their information will be visible.
        </>
      );
    }
    return `* Once users register their information will be visible.`;
  };

  return (
    <>
      {!usersEvent ? (
        <div className='flex justify-center items-center h-44'>
          <LoadingIndicator />
        </div>
      ) : (
        <Col nowrap={true}>
          <EventUsersSearch
            setSearchValue={handleSetSearchValue}
            updateUserTable={handleUpdateUserTable}
            filterChange={handleFilterChange}
            filterList={filterBy}
            disabled={firstLoadIsEmtpy}
          />
          <div className='relative overflow-x-auto overflow-y-hidden table-container'>
            {getUsersLoading ? (
              <div className='table-loading-cover'>
                <LoadingIndicator className='loading-indicator' />
              </div>
            ) : null}
            <TableComponent
              pagination={pagination}
              config={usersEvent}
              columnsData={eventUsersTableConfig.columnsData(
                orderBy,
                handleOrderByChange,
                firstLoadIsEmtpy,
              )}
              setPagination={setPagination}
            />
            <HelpText className='pt-5'>{getDisclaimerText()}</HelpText>
          </div>
        </Col>
      )}
    </>
  );
};
