// dayjs mandates that we use a 'require' statement to load the appropriate
// locale config.  require statements do not support variables so we must list out each
// require statement explicitly here.
export const LOCALES = {
  af: {
    loadLocale: () => {
      require('dayjs/locale/af');
    },
  },
  am: {
    loadLocale: () => {
      require('dayjs/locale/am');
    },
  },
  'ar-dz': {
    loadLocale: () => {
      require('dayjs/locale/ar-dz');
    },
  },
  'ar-iq': {
    loadLocale: () => {
      require('dayjs/locale/ar-iq');
    },
  },
  'ar-kw': {
    loadLocale: () => {
      require('dayjs/locale/ar-kw');
    },
  },
  'ar-ly': {
    loadLocale: () => {
      require('dayjs/locale/ar-ly');
    },
  },
  'ar-ma': {
    loadLocale: () => {
      require('dayjs/locale/ar-ma');
    },
  },
  'ar-sa': {
    loadLocale: () => {
      require('dayjs/locale/ar-sa');
    },
  },
  'ar-tn': {
    loadLocale: () => {
      require('dayjs/locale/ar-tn');
    },
  },
  ar: {
    loadLocale: () => {
      require('dayjs/locale/ar');
    },
  },
  az: {
    loadLocale: () => {
      require('dayjs/locale/az');
    },
  },
  be: {
    loadLocale: () => {
      require('dayjs/locale/be');
    },
  },
  bg: {
    loadLocale: () => {
      require('dayjs/locale/bg');
    },
  },
  bi: {
    loadLocale: () => {
      require('dayjs/locale/bi');
    },
  },
  bm: {
    loadLocale: () => {
      require('dayjs/locale/bm');
    },
  },
  'bn-bd': {
    loadLocale: () => {
      require('dayjs/locale/bn-bd');
    },
  },
  bn: {
    loadLocale: () => {
      require('dayjs/locale/bn');
    },
  },
  bo: {
    loadLocale: () => {
      require('dayjs/locale/bo');
    },
  },
  br: {
    loadLocale: () => {
      require('dayjs/locale/br');
    },
  },
  bs: {
    loadLocale: () => {
      require('dayjs/locale/bs');
    },
  },
  ca: {
    loadLocale: () => {
      require('dayjs/locale/ca');
    },
  },
  cs: {
    loadLocale: () => {
      require('dayjs/locale/cs');
    },
  },
  cv: {
    loadLocale: () => {
      require('dayjs/locale/cv');
    },
  },
  cy: {
    loadLocale: () => {
      require('dayjs/locale/cy');
    },
  },
  da: {
    loadLocale: () => {
      require('dayjs/locale/da');
    },
  },
  'de-at': {
    loadLocale: () => {
      require('dayjs/locale/de-at');
    },
  },
  'de-ch': {
    loadLocale: () => {
      require('dayjs/locale/de-ch');
    },
  },
  de: {
    loadLocale: () => {
      require('dayjs/locale/de');
    },
  },
  dv: {
    loadLocale: () => {
      require('dayjs/locale/dv');
    },
  },
  el: {
    loadLocale: () => {
      require('dayjs/locale/el');
    },
  },
  'en-au': {
    loadLocale: () => {
      require('dayjs/locale/en-au');
    },
  },
  'en-ca': {
    loadLocale: () => {
      require('dayjs/locale/en-ca');
    },
  },
  'en-gb': {
    loadLocale: () => {
      require('dayjs/locale/en-gb');
    },
  },
  'en-ie': {
    loadLocale: () => {
      require('dayjs/locale/en-ie');
    },
  },
  'en-il': {
    loadLocale: () => {
      require('dayjs/locale/en-il');
    },
  },
  'en-in': {
    loadLocale: () => {
      require('dayjs/locale/en-in');
    },
  },
  'en-nz': {
    loadLocale: () => {
      require('dayjs/locale/en-nz');
    },
  },
  'en-sg': {
    loadLocale: () => {
      require('dayjs/locale/en-sg');
    },
  },
  'en-tt': {
    loadLocale: () => {
      require('dayjs/locale/en-tt');
    },
  },
  en: {
    loadLocale: () => {
      require('dayjs/locale/en');
    },
  },
  eo: {
    loadLocale: () => {
      require('dayjs/locale/eo');
    },
  },
  'es-do': {
    loadLocale: () => {
      require('dayjs/locale/es-do');
    },
  },
  'es-mx': {
    loadLocale: () => {
      require('dayjs/locale/es-mx');
    },
  },
  'es-pr': {
    loadLocale: () => {
      require('dayjs/locale/es-pr');
    },
  },
  'es-us': {
    loadLocale: () => {
      require('dayjs/locale/es-us');
    },
  },
  es: {
    loadLocale: () => {
      require('dayjs/locale/es');
    },
  },
  et: {
    loadLocale: () => {
      require('dayjs/locale/et');
    },
  },
  eu: {
    loadLocale: () => {
      require('dayjs/locale/eu');
    },
  },
  fa: {
    loadLocale: () => {
      require('dayjs/locale/fa');
    },
  },
  fi: {
    loadLocale: () => {
      require('dayjs/locale/fi');
    },
  },
  fo: {
    loadLocale: () => {
      require('dayjs/locale/fo');
    },
  },
  'fr-ca': {
    loadLocale: () => {
      require('dayjs/locale/fr-ca');
    },
  },
  'fr-ch': {
    loadLocale: () => {
      require('dayjs/locale/fr-ch');
    },
  },
  fr: {
    loadLocale: () => {
      require('dayjs/locale/fr');
    },
  },
  fy: {
    loadLocale: () => {
      require('dayjs/locale/fy');
    },
  },
  ga: {
    loadLocale: () => {
      require('dayjs/locale/ga');
    },
  },
  gd: {
    loadLocale: () => {
      require('dayjs/locale/gd');
    },
  },
  gl: {
    loadLocale: () => {
      require('dayjs/locale/gl');
    },
  },
  gu: {
    loadLocale: () => {
      require('dayjs/locale/gu');
    },
  },
  he: {
    loadLocale: () => {
      require('dayjs/locale/he');
    },
  },
  hi: {
    loadLocale: () => {
      require('dayjs/locale/hi');
    },
  },
  hr: {
    loadLocale: () => {
      require('dayjs/locale/hr');
    },
  },
  ht: {
    loadLocale: () => {
      require('dayjs/locale/ht');
    },
  },
  hu: {
    loadLocale: () => {
      require('dayjs/locale/hu');
    },
  },
  'hy-am': {
    loadLocale: () => {
      require('dayjs/locale/hy-am');
    },
  },
  id: {
    loadLocale: () => {
      require('dayjs/locale/id');
    },
  },
  is: {
    loadLocale: () => {
      require('dayjs/locale/is');
    },
  },
  'it-ch': {
    loadLocale: () => {
      require('dayjs/locale/it-ch');
    },
  },
  it: {
    loadLocale: () => {
      require('dayjs/locale/it');
    },
  },
  ja: {
    loadLocale: () => {
      require('dayjs/locale/ja');
    },
  },
  jv: {
    loadLocale: () => {
      require('dayjs/locale/jv');
    },
  },
  ka: {
    loadLocale: () => {
      require('dayjs/locale/ka');
    },
  },
  kk: {
    loadLocale: () => {
      require('dayjs/locale/kk');
    },
  },
  km: {
    loadLocale: () => {
      require('dayjs/locale/km');
    },
  },
  kn: {
    loadLocale: () => {
      require('dayjs/locale/kn');
    },
  },
  ko: {
    loadLocale: () => {
      require('dayjs/locale/ko');
    },
  },
  ku: {
    loadLocale: () => {
      require('dayjs/locale/ku');
    },
  },
  ky: {
    loadLocale: () => {
      require('dayjs/locale/ky');
    },
  },
  lb: {
    loadLocale: () => {
      require('dayjs/locale/lb');
    },
  },
  lo: {
    loadLocale: () => {
      require('dayjs/locale/lo');
    },
  },
  lt: {
    loadLocale: () => {
      require('dayjs/locale/lt');
    },
  },
  lv: {
    loadLocale: () => {
      require('dayjs/locale/lv');
    },
  },
  me: {
    loadLocale: () => {
      require('dayjs/locale/me');
    },
  },
  mi: {
    loadLocale: () => {
      require('dayjs/locale/mi');
    },
  },
  mk: {
    loadLocale: () => {
      require('dayjs/locale/mk');
    },
  },
  ml: {
    loadLocale: () => {
      require('dayjs/locale/ml');
    },
  },
  mn: {
    loadLocale: () => {
      require('dayjs/locale/mn');
    },
  },
  mr: {
    loadLocale: () => {
      require('dayjs/locale/mr');
    },
  },
  'ms-my': {
    loadLocale: () => {
      require('dayjs/locale/ms-my');
    },
  },
  ms: {
    loadLocale: () => {
      require('dayjs/locale/ms');
    },
  },
  mt: {
    loadLocale: () => {
      require('dayjs/locale/mt');
    },
  },
  my: {
    loadLocale: () => {
      require('dayjs/locale/my');
    },
  },
  nb: {
    loadLocale: () => {
      require('dayjs/locale/nb');
    },
  },
  ne: {
    loadLocale: () => {
      require('dayjs/locale/ne');
    },
  },
  'nl-be': {
    loadLocale: () => {
      require('dayjs/locale/nl-be');
    },
  },
  nl: {
    loadLocale: () => {
      require('dayjs/locale/nl');
    },
  },
  nn: {
    loadLocale: () => {
      require('dayjs/locale/nn');
    },
  },
  'pa-in': {
    loadLocale: () => {
      require('dayjs/locale/pa-in');
    },
  },
  pl: {
    loadLocale: () => {
      require('dayjs/locale/pl');
    },
  },
  'pt-br': {
    loadLocale: () => {
      require('dayjs/locale/pt-br');
    },
  },
  pt: {
    loadLocale: () => {
      require('dayjs/locale/pt');
    },
  },
  rn: {
    loadLocale: () => {
      require('dayjs/locale/rn');
    },
  },
  ro: {
    loadLocale: () => {
      require('dayjs/locale/ro');
    },
  },
  ru: {
    loadLocale: () => {
      require('dayjs/locale/ru');
    },
  },
  rw: {
    loadLocale: () => {
      require('dayjs/locale/rw');
    },
  },
  sd: {
    loadLocale: () => {
      require('dayjs/locale/sd');
    },
  },
  se: {
    loadLocale: () => {
      require('dayjs/locale/se');
    },
  },
  si: {
    loadLocale: () => {
      require('dayjs/locale/si');
    },
  },
  sk: {
    loadLocale: () => {
      require('dayjs/locale/sk');
    },
  },
  sl: {
    loadLocale: () => {
      require('dayjs/locale/sl');
    },
  },
  sq: {
    loadLocale: () => {
      require('dayjs/locale/sq');
    },
  },
  sr: {
    loadLocale: () => {
      require('dayjs/locale/sr');
    },
  },
  ss: {
    loadLocale: () => {
      require('dayjs/locale/ss');
    },
  },
  'sv-fi': {
    loadLocale: () => {
      require('dayjs/locale/sv-fi');
    },
  },
  sv: {
    loadLocale: () => {
      require('dayjs/locale/sv');
    },
  },
  sw: {
    loadLocale: () => {
      require('dayjs/locale/sw');
    },
  },
  ta: {
    loadLocale: () => {
      require('dayjs/locale/ta');
    },
  },
  te: {
    loadLocale: () => {
      require('dayjs/locale/te');
    },
  },
  tet: {
    loadLocale: () => {
      require('dayjs/locale/tet');
    },
  },
  tg: {
    loadLocale: () => {
      require('dayjs/locale/tg');
    },
  },
  th: {
    loadLocale: () => {
      require('dayjs/locale/th');
    },
  },
  tk: {
    loadLocale: () => {
      require('dayjs/locale/tk');
    },
  },
  'tl-ph': {
    loadLocale: () => {
      require('dayjs/locale/tl-ph');
    },
  },
  tlh: {
    loadLocale: () => {
      require('dayjs/locale/tlh');
    },
  },
  tr: {
    loadLocale: () => {
      require('dayjs/locale/tr');
    },
  },
  tzl: {
    loadLocale: () => {
      require('dayjs/locale/tzl');
    },
  },
  tzm: {
    loadLocale: () => {
      require('dayjs/locale/tzm');
    },
  },
  'ug-cn': {
    loadLocale: () => {
      require('dayjs/locale/ug-cn');
    },
  },
  uk: {
    loadLocale: () => {
      require('dayjs/locale/uk');
    },
  },
  ur: {
    loadLocale: () => {
      require('dayjs/locale/ur');
    },
  },
  uz: {
    loadLocale: () => {
      require('dayjs/locale/uz');
    },
  },
  vi: {
    loadLocale: () => {
      require('dayjs/locale/vi');
    },
  },
  yo: {
    loadLocale: () => {
      require('dayjs/locale/yo');
    },
  },
  'zh-cn': {
    loadLocale: () => {
      require('dayjs/locale/zh-cn');
    },
  },
  'zh-hk': {
    loadLocale: () => {
      require('dayjs/locale/zh-hk');
    },
  },
  'zh-tw': {
    loadLocale: () => {
      require('dayjs/locale/zh-tw');
    },
  },
  zh: {
    loadLocale: () => {
      require('dayjs/locale/zh');
    },
  },
};
