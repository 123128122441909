export enum NOTIFICATION_TYPE {
  EMAIL_VERIFICATION = 'email.verification',
  KYC_ADDITIONAL_INFO_REQUESTED = 'kyc.additional_info_requested',
  KYC_EXPIRED = 'kyc.expired',
  KYC_COMPLETED = 'kyc.completed',
  KYC_FAILED = 'kyc.failed',
  KYC_DOCUMENTS_UPLOADED_INTERNAL = 'kyc.documents_uploaded_internal',
  KYC_SUBMITTED_INTERNAL = 'kyc.submitted_internal',
  GENERAL_MESSAGE = 'misc.general_message',
  TARGETED_EVENTS = 'promo.targeted_events',
  TARGETED_MESSAGES = 'promo.targeted_messages',
  SPECIAL_INVITATIONS = 'promo.special_invitations',
  SPECIAL_EVENTS = 'promo.special_events',
  EVENT_INVITATIONS = 'promo.event_invitations',
  PURCHASE_COMPLETED = 'purchase.completed',
  PURCHASE_FAILED = 'purchase.failed',
  PURCHASE_DOCUMENT_GENERATED = 'purchase.document_generated',
  PURCHASE_ADDED_ACCESS_LIST = 'purchase.added_access_list',
  CLAIM_COMPLETED = 'claim.completed',
  DISTRIBUTE_DOCUMENT_GENERATED = 'distribute.document_generated',
  DISTRIBUTE_DELEGATE_SET = 'distribute.delegate_set',
  TRADE_COMPLETED = 'trade.completed',
  PROJECT_CREATED = 'project.created',
  PROJECT_CREATED_INTERNAL = 'project.created_internal',
  PROJECT_SETUP_COMPLETED = 'project.setup_completed',
  PROJECT_SETUP_NO_COMPLETED = 'project.setup_not_completed',
  EVENT_CREATED = 'event.created',
  ACCOUNT_VERIFY_EMAIL = 'account.verify_email',
  ACCOUNT_EMAIL_VERIFIED = 'account.email_verified',
  ACCOUNT_PROFILE_UPDATED = 'account.profile_updated',
  ACCOUNT_WALLET_ADDED = 'account.wallet_added',
  ACCOUNT_WALLET_REMOVED = 'account.wallet_removed',
  BILLING_NEW_STATEMENT = 'billing.new_statement',
  BILLING_OVERDUE = 'billing.overdue',
  BILLING_PAYMENT_COMPLETED = 'billing.payment_completed',
  BILLING_PAYMENT_FAILED = 'billing.payment_failed',
  BILLING_MAX_LIMIT_REACHED = 'billing.max_limit_reached',
  BILLING_TIER_UPGRADED = 'billing.tier_upgraded',
  COMPLIANCE_DOCUMENT_UPLOADED = 'compliance.document_uploaded',
  COMPLIANCE_DOCUMENT_UPLOADED_INTERNAL = 'compliance.document_uploaded_internal',
  COMPLIANCE_DOCUMENT_APPROVED = 'compliance.document_approved',
  COMPLIANCE_DOCUMENT_REJECTED = 'compliance.document_rejected',
  COMPLIANCE_INFORMATION_REQUIRED = 'compliance.information_required',
  COMPLIANCE_INFORMATION_REQUIRED_INTERNAL = 'compliance.information_required_internal',
  KYC3_SUBMITTED_INTERNAL = 'kyc3.submitted_internal',
  KYC3_REJECTED = 'kyc3.rejected',
  KYC3_COMPLETED = 'kyc3.completed',
  KYC3_INFORMATION_REQUIRED = 'kyc3.information_required',
  KYC3_DOCUMENT_UPLOADED_INTERNAL = 'kyc3.documents_uploaded_internal',
}
