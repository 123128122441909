import {
  getDifference,
  subtractFromDate,
  useConfiguration,
  useToast,
  useWagmi,
} from '@tokensoft-web/common-utils';
import { simulateContract, writeContract } from '@wagmi/core';
import BigNumber from 'bignumber.js';
import { useState } from 'react';
import { TransactionExecutionError, TransactionReceipt } from 'viem';
import {
  useReadContract,
  useAccount as useWagmiAccount,
  useWaitForTransactionReceipt,
} from 'wagmi';
import { generatePeriodicTranches } from '../components/admin/charts/vesting-chart-utils';
import {
  getAdvancedDistributorABI,
  getContinuousVestingMerkleABI,
  getContinuousVestingMerkleDistributorFactoryABI,
  getErc20ABI,
  getTrancheVestingMerkleABI,
  getTrancheVestingMerkleDistributorFactoryABI,
} from './abi';
import { DEPLOY_VESTING_TYPE_OPTIONS, VESTING_TYPE } from './enums';

export const useDeployContinuousVestingMerkleDistributorWithFactory = () => {
  const { wagmiConfig } = useWagmi();
  const { showErrorToast } = useToast();
  const { chain } = useWagmiAccount();
  const {
    configuration: { networks },
  } = useConfiguration();
  const [transactionHash, setTransactionHash] = useState(null);
  const [isDeploying, setIsDeploying] = useState(false);
  const [args, setArgs] = useState([]);

  const factoryAddress = networks?.find(
    (n) => n.chainId === chain.id,
  )?.continuousVestingDistributorFactoryAddress;

  const config = {
    address: factoryAddress,
    abi: getContinuousVestingMerkleDistributorFactoryABI(),
    functionName: 'predictDistributorAddress',
    args,
    enabled: args?.length > 0,
  } as any;

  const { data: deployedAddress } = useReadContract(config);

  const write = async ({ args }) => {
    setArgs(args);
    setIsDeploying(true);

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: factoryAddress,
        abi: getContinuousVestingMerkleDistributorFactoryABI(),
        functionName: 'deployDistributor',
        args,
      } as any);

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      return writeContractResponse;
    } catch (error) {
      console.error({ error });
    } finally {
      setIsDeploying(false);
    }
  };

  const response = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    deployedContinuousVestingMerkleDistributorAddress: deployedAddress,
    deployContinuousVestingMerkleDistributor: write,
    isDeployingContinuousVestingMerkleDistributor: isDeploying,
    deployContinuousVestingMerkleDistributorResponse: { ...response },
    deployContinuousVestingMerkleDistributorReceipt:
      response?.data || ({} as TransactionReceipt),
    deployContinuousVestingMerkleDistributorError:
      response?.error || ({} as Error),
  };
};

export const useDeployTrancheVestingMerkleDistributorWithFactory = () => {
  const { wagmiConfig } = useWagmi();
  const { chain } = useWagmiAccount();
  const {
    configuration: { networks },
  } = useConfiguration();
  const [transactionHash, setTransactionHash] = useState(null);
  const [isDeploying, setIsDeploying] = useState(false);
  const [args, setArgs] = useState([]);

  const factoryAddress = networks?.find(
    (n) => n.chainId === chain.id,
  )?.trancheVestingDistributorFactoryAddress;

  const config = {
    address: factoryAddress,
    abi: getTrancheVestingMerkleDistributorFactoryABI(),
    functionName: 'predictDistributorAddress',
    args,
    enabled: args?.length > 0,
  } as any;

  const { data: deployedAddress } = useReadContract(config);

  const write = async ({ args }) => {
    setArgs(args);
    setIsDeploying(true);

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: factoryAddress,
        abi: getTrancheVestingMerkleDistributorFactoryABI(),
        functionName: 'deployDistributor',
        args,
      } as any);

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      return writeContractResponse;
    } catch (error) {
      console.error({ error });
    } finally {
      setIsDeploying(false);
    }
  };

  const response = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    deployedTrancheVestingMerkleDistributorAddress: deployedAddress,
    deployTrancheVestingMerkleDistributor: write,
    isDeployingTrancheVestingMerkleDistributor: isDeploying,
    deployTrancheVestingMerkleDistributorResponse: { ...response },
    deployTrancheVestingMerkleDistributorReceipt:
      response?.data || ({} as TransactionReceipt),
    deployTrancheVestingMerkleDistributorError:
      response?.error || ({} as Error),
  };
};

export const useDeployDistributor = () => {
  const [vestingType, setVestingType] = useState();

  const {
    deployedContinuousVestingMerkleDistributorAddress,
    deployContinuousVestingMerkleDistributor,
    isDeployingContinuousVestingMerkleDistributor,
    deployContinuousVestingMerkleDistributorResponse,
    deployContinuousVestingMerkleDistributorReceipt,
    deployContinuousVestingMerkleDistributorError,
  } = useDeployContinuousVestingMerkleDistributorWithFactory();

  const {
    deployedTrancheVestingMerkleDistributorAddress,
    deployTrancheVestingMerkleDistributor,
    isDeployingTrancheVestingMerkleDistributor,
    deployTrancheVestingMerkleDistributorResponse,
    deployTrancheVestingMerkleDistributorReceipt,
    deployTrancheVestingMerkleDistributorError,
  } = useDeployTrancheVestingMerkleDistributorWithFactory();

  const deployDistributor = async (vestingType, args) => {
    setVestingType(vestingType);
    if (
      vestingType === DEPLOY_VESTING_TYPE_OPTIONS.CONTINUOUS ||
      vestingType === DEPLOY_VESTING_TYPE_OPTIONS.INSTANT
    ) {
      return deployContinuousVestingMerkleDistributor(args);
    } else if (vestingType === DEPLOY_VESTING_TYPE_OPTIONS.MONTHLY) {
      return deployTrancheVestingMerkleDistributor(args);
    }
  };

  return {
    deployDistributor,
    ...(vestingType === DEPLOY_VESTING_TYPE_OPTIONS.CONTINUOUS ||
    vestingType === DEPLOY_VESTING_TYPE_OPTIONS.INSTANT
      ? {
          deployedAddress: deployedContinuousVestingMerkleDistributorAddress,
          isPending:
            isDeployingContinuousVestingMerkleDistributor ||
            deployContinuousVestingMerkleDistributorResponse?.isLoading,
          receipt: deployContinuousVestingMerkleDistributorReceipt,
          error: deployContinuousVestingMerkleDistributorError,
        }
      : {
          deployedAddress: deployedTrancheVestingMerkleDistributorAddress,
          isPending:
            isDeployingTrancheVestingMerkleDistributor ||
            deployTrancheVestingMerkleDistributorResponse?.isLoading,
          receipt: deployTrancheVestingMerkleDistributorReceipt,
          error: deployTrancheVestingMerkleDistributorError,
        }),
  };
};

export const useGetErc20TokenBalance = (
  tokenAddress?: any,
  address?: string,
  chaindId?: number,
) => {
  let abi =
    tokenAddress.toLowerCase() ===
    `0xdAC17F958D2ee523a2206206994597C13D831ec7`.toLowerCase()
      ? getErc20ABI(true)
      : getErc20ABI();
  const config = {
    address: tokenAddress || null,
    abi,
    functionName: 'balanceOf',
    chainId: chaindId ? chaindId : null,
    args: [address],
  } as any;

  const { data, isSuccess, isLoading } = useReadContract(config);
  const balance = data ? new BigNumber(Number(data)) : null;

  return {
    balance,
    isSuccess,
    isLoading,
  };
};

export const useFundDistributor = () => {
  const { wagmiConfig } = useWagmi();
  const [transactionHash, setTransactionHash] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [prepareError, setPrepareError] = useState(null);

  const write = async (
    contractAddress: string,
    toAddress: string,
    amount: string,
    chainId: number,
  ) => {
    setSubmitting(true);
    setPrepareError(null);

    try {
      let abi =
        contractAddress.toLowerCase() ===
        `0xdAC17F958D2ee523a2206206994597C13D831ec7`.toLowerCase()
          ? getErc20ABI(true)
          : getErc20ABI();
      const { request } = await simulateContract(wagmiConfig, {
        address: contractAddress,
        abi,
        functionName: 'transfer',
        chainId,
        args: [toAddress, amount],
      } as any);

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      return writeContractResponse;
    } catch (e) {
      setPrepareError(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
    error: prepareError || waitForTransactionResponse?.error,
  };
};

export const useSweepDistributor = () => {
  const { wagmiConfig } = useWagmi();
  const [transactionHash, setTransactionHash] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [prepareError, setPrepareError] = useState(null);

  const write = async (
    contractAddress: string,
    token: string,
    amount: string,
    chainId: number,
  ) => {
    setSubmitting(true);
    setPrepareError(null);

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: contractAddress,
        abi: [
          {
            inputs: [
              {
                internalType: 'contract IERC20',
                name: 'token',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
              },
            ],
            name: 'sweepToken',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
        ],
        functionName: 'sweepToken',
        chainId,
        args: [token, amount],
      } as any);

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      return writeContractResponse;
    } catch (e) {
      setPrepareError(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
    error: prepareError || waitForTransactionResponse?.error,
  };
};

export const useUpdateOwner = () => {
  const { wagmiConfig } = useWagmi();
  const [transactionHash, setTransactionHash] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const write = async (chainId, contractAddress, args) => {
    setSubmitting(true);

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: contractAddress,
        abi: getAdvancedDistributorABI(),
        functionName: 'transferOwnership',
        chainId,
        args,
      } as any);

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      return writeContractResponse;
    } finally {
      setSubmitting(false);
    }
  };

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  };
};

export const useUpdateRecipient = () => {
  const { wagmiConfig } = useWagmi();
  const [transactionHash, setTransactionHash] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const write = async (chainId, contractAddress, args) => {
    setSubmitting(true);

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: contractAddress,
        abi: getAdvancedDistributorABI(),
        functionName: 'setSweepRecipient',
        chainId,
        args,
      } as any);

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      return writeContractResponse;
    } finally {
      setSubmitting(false);
    }
  };

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  };
};

export const useUpdateDistributorMerkleRoot = () => {
  const { wagmiConfig } = useWagmi();
  const { showErrorToast } = useToast();
  const [transactionHash, setTransactionHash] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const getABIForVestingType = (vestingType: VESTING_TYPE) => {
    switch (vestingType) {
      case VESTING_TYPE.CONTINUOUS:
      case VESTING_TYPE.NO_VESTING:
        return getContinuousVestingMerkleABI();
      case VESTING_TYPE.TRANCHE:
        return getTrancheVestingMerkleABI();
      default:
        throw new Error('Invalid vesting type');
    }
  };

  const write = async (
    vestingType: VESTING_TYPE,
    chainId: number,
    contractAddress: string,
    args: any[],
  ) => {
    setSubmitting(true);

    const abi = getABIForVestingType(vestingType);

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: contractAddress,
        abi: abi,
        functionName: 'setMerkleRoot',
        chainId,
        args,
      } as any);

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      return writeContractResponse;
    } catch (e) {
      let msg: string;
      if (e instanceof TransactionExecutionError) {
        msg = e.shortMessage;
      } else {
        msg = e.message;
      }

      if (msg === '') {
        msg = 'An error occurred while updating the distributor.';
      }

      showErrorToast({ description: msg });
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  };
};

export const useUpdateDistributorUri = () => {
  const { wagmiConfig } = useWagmi();
  const { showErrorToast } = useToast();
  const [transactionHash, setTransactionHash] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const write = async (chainId, contractAddress, args) => {
    setSubmitting(true);

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: contractAddress,
        abi: getAdvancedDistributorABI(),
        functionName: 'setUri',
        chainId,
        args,
      } as any);

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      return writeContractResponse;
    } catch (e) {
      let msg: string;
      if (e instanceof TransactionExecutionError) {
        msg = e.shortMessage;
      } else {
        msg = e.message;
      }

      if (msg === '') {
        msg = 'An error occurred while updating the distributor.';
      }

      showErrorToast({ description: msg });
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  };
};

export const useUpdateVestingConfig = () => {
  const { wagmiConfig } = useWagmi();
  const [transactionHash, setTransactionHash] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const getTranches = (args) => {
    const tranches = generatePeriodicTranches(
      args.startTime,
      args.cliffTime,
      args.endTime,
    );
    const tranchesArray = tranches.map((t) => {
      return [t.time, t.vestedFraction];
    });
    return [tranchesArray];
  };

  const write = async (
    chainId,
    contractAddress,
    args,
    vestingType: `monthly` | `continuous` | `instant`,
  ) => {
    setSubmitting(true);
    console.log('updating distributor vesting config');

    const abi =
      vestingType === DEPLOY_VESTING_TYPE_OPTIONS.MONTHLY
        ? getTrancheVestingMerkleABI()
        : getContinuousVestingMerkleABI();
    const functionName =
      vestingType === DEPLOY_VESTING_TYPE_OPTIONS.MONTHLY
        ? 'setTranches'
        : 'setVestingConfig';

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: contractAddress,
        abi,
        functionName,
        chainId,
        args:
          vestingType === DEPLOY_VESTING_TYPE_OPTIONS.MONTHLY
            ? getTranches(args)
            : [args.startTime, args.cliffTime, args.endTime],
      } as any);

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      console.log(
        'update distributor vesting config transaction response',
        writeContractResponse,
      );
      return writeContractResponse;
    } finally {
      setSubmitting(false);
    }
  };

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  };
};

export const getTrancheStartDate = (
  cliffVestedFraction,
  endVestedFraction,
  cliffDate,
  endDate,
) => {
  const targetPercentage = 0;
  const startPercentage = Number(cliffVestedFraction) / 100;
  const endPercentage = Number(endVestedFraction) / 100;

  // Calculate the rate of change per month
  const percentageChange = endPercentage - startPercentage;
  const monthsBetween = getDifference(cliffDate, endDate, 'months');
  const rateOfChangePerMonth = percentageChange / monthsBetween;

  // Calculate the number of months to reach the target percentage
  const monthsToTarget = Math.floor(
    (startPercentage - targetPercentage) / rateOfChangePerMonth,
  );

  // Calculate the date when the target percentage is reached
  const targetDate = subtractFromDate(
    cliffDate,
    monthsToTarget,
    'months',
  ).toISOString();
  return targetDate;
};
