import classNames from 'classnames';
import { Text } from './text';

export const TableData = ({ className = '', children, ...restProps }) => {
  return (
    <Text
      className={classNames('text-neutral-medium', className)}
      {...restProps}
    >
      {children}
    </Text>
  );
};
