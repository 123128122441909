import { EVENT_TYPE, useGetEvents } from '@tokensoft-web/common-utils';
import { useEffect, useState } from 'react';

export const useGetEventsSummary = () => {
  const {
    results: events,
    loading: eventsLoading,
    loadMore: eventsLoadMore,
  } = useGetEvents(
    [
      EVENT_TYPE.SALE,
      EVENT_TYPE.RAISE,
      EVENT_TYPE.IDENTITY,
      EVENT_TYPE.DISTRIBUTE,
    ],
    false,
  );
  const [eventSummaryData, setEventSummaryData] = useState([]);

  useEffect(() => {
    if (!events) {
      return;
    }

    setEventSummaryData(events);
  }, [events]);

  return {
    results: eventSummaryData,
    loading: eventsLoading,
    loadMore: eventsLoadMore,
  };
};
