import {
  Card,
  CheckboxInput,
  Col,
  ExclamationIcon,
  Row,
  Text,
} from '@tokensoft-web/common-ui';
import { useEffect } from 'react';

const ConnextIntroFooter = ({
  termsSelected,
  termsSelect,
}: {
  termsSelected: boolean;
  termsSelect: Function;
}) => {
  useEffect(() => {
    termsSelect(false);
  }, []);

  return (
    <>
      <Card>
        <Col gap={2.5}>
          <Row yalign={'center'}>
            <ExclamationIcon />
            <span className='font-semibold ml-2'>
              Upgrading From State Channels
            </span>
          </Row>
          <Text>
            If you were a user of Connext V0 in 2021, you may need to sign a
            message to connect your V0 wallet. If you were not a user of V0,
            this step will not apply to you.
          </Text>
        </Col>
      </Card>

      <Row place={'start'}>
        <CheckboxInput
          checked={termsSelected}
          onClick={() => termsSelect(!termsSelected)}
          className='cursor-pointer'
        >
          I have read and agree to the{' '}
          <a
            href='https://www.connext.network/airdrop-terms-of-service'
            target='_blank'
            className='underline'
          >
            Connext Airdrop Terms of Service
          </a>
        </CheckboxInput>
      </Row>
    </>
  );
};

export default ConnextIntroFooter;
