import { PageHeader, Stacked } from '@tokensoft-web/common-ui';
import {
  EVENT_TYPE,
  useGetEventPrivacyConfiguration,
} from '@tokensoft-web/common-utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { EventDetails } from '../../../components/admin/event/event-details';
import { EventDistributorSummary } from '../../../components/admin/event/event-distributor-summary';
import { EventSaleSummary } from '../../../components/admin/event/event-sale-summary';
import { EventPrivacyConfiguration } from '../../../components/admin/event/hooks/use-event-visibility-options';
import { useEvent } from '../../../contexts/event/event-context';
import { useProject } from '../../../contexts/project/project-context';
import EventContent from './event-content';
import EventDocuments from './event-documents';
import EventEligibility from './event-eligibility';
import EventPrivacyOverview from './event-privacy-overview';
import EventPublicOverview from './event-public-overview';

const EventEditDetailsPage = () => {
  const { event } = useEvent();

  const { data: privacyConfiguration, isPendingEventPrivacyConfiguration } =
    useGetEventPrivacyConfiguration(event.id ?? null);

  // If navigated here with a state, use that state as the privacy configuration;
  // otherwise, use the fetched privacy configuration
  const { state } = useLocation();
  const receivedPrivacyConfiguration = state as EventPrivacyConfiguration;

  const activePrivacyConfiguration =
    receivedPrivacyConfiguration ?? privacyConfiguration;

  const { project } = useProject();
  const navigate = useNavigate();

  const onEditPrivacyConfiguration = () => {
    const projectId = project?.id;
    const eventId = event.id;

    if (!projectId || !eventId) {
      return;
    }

    navigate(`/admin/project/${projectId}/event/${eventId}/privacy`, {
      state: activePrivacyConfiguration,
    });
  };

  return (
    <>
      <PageHeader
        title={`Edit Event Details`}
        breadcrumb={
          <>
            <a
              className='cursor-pointer'
              onClick={() => {
                navigate(`/admin/project/${project?.id}`);
              }}
            >
              &lt; {project?.name}
            </a>{' '}
            &lt;{' '}
            <a
              className='cursor-pointer'
              onClick={() => {
                navigate(`/admin/project/${project?.id}/event/${event.id}`);
              }}
            >
              Event Dashboard
            </a>{' '}
            &lt; Edit Event Details
          </>
        }
      />

      <Stacked>
        <EventDetails readonly={true} />

        <EventEligibility context={event}></EventEligibility>

        {isPendingEventPrivacyConfiguration ? (
          <div>Loading...</div>
        ) : activePrivacyConfiguration ? (
          <EventPrivacyOverview
            privacyConfiguration={activePrivacyConfiguration}
            onEdit={onEditPrivacyConfiguration}
          />
        ) : (
          <EventPublicOverview onEdit={onEditPrivacyConfiguration} />
        )}

        <EventDocuments context={event}></EventDocuments>

        <EventContent context={event}></EventContent>

        {event.type === EVENT_TYPE.DISTRIBUTE ? (
          <EventDistributorSummary event={event} readonly />
        ) : null}

        {[EVENT_TYPE.RAISE, EVENT_TYPE.SALE].includes(event.type) ? (
          <EventSaleSummary event={event} readonly />
        ) : null}
      </Stacked>
    </>
  );
};

export default EventEditDetailsPage;
