import {
  Button,
  ButtonRow,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  DocumentLink,
  Stacked,
  Text,
} from '@tokensoft-web/common-ui';
import {
  useAcceptAndSignEventUserDocuments,
  useToast,
  utcToLocalDate,
} from '@tokensoft-web/common-utils';
import { useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import EventBodyWrapper from '../../components/event/event-body-wrapper';
import EventEligibility from '../../components/event/event-eligibility';
import EventHeader from '../../components/event/event-header';
import EventHeaderWrapper from '../../components/event/event-header-wrapper';
import { useEvent } from '../../contexts/event/event-me-context';
import Signature from '../../pages/sale/signature';
import EventContent from './event-content';
import EventDocuments from './event-documents';

const EventDetails = () => {
  const { showErrorToast, showSuccessToast } = useToast();

  const { event, refresh, loading } = useEvent();

  const [acceptedDocs, setAcceptedDocs] = useState(false);
  const [signatureValid, setSignatureValid] = useState(false);

  const description = event?.contentBodyOpen;

  const contentHeaderBgColor = event?.contentHeaderBgColor;
  const contentHeaderBgImage = event?.contentHeaderBgImage;
  const contentHeaderImage = event?.contentHeaderImage;
  const contentHeaderFontColor = event?.contentHeaderFontColor;
  const contentHeaderSubtitle = event?.contentHeaderSubtitle;
  const contentHeaderTitle = event?.contentHeaderTitle;
  const themeLayoutVersion = event?.themeLayoutVersion;
  const eventUserDocuments = event?.eventUsers[0]?.documents;
  const documentsToAcceptOrSign = eventUserDocuments?.filter(
    (doc) => !doc.acceptedAt,
  );
  const documentSignatureRequired = documentsToAcceptOrSign
    ? documentsToAcceptOrSign.findIndex(
        (doc) => doc?.eventDocumentVersion?.requiresSignature === true,
      ) >= 0
    : false;

  const { mutate: sign, isPending: signLoading } =
    useAcceptAndSignEventUserDocuments();

  const isWaitingDocumentSignature = () => {
    if (signLoading) {
      return true;
    }

    if (!documentSignatureRequired) {
      return false;
    }

    if (documentsToAcceptOrSign && !acceptedDocs) {
      return true;
    }

    if (!acceptedDocs || !signatureValid) {
      return true;
    }
  };

  const handleNext = () => {
    sign(
      {
        eventId: event.id,
        documents: documentsToAcceptOrSign,
      },
      {
        onSuccess: () => {
          showSuccessToast({
            description: 'Successfully updated event documents.',
          });
          refresh();
        },
        onError: (error) => {
          showErrorToast({ description: error.message });
        },
      },
    );
  };

  const renderEventDetails = () => {
    // If there are documents to accept/sign, show the accept/signature form
    if (documentsToAcceptOrSign && documentsToAcceptOrSign.length) {
      return (
        <>
          <EventContent bodyContent={description} />

          {documentsToAcceptOrSign?.length > 0 ? (
            <Card>
              <CardHeader>
                <CardTitle>Terms & Conditions</CardTitle>
              </CardHeader>

              <CardBody>
                <Col gap={5}>
                  <EventDocuments
                    documents={documentsToAcceptOrSign}
                    setAcceptedDocs={setAcceptedDocs}
                    eventType={event?.type}
                    requiresSignature={false}
                  />

                  {documentSignatureRequired ? (
                    <Signature
                      setSignatureValid={setSignatureValid}
                      nameLabel={'Name'}
                    />
                  ) : null}
                </Col>
              </CardBody>

              <CardFooter>
                <ButtonRow place={'end'}>
                  <Button
                    onClick={() => handleNext()}
                    disabled={isWaitingDocumentSignature() || loading}
                  >
                    {signLoading ? (
                      <div className='flex'>
                        <div className='animate-spin'>
                          <AiOutlineLoading3Quarters size={24} />
                        </div>
                        <span className='pl-2'>Saving...</span>
                      </div>
                    ) : (
                      <span>Save</span>
                    )}
                  </Button>
                </ButtonRow>
              </CardFooter>
            </Card>
          ) : null}
        </>
      );
    }

    // Once documents are all accepted/signed, show the list of documents
    // and the event eligibility
    return (
      <>
        <EventContent bodyContent={description} />

        <EventEligibility event={event} refreshEvent={refresh} />
        {eventUserDocuments?.length > 0 ? (
          <Card>
            <CardHeader>
              <CardTitle>Terms & Conditions</CardTitle>
            </CardHeader>

            <CardBody>
              <Col gap={5}>
                <Text>
                  Please save a copy of the below documents for your records.
                </Text>

                <Stacked gap={2.5}>
                  {eventUserDocuments.map((record, i) => {
                    return (
                      <Text key={i}>
                        <DocumentLink document={record} />
                        {record.signedAt ? (
                          <span className={'text-sm'}>
                            {' '}
                            (Signed {utcToLocalDate(record.signedAt)})
                          </span>
                        ) : record.acceptedAt ? (
                          <span className={'text-sm'}>
                            {' '}
                            (Accepted {utcToLocalDate(record.acceptedAt)})
                          </span>
                        ) : null}
                      </Text>
                    );
                  })}
                </Stacked>
              </Col>
            </CardBody>
          </Card>
        ) : null}
      </>
    );
  };
  return (
    <Col gap={10}>
      <EventHeaderWrapper
        backgroundColor={contentHeaderBgColor}
        backgroundImg={contentHeaderBgImage}
      >
        <EventHeader
          headerTitle={contentHeaderTitle}
          headerSubtitle={contentHeaderSubtitle}
          contentHeaderImage={contentHeaderImage}
          contentHeaderFontColor={contentHeaderFontColor}
          themeLayoutVersion={themeLayoutVersion}
        />
      </EventHeaderWrapper>

      <EventBodyWrapper>{renderEventDetails()}</EventBodyWrapper>
    </Col>
  );
};

export default EventDetails;
