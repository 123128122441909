import { ReactNode } from 'react';
import { Col } from '../layout/container/col';

export const Step = ({ children }: { children?: ReactNode }) => {
  return (
    <Col className={'flex-1'} gap={12}>
      {children}
    </Col>
  );
};
