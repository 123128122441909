import { TableComponent, Text } from '@tokensoft-web/common-ui';
import { useState } from 'react';

export interface PrivateEventParticipantsTableRow {
  email?: string;
  walletAddress?: string;
}

interface PrivateEventParticipantsTableProps {
  data: PrivateEventParticipantsTableRow[];
}

export const PrivateEventParticipantsTable = ({
  data,
}: PrivateEventParticipantsTableProps) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: data.length,
  });

  const config = {
    rows: data,
  };

  // Generate column from data
  const columnsData =
    data.length > 0
      ? Object.keys(data[0]).map((key) => ({
          Header: key,
          accessorKey: key,
        }))
      : [];

  return (
    <div className='max-h-[480px] overflow-auto'>
      <Text>{data.length} rows</Text>
      <TableComponent
        config={config}
        columnsData={columnsData}
        pagination={pagination}
        setPagination={setPagination}
        paginationDisabled
      />
    </div>
  );
};
