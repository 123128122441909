import { Card, DocumentUploader } from '@tokensoft-web/common-ui';
import {
  VERIFICATION_STATUS,
  useAccount,
  utcToLocalDate,
} from '@tokensoft-web/common-utils';
import { useState } from 'react';
import { StatusIcon } from '../admin/project/status-icon';

export const ProfileAccreditation = () => {
  const { tier3Status, tier3Expiration } = useAccount();
  const [showMoreDocuments, setShowMoreDocuments] = useState(false);

  return (
    <>
      <Card className='mb-8 h-full'>
        <div className={'h-full flex flex-col justify-between'}>
          <div className={'flex flex-col items-center'}>
            <div className={'mb-4'}>
              <StatusIcon status={tier3Status} size='xl' />
            </div>

            <div className={'mb-8 flex flex-col items-center justify-center'}>
              {tier3Status === VERIFICATION_STATUS.COMPLETED && (
                <>
                  <div className={'title-text text-center mb-4'}>
                    Congratulations!
                  </div>
                  <div className={'w-3/4 text-center'}>
                    Your accreditation is complete.{' '}
                    {tier3Expiration
                      ? `This accreditation is valid through ${utcToLocalDate(
                          tier3Expiration,
                        )}`
                      : `This accreditation expires 90 days from the date of approval.`}
                  </div>
                </>
              )}

              {tier3Status === VERIFICATION_STATUS.FAILED && (
                <>
                  <div className={'title-text text-center mb-4'}>
                    We're sorry.
                  </div>
                  <div className={'w-3/4 text-center'}>
                    We could not approve your application.
                  </div>
                </>
              )}

              {tier3Status === VERIFICATION_STATUS.PENDING && (
                <>
                  <div className={'title-text text-center mb-4'}>
                    Application pending
                  </div>
                  {showMoreDocuments ? (
                    <DocumentUploader onSetIsShowing={setShowMoreDocuments} />
                  ) : (
                    <div className={'w-3/4 text-center'}>
                      Your accreditation check is underway. We will notify you
                      once your documents have been reviewed. This process
                      typically takes between 3-5 business days. Click{' '}
                      <a onClick={() => setShowMoreDocuments(true)}>here</a> to
                      submit additional documents for consideration.
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};
