import {
  Error,
  LoadingIndicator,
  SwitchChainWarning,
} from '@tokensoft-web/common-ui';
import {
  DEFAULT_EVENT_HEADER_BG_COLOR,
  DEFAULT_EVENT_HEADER_FONT_COLOR,
  SALE_STATUS,
  getUtcNowUnix,
  useAuth,
  useEventEligibility,
  useGetSale,
  useToast,
  useWallet,
} from '@tokensoft-web/common-utils';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EventHeaderWrapper from '../../components/event/event-header-wrapper';
import { PAYMENT_STEP } from '../../utils/enums';
import { Receipt } from '../../utils/interface';
import { useDelay } from '../../utils/sale';
import { SaleContext } from './sale-context';

interface SaleProviderProps {
  children?: ReactNode;
}

export const SaleProvider: FC<SaleProviderProps> = ({ children }) => {
  const { connectedChainId } = useWallet();
  const { eventId } = useParams();
  const { sale, loading, refresh } = useGetSale(false, eventId);
  const { data: eligibilityData } = useEventEligibility(
    sale?.event?.id,
    !!sale?.event?.id,
  );
  const [correctNetwork, setCorrectNetwork] = useState(true);
  const { showSuccessToast } = useToast();
  const {
    user: { walletAddress },
  } = useAuth();
  const { delay, isSuccess } = useDelay(sale, walletAddress);
  const [step, setStep] = useState(null);
  const [receipt, setReceipt] = useState<Receipt>();
  const [delayRemaining, setDelayRemaining] = useState(0);

  const documentsToAcceptOrSign = sale?.event?.eventUsers[0]?.documents?.filter(
    (doc) => !doc.acceptedAt,
  );

  const contentHeaderBgColor =
    sale?.event?.contentHeaderBgColor || DEFAULT_EVENT_HEADER_BG_COLOR;
  const contentHeaderBgImage = sale?.event?.contentHeaderBgImage;
  const contentHeaderFontColor =
    sale?.event?.contentHeaderFontColor || DEFAULT_EVENT_HEADER_FONT_COLOR;

  useEffect(() => {
    if (delay && delayRemaining > 0) {
      setStep(PAYMENT_STEP.FAIR_QUEUE);
    }
  }, [delay, delayRemaining, isSuccess]);

  useEffect(() => {
    if (delay) {
      const start = parseInt(sale.startTime);
      const now = getUtcNowUnix();
      const time = start + delay - now + 90; // pad by 90 seconds
      setDelayRemaining(time > 0 ? time : 0);
    }
  }, [delay]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (delayRemaining > 0) {
        setDelayRemaining(delayRemaining - 1);
      }

      if (delayRemaining === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  });

  const handleGetReceipt = (receipt: Receipt) => {
    setReceipt(receipt);
    setStep(PAYMENT_STEP.RECEIPT);
    showSuccessToast({
      title: `You purchased ${sale.symbol}!`,
      description:
        'The seller will provide instructions for claiming your tokens.',
    });
  };

  useEffect(() => {
    if (!connectedChainId || !sale) {
      return;
    }

    setCorrectNetwork(connectedChainId === sale.chainId);
  }, [connectedChainId, sale]);

  if (loading) {
    return (
      <EventHeaderWrapper
        backgroundColor={contentHeaderBgColor}
        backgroundImg={contentHeaderBgImage}
      >
        <LoadingIndicator text='Loading Event' color={contentHeaderFontColor} />
      </EventHeaderWrapper>
    );
  }

  if (!loading && !sale) {
    return (
      <EventHeaderWrapper
        backgroundColor={contentHeaderBgColor}
        backgroundImg={contentHeaderBgImage}
      >
        <Error
          title='Sale Not Found'
          message="It looks like this isn't a valid sale."
        />
      </EventHeaderWrapper>
    );
  }

  if (!loading && sale) {
    if (!sale.validSale) {
      return (
        <EventHeaderWrapper
          backgroundColor={contentHeaderBgColor}
          backgroundImg={contentHeaderBgImage}
        >
          <Error />
        </EventHeaderWrapper>
      );
    }

    if (sale.status === SALE_STATUS.CURRENT && !correctNetwork) {
      return (
        <EventHeaderWrapper
          backgroundColor={contentHeaderBgColor}
          backgroundImg={contentHeaderBgImage}
        >
          <SwitchChainWarning
            chainId={sale.chainId}
            style={{ color: contentHeaderFontColor }}
          />
        </EventHeaderWrapper>
      );
    }
  }

  const handleSaleCapMet = () => {
    sale.status = SALE_STATUS.COMPLETED;
    refresh();
  };

  return (
    <SaleContext.Provider
      value={{
        sale,
        refresh,
        step,
        setStep,
        receipt,
        handleGetReceipt,
        correctNetwork,
        setReceipt,
        status: sale?.status,
        documentsToAcceptOrSign,
        hasQueue: Number(sale?.maxQueueTime) > 0,
        handleSaleCapMet,
        eligibilityData,
        delay: {
          hasDelay: delayRemaining > 0,
          delayRemaining: delayRemaining || 0,
        },
      }}
    >
      {children}
    </SaleContext.Provider>
  );
};
