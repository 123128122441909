import { getDurationObject } from '@tokensoft-web/common-utils';
import classNames from 'classnames';
import { FC } from 'react';
import './countdown.css';

interface CountdownProps {
  id?: string;
  className?: any;
  remainingTime?: any;
  size?: 'xsmall' | 'small' | 'large';
  fontColor?: string;
}

export const Countdown: FC<CountdownProps> = ({
  id,
  className,
  size,
  remainingTime,
  fontColor,
}: CountdownProps) => {
  let counterSize = 'text-[28px]';
  let counterWidth = 'w-[72px]';
  let labelSize = '';

  if (size === 'small') {
    counterSize = 'text-[14px]';
    counterWidth = 'w-[64px]';
  } else if (size === 'xsmall') {
    counterSize = 'text-[14px]';
    counterWidth = 'w-[36px]';
    labelSize = 'text-[10px]';
  }

  const key = id || 'event-countdown';

  const convertToDisplayNumber = (num: number) => {
    if (!num) {
      return '00';
    }

    if (String(num) === '0') {
      return '00';
    }

    if (String(num).length === 1) {
      return `0${num}`;
    }

    return num;
  };

  const { totalDays, hours, minutes, seconds } =
    getDurationObject(remainingTime);
  const adjustedDays = totalDays < 0 ? 0 : totalDays;
  const adjustedHours = hours < 0 ? 0 : hours;
  const adjustedMinutes = minutes < 0 ? 0 : minutes;
  const adjustedSeconds = seconds < 0 ? 0 : seconds;

  return (
    <div className={classNames('flex flex-row', className)}>
      <div className='mr-2'>
        <div className={classNames('mb-2', fontColor, labelSize)}>DAY</div>
        <div
          className={classNames('counter-box', counterWidth, size)}
          data-testid={key + '-countdown-day'}
        >
          <span
            className={classNames('font-semibold high-contrast', counterSize)}
          >
            {convertToDisplayNumber(adjustedDays)}
          </span>
        </div>
      </div>
      <div className='mx-2'>
        <div className={classNames('mb-2', fontColor, labelSize)}>HR</div>
        <div
          className={classNames('counter-box', counterWidth, size)}
          data-testid={key + '-countdown-hour'}
        >
          <span
            className={classNames('font-semibold high-contrast', counterSize)}
          >
            {convertToDisplayNumber(adjustedHours)}
          </span>
        </div>
      </div>
      <div className='mx-2'>
        <div className={classNames('mb-2', fontColor, labelSize)}>MIN</div>
        <div
          className={classNames('counter-box', counterWidth, size)}
          data-testid={key + '-countdown-min'}
        >
          <span
            className={classNames('font-semibold high-contrast', counterSize)}
          >
            {convertToDisplayNumber(adjustedMinutes)}
          </span>
        </div>
      </div>
      <div className='ml-2'>
        <div className={classNames('mb-2', fontColor, labelSize)}>SEC</div>
        <div
          className={classNames('counter-box', counterWidth, size)}
          data-testid={key + '-countdown-sec'}
        >
          <span
            className={classNames('font-semibold high-contrast', counterSize)}
          >
            {convertToDisplayNumber(adjustedSeconds)}
          </span>
        </div>
      </div>
    </div>
  );
};
