import { FC, ReactNode } from 'react';
import { TIER_NAME } from '../../enum/tier-name';
import { VERIFICATION_STATUS } from '../../enum/verification-status';
import { useGetAccount } from '../../service/account-service';
import {
  getCurrentStateForTier,
  getVerificationExpirationForTier,
  getVerificationStatusForTier,
  isEntity,
  isUsAccount,
  isVerificationFinished,
  isVerificationStarted,
} from '../../util/identity';
import { isEmptyObject } from '../../util/object';
import { AccountContext } from './account-context';

interface AccountProviderProps {
  children?: ReactNode;
}

export const AccountProvider: FC<AccountProviderProps> = ({ children }) => {
  // @ts-ignore
  const { account, isLoading, refetch } = useGetAccount();

  const usAccount = isUsAccount(account);
  const entity = isEntity(account);
  const tier1Status = getVerificationStatusForTier(
    account?.kyc.tiers,
    TIER_NAME.IDENTITY,
  );
  const tier1Expiration = getVerificationExpirationForTier(
    account?.kyc.tiers,
    TIER_NAME.IDENTITY,
  );
  const isTier1Started = isVerificationStarted(
    account?.kyc.tiers,
    TIER_NAME.IDENTITY,
  );
  const isTier1Finished = isVerificationFinished(
    account?.kyc.tiers,
    TIER_NAME.IDENTITY,
  );
  const isTier1Completed = tier1Status === VERIFICATION_STATUS.COMPLETED;
  const tier1CurrentState = getCurrentStateForTier(
    account?.kyc.tiers,
    TIER_NAME.IDENTITY,
  );

  const tier3Status = getVerificationStatusForTier(
    account?.kyc.tiers,
    TIER_NAME.ACCREDITATION,
  );
  const tier3Expiration = getVerificationExpirationForTier(
    account?.kyc.tiers,
    TIER_NAME.ACCREDITATION,
  );
  const isTier3Started = isVerificationStarted(
    account?.kyc.tiers,
    TIER_NAME.ACCREDITATION,
  );
  const isTier3Finished = isVerificationFinished(
    account?.kyc.tiers,
    TIER_NAME.ACCREDITATION,
  );
  const isTier3Completed = tier3Status === VERIFICATION_STATUS.COMPLETED;
  const isTier3Eligible = usAccount && isTier1Started;
  const tier3CurrentState = getCurrentStateForTier(
    account?.kyc.tiers,
    TIER_NAME.ACCREDITATION,
  );

  const completeProfile =
    !isEmptyObject(account) &&
    account.emailVerified &&
    isTier1Completed &&
    ((isTier3Eligible && isTier3Completed) || !isTier3Eligible);

  return isLoading ? (
    <></>
  ) : (
    <AccountContext.Provider
      value={{
        account: account || {},
        loading: isLoading,
        refetch: refetch,
        isUsAccount: usAccount,
        isEntity: entity,
        tier1Status,
        isTier1Started,
        isTier1Finished,
        isTier1Completed,
        tier1Expiration,
        tier1CurrentState,
        tier3Status,
        isTier3Started,
        isTier3Finished,
        isTier3Completed,
        isTier3Eligible,
        tier3Expiration,
        tier3CurrentState,
        isProfileSetupComplete: completeProfile,
        configuration: account?.configuration || {},
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
