import { Button, Text } from '@tokensoft-web/common-ui';
import { useModal } from '@tokensoft-web/common-utils';
import { AiFillEye } from 'react-icons/ai';
import { useWizard } from 'react-use-wizard';

export const PreviewPageButton = ({
  previewContent,
  disabled = false,
  label = 'Preview Page',
  fill = 'outline',
  onContinue = null,
}) => {
  const { showModal } = useModal();
  const { nextStep } = useWizard();

  return (
    <Button
      fill={fill}
      onClick={() =>
        showModal({
          content: previewContent(onContinue || nextStep),
          className: 'w-10/12',
        })
      }
      disabled={disabled}
    >
      <>
        <AiFillEye size={24} />
        <Text>{label}</Text>
      </>
    </Button>
  );
};
