import {
  getResourceUri,
  getValidIPFSHash,
  useConfiguration,
} from '@tokensoft-web/common-utils';
import { useNavigate } from 'react-router-dom';
import { TokensoftIcon } from '../../logo/tokensoft-icon';
import { TokensoftLogo } from '../../logo/tokensoft-logo';
import './nav-header.css';

export const NavHeader = () => {
  const { configuration } = useConfiguration();
  const navigate = useNavigate();

  let logo = <TokensoftLogo />;
  let logoSmall = <TokensoftIcon />;

  if (configuration?.project) {
    if (configuration.project.theme?.logoSmall) {
      logoSmall = (
        <img
          src={
            getValidIPFSHash(configuration.project.theme.logoSmall)
              ? getResourceUri(configuration?.project?.theme?.logoSmall)
              : configuration?.project?.theme?.logoSmall
          }
        />
      );
    } else {
      logoSmall = (
        <span className={'text-3xl font-semibold'}>
          {configuration.project.name.charAt(0).toUpperCase()}
        </span>
      );
    }

    if (configuration.project.theme?.logo) {
      logo = (
        <img
          src={
            getValidIPFSHash(configuration.project.theme.logo)
              ? getResourceUri(configuration?.project?.theme?.logo)
              : configuration?.project?.theme?.logo
          }
        />
      );
    } else {
      logo = (
        <span className={'text-3xl font-semibold'}>
          {configuration.project.name.toUpperCase()}
        </span>
      );
    }
  }

  return (
    <div className='nav-header'>
      <div
        className='brand-logo hover:cursor-pointer'
        onClick={() => navigate('/')}
      >
        <div className='brand-logo-abbr w-full block md:hidden'>
          {logoSmall}
        </div>
        <div className='brand-logo-full w-full hidden md:block'>{logo}</div>
      </div>
    </div>
  );
};
