import {
  Alert,
  Button,
  Card,
  LoadingIndicator,
  Row,
  Stacked,
  Text,
} from '@tokensoft-web/common-ui';
import {
  DATE_TIME_ISO_FORMAT,
  EVENT_STATUS,
  useAccount,
  useGetSale,
  useGetSaleContractOwner,
  useNetworks,
  useWagmi,
  utcToUtcUnixDateTime,
} from '@tokensoft-web/common-utils';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { FaRocket } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {
  DEFAULT_BASE_CURRENCY,
  DEFAULT_BASE_CURRENCY_DECIMALS,
} from '../../../utils/constant';
import { SaleOwnerSummary } from '../../sale/sale-owner-summary';
import { SaleParticipatesSummary } from '../../sale/sale-participates-summary';
import { SalePaymentConfigSummary } from '../../sale/sale-payment-config-summary';
import { SaleSetupSummary } from '../../sale/sale-setup-summary';
import { SaleProgress } from './sale-progress';

export const EventSaleSummary = ({ event, readonly = false }) => {
  const { account } = useAccount();
  const navigate = useNavigate();
  const [saleData, setSaleData] = useState(null);
  const [purchasesData, setPurchasesData] = useState(null);
  const { getNetworkDetails } = useNetworks();
  const wagmi = useWagmi();

  const { sale, loading } = useGetSale(true, event?.id);

  const isDeployedOnChain = event?.sale?.id;

  const handleDeploy = () => {
    navigate(
      `/admin/project/${event.project.id}/event/${event.id}/deploy/sale`,
    );
  };

  const generateDummyData = () => {
    // generate fake data and blur out the chart
    const eventStartTime = utcToUtcUnixDateTime('01/01/2023');
    const startTime = utcToUtcUnixDateTime('01/01/2023');
    const endTime = utcToUtcUnixDateTime('01/01/2024');
    const eventEndTime = utcToUtcUnixDateTime('01/01/2024');

    setSaleData({
      account: {
        timezone: account.timezone,
      },
      event: {
        ...event,
        startTime: eventStartTime,
        endTime: eventEndTime,
      },
      startTime: startTime,
      cliffTime: endTime,
      endTime: endTime,
      unixStartTime: Number(startTime),
      unixEndTime: Number(endTime),
      price: 1000,
      recipient: '0x.......',
      owner: '0x.......',
      maxRaise: 1000000,
      minPurchase: 1000,
      userLimit: 10000,
      fairQueueEnabled: true,
      maxQueueIntervalLength: 1,
      maxQueueIntervalUnits: 'minute',
      networkId: 1,
      paymentMethods: [
        {
          symbol: 'ETH',
        },
      ],
    });

    setPurchasesData([]);
  };

  useEffect(() => {
    if (!event) {
      return;
    }

    if (!isDeployedOnChain) {
      generateDummyData();
    }
  }, [event]);

  const saleOwner = useGetSaleContractOwner(sale);
  useEffect(() => {
    if (saleOwner) {
      sale.owner.id = saleOwner;
    }
  }, [saleOwner]);

  useEffect(() => {
    if (!sale) {
      generateDummyData();
      return;
    }

    const eventStartTime = sale.event.startTime
      ? utcToUtcUnixDateTime(sale.event.startTime, DATE_TIME_ISO_FORMAT)
      : null;
    const eventEndTime = sale.event.endTime
      ? utcToUtcUnixDateTime(sale.event.endTime, DATE_TIME_ISO_FORMAT)
      : null;
    const startTime = eventStartTime;
    const endTime = eventEndTime;

    let currencyDecimals = DEFAULT_BASE_CURRENCY_DECIMALS;
    const isNativeBaseCurrency = sale.baseCurrency !== DEFAULT_BASE_CURRENCY;
    if (isNativeBaseCurrency) {
      const networkDetails = getNetworkDetails(sale.chainId);
      currencyDecimals = networkDetails.decimals;
    }

    setSaleData({
      ...sale,
      account: {
        timezone: account.timezone,
      },
      event: {
        ...event,
        status: event.status,
        startTime: Number(eventStartTime),
        endTime: Number(eventEndTime),
      },
      startTime: Number(startTime),
      endTime: Number(endTime),
      recipient: sale.recipient?.id,
      owner: sale.owner?.id,
      priceBaseUnits: sale.price,
      maxRaiseBaseUnits: sale.saleMaximum,
      minPurchaseBaseUnits: sale.purchaseMinimum,
      userLimitBaseUnits: sale.userMaximum,
      fairQueueEnabled: sale.maxQueueTime !== '0' || false,
      maxQueueIntervalLength: sale.maxQueueTime,
      maxQueueIntervalUnits: 'second',
      paymentMethods: sale.paymentMethods,
      networkId: sale.chainId,
      tokenName: sale.tokenName,
      tokenSymbol: sale.symbol,
      unixStartTime: eventStartTime,
      unixEndTime: eventEndTime,
      nativeBaseCurrency: {
        symbol: sale.baseCurrency,
        decimals: currencyDecimals,
      },
    });
    setPurchasesData([]);
  }, [sale]);

  if (!saleData) {
    return (
      <Card
        className={classNames(
          'col-span-3',
          !isDeployedOnChain || !sale ? 'blur' : '',
        )}
      >
        {loading ? (
          <LoadingIndicator
            text={'Loading Data'}
            className={'no-padding h-96'}
          />
        ) : null}
      </Card>
    );
  }

  if (readonly) {
    if (sale && isDeployedOnChain) {
      return (
        <Stacked>
          <SaleSetupSummary context={saleData}></SaleSetupSummary>
          <SaleParticipatesSummary context={saleData}></SaleParticipatesSummary>
          <SalePaymentConfigSummary
            context={saleData}
          ></SalePaymentConfigSummary>
          <SaleOwnerSummary context={saleData}></SaleOwnerSummary>
        </Stacked>
      );
    }

    return <></>;
  }

  return (
    <>
      {isDeployedOnChain && !sale && !loading ? (
        <Alert type='alert-warning light'>
          <Text>Data Pending</Text>
        </Alert>
      ) : null}

      {!isDeployedOnChain ? (
        <>
          {event?.status !== EVENT_STATUS.COMPLETED ? (
            <Row place={'center'} className={'relative'}>
              <Button className={'btn-floating'} onClick={handleDeploy}>
                <FaRocket />
                <Text>Launch Sale</Text>
              </Button>

              <SaleSetupSummary
                context={saleData}
                className={classNames(
                  !isDeployedOnChain || !sale ? 'blur' : '',
                )}
              />
            </Row>
          ) : null}
        </>
      ) : (
        <>
          <SaleProgress context={saleData} />
          {/*<Card className={classNames(!isDeployedOnChain || !sale ? 'blur' : '')}>*/}
          {/*  <PurchasingChart saleData={saleData} purchasesData={purchasesData} />*/}
          {/*</Card>*/}
        </>
      )}
    </>
  );
};
