import {
  Account,
  GENESIS_ADDRESS,
  gt,
  gte,
  localToUtcUnixDateTime,
  lte,
  NetworkDetails,
  toBaseUnits,
  useConfiguration,
  useWagmi,
  validateEndDate,
  validateStartDate,
} from '@tokensoft-web/common-utils';
import { simulateContract, writeContract } from '@wagmi/core';
import { BigNumber, ethers } from 'ethers';
import { useState } from 'react';
import { TransactionReceipt } from 'viem';
import {
  useReadContract,
  useAccount as useWagmiAccount,
  useWaitForTransactionReceipt,
} from 'wagmi';
import {
  getChainlinkOracleABI,
  getErc20ABI,
  getFlatPriceSaleABI,
  getFlatPriceSaleFactoryABI,
} from './abi';
import { PaymentMethod, Sale } from './interface';

export const validateOnChainConfig = (onChainConfig, account?: Account) => {
  const {
    recipient,
    saleMaximum,
    userMaximum,
    price,
    purchaseMinimum,
    startTime,
    endTime,
    maxQueueTime,
    owner,
  } = onChainConfig;

  const validSaleMaximum =
    !saleMaximum ||
    (gt(saleMaximum, 0) && (!userMaximum || gte(saleMaximum, userMaximum)));

  let saleMaximumError;
  if (!validSaleMaximum) {
    saleMaximumError = userMaximum
      ? 'Max raise must be greater than per-user limit'
      : 'Max raise must be greater than 0';
  }

  const validPurchaseMinimum =
    !purchaseMinimum || !userMaximum || lte(purchaseMinimum, userMaximum);

  const validUserMaximum =
    !userMaximum ||
    (gt(userMaximum, 0) &&
      (!saleMaximum || lte(userMaximum, saleMaximum)) &&
      (!purchaseMinimum || gte(userMaximum, purchaseMinimum)));

  let userMaximumError;
  if (!validUserMaximum) {
    if (
      saleMaximum &&
      !lte(userMaximum, saleMaximum) &&
      purchaseMinimum &&
      !gte(userMaximum, purchaseMinimum)
    ) {
      userMaximumError =
        'Per-user limit must be more than minimum purchase and less than max raise';
    } else if (saleMaximum && !lte(userMaximum, saleMaximum)) {
      userMaximumError = 'Per-user limit must be less than max raise';
    } else {
      userMaximumError = 'Per-user limit must be more than minimum purchase';
    }
  }

  const validRecipient =
    !recipient ||
    (ethers.utils.isAddress(recipient) && recipient !== GENESIS_ADDRESS);

  let recipientError;
  if (!validRecipient) {
    recipientError = 'Invalid address';
  }

  const priceError =
    price && price <= 0 ? 'Price must be greater than 0' : null;

  const validOwner =
    !owner || (ethers.utils.isAddress(owner) && owner !== GENESIS_ADDRESS);

  let ownerError;
  if (!validOwner) {
    ownerError = 'Invalid address';
  }

  const startTimeError = validateStartDate(
    startTime,
    endTime,
    account?.timezone,
  );
  const validStartTime = !startTime || startTimeError == null;

  const endTimeError = validateEndDate(startTime, endTime, account?.timezone);
  const validEndTime = !endTime || endTimeError == null;

  const validStartAndEndTimes = validStartTime && validEndTime;

  let validMaxQueueTime = true;
  const startTimeUnix = localToUtcUnixDateTime(startTime, account?.timezone);
  const endTimeUnix = localToUtcUnixDateTime(endTime, account?.timezone);
  if (startTime && endTime && validStartAndEndTimes && maxQueueTime) {
    validMaxQueueTime = startTimeUnix + Number(maxQueueTime) <= endTimeUnix;
  }

  let maxQueueTimeError;
  if (!validMaxQueueTime) {
    if (startTimeUnix + Number(maxQueueTime) > endTimeUnix) {
      maxQueueTimeError = 'Cannot exceed length of sale';
    } else {
      maxQueueTimeError = `Invalid value`;
    }
  }

  return {
    validRecipient,
    recipientError,
    validOwner,
    ownerError,
    priceError,
    validSaleMaximum,
    saleMaximumError,
    validUserMaximum,
    userMaximumError,
    validPurchaseMinimum,
    validStartTime,
    startTimeError,
    validEndTime,
    endTimeError,
    validStartAndEndTimes,
    validMaxQueueTime,
    maxQueueTimeError,
  };
};

export const getPaymentMethods = (sale: Sale, network: NetworkDetails) => {
  const { symbol: nativeSymbol } = network;

  return sale.paymentMethods
    .map((paymentMethod) => {
      const { token, native, symbol } = paymentMethod;
      return {
        ...paymentMethod,
        address: token,
        symbol: symbol || nativeSymbol,
        label: symbol || nativeSymbol,
        value: symbol || nativeSymbol,
        native,
      };
    })
    .sort((a, b) => (!a.address ? -1 : !b.address ? 1 : 0));
};

export const useDeploySale = () => {
  const { wagmiConfig } = useWagmi();
  const { chain } = useWagmiAccount();
  const {
    configuration: { networks },
  } = useConfiguration();
  const [transactionHash, setTransactionHash] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const factoryAddress = networks?.find(
    (n) => n.chainId === chain.id,
  )?.saleFactoryAddress;

  const write = async (args) => {
    setSubmitting(true);

    console.log('Deploying new sale', args);

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: factoryAddress,
        abi: getFlatPriceSaleFactoryABI(),
        functionName: 'newSale',
        chain,
        args,
      } as any);

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      console.log('Deploy sale transaction response', writeContractResponse);
      return writeContractResponse;
    } catch (error) {
      console.error({ error });
    } finally {
      setSubmitting(false);
    }
  };

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    write,
    isSubmitting: submitting,
    response: { ...waitForTransactionResponse },
    data: waitForTransactionResponse?.data || ({} as TransactionReceipt),
    error: waitForTransactionResponse?.error || ({} as Error),
  };
};

export const useUpdateSaleOnChainConfig = () => {
  const { wagmiConfig } = useWagmi();
  const [transactionHash, setTransactionHash] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const write = async (chainId, contractAddress, args) => {
    setSubmitting(true);

    console.log('updating sale on chain config');

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: contractAddress,
        abi: getFlatPriceSaleABI(),
        functionName: 'update',
        chainId,
        args,
      } as any);

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      console.log('update sale transaction response', writeContractResponse);
      return writeContractResponse;
    } finally {
      setSubmitting(false);
    }
  };

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  };
};

export const useUpdateOwner = () => {
  const { wagmiConfig } = useWagmi();
  const [transactionHash, setTransactionHash] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const write = async (chainId, contractAddress, args) => {
    setSubmitting(true);

    console.log('updating sale owner');

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: contractAddress,
        abi: getFlatPriceSaleABI(),
        functionName: 'transferOwnership',
        chainId,
        args,
      } as any);

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      console.log(
        'update sale owner transaction response',
        writeContractResponse,
      );
      return writeContractResponse;
    } finally {
      setSubmitting(false);
    }
  };

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  };
};

export const useBuyTransaction = () => {
  const { wagmiConfig } = useWagmi();
  const [transactionHash, setTransactionHash] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const write = async (chainId, paymentMethod, toAddress, value, proof) => {
    setSubmitting(true);

    const txValue = toBaseUnits(value, paymentMethod.decimals);
    console.log('preparing purchase', txValue.toString());

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: toAddress,
        abi: getFlatPriceSaleABI(),
        functionName: 'buyWithNative',
        chainId,
        args: ['0x', proof],
        value: txValue,
      } as any);

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      console.log(
        'native purchase transaction response',
        writeContractResponse,
      );
      return writeContractResponse;
    } finally {
      setSubmitting(false);
    }
  };

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  };
};

export const useSweepNative = () => {
  const { wagmiConfig } = useWagmi();
  const [transactionHash, setTransactionHash] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const write = async (chainId, toAddress, proof, payee) => {
    setSubmitting(true);

    console.log('preparing sweep Native', proof);

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: toAddress,
        abi: getFlatPriceSaleABI(),
        functionName: 'withdrawPayments',
        chainId,
        args: [payee],
      } as any);

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      console.log('sweep Native transaction response', writeContractResponse);
      return writeContractResponse;
    } finally {
      setSubmitting(false);
    }
  };

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  };
};

export const useSweepToken = () => {
  const { wagmiConfig } = useWagmi();
  const [transactionHash, setTransactionHash] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const write = async (chainId, token, toAddress, proof) => {
    setSubmitting(true);

    console.log('preparing sweep Token', proof);

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: toAddress,
        abi: getFlatPriceSaleABI(),
        functionName: 'sweepToken',
        chainId,
        args: [token],
      } as any);

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      console.log('sweep Token transaction response', writeContractResponse);
      return writeContractResponse;
    } finally {
      setSubmitting(false);
    }
  };

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  };
};

export const useSendErc20Transaction = () => {
  const { wagmiConfig } = useWagmi();
  const [transactionHash, setTransactionHash] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const write = async (chainId, paymentMethod, toAddress, value, proof) => {
    setSubmitting(true);

    const txValue = toBaseUnits(value, paymentMethod.decimals);
    console.log('preparing purchase', txValue.toString());

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: toAddress,
        abi: getFlatPriceSaleABI(),
        functionName: 'buyWithToken',
        chainId,
        args: [paymentMethod.address, txValue, '0x', proof],
      } as any);

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      console.log('purchase transaction response', writeContractResponse);
      return writeContractResponse;
    } finally {
      setSubmitting(false);
    }
  };

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  };
};

export const useCheckTransferAllowance = (
  chainId,
  paymentMethod,
  walletAddress,
  saleId,
  value,
) => {
  if (!paymentMethod?.address) {
    return;
  }
  const tokenAddress = ethers.utils.getAddress(paymentMethod.address);
  let abi =
    tokenAddress === `0xdAC17F958D2ee523a2206206994597C13D831ec7`
      ? getErc20ABI(true)
      : getErc20ABI();
  const config = {
    address: tokenAddress,
    abi,
    functionName: 'allowance',
    chainId,
    args: [walletAddress, saleId],
  } as any;

  const { data, error, isLoading, refetch } = useReadContract(config);
  let hasEnoughAllowance = false;

  if (data) {
    const allowance = BigNumber.from(data).toHexString();
    const decimals = paymentMethod.decimals;
    const approvalValue = toBaseUnits(value, decimals);
    hasEnoughAllowance = gte(allowance, approvalValue);
  }

  return { data, error, isLoading, refetch, hasEnoughAllowance };
};

export const useApproveErc20Transfer = () => {
  const { wagmiConfig } = useWagmi();
  const [transactionHash, setTransactionHash] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const write = async (chainId, paymentMethod, toAddress, value) => {
    setSubmitting(true);

    const txValue = toBaseUnits(value, paymentMethod.decimals);
    console.log('preparing approval', txValue.toString());

    try {
      const tokenAddress = ethers.utils.getAddress(paymentMethod.address);
      let abi =
        tokenAddress === `0xdAC17F958D2ee523a2206206994597C13D831ec7`
          ? getErc20ABI(true)
          : getErc20ABI();
      const { request } = await simulateContract(wagmiConfig, {
        address: ethers.utils.getAddress(paymentMethod.address),
        abi,
        functionName: 'approve',
        chainId,
        args: [toAddress, txValue],
      } as any);

      const writeContractResponse = await writeContract(wagmiConfig, request);
      setTransactionHash(writeContractResponse);
      console.log('approval transaction response', writeContractResponse);
      return writeContractResponse;
    } finally {
      setSubmitting(false);
    }
  };

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  });

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  };
};

export const useBuyerTotal = (
  chainId: number,
  saleId: string,
  walletAddress: string,
) => {
  const config = {
    address: ethers.utils.getAddress(saleId),
    abi: getFlatPriceSaleABI(),
    functionName: 'buyerTotal',
    chainId,
    args: [walletAddress],
  } as any;

  let buyerTotalNumber = '0';

  const { data } = useReadContract(config);

  // @ts-ignore
  if (data) {
    buyerTotalNumber = BigNumber.from(data).toString();
  }

  return buyerTotalNumber;
};

export const useExchangeRate = (
  chainId: number,
  paymentMethod: PaymentMethod,
) => {
  const { oracle: priceOracleAddress } = paymentMethod;

  const config = {
    address: ethers.utils.getAddress(priceOracleAddress),
    abi: getChainlinkOracleABI(),
    functionName: 'latestRoundData',
    chainId,
  } as any;

  let exchangeRate = '0';

  const { data, error } = useReadContract(config);

  // @ts-ignore
  const latestRoundData = data?.answer || data?.[1];
  if (latestRoundData) {
    exchangeRate = BigNumber.from(latestRoundData).toString();
  }

  return exchangeRate;
};

export const useDelay = (sale?: any, walletAddress?: string) => {
  let delay = null;

  const config = {
    address: sale?.id ? ethers.utils.getAddress(sale.id) : null,
    abi: getFlatPriceSaleABI(),
    functionName: 'getFairQueueTime',
    chainId: sale?.chainId ? sale.chainId : null,
    args: [walletAddress],
  } as any;

  const { data, isSuccess, isLoading } = useReadContract(config);

  // @ts-ignore
  if (data) {
    delay = Number(BigNumber.from(data).toString());

    if (delay === 0) {
      delay = null;
    }
  }

  return {
    delay,
    isSuccess,
    isLoading,
  };
};
