import {
  ButtonRow,
  DocumentUploader,
  PageHeader,
} from '@tokensoft-web/common-ui';
import { useAccount, useAnalytics } from '@tokensoft-web/common-utils';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export const IdentityPending = () => {
  useAnalytics('/account/identity-pending');
  const { isTier3Eligible, isTier3Started } = useAccount();

  const [showForm, setShowForm] = useState(false);

  return (
    <>
      <PageHeader title={'Application pending'} />

      {showForm ? (
        <DocumentUploader
          onSetIsShowing={setShowForm}
          title='Submit Additional Identity Documentation'
        />
      ) : (
        <>
          <p className='text-xs md:text-base mb-8 text-center'>
            Your profile is being reviewed and a determination will be made
            within 3-5 business days.
          </p>

          <ButtonRow place={'center'}>
            <Link className='btn btn-primary' to='/account'>
              My Profile
            </Link>

            <Link className='btn btn-primary' to='/'>
              Dashboard
            </Link>

            <button
              className='btn btn-primary'
              onClick={() => setShowForm(true)}
            >
              Submit Documents
            </button>

            {isTier3Eligible && !isTier3Started ? (
              <Link className='btn btn-primary' to='/account/accreditation'>
                Begin Accreditation
              </Link>
            ) : null}
          </ButtonRow>
        </>
      )}
    </>
  );
};
