export const connextDomainToChainId = (domain: number): number => {
  switch (domain) {
  case 6648936: // Mainnet
    return 1;
  case 1869640809: // Optimism
    return 10;
  case 1886350457: // Polygon
    return 137;
  case 1634886255: // Arbitrum One
    return 42161;
  case 6450786: // Binance Smart Chain
    return 56;
  case 6778479: // Gnosis
    return 100;
  case 9991: // Mumbai
    return 80001;
  case 2053862260: // zkSync Era Testnet
    return 280;
  case 1668247156: // Linea Testnet
    return 59140;
  case 1887071092: // Polygon zkEVM Testnet
    return 1442;
  default:
    return 1;
  }
};

export const chainIdToConnextDomain = (chainId: number): number => {
  switch (chainId) {
  case 1: // Mainnet
    return 6648936;
  case 10: // Optimism
    return 1869640809;
  case 137: // Polygon
    return 1886350457;
  case 42161: // Arbitrum One
    return 1634886255;
  case 56: // Binance Smart Chain
    return 6450786;
  case 100: // Gnosis
    return 6778479;
  case 80001: // Mumbai
    return 9991;
  case 280: // zkSync Era Testnet
    return 2053862260;
  case 59140: // Linea Testnet
    return 1668247156;
  case 1442: // Polygon zkEVM Testnet
    return 1887071092;
  default:
    return 1;
  }
};
