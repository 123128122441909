import {
  Button,
  ButtonRow,
  Col,
  FrontArrowIcon,
} from '@tokensoft-web/common-ui';
import { EVENT_TYPE } from '@tokensoft-web/common-utils';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useProject } from '../../../contexts/project/project-context';
import SelectEventType from '../project/select-event-type';

const EventCreate = () => {
  const navigate = useNavigate();
  const { project } = useProject();
  const [selectedEventType, setSelectedEventType] = useState<any>();

  const handleClick = () => {
    if (selectedEventType === EVENT_TYPE.IDENTITY) {
      navigate('identity');
    } else if (selectedEventType === EVENT_TYPE.RAISE) {
      navigate('sale');
    } else if (selectedEventType === EVENT_TYPE.DISTRIBUTE) {
      navigate('distributor');
    }
  };

  return (
    <Col place={'start-between'} className={'h-full'}>
      <SelectEventType
        setSelectedEventType={setSelectedEventType}
        selectedEventType={selectedEventType}
      />

      <ButtonRow justify={'between'}>
        <Link
          to={`/admin/project/${project.id}/`}
          className={'btn btn-outline-primary'}
        >
          Cancel
        </Link>

        <Button disabled={!selectedEventType} onClick={handleClick}>
          <span>Continue</span>
          <FrontArrowIcon />
        </Button>
      </ButtonRow>
    </Col>
  );
};

export default EventCreate;
