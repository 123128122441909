import * as Sentry from '@sentry/browser';
import { FC, ReactNode, useEffect, useState } from 'react';
import {
  removeSessionStorageKey,
  sessionStorageKey,
} from '../../client/auth-client';
import { User } from '../../type/user';
import { AuthContext } from './auth-context';

declare const window: any;

export interface AuthProviderProps {
  children?: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState(
    JSON.parse(window.sessionStorage.getItem(sessionStorageKey)) || null,
  );

  const [error] = useState(false);

  useEffect(() => {
    if (user) {
      setContext(user);
    }
  }, [user]);

  const setContext = (user: User) => {
    if (
      process.env.REACT_APP_SENTRY_DSN &&
      process.env.REACT_APP_PROCESS_ENV !== 'development'
    ) {
      console.log('Setting user context...');
      Sentry.setTag('walletAddress', user.walletAddress);
      Sentry.setTag('authId', user.authId);
    }
  };

  const authenticate = (user: User) => {
    user = { ...user, walletAddress: user.walletAddress.toLowerCase() };
    setUser(user);

    window.sessionStorage.setItem(sessionStorageKey, JSON.stringify(user));
  };

  // return auth to initial state
  const disconnect = () => {
    removeSessionStorageKey();
    setUser(null);
  };

  return (
    <>
      <AuthContext.Provider
        value={{
          user: user || {},
          disconnect,
          isAuthenticated: !!user,
          error,
          authenticate: authenticate,
          removeWalletAddress: (walletAddress: string) => {
            authenticate({
              ...user,
              walletAddresses: user.walletAddresses.filter(
                (wa) => wa.walletAddress !== walletAddress,
              ),
            });
          },
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};
