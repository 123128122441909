import { Stretched } from '@tokensoft-web/common-ui';
import {
  DEFAULT_EVENT_HEADER_BG_COLOR,
  getIPFSLink,
} from '@tokensoft-web/common-utils';
import './event-header-wrapper.css';

interface EventHeaderWrapperProps {
  backgroundColor: any;
  backgroundImg?: any;
  children: any;
}

const EventHeaderWrapper = ({
  backgroundColor,
  children,
  backgroundImg,
}: EventHeaderWrapperProps) => {
  const style = {
    backgroundColor: backgroundColor || DEFAULT_EVENT_HEADER_BG_COLOR,
  };
  if (backgroundImg) {
    style['backgroundImage'] = `url(${getIPFSLink(backgroundImg)})`;
    style['backgroundSize'] = 'cover';
    style['backgroundRepeat'] = 'no-repeat';
    style['backgroundPosition'] = 'center';
    style['flex'] = 1;
    style['maxWidth'] = 1800;
  }

  return (
    <div className='flex justify-center'>
      <div
        data-testid={'event-banner'}
        className={'event-banner'}
        style={style}
      >
        <Stretched
          gap={10}
          place={'between-center'}
          className='event-header mx-auto max-w-6xl'
        >
          {children}
        </Stretched>
      </div>
    </div>
  );
};

export default EventHeaderWrapper;
