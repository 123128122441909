import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  HelpPopover,
  InfoIcon,
  Row,
  SectionTitle,
  Stacked,
  Stretched,
  Text,
} from '@tokensoft-web/common-ui';
import {
  BLOCKCHAIN_KEY,
  EVENT_STATUS,
  EVENT_TYPE,
  useAccount,
  useEventEligibility,
  useModal,
  useRequestAccess,
} from '@tokensoft-web/common-utils';
import { useEffect, useState } from 'react';
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillExclamationCircle,
  AiFillQuestionCircle,
  AiOutlineLoading3Quarters,
} from 'react-icons/ai';
import { BsFillArrowUpRightCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { EligibilityCategory, EligibilityStatus } from '../../utils/enums';
import { AddWalletModal, MODAL_STEP } from '../profile/add-wallet-modal';
import EventEligibilityAllocations from './event-eligibility-allocations';
import './event-eligibility.css';

interface EventEligibilityProps {
  event: any;
  refreshEvent: Function;
}

const EventEligibility = ({ event, refreshEvent }: EventEligibilityProps) => {
  const { showModal } = useModal();
  const { account, refetch } = useAccount();
  const navigate = useNavigate();
  const [generatedEligibilityData, setGeneratedEligibilityData] =
    useState(null);

  const { data: eligibilityData, refetch: refreshEligibilityData } =
    useEventEligibility(event?.id, !!event?.eventUsers[0]?.id);
  const {
    mutate: requestAccess,
    isSuccess: requestAccessSuccess,
    isLoading: requestAccessIsLoading,
  } = useRequestAccess();

  const polkadotWallet = account.wallets?.find(
    (wallet) => wallet.blockchainType === BLOCKCHAIN_KEY.POLKADOT,
  );
  const eligibilityItemsWithoutSubcriteria =
    generatedEligibilityData?.eligibility?.filter(
      (eligibilityItem) => !eligibilityItem.subcriteria,
    );
  const eligibilityItemsWithSubcriteria =
    generatedEligibilityData?.eligibility?.filter(
      (eligibilityItem) => eligibilityItem.subcriteria?.criteria.length > 0,
    );

  useEffect(() => {
    if (!!event?.eventUsers[0]?.id) {
      setGeneratedEligibilityData(eligibilityData);
      return;
    }

    setGeneratedEligibilityData({
      eligibility: [
        {
          label: 'Identity Verification',
          category: EligibilityCategory.IDENTITY,
          status: EligibilityStatus.MORE_INFO_NEEDED,
          subcriteria: null,
        },
        {
          label: 'Eligible Region',
          category: EligibilityCategory.REGION,
          status: EligibilityStatus.MORE_INFO_NEEDED,
          subcriteria: null,
        },
      ],
    });
  }, [eligibilityData]);

  const handleAddPolkadotWallet = () => {
    showModal({
      className: 'md',
      content: (
        <AddWalletModal
          onComplete={() => {
            refetch();
            refreshEligibilityData && refreshEligibilityData();
          }}
          initialUser={{}}
          onStep={MODAL_STEP.DOT}
        />
      ),
    });
  };

  const handleRequestAccess = () => {
    requestAccess({ id: event.id });
  };

  const handleCompleteKyc = () => {
    navigate('/account/identity');
  };

  useEffect(() => {
    if (requestAccessSuccess) {
      refreshEvent();
    }
  }, [requestAccessSuccess]);

  const renderIcon = (eligibilityCriteria) => {
    const { category, status } = eligibilityCriteria;

    if (status === EligibilityStatus.COMPLETED) {
      return (
        <AiFillCheckCircle
          color='var(--success)'
          size={18}
          title={`${category}-${status}`}
        />
      );
    } else if (status === EligibilityStatus.PENDING) {
      return (
        <AiFillQuestionCircle
          color='var(--warning)'
          size={18}
          title={`${category}-${status}`}
        />
      );
    } else if (status === EligibilityStatus.FAILED) {
      return (
        <AiFillCloseCircle
          color='var(--danger)'
          size={18}
          title={`${category}-${status}`}
        />
      );
    } else if (status === EligibilityStatus.MORE_INFO_NEEDED) {
      return (
        <AiFillExclamationCircle
          color='var(--neutral)'
          size={18}
          title={`${category}-${status}`}
        />
      );
    } else {
      return (
        <BsFillArrowUpRightCircleFill
          color='var(--info-300)'
          size={16}
          title={`${category}-${status}`}
        />
      );
    }
  };

  const buildStatus = (eligibilityCriteria) => {
    const { category, status } = eligibilityCriteria;

    if (status === EligibilityStatus.COMPLETED) {
      if (category === EligibilityCategory.POLKADOT_WALLET) {
        return 'Connected';
      }
      return 'Passed';
    } else if (status === EligibilityStatus.PENDING) {
      return 'Pending Review';
    } else if (status === EligibilityStatus.FAILED) {
      if (category === EligibilityCategory.REGION) {
        return 'Not Eligible';
      }
      return 'Failed';
    } else if (status === EligibilityStatus.MORE_INFO_NEEDED) {
      if (category === EligibilityCategory.POLKADOT_WALLET) {
        return 'Not Started';
      }
      return 'Needs More Information';
    } else {
      return 'Not Started';
    }
  };

  const buildPopoverMessage = (eligibilityCriteria) => {
    const { category, status, helpText } = eligibilityCriteria;

    if (category === EligibilityCategory.IDENTITY) {
      if (status === EligibilityStatus.COMPLETED) {
        return 'Your identity has been verified successfully.';
      } else if (status === EligibilityStatus.PENDING) {
        return 'Your identity verification materials are under review.';
      } else if (status === EligibilityStatus.FAILED) {
        return 'Unfortunately, your identity could not be verified.';
      } else if (status === EligibilityStatus.MORE_INFO_NEEDED) {
        return 'Your identity could not be verified. Please provide more information to continue.';
      } else {
        return (
          <Text>
            You must verify your identity to participate in this event.
          </Text>
        );
      }
    }

    if (category === EligibilityCategory.REGION) {
      if (status === EligibilityStatus.COMPLETED) {
        return 'You reside in an eligible region.';
      } else if (status === EligibilityStatus.MORE_INFO_NEEDED) {
        return 'You must reside in an eligible region to participate in this event. Please verify your identity to determine your eligibility.';
      }
      return 'You do not reside in an eligible region.';
    }

    if (category === EligibilityCategory.ACCREDITATION) {
      if (status === EligibilityStatus.COMPLETED) {
        return 'Your accredited investor application has been verified successfully.';
      } else if (status === EligibilityStatus.PENDING) {
        return 'Your accredited investor application is under review.';
      } else if (status === EligibilityStatus.FAILED) {
        return 'Your accredited investor status could not be verified.';
      } else {
        return (
          <div className='flex flex-row items-center'>
            <span>
              You must be a US{' '}
              <a
                href='https://www.sec.gov/education/smallbusiness/exemptofferings/faq?auHash=rh5WfJi9h3wRzP6X2anOmgYLdhPHNuo-3Vw0YNZyR_M#faq2'
                target='_blank'
              >
                accredited investor
              </a>{' '}
              to participate in this event.
            </span>
          </div>
        );
      }
    }

    if (category === EligibilityCategory.POLKADOT_WALLET) {
      if (status === EligibilityStatus.COMPLETED && polkadotWallet) {
        return (
          <Text>
            You are currently connected with {polkadotWallet.walletAddress}. You
            can change your connected wallet in your{' '}
            <a href='/account/profile'>profile</a>.
          </Text>
        );
      } else {
        return <Text>You need to add a Polkadot wallet.</Text>;
      }
    }

    return (
      helpText ||
      'This item must be completed in order to be eligible for the event.'
    );
  };

  const buildItem = (eligibilityCriteria) => {
    const message = `${eligibilityCriteria.label} (${buildStatus(
      eligibilityCriteria,
    )})`;

    if (eligibilityCriteria.status !== EligibilityStatus.COMPLETED) {
      const popoverMessage = buildPopoverMessage(eligibilityCriteria);

      if (
        eligibilityCriteria.category === EligibilityCategory.IDENTITY &&
        eligibilityCriteria.status !== EligibilityStatus.FAILED
      ) {
        return (
          <HelpPopover
            button={
              <div
                className='flex flex-row items-center gap-3'
                data-testid={eligibilityCriteria.category + '-popover-button'}
              >
                <div
                  className='eligibility-message cursor-pointer'
                  onClick={handleCompleteKyc}
                >
                  <span
                    data-testid={
                      eligibilityCriteria.category + '-eligibility-message'
                    }
                    className='underline decoration-dotted underline-offset-4'
                  >
                    {message}
                  </span>
                </div>

                <div className='cursor-pointer'>
                  <InfoIcon />
                </div>
              </div>
            }
          >
            <Text
              textAlign={'center'}
              data-testid={
                eligibilityCriteria.category + '-eligibility-popover-message'
              }
            >
              {popoverMessage}
            </Text>
          </HelpPopover>
        );
      } else if (
        eligibilityCriteria.category === EligibilityCategory.POLKADOT_WALLET
      ) {
        return (
          <HelpPopover
            button={
              <div
                className='flex flex-row items-center gap-3'
                data-testid={eligibilityCriteria.category + '-popover-button'}
              >
                <div
                  className='eligibility-message cursor-pointer'
                  onClick={handleAddPolkadotWallet}
                >
                  <span
                    data-testid={
                      eligibilityCriteria.category + '-eligibility-message'
                    }
                    className='underline decoration-dotted underline-offset-4'
                  >
                    {message}
                  </span>
                </div>

                <div className='cursor-pointer'>
                  <InfoIcon />
                </div>
              </div>
            }
          >
            <Text
              textAlign={'center'}
              data-testid={
                eligibilityCriteria.category + '-eligibility-popover-message'
              }
            >
              {popoverMessage}
            </Text>
          </HelpPopover>
        );
      }
    }

    return (
      <div className='eligibility-message'>
        <span
          data-testid={eligibilityCriteria.category + '-eligibility-message'}
        >
          {message}
        </span>
      </div>
    );
  };

  const allEligibilityItemsCompleted =
    generatedEligibilityData?.eligibility?.every(
      (eligibilityItem) =>
        eligibilityItem.status === EligibilityStatus.COMPLETED,
    );

  const renderEligibilityCard = () => {
    return (
      <Col gap={5} className={!!event?.eventUsers[0]?.id ? '' : 'blur'}>
        {/* If event registration not open, don't render eligibility criteria section */}
        {event.isRegistrationOpen && (
          <>
            <Text>
              Please review the eligibility criteria below and provide any
              missing information. If you have questions about identity
              verification, please{' '}
              <a href='https://tokensoft.freshdesk.com/support/home'>
                contact support
              </a>
              .
            </Text>

            <Stretched xgap={5} ygap={5}>
              <Card>
                <CardHeader>
                  <CardTitle data-testid='eligibility-header'>
                    Eligibility Criteria
                  </CardTitle>
                </CardHeader>

                <CardBody>
                  <Col gap={5}>
                    {eligibilityItemsWithoutSubcriteria?.length > 0 ? (
                      <Text>
                        You must meet <strong>all</strong> of these criteria:
                      </Text>
                    ) : null}

                    {eligibilityItemsWithoutSubcriteria?.map(
                      (eligibilityItem, i) => (
                        <div
                          className='flex flex-row items-center gap-3 eligibility-item'
                          key={i}
                        >
                          <div
                            className=''
                            data-testid={
                              eligibilityItem.category + '-eligibility-icon'
                            }
                          >
                            {renderIcon(eligibilityItem)}
                          </div>
                          {buildItem(eligibilityItem)}
                        </div>
                      ),
                    )}

                    {eligibilityItemsWithSubcriteria?.map(
                      (eligibilityItem, i) => (
                        <>
                          {eligibilityItem.subcriteria.inclusionType ===
                          'AT_LEAST_ONE' ? (
                            <Text>
                              You must meet at least <strong>one</strong> of the
                              these criteria:
                            </Text>
                          ) : null}

                          {eligibilityItem.subcriteria.criteria.map(
                            (eligibilitySubItem, i) => (
                              <div
                                className='flex flex-row items-center gap-3 eligibility-item'
                                key={i}
                              >
                                <div
                                  className=''
                                  data-testid={
                                    eligibilitySubItem.category +
                                    '-eligibility-icon'
                                  }
                                >
                                  {renderIcon(eligibilitySubItem)}
                                </div>
                                {buildItem(eligibilitySubItem)}
                              </div>
                            ),
                          )}
                        </>
                      ),
                    )}
                  </Col>
                </CardBody>
              </Card>

              <EventEligibilityAllocations
                eligibilityData={generatedEligibilityData}
              />
            </Stretched>
          </>
        )}
        {/* Always render positive eligibility status if we can */}
        {allEligibilityItemsCompleted && (
          <>
            <Alert type='alert-success light'>
              <Text>
                You have met all eligibility criteria to participate in this
                event.
              </Text>
            </Alert>
            {event.type === EVENT_TYPE.IDENTITY ? (
            <Alert type='alert-info light'>
              <Text>
                Please return to the dashboard to participate in the event
                once it is live.
              </Text>
            </Alert>) : event.type === EVENT_TYPE.RAISE ? (
            <Alert type='alert-info light'>
              <Text>
              Please be patient as your account is enabled after completing KYC.  Once processed, a purchase form will appear on this page for you to continue.
              </Text>
            </Alert>) : null}
          </>
        )}
      </Col>
    );
  };

  const renderLateRegistration = () => {
    if (event.status === EVENT_STATUS.CURRENT && event.isRegistrationOpen) {
      if (event.type === EVENT_TYPE.IDENTITY) {
        return (
          <Col ygap={5}>
            <SectionTitle>Live Now</SectionTitle>
            <Col ygap={2.5}>
              <Text>
                Please request access to view details, verify your eligibility,
                and register.
              </Text>
            </Col>
          </Col>
        );
      } else {
        return (
          <Col ygap={5}>
            <SectionTitle>Late Registration</SectionTitle>
            <Col ygap={2.5}>
              <Text>
                This event is now open for users who meet the eligibility
                requirements.
              </Text>
            </Col>
          </Col>
        );
      }
    } else if (!event.isRegistrationOpen) {
      const isRegistrationInFuture =
        new Date(event.registrationStartTime) > new Date();

      const msg = isRegistrationInFuture
        ? 'Registration is not open yet.'
        : 'Registration is closed.';
      return (
        <Alert type='alert-warning light'>
          <Text>{msg}</Text>
        </Alert>
      );
    }

    return <></>;
  };

  if (!generatedEligibilityData) {
    return null;
  }

  if (generatedEligibilityData.eligibility?.length === 0) {
    return (
      <Stretched xgap={5} ygap={5}>
        <Card>
          <CardHeader>
            <CardTitle data-testid='eligibility-header'>
              Eligibility Criteria
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Row gap={5}>
              <div className='flex flex-row items-center gap-3 eligibility-item'>
                <div className=''>
                  <AiFillCheckCircle
                    color='var(--success)'
                    size={18}
                    title={`You're all set.`}
                  />
                </div>
                <Text>You are registered for this event.</Text>
              </div>
            </Row>
          </CardBody>
        </Card>
      </Stretched>
    );
  }

  // If current user has already requested access
  if (!!event?.eventUsers[0]?.id) {
    return (
      <Stacked>
        {renderLateRegistration()}
        {renderEligibilityCard()}
      </Stacked>
    );
  }

  return (
    <Stacked>
      {renderLateRegistration()}

      <Row place={'center'} className={'relative'}>
        <Button className={'btn-floating'} onClick={handleRequestAccess}>
          {requestAccessIsLoading ? (
            <>
              <div className='animate-spin'>
                <AiOutlineLoading3Quarters size={16} />
              </div>
              <Text>Requesting Access</Text>
            </>
          ) : (
            <>
              <Text>Request Access</Text>
            </>
          )}
        </Button>

        {renderEligibilityCard()}
      </Row>
    </Stacked>
  );
};

export default EventEligibility;
