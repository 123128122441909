import { createContext, useContext } from 'react';

interface NetworkContextProps {
  supportedNetworks?: any;
  isSupportedNetwork?: Function;
  getNetworkDetails?: Function;
  getNativeBaseCurrencyPriceOracle?: Function;
  supportsENSDomain?: Function;
  loading?: boolean;
}

export const NetworkContext = createContext<NetworkContextProps | undefined>(
  undefined,
);
NetworkContext.displayName = 'NetworkContext';

export const useNetworks = (): NetworkContextProps => {
  const context = useContext(NetworkContext);

  if (!context) {
    throw new Error(
      'NetworkContext context is undefined, please verify you are calling useNetworks() as child of a <NetworkContext> component.',
    );
  }

  return context;
};
