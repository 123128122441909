import {
  FEATURE,
  FEATURE_RESTRICTION,
  useAccount,
  useCreateProject,
  useFeature,
  useToast,
} from '@tokensoft-web/common-utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RegisterProjectPage from '../../../components/admin/register/register-project';
import RegisterUserPage from '../../../components/admin/register/register-user';
import GlobalRestriction from '../../../components/feature/global-restriction';
import KycRestriction from '../../../components/feature/kyc-restriction';
import RegionRestriction from '../../../components/feature/region-restriction';
import WalletAddressRestriction from '../../../components/feature/wallet-address-restriction';
import WhitelabelRestriction from '../../../components/feature/whitelabel-restriction';

export const RegisterPage = () => {
  const { showErrorToast } = useToast();
  const { getFeatureRestriction } = useFeature();
  const { account } = useAccount();
  const navigate = useNavigate();
  const { isLoading, mutate: createProject } = useCreateProject();
  const restriction = getFeatureRestriction(FEATURE.REGISTRATION);

  const [step, setStep] = useState('project');
  const [projectName, setProjectName] = useState(null);
  const [userRole, setUserRole] = useState({
    label: 'Admin',
    value: 'admin',
  });
  const [userInfo, setUserInfo] = useState({
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    phoneNumber: undefined,
  });

  useEffect(() => {
    if (account) {
      setUserInfo({
        firstName: account.firstName,
        lastName: account.lastName,
        email: account.email,
        phoneNumber: account.phoneNumber,
      });
    }
  }, [account]);

  const handleUserInfoChange = (e) => {
    setUserInfo((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleProjectNameChange = (e) => setProjectName(e.target.value);

  const handleProjectSubmit = () => {
    setStep('user');
  };

  const handleUserBack = () => {
    setStep('project');
  };

  const handleUserSubmit = () => {
    createProject(
      {
        project: {
          name: projectName,
          ownerCompanyRole: userRole.value,
        },
        account: {
          ...userInfo,
          id: account.id,
        },
      },
      {
        onSuccess: (data: { id: number }) =>
          navigate(`/admin/project/${data.id}`),
        onError: (error) => showErrorToast({ description: error.message }),
      },
    );
  };

  if (restriction) {
    switch (restriction) {
      case FEATURE_RESTRICTION.GLOBAL_RESTRICTION:
        return <GlobalRestriction />;
      case FEATURE_RESTRICTION.WHITELABEL_RESTRICTION:
        return <WhitelabelRestriction />;
      case FEATURE_RESTRICTION.KYC_RESTRICTION:
        return <KycRestriction />;
      case FEATURE_RESTRICTION.REGION_RESTRICTION:
        return <RegionRestriction />;
      case FEATURE_RESTRICTION.WALLET_ADDRESS_RESTRICTION:
        return <WalletAddressRestriction />;
    }
  }

  return (
    <div className='pt-8'>
      <div className={'w-full'}>
        {step === 'project' ? (
          <RegisterProjectPage
            projectName={projectName}
            onProjectNameChange={handleProjectNameChange}
            onSubmit={handleProjectSubmit}
          />
        ) : (
          <RegisterUserPage
            onBack={handleUserBack}
            onSubmit={handleUserSubmit}
            userInfo={userInfo}
            onUserInfoChange={handleUserInfoChange}
            userRole={userRole}
            onUserRoleChange={setUserRole}
            projectCreationPending={isLoading}
          />
        )}
      </div>
    </div>
  );
};
