import AdvancedDistributorDefinition from '../contracts/AdvancedDistributor.json';
import ChainlinkOracleDefinition from '../contracts/ChainlinkOracle.json';
import ContinuousVestingMerkleDefinition from '../contracts/ContinuousVestingMerkle.json';
import ContinuousVestingMerkleDistributorFactoryDefinition from '../contracts/ContinuousVestingMerkleDistributorFactory.json';
import CrosschainDistributorDefinition from '../contracts/CrosschainDistributor.json';
import Distributor from '../contracts/Distributor.json';
import Erc20Definition from '../contracts/Erc20.json';
import FlatPriceSaleDefinition from '../contracts/FlatPriceSale.json';
import FlatPriceSaleFactoryDefinition from '../contracts/FlatPriceSaleFactory.json';
import IPriceTierVesting from '../contracts/IPriceTierVesting.json';
import SatelliteDefinition from '../contracts/SatelliteDefinition.json';
import TrancheVestingDistribution from '../contracts/TrancheVestingDistribution.json';
import TrancheVestingMerkleDefinition from '../contracts/TrancheVestingMerkle.json';
import TrancheVestingMerkleDistributorFactoryDefinition from '../contracts/TrancheVestingMerkleDistributorFactory.json';
import Erc20USDTDefinition from '../contracts/USDT.json';

export enum DISTRIBUTOR_TYPES {
  // base distributor
  IDistributor = 'IDistributor',
  // Adbanced Distributor
  AdvancedDistributor = 'AdvancedDistributor',
  // unclaimed tokens in the distributor have voting power
  IVoting = 'IVoting',
  // distributor eligibility is determined by a merkle proof
  IMerkleSet = 'IMerkleSet',
  // tokens to distribute are vested/unlocked based on some conditions
  IVesting = 'IVesting',
  // tokens unlock in discrete chunks over time
  ITrancheVesting = 'ITrancheVesting',
  // tokens unlock based on price of a reference asset
  IPriceTierVesting = 'IPriceTierVesting',
  // tokens unlock continuously
  IContinuousVesting = 'IContinuousVesting',
  // one off interface to support misconfigured softdrop distributors
  IVotingWithoutDelegation = 'IVotingWithoutDelegation',
  // CrosschainDistributor for connext
  CrosschainDistributor = 'CrosschainDistributor',
  // Satellite contracts for connext
  Satellite = 'Satellite',
  IConnext = 'IConnext',
}

export const getSatelliteContractForChainId = (
  distributor,
  chainId: number,
) => {
  return distributor?.contracts?.find((contract) => {
    if (contract.networkId === chainId) {
      return contract;
    }

    return null;
  });
};

export const getConnextContracts = (distributor) => {
  return distributor.contracts?.filter((contract) =>
    isConnextContract(contract.interfaces),
  );
};

export const isDistributorDelegationEnabled = (distributor) => {
  const interfaces = getDistributorInterfaces(distributor);
  return isDelegationEnabled(interfaces);
};

export const getDistributorInterfaces = (distributor) => {
  const distributorContract = distributor.contracts?.find(
    (contract) => contract.id === `${distributor.id}-${distributor.chainId}`,
  );
  return distributorContract ? distributorContract.interfaces : [];
};

export const isDelegationEnabled = (interfaces) => {
  const isVoting = isVotingType(interfaces);
  const isVotingWithoutDelegation = isVotingWithoutDelegationType(interfaces);
  return isVoting && !isVotingWithoutDelegation;
};

export const isVotingWithoutDelegationType = (interfaces) => {
  return (
    interfaces?.findIndex(
      (iface) => iface.name === DISTRIBUTOR_TYPES.IVotingWithoutDelegation,
    ) >= 0
  );
};

export const isContinuousVestingType = (interfaces) => {
  return (
    interfaces?.findIndex(
      (iface) => iface.name === DISTRIBUTOR_TYPES.IContinuousVesting,
    ) >= 0
  );
};

export const isAdvancedDistributorType = (interfaces) => {
  return (
    interfaces?.findIndex(
      (iface) => iface.name === DISTRIBUTOR_TYPES.AdvancedDistributor,
    ) >= 0
  );
};

export const isTrancheVestingType = (interfaces) => {
  return (
    interfaces?.findIndex(
      (iface) => iface.name === DISTRIBUTOR_TYPES.ITrancheVesting,
    ) >= 0
  );
};

export const isCrosschainDistributorType = (interfaces) => {
  return (
    interfaces?.findIndex(
      (iface) => iface.name === DISTRIBUTOR_TYPES.CrosschainDistributor,
    ) >= 0
  );
};

export const isIPriceTierVesting = (interfaces) => {
  return (
    interfaces?.findIndex(
      (iface) => iface.name === DISTRIBUTOR_TYPES.IPriceTierVesting,
    ) >= 0
  );
};

export const isSatelliteContract = (interfaces) => {
  return (
    interfaces?.findIndex(
      (iface) => iface.name === DISTRIBUTOR_TYPES.Satellite,
    ) >= 0
  );
};

export const isConnextContract = (interfaces) => {
  return (
    interfaces?.findIndex(
      (iface) => iface.name === DISTRIBUTOR_TYPES.IConnext,
    ) >= 0
  );
};

export const isVotingType = (interfaces) => {
  return (
    interfaces?.findIndex(
      (iface) => iface.name === DISTRIBUTOR_TYPES.IVoting,
    ) >= 0
  );
};

export const getDelegateABI = () => {
  // FIXME: This should use a voting abi and not the price tier vesting abi
  return IPriceTierVesting;
};

export const getVotesABI = () => {
  return Distributor;
};

export const getClaimableAmountABI = () => {
  return Distributor;
};

export const getPurchasedAmountABI = () => {
  return TrancheVestingDistribution;
};

export const getFlatPriceSaleABI = () => {
  return FlatPriceSaleDefinition;
};

export const getAdvancedDistributorABI = () => {
  return AdvancedDistributorDefinition;
};

export const getCrosschainDistributorABI = () => {
  return CrosschainDistributorDefinition;
};

export const getSatelliteABI = () => {
  return SatelliteDefinition;
};

export const getErc20ABI = (isUsdt: boolean = false) => {
  if (isUsdt) {
    return Erc20USDTDefinition;
  }
  return Erc20Definition;
};

export const getChainlinkOracleABI = () => {
  return ChainlinkOracleDefinition;
};

export const getFlatPriceSaleFactoryABI = () => {
  return FlatPriceSaleFactoryDefinition;
};

export const getContinuousVestingMerkleABI = () => {
  return ContinuousVestingMerkleDefinition;
};

export const getTrancheVestingMerkleABI = () => {
  return TrancheVestingMerkleDefinition;
};

export const getContinuousVestingMerkleDistributorFactoryABI = () => {
  return ContinuousVestingMerkleDistributorFactoryDefinition;
};

export const getTrancheVestingMerkleDistributorFactoryABI = () => {
  return TrancheVestingMerkleDistributorFactoryDefinition;
};
