export enum SALE_ACCESS {
  PUBLIC = 'Public',
  PRIVATE = 'Private',
}

export enum TRANSACTION_TYPE {
  PURCHASE = 'PURCHASE',
  CLAIM = 'CLAIM',
}

export enum TX_DESCRIPTION_TYPE {
  PURCHASE = 'PURCHASE',
  CLAIM = 'CLAIM',
  VOID = 'VOID',
}

export enum FILE_TYPE {
  IMAGES = 'image/*',
  PNG = 'image/png',
  PDF = 'application/pdf',
  CSV = 'text/csv',
}

export enum PAYMENT_STEP {
  FAIR_QUEUE = 'Fair Queue',
  TERMS = 'Terms',
  PURCHASE = 'Purchase',
  CONFIRMATION = 'Confirmation',
  RECEIPT = 'Receipt',
}

export enum TRADING_STATE {
  MAIN = 'main',
  GAS = 'gas',
  SELECT_QUOTE_TOKEN = 'selectQuoteToken',
  SELECT_BASE_TOKEN = 'selectBaseToken',
  TRADE_PENDING = 'tradePending',
  TRADE_DETAILS = 'tradeDetails',
  TRADE_CONFIRMED = 'tradeConfirmed',
  TRANS_REJECTED = 'transactionRejected',
  TRANS_SUBMITTED = 'transactionSubmitted',
}

export enum CLAIM_STEP {
  CLAIM = 'CLAIM',
  INTRO = 'INTRO',
  UPCOMING = 'UPCOMING',
  DELEGATE = 'DELEGATE',
}

export enum SALE_STEP {
  SALE = 'SALE',
  SALE_DETAILS = 'SALE_DETAILS',
}

export enum VESTING_TYPE {
  NO_VESTING = 'NO_VESTING',
  TRANCHE = 'TRANCHE',
  CONTINUOUS = 'CONTINUOUS',
}

export enum DEPLOY_VESTING_TYPE_OPTIONS {
  INSTANT = 'instant',
  CONTINUOUS = 'continuous',
  MONTHLY = 'monthly',
}

export enum EVENT_SIGNATURE_TYPE {
  CONNEXT = 'CONNEXT',
}

export const enum DOCUMENT_CATEGORY {
  COMPLIANCE_DOCUMENTS = 'COMPLIANCE_DOCUMENTS',
  PURCHASE_AGREEMENTS = 'PURCHASE_AGREEMENTS',
  CLAIM_AGREEMENTS = 'CLAIM_AGREEMENTS',
  PRIVACY_DOCUMENTS = 'PRIVACY_DOCUMENTS',
}

export const enum USER_EVENT_TABLE_COLUMNS {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  EMAIL = 'EMAIL',
  LEGACY_EMAIL = 'LEGACY_EMAIL',
  ENTITY_NAME = 'ENTITY_NAME',
  WALLET_ADDRESS = 'WALLET_ADDRESS',
  REFERRAL_CODE = 'REFERRAL_CODE',
  COUNTRY = 'COUNTRY',
  COMPLIANCE = 'COMPLIANCE',
  ACCREDITATION = 'ACCREDITATION',
  IDENTITY = 'IDENTITY',
  ELIGIBLE = 'ELIGIBLE',
}

export enum EligibilityCategory {
  IDENTITY = 'IDENTITY',
  REGION = 'REGION',
  ACCREDITATION = 'ACCREDITATION',
  POLKADOT_WALLET = 'POLKADOT_WALLET',
  ELIGIBILITY_ALLOCATIONS = 'ELIGIBILITY_ALLOCATIONS',
}

export enum EligibilityStatus {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  MORE_INFO_NEEDED = 'MORE_INFO_NEEDED',
}

export const enum LayoutType {
  TITLE_ICON_50_50 = 'title_icon_50_50',
  TITLE_ICON_50_50_REVERSED = 'title_icon_reversed_50_50',
  ICON_100 = 'icon_100',
  TITLE_100 = 'title_100',
  TITLE_ICON_CENTERED = 'title_icon_centered',
  TITLE_ONLY_100_CENTERED = 'title_only_100_centered',
  TITLE_ONLY_100_LEFT = 'title_only_100_left',
  TITLE_ONLY_100_RIGHT = 'title_only_100_right',
}
export const enum LayoutTypeName {
  TITLE_ICON_50_50 = 'Standard',
  TITLE_ICON_50_50_REVERSED = 'Reversed',
  ICON_100 = 'Icon Centered',
  TITLE_100 = 'Title Centered',
  TITLE_ICON_CENTERED = 'Center Aligned w/ Logo',
  TITLE_ONLY_100_CENTERED = 'Center Aligned Text Only',
  TITLE_ONLY_100_LEFT = 'Left Aligned Text Only',
  TITLE_ONLY_100_RIGHT = 'Right Aligned Text Only',
}
