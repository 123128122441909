import { createContext, useContext } from 'react';
import { CLAIM_STEP } from '../../utils/enums';

interface DistributorContextProps {
  event: any;
  distributor: any;
  isLoading: boolean;
  delay: any;
  documentsToAcceptOrSign: any[];
  pageState: CLAIM_STEP;
  setPageState: Function;
  delegateSaved: any;
  setDelegateSaved: Function;
  pendingTransaction: any;
  onPendingTransaction: Function;
  completedTransaction: any;
  onCompletedTransaction: Function;
  correctNetworkChainId: number;
}

export const DistributorContext =
  createContext<DistributorContextProps>(undefined);
DistributorContext.displayName = 'DistributorContext';

export const useDistributor = (): DistributorContextProps => {
  return useContext(DistributorContext);
};
