import {
  BackButton,
  ButtonRow,
  PageSubtitle,
  PageTitle,
  SaveButton,
  Step,
  StepFooter,
  StepHeader,
} from '@tokensoft-web/common-ui';
import {
  EVENT_TYPE,
  localToUtcDateTime,
  useAccount,
  useToast,
  useUpdateEvent,
  utcToEditableDateTime,
} from '@tokensoft-web/common-utils';
import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wizard } from 'react-use-wizard';
import { EventPageSetupGeneral } from '../../../components/admin/event/event-page-setup-general';
import { PreviewPage } from '../../../components/admin/event/preview-page';
import { useEvent } from '../../../contexts/event/event-context';

const WizardContext = createContext(null);

export const EditEventGeneralWizard = () => {
  const { account } = useAccount();
  const navigate = useNavigate();
  const { event } = useEvent();
  const { showErrorToast, showSuccessToast } = useToast();

  const { mutate: update, isPending: updateLoading } = useUpdateEvent();

  const [wizardState, setWizardState] = useState<any>({
    eventPageGeneralFormValid: false,
    eventId: event?.id,
    projectId: event?.project?.id,
    name: event?.name,
    description: event?.description,
    startTime: event?.registrationStartTime
      ? utcToEditableDateTime(event.registrationStartTime, account?.timezone)
      : null,
    endTime: event?.registrationEndTime
      ? utcToEditableDateTime(event.registrationEndTime, account?.timezone)
      : null,
    alwaysOpen: !!event?.endTime,
    eventType: event?.type,
  });

  const renderPreviewContent = () => {
    return <PreviewPage context={wizardState} />;
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    let payload: any = {
      id: event.id,
      name: wizardState.name,
      description: wizardState.description,
      registrationStartTime: localToUtcDateTime(
        wizardState.startTime,
        account?.timezone,
      ),
      registrationEndTime: wizardState.endTime
        ? localToUtcDateTime(wizardState.endTime, account?.timezone)
        : null,
    };

    // keep event start/end time in sync with registration dates
    if (event.type === EVENT_TYPE.IDENTITY) {
      payload = {
        ...payload,
        startTime: localToUtcDateTime(wizardState.startTime, account?.timezone),
        endTime: wizardState.endTime
          ? localToUtcDateTime(wizardState.endTime, account?.timezone)
          : null,
      };
    }

    update(payload, {
      onSuccess: (data) => {
        showSuccessToast({ description: 'Successfully updated event.' });
        goBack();
      },
      onError: (error) => {
        showErrorToast({ description: error.message });
      },
    });
  };

  const handleCancel = () => {
    goBack();
  };

  return (
    <WizardContext.Provider
      value={{
        wizardState,
        setWizardState,
      }}
    >
      <Wizard>
        <Step>
          <StepHeader>
            <PageTitle>General Information</PageTitle>
            <PageSubtitle>
              Let’s start with some general info about your event. Please fill
              out your event title, subtitle and pick a start date for your open
              registration period. This information will be part of your
              end-user facing event.
            </PageSubtitle>
          </StepHeader>

          <EventPageSetupGeneral
            editMode
            wizardState={wizardState}
            updateWizardState={setWizardState}
          />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton
                label={'Cancel'}
                onClick={handleCancel}
                disabled={updateLoading}
              />
              <SaveButton
                disabled={
                  !wizardState?.eventPageGeneralFormValid || updateLoading
                }
                saving={updateLoading}
                onClick={handleSave}
              />
            </ButtonRow>
          </StepFooter>
        </Step>
      </Wizard>
    </WizardContext.Provider>
  );
};
