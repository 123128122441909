import classNames from 'classnames';
import './event-users-table-header.css';

import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';

type EventUsersTableHeader = {
  content: any;
  orderKey?: string;
  orderModify?: Function;
  orderList?: object;
  disabled?: boolean;
};

export const EventUsersTableHeader = ({
  content,
  orderKey,
  orderList,
  orderModify,
  disabled,
}: EventUsersTableHeader) => {
  const handleHeaderOnClick = () => {
    if (orderKey && orderList && orderModify && !disabled) {
      switch (orderList[orderKey].desc) {
        case true:
          orderModify(orderKey, null);
          break;
        case false:
          orderModify(orderKey, true);
          break;
        default:
          orderModify(orderKey, false);
      }
    }
  };

  return (
    <div
      className={classNames('table-header', orderList?.[orderKey] && 'ordered')}
      onClick={handleHeaderOnClick}
    >
      {content}
      {orderList?.[orderKey] ? (
        orderList[orderKey].desc !== null ? (
          orderList[orderKey].desc === false ? (
            <FaSortDown size={14} />
          ) : (
            <FaSortUp size={14} />
          )
        ) : (
          <FaSort className='text-neutral-400' size={14} />
        )
      ) : null}
    </div>
  );
};
