import {
  EVENT_TYPE,
  div,
  gte,
  mult,
  sub,
  toNumber,
  uniqueValues,
  useAsync,
  useAuth,
  useGetDistributors,
  useGetEvents,
} from '@tokensoft-web/common-utils';
import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import { usePublicClient } from 'wagmi';
import {
  getClaimableAmountABI,
  getDistributorInterfaces,
  isContinuousVestingType,
  isIPriceTierVesting,
  isTrancheVestingType,
} from '../utils/abi';
import {
  getVestedFractionForContinuous,
  getVestedFractionForPriceTier,
  getVestedFractionForTranche,
} from '../utils/claim';
import { TierVesting, Tranche } from '../utils/interface';
import { getEthersContract } from './ethers-service';

export const useGetDistributorSummary = () => {
  const { results: events, loading: eventsLoading } = useGetEvents([
    EVENT_TYPE.DISTRIBUTE,
  ]);
  const { results: merkleDistributors, loading: merkleDistributorsLoading } =
    useGetDistributors(true);
  const [distributorData, setDistributorData] = useState([]);

  useEffect(() => {
    if (!events) {
      return;
    }

    setDistributorData(events);
  }, [events]);

  useEffect(() => {
    if (eventsLoading) {
      return;
    }

    if (!distributorData) {
      return;
    }

    if (!merkleDistributors) {
      return;
    }

    const newDistributorData = [...events];
    merkleDistributors.forEach((distributor) => {
      const loadedDistributorIndex = newDistributorData.findIndex(
        (d) => d.distributor.id === distributor.id,
      );
      if (loadedDistributorIndex >= 0) {
        const loadedDistributor = newDistributorData[loadedDistributorIndex];
        newDistributorData[loadedDistributorIndex] = Object.assign(
          {},
          distributor,
          loadedDistributor,
          { name: loadedDistributor.name },
        );
      }
    });

    setDistributorData(newDistributorData);
  }, [merkleDistributors, eventsLoading]);

  return {
    results: distributorData,
    loading: eventsLoading,
    merkleDataLoading: merkleDistributorsLoading,
  };
};

export const useGetSaleDistributors = () => {
  const {
    isAuthenticated,
    user: { walletAddress },
  } = useAuth();
  const { run, data } = useAsync();
  const { distributors } = useGetDistributors(false);
  const [searchResults, setSearchResults] = useState([]);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const provider = usePublicClient();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const saleDistributors = distributors?.filter(
      (distributor) => distributor.sale?.id != null,
    );
    for (let i = 0; i < saleDistributors?.length; i++) {
      const distributor = distributors[i];
      const interfaces = getDistributorInterfaces(distributor);

      /* Setup default query parameters */
      run(
        getClaimableAmount(
          provider,
          '0',
          walletAddress,
          distributor.id,
          distributor.chainId,
          interfaces,
        ),
      );
    }
  }, [isAuthenticated, walletAddress, distributors]);

  useEffect(() => {
    if (!data) {
      return;
    }

    setSearchResults(uniqueValues(searchResults.concat(data)));

    const saleDistributors = distributors?.filter(
      (distributor) => distributor.sale?.id != null,
    );
    setLoadingComplete(saleDistributors.length === searchResults.length);
  }, [data]);

  return { results: searchResults, loading: loadingComplete };
};

export const getClaimableAmount = async (
  provider: any,
  fallbackValue: string,
  walletAddress: string,
  distributionAddress: string,
  chainId: number,
  interfaces: any[],
  start?: number,
  cliff?: number,
  end?: number,
  tranches?: Tranche[],
  fractionDenominator?: string,
  // If interpolating data, skip on chain query and calculate our own claimable
  interpolateData?: boolean,
) => {
  const fallbackValueNum = fallbackValue
    ? div(fallbackValue, fractionDenominator)
    : toNumber(0);

  console.log('Calculating Claimable Amount...');
  console.log('Fallback:', fallbackValue.toString());
  console.log('Fetching ABI');
  const distributionABI = getClaimableAmountABI();

  console.log('Fetching Contract');
  const distributionContract = getEthersContract(
    distributionAddress,
    // @ts-ignore
    distributionABI,
    provider,
  );

  // if merkle root use getClaimableAmount, otherwise use getPurchasedAmount
  if (
    isContinuousVestingType(interfaces) ||
    isTrancheVestingType(interfaces) ||
    isIPriceTierVesting(interfaces)
  ) {
    console.log('Approved Vesting Type');
    try {
      if (!interpolateData) {
        console.log('Interpolation Off - Returning Contract Claimable Amount');
        const claimableAmount =
          await distributionContract.getClaimableAmount(walletAddress);
        console.log('Contract Claimable Amount', claimableAmount);
        return toNumber(claimableAmount.toString());
      }

      // Manually calculate
      throw new Error();
    } catch (_) {
      try {
        // Since getClaimableAmount call was not successful OR interpolate data, try to get a distribution record
        console.log('Fetching Distribution Record');
        const distributionRecord =
          await distributionContract.getDistributionRecord(walletAddress);
        console.log('Distribution Record', distributionRecord);

        let recordTotalNumber;
        if (!distributionRecord.initialized) {
          recordTotalNumber = toNumber(fallbackValue);
        } else {
          recordTotalNumber = toNumber(distributionRecord.total.toString());
        }

        console.log('Distribution Record Total:', recordTotalNumber.toString());
        console.log(
          'Distribution Record Initialized:',
          distributionRecord.initialized,
        );
        console.log(
          'Distribution Record Claimed:',
          distributionRecord.claimed.toString(),
        );

        const claimable = interpolateClaimbleForInterface(
          interfaces,
          recordTotalNumber,
          start,
          cliff,
          end,
          tranches,
          toNumber(fractionDenominator),
        );
        console.log('Claimable:', claimable);

        if (!distributionRecord.initialized) {
          return claimable;
        }

        const recordClaimedNumber = toNumber(
          distributionRecord.claimed.toString(),
        );
        return gte(recordClaimedNumber, claimable)
          ? toNumber(0) // no more tokens to claim
          : sub(claimable, recordClaimedNumber); // claim all available tokens
      } catch (err) {
        console.log('Error Calculating Claimable Amount', err);
      }

      return fallbackValueNum;
    }
  }

  try {
    const claimableAmount =
      await distributionContract.getPurchasedAmount(walletAddress);
    return toNumber(claimableAmount.toString());
  } catch (e) {
    return fallbackValueNum;
  }
};

export const interpolateClaimbleForInterface = (
  interfaces: any[],
  recordTotal: number | string | BigNumber,
  start?: number,
  cliff?: number,
  end?: number,
  tranches?: Tranche[],
  fractionDenominator?: BigNumber,
  oraclePrice?: number,
  tierVesting?: TierVesting[],
) => {
  if (isContinuousVestingType(interfaces)) {
    const vestedFraction = getVestedFractionForContinuous(
      start,
      cliff,
      end,
      fractionDenominator,
    );
    return div(mult(recordTotal, vestedFraction), fractionDenominator);
  } else if (isTrancheVestingType(interfaces)) {
    // Manually calculate for tranche
    const vestedFraction = getVestedFractionForTranche(tranches);
    console.info('vested fraction:', vestedFraction);
    return div(mult(recordTotal, vestedFraction), fractionDenominator);
  } else if (isIPriceTierVesting(interfaces)) {
    // get vested fraction for price tier
    if (!tierVesting || !tierVesting.length || !oraclePrice) {
      return toNumber(0);
    }
    const vestedFraction = getVestedFractionForPriceTier(
      tierVesting,
      oraclePrice,
    );
    console.info('vested fraction:', vestedFraction);
    return div(mult(recordTotal, vestedFraction), fractionDenominator);
  }

  return recordTotal ? div(recordTotal, fractionDenominator) : toNumber(0);
};
