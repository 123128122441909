export const TokensoftIcon = () => (
  <svg version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 612 612'>
    <g>
      <rect
        x='406.125'
        y='253.123'
        style={{ fill: 'var(--logo-color)' }}
        width='93.008'
        height='93.008'
      />
      <polygon
        style={{ fill: 'var(--logo-color)' }}
        points='406.125,172.839 139.834,172.839 139.834,253.102 232.845,253.102 232.845,439.128
        313.109,439.128 313.109,253.102 406.125,253.102 	'
      />
    </g>
  </svg>
);
