import { Col, PDFIcon, Row, Text, TextLink } from '@tokensoft-web/common-ui';
import {
  getTruncatedAddress,
  getTxUrl,
  useAuth,
  useModal,
  useNetworks,
  viewDocumentVersion,
  viewEventUserDocumentVersion,
} from '@tokensoft-web/common-utils';
import { FaRegFilePdf } from 'react-icons/fa';
import { VscLinkExternal } from 'react-icons/vsc';
import { TX_DESCRIPTION_TYPE } from '../../utils/enums';
import { Transaction } from '../../utils/interface';

const TransactionsTableRow = ({
  transaction,
}: {
  transaction: Transaction;
}) => {
  const { user } = useAuth();
  const { getNetworkDetails } = useNetworks();
  const { showModal } = useModal();
  const { token } = user;
  const description = transaction?.description;
  const createdAt = transaction?.createdAt;
  const transactionHash = transaction?.transactionHash;
  const sale = transaction?.sale;

  const eventUserDocuments = sale.event?.eventUsers[0]?.documents?.filter(
    (document) => document.signed_at && document.generated_at,
  );
  const eventDocuments = sale?.event?.documents.filter(
    (document) => !document.requires_signature,
  );

  const hasDocuments =
    transaction?.type === TX_DESCRIPTION_TYPE.PURCHASE &&
    (eventDocuments?.length > 0 || eventUserDocuments?.length > 0);

  const handleViewDocumentsClick = () => {
    showModal({
      content: (
        <div className={'p-4'}>
          <table className={'w-full'}>
            <thead>
              <tr>
                <th
                  style={{ borderBottom: '1px solid var(--neutral-light)' }}
                  className={'text-left'}
                >
                  Purchase Documents
                </th>
              </tr>
            </thead>
            <tbody>
              {eventDocuments?.map((doc, i) => {
                return (
                  <tr key={i}>
                    <td className='pt-2 truncate'>
                      <a
                        className='high-contrast-link'
                        onClick={() => viewDocumentVersion(doc, token)}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <div className={'flex flex-row items-center'}>
                          <FaRegFilePdf size={18} />
                          <span className={'ml-2'}>
                            {doc.documentVersion.document.name}
                          </span>
                        </div>
                      </a>
                    </td>
                  </tr>
                );
              })}
              {eventUserDocuments?.map((doc, i) => {
                return (
                  <tr key={i}>
                    <td className='pt-2 truncate'>
                      <a
                        className='high-contrast-link'
                        onClick={() =>
                          viewEventUserDocumentVersion(doc.id, token)
                        }
                        target='_blank'
                        rel='noreferrer'
                      >
                        <div className={'flex flex-row items-center'}>
                          <FaRegFilePdf size={18} />
                          <span className={'ml-2'}>
                            {
                              doc.eventDocumentVersion.documentVersion.document
                                .name
                            }
                          </span>
                        </div>
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className={'mt-2'}>
            <span className={'text-xs font-normal'}>
              * Documents may take up to 24 hours to appear.
            </span>
          </div>
        </div>
      ),
      onClose: () => {},
    });
  };

  return (
    <tr>
      <td
        className={'text-left py-2 align-top lg:hidden inline-block'}
        colSpan={4}
      >
        <Col ygap={2.5}>
          <div>
            {`${description}`} on {createdAt}
          </div>

          <TextLink place={'start-center'}>
            <a
              target='_blank'
              rel='noreferrer'
              href={getTxUrl(transactionHash, getNetworkDetails(sale.chainId))}
              onClick={(e) => e.stopPropagation()}
            >
              <Row nowrap place={'start-center'} gap={2.5}>
                <Text className={'font-normal'}>
                  {getTruncatedAddress(transaction.transactionHash)}
                </Text>
                <VscLinkExternal />
              </Row>
            </a>
          </TextLink>

          {hasDocuments ? (
            <a
              onClick={() => {
                handleViewDocumentsClick();
              }}
            >
              <Row xgap={2.5} ygap={5} place={'start'}>
                <PDFIcon />
                <Text>View Documents</Text>
              </Row>
            </a>
          ) : null}
        </Col>
      </td>
      <td
        className={'text-left py-2 align-top hidden lg:inline-block'}
        style={{ width: '15%', paddingRight: '16px' }}
      >
        <TextLink place={'start-center'}>
          <a
            target='_blank'
            rel='noreferrer'
            href={getTxUrl(transactionHash, getNetworkDetails(sale.chainId))}
            onClick={(e) => e.stopPropagation()}
          >
            <Row nowrap place={'start-center'} gap={2.5}>
              <Text className={'font-normal'}>
                {getTruncatedAddress(transaction.transactionHash)}
              </Text>
              <VscLinkExternal />
            </Row>
          </a>
        </TextLink>
      </td>
      <td
        className={'text-left py-2 align-top hidden lg:inline-block'}
        style={{ width: '40%', paddingRight: '16px' }}
      >
        {`${description}`}
      </td>
      <td
        className={'text-left py-2 align-top hidden lg:inline-block'}
        style={{ width: '30%', paddingRight: '16px' }}
      >
        {createdAt}
      </td>
      <td
        className={'text-left lg:pl-8 py-2 align-top hidden lg:inline-block'}
        style={{ width: '15%', paddingRight: '16px' }}
      >
        {hasDocuments ? (
          <div>
            <a
              className={'default-contrast-link'}
              onClick={() => {
                handleViewDocumentsClick();
              }}
            >
              <PDFIcon />
            </a>
          </div>
        ) : null}
      </td>
    </tr>
  );
};

export default TransactionsTableRow;
