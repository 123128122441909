import {
  BackButton,
  ButtonRow,
  Col,
  LoadingIndicator,
  PageSubtitle,
  PageTitle,
  SaveButton,
  Step,
  StepFooter,
  StepHeader,
  SwitchChainWarning,
  Text,
} from '@tokensoft-web/common-ui';
import {
  getTruncatedAddress,
  getTxUrl,
  useAuth,
  useGetSale,
  useGetSaleContractOwner,
  useNetworks,
  usePrepareSaleParticipantsUpdate,
  useToast,
  useWagmi,
  useWallet,
} from '@tokensoft-web/common-utils';
import { createContext, useEffect, useState } from 'react';
import { VscLinkExternal } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import { Wizard } from 'react-use-wizard';
import SaleParticipants from '../../../components/admin/sales/sale-participants';
import { useEvent } from '../../../contexts/event/event-context';
import { useUpdateSaleOnChainConfig } from '../../../utils/sale';

const WizardContext = createContext(null);

export const EditSaleParticipantsWizard = () => {
  const {
    user: { walletAddress },
  } = useAuth();
  const navigate = useNavigate();
  const { event } = useEvent();
  const { sale, loading } = useGetSale(true, event?.id);
  const { showErrorToast, showSuccessToast } = useToast();
  const { connectedChainId } = useWallet();
  const { getNetworkDetails } = useNetworks();
  const [txPending, setTxPending] = useState(false);
  const wagmi = useWagmi();

  const { mutate: prepareSale } = usePrepareSaleParticipantsUpdate();
  const isOwner =
    sale?.owner?.id.toLowerCase() === walletAddress?.toLowerCase();

  const {
    error: updateSaleConfigError,
    write: sendUpdateSaleConfig,
    isLoading: updateSaleConfigLoading,
    data: updateSaleConfigReceipt,
  } = useUpdateSaleOnChainConfig();

  const [wizardState, setWizardState] = useState<any>();

  const saleOwner = useGetSaleContractOwner(sale);

  useEffect(() => {
    if (saleOwner) {
      sale.owner.id = saleOwner;

      setWizardState({
        participantsFormValid: true,
        eventId: sale.event?.id,
        saleId: sale.id,
        networkId: sale.chainId,
        priceBaseUnits: sale.price,
        maxRaiseBaseUnits: sale.saleMaximum,
        userLimitBaseUnits: sale.userMaximum,
        minPurchaseBaseUnits: sale.purchaseMinimum,
        recipient: sale.recipient.id,
        startTime: sale.startTime,
        endTime: sale.endTime,
        maxQueueTime: sale.maxQueueTime,
        includeEligibleEventUsersOnly: true,
      });
    }
  }, [saleOwner]);

  useEffect(() => {
    if (updateSaleConfigReceipt) {
      if (updateSaleConfigReceipt.status === 'success') {
        setTxPending(false);
        goBack();
        showSuccessToast({
          description: (
            <div className='flex flex-row'>
              Successfully submitted transaction.
              <a
                target='_blank'
                rel='noreferrer'
                href={getTxUrl(
                  updateSaleConfigReceipt.transactionHash,
                  getNetworkDetails(sale.chainId),
                )}
                className='w-[30px] flex items-center justify-center text-white'
                onClick={(e) => e.stopPropagation()}
              >
                <VscLinkExternal color='white' />
              </a>
            </div>
          ),
        });
      }
    }
  }, [updateSaleConfigReceipt]);

  useEffect(() => {
    if (updateSaleConfigError) {
      setTxPending(false);
      showErrorToast({ description: updateSaleConfigError.toString() });
    }
  }, [updateSaleConfigError]);

  const goBack = () => {
    navigate(-1);
  };

  const handleSave = async () => {
    setTxPending(true);

    prepareSale(
      {
        ...wizardState,
      },
      {
        onSuccess: (result) => {
          if (result.valid) {
            sendUpdateSaleConfig(sale.chainId, sale.id, [result.args]);
          } else {
            showErrorToast({
              description:
                result.errorMessage ||
                'Error updating sale.  Please try again.',
            });
          }
        },
        onError: (error) => {
          showErrorToast({
            description:
              error.toString() || 'Error updating sale.  Please try again.',
          });
        },
      },
    );
  };

  const handleCancel = () => {
    goBack();
  };

  if (!sale || !wizardState) {
    return <LoadingIndicator text={'Loading event'} />;
  }

  if (!loading && sale && sale.chainId !== connectedChainId) {
    const correctNetworkDetails = getNetworkDetails(sale.chainId);
    return (
      <SwitchChainWarning
        chainId={sale.chainId}
        subtitle={`Please connect to the ${correctNetworkDetails.name} network to continue.`}
      />
    );
  }

  return (
    <WizardContext.Provider
      value={{
        wizardState,
        setWizardState,
      }}
    >
      <Wizard>
        <Step>
          <StepHeader>
            <PageTitle>Participants</PageTitle>
            <PageSubtitle>
              Specify who can participate in the event. Everyone in the
              participant list must also meet all event eligibility requirements
              before they can purchase in this app.
            </PageSubtitle>
          </StepHeader>

          <SaleParticipants context={wizardState} setContext={setWizardState} />

          <StepFooter>
            {!isOwner ? (
              <Col place={'end'}>
                <Text>
                  You are not the owner ({getTruncatedAddress(sale.owner.id)})
                  of this contract. Only the owner can update the participants.
                </Text>
              </Col>
            ) : null}
            <ButtonRow place='between'>
              <BackButton
                label={'Cancel'}
                onClick={handleCancel}
                disabled={updateSaleConfigLoading}
              />
              <SaveButton
                disabled={
                  !isOwner ||
                  !wizardState?.participantsFormValid ||
                  updateSaleConfigLoading ||
                  txPending
                }
                saving={updateSaleConfigLoading}
                onClick={handleSave}
              />
            </ButtonRow>
          </StepFooter>
        </Step>
      </Wizard>
    </WizardContext.Provider>
  );
};
