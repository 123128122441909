import { NetworkIcon } from '@tokensoft-web/common-ui';
import {
  BLOCKCHAIN_KEY,
  POLKADOT_LOGO_URL,
  useGetUsersEvent,
} from '@tokensoft-web/common-utils';
import { AiFillCheckCircle } from 'react-icons/ai';
import { EventUsersTableHeader } from '../components/admin/event/event-users-table-header';

export enum USER_PAGE_TABLE_CONFIG_TYPES {
  WALLETS = 'WALLETS',
}

export const USER_PAGE_TABLE_RELATIONSHIP = [
  {
    type: USER_PAGE_TABLE_CONFIG_TYPES.WALLETS,
    text: 'Wallets',
    tableConfig: USER_PAGE_TABLE_CONFIG_TYPES.WALLETS,
  },
];

const getNetworkIcon = (info) => {
  const value = info.getValue();

  if (value.blockchainType === BLOCKCHAIN_KEY.POLKADOT) {
    return <img src={POLKADOT_LOGO_URL} width='24' />;
  }

  return (
    <NetworkIcon
      chainId={value.protocol}
      logoSize={'sm'}
      showNetworkName={false}
    />
  );
};

export const USER_PAGE_TABLE_CONFIG = {
  WALLETS: {
    getData: useGetUsersEvent,
    columnsData: (orderBy, handleOrderByChange, disabled) => [
      {
        accessorFn: (row) => row.walletAddress,
        id: 'walletAddress',
        cell: (info) => <div>{info.getValue()}</div>,
        header: () => (
          <EventUsersTableHeader
            content={'WALLET ADDRESS'}
            orderKey='wallet'
            orderList={orderBy}
            orderModify={handleOrderByChange}
            disabled={disabled}
          />
        ),
        headerText: 'WALLET ADDRESS',
        footer: (props) => props.column.id,
        size: 160,
      },
      {
        accessorFn: (row) => row,
        id: 'protocol',
        cell: (info) => getNetworkIcon(info),
        header: () => (
          <EventUsersTableHeader
            content={'PROTOCOL'}
            orderKey='wallet'
            orderList={orderBy}
            orderModify={handleOrderByChange}
            disabled={disabled}
          />
        ),
        headerText: 'PROTOCOL',
        footer: (props) => props.column.id,
        size: 160,
      },
      {
        accessorFn: (row) => row.primary,
        id: 'primary',
        cell: (info) => (
          <div>
            {info.getValue() ? (
              <AiFillCheckCircle color='var(--success)' size={24} />
            ) : null}
          </div>
        ),
        header: () => (
          <EventUsersTableHeader
            content={'PRIMARY'}
            orderKey='wallet'
            orderList={orderBy}
            orderModify={handleOrderByChange}
            disabled={disabled}
          />
        ),
        headerText: 'PRIMARY',
        footer: (props) => props.column.id,
        size: 160,
      },
    ],
    handleTableData: (data) => {
      return data?.wallets?.map((acc) => {
        return {
          walletAddress: acc.walletAddress,
          protocol: acc.networkId,
          primary: acc.primary,
          blockchainType: acc.blockchainType,
        };
      });
    },
    totalRecords: (data) => {
      return data?.wallets?.length;
    },
    orderBy: () => {
      return {};
    },
    filterBy: (filterDataSet) => {
      return {};
    },
  },
};
