import classNames from 'classnames';
import { Text } from './text';

export const EventSubtitle = ({ className = '', children, ...restProps }) => {
  return (
    <Text
      className={classNames('text-xl', className)}
      data-testid='event-banner-subtitle'
      {...restProps}
    >
      {children}
    </Text>
  );
};
