import { FC, ReactNode, useState } from 'react';
import ReactGA from 'react-ga4';
import { useConfiguration } from '../configuration/configuration-context';
import { AnalyticsContext } from './analytics-context';

interface AnalyticsProviderProps {
  children?: ReactNode;
}

export const AnalyticsProvider: FC<AnalyticsProviderProps> = ({ children }) => {
  const tokensoftTrackerId = process.env.REACT_APP_GA_TRACKING_ID || null;
  const [initialized, setInitialized] = useState(false);
  const { configuration } = useConfiguration();

  /**
   * Initialize GA.  Note: tracking is only enabled if the tokensoft tracker is defined
   * in a given environment.
   */
  const initialize = () => {
    if (initialized) {
      return;
    }

    if (tokensoftTrackerId) {
      console.log('Initializing Analytics...', tokensoftTrackerId);
      let trackingIds = [
        {
          trackingId: tokensoftTrackerId,
        },
      ];

      if (configuration?.project?.gaTrackingId) {
        console.log(
          'Initializing Analytics for project ...',
          configuration?.project?.gaTrackingId,
        );
        trackingIds.push({
          trackingId: configuration?.project?.gaTrackingId,
        });
      }

      ReactGA.initialize(trackingIds);
      setInitialized(true);
    }
  };

  /**
   * Log a new event with GA. This will log to the tokensoft trackerId and to the  project trackerId if present in Project configuration
   */
  const pageEvent = (category: string, action: string, value?: number) => {
    let actionString = action.split('/').join('.');
    actionString =
      category +
      (actionString.charAt(0) === '.' ? actionString : '.' + actionString);

    initialize();
    ReactGA.event({
      category: category,
      action: actionString,
      value: value,
    });
  };

  return (
    <AnalyticsContext.Provider
      value={{
        pageEvent,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
