import {
  convertBaseUnitsToDecimal,
  formatValue,
  getTruncatedAddress,
  getTxUrl,
  unixToLocalDateTime,
  useAccount,
  useNetworks,
} from '@tokensoft-web/common-utils';
import { FC } from 'react';
import { NULL_TRANSFER_ID } from '../../utils/constant';
import { getConnextScanUrl } from '../../utils/network';
import { ClaimRecord } from './claim-history';

interface ClaimsHistoryTableProps {
  records: ClaimRecord[];
  tokenDecimals: string;
  tokenSymbol: string;
  chainId: number;
  isCrossChain: boolean;
  claimableFixedNumber: number;
}

const ClaimsHistoryTable: FC<ClaimsHistoryTableProps> = ({
  records,
  chainId,
  tokenDecimals,
  tokenSymbol,
  isCrossChain,
  claimableFixedNumber,
}) => {
  const { account } = useAccount();
  const { getNetworkDetails } = useNetworks();
  return (
    <table className='w-full'>
      <thead>
        <tr>
          <th className='text-left border-b high-contrast'>Transaction ID</th>
          {isCrossChain ? (
            <th className='text-left border-b high-contrast'>Transfer ID</th>
          ) : null}
          <th className='text-left border-b high-contrast'>Description</th>
          <th className='text-left border-b high-contrast'>Date</th>
        </tr>
      </thead>
      <tbody>
        {records?.length > 0 ? (
          <>
            {records?.map((record: ClaimRecord, i: number) => {
              const createdAt = unixToLocalDateTime(
                record.createdAt,
                account?.timezone,
              );
              const claimed = formatValue(
                convertBaseUnitsToDecimal(record.amount, Number(tokenDecimals)),
                { commas: true, decimalPlaces: claimableFixedNumber },
              );
              return (
                <tr key={i}>
                  <td className={'text-left py-2'}>
                    <a
                      className={'default-contrast-link'}
                      target='_blank'
                      rel='noreferrer'
                      href={getTxUrl(
                        record.transactionHash,
                        getNetworkDetails(chainId),
                      )}
                    >
                      {getTruncatedAddress(record.transactionHash)}
                    </a>
                  </td>
                  {isCrossChain ? (
                    <td className={'text-left py-2'}>
                      {record.transferId !== NULL_TRANSFER_ID ? (
                        <a
                          className={'default-contrast-link'}
                          target='_blank'
                          rel='noreferrer'
                          href={getConnextScanUrl(record.transferId)}
                        >
                          {getTruncatedAddress(record.transferId)}
                        </a>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                  ) : null}
                  <td className={'text-left py-2'}>
                    {claimed} {tokenSymbol}
                  </td>
                  <td className={'text-left py-2'}>{createdAt}</td>
                </tr>
              );
            })}
          </>
        ) : (
          <div className='grid grid-cols-2 pl-6'>
            <span>No past claims</span>
          </div>
        )}
      </tbody>
    </table>
  );
};

export default ClaimsHistoryTable;
