import classNames from 'classnames';
import { Text } from './text';

export const Breadcrumb = ({ className = '', children, ...restProps }) => {
  return (
    <Text
      textAlign={'center'}
      className={classNames(
        'breadcrumb text-xs font-normal text-neutral-medium',
        className,
      )}
      {...restProps}
    >
      {children}
    </Text>
  );
};
