import {
  getRemainingFromUnix,
  localToUtcUnixDateTime,
  useAccount,
} from '@tokensoft-web/common-utils';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { EventStatusBadge } from '../badge/event-status-badge';
import { Countdown } from './countdown';

interface EventCountdownProps {
  id?: string;
  className?: any;
  startTime?: any;
  size?: 'xsmall' | 'small' | 'large';
  justify?: 'start' | 'center' | 'end';
  fontColor?: string;
  status?: string;
  showStatus?: boolean;
}

export const EventCountdown: FC<EventCountdownProps> = ({
  id,
  className = '',
  justify,
  size = 'small',
  startTime,
  fontColor = 'text-neutral-medium',
  status = null,
  showStatus,
}: EventCountdownProps) => {
  const { account } = useAccount();
  const initialStartTimeInUnix = startTime
    ? localToUtcUnixDateTime(startTime, account?.timezone, true)
    : null;
  const initialRemaining = getRemainingFromUnix(initialStartTimeInUnix);
  const [remainingTime, setRemainingTime] = useState(initialRemaining);

  useEffect(() => {
    const interval = setInterval(() => {
      const _remaining = getRemainingFromUnix(initialStartTimeInUnix);
      if (_remaining > 0) {
        setRemainingTime(_remaining);
      } else {
        setRemainingTime(0);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  });

  if (!startTime) {
    return <></>;
  }

  if (remainingTime <= 0 && showStatus) {
    return (
      <div className={className}>
        <EventStatusBadge startTime={startTime} status={status} />
      </div>
    );
  }

  return (
    <div className={className}>
      <Countdown
        id={id}
        className={classNames(justify ? `justify-${justify}` : '')}
        size={size}
        fontColor={fontColor}
        remainingTime={remainingTime}
      />
    </div>
  );
};
