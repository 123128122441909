import {
  add,
  addToUtcUnixDateTime,
  DATE_TIME_ISO_FORMAT,
  div,
  eq,
  formatValue,
  getDifference,
  lt,
  mult,
  sub,
  toNumber,
  utcToUtcUnixDateTime,
  utcUnixToUtcDate,
} from '@tokensoft-web/common-utils';
import { VESTING_TYPE } from '../../../utils/enums';

export const DEFAULT_CHART_FONT_COLOR = '#6B7280';
export const DEFAULT_CHART_FONT_FAMILY = 'Inter';
export const DEFAULT_CHART_FONT_SIZE = 12;
export const DEFAULT_CHART_PRIMARY_LINE_COLOR = '#8BB5FD';
export const DEFAULT_CHART_PRIMARY_FILL_COLOR = 'rgba(139, 181, 253, 0.15)';
export const DEFAULT_CHART_SECONDARY_LINE_COLOR = '#0350D7';
export const DEFAULT_CHART_SECONDARY_FILL_COLOR = 'rgba(3, 80, 215, 0.15)';
export const DEFAULT_CHART_GRID_COLOR = '#E5E7EB';

/**
 * Generate fake claim data based on event and vesting dates
 */
export const generateClaimData = (
  cliffTime,
  endTime,
  eventEndTime,
  totalAllocations,
) => {
  const vestingDays = getDifference(
    utcUnixToUtcDate(cliffTime),
    utcUnixToUtcDate(eventEndTime),
  );
  const maxClaimablePerDay = div(div(totalAllocations, 2), vestingDays);

  // console.log(`Generating claim data`, {cliffTime: utcUnixToUtcDate(cliffTime), endTime: utcUnixToUtcDate(endTime), eventEndTime: utcUnixToUtcDate(eventEndTime), vestingDays, maxClaimablePerDay})

  let claimData = [];
  let currentDay = cliffTime;
  let currentTotal = 0;
  for (let i = 0; i <= vestingDays; i++) {
    const claimAmount = Math.floor(
      Math.random() * (Number(maxClaimablePerDay) - 1) + 1,
    );
    currentTotal = Number(add(currentTotal, claimAmount));
    claimData.push({
      time: currentDay.toString(),
      date: utcUnixToUtcDate(currentDay, DATE_TIME_ISO_FORMAT),
      amount: currentTotal,
    });

    currentDay = addToUtcUnixDateTime(currentDay, 1, 'day');
  }

  // console.log('Generated claim data', claimData)

  return claimData;
};

/**
 * Generate tranches based on event and vesting dates
 */
export const generateTranches = (
  eventStartTime,
  startTime,
  cliffTime,
  endTime,
  eventEndTime,
  units = 'month',
  vestingType,
) => {
  let tranches;

  if (vestingType === VESTING_TYPE.TRANCHE) {
    tranches = generatePeriodicTranches(startTime, cliffTime, endTime, units);
    tranches = addEventTranches(
      tranches,
      eventStartTime,
      startTime,
      cliffTime,
      endTime,
      eventEndTime,
    );
  } else if (vestingType === VESTING_TYPE.CONTINUOUS) {
    tranches = generateContinuousTranches(
      startTime,
      cliffTime,
      endTime,
      eventEndTime,
    );
  }

  // console.log('Generated tranche data', tranches)

  return tranches;
};

/**
 * Generate tranches for a tranche based distributor
 */
export const generatePeriodicTranches = (
  startTime,
  cliffTime,
  endTime,
  units = 'month',
) => {
  const totalTranches = getDifference(
    utcUnixToUtcDate(startTime),
    utcUnixToUtcDate(endTime),
    units,
  );

  let tranches = [];

  for (let i = 0; i < totalTranches; i++) {
    const trancheStartTime = addToUtcUnixDateTime(startTime, i, units);

    if (lt(trancheStartTime, cliffTime)) {
      continue;
    }

    const vestedFraction = Number((i + 1) * (100 / totalTranches) * 100);

    tranches.push({
      time: trancheStartTime.toString(),
      vestedFraction: Math.floor(vestedFraction),
      date: utcUnixToUtcDate(trancheStartTime, DATE_TIME_ISO_FORMAT),
    });
  }

  // console.log('Created tranches for tranche vesting', tranches)
  return tranches;
};

/**
 * Generate tranches for a continuous vesting distributor
 */
export const generateContinuousTranches = (
  startTime,
  cliffTime,
  endTime,
  eventEndTime,
) => {
  const totalDays =
    getDifference(utcUnixToUtcDate(startTime), utcUnixToUtcDate(endTime)) + 1;
  const vestingDays =
    getDifference(utcUnixToUtcDate(cliffTime), utcUnixToUtcDate(endTime)) + 1;

  const cliffVestedFraction = eq(cliffTime, endTime)
    ? 10000
    : Math.floor(((totalDays - vestingDays) / totalDays) * 10000);

  const tranches = [];
  // cliff time
  tranches.push({
    time: cliffTime.toString(),
    date: utcUnixToUtcDate(cliffTime),
    vestedFraction: cliffVestedFraction,
  });

  // vesting end time
  tranches.push({
    time: endTime.toString(),
    date: utcUnixToUtcDate(endTime),
    vestedFraction: 10000,
  });

  // event end time
  if (eventEndTime && eventEndTime > 0) {
    tranches.push({
      time: eventEndTime.toString(),
      date: utcUnixToUtcDate(eventEndTime),
      vestedFraction: 10000,
    });
  }

  return tranches;
};

/**
 * Add event start and end time to an existing set of tranches
 * to correctly show the vesting period boundaries on the vesting chart.
 *
 * 0% of tokens are vested between the start time and the cliff time
 * 100% of tokens are vested between the end time and the event end time
 */
export const addEventTranches = (
  tranches,
  eventStartTime,
  startTime,
  cliffTime,
  endTime,
  eventEndTime,
) => {
  // if the vesting start time is not found, add it.
  if (tranches.findIndex((tranche) => eq(tranche.time, startTime)) < 0) {
    tranches.unshift({
      time: startTime.toString(),
      date: utcUnixToUtcDate(startTime, DATE_TIME_ISO_FORMAT),
      vestedFraction: 0,
    });
  }

  // if the event start time is not found, add it.
  // in practice, the event start time should be the same as the vesting start time
  // but it is possible for these to be different.
  if (
    eventStartTime &&
    tranches.findIndex((tranche) => eq(tranche.time, eventStartTime)) < 0
  ) {
    tranches.unshift({
      time: eventStartTime.toString(),
      date: utcUnixToUtcDate(eventStartTime, DATE_TIME_ISO_FORMAT),
      vestedFraction: 0,
    });
  }

  // if the vesting end time is not found, add it.
  if (
    endTime &&
    tranches.findIndex((tranche) => eq(tranche.time, endTime)) < 0
  ) {
    tranches.push({
      time: endTime.toString(),
      date: utcUnixToUtcDate(endTime, DATE_TIME_ISO_FORMAT),
      vestedFraction: 10000,
    });
  }

  // if the event end time is not found, add it.
  if (
    eventEndTime &&
    tranches.findIndex((tranche) => eq(tranche.time, eventEndTime)) < 0
  ) {
    tranches.push({
      time: eventEndTime.toString(),
      date: utcUnixToUtcDate(eventEndTime, DATE_TIME_ISO_FORMAT),
      vestedFraction: 0,
    });
  }

  return tranches;
};

/**
 * Build vertical bars to display important dates on the vesting chart
 */
export const buildStripLines = (distributorData, isMediumScreen) => {
  if (isMediumScreen) {
    return [];
  }

  const stripLines = [];
  stripLines.push({
    value: new Date(distributorData.startTime * 1000),
    thickness: 1,
    color: DEFAULT_CHART_GRID_COLOR,
    label: `Event Start ${utcUnixToUtcDate(distributorData.startTime)}`,
    labelPlacement: 'outside',
    labelFontColor: DEFAULT_CHART_FONT_COLOR,
    labelBackgroundColor: '#ffffff',
    labelMaxWidth: 72,
    labelTextAlign: 'center',
    showOnTop: true,
  });

  if (distributorData.event.endTime && distributorData.event.endTime > 0) {
    stripLines.push({
      value: new Date(distributorData.event.endTime * 1000),
      thickness: 1,
      color: DEFAULT_CHART_GRID_COLOR,
      label: `${utcUnixToUtcDate(distributorData.event.endTime)}`,
      labelPlacement: 'outside',
      labelFontColor: DEFAULT_CHART_FONT_COLOR,
      labelBackgroundColor: '#ffffff',
      labelMaxWidth: 72,
      labelAlign: 'center',
      showOnTop: true,
    });
  }

  return stripLines;
};

/**
 * Build axes for vesting chart
 */
export const buildAxes = (distributorData, min, max, isMediumScreen) => {
  return {
    axisX: {
      minimum: min ? min : null,
      maximum: max ? max : null,
      labelFormatter: function (e) {
        return '';
      },
      crosshair: {
        enabled: !!distributorData.id,
      },
      stripLines: buildStripLines(distributorData, isMediumScreen),
      tickColor: 'transparent',
      lineColor: DEFAULT_CHART_FONT_COLOR,
      labelFontColor: DEFAULT_CHART_FONT_COLOR,
      labelFontSize: DEFAULT_CHART_FONT_SIZE,
      labelFontWeight: 'lighter',
      labelFontFamily: DEFAULT_CHART_FONT_FAMILY,
    },
    axisY: {
      suffix: '%',
      minimum: 0,
      maximum: 100,
      lineColor: DEFAULT_CHART_FONT_COLOR,
      labelFontColor: DEFAULT_CHART_FONT_COLOR,
      labelFontSize: DEFAULT_CHART_FONT_SIZE,
      labelFontWeight: 'lighter',
      labelFontFamily: DEFAULT_CHART_FONT_FAMILY,
    },
    axisY2: {
      suffix: ` ${distributorData.tokenSymbol}`,
      minimum: 0,
      maximum: toNumber(distributorData.totalAllocations),
      lineColor: DEFAULT_CHART_FONT_COLOR,
      labelFontColor: DEFAULT_CHART_FONT_COLOR,
      labelFontSize: DEFAULT_CHART_FONT_SIZE,
      labelFontWeight: 'lighter',
      labelFontFamily: DEFAULT_CHART_FONT_FAMILY,
    },
  };
};

/**
 * Build dataset array used to render a multi-axis line chart
 */
export const buildDatasets = (vestingType, vestingDataset, claimsDataset) => {
  return [
    {
      type: vestingType === VESTING_TYPE.TRANCHE ? 'stepArea' : 'area',
      name: 'Vesting Schedule',
      dataPoints: vestingDataset,
      color: DEFAULT_CHART_PRIMARY_FILL_COLOR,
      lineColor: DEFAULT_CHART_PRIMARY_LINE_COLOR,
      lineThickness: 1,
      markerType: 'none',
      legendMarkerType: 'circle',
      legendMarkerColor: DEFAULT_CHART_PRIMARY_FILL_COLOR,
      legendMarkerBorderColor: DEFAULT_CHART_PRIMARY_LINE_COLOR,
      legendMarkerBorderThickness: 1,
    },
    {
      type: 'splineArea',
      name: 'Claimed Tokens',
      dataPoints: claimsDataset,
      color: DEFAULT_CHART_SECONDARY_FILL_COLOR,
      lineColor: DEFAULT_CHART_SECONDARY_LINE_COLOR,
      lineThickness: 1,
      markerType: 'none',
      legendMarkerType: 'circle',
      legendMarkerColor: DEFAULT_CHART_SECONDARY_FILL_COLOR,
      legendMarkerBorderColor: DEFAULT_CHART_SECONDARY_LINE_COLOR,
      legendMarkerBorderThickness: 1,
      axisYType: 'secondary',
    },
  ];
};

/**
 * Calculate the padding to apply to the x-axis of the vesting chart
 * Claims data should display to the end of the chart
 */
export const buildChartPadding = (
  vestingDataset = [],
  claimsDataset = [],
  distributorData,
) => {
  const mergedDataset: any[] = vestingDataset.concat(claimsDataset);

  if (mergedDataset.length <= 0) {
    return {
      vestingDataset: vestingDataset,
      claimsDataset: claimsDataset,
    };
  }

  let sortedDataset = Array.from(mergedDataset).sort((a, b) => {
    return Number(sub(utcToUtcUnixDateTime(a.x), utcToUtcUnixDateTime(b.x)));
  });

  const max = utcToUtcUnixDateTime(sortedDataset[sortedDataset.length - 1].x);
  const min = utcToUtcUnixDateTime(sortedDataset[0].x);
  const distance = sub(max, min);
  const pad = mult(distance, 0.1);

  const paddedMaxDate = new Date(Number(add(max, pad)) * 1000);
  const paddedMinDate = new Date(Number(sub(min, pad)) * 1000);

  // add one last data point on the claims dataset so it looks like
  // the data runs to the end of the chart
  if (claimsDataset.length > 0) {
    const lastClaimDatapoint = claimsDataset[claimsDataset.length - 1];

    claimsDataset.push({
      x: paddedMaxDate,
      y: lastClaimDatapoint.y,
      toolTipContent: buildClaimTooltipContent(
        lastClaimDatapoint.y,
        Number(add(max, pad)),
        distributorData,
      ),
    });
  }

  // if there is no distributor id we are in 'demo' mode.  for instant
  // vesting we want to make it look like the vesting period goes all the
  // way to the end of the chart
  if (vestingDataset.length > 0) {
    if (
      !distributorData.id &&
      eq(distributorData.cliffTime, distributorData.endTime)
    ) {
      const lastVestingDatapoint = vestingDataset[vestingDataset.length - 1];

      vestingDataset.push({
        x: paddedMaxDate,
        y: lastVestingDatapoint.y,
        toolTipContent: buildVestingTooltipContent(
          lastVestingDatapoint.y,
          Number(add(max, pad)),
          distributorData,
        ),
      });
    }
  }

  return {
    vestingDataset: vestingDataset,
    claimsDataset: claimsDataset,
    maximum: paddedMaxDate,
    minimum: paddedMinDate,
  };
};

/**
 * Build tooltip content for a vesting schedule datapoint
 */
export const buildVestingTooltipContent = (
  vestingValue,
  time,
  distributorData,
) => {
  const percentage = Math.floor(vestingValue);
  const formattedAmount = formatValue(
    mult(distributorData.totalAllocations, div(percentage, 100), 0),
    { commas: true },
  );
  return `
        <div class="chart-tooltip">
            <div class="chart-tooltip-text"><strong>${percentage}%</strong> | ${formattedAmount} ${
              distributorData.tokenSymbol
            } - Vesting</div>
            <div class="chart-tooltip-text">${utcUnixToUtcDate(time)}</div>
        </div>
      `;
};

/**
 * Build tooltip content for a claim datapoint
 */
export const buildClaimTooltipContent = (amount, time, distributorData) => {
  const formattedAmount = formatValue(amount, { commas: true });
  const percentage = Math.floor(
    toNumber(mult(div(amount, distributorData.totalAllocations), 100, 0)),
  );
  return `
      <div class="chart-tooltip">
        <div class="chart-tooltip-text"><strong>${percentage}%</strong> | ${formattedAmount} ${
          distributorData.tokenSymbol
        } - Claimed</div>
        <div class="chart-tooltip-text">${utcUnixToUtcDate(time)}</div>
      </div>  
  `;
};
