import { Alert } from '@tokensoft-web/common-ui';
import {
  FEATURE,
  getTxUrl,
  useAccount,
  useAuth,
  useModal,
  useNetworks,
  useToast,
  useWallet,
} from '@tokensoft-web/common-utils';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { FaRegTimesCircle } from 'react-icons/fa';
import { VscLinkExternal } from 'react-icons/vsc';
import { getConnextContracts, getDistributorInterfaces } from '../../utils/abi';
import { chainIdToConnextDomain } from '../../utils/domain';
import { isSmartContractWallet } from '../../utils/wallet';
import ClaimButton from './claim-button';
import ClaimModalStep from './claim-modal-step';
import './claim-modal.css';
import SubmitClaimSuccess from './submit-claim-success';

interface ClaimNetworkSelectionModalProps {
  event: any;
  distributor: any;
  formattedClaimableAmount: number;
  onMinedTransaction: Function;
  delegateSaved?: any;
  setDelegateSaved?: Function;
  onDelegateFinished?: Function;
  correctNetworkChainId: number;
}

enum CLAIM_STEP {
  NETWORK_SELECTION = 'NETWORK_SELECTION',
  SUCCESS = 'SUCCESS',
}

const ClaimNetworkSelectionModal = ({
  event,
  distributor,
  formattedClaimableAmount,
  onMinedTransaction,
  delegateSaved,
  setDelegateSaved,
  onDelegateFinished,
  correctNetworkChainId,
}: ClaimNetworkSelectionModalProps) => {
  const { closeModal } = useModal();
  const [pending, setPending] = useState(false);
  const {
    user: { walletAddress },
  } = useAuth();
  const { account } = useAccount();
  const { showErrorToast } = useToast();
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [isCrossChainTransaction, setIsCrossChainTransaction] = useState(false);
  const [pendingTxHash, setPendingTxHash] = useState(null);
  const [claimStep, setClaimStep] = useState<CLAIM_STEP>(
    CLAIM_STEP.NETWORK_SELECTION,
  );
  const { connectedChainId } = useWallet();
  const { getNetworkDetails } = useNetworks();
  const [delegateFromDistributor, setDelegateFromDistributor] =
    useState(delegateSaved);
  const isConnextFlow = !!event?.features?.includes(
    FEATURE.CLAIM_CONNEXT_LAYOUT,
  );
  const isSmartContract = isSmartContractWallet(
    account?.wallets,
    walletAddress,
  );

  const userOnCorrectNetwork =
    isConnextFlow && !isSmartContract
      ? true
      : connectedChainId === correctNetworkChainId;

  const interfaces = getDistributorInterfaces(distributor);

  useEffect(() => {
    setClaimStep(CLAIM_STEP.NETWORK_SELECTION);
  }, []);

  useEffect(() => {
    if (distributor) {
      const networkDetails = getNetworkDetails(distributor.chainId);
      setSelectedNetwork(networkDetails);
      setDelegateSaved({
        ...delegateSaved,
        delegatedToNetwork: networkDetails.chainId,
      });
      setDelegateFromDistributor({
        ...delegateSaved,
        delegatedToNetwork: networkDetails.chainId,
      });
      setIsCrossChainTransaction(
        networkDetails.chainId !== distributor.chainId,
      );
    }
  }, [distributor]);

  const handleNetworkSelect = (networkDetails) => {
    setDelegateSaved({
      ...delegateSaved,
      delegatedToNetwork: networkDetails.chainId,
    });
    setDelegateFromDistributor({
      ...delegateSaved,
      delegatedToNetwork: networkDetails.chainId,
    });
    setSelectedNetwork(networkDetails);
    setIsCrossChainTransaction(networkDetails.chainId !== distributor.chainId);
  };

  const handleSubmitTransaction = (txHash: string) => {
    setPendingTxHash(txHash);
    setClaimStep(CLAIM_STEP.SUCCESS);
  };

  const renderNetworkSelection = () => {
    const tempConnextContracts = getConnextContracts(distributor);

    const connextContractsOrdered = [
      ...tempConnextContracts.filter(
        (connextContract) => connextContract.network_id === distributor.chainId,
      ),
      ...tempConnextContracts.filter(
        (connextContract) => connextContract.network_id !== distributor.chainId,
      ),
    ];

    return connextContractsOrdered.map((connextContract, i) => {
      const chainId = connextContract?.network_id;
      const networkDetails = getNetworkDetails(chainId);

      return (
        <div
          key={i}
          className={classNames(
            'flex flex-row justify-between network-selection',
            selectedNetwork?.chainId === chainId ? 'selected-network' : '',
          )}
          onClick={() => handleNetworkSelect(networkDetails)}
        >
          {networkDetails.logoUri ? (
            <img src={networkDetails.logoUri} width='30' alt='' />
          ) : null}
          <span>{networkDetails.name}</span>
        </div>
      );
    });
  };

  const renderClaimStep = () => {
    if (claimStep === CLAIM_STEP.SUCCESS) {
      return (
        <SubmitClaimSuccess
          interfaces={interfaces}
          transactionHash={pendingTxHash}
          delegateSaved={delegateFromDistributor}
          event={event}
          distributor={distributor}
          claimableAmount={formattedClaimableAmount}
          network={selectedNetwork}
          onDelegateFinished={onDelegateFinished}
          onMinedTransaction={onMinedTransaction}
          isCrossChainTransaction={isCrossChainTransaction}
          onClaimError={(errorMessage) => {
            setClaimStep(CLAIM_STEP.NETWORK_SELECTION);
            console.error(errorMessage);
            showErrorToast({
              description:
                'The transaction could not be completed, please try again later.',
            });
          }}
        />
      );
    }

    if (claimStep === CLAIM_STEP.NETWORK_SELECTION) {
      const beneficiaryDomain = distributor.domain;

      return (
        <>
          <div className='flex flex-col mt-4'>
            <div className='flex flex-row justify-between'>
              <span className='w-[20px]'></span>
              <span className='text-2xl font-bold text-center'>
                Claim Tokens
              </span>
              <div
                onClick={() => !pending && closeModal()}
                className='w-[20px]'
              >
                {!pending && (
                  <FaRegTimesCircle className='text-neutral-medium cursor-pointer' />
                )}
              </div>
            </div>
            <ClaimModalStep onStep={1} />
            <p className='text-center mt-6'>
              {`Please select a network where you would like to receive `}
              <span className='font-bold'>
                {formattedClaimableAmount} {distributor.tokenSymbol}
              </span>{' '}
              tokens.
            </p>
          </div>
          <div className='mt-4'>{renderNetworkSelection()}</div>
          {pendingTxHash && (
            <Alert type='alert-success'>
              <div className='flex flex-row items-center text-white'>
                Your claim is being submitted. View your transaction here
                <a
                  target='_blank'
                  rel='noreferrer'
                  href={getTxUrl(
                    pendingTxHash,
                    getNetworkDetails(distributor.chainId),
                  )}
                  className='self-center ml-2'
                >
                  <VscLinkExternal color='white' />
                </a>
              </div>
            </Alert>
          )}
          <div className='my-6 w-full'>
            <ClaimButton
              className={'btn btn-primary btn-lg w-full py-3'}
              claimData={{
                chainId: distributor.chainId,
                symbol: distributor.tokenSymbol,
                distributorAddress: distributor.id,
                proof: distributor?.proof,
                proofIndex: distributor?.proofIndex,
                proofAmount: distributor?.proofAmount,
                saleId: '',
                interfaces: interfaces,
                recipientDomain: chainIdToConnextDomain(
                  selectedNetwork?.chainId,
                ),
                beneficiary: walletAddress,
                beneficiaryDomain: beneficiaryDomain,
              }}
              onPendingState={(isPending) => {
                setPending(isPending);
              }}
              features={event?.features}
              text={'Claim'}
              userOnCorrectNetwork={userOnCorrectNetwork}
              disabled={!selectedNetwork?.id}
              onSubmitTransaction={handleSubmitTransaction}
            />
          </div>
        </>
      );
    }

    return null;
  };

  return renderClaimStep();
};

export default ClaimNetworkSelectionModal;
