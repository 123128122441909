import classNames from 'classnames';
import { ReactNode } from 'react';
import { PageHeader } from '../layout/page-header/page-header';

export const StepHeader = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <PageHeader className={classNames(className, '!mb-2')}>
      {children}
    </PageHeader>
  );
};
