import { LoadingIndicator, PageHeader } from '@tokensoft-web/common-ui';
import { useAnalytics } from '@tokensoft-web/common-utils';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { KYCNotificationBanner } from '../../components/account/kyc-notification-banner';
import { ProfileAccreditation } from '../../components/profile/profile-accreditation';
import { ProfileDetails } from '../../components/profile/profile-details';
import { ProfileIdentity } from '../../components/profile/profile-identity';
import { ProfileSetup } from '../../components/profile/profile-setup';
import { ProfileVerifyEmail } from '../../components/profile/profile-verify-email';
import { ProfileWallets } from '../../components/profile/profile-wallets';
import { useProfile } from '../../contexts/profile/profile-context';

const ProfileDashboard = () => {
  const { account, view, setView } = useProfile();
  const { hash } = useLocation();
  useAnalytics('/account/profile');

  useEffect(() => {
    if (hash) {
      setView(hash.replace('#', ''));
    }
  }, [hash]);

  const renderView = () => {
    switch (view) {
      case 'profile-wallets':
        return <ProfileWallets />;
      case 'profile-accreditation':
        return <ProfileAccreditation />;
      case 'profile-identity':
        return <ProfileIdentity />;
      case 'profile-verify-email':
        return <ProfileVerifyEmail />;
      default:
        return <ProfileDetails />;
    }
  };

  if (!account) {
    return <LoadingIndicator text={'Loading profile'} />;
  }

  return (
    <>
      <PageHeader title={'My Profile'} />

      <div className='pb-5'>
        <KYCNotificationBanner />
      </div>
      <div className={'grid grid-cols-3 gap-x-8 gap-y-8'}>
        <div className={'col-span-3 lg:col-span-2'}>{renderView()}</div>

        <div className={'col-span-3 lg:col-span-1'}>
          <ProfileSetup />
        </div>
      </div>
    </>
  );
};

export default ProfileDashboard;
