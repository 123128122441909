import { NetworkDetails } from '../type/network-details';

export const hexToDecimal = (hex: string): number => {
  return parseInt(hex, 16);
};

export const decimalToHex = (num: any) => {
  const val = Number(num);
  return '0x' + val.toString(16);
};

export const getTxUrl = (transactionHash: string, network: NetworkDetails) => {
  return `${network.blockExplorerUri}tx/${transactionHash}`;
};
