import {
  BackButton,
  Button,
  ButtonRow,
  ContinueButton,
  FrontArrowIcon,
  GoToStepButton,
  PageSubtitle,
  PageTitle,
  Step,
  StepFooter,
  StepHeader,
  Text,
  WizardBreadcrumb,
} from '@tokensoft-web/common-ui';
import {
  EVENT_TYPE,
  EventWizardState,
  complianceTiertoKycRestriction,
  localToUtcDateTime,
  useAccount,
  useConfiguration,
  useCreateEvent,
  useToast,
} from '@tokensoft-web/common-utils';
import { createContext, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { HiRocketLaunch } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';
import { Wizard } from 'react-use-wizard';
import { CreateEventPayload } from '../../../@types/event';
import DocumentUploadSaveButton from '../../../components/admin/event/document/document-upload-save-button';
import DocumentUploadSection from '../../../components/admin/event/document/document-upload-section';
import DocumentsList from '../../../components/admin/event/document/documents-list';
import EventCreateConfirmation from '../../../components/admin/event/event-create-confirmation';
import EventEligibilitySetup from '../../../components/admin/event/event-eligibility-setup';
import { EventPageSetupContent } from '../../../components/admin/event/event-page-setup-content';
import { EventPageSetupGeneral } from '../../../components/admin/event/event-page-setup-general';
import { PreviewPage } from '../../../components/admin/event/preview-page';
import { PreviewPageButton } from '../../../components/admin/event/preview-page-button';
import { DocumentUploadProvider } from '../../../contexts/event/document-upload-provider';
import { useProject } from '../../../contexts/project/project-context';
import { formatCreateEventDocumentsPayload } from '../../../utils/document';
import { DOCUMENT_CATEGORY } from '../../../utils/enums';
import './event-create-document.css';

const WizardContext = createContext(null);

interface LaunchEventWizardProps {
  eventType: EVENT_TYPE;
  documentCategory: DOCUMENT_CATEGORY;
  adjustEventCreationFields?: (
    wizardState: CreateEventPayload,
  ) => CreateEventPayload;
}

export const LaunchEventWizard = ({
  eventType,
  documentCategory,
  adjustEventCreationFields,
}: LaunchEventWizardProps) => {
  const { account } = useAccount();
  const { showErrorToast } = useToast();
  const navigate = useNavigate();
  const { project } = useProject();
  const { configuration } = useConfiguration();
  const [wizardState, setWizardState] = useState<EventWizardState | null>({
    eventConfig: configuration?.eventTypes[eventType] || null,
    eventType: eventType,
    isEditingDocuments: null,
  });
  const { mutate: createEvent, isPending: createEventLoading } =
    useCreateEvent();

  const handleLaunchEvent = () => {
    const allowedRegions = wizardState.allowedCountryList.map(
      (country) => country.value,
    );

    const payload: CreateEventPayload = {
      allowedRegions,
      bodyHeader: wizardState.bodyTitle,
      contentBodyClosed: wizardState.contentBodyClosed,
      contentBodyOpen: wizardState.contentBodyOpen,
      contentBodyUpcoming: wizardState.contentBodyUpcoming,
      description: wizardState.description,
      eventType: eventType,
      contentHeaderBgColor: wizardState.contentHeaderBgColor,
      contentHeaderBgImage: wizardState.contentHeaderBgImage,
      contentHeaderFontColor: wizardState.contentHeaderFontColor,
      contentHeaderImage: wizardState.contentHeaderImage,
      contentHeaderSubtitle: wizardState.contentHeaderSubtitle,
      contentHeaderTitle: wizardState.contentHeaderTitle,
      name: wizardState.name,
      privacyConfiguration: wizardState.privacyConfiguration,
      projectId: project.id,
      themeLayoutVersion: wizardState.themeLayoutVersion,
      walletRequirements: wizardState?.walletRequirements,

      documents: formatCreateEventDocumentsPayload(
        documentCategory,
        wizardState.documents,
        allowedRegions,
      ),
      kycRestriction: complianceTiertoKycRestriction(
        wizardState?.complianceTier,
      ),
      registrationEndTime: wizardState.endTime
        ? localToUtcDateTime(wizardState.endTime, account?.timezone)
        : null,
      registrationStartTime: localToUtcDateTime(
        wizardState.startTime,
        account?.timezone,
      ),
    };

    // Allow each event type to populate its own fields if desired
    if (adjustEventCreationFields) {
      adjustEventCreationFields(payload);
    }

    createEvent(payload, {
      onSuccess: (data) => {
        navigate(`/admin/project/${project?.id}/event/${data.id}`);
      },
      onError: (error) => {
        showErrorToast({ description: error.message });
      },
    });
  };

  const renderPreviewContent = (onContinue) => {
    return <PreviewPage context={wizardState} onContinue={onContinue} />;
  };

  return (
    <WizardContext.Provider
      value={{
        wizardState,
        setWizardState,
      }}
    >
      <Wizard>
        <Step>
          <StepHeader>
            <WizardBreadcrumb>STEP 1</WizardBreadcrumb>
            <PageTitle>General Information</PageTitle>
            <PageSubtitle>
              Let&apos;s start with some general info about your event. Please
              fill out your event title, subtitle and pick a start date for your
              open registration period. This information will be part of your
              end-user facing event.
            </PageSubtitle>
          </StepHeader>

          <EventPageSetupGeneral
            wizardState={wizardState}
            updateWizardState={setWizardState}
          />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton
                onClick={() =>
                  navigate(`/admin/project/${project?.id}/event/launch`)
                }
              />
              <ContinueButton
                disabled={!wizardState?.eventPageGeneralFormValid}
              />
            </ButtonRow>
          </StepFooter>
        </Step>
        <Step>
          <StepHeader>
            <WizardBreadcrumb>STEP 2</WizardBreadcrumb>
            <PageTitle>Eligibility</PageTitle>
            <PageSubtitle>
              Decide who can participate in your event. Gather the requirements
              from your compliance manual and set them here.
            </PageSubtitle>
          </StepHeader>

          <EventEligibilitySetup
            wizardState={wizardState}
            setWizardState={setWizardState}
          />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton />
              <ContinueButton disabled={!wizardState?.eligibilityFormValid} />
            </ButtonRow>
          </StepFooter>
        </Step>

        <Step>
          <StepHeader>
            <WizardBreadcrumb>STEP 3</WizardBreadcrumb>
            <PageTitle>Document Setup</PageTitle>
            <PageSubtitle>
              Please upload the documents you would like your event participants
              to review and accept.
            </PageSubtitle>
          </StepHeader>

          <DocumentsList
            wizardState={wizardState}
            setWizardState={setWizardState}
          />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton />
              <GoToStepButton
                stepToGo={4}
                icon={<FrontArrowIcon />}
                disabled={!wizardState?.documentsFormValid}
              />
            </ButtonRow>
          </StepFooter>
        </Step>

        <Step>
          <StepHeader>
            <WizardBreadcrumb>STEP 3</WizardBreadcrumb>
            <PageTitle>Document Setup</PageTitle>
            <PageSubtitle>
              Please upload the documents you would like your event participants
              to review and accept.
            </PageSubtitle>
          </StepHeader>

          <DocumentUploadProvider
            wizardState={wizardState}
            setWizardState={setWizardState}
          >
            <DocumentUploadSection />

            <StepFooter>
              <ButtonRow place='between'>
                <BackButton label='Cancel' />
                <DocumentUploadSaveButton />
              </ButtonRow>
            </StepFooter>
          </DocumentUploadProvider>
        </Step>

        <Step>
          <StepHeader>
            <WizardBreadcrumb>STEP 4</WizardBreadcrumb>
            <PageTitle>Event Page Setup</PageTitle>
            <PageSubtitle>
              The Event Page is displayed to your users as a preview page for an
              upcoming event. This page is designed to introduce them to the
              process and to provide general context about your project.
            </PageSubtitle>
          </StepHeader>

          <EventPageSetupContent
            wizardState={wizardState}
            updateWizardState={setWizardState}
          />

          <StepFooter>
            <ButtonRow place='between'>
              <GoToStepButton fill={'outline'} stepToGo={2} label='Back' />
              <PreviewPageButton
                previewContent={renderPreviewContent}
                disabled={!wizardState?.eventPageSetupFormValid}
                label={'Preview and Continue'}
                fill={''}
              />
            </ButtonRow>
          </StepFooter>
        </Step>

        <Step>
          <StepHeader>
            <WizardBreadcrumb>STEP 5</WizardBreadcrumb>
            <PageTitle>Event Details Confirmation</PageTitle>
            <PageSubtitle>
              Before proceeding please make sure that your details that have
              been agreed to are correct.
            </PageSubtitle>
          </StepHeader>

          <EventCreateConfirmation wizardState={wizardState} />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton />
              <Button disabled={createEventLoading} onClick={handleLaunchEvent}>
                {createEventLoading ? (
                  <>
                    <div className='animate-spin'>
                      <AiOutlineLoading3Quarters size={16} />
                    </div>
                    <Text className='pl-2'>Configuring...</Text>
                  </>
                ) : (
                  <>
                    <HiRocketLaunch />
                    <Text className='ml-2'>Create Event</Text>
                  </>
                )}
              </Button>
            </ButtonRow>
          </StepFooter>
        </Step>
      </Wizard>
    </WizardContext.Provider>
  );
};
