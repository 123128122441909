import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  HelpText,
  Row,
  Text,
} from '@tokensoft-web/common-ui';
import { add, formatValue } from '@tokensoft-web/common-utils';
import { EligibilityCategory } from '../../utils/enums';
import './event-eligibility.css';

interface EventEligibilityAllocationsProps {
  eligibilityData?: any;
}

const EventEligibilityAllocations = ({
  eligibilityData,
}: EventEligibilityAllocationsProps) => {
  const eligibilityAllocations = eligibilityData?.eligibility.filter(
    (data) => data.category === EligibilityCategory.ELIGIBILITY_ALLOCATIONS,
  );

  const maxAllocationSaleToken =
    eligibilityAllocations?.reduce((acc, cur) => {
      const itemTotal = cur.subcriteria?.criteria?.reduce((subAcc, subCur) => {
        return add(subAcc, subCur.value.allocation_amount_sale_token);
      }, 0);
      return add(acc, itemTotal);
    }, 0) || 0;

  const maxBonusSaleToken =
    eligibilityAllocations?.reduce((acc, cur) => {
      const itemTotal = cur.subcriteria?.criteria?.reduce((subAcc, subCur) => {
        return add(subAcc, subCur.value.bonus_amount_sale_token);
      }, 0);
      return add(acc, itemTotal);
    }, 0) || 0;

  const totalAllocation = add(maxAllocationSaleToken, maxBonusSaleToken);

  if (!eligibilityAllocations || eligibilityAllocations.length === 0) {
    return null;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle data-testid='eligibility-allocation-header'>
          Your Max Allocation ({formatValue(totalAllocation, { commas: true })})
        </CardTitle>
      </CardHeader>

      <CardBody>
        <Col gap={5}>
          <Col gap={2.5}>
            <Col>
              <Text>Max Purchase Allocation</Text>
              <Row
                nowrap
                className={'max-allocation'}
                place={'between'}
                yalign={'center'}
              >
                <Text className={'allocation-token-name'}>{'MNCT'}</Text>
                <Text className={'allocation-amount'}>
                  {formatValue(maxAllocationSaleToken, { commas: true })}
                </Text>
              </Row>
            </Col>

            <Col>
              <Text>Max Token Bonus</Text>
              <Row
                nowrap
                className={'max-bonus'}
                place={'between'}
                yalign={'center'}
              >
                <Text className={'bonus-token-name'}>{'Bonus'}</Text>
                <Text className={'bonus-amount'}>
                  {formatValue(maxBonusSaleToken, { commas: true })}
                </Text>
              </Row>
            </Col>
          </Col>

          {eligibilityAllocations.map((eligibilityItem, i) => (
            <>
              {eligibilityItem.subcriteria?.criteria?.map((sub, x) => (
                <>{sub.description ? <Text>{sub.description}</Text> : null}</>
              ))}
            </>
          ))}

          <HelpText>
            * Eligibility and allocation are subject to change, depending on ...
          </HelpText>
        </Col>
      </CardBody>
    </Card>
  );
};

export default EventEligibilityAllocations;
