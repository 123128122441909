import { getTruncatedAddress, useClipboard } from '@tokensoft-web/common-utils';
import { AiOutlineCopy } from 'react-icons/ai';
import { Row } from '../../../..';
import { NetworkIcon } from '../icon/network-icon';

export const NetworkAddress = ({
  address,
  network,
  showTruncated = true,
}: {
  address: any;
  network?: any;
  showTruncated: boolean;
}) => {
  const { copyToClipboard } = useClipboard();
  const addressTruncated = getTruncatedAddress(address);

  if (!address) {
    return '-';
  }

  return (
    <Row yalign={'center'} gap={2}>
      {network ? (
        <NetworkIcon
          chainId={network}
          logoSize={'sm'}
          showNetworkName={false}
          className='w-auto'
        />
      ) : null}
      <span>{showTruncated ? addressTruncated : address}</span>
      <span
        className={
          'cursor-pointer text-primary-medium hover:text-primary-light'
        }
        onClick={() => copyToClipboard(address)}
      >
        <AiOutlineCopy size={18} />
      </span>
    </Row>
  );
};
