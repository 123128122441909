import { PageHeader } from '@tokensoft-web/common-ui';

const DelegateHeader = ({
  className = '',
  overrideTitle,
  overrideSubTitle,
}: {
  useSubtitle?: boolean;
  className?: string;
  overrideTitle?: string;
  overrideSubTitle?: string;
}) => {
  return (
    <>
      <PageHeader
        title={`${overrideTitle}`}
        subtitle={overrideSubTitle}
        className={`page-header ${className}`}
        breadcrumb='CLAIMS'
      />
    </>
  );
};

export default DelegateHeader;
