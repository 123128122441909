import {
  CheckboxInput,
  Col,
  HelpPopover,
  InfoIcon,
  MultiSelect,
  Row,
  Section,
  SectionTitle,
  Stacked,
  Stretched,
  Text,
} from '@tokensoft-web/common-ui';
import {
  BLOCKCHAIN_KEY,
  COMPLIANCE_TIER,
  CountryList,
  DEFAULT_BLOCKED_COUNTRIES,
  EVENT_TYPE,
  EventWizardState,
  countryList,
} from '@tokensoft-web/common-utils';
import { useEffect, useState } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';

interface EventEligibilitySetupProps {
  wizardState: EventWizardState;
  setWizardState: Function;
}

const EventEligibilitySetup = ({
  wizardState,
  setWizardState,
}: EventEligibilitySetupProps) => {
  const [complianceTier, setComplianceTier] = useState<COMPLIANCE_TIER>(
    wizardState?.complianceTier
      ? wizardState?.complianceTier
      : COMPLIANCE_TIER.IDENTITY_VERIFICATION,
  );
  const [walletRequirements, setWalletRequirements] = useState<
    BLOCKCHAIN_KEY[]
  >(
    wizardState?.walletRequirements
      ? wizardState?.walletRequirements
      : [BLOCKCHAIN_KEY.ETHEREUM],
  );
  const [allowedCountryList, setAllowedCountryList] = useState<CountryList[]>(
    wizardState?.allowedCountryList || [],
  );
  const [blockedCountryList, setBlockedCountryList] = useState<CountryList[]>(
    wizardState?.blockedCountryList || [],
  );

  useEffect(() => {
    if (!wizardState?.allowedCountryList) {
      const defaultCountryList = countryList().data.filter(
        (country) =>
          !DEFAULT_BLOCKED_COUNTRIES.some(
            (blockedCountry) => blockedCountry.value === country.value,
          ),
      );
      setAllowedCountryList(defaultCountryList);
    }
  }, []);

  useEffect(() => {
    if (
      allowedCountryList ||
      blockedCountryList ||
      complianceTier ||
      walletRequirements
    ) {
      const isFormValid = !!complianceTier;
      const clonedChanges = { ...wizardState };
      clonedChanges.complianceTier = complianceTier;
      clonedChanges.walletRequirements = walletRequirements;
      clonedChanges.allowedCountryList = allowedCountryList;
      clonedChanges.blockedCountryList = blockedCountryList;
      clonedChanges.eligibilityFormValid = isFormValid;
      setWizardState(clonedChanges);
    }
  }, [
    allowedCountryList,
    blockedCountryList,
    complianceTier,
    walletRequirements.length,
  ]);

  const handleBlockCountryOnClick = (countryObj) => {
    const updatedCountryList = allowedCountryList.filter((country) => {
      return country.value !== countryObj.value;
    });

    setBlockedCountryList([...blockedCountryList, countryObj]);
    setAllowedCountryList(updatedCountryList);
  };

  const handleRemoveBlockCountry = (countryObj) => {
    // Add back the country into the allowed list and reorder the list
    const newAllowedCountryList = [...allowedCountryList, countryObj].sort(
      (a, b) => {
        return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
      },
    );

    setAllowedCountryList(newAllowedCountryList);

    // Remove the country from the blocked list
    setBlockedCountryList((current) =>
      current.filter((country) => country.value !== countryObj.value),
    );
  };

  // Filter out the default blocked countries from list and sort
  const sortedBlockedCountryList = blockedCountryList
    .filter(
      (country) =>
        !DEFAULT_BLOCKED_COUNTRIES.some(
          (blockedCountry) => blockedCountry.value === country.value,
        ),
    )
    .sort((a, b) => {
      return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
    });

  return (
    <Stacked>
      <Section gap={2.5}>
        <SectionTitle>Compliance Tier</SectionTitle>
        <Text>Users must complete all required steps to be eligible.</Text>
        <Col className='mt-3'>
          <Row yalign={'center'}>
            <CheckboxInput
              className='mr-2'
              checked={
                complianceTier === COMPLIANCE_TIER.IDENTITY_VERIFICATION ||
                complianceTier ===
                  COMPLIANCE_TIER.ID_VERIFICATION_WITH_ACCREDITATION
              }
              onClick={(e) => {
                if (e.target.checked) {
                  setComplianceTier(COMPLIANCE_TIER.IDENTITY_VERIFICATION);
                } else {
                  setComplianceTier(COMPLIANCE_TIER.NO_VERIFICATION);
                }
              }}
              disabled={
                wizardState?.eventType === EVENT_TYPE.RAISE ||
                wizardState?.eventType === EVENT_TYPE.SALE
              }
            >
              Identity Verification
            </CheckboxInput>
            <HelpPopover
              className='max-w-md'
              button={
                <div
                  className='pb-2 block form-check-label cursor-pointer'
                  onClick={() => {}}
                >
                  <InfoIcon fill={'var(--neutral)'} />
                </div>
              }
            >
              <span>
                To be eligible, each applicant must verify his or her identity
                by submitting a valid government identification document (such
                as a passport) and a live selfie video.
              </span>
            </HelpPopover>
          </Row>
          <div className='flex flex-row items-center'>
            <CheckboxInput
              className='mr-2'
              checked={
                complianceTier ===
                COMPLIANCE_TIER.ID_VERIFICATION_WITH_ACCREDITATION
              }
              onClick={(e) => {
                if (e.target.checked) {
                  setComplianceTier(
                    COMPLIANCE_TIER.ID_VERIFICATION_WITH_ACCREDITATION,
                  );
                } else {
                  setComplianceTier(COMPLIANCE_TIER.IDENTITY_VERIFICATION);
                }
              }}
            >
              Accredited Investor Verification
            </CheckboxInput>
            <HelpPopover
              className='max-w-md'
              button={
                <div
                  className='pb-2 block form-check-label cursor-pointer'
                  onClick={() => {}}
                >
                  <InfoIcon fill={'var(--neutral)'} />
                </div>
              }
            >
              <span>
                To be eligible, each applicant must qualify as an accredited
                investors{' '}
                <a
                  href='https://www.sec.gov/education/capitalraising/building-blocks/accredited-investor'
                  target='_blank'
                >
                  as defined by the US Securities and Exchange Commission
                </a>
                . Applicants must provide proof of a qualifying net worth,
                income, or professional membership.
              </span>
            </HelpPopover>
          </div>
        </Col>
      </Section>

      <Section gap={2.5}>
        <SectionTitle>Wallet Requirements</SectionTitle>
        <Text>
          Users must verify ownership of each of the following wallet types.
        </Text>
        <Col className='mt-3'>
          <Row yalign={'center'}>
            <CheckboxInput
              disabled
              className='mr-2'
              checked={walletRequirements?.includes(BLOCKCHAIN_KEY.ETHEREUM)}
              onClick={() => {}}
            >
              Ethereum Wallet
            </CheckboxInput>
            <HelpPopover
              className='max-w-md'
              button={
                <div
                  className='pb-2 block form-check-label cursor-pointer'
                  onClick={() => {}}
                >
                  <InfoIcon fill={'var(--neutral)'} />
                </div>
              }
            >
              <span>
                Sign in with Metamask, WalletConnect, or a smart contract wallet
              </span>
            </HelpPopover>
          </Row>
          <Row yalign={'center'}>
            <CheckboxInput
              className='mr-2'
              checked={walletRequirements?.includes(BLOCKCHAIN_KEY.POLKADOT)}
              onClick={(e) => {
                if (e.target.checked) {
                  setWalletRequirements((walletRequirements) => [
                    ...walletRequirements,
                    BLOCKCHAIN_KEY.POLKADOT,
                  ]);
                } else {
                  setWalletRequirements(
                    walletRequirements.filter(
                      (wallet) => wallet !== BLOCKCHAIN_KEY.POLKADOT,
                    ),
                  );
                }
              }}
            >
              Polkadot Wallet
            </CheckboxInput>
            <HelpPopover
              className='max-w-md'
              button={
                <div
                  className='pb-2 block form-check-label cursor-pointer'
                  onClick={() => {}}
                >
                  <InfoIcon fill={'var(--neutral)'} />
                </div>
              }
            >
              <span>{`Verify with Polkadot.js, Subwallet, or another Substrate-compatible browser extension`}</span>
            </HelpPopover>
          </Row>
        </Col>
      </Section>

      <Section gap={2.5}>
        <SectionTitle>Regional Restrictions</SectionTitle>
        <Text>
          OFAC regions are blocked by default. All other regions should be added
          in accordance with your corporate compliance manual.
        </Text>

        <Stretched gap={5} place={'between'}>
          <Col gap={2.5} className='mt-3'>
            <Text className='text-md font-semibold high-contrast'>Blocked</Text>
            <div className='grid grid-cols-2 md:grid-cols-4 gap-2'>
              {DEFAULT_BLOCKED_COUNTRIES.map((country, i) => (
                <div key={i} className='col-span-1'>
                  <span>{country.label}</span>
                </div>
              ))}
            </div>
          </Col>

          <Col gap={2.5} className='mt-3'>
            <Text className='text-md font-semibold high-contrast'>
              Additional regions to block
            </Text>
            <MultiSelect
              options={allowedCountryList}
              value={{ value: null, label: 'Select a Region' }}
              onChange={(obj) => handleBlockCountryOnClick(obj)}
              required
              isMulti={false}
            />
          </Col>
        </Stretched>

        <div
          className={sortedBlockedCountryList.length === 0 ? 'hidden' : null}
        >
          <Col gap={2.5}>
            <span className='font-semibold high-contrast'>
              Added Blocked Regions
            </span>
            <div className='flex flex-col'>
              {sortedBlockedCountryList.map((country, i) => (
                <div key={i} className='flex flex-row items-center mb-2'>
                  <span
                    className='cursor-pointer'
                    onClick={() => handleRemoveBlockCountry(country)}
                  >
                    <AiFillCloseCircle />
                  </span>
                  <span className='ml-2 '>{country.label}</span>
                </div>
              ))}
            </div>
          </Col>
        </div>
      </Section>
    </Stacked>
  );
};

export default EventEligibilitySetup;
