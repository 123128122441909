import {
  add,
  eq,
  isEmptyObject,
  toNumber,
  useToast,
  useWallet,
} from '@tokensoft-web/common-utils';
import { useEffect, useState } from 'react';
import { usePublicClient } from 'wagmi';
import { useClaims } from '../../utils/claim';
import { Sale } from '../../utils/interface';
import ClaimButton from './../claims/claim-button';

interface SaleClaimWrapperProps {
  sale: Sale;
  walletAddress: string;
  existingClaim: any;
  onMinedTransaction: Function;
}

const SaleClaimWrapper = ({
  sale,
  walletAddress,
  existingClaim,
  onMinedTransaction,
}: SaleClaimWrapperProps) => {
  const distributorAddress = sale?.distributor?.id;
  const { connectedChainId } = useWallet();
  const provider = usePublicClient();

  // Skip checking claims if incorrect chainId or missing distributorAddress from sale
  if (connectedChainId !== sale.chainId || !distributorAddress) {
    return null;
  }

  const [hasRemainingClaimable, setHasRemainingClaimable] = useState(false);
  const { showErrorToast } = useToast();
  const { claim, error, isLoading } = useClaims(
    sale?.chainId,
    distributorAddress,
    walletAddress,
    sale?.id,
    provider,
  );

  useEffect(() => {
    if (error) {
      showErrorToast({
        description: `Unable to get claims details - ${error}`,
      });
    }
  }, [error]);

  useEffect(() => {
    const getHasRemainingClaim = () => {
      if (claim?.saleId === sale?.id) {
        // User has not claimed anything yet
        if (!existingClaim || !existingClaim.length) {
          return true;
        }

        // Check if user has successfully claimed
        // Get the total claimed
        const totalClaimed = existingClaim.reduce(
          (n, { value }) => add(n, value),
          0,
        );

        // Is total claimed same as claimable amount?
        if (claim?.claimableAmount && eq(totalClaimed, claim.claimableAmount)) {
          return false;
        }

        // Check if the claim has already been claimed
        return toNumber(claim?.claimableAmount).gt(0);
      }
    };

    if (!isEmptyObject(claim) && !isLoading) {
      const claimable = getHasRemainingClaim();
      setHasRemainingClaimable(claimable);
    }
  }, [claim, isLoading, existingClaim]);

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      {hasRemainingClaimable && (
        <ClaimButton
          claimData={{
            chainId: claim.chainId,
            symbol: sale?.distributor?.tokenSymbol,
            distributorAddress: distributorAddress,
            saleId: sale.id,
          }}
          sale={sale}
          onMinedTransaction={onMinedTransaction}
          userOnCorrectNetwork={connectedChainId === sale.chainId}
        />
      )}
    </>
  );
};

export default SaleClaimWrapper;
