import * as Collapsible from '@radix-ui/react-collapsible';
import { unixToLocalDateTime, useAccount } from '@tokensoft-web/common-utils';
import { useState } from 'react';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import { useDistributor } from '../../contexts/distributor/distributor-context';
import {
  getDistributorInterfaces,
  isContinuousVestingType,
  isCrosschainDistributorType,
} from '../../utils/abi';
import ClaimsHistoryTable from './claim-history-table';

export interface ClaimRecord {
  amount: string;
  transactionHash: string;
  transferId?: string;
  createdAt: string;
}

const ClaimHistory = () => {
  const { distributor } = useDistributor();
  const { account } = useAccount();
  const [isOpen, setIsOpen] = useState(true);

  const tokenDecimals = distributor.tokenDecimals;
  const chainId = distributor.chainId;
  const tokenSymbol = distributor.tokenSymbol;

  const interfaces = getDistributorInterfaces(distributor);
  const isCrossChain = isCrosschainDistributorType(interfaces);

  const claimableFixedNumber = isContinuousVestingType(interfaces) ? 4 : 2;

  let records = [];
  if (distributor.distributionRecords?.length > 0) {
    records = distributor.distributionRecords[0].claims;
  }

  if (records.length === 0) {
    return null;
  }

  const sortedRecords = [...records].sort(
    (d1, d2) =>
      new Date(
        unixToLocalDateTime(d2?.createdAt, account?.timezone),
      ).getTime() -
      new Date(unixToLocalDateTime(d1?.createdAt, account?.timezone)).getTime(),
  );

  return (
    <Collapsible.Root open={isOpen}>
      <div
        className={'flex items-center font-bold text-lg'}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Collapsible.Trigger asChild>
          {isOpen ? (
            <FaMinusCircle className='mr-2 self-center' />
          ) : (
            <FaPlusCircle className='mr-2 self-center' />
          )}
        </Collapsible.Trigger>

        <span className='cursor-pointer'>Past Claims</span>
      </div>
      <Collapsible.Content>
        <div className={'py-4'}>
          <ClaimsHistoryTable
            records={sortedRecords}
            tokenDecimals={tokenDecimals}
            tokenSymbol={tokenSymbol}
            chainId={chainId}
            isCrossChain={isCrossChain}
            claimableFixedNumber={claimableFixedNumber}
          />
        </div>
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

export default ClaimHistory;
