import { Error, LoadingIndicator } from '@tokensoft-web/common-ui';
import { useGetEvent } from '@tokensoft-web/common-utils';
import { FC, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { EventContext } from './event-context';

interface EventProviderProps {
  children?: ReactNode;
}

export const EventProvider: FC<EventProviderProps> = ({ children }) => {
  const { eventId } = useParams();
  const {
    event: event,
    loading: loading,
    refresh: refresh,
  } = useGetEvent(eventId);

  const hasSetEventDetails = !!event?.name;
  const isOnChainEvent = !!event?.sale?.id || !!event?.distributor?.id;

  if (loading) {
    return <LoadingIndicator text={'Loading event'} />;
  }

  if (!loading && !event) {
    return (
      <Error
        title='Event Not Found'
        message="It looks like this isn't a valid event."
      />
    );
  }

  return (
    <EventContext.Provider
      value={{
        event: event,
        loading: loading,
        refresh: refresh,
        hasSetEventDetails: hasSetEventDetails,
        isOnChainEvent: isOnChainEvent,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};
