import classNames from 'classnames';
import { FC } from 'react';
import './progress-bar.css';

type ProgressBarProps = {
  progress?: ProgressValues[];
  className?: string;
};

type ProgressValues = {
  value: number;
  color?: string;
};

export const ProgressBar: FC<ProgressBarProps> = ({ progress, className }) => {
  return (
    <div className={classNames('progress-bar', className)}>
      {progress && progress.length !== 0 ? (
        progress
          .sort((a, b) => b.value - a.value)
          .map((item, index) => (
            <div
              key={index}
              className={classNames('progress')}
              style={{
                width: item.value + '%',
                backgroundColor: item.color ? item.color : 'var(--primary)',
              }}
            />
          ))
      ) : (
        <div className='progress' style={{ width: 100 + '%' }} />
      )}
    </div>
  );
};
