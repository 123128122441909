import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useAccountApiClient } from '../client/account-api-client';
import { useAccount } from '../context/account/account-context';
import { useAuth } from '../context/auth/auth-context';
import { useToast } from '../context/toast/toast-context';
import { INVESTOR_TYPE } from '../enum/investor-type';
import { useAsync } from '../hook/async';
import { AccountDetails } from '../type/account-details';
import { localToUtcDateTime } from '../util/date-time';

export const useGetAccount = () => {
  const {
    isAuthenticated,
    user: { walletAddress },
  } = useAuth();
  const client = useAccountApiClient();
  const result = useQuery({
    enabled: isAuthenticated,
    queryKey: ['account', walletAddress],
    queryFn: () => client(`accounts/me`),
  });

  return isAuthenticated
    ? { ...result, account: result.data || null }
    : { isLoading: false, account: null };
};

export const useResendVerificationEmail = () => {
  const queryClient = useQueryClient();
  const client = useAccountApiClient();
  const {
    user: { authId },
  } = useAuth();
  return useMutation({
    mutationFn: (emailToken) => {
      return client(`accounts/${authId}/email/verify/resend`, {
        method: 'post',
        data: {},
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['account'] }),
  });
};

export const useVerifyEmailToken = () => {
  const queryClient = useQueryClient();
  const {
    user: { authId },
  } = useAuth();
  const client = useAccountApiClient();
  return useMutation({
    mutationFn: (emailToken) => {
      return client(`accounts/email/verify`, {
        method: 'post',
        data: {
          authId,
          emailToken: emailToken,
        },
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['account'] }),
  });
};

export const useGetAccountByEmail = () => {
  const client = useAccountApiClient();
  return useMutation({
    mutationFn: (data: any) => {
      return client(
        `admin/projects/${data.projectId}/accounts/${data.authId}`,
        {
          method: 'get',
        },
      );
    },
  });
};

export const useCreateAccountKycTier = () => {
  const client = useAccountApiClient();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToast();

  return useMutation({
    mutationFn: (data) => {
      return client('accounts/kyc/tiers', {
        data,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['account'] });
    },
    onError: (error: Error) => {
      console.log(error);
      showErrorToast({ description: error.message });
    },
  });
};

export const useUpdateAccountKycTier = () => {
  const client = useAccountApiClient();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToast();

  return useMutation({
    mutationFn: ({ accountKycTierId, data }: any) => {
      return client(`accounts/kyc/tiers/${accountKycTierId}`, {
        method: 'put',
        data,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['account'] });
    },
    onError: (error: Error) => {
      console.log(error);
      showErrorToast({ description: error.message });
    },
  });
};

export const useGetMessageByToken = (messageToken) => {
  const { isAuthenticated } = useAuth();
  const { run, data, isPending } = useAsync();
  const client = useAccountApiClient();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (!messageToken) {
      return;
    }

    run(client(`accounts/messages/${messageToken}`));
  }, [isAuthenticated, messageToken, run]);

  return !isAuthenticated
    ? { result: false, isPending }
    : { result: data, isPending };
};

export const useCreateMessage = () => {
  const client = useAccountApiClient();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToast();

  return useMutation({
    mutationFn: (data) => {
      return client('accounts/messages', {
        data,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['account'] });
    },
    onError: (error: Error) => {
      console.log(error);
      showErrorToast({ description: error.message });
    },
  });
};

export const useUpdateAccount = () => {
  const { account } = useAccount();
  const client = useAccountApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => {
      return client(`accounts/${account.id}`, {
        method: 'put',
        data,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['account'] }),
  });
};

export const useGenerateAccessToken = (
  authId: number,
  accountId: string,
): any => {
  const client = useAccountApiClient();

  return useMutation({
    mutationFn: (referrer: string) => {
      const data = {
        authId,
        referrer,
      };

      return client(`accounts/${accountId}/idv/token`, {
        method: 'post',
        data,
      });
    },
  });
};

export const useGenerateCustomAccessToken = (
  authId: number,
  accountId: string,
  levelName: string,
): any => {
  const client = useAccountApiClient();

  return useMutation({
    mutationFn: (referrer: string) => {
      const data = {
        authId,
        referrer,
        levelName,
      };

      return client(`accounts/${accountId}/idv/customToken`, {
        method: 'post',
        data,
      });
    },
  });
};

export const useSetAccountDetails = (authId: number, accountId: string) => {
  const queryClient = useQueryClient();
  const client = useAccountApiClient();

  return useMutation({
    mutationFn: (accountDetails: AccountDetails) => {
      const data = {
        authId,
        firstName: accountDetails.firstName,
        lastName: accountDetails.lastName,
        dob: localToUtcDateTime(accountDetails.dob),
        phoneNumber: accountDetails.phoneNumber,
        email: accountDetails.email,
        emailVerified: accountDetails.emailVerified,
        timezone: accountDetails.timezone,
        title: accountDetails.title,
        investorType: accountDetails.investorType,
        addresses: [
          {
            addressType: INVESTOR_TYPE.MYSELF,
            flatNumber: accountDetails.flatNumber,
            buildingNumber: accountDetails.buildingNumber,
            streetLine1: accountDetails.streetLineOne,
            streetLine2: accountDetails.streetLineTwo,
            city: accountDetails.city,
            state: accountDetails.state,
            postcode: accountDetails.zipCode,
            country: accountDetails.country,
          },
        ],
      };

      // If entity, add the extra address for entities
      if (accountDetails.investorType === INVESTOR_TYPE.ENTITY) {
        const entityAddress = {
          addressType: INVESTOR_TYPE.ENTITY,
          flatNumber: accountDetails.entityFlatNumber,
          buildingNumber: accountDetails.entityBuildingNumber,
          streetLine1: accountDetails.entityStreetLineOne,
          streetLine2: accountDetails.entityStreetLineTwo,
          city: accountDetails.entityCity,
          state: accountDetails.entityState,
          postcode: accountDetails.entityZipCode,
          country: accountDetails.entityCountry,
        };
        data['entityName'] = accountDetails.entityName;
        data['dba'] = accountDetails.entityDba;
        data['entityFormationDate'] = localToUtcDateTime(
          accountDetails.entityFormationDate,
        );
        data['entityTaxId'] = accountDetails.entityTaxId;
        data.addresses = [...data.addresses, entityAddress];
      }

      return client(`accounts/${accountId}`, {
        method: 'put',
        data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['account'] });
    },
  });
};
