import { AnimatedCheckmark } from '@tokensoft-web/common-ui';
import { VERIFICATION_STATUS } from '@tokensoft-web/common-utils';
import {
  AiFillCheckCircle,
  AiOutlineMinusCircle,
  AiOutlineWarning,
} from 'react-icons/ai';
import { FaExclamationCircle } from 'react-icons/fa';

export const StatusIcon = ({
  id = 'status-icon',
  status,
  size = 'sm',
  ...restProps
}) => {
  const SIZES = {
    xs: 16,
    sm: 18,
    md: 24,
    lg: 48,
    xl: 56,
  };

  const iconSize = SIZES[size];

  const key = id || 'status-icon';

  switch (status) {
    case VERIFICATION_STATUS.NONE:
    case VERIFICATION_STATUS.NEW:
    case false:
    default:
      return (
        <AiOutlineMinusCircle
          className={`status-icon-NEW`}
          data-testid={`${key}`}
          size={iconSize}
          {...restProps}
        />
      );

    case VERIFICATION_STATUS.PENDING:
      return (
        <AiOutlineWarning
          className={`status-icon-${VERIFICATION_STATUS.PENDING}`}
          data-testid={`${key}`}
          color='var(--warning)'
          size={iconSize}
          {...restProps}
        />
      );

    case VERIFICATION_STATUS.COMPLETED:
    case true:
      return size === 'xl' ? (
        <AnimatedCheckmark
          className={`status-icon-${VERIFICATION_STATUS.COMPLETED}`}
          data-testid={`${key}`}
          size={iconSize}
        />
      ) : (
        <AiFillCheckCircle
          className={`status-icon-${VERIFICATION_STATUS.COMPLETED}`}
          data-testid={`${key}`}
          color='var(--success)'
          size={iconSize}
          {...restProps}
        />
      );

    case VERIFICATION_STATUS.FAILED:
      return (
        <FaExclamationCircle
          className={`status-icon-${VERIFICATION_STATUS.FAILED}`}
          data-testid={`${key}`}
          color='var(--danger)'
          size={iconSize}
          {...restProps}
        />
      );
  }
};
