import { BLOCKCHAIN_KEY } from '../enum/blockchain-key';
import { FEATURE } from '../enum/features';

export const getWalletRequirementsFromFeatures = (
  features: string[],
): BLOCKCHAIN_KEY[] => {
  const requirements = [];

  features.forEach((feature) => {
    if (feature.includes(FEATURE.ADD_POLKADOT_WALLETS)) {
      requirements.push(BLOCKCHAIN_KEY.POLKADOT);
    }
  });

  // By default add ethereum as a wallet requirement
  requirements.push(BLOCKCHAIN_KEY.ETHEREUM);
  return requirements;
};
