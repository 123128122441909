import CanvasJSReact from '@canvasjs/react-charts';
import { deepMerge } from '@tokensoft-web/common-utils';
import { useMemo } from 'react';
import './charts.css';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const DonutChart = ({ options }) => {
  const chartOptions = useMemo(() => {
    if (!options) {
      return null;
    }

    return deepMerge(
      {
        animationEnabled: true,
        toolTip: {
          enabled: false,
        },
        title: {
          verticalAlign: 'center',
          dockInsidePlotArea: true,
          fontColor: '#111827',
          fontSize: 24,
          fontFamily: 'Inter',
          fontWeight: 'bold',
        },
      },
      options,
    );
  }, [options]);

  if (!chartOptions) {
    return <></>;
  }

  return (
    <CanvasJSChart
      options={chartOptions}
      containerProps={{ width: '112px', height: '112px' }}
    />
  );
};
