import { Account, Address, INVESTOR_TYPE } from '@tokensoft-web/common-utils';

export const initialAccountDetailsState = (account: Account) => {
  if (!account) {
    return null;
  }

  const individual: Address = account.addresses.find(
    (address) => address.addressType === INVESTOR_TYPE.MYSELF,
  );
  const entity: Address = account.addresses.find(
    (address) => address.addressType === INVESTOR_TYPE.ENTITY,
  );

  return {
    investorType: account.investorType,
    firstName: account.firstName,
    middleName: account.middleName,
    lastName: account.lastName,
    dob: account.dob,
    email: account.email,
    emailVerified: account.emailVerified,
    timezone: account.timezone,
    flatNumber: individual?.flatNumber,
    buildingNumber: individual?.buildingNumber,
    buildingName: individual?.buildingName,
    streetLineOne: individual?.streetLine1,
    streetLineTwo: individual?.streetLine2,
    state: individual?.state,
    city: individual?.city,
    zipCode: individual?.postcode,
    country: individual?.country,
    phoneNumber: account.phoneNumber, // Update this so that entity and individual have their own phone numbers
    title: entity?.title,
    entityTitle: entity?.title,
    entityName: account.entityName,
    entityCountry: entity?.country,
    entityFlatNumber: entity?.flatNumber,
    entityBuildingNumber: entity?.buildingNumber,
    entityBuildingName: entity?.buildingName,
    entityStreetLineOne: entity?.streetLine1,
    entityStreetLineTwo: entity?.streetLine2,
    entityCity: entity?.city,
    entityState: entity?.state,
    entityZipCode: entity?.postcode,
    entityDba: account.dba,
    entityFormationDate: account.entityFormationDate,
    entityTaxId: account.entityTaxId,
  };
};
