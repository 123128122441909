import classNames from 'classnames';
import { Text } from './text';

export const ModalTitle = ({ className = '', children, ...restProps }) => {
  return (
    <Text
      textAlign={'center'}
      className={classNames('text-2xl font-bold high-contrast', className)}
      {...restProps}
    >
      {children}
    </Text>
  );
};
