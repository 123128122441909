import classNames from 'classnames';
import { Row } from '../layout/container/row';
import './button.css';

export const Button = ({
  className = '',
  onClick = null,
  color = 'primary',
  fill = null,
  size = null,
  disabled = false,
  readonly = false,
  children,
  ...restProps
}) => {
  return (
    <button
      disabled={disabled}
      onClick={() => {
        if (!readonly) {
          onClick && onClick();
        }
      }}
      className={classNames(
        'btn truncate',
        !fill ? `btn-${color}` : '',
        fill ? `btn-${fill}-${color}` : '',
        size ? `btn-${size}` : '',
        readonly ? 'readonly' : '',
        className,
      )}
      {...restProps}
    >
      <Row nowrap gap={2.5} place={'center'}>
        {children}
      </Row>
    </button>
  );
};
