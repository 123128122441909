import classNames from 'classnames';
import { Text } from './text';

export const WizardBreadcrumb = ({
  className = '',
  children,
  ...restProps
}) => {
  return (
    <Text
      textAlign={'center'}
      className={classNames(
        'wizard-breadcrumb text-sm leading-5 text-neutral-extra-dark uppercase',
        className,
      )}
      {...restProps}
    >
      {children}
    </Text>
  );
};
