import classNames from 'classnames';
import { useWizard } from 'react-use-wizard';
import { Button } from '../button/button';
import { FrontArrowIcon } from '../icon/front-arrow-icon';
import { Text } from '../typography/text';

export const ContinueButton = ({
  disabled = false,
  label = 'Continue',
  icon = <FrontArrowIcon />,
  className = '',
  onClick = null,
  ...restProps
}) => {
  const { nextStep, isLoading } = useWizard();

  return (
    <Button
      className={classNames('md:w-36 w-full', className)}
      onClick={(e) => (onClick ? onClick(e) : nextStep())}
      disabled={disabled || isLoading}
      {...restProps}
    >
      <Text>{label}</Text>
      {icon}
    </Button>
  );
};
