import { useCreateImageResource, useToast } from '@tokensoft-web/common-utils';
import { useRef } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { UploadIcon } from '../../../..';
import { Button } from '../button/button';
import { Text } from '../typography/text';

export const ImageUploadButton = ({
  onFileUploaded,
  className,
  ...restProps
}) => {
  const { showErrorToast } = useToast();
  const { mutate: uploadImageResource, isPending } = useCreateImageResource();
  const hiddenFileInput = useRef(null);

  const handleOnClick = (e) => {
    if (e) {
      e.preventDefault();
    }

    hiddenFileInput.current.click();
  };

  const handleImageUpload = (e) => {
    if (e) {
      e.preventDefault();
    }

    const file = e.target.files[0];

    const twoMB = 2097152;
    if (file.size > twoMB) {
      hiddenFileInput.current.value = null;
      showErrorToast({ description: 'File size must be less than 2MB' });
      return;
    }

    const formData = new FormData();
    formData.append('file', file, file.name);

    uploadImageResource(formData, {
      onSuccess: (data) => {
        hiddenFileInput.current.value = null;
        onFileUploaded && onFileUploaded(data);
      },
      onError: (error) => {
        hiddenFileInput.current.value = null;
        showErrorToast({ description: error.message });
      },
    });
  };

  return (
    <>
      <Button
        className={className}
        onClick={handleOnClick}
        disabled={isPending}
        {...restProps}
      >
        {isPending ? (
          <>
            <div className='animate-spin'>
              <AiOutlineLoading3Quarters size={16} />
            </div>
            <Text>Uploading</Text>
          </>
        ) : (
          <>
            <UploadIcon />
            <Text>Upload Image</Text>
          </>
        )}
      </Button>
      <input
        type='file'
        accept='.png, .jpg, .jpeg, .ico, .svg, .gif'
        onChange={handleImageUpload}
        ref={hiddenFileInput}
        style={{ display: 'none' }} // Make the file input element invisible
      />
    </>
  );
};
