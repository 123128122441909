import { FC, ReactNode } from 'react';
import { NETWORK_NAME } from '../../enum/network-name';
import { PAYMENT_METHOD } from '../../enum/payment-method';
import { NetworkDetails } from '../../type/network-details';
import { hexToDecimal } from '../../util/network';
import { useConfiguration } from '../configuration/configuration-context';
import { NetworkContext } from './network-context';

interface NetworkProviderProps {
  children?: ReactNode;
}

const UNKNOWN_NETWORK = {
  name: NETWORK_NAME.UNKNOWN,
  symbol: PAYMENT_METHOD.ETH,
  logoUri: 'https://s3.amazonaws.com/media.tokensoft.io/images/eth.png',
} as NetworkDetails;

export const NetworkProvider: FC<NetworkProviderProps> = ({ children }) => {
  const { configuration, loading } = useConfiguration();
  const supportedNetworks = configuration?.networks?.filter(
    (network) => network.enabled === true,
  );

  const getNetworkDetails = (chainId: any): NetworkDetails => {
    if (!chainId) {
      return UNKNOWN_NETWORK;
    }

    const decimalChainId =
      typeof chainId === 'string' ? hexToDecimal(chainId) : Number(chainId);
    const networkDetails = supportedNetworks?.find(
      (network) => network.id == decimalChainId,
    );
    if (!networkDetails) {
      return UNKNOWN_NETWORK;
    }

    return networkDetails;
  };

  const getNativeBaseCurrencyPriceOracle = (chainId: any): string => {
    if (!chainId) {
      return null;
    }

    const decimalChainId =
      typeof chainId === 'string' ? hexToDecimal(chainId) : Number(chainId);
    const networkDetails = getNetworkDetails(chainId);
    const paymentMethods = networkDetails?.paymentMethods;
    if (!paymentMethods) {
      return null;
    }

    const nativePaymentDetails = paymentMethods.find(
      (network) => network.native && decimalChainId === chainId,
    );
    if (!nativePaymentDetails) {
      return null;
    }

    return nativePaymentDetails.nativePriceOracleAddress;
  };

  const isSupportedNetwork = (networkName: NETWORK_NAME): boolean => {
    return (
      supportedNetworks?.findIndex((network) => network.name == networkName) >=
      0
    );
  };

  const supportsENSDomain = (chainId: number | string): boolean => {
    const networkDetails = getNetworkDetails(chainId);
    return (
      networkDetails.name == NETWORK_NAME.ETHEREUM ||
      networkDetails.name == NETWORK_NAME.SEPOLIA
    );
  };

  return (
    <>
      <NetworkContext.Provider
        value={{
          supportedNetworks: supportedNetworks,
          isSupportedNetwork: isSupportedNetwork,
          getNetworkDetails: getNetworkDetails,
          getNativeBaseCurrencyPriceOracle: getNativeBaseCurrencyPriceOracle,
          supportsENSDomain: supportsENSDomain,
          loading: loading,
        }}
      >
        {children}
      </NetworkContext.Provider>
    </>
  );
};
