import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  EditIcon,
  Section,
  Stacked,
  Stretched,
  TableData,
  TableHeader,
  Text,
  TextLink,
} from '@tokensoft-web/common-ui';
import {
  allowedRegionsToCountryList,
  blockedRegionsFromCountryList,
  getWalletRequirementsFromFeatures,
  kycRestrictionToComplianceSummary,
  walletRequirementsString,
} from '@tokensoft-web/common-utils';
import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const EventEligibility = ({ context, className = '' }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);

  const allowedRegions = allowedRegionsToCountryList(context?.allowedRegions);
  const blockedRegions = blockedRegionsFromCountryList(allowedRegions);
  const walletRequirements = getWalletRequirementsFromFeatures(
    context.features,
  );

  const key = 'event-eligibility';

  return (
    <Card className={classNames(className)}>
      <CardHeader place={'center'}>
        <CardTitle
          open={isOpen}
          onOpen={setIsOpen}
          data-testid={`${key}-title`}
        >
          Eligibility
        </CardTitle>
        <TextLink
          width={'w-fit'}
          onClick={() => {
            navigate(
              `/admin/project/${context.project.id}/event/${context.id}/eligibility`,
            );
          }}
        >
          <EditIcon />
          <Text>Edit Details</Text>
        </TextLink>
      </CardHeader>

      <CardBody open={isOpen}>
        <Stacked>
          <Section gap={5} place={'start'}>
            <Stretched ygap={5}>
              <Col gap={2.5}>
                <TableHeader data-testid={`${key}-compliance-tier-header`}>
                  Compliance Tier
                </TableHeader>
                <TableData data-testid={`${key}-compliance-tier-data`}>
                  {kycRestrictionToComplianceSummary(context.kycRestriction)}
                </TableData>
              </Col>
            </Stretched>
          </Section>

          <Section gap={5} place={'start'}>
            <Stretched ygap={5}>
              <Col gap={2.5}>
                <TableHeader data-testid={`${key}-compliance-tier-header`}>
                  Wallet Requirements
                </TableHeader>
                <TableData data-testid={`${key}-compliance-tier-data`}>
                  {walletRequirementsString(walletRequirements)}
                </TableData>
              </Col>
            </Stretched>
          </Section>

          <Section gap={5} place={'start'}>
            <Stretched ygap={5}>
              <Col gap={2.5}>
                <TableHeader data-testid={`${key}-blocked-regions-header`}>
                  Blocked Regions
                </TableHeader>
                <div
                  data-testid={`${key}-blocked-regions-data`}
                  className='grid grid-cols-2 md:grid-cols-4 gap-2'
                >
                  {blockedRegions.map((region, i) => (
                    <div key={i} className='col-span-1 pr-2'>
                      <Text className='abbreviated'>{region.label}</Text>
                    </div>
                  ))}
                </div>
              </Col>
            </Stretched>
          </Section>
        </Stacked>
      </CardBody>
    </Card>
  );
};

export default EventEligibility;
