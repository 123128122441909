import classNames from 'classnames';
import { Stretched } from '../../layout/container/stretched';

export const ButtonRow = ({ className, children, justify, ...restProps }) => {
  return (
    <Stretched
      xgap={4}
      ygap={2.5}
      place={justify}
      className={classNames('mt-4 button-row', className)}
      {...restProps}
    >
      {children}
    </Stretched>
  );
};
