import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  EditIcon,
  PDFIcon,
  Row,
  Section,
  Stacked,
  Stretched,
  TableData,
  Text,
  TextLink,
} from '@tokensoft-web/common-ui';
import {
  DOCUMENT_SHOW_TO_TYPE,
  useAuth,
  viewDocumentVersion,
} from '@tokensoft-web/common-utils';
import classNames from 'classnames';
import { useState } from 'react';
import { AiFillEye } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { showDocumentTo } from '../../../utils/document';

const EventDocuments = ({ context, className = '' }) => {
  const navigate = useNavigate();
  const {
    user: { token },
  } = useAuth();
  const [isOpen, setIsOpen] = useState(!Object.keys(context).includes('id'));

  const key = 'event-documents';

  const formatDocumentTitle = (document): string => {
    return `${document.documentVersion.document.name}`;
  };

  const formatDocumentRestrictionType = (document): string => {
    const restrictionType = showDocumentTo(document.regionRestriction);
    switch (restrictionType) {
      case DOCUMENT_SHOW_TO_TYPE.ALL_ELIGIBLE_PERSONS:
        return 'All eligible persons';
      case DOCUMENT_SHOW_TO_TYPE.OUTSIDE_US:
        return 'Eligible persons outside the US';
      case DOCUMENT_SHOW_TO_TYPE.US_ONLY:
        return 'Eligible persons within the US';
    }
  };

  const handleOpenDocument = async (document) => {
    await viewDocumentVersion(document, token);
  };

  return (
    <Card className={classNames(className)}>
      <CardHeader place={'center'}>
        <CardTitle
          open={isOpen}
          onOpen={context.id ? setIsOpen : null}
          data-testid={`${key}-title`}
        >
          Documents
        </CardTitle>
        {context.id ? (
          <TextLink
            width={'w-fit'}
            onClick={() => {
              navigate(
                `/admin/project/${context.project.id}/event/${context.id}/documents`,
              );
            }}
          >
            <EditIcon />
            <Text>Edit Details</Text>
          </TextLink>
        ) : null}
      </CardHeader>

      <CardBody open={isOpen}>
        <Stacked>
          <Section gap={0} place={'start'}>
            {!context?.documents?.length ? (
              <Stretched ygap={5}>
                <Col>
                  <TableData data-testid={`${key}-start-date-data`}>
                    No documents found
                  </TableData>
                </Col>
              </Stretched>
            ) : (
              context?.documents?.map((doc, i) => {
                return (
                  <Row
                    xalign={'between'}
                    key={i}
                    className='document-section cursor-pointer'
                    onClick={() => {
                      handleOpenDocument(doc);
                    }}
                  >
                    <div className='flex flex-row justify-center items-center'>
                      <PDFIcon />
                      <span className='ml-2'>{formatDocumentTitle(doc)}</span>
                    </div>
                    <div className='flex flex-row justify-center items-center mt-2 sm:mt-0'>
                      <span className='mr-2'>
                        <AiFillEye fill='var(--primary)' size={22} />
                      </span>
                      <span className='mr-3'>
                        {formatDocumentRestrictionType(doc)}
                      </span>
                    </div>
                  </Row>
                );
              })
            )}
          </Section>
        </Stacked>
      </CardBody>
    </Card>
  );
};

export default EventDocuments;
