import { useAnalytics, useRequestAccess } from '@tokensoft-web/common-utils';
import { useEffect } from 'react';
import ClaimDelegate from '../../components/claims/claim-delegate';
import ClaimDetails from '../../components/claims/claim-details';
import ClaimIntro from '../../components/claims/claim-intro';
import { useDistributor } from '../../contexts/distributor/distributor-context';
import { CLAIM_STEP } from '../../utils/enums';
import './claims.css';

const ClaimPage = () => {
  const {
    event,
    distributor,
    documentsToAcceptOrSign,
    setPageState,
    pageState,
  } = useDistributor();
  const { pageEvent } = useAnalytics();
  const { mutate: requestAccess } = useRequestAccess();

  useEffect(() => {
    if (distributor) {
      if (!event.eventUsers?.length) {
        requestAccess({ id: event.id });
      }

      // Only show intro if distributor has no associated sale and wallet has not yet claimed
      const showIntro =
        (documentsToAcceptOrSign && documentsToAcceptOrSign.length !== 0) ||
        (!distributor.sale?.id && distributor.distributionRecords.length === 0);

      if (showIntro) {
        setPageState(CLAIM_STEP.INTRO);
      } else {
        setPageState(CLAIM_STEP.CLAIM);
      }
    }
  }, [distributor?.id]);

  const renderPage = () => {
    switch (pageState) {
      case CLAIM_STEP.INTRO:
        pageEvent('claim', 'intro');
        return <ClaimIntro />;
      case CLAIM_STEP.CLAIM:
        pageEvent('claim', 'detail');
        return <ClaimDetails />;
      case CLAIM_STEP.DELEGATE:
        pageEvent('claim', 'delegate');
        return <ClaimDelegate />;
      default:
        return null;
    }
  };

  return <>{renderPage()}</>;
};

export default ClaimPage;
