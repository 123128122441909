import {
  Button,
  ButtonRow,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FrontArrowIcon,
  Stacked,
  Text,
} from '@tokensoft-web/common-ui';
import {
  FEATURE,
  TIER_ID,
  useAcceptAndSignEventUserDocuments,
  useAccount,
  useAuth,
  useClaimSignedMessage,
  useClaimSigninMessagePrompt,
  useToast,
} from '@tokensoft-web/common-utils';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useSignMessage } from 'wagmi';
import { useDistributor } from '../../contexts/distributor/distributor-context';
import Signature from '../../pages/sale/signature';
import { CLAIM_STEP } from '../../utils/enums';
import { isSmartContractWallet } from '../../utils/wallet';
import EventBodyWrapper from '../event/event-body-wrapper';
import EventDocuments from '../event/event-documents';
import EventHeader from '../event/event-header';
import EventHeaderWrapper from '../event/event-header-wrapper';
import KycRestriction from '../feature/kyc-restriction';
import ClaimAvailability from './claim-availability';
import ClaimIneligible from './claim-ineligible';
import './connext/connext-claim.css';
import ConnextHeader from './connext/connext-header';
import ConnextIntroFooter from './connext/connext-intro-footer';
import ConnextIntroHeaderText from './connext/connext-intro-header-text';

const ClaimIntro = () => {
  const {
    user: { walletAddress },
  } = useAuth();
  const { account } = useAccount();
  const { showErrorToast } = useToast();
  const [pendingSignature, setPendingSignature] = useState(false);
  const [acceptedDocs, setAcceptedDocs] = useState(false);
  const [signatureValid, setSignatureValid] = useState(false);

  const { data: signedMessageData, isPending, signMessage } = useSignMessage();
  const { event, distributor, setPageState, documentsToAcceptOrSign } =
    useDistributor();
  const navigate = useNavigate();
  const isSmartContract = isSmartContractWallet(
    account?.wallets,
    walletAddress,
  );

  const delegatePreselectionRequired = !!event?.features?.includes(
    FEATURE.CLAIM_DELEGATE_PRESELECT_REQUIRED,
  );
  const connextSignatureRequired =
    !!event?.features?.includes(FEATURE.CLAIM_CONNEXT_SIGNATURE_REQUIRED) &&
    !isSmartContract;
  const connextLayout = !!event?.features?.includes(
    FEATURE.CLAIM_CONNEXT_LAYOUT,
  );
  const description = event?.longDescription || distributor.intro || null;

  const { mutate: sign, isPending: signLoading } =
    useAcceptAndSignEventUserDocuments();
  const { data: signinData, isLoading: isLoadingSignMessagePrompt } =
    useClaimSigninMessagePrompt(event?.id, connextSignatureRequired);
  const { mutate: signedSignature } = useClaimSignedMessage(event?.id);

  const documentSignatureRequired = documentsToAcceptOrSign
    ? documentsToAcceptOrSign.findIndex(
        (doc) => doc?.eventDocumentVersion?.requiresSignature === true,
      ) >= 0
    : false;
  const isWaitingSignaturePrompt =
    connextSignatureRequired && isLoadingSignMessagePrompt;
  const loading =
    isPending || pendingSignature || isWaitingSignaturePrompt || signLoading;

  const [termsSelected, setTermsSelected] = useState(true);

  const contentHeaderBgColor = event?.contentHeaderBgColor;
  const contentHeaderImage = event?.contentHeaderImage;
  const contentHeaderFontColor = event?.contentHeaderFontColor;
  const contentHeaderTitle = event?.contentHeaderTitle;
  const contentHeaderBgImage = event?.contentHeaderBgImage;
  const themeLayoutVersion = event?.themeLayoutVersion;

  const isWaitingDocumentSignature = () => {
    if (signLoading) {
      return true;
    }

    if (!documentSignatureRequired) {
      return false;
    }

    if (documentsToAcceptOrSign && !acceptedDocs) {
      return true;
    }

    if (!acceptedDocs || !signatureValid) {
      return true;
    }
  };

  useEffect(() => {
    if (signedMessageData && connextSignatureRequired) {
      setPendingSignature(true);
      signedSignature(
        {
          signedMessage: signedMessageData,
        },
        {
          onSuccess: () => {
            setPendingSignature(false);
            setPageState(CLAIM_STEP.DELEGATE);
          },
          onError: (e) => {
            setPendingSignature(false);
            // Allow user to continue if signature endpoint fails
            setPageState(CLAIM_STEP.DELEGATE);
          },
        },
      );
    }
  }, [signedMessageData]);

  const handleContinueButtonOnClick = () => {
    if (connextSignatureRequired && isLoadingSignMessagePrompt) {
      return;
    }

    if (connextSignatureRequired && signinData?.prompt) {
      signMessage({ account: walletAddress, message: signinData?.prompt });
      return;
    }

    if (documentsToAcceptOrSign && documentsToAcceptOrSign?.length) {
      sign(
        {
          eventId: event.id,
          documents: documentsToAcceptOrSign,
        },
        {
          onSuccess: () => {
            gotoNextPage();
          },
          onError: (error) => showErrorToast({ description: error.message }),
        },
      );
      return;
    } else {
      gotoNextPage();
    }
  };

  const gotoNextPage = () => {
    if (delegatePreselectionRequired) {
      setPageState(CLAIM_STEP.DELEGATE);
    } else {
      setPageState(CLAIM_STEP.CLAIM);
    }
  };

  // if distributor is not set, the key claimAmount will not exist,
  // therefore, we should only check claimAmount only if distributor exists and key exists
  if ('proofAmount' in distributor && !distributor.proofAmount) {
    return (
      <Col className={'py-14 px-8'}>
        <ClaimIneligible />
      </Col>
    );
  }

  if (event?.eventUsers?.length) {
    if (
      event.eventUsers[0].restrictions?.kycRestricted &&
      event.kycRestriction !== TIER_ID.NONE
    ) {
      return (
        <Col className={'py-14 px-8'}>
          <KycRestriction />
        </Col>
      );
    }

    if (event.eventUsers[0].restrictions?.walletAddressRestricted) {
      return (
        <Col className={'py-14 px-8'}>
          <ClaimIneligible />
        </Col>
      );
    }
  }

  const renderClaimHeader = () => {
    if (connextLayout) {
      return (
        <>
          <ConnextHeader subText='Welcome to the Connext Airdrop!' />
          <EventBodyWrapper>
            <ConnextIntroHeaderText />
          </EventBodyWrapper>
        </>
      );
    }

    return (
      <EventHeaderWrapper
        backgroundColor={contentHeaderBgColor}
        backgroundImg={contentHeaderBgImage}
      >
        <EventHeader
          headerTitle={contentHeaderTitle || distributor?.name}
          contentHeaderImage={contentHeaderImage}
          contentHeaderFontColor={contentHeaderFontColor}
          themeLayoutVersion={themeLayoutVersion}
        />
      </EventHeaderWrapper>
    );
  };

  const renderClaimFooter = () => {
    if (connextLayout) {
      return (
        <ConnextIntroFooter
          termsSelected={termsSelected}
          termsSelect={setTermsSelected}
        />
      );
    }

    if (documentsToAcceptOrSign?.length) {
      return (
        <Card>
          <CardHeader>
            <CardTitle>Terms & Conditions</CardTitle>
          </CardHeader>

          <CardBody>
            <Col gap={5}>
              <EventDocuments
                documents={documentsToAcceptOrSign}
                setAcceptedDocs={setAcceptedDocs}
                eventType={event?.type}
                requiresSignature={true}
              />

              {documentSignatureRequired ? (
                <Signature
                  setSignatureValid={setSignatureValid}
                  nameLabel={'Name'}
                />
              ) : null}
            </Col>
          </CardBody>
        </Card>
      );
    }

    if (description) {
      return <div dangerouslySetInnerHTML={{ __html: description }} />;
    }

    return <></>;
  };

  return (
    <Stacked>
      {renderClaimHeader()}

      <EventBodyWrapper>
        <ClaimAvailability />

        {renderClaimFooter()}

        <ButtonRow place={'between'} className={classNames('mt-8')}>
          <Button onClick={() => navigate('/claim')}>Cancel</Button>

          <Button
            disabled={loading || !termsSelected || isWaitingDocumentSignature()}
            onClick={() => handleContinueButtonOnClick()}
          >
            <>
              <Text>Continue</Text>
              {loading ? (
                <div className='animate-spin'>
                  <AiOutlineLoading3Quarters size={16} />
                </div>
              ) : (
                <FrontArrowIcon />
              )}
            </>
          </Button>
        </ButtonRow>
      </EventBodyWrapper>
    </Stacked>
  );
};

export default ClaimIntro;
