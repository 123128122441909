import {
  CreateDocument,
  DOCUMENT_SHOW_TO_TYPE,
  EventWizardState,
} from '@tokensoft-web/common-utils';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { DocumentUploadContext } from './document-upload-context';

interface DocumentUploadProviderProps {
  wizardState: EventWizardState;
  setWizardState: Function;
  hideStepFooter?: boolean;
  children?: ReactNode;
}

export const documentShowTypeToString = (
  documentType: DOCUMENT_SHOW_TO_TYPE,
): string => {
  switch (documentType) {
    case DOCUMENT_SHOW_TO_TYPE.ALL_ELIGIBLE_PERSONS:
      return 'All eligible persons';
    case DOCUMENT_SHOW_TO_TYPE.OUTSIDE_US:
      return 'Eligible persons outside the US';
    case DOCUMENT_SHOW_TO_TYPE.US_ONLY:
      return 'Eligible persons within the US';
  }
};

export const DocumentUploadProvider: FC<DocumentUploadProviderProps> = ({
  wizardState,
  setWizardState,
  hideStepFooter = false,
  children,
}) => {
  const [isUSRestricted, setIsUSRestricted] = useState(false);
  const [eventType, setEventType] = useState(null);

  const [documentData, setDocumentData] = useState<CreateDocument>({
    signatureRequired: false,
    company: 'COMPANY',
    companyName: null,
    signerName: null,
    signerTitle: null,
    showDocumentTo: DOCUMENT_SHOW_TO_TYPE.ALL_ELIGIBLE_PERSONS,
    documentTitle: null,
    uploadedFile: null,
    recipient: 'RECIPIENT',
    addendum: 'ADDENDUM',
    index: null,
  });

  useEffect(() => {
    if (wizardState) {
      const _isUSRestricted =
        wizardState.blockedCountryList.find(
          (country) => country.value === 'US',
        ) !== undefined;
      setIsUSRestricted(_isUSRestricted);
      setEventType(wizardState.eventType);
      if (wizardState.isEditingDocuments !== null) {
        setDocumentData({
          ...wizardState.documents[wizardState.isEditingDocuments],
          index: wizardState.isEditingDocuments,
        });
      }
    }
  }, [wizardState]);

  const handleOnSave = () => {
    let newDocumentsSection = [];
    if (
      wizardState.isEditingDocuments != null &&
      wizardState.isEditingDocuments >= 0
    ) {
      newDocumentsSection = wizardState.documents.map((section, i) => {
        if (i === wizardState.isEditingDocuments) {
          return documentData;
        }
        return section;
      });
    } else {
      newDocumentsSection = [...wizardState.documents, documentData];
    }

    const clonedChanges = { ...wizardState };
    clonedChanges.documents = newDocumentsSection;
    clonedChanges.isEditingDocuments = null;
    setWizardState(clonedChanges);
  };

  const handleDeleteSection = (i) => {
    let newDocumentsSection = wizardState.documents.filter(
      (a, index) => i !== index,
    );
    const clonedChanges = { ...wizardState };
    clonedChanges.documents = newDocumentsSection;
    setWizardState(clonedChanges);
  };

  const value = useMemo(
    () => ({
      isUSRestricted,
      documentData,
      setDocumentData,
      handleOnSave,
      handleDeleteSection,
      hideStepFooter,
      eventType,
    }),
    [
      isUSRestricted,
      documentData,
      setDocumentData,
      handleOnSave,
      handleDeleteSection,
      hideStepFooter,
      eventType,
    ],
  );

  return (
    <DocumentUploadContext.Provider value={value}>
      {children}
    </DocumentUploadContext.Provider>
  );
};
