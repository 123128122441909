import * as Sentry from '@sentry/react';
import { FrontArrowIcon } from '@tokensoft-web/common-ui';
import {
  getTruncatedAddress,
  getTxUrl,
  useAnalytics,
  useAuth,
  useClaimsDelegateSet,
  useConfiguration,
  useFindDelegate,
  useModal,
  useNetworks,
  useToast,
} from '@tokensoft-web/common-utils';
import { useEffect, useState } from 'react';
import { VscLinkExternal } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import { isSatelliteContract } from '../../utils/abi';
import { useSubmitDelegate } from '../../utils/claim';
import DelegatingModal from './delegating-modal';
import DelegatingModalDelegateFound from './delegating-modal-delegate-found';

interface DelegateAcceptButtonProps {
  contract: any;
  onContinue: Function;
  disabled?: boolean;
  delegateWalletAddress: string;
  delegateByInputWallet?: boolean;
  delegateInfo?: any;
}

enum MODAL_STEP {
  NONE = 'NONE',
  SUBMIT = 'SUBMIT',
  PENDING_SUBMIT = 'PENDING_SUBMIT',
  SUCCESS = 'SUCCESS',
  FOUND_DELEGATE = 'FOUND_DELEGATE',
}

const DelegateAcceptButton = ({
  contract,
  onContinue,
  disabled,
  delegateWalletAddress,
  delegateByInputWallet = true,
  delegateInfo,
}: DelegateAcceptButtonProps) => {
  const {
    user: { walletAddress },
  } = useAuth();
  const { getNetworkDetails } = useNetworks();
  const { showModal, closeModal } = useModal();
  const { showSuccessToast, showErrorToast, showInfoToast } = useToast();
  const [pending, setPending] = useState(false);
  const { pageEvent } = useAnalytics();
  const [modalStep, setModalStep] = useState<MODAL_STEP>(MODAL_STEP.NONE);

  const isSatellite = isSatelliteContract(contract.interfaces || []);
  const {
    error: submitDelegateError,
    write: submitDelegate,
    data: submitDelegateReceipt,
  } = useSubmitDelegate(isSatellite, walletAddress);
  const { configuration } = useConfiguration();
  const navigate = useNavigate();

  const [delegateSaved, setDelegateSaved] = useState(null);
  const { mutate: claimDelegateSet } = useClaimsDelegateSet();
  const {
    mutate: findDelegate,
    isPending: findDelegateLoading,
    isSuccess: findDelegateSuccess,
    error: findDelegateError,
  } = useFindDelegate();

  useEffect(() => {
    const pendingSubmit = modalStep === MODAL_STEP.PENDING_SUBMIT;
    if (modalStep == MODAL_STEP.FOUND_DELEGATE) {
      const hexSplit = delegateWalletAddress.split('0x');
      const isHex = hexSplit.length > 1;
      const delegateToAddressOrENS = isHex
        ? getTruncatedAddress(delegateWalletAddress)
        : delegateWalletAddress;
      showModal({
        content: (
          <DelegatingModalDelegateFound
            delegateToAddressOrENS={delegateToAddressOrENS}
            delegateSaved={delegateSaved}
            handleOnContinue={handleOnContinue}
          />
        ),
        onClose: () => {
          setModalStep(MODAL_STEP.NONE);
        },
        disableClose: true,
      });
    } else if (modalStep === MODAL_STEP.SUBMIT) {
      const delegatingToSelf =
        walletAddress.toLowerCase() === delegateWalletAddress.toLowerCase();
      const hexSplit = delegateWalletAddress.split('0x');
      const isHex = hexSplit.length > 1;
      const delegateToAddressOrENS = isHex
        ? getTruncatedAddress(delegateWalletAddress)
        : delegateWalletAddress;
      showModal({
        content: (
          <DelegatingModal
            delegatingToSelf={delegatingToSelf}
            delegateByInputWallet={delegateByInputWallet}
            delegateSaved={delegateSaved}
            delegateToAddressOrENS={delegateToAddressOrENS}
            delegateInfo={delegateInfo}
            showSteps={false}
          />
        ),
        onClose: () => {
          setModalStep(MODAL_STEP.NONE);
        },
        disableClose: true,
      });
    }
  }, [modalStep]);

  const handleOnContinue = () => {
    closeModal();
    setModalStep(MODAL_STEP.NONE);
    handleDelegateCall();
  };

  useEffect(() => {
    if (submitDelegateReceipt) {
      closeModal();
      showSuccessToast({
        description: (
          <div className='flex flex-row'>
            Successfully submitted delegation.
            <a
              target='_blank'
              rel='noreferrer'
              href={getTxUrl(
                submitDelegateReceipt.transactionHash,
                getNetworkDetails(contract.networkId),
              )}
              className='w-[30px] flex items-center justify-center text-white'
              onClick={(e) => e.stopPropagation()}
            >
              <VscLinkExternal color='white' />
            </a>
          </div>
        ),
      });
      claimDelegateSet(
        {
          distributorId: contract.address,
        },
        {
          onSuccess: () => console.log('Claim Delegate set send Success'),
          onError: () => console.log('Claim Delegate set send Error'),
        },
      );
      pageEvent('governance', 'submitDelegate');
      navigate(`/governance`);
    }
  }, [submitDelegateReceipt]);

  useEffect(() => {
    if (submitDelegateError) {
      setPending(false);
      Sentry.captureException(submitDelegateError.message);
      console.error(submitDelegateError.message);
      showErrorToast({
        description:
          'The transaction could not be completed, please try again later.',
      });
      closeModal();
    }
  }, [submitDelegateError]);

  const handleDelegateCall = async () => {
    setModalStep(MODAL_STEP.SUBMIT);
    try {
      await submitDelegate(
        contract.networkId,
        contract.address,
        delegateWalletAddress,
      );
    } catch (e) {
      showErrorToast({ description: e.message });
      setModalStep(MODAL_STEP.NONE);
      closeModal();
    }
  };

  const executeDelegate = () => {
    if (delegateByInputWallet) {
      findDelegate(
        {
          walletAddress: delegateWalletAddress,
          projectId: configuration.project?.id,
        },
        {
          onSuccess: (response) => {
            if (response.delegate) {
              setDelegateSaved(response.delegate);
              setModalStep(MODAL_STEP.FOUND_DELEGATE);
            } else {
              handleDelegateCall();
            }
          },
          onError: (error) => showErrorToast({ description: error.message }),
        },
      );
    } else {
      handleDelegateCall();
    }
  };

  return (
    <button
      className='btn btn-primary btn-lg ml-auto'
      onClick={() => executeDelegate()}
      disabled={pending || disabled || findDelegateLoading}
    >
      <div className='flex flex-row justify-center items-center'>
        {findDelegateLoading ? (
          <div>Processing</div>
        ) : pending ? (
          <div>Saving</div>
        ) : (
          <div className='flex flex-row items-center'>
            <span className='mr-2'>Save</span>
            <FrontArrowIcon />
          </div>
        )}
      </div>
    </button>
  );
};

export default DelegateAcceptButton;
