import { createContext, useContext } from 'react';
import { CLAIM_STEP } from '../../utils/enums';

interface ContractContextProps {
  contract: any;
  isLoading: boolean;
  refresh: Function;
  delegatedToAddress: string;
  pageState: CLAIM_STEP;
  setPageState: Function;
}

export const ContractContext = createContext<ContractContextProps>(undefined);
ContractContext.displayName = 'ContractContext';

export const useContract = (): ContractContextProps => {
  return useContext(ContractContext);
};
