import {
  ButtonRow,
  Error,
  PageHeader,
  UploadedDoc,
} from '@tokensoft-web/common-ui';
import {
  QUALIFICATION_METHOD,
  TIER_ID,
  isEntity,
  useAccount,
  useAnalytics,
  useCreateAccountKycTier,
  useUpdateAccount,
} from '@tokensoft-web/common-utils';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AdditionalInformationPanel } from '../additional-information-panel';
import {
  DOCUMENT_UPLOAD_TYPE,
  DocumentUploadPanel,
} from '../document-upload-panel';
import { AccreditationHistoryPanel } from './accreditation-history-panel';
import { AccreditationQualificationsPanel } from './accreditation-qualifications-panel';

export const AccreditationForm = () => {
  useAnalytics('/account/accreditation-form');
  const { account } = useAccount();

  const {
    mutate: createAccountKycTier,
    isPending: createAccountKycTierLoading,
  } = useCreateAccountKycTier();

  const {
    mutate: updateAccount,
    isPending: updateAccountLoading,
    isSuccess,
  } = useUpdateAccount();

  const [formValues, setFormValues] = useState({
    qualificationMethod: isEntity(account)
      ? QUALIFICATION_METHOD.ENTITY_NET_WORTH
      : QUALIFICATION_METHOD.NET_WORTH,
    notes: '',
  });
  const [docsUploaded, setDocsUploaded] = useState<UploadedDoc[]>([]);

  const documentIds = docsUploaded.map(({ documentId }) => ({
    id: documentId,
  }));

  const isLoading = createAccountKycTierLoading || updateAccountLoading;
  const disableSubmit =
    isLoading ||
    (formValues.qualificationMethod !== QUALIFICATION_METHOD.NONE &&
      !documentIds.length);

  const handleSubmit = async () => {
    if (formValues.qualificationMethod === QUALIFICATION_METHOD.NONE) {
      await updateAccount({
        id: account.id,
        authId: account.authId,
        qualificationMethod: formValues.qualificationMethod,
      });
    } else {
      createAccountKycTier({
        account: {
          id: account.id,
          qualification_method: formValues.qualificationMethod,
        },
        kycTier: { id: TIER_ID.ACCREDITATION_KYC_TIER_ID },
        message:
          formValues.notes ||
          'Accreditation documentation submitted for review',
        documents: documentIds,
      });
    }
  };

  return isSuccess ? (
    <Error
      title={"We're sorry!"}
      message={
        'You are not eligible for accreditation, but you may re-apply at any time.'
      }
    />
  ) : (
    <>
      <PageHeader title={'Accreditation (US)'} />

      <div data-test-id='profile' className='grid gap-6 grid-cols-1'>
        <AccreditationHistoryPanel
          account={account}
          qualificationMethod={formValues.qualificationMethod}
          onClick={(qualificationMethod) =>
            setFormValues((prev) => ({
              ...prev,
              qualificationMethod,
            }))
          }
        />
        <AccreditationQualificationsPanel
          account={account}
          qualificationMethod={formValues.qualificationMethod}
          onClick={(qualificationMethod) =>
            setFormValues((prev) => ({
              ...prev,
              qualificationMethod,
            }))
          }
        />
        {formValues.qualificationMethod === QUALIFICATION_METHOD.NONE ? null : (
          <>
            <DocumentUploadPanel
              type={DOCUMENT_UPLOAD_TYPE.ACCREDITATION}
              qualificationMethod={formValues.qualificationMethod}
              onFileChange={setDocsUploaded}
              docsUploaded={docsUploaded}
            />
            <AdditionalInformationPanel
              value={formValues.notes}
              onChange={(e) => {
                setFormValues((prev) => ({
                  ...prev,
                  notes: e.target.value,
                }));
              }}
            />
          </>
        )}

        <ButtonRow place={'between'}>
          <Link className='btn btn-outline-dark' to='/account'>
            Cancel
          </Link>
          <button
            className='btn btn-primary'
            disabled={disableSubmit}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </ButtonRow>
      </div>
    </>
  );
};
