import { useEffect } from 'react';
import { PaymentMethod } from '../utils/interface';
import { useCheckTransferAllowance } from '../utils/sale';

interface erc20PurchaseProps {
  connectedChainId: string;
  paymentMethod: PaymentMethod;
  walletAddress: string;
  saleId: string;
  value: string;
  onAllowanceCheck: Function;
}

const Erc20Purchase = ({
  connectedChainId,
  paymentMethod,
  walletAddress,
  saleId,
  value,
  onAllowanceCheck,
}: erc20PurchaseProps) => {
  if (!paymentMethod.address) {
    return;
  }

  const { hasEnoughAllowance } = useCheckTransferAllowance(
    connectedChainId,
    paymentMethod,
    walletAddress,
    saleId,
    value,
  );

  useEffect(() => {
    onAllowanceCheck(hasEnoughAllowance);
  }, [hasEnoughAllowance]);

  return <></>;
};

export default Erc20Purchase;
