import classNames from 'classnames';
import { Text } from './text';

export const FormLabel = ({ className = '', children, ...restProps }) => {
  return (
    <div className='py-1.5 mb-0'>
      <Text
        className={classNames(
          'form-label text-sm font-semibold high-contrast',
          className,
        )}
        {...restProps}
      >
        {children}
      </Text>
    </div>
  );
};
