import { Col, Stacked } from '@tokensoft-web/common-ui';
import { FEATURE, useAccount, useAuth } from '@tokensoft-web/common-utils';
import { useDistributor } from '../../contexts/distributor/distributor-context';
import { NULL_TRANSFER_ID } from '../../utils/constant';
import { isSmartContractWallet } from '../../utils/wallet';
import EventBodyWrapper from '../event/event-body-wrapper';
import EventHeader from '../event/event-header';
import EventHeaderWrapper from '../event/event-header-wrapper';
import ClaimAvailability from './claim-availability';
import ClaimFooter from './claim-footer';
import ClaimHistory from './claim-history';
import ClaimVestingDetails from './claim-vesting-details';
import ConnextClaimProgressAlert from './connext/connext-claim-progress-alert';
import ConnextClaimSuccessBanner from './connext/connext-claim-success-banner';
import ConnextDetailsClaimedHeaderText from './connext/connext-details-completed-header-text';
import ConnextDetailsHeaderText from './connext/connext-details-header-text';
import ConnextHeader from './connext/connext-header';
import DistributorDisclaimers from './distributor-disclaimers';
import DistributorDocuments from './distributor-documents';

const ClaimDetails = () => {
  const { event, distributor, completedTransaction } = useDistributor();
  const {
    user: { walletAddress },
  } = useAuth();
  const { account } = useAccount();

  const connextLayout = !!event?.features?.includes(
    FEATURE.CLAIM_CONNEXT_LAYOUT,
  );

  const contentHeaderBgColor = event?.contentHeaderBgColor;
  const contentHeaderImage = event?.contentHeaderImage;
  const contentHeaderFontColor = event?.contentHeaderFontColor;
  const contentHeaderTitle = event?.contentHeaderTitle;
  const contentHeaderBgImage = event?.contentHeaderBgImage;
  const themeLayoutVersion = event?.themeLayoutVersion;

  const renderClaimHeader = () => {
    if (connextLayout) {
      const isSmartContract = isSmartContractWallet(
        account?.wallets,
        walletAddress,
      );
      return (
        <>
          <ConnextHeader
            subText={
              completedTransaction !== null
                ? 'Claiming is Now Open!'
                : 'Step 2: Begin Claiming'
            }
          />

          <EventBodyWrapper>
            {renderClaimSuccessBanner()}

            {completedTransaction !== null ? (
              <ConnextDetailsClaimedHeaderText />
            ) : (
              <ConnextDetailsHeaderText isSmartContract={isSmartContract} />
            )}

            {renderClaimProgressAlert()}
          </EventBodyWrapper>
        </>
      );
    }

    return (
      <EventHeaderWrapper
        backgroundColor={contentHeaderBgColor}
        backgroundImg={contentHeaderBgImage}
      >
        <EventHeader
          headerTitle={contentHeaderTitle || distributor?.name}
          headerSubtitle={`You're now able to claim your ${distributor.tokenSymbol} tokens.`}
          contentHeaderImage={contentHeaderImage}
          contentHeaderFontColor={contentHeaderFontColor}
          themeLayoutVersion={themeLayoutVersion}
        />
      </EventHeaderWrapper>
    );
  };

  const renderClaimSuccessBanner = () => {
    if (completedTransaction === null) {
      return null;
    }

    return <ConnextClaimSuccessBanner tokenName={distributor.tokenSymbol} />;
  };

  const renderClaimProgressAlert = () => {
    if (completedTransaction === null) {
      return null;
    }

    if (completedTransaction?.transferId === NULL_TRANSFER_ID) {
      return null;
    }

    return (
      <ConnextClaimProgressAlert
        txHash={completedTransaction?.transactionHash}
      />
    );
  };

  return (
    <Stacked>
      {renderClaimHeader()}

      <EventBodyWrapper>
        <ClaimAvailability allowClaiming={true} />

        <Col gap={4}>
          {/*<div className="flex flex-row mt-4">*/}
          {/*  <span className='text-sm italic'>*Disclaimer: You must first claim your available tokens to participate in governance.</span>*/}
          {/*</div>*/}

          <ClaimVestingDetails />

          <ClaimHistory />

          <DistributorDocuments />

          <DistributorDisclaimers />

          <ClaimFooter />
        </Col>
      </EventBodyWrapper>
    </Stacked>
  );
};

export default ClaimDetails;
