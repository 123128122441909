import {
  Error,
  LoadingIndicator,
  SwitchChainWarning,
} from '@tokensoft-web/common-ui';
import {
  useGetContract,
  useNetworks,
  useWallet,
} from '@tokensoft-web/common-utils';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContractContext } from './contract-context';

interface ContractProviderProps {
  children?: ReactNode;
}

export const ContractProvider: FC<ContractProviderProps> = ({ children }) => {
  const { contractId } = useParams();
  const { getNetworkDetails } = useNetworks();
  const { connectedChainId, provider } = useWallet();
  const [pageState, setPageState] = useState(null);
  const {
    contract: contract,
    loading: loading,
    refresh: refresh,
  } = useGetContract(contractId);

  const correctNetwork = connectedChainId === contract?.networkId;

  const [delegatedToAddress, setDelegatedToAddress] = useState(null);

  useEffect(() => {
    if (contract) {
      setDelegatedToAddress(contract?.userContractMetadata?.delegateAddress);
    }
  }, [contract]);

  if (loading) {
    return <LoadingIndicator text='Loading Contract' />;
  }

  if (!loading && !contract) {
    return (
      <Error
        title='Contract Not Found'
        message="It looks like this isn't a valid Contract."
      />
    );
  }

  if (!correctNetwork) {
    const correctNetworkDetails = getNetworkDetails(contract?.networkId);
    return (
      <SwitchChainWarning
        chainId={correctNetworkDetails.chainId}
        subtitle={`Please connect to the ${correctNetworkDetails.name} network to continue.`}
      />
    );
  }

  if (!contract.interfaces?.length) {
    return (
      <Error title="We're sorry!" message='Contract setup is not complete.' />
    );
  }

  return (
    <ContractContext.Provider
      value={{
        contract: contract,
        isLoading: loading,
        refresh: refresh,
        delegatedToAddress: delegatedToAddress,
        pageState: pageState,
        setPageState: setPageState,
      }}
    >
      {children}
    </ContractContext.Provider>
  );
};
