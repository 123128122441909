import { useAccountApiClient, useToast } from '@tokensoft-web/common-utils';
import { FC } from 'react';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import { MdHighlightOff } from 'react-icons/md';
import './file-upload.css';

const Preview = ({ meta, fileWithMeta: { remove } }) => {
  return (
    <div className='w-full flex items-center justify-between'>
      <div className='flex items-center gap-2 pb-4'>
        <img className='dzu-previewImage' src={meta.previewUrl} />
        <span>{meta.name}</span>
      </div>
      <MdHighlightOff
        style={{ justifySelf: 'end' }}
        className='cursor-pointer'
        color='var(--primary)'
        onClick={remove}
      />
    </div>
  );
};

export interface UploadedDoc {
  id: string;
  documentId: string;
}

interface FileUploadProps {
  maxFiles?: number;
  maxSizeBytes?: number;
  uploadUrl: string;
  fileTypes?: string;
  onFileChange: Function;
  docsUploaded: UploadedDoc[];
}

export const FileUpload: FC<FileUploadProps> = ({
  maxFiles = 10,
  maxSizeBytes = 10 * 1000000, // 10 MB
  uploadUrl,
  fileTypes = 'image/*,application/pdf',
  onFileChange, // parent docsUploaded state setter
  docsUploaded, // parent slice of state
}) => {
  const client = useAccountApiClient();
  const { showErrorToast } = useToast();

  const handleChangeStatus = async ({ meta, file, remove }, status) => {
    if (status === 'done') {
      try {
        const data = new FormData();
        data.append('file', file, file.name);

        const res = await client(uploadUrl, {
          headers: { 'Content-Type': 'multipart/form-data' },
          data,
        });

        onFileChange((prev) => [...prev, { id: meta.id, documentId: res.id }]);
      } catch (error) {
        showErrorToast({ description: error.message });
        remove();
      }
    }

    if (status === 'error_file_size') {
      showErrorToast({
        description: `File too large - the maximum file size is ${
          maxSizeBytes / 1000000
        } MB`,
      });
      remove();
    }

    if (status === 'removed') {
      const { documentId } =
        docsUploaded.find((doc) => doc.id === meta.id) || {};
      if (documentId) {
        try {
          await client(`accounts/documents/${documentId}`, {
            method: 'delete',
          });

          onFileChange((prev) => prev.filter((doc) => doc.id !== meta.id));
        } catch (error) {
          showErrorToast({ description: error.message });
        }
      }
    }
  };

  return (
    <Dropzone
      autoUpload={false}
      onChangeStatus={handleChangeStatus}
      accept={fileTypes}
      maxFiles={maxFiles}
      maxSizeBytes={maxSizeBytes}
      inputContent='Drop files here to upload'
      inputWithFilesContent='Drop files here to upload'
      styles={{
        dropzone: { padding: '1.8rem 1.8rem 0' },
        inputLabel: {
          fontFamily: 'var(--font-family-base)',
          color: 'var(--primary)',
        },
        inputLabelWithFiles: {
          backgroundColor: 'var(--primary)',
          color: '#fff',
          border: '1px solid var(--primary)',
          borderRadius: '0.375rem',
          margin: '2rem auto',
          padding: '0.938rem 1.5rem',
          fontSize: '1rem',
          fontWeight: '400',
          lineHeight: '1.5',
          fontFamily: 'var(--font-family-base)',
        },
      }}
      PreviewComponent={Preview}
    />
  );
};
