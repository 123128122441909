import { CountriesData } from '../type/countries';

class CountryList {
  private data;
  private labelMap;
  private valueMap;

  constructor() {
    this.data = require('./country-list-data.json');
    this.labelMap = {};
    this.valueMap = {};

    this.data.forEach((country) => {
      this.labelMap[country.label.toLowerCase()] = country.value;
      this.valueMap[country.value.toLowerCase()] = country.label;
    });
  }

  getValue(label) {
    return this.labelMap[label.toLowerCase()];
  }

  getLabel(value) {
    return this.valueMap[value.toLowerCase()];
  }

  getLabels() {
    return this.data.map((country) => country.label);
  }

  getValues(): string[] {
    return this.data.map((country) => country.value);
  }

  getLabelList() {
    return this.labelMap;
  }

  getValueList() {
    return this.valueMap;
  }

  getData() {
    return this.data;
  }

  getInverseList(input: CountriesData[]): CountriesData[] {
    if (!input || !input.length) {
      return this.data;
    }
    return this.data.filter(
      ({ value: id1 }) => !input.some(({ value: id2 }) => id2 === id1),
    );
  }

  setLabel(value: string, label: string) {
    this.data.forEach((country) => {
      if (country.value === value) {
        country.label = label;
        this.valueMap[country.value.toLowerCase()] = country.label;
      }
    });

    return this;
  }

  setEmpty(label) {
    this.data.unshift({
      value: '',
      label: label,
    });
    this.valueMap[''] = label;
    this.labelMap[label] = '';

    return this;
  }
}

export const countryList = () => {
  return new CountryList();
};
