import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useDistributorApiClient } from '../client/distributor-api-client';
import { useEventApiClient } from '../client/event-api-client';
import { useAuth } from '../context/auth/auth-context';
import { useConfiguration } from '../context/configuration/configuration-context';
import { useNetworks } from '../context/network/network-context';
import { useAsync } from '../hook/async';
import { uniqueValues } from '../util/object';

export const useGetClaims = (
  beneficiary?: string,
  chainId?: number,
  eventId?: string,
  projectId?: string,
) => {
  const {
    user: { walletAddress },
  } = useAuth();
  const { supportedNetworks } = useNetworks();
  const client = useDistributorApiClient();
  const { configuration } = useConfiguration();
  const { run, data } = useAsync();
  const [searchResults, setSearchResults] = useState([]);
  const [moreResults, setMoreResults] = useState(null);
  const [loadingComplete, setLoadingComplete] = useState([]);
  const [reload, setReload] = useState(null);

  const PAGE_SIZE: number = 10;

  /**
   * Build API request
   */
  const getClient = (request: any) => {
    let url = 'claims/search';

    if (request.nextLink) {
      url += '?' + request.nextLink.split('?')[1];
    } else {
      url += `?offset=0&queryLimit=${PAGE_SIZE}`;
    }

    return client(url, {
      method: 'post',
      data: request.searchRequest,
    });
  };

  /**
   * Iterate over each network and execute query
   */
  useEffect(() => {
    if (!reload) {
      return;
    }

    const _beneficiary = beneficiary
      ? beneficiary.toLowerCase()
      : walletAddress.toLowerCase();

    if (chainId && eventId) {
      // Only run for one chain
      run(
        getClient({
          searchRequest: {
            beneficiary: _beneficiary,
            chainId,
            eventId,
            projectId: projectId || configuration?.project?.id,
          },
        }),
      );
    } else {
      for (let i = 0; i < supportedNetworks.length; i++) {
        const network = supportedNetworks[i];

        /* Setup default query parameters */
        run(
          getClient({
            searchRequest: {
              beneficiary: _beneficiary,
              chainId: network.chainId,
              projectId: projectId || configuration?.project?.id,
              eventId,
            },
          }),
        );
      }
    }
  }, [reload]);

  /**
   * Accumulate data and trigger fetching of additional pages (if available)
   */
  useEffect(() => {
    if (!data) {
      return;
    }

    if (data?.claims?.length > 0) {
      setSearchResults(uniqueValues(searchResults.concat(data.claims)));

      if (data.nextLink) {
        setMoreResults(data);
      } else {
        setLoadingComplete(
          loadingComplete.concat([data.searchRequest.chainId]),
        );
      }
    } else {
      setLoadingComplete(loadingComplete.concat([data.searchRequest.chainId]));
    }
  }, [data]);

  /**
   * Fetch additional page of data
   */
  useEffect(() => {
    if (moreResults) {
      run(getClient(moreResults));
    }
  }, [moreResults]);

  const refresh = () => {
    setSearchResults([]);
    setMoreResults(null);
    setLoadingComplete([]);
    setReload(new Date());
  };

  useEffect(() => {
    if (configuration && walletAddress) {
      refresh();
    }
  }, [configuration, walletAddress]);

  const loading = chainId
    ? loadingComplete.length !== 1
    : loadingComplete.length !== supportedNetworks.length;

  return { results: searchResults, loading: loading, refresh: refresh };
};

export const useGetEventClaimsByWalletAddress = (
  eventId: string,
  enabled: boolean,
) => {
  const client = useDistributorApiClient();

  const result = useQuery({
    enabled: enabled,
    queryKey: ['eventClaimsByWalletAddress'],
    queryFn: () => client(`claims/event/${eventId}`),
    refetchInterval: 2000,
  });

  return result;
};

export const useGetAdminEventClaims = (eventId: string) => {
  const client = useDistributorApiClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`admin/claims/event/${eventId}`, {
        method: 'post',
        data,
      });
    },
  });
};

export const useClaimsCompleted = () => {
  const client = useDistributorApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => {
      return client(`claims/completed`, {
        method: 'post',
        data: data,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['claims'] });
    },
  });
};

export const useClaimsFailed = () => {
  const client = useDistributorApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => {
      return client(`claims/failed`, {
        method: 'post',
        data: data,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['claims'] });
    },
  });
};

export const useClaimsDelegateSet = () => {
  const client = useDistributorApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => {
      return client(`claims/delegate-set`, {
        method: 'post',
        data: data,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['claims'] });
    },
  });
};

export const useClaimSigninMessagePrompt = (
  eventId: string,
  enabled: boolean,
) => {
  const client = useEventApiClient();

  return useQuery({
    enabled: !!(eventId && enabled),
    queryKey: ['eventId', eventId],
    queryFn: () => client(`events/${eventId}/signature`),
  });
};

export const useClaimSignedMessage = (eventId: string) => {
  const client = useEventApiClient();

  return useMutation({
    mutationFn: (data) => {
      return client(`events/${eventId}/signed-signature`, {
        method: 'post',
        data: data,
      });
    },
  });
};

export const useSubmitRelayerClaim = () => {
  const client = useDistributorApiClient();

  return useMutation({
    mutationFn: (data) => {
      return client(`relayer/relay`, {
        method: 'post',
        data: data,
        refetchOnWindowFocus: false,
      });
    },
  });
};

export const useGetRelayerClaimStatus = (
  enabled: boolean,
  refetchInterval: number = 1000,
) => {
  const client = useDistributorApiClient();

  return useQuery({
    enabled,
    queryKey: ['relayerClaim'],
    queryFn: () => client(`relayer/relay`),
    refetchInterval: refetchInterval,
  });
};
