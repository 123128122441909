import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  braveWallet,
  injectedWallet,
  metaMaskWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { FC, ReactNode, useEffect, useState } from 'react';
import {
  WagmiProvider as RawWagmiProvider,
  createConfig,
  fallback,
  http,
} from 'wagmi';
import {
  Chain,
  arbitrum,
  avalanche,
  avalancheFuji,
  base,
  baseSepolia,
  bsc,
  bscTestnet,
  celoAlfajores,
  gnosis,
  mainnet,
  optimism,
  optimismSepolia,
  polygon,
  polygonAmoy,
  polygonMumbai,
  sepolia,
  zkSync,
  zkSyncSepoliaTestnet,
} from 'wagmi/chains';
import { NETWORK_NAME } from '../../enum/network-name';
import { useConfiguration } from '../configuration/configuration-context';
import { useNetworks } from '../network/network-context';
import { WagmiContext } from './wagmi-context';
import { wagmiTransportUrls } from './wagmi-transport-urls';

export interface WagmiProviderProps {
  children?: ReactNode;
}

export const WagmiProvider: FC<WagmiProviderProps> = ({ children }) => {
  const { loading, supportedNetworks } = useNetworks();
  const [wagmiConfig, setWagmiConfig] = useState(null);
  const [activeChains, setActiveChains] = useState(null);
  const [defaultChain, setDefaultChain] = useState(null);
  const { configuration } = useConfiguration();

  const updateDefaultChain = (chainId) => {
    setDefaultChain(activeChains.find((c) => c.id === chainId));
  };

  const getSupportedChains = (): Readonly<[Chain, ...Chain[]]> => {
    if (!supportedNetworks) {
      return process.env.REACT_APP_PROCESS_ENV === 'production'
        ? [mainnet]
        : [sepolia];
    }

    let sn = supportedNetworks?.map((network) => {
      switch (network.name) {
        case NETWORK_NAME.ETHEREUM:
          return mainnet;
        case NETWORK_NAME.AVALANCHE:
          return avalanche;
        case NETWORK_NAME.POLYGON:
          return polygon;
        case NETWORK_NAME.POLYGON_AMOY:
          return polygonAmoy;
        case NETWORK_NAME.SEPOLIA:
          return sepolia;
        case NETWORK_NAME.FUJI:
          return avalancheFuji;
        case NETWORK_NAME.MUMBAI:
          return {
            ...polygonMumbai,
            rpcUrls: {
              public: { http: ['https://rpc-mumbai.maticvigil.com/'] },
              default: { http: ['https://rpc-mumbai.maticvigil.com/'] },
            },
          };
        case NETWORK_NAME.OPTIMISM:
          return optimism;
        case NETWORK_NAME.ARBITRUM:
          return arbitrum;
        case NETWORK_NAME.BINANCE:
          return bsc;
        case NETWORK_NAME.BSC_TESTNET:
          return bscTestnet;
        case NETWORK_NAME.BASE:
          return base;
        case NETWORK_NAME.BASE_SEPOLIA:
          return baseSepolia;
        case NETWORK_NAME.GNOSIS:
          return gnosis;
        case NETWORK_NAME.CELO_ALFAJORES:
          return {
            id: 44787,
            name: 'Alfajores',
            network: 'alfajores',
            nativeCurrency: {
              name: 'Goerli CELO',
              symbol: 'ETH',
              decimals: 18,
            },
            rpcUrls: {
              default: { http: ['https://alfajores-forno.celo-testnet.org'] },
              public: { http: ['https://alfajores-forno.celo-testnet.org'] },
            },
            blockExplorers: {
              default: {
                name: 'alfajores',
                url: 'https://alfajores-blockscout.celo-testnet.org',
              },
            },
            testnet: true,
          };
        case NETWORK_NAME.ZKSYNC:
          return zkSync;
        case NETWORK_NAME.ZKSYNC_SEPOLIA:
          return zkSyncSepoliaTestnet;
        default:
          break;
      }
    });
    sn = sn.filter((element) => element !== undefined);
    return sn;
  };

  useEffect(() => {
    if (activeChains) {
      // first, check to see if the user is connected to a supported network
      const connectedNetworkId = window.ethereum?.networkVersion;
      if (connectedNetworkId) {
        console.log(
          'wallet provider already connected to chain id...',
          connectedNetworkId,
        );
        const foundChain = activeChains.find(
          (c) => c.id === Number(connectedNetworkId),
        );
        if (foundChain) {
          return setDefaultChain(foundChain);
        }
      }

      // next, see if the project has a default network configured
      const configDefaultNetwork = configuration?.project?.networkId;
      if (configDefaultNetwork) {
        const foundChain = activeChains.find(
          (c) => c.id === Number(configDefaultNetwork),
        );
        if (foundChain) {
          return setDefaultChain(foundChain);
        }
      }

      // as a last resort, fallback to the environment default
      if (process.env.REACT_APP_PROCESS_ENV === 'production') {
        setDefaultChain(activeChains.find((c) => c.id === mainnet.id));
      } else {
        setDefaultChain(activeChains.find((c) => c.id === sepolia.id));
      }
    }
  }, [activeChains, configuration]);

  useEffect(() => {
    if (!loading) {
      const supportedChains = getSupportedChains();

      const connectors = connectorsForWallets(
        [
          {
            groupName: 'Recommended',
            wallets: [
              metaMaskWallet,
              walletConnectWallet,
              injectedWallet,
              braveWallet,
            ],
          },
        ],
        {
          appName: 'Tokensoft',
          projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
        },
      );

      const wagmiConfig = createConfig({
        chains: supportedChains,
        connectors,
        transports: {
          [mainnet.id]: fallback([
            ...wagmiTransportUrls[mainnet.id].map((url) => http(url)),
            http(),
          ]),
          [sepolia.id]: fallback([
            ...wagmiTransportUrls[sepolia.id].map((url) => http(url)),
            http(),
          ]),
          [polygon.id]: fallback([
            ...wagmiTransportUrls[polygon.id].map((url) => http(url)),
            http(),
          ]),
          [polygonMumbai.id]: fallback([
            ...wagmiTransportUrls[polygonMumbai.id].map((url) => http(url)),
            http(),
          ]),
          [polygonAmoy.id]: fallback([
            ...wagmiTransportUrls[polygonAmoy.id].map((url) => http(url)),
            http(),
          ]),
          [optimism.id]: fallback([
            ...wagmiTransportUrls[optimism.id].map((url) => http(url)),
            http(),
          ]),
          [optimismSepolia.id]: fallback([
            ...wagmiTransportUrls[optimismSepolia.id].map((url) => http(url)),
            http(),
          ]),
          [arbitrum.id]: fallback([
            ...wagmiTransportUrls[arbitrum.id].map((url) => http(url)),
            http(),
          ]),
          [base.id]: fallback([
            ...wagmiTransportUrls[base.id].map((url) => http(url)),
            http(),
          ]),
          [baseSepolia.id]: fallback([
            ...wagmiTransportUrls[baseSepolia.id].map((url) => http(url)),
            http(),
          ]),
          [bsc.id]: fallback([
            ...wagmiTransportUrls[bsc.id].map((url) => http(url)),
            http(),
          ]),
          [bscTestnet.id]: fallback([
            ...wagmiTransportUrls[bscTestnet.id].map((url) => http(url)),
            http(),
          ]),
          [avalanche.id]: fallback([
            ...wagmiTransportUrls[avalanche.id].map((url) => http(url)),
            http(),
          ]),
          [avalancheFuji.id]: fallback([
            ...wagmiTransportUrls[avalancheFuji.id].map((url) => http(url)),
            http(),
          ]),
          [gnosis.id]: fallback([
            ...wagmiTransportUrls[gnosis.id].map((url) => http(url)),
            http(),
          ]),
          [celoAlfajores.id]: fallback([
            ...wagmiTransportUrls[celoAlfajores.id].map((url) => http(url)),
            http(),
          ]),
          [zkSync.id]: fallback([
            ...wagmiTransportUrls[zkSync.id].map((url) => http(url)),
            http(),
          ]),
          [zkSyncSepoliaTestnet.id]: fallback([
            ...wagmiTransportUrls[zkSyncSepoliaTestnet.id].map((url) =>
              http(url),
            ),
            http(),
          ]),
        },
      });

      setWagmiConfig(wagmiConfig);
      setActiveChains(supportedChains);
    }
  }, [loading]);

  if (!wagmiConfig) {
    return <></>;
  }

  return (
    <>
      <WagmiContext.Provider
        value={{
          activeChains: activeChains,
          defaultChain: defaultChain,
          updateDefaultChain: updateDefaultChain,
          wagmiConfig: wagmiConfig,
        }}
      >
        <RawWagmiProvider config={wagmiConfig}>{children}</RawWagmiProvider>
      </WagmiContext.Provider>
    </>
  );
};
