import {
  Col,
  EventSubtitle,
  EventTitle,
  Stretched,
} from '@tokensoft-web/common-ui';
import {
  DEFAULT_EVENT_HEADER_FONT_COLOR,
  getResourceUri,
  getValidIPFSHash,
} from '@tokensoft-web/common-utils';
import { LayoutType } from '../../utils/enums';

interface EventHeaderProps {
  headerTitle?: string;
  headerSubtitle?: string;
  contentHeaderImage?: string;
  contentHeaderFontColor?: string;
  themeLayoutVersion?: string;
}

const EventHeader = ({
  headerTitle,
  headerSubtitle,
  contentHeaderImage,
  contentHeaderFontColor,
  themeLayoutVersion,
}: EventHeaderProps) => {
  const headerImage = getValidIPFSHash(contentHeaderImage)
    ? getResourceUri(contentHeaderImage)
    : contentHeaderImage;
  const _contentHeaderFontColor =
    contentHeaderFontColor || DEFAULT_EVENT_HEADER_FONT_COLOR;
  let title = {} as any;
  let icon = {} as any;

  switch (themeLayoutVersion) {
    case LayoutType.TITLE_ICON_50_50: {
      title = {
        show: true,
        className: 'event-banner-text-container w-full',
        xalign: 'left',
      };
      icon = {
        show: true,
        className: 'event-banner-logo-container max-h-64 w-96',
        width: 'fit',
        imgClassName: 'event-banner-logo',
      };
      break;
    }
    case LayoutType.TITLE_ICON_50_50_REVERSED: {
      title = {
        show: true,
        className: 'event-banner-text-container order-2',
        xalign: 'right',
      };
      icon = {
        show: true,
        className: ' max-h-64 w-96 order-1',
        width: 'fit',
        imgClassName: 'event-banner-logo',
      };
      break;
    }
    case LayoutType.ICON_100: {
      title = {
        show: false,
        className: '',
        xalign: '',
      };
      icon = {
        show: true,
        className: 'event-banner-logo-container max-h-64',
        width: 'full',
        imgClassName: 'h-full',
      };
      break;
    }
    case LayoutType.TITLE_100: {
      title = {
        show: true,
        className: 'event-banner-text-container',
        xalign: 'center',
      };
      icon = {
        show: false,
        className: '',
        width: '',
        imgClassName: '',
      };
      break;
    }
    default: {
      title = {
        show: true,
        className: 'event-banner-text-container w-full',
        xalign: 'left',
      };
      icon = {
        show: true,
        className: 'event-banner-logo-container max-h-64 w-96',
        width: 'fit',
        imgClassName: 'event-banner-logo',
      };
      break;
    }
  }

  return (
    <Stretched>
      {title.show ? (
        <Col
          gap={2}
          yalign='center'
          className={title.className}
          style={{ color: _contentHeaderFontColor }}
        >
          <EventTitle textAlign={title.xalign}>{headerTitle}</EventTitle>
          <EventSubtitle textAlign={title.xalign}>
            {headerSubtitle}
          </EventSubtitle>
        </Col>
      ) : null}
      {icon.show ? (
        <Col
          width={`${icon.width}`}
          className={icon.className}
          data-testid={'event-banner-logo-container'}
          place={'center'}
        >
          <img className={icon.imgClassName} src={headerImage} />
        </Col>
      ) : null}
    </Stretched>
  );
};

export default EventHeader;
