import { FC, ReactNode } from 'react';
import { useGetConfiguration } from '../../service/configuration-service';
import { ConfigurationContext } from './configuration-context';

interface ConfigurationProviderProps {
  children?: ReactNode;
}

export const ConfigurationProvider: FC<ConfigurationProviderProps> = ({
  children,
}) => {
  const { configuration, loading } = useGetConfiguration();

  if (loading) {
    return <></>;
  }

  return (
    <>
      <ConfigurationContext.Provider
        value={{
          configuration: configuration,
          loading: loading,
        }}
      >
        {children}
      </ConfigurationContext.Provider>
    </>
  );
};
