import { ReactNode, createContext, useContext } from 'react';

export interface Modal {
  content: ReactNode;
  onClose: Function;
  isOpen?: boolean;
  className?: string;
  disableClose?: boolean;
}

export interface ModalContextProps {
  modal: Modal;
  setModal(modal?: Modal): void;
  showModal: Function;
  closeModal: Function;
}

export const ModalContext = createContext(undefined);
ModalContext.displayName = 'ModalContext';

export const useModal = (): ModalContextProps => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error(
      'ModalProvider context is undefined, please verify you are calling useModal() as child of a <ModalProvider> component.',
    );
  }

  return context;
};
