import classNames from 'classnames';
import { ReactNode } from 'react';
import { Col } from '../layout/container/col';
import { Row } from '../layout/container/row';
import { CardTitle } from '../typography/card-title';
import { Text } from '../typography/text';
import './card.css';

interface CardProps {
  id?: string;
  title?: string;
  subtitle?: string;
  className?: any;
  children?: ReactNode;
  actions?: ReactNode;
  footer?: ReactNode;
  width?: string;
  onClick?: Function;
}

export const Card = ({
  id,
  title,
  subtitle,
  className,
  children,
  width = 'full',
  actions = <></>,
  footer,
  onClick,
}: CardProps) => {
  const key = id || 'card';

  return (
    <Col
      width={width}
      data-testid={key}
      onClick={onClick}
      className={classNames('card', className, onClick && 'cursor-pointer')}
    >
      {title ? (
        <>
          <Row className='card-header' data-testid={`${key}-header`}>
            <div>
              <CardTitle data-testid={`${key}-title`}>{title}</CardTitle>
              {subtitle ? (
                <Text data-testid={`${key}-subtitle`}>{subtitle}</Text>
              ) : null}
            </div>
            {actions}
          </Row>
        </>
      ) : null}

      <div data-testid={`${key}-body`} className='card-body'>
        {children}
      </div>

      {footer ? (
        <div className='card-footer' data-testid={`${key}-footer`}>
          {footer}
        </div>
      ) : null}
    </Col>
  );
};
