import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import { CollapseIcon } from '../icon/collapse-icon';
import { ExpandIcon } from '../icon/expand-icon';
import { Row } from '../layout/container/row';
import { Text } from './text';

type CardTitleProps = {
  className?: string;
  open?: boolean;
  onOpen?: Function;
  children?: ReactNode;
  [restProps: string]: any;
};

export const CardTitle = ({
  className = '',
  open,
  onOpen,
  children,
  ...restProps
}: CardTitleProps) => {
  const [isOpen, setIsOpen] = useState(open);

  const toggleOpen = () => {
    let openClose = !isOpen;
    setIsOpen(openClose);
    onOpen(openClose);
  };

  if (onOpen) {
    return (
      <Row
        nowrap
        gap={2}
        yalign={'center'}
        className='cursor-pointer'
        onClick={toggleOpen}
      >
        {isOpen ? <ExpandIcon /> : <CollapseIcon />}
        <Text
          className={classNames('text-lg font-bold high-contrast', className)}
          {...restProps}
        >
          {children}
        </Text>
      </Row>
    );
  }

  return (
    <Text
      className={classNames('text-lg font-bold high-contrast', className)}
      {...restProps}
    >
      {children}
    </Text>
  );
};
