import classNames from 'classnames';
import { ChangeEventHandler, FC } from 'react';
import { Row } from '../../layout/container/row';
import './checkbox-input.css';

type CheckboxInputProps = {
  children?: any;
  name: string;
  checked?: boolean;
  disabled?: boolean;
  className: string;
  /** Intentionally using ChangeEventHandler here */
  onClick: ChangeEventHandler<HTMLInputElement>;
};

export const CheckboxInput: FC<CheckboxInputProps> = ({
  children,
  name,
  checked,
  disabled,
  className,
  onClick,
}) => {
  return (
    <Row
      yalign={'start'}
      nowrap
      className={classNames('pb-2 block w-auto', className)}
    >
      <label>
        <div className='mr-3'>
          {/* onChange must be set to silence warnings; we're using onClick from the Label, as for="id" doesn't work. */}
          <input
            className={classNames(
              'form-check-input',
              disabled ? 'cursor-default' : 'cursor-pointer',
            )}
            type='checkbox'
            name={name}
            checked={checked}
            disabled={disabled}
            onChange={onClick}
          />
        </div>
      </label>
      <div>{children}</div>
    </Row>
  );
};
