import { createContext, useContext } from 'react';
import { User } from '../../type/user';

export interface AuthContextProps {
  user: User;
  error: boolean;
  authenticate: Function;
  removeWalletAddress: Function;
  disconnect(redirectTo?: string): void;
  isAuthenticated: boolean;
}

export const AuthContext = createContext<AuthContextProps | undefined>(
  undefined,
);
AuthContext.displayName = 'AuthContext';

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(
      'AuthProvider context is undefined, please verify you are calling useAuth() as child of a <AuthProvider> component.',
    );
  }

  return context;
};
