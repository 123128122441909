import {
  ButtonRow,
  Card,
  Col,
  FormField,
  FormLabel,
  HelpText,
  IconLogo,
  InputGroup,
  LoadingIndicator,
  MultiSelect,
  Row,
  Stretched,
  Text,
} from '@tokensoft-web/common-ui';
import {
  FEATURE,
  TIMEZONES,
  formatAddress,
  useAuth,
  useClipboard,
  useConfiguration,
  useFeature,
  useToast,
  useUpdateAccount,
} from '@tokensoft-web/common-utils';
import { FC, useEffect, useState } from 'react';
import { AiOutlineCopy, AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useProfile } from '../../contexts/profile/profile-context';
import {
  validateEmail,
  validatePhoneNumber,
} from '../../utils/form-validation';
import './profile.css';

type ProfileDetailsProps = {};

export const ProfileDetails: FC<ProfileDetailsProps> = ({}) => {
  const {
    user: { walletAddress },
  } = useAuth();
  const { account, loading, setView } = useProfile();
  const { copyToClipboard } = useClipboard();
  const { configuration } = useConfiguration();
  const { isFeatureEnabled } = useFeature();
  const { showSuccessToast, showErrorToast } = useToast();
  const [profileUpdates, setProfileUpdates] = useState(null);
  const [emailChanged, setEmailChanged] = useState(false);
  const [needToReset, setNeedToReset] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const { mutate: update, isPending: updateLoading } = useUpdateAccount();

  const emailValid = !loading && validateEmail(profileUpdates?.email);
  const phoneNumberValid =
    !loading && validatePhoneNumber(profileUpdates?.phoneNumber);

  useEffect(() => {
    if (account) {
      setProfileUpdates(account);
      setEmailChanged(false);
    }
  }, [account]);

  const handleEmailChange = (event) => {
    const clonedChanges = { ...profileUpdates };
    clonedChanges.email = event.target.value;
    clonedChanges.emailVerified = false;
    setProfileUpdates(clonedChanges);
    setEmailChanged(clonedChanges.email !== account?.email);
    setNeedToReset(true);
  };

  const handleTimezoneSelect = (selected) => {
    const clonedChanges = { ...profileUpdates };
    console.log('setting timezone', selected.value);
    clonedChanges.timezone = selected.value;
    setProfileUpdates(clonedChanges);
    setNeedToReset(true);
  };

  const handlePhoneNumberChange = (e) => {
    setProfileUpdates({ ...profileUpdates, phoneNumber: e.target.value });
    setNeedToReset(true);
  };

  const handleReset = () => {
    setProfileUpdates(account);
    setEmailChanged(false);
    setNeedToReset(false);
  };

  const handleCancel = () => {
    setProfileUpdates(account);
    setNeedToReset(false);
    setEmailChanged(false);
    setEditMode(false);
  };

  const handleSave = () => {
    update(
      {
        id: profileUpdates.id,
        authId: profileUpdates.authId,
        email: profileUpdates.email,
        phoneNumber: profileUpdates.phoneNumber,
        timezone: profileUpdates.timezone,
      },
      {
        onSuccess: () => {
          showSuccessToast({
            description: 'Successfully saved profile details.',
          });
          if (emailChanged) {
            setView('profile-verify-email');
          }
        },
        onError: (error) => showErrorToast({ description: error.message }),
      },
    );
  };

  const disableSave =
    !profileUpdates?.email || !emailValid || !phoneNumberValid || updateLoading;
  const formattedAddress = formatAddress(account);

  return (
    <>
      <div id='contact-information'>
        <Card className='h-full'>
          {loading ? (
            <div className={'mb-12'}>
              <LoadingIndicator />
            </div>
          ) : (
            <Col className={'h-full justify-between'}>
              <div>
                <Stretched place={'start-center'} className='mb-8'>
                  <IconLogo
                    className='xlarge'
                    logo={
                      'https://s3.amazonaws.com/media.tokensoft.io/images/profile.png'
                    }
                  />
                  <Col className={'self-center ml-2'}>
                    <Text className='text-3xl'>
                      {account?.firstName} {account.lastName}
                    </Text>
                    <Row nowrap>
                      <HelpText className={'text-xs break-anywhere'}>
                        {walletAddress}
                      </HelpText>
                      <div
                        className={'ml-2'}
                        onClick={() => copyToClipboard(walletAddress)}
                      >
                        <AiOutlineCopy size={16} />
                      </div>
                    </Row>
                  </Col>
                </Stretched>

                <FormField className={'mb-4'}>
                  <FormLabel>Permanent address</FormLabel>
                  <Text>
                    {formattedAddress.length
                      ? formattedAddress.join(' ')
                      : 'Address not set'}
                  </Text>
                </FormField>

                {isFeatureEnabled(FEATURE.LEGACY_PURCHASES) &&
                !account.emailVerified &&
                configuration?.project ? (
                  <div className='email-info-body p-4 mb-2'>
                    <span className='text-xs'>
                      You need to verify your email in order to view your legacy
                      purchase history.
                    </span>
                  </div>
                ) : null}
                <InputGroup
                  label={'Email address'}
                  value={profileUpdates?.email}
                  name='email'
                  required={true}
                  valid={emailValid}
                  readonly={!editMode}
                  disabled={updateLoading}
                  onChange={handleEmailChange}
                  error={emailValid ? null : 'Invalid email'}
                />

                <InputGroup
                  label='Phone number'
                  value={profileUpdates?.phoneNumber}
                  name='phoneNumber'
                  required={false}
                  readonly={!editMode}
                  disabled={updateLoading}
                  onChange={handlePhoneNumberChange}
                  error={phoneNumberValid ? null : 'Invalid phone number'}
                />

                <MultiSelect
                  label='Time zone'
                  options={TIMEZONES}
                  value={TIMEZONES.find(
                    (timezone) => timezone.value == profileUpdates?.timezone,
                  )}
                  onChange={handleTimezoneSelect}
                  readonly={!editMode}
                  disabled={updateLoading}
                  isMulti={false}
                />
              </div>

              <ButtonRow place={'end'}>
                {editMode ? (
                  <>
                    <button
                      disabled={updateLoading}
                      className='btn btn-outline-primary'
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      disabled={updateLoading || !needToReset}
                      className='btn btn-outline-primary'
                      onClick={handleReset}
                    >
                      Reset
                    </button>

                    <button
                      className='btn btn-primary'
                      disabled={disableSave}
                      onClick={handleSave}
                    >
                      {updateLoading ? (
                        <div className='flex flex-row'>
                          <div className='animate-spin'>
                            <AiOutlineLoading3Quarters size={24} />
                          </div>
                          <span className='pl-2'>Saving...</span>
                        </div>
                      ) : (
                        <span>Save</span>
                      )}
                    </button>
                  </>
                ) : (
                  <button
                    className='btn btn-outline-primary'
                    onClick={() => setEditMode(!editMode)}
                  >
                    Edit
                  </button>
                )}
              </ButtonRow>
            </Col>
          )}
        </Card>
      </div>
    </>
  );
};
