export const TokensoftLogo = () => (
  <svg version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 1000 120'>
    <g>
      <polygon
        style={{ fill: 'var(--logo-color)' }}
        points='329.615,107.362 424.336,107.362 424.336,84.139 352.837,84.139 352.837,71.613
        398.585,71.613 398.585,48.388 352.837,48.388 352.837,35.861 424.336,35.861 424.336,12.638 329.615,12.64 	'
      />
      <polygon
        style={{ fill: 'var(--logo-color)' }}
        points='542.582,71.613 614.081,71.613 614.081,84.139 542.582,84.139 542.582,107.362
        637.303,107.362 637.303,48.388 565.804,48.388 565.804,35.861 637.303,35.861 637.303,12.638 542.582,12.64 	'
      />
      <polygon
        style={{ fill: 'var(--logo-color)' }}
        points='507.596,70.003 459.322,12.641 436.097,12.641 436.097,107.362 459.322,107.362
        459.322,49.764 508.351,106.639 509.049,107.362 530.821,107.362 530.821,12.641 507.596,12.641 	'
      />
      <polygon
        style={{ fill: 'var(--logo-color)' }}
        points='10.162,35.863 45.914,35.863 45.914,107.362 69.137,107.362 69.137,35.863 104.886,35.863
        104.886,12.641 10.162,12.641 	'
      />
      <path
        style={{ fill: 'var(--logo-color)' }}
        d='M116.649,107.362h94.722V12.638l-94.722,0.002V107.362z M139.872,35.861h48.276v48.279h-48.276
        V35.861z'
      />
      <polygon
        style={{ fill: 'var(--logo-color)' }}
        points='755.549,107.362 778.771,107.362 778.771,72.015 824.519,72.015 824.519,48.79
        778.771,48.79 778.771,35.863 850.271,35.863 850.271,12.638 755.549,12.638 	'
      />
      <path
        style={{ fill: 'var(--logo-color)' }}
        d='M649.064,107.362h94.721V12.638l-94.721,0.002V107.362z M672.289,35.861h48.274v48.279h-48.274
        V35.861z'
      />
      <polygon
        style={{ fill: 'var(--logo-color)' }}
        points='956.755,35.863 956.755,12.641 862.034,12.641 862.034,35.865 897.781,35.865
        897.781,107.362 921.006,107.362 921.006,35.865 956.754,35.865 956.754,68.948 989.838,68.948 989.838,35.863 	'
      />
      <polygon
        style={{ fill: 'var(--logo-color)' }}
        points='286.309,12.64 253.297,48.389 246.357,48.389 246.357,12.64 223.132,12.64 223.132,48.389
        223.132,71.614 223.132,107.362 246.357,107.362 246.357,71.614 253.297,71.614 286.309,107.362 317.853,107.362 274.172,60.001
        317.853,12.64 	'
      />
    </g>
  </svg>
);
