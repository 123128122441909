import {
  Card,
  Error,
  FileUpload,
  InputGroup,
  LoadingIndicator,
  UploadedDoc,
} from '@tokensoft-web/common-ui';
import {
  useAccount,
  useAnalytics,
  useCreateMessage,
  useGetMessageByToken,
  useToast,
  utcToLocalDate,
} from '@tokensoft-web/common-utils';
import { useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Link, useNavigate, useParams } from 'react-router-dom';

const ViewMessagePage = () => {
  const navigate = useNavigate();
  useAnalytics('/account/view-message');
  const { showSuccessToast, showErrorToast } = useToast();
  const { messageToken } = useParams();
  const { result, isPending } = useGetMessageByToken(messageToken);
  const { account } = useAccount();
  const { mutate: createMessage, isPending: createMessagePending } =
    useCreateMessage();

  const [showForm, setShowForm] = useState(false);
  const [responseText, setResponseText] = useState('');
  const [docsUploaded, setDocsUploaded] = useState<UploadedDoc[]>([]);

  if (isPending || createMessagePending) {
    return <LoadingIndicator />;
  }

  const handlePost = () => {
    const documentIds = docsUploaded.map(({ documentId }) => ({
      id: documentId,
    }));
    const {
      account: { kycTiers },
    } = result;

    createMessage(
      {
        account: {
          id: account.id,
          kycTiers,
        },
        message: responseText || 'Documentation submitted for review',
        documents: documentIds,
      },
      {
        onSuccess: () => {
          setResponseText('');
          setDocsUploaded([]);
          setShowForm(false);
          showSuccessToast({
            title: 'Message received!',
            description:
              'If a response is required, you should expect one within 3-5 business days.',
          });
          navigate('/account');
        },
        onError: (error) => showErrorToast({ description: error.message }),
      },
    );
  };

  const disablePost =
    (!responseText && docsUploaded.length === 0) || createMessagePending;

  if (!result || result?.id === null) {
    return (
      <Error
        title='Oops! Something went wrong.'
        message="Invalid message token. Click <a href='/account' class='text-primary-medium underline'>here</a> to return to your profile."
      />
    );
  }

  return (
    <div className='flex flex-col items-center pt-8'>
      <Card
        className='w-full xl:w-3/4'
        title='Message from Tokensoft'
        actions={<>{utcToLocalDate(result.createdAt)}</>}
        subtitleStyle='text-right'
      >
        <p dangerouslySetInnerHTML={{ __html: result.message }} />
      </Card>
      {showForm ? (
        <div className='w-full xl:w-3/4'>
          <Card title='Response'>
            <InputGroup
              type='textarea'
              value={responseText}
              name='responseText'
              onChange={(e) => setResponseText(e.target.value)}
            />
          </Card>
          <Card title='Attachments'>
            <FileUpload
              uploadUrl='accounts/documents'
              onFileChange={setDocsUploaded}
              docsUploaded={docsUploaded}
            />
            <p className='text-xs text-neutral-light pt-2'>
              * By providing information, you represent and warrant that it is
              true, complete, and correct.
            </p>
          </Card>
          <div className='w-full flex justify-end gap-2 pb-8 pt-8'>
            <button
              className='btn btn-outline-dark'
              onClick={() => setShowForm(false)}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary'
              onClick={handlePost}
              disabled={disablePost}
            >
              {createMessagePending ? (
                <div className='flex flex-row items-center'>
                  <div className='animate-spin'>
                    <AiOutlineLoading3Quarters size={24} />
                  </div>
                  <span className='pl-2 self-center'>Posting...</span>
                </div>
              ) : (
                'Post'
              )}
            </button>
          </div>
        </div>
      ) : result.responseRequired ? (
        <div className='w-full flex justify-center pb-8 pt-8'>
          <button className='btn btn-primary' onClick={() => setShowForm(true)}>
            Reply or Attach Files
          </button>
        </div>
      ) : (
        <div className={'flex flex-row items-center space-x-4'}>
          <Link className='btn btn-primary' to='/account'>
            My Profile
          </Link>
          <Link className='btn btn-primary' to='/'>
            Dashboard
          </Link>
        </div>
      )}
    </div>
  );
};

export default ViewMessagePage;
