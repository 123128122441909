export const PolkadotWalletIcon = ({ fill = 'var(--primary)' }) => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Group 62'>
        <path
          id='Vector'
          d='M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z'
          fill='#EEEEEE'
        />
        <path
          id='Vector_2'
          d='M15 6.09375C16.2944 6.09375 17.3438 5.04442 17.3438 3.75C17.3438 2.45558 16.2944 1.40625 15 1.40625C13.7056 1.40625 12.6562 2.45558 12.6562 3.75C12.6562 5.04442 13.7056 6.09375 15 6.09375Z'
          fill='#9391ED'
        />
        <path
          id='Vector_3'
          d='M15 11.7188C16.2944 11.7188 17.3438 10.6694 17.3438 9.375C17.3438 8.08058 16.2944 7.03125 15 7.03125C13.7056 7.03125 12.6562 8.08058 12.6562 9.375C12.6562 10.6694 13.7056 11.7188 15 11.7188Z'
          fill='#9A1986'
        />
        <path
          id='Vector_4'
          d='M10.1284 8.90625C11.4228 8.90625 12.4722 7.85692 12.4722 6.5625C12.4722 5.26808 11.4228 4.21875 10.1284 4.21875C8.834 4.21875 7.78467 5.26808 7.78467 6.5625C7.78467 7.85692 8.834 8.90625 10.1284 8.90625Z'
          fill='#C631DD'
        />
        <path
          id='Vector_5'
          d='M5.25732 11.7188C6.55174 11.7188 7.60107 10.6694 7.60107 9.375C7.60107 8.08058 6.55174 7.03125 5.25732 7.03125C3.96291 7.03125 2.91357 8.08058 2.91357 9.375C2.91357 10.6694 3.96291 11.7188 5.25732 11.7188Z'
          fill='#9391ED'
        />
        <path
          id='Vector_6'
          d='M10.1284 14.5312C11.4228 14.5312 12.4722 13.4819 12.4722 12.1875C12.4722 10.8931 11.4228 9.84375 10.1284 9.84375C8.834 9.84375 7.78467 10.8931 7.78467 12.1875C7.78467 13.4819 8.834 14.5312 10.1284 14.5312Z'
          fill='#9A1986'
        />
        <path
          id='Vector_7'
          d='M5.25732 17.3438C6.55174 17.3438 7.60107 16.2944 7.60107 15C7.60107 13.7056 6.55174 12.6562 5.25732 12.6562C3.96291 12.6562 2.91357 13.7056 2.91357 15C2.91357 16.2944 3.96291 17.3438 5.25732 17.3438Z'
          fill='#C631DD'
        />
        <path
          id='Vector_8'
          d='M5.25732 22.9688C6.55174 22.9688 7.60107 21.9194 7.60107 20.625C7.60107 19.3306 6.55174 18.2812 5.25732 18.2812C3.96291 18.2812 2.91357 19.3306 2.91357 20.625C2.91357 21.9194 3.96291 22.9688 5.25732 22.9688Z'
          fill='#9391ED'
        />
        <path
          id='Vector_9'
          d='M10.1284 20.1562C11.4228 20.1562 12.4722 19.1069 12.4722 17.8125C12.4722 16.5181 11.4228 15.4688 10.1284 15.4688C8.834 15.4688 7.78467 16.5181 7.78467 17.8125C7.78467 19.1069 8.834 20.1562 10.1284 20.1562Z'
          fill='#9A1986'
        />
        <path
          id='Vector_10'
          d='M10.1284 25.7812C11.4228 25.7812 12.4722 24.7319 12.4722 23.4375C12.4722 22.1431 11.4228 21.0938 10.1284 21.0938C8.834 21.0938 7.78467 22.1431 7.78467 23.4375C7.78467 24.7319 8.834 25.7812 10.1284 25.7812Z'
          fill='#C631DD'
        />
        <path
          id='Vector_11'
          d='M15 28.5938C16.2944 28.5938 17.3438 27.5444 17.3438 26.25C17.3438 24.9556 16.2944 23.9062 15 23.9062C13.7056 23.9062 12.6562 24.9556 12.6562 26.25C12.6562 27.5444 13.7056 28.5938 15 28.5938Z'
          fill='#9391ED'
        />
        <path
          id='Vector_12'
          d='M15 22.9688C16.2944 22.9688 17.3438 21.9194 17.3438 20.625C17.3438 19.3306 16.2944 18.2812 15 18.2812C13.7056 18.2812 12.6562 19.3306 12.6562 20.625C12.6562 21.9194 13.7056 22.9688 15 22.9688Z'
          fill='#9A1986'
        />
        <path
          id='Vector_13'
          d='M19.8716 25.7812C21.166 25.7812 22.2153 24.7319 22.2153 23.4375C22.2153 22.1431 21.166 21.0938 19.8716 21.0938C18.5772 21.0938 17.5278 22.1431 17.5278 23.4375C17.5278 24.7319 18.5772 25.7812 19.8716 25.7812Z'
          fill='#C631DD'
        />
        <path
          id='Vector_14'
          d='M24.7427 22.9688C26.0371 22.9688 27.0864 21.9194 27.0864 20.625C27.0864 19.3306 26.0371 18.2812 24.7427 18.2812C23.4483 18.2812 22.3989 19.3306 22.3989 20.625C22.3989 21.9194 23.4483 22.9688 24.7427 22.9688Z'
          fill='#9391ED'
        />
        <path
          id='Vector_15'
          d='M19.8716 20.1562C21.166 20.1562 22.2153 19.1069 22.2153 17.8125C22.2153 16.5181 21.166 15.4688 19.8716 15.4688C18.5772 15.4688 17.5278 16.5181 17.5278 17.8125C17.5278 19.1069 18.5772 20.1562 19.8716 20.1562Z'
          fill='#9A1986'
        />
        <path
          id='Vector_16'
          d='M24.7427 17.3438C26.0371 17.3438 27.0864 16.2944 27.0864 15C27.0864 13.7056 26.0371 12.6562 24.7427 12.6562C23.4483 12.6562 22.3989 13.7056 22.3989 15C22.3989 16.2944 23.4483 17.3438 24.7427 17.3438Z'
          fill='#C631DD'
        />
        <path
          id='Vector_17'
          d='M24.7427 11.7188C26.0371 11.7188 27.0864 10.6694 27.0864 9.375C27.0864 8.08058 26.0371 7.03125 24.7427 7.03125C23.4483 7.03125 22.3989 8.08058 22.3989 9.375C22.3989 10.6694 23.4483 11.7188 24.7427 11.7188Z'
          fill='#9391ED'
        />
        <path
          id='Vector_18'
          d='M19.8716 14.5312C21.166 14.5312 22.2153 13.4819 22.2153 12.1875C22.2153 10.8931 21.166 9.84375 19.8716 9.84375C18.5772 9.84375 17.5278 10.8931 17.5278 12.1875C17.5278 13.4819 18.5772 14.5312 19.8716 14.5312Z'
          fill='#9A1986'
        />
        <path
          id='Vector_19'
          d='M19.8716 8.90625C21.166 8.90625 22.2153 7.85692 22.2153 6.5625C22.2153 5.26808 21.166 4.21875 19.8716 4.21875C18.5772 4.21875 17.5278 5.26808 17.5278 6.5625C17.5278 7.85692 18.5772 8.90625 19.8716 8.90625Z'
          fill='#C631DD'
        />
        <path
          id='Vector_20'
          d='M15 17.3438C16.2944 17.3438 17.3438 16.2944 17.3438 15C17.3438 13.7056 16.2944 12.6562 15 12.6562C13.7056 12.6562 12.6562 13.7056 12.6562 15C12.6562 16.2944 13.7056 17.3438 15 17.3438Z'
          fill='#7E9A19'
        />
      </g>
    </svg>
  );
};
