import classNames from 'classnames';
import { ReactNode } from 'react';
import { BrowserTitle } from '../../config/browser-title';
import { Breadcrumb } from '../../typography/breadcrumb';
import { PageSubtitle } from '../../typography/page-subtitle';
import { PageTitle } from '../../typography/page-title';
import { Text } from '../../typography/text';
import { Col } from '../container/col';

interface PageHeaderProps {
  title?: string;
  subtitle?: string;
  breadcrumb?: ReactNode;
  instructions?: ReactNode;
  className?: string;
  style?: string;
  children?: ReactNode;
  id?: string;
}

export const PageHeader = ({
  id,
  title,
  subtitle,
  instructions,
  className,
  breadcrumb,
  style,
  children,
}: PageHeaderProps) => {
  const key = id || 'page-header';

  return (
    <>
      <BrowserTitle title={title || ''} />

      <Col
        data-testid={key}
        gap={4}
        textAlign={'center'}
        place={'center'}
        className={classNames('page-header mb-10 mt-4', className)}
      >
        {breadcrumb && (
          <Breadcrumb data-testid={`${key}-breadcrumb`} style={style}>
            {breadcrumb}
          </Breadcrumb>
        )}

        {title && (
          <PageTitle data-testid={`${key}-title`} style={style}>
            {title}
          </PageTitle>
        )}

        {subtitle && (
          <PageSubtitle
            data-testid={`${key}-subtitle`}
            style={style}
            className={'mt-0'}
          >
            {subtitle}
          </PageSubtitle>
        )}

        {children}
      </Col>

      {instructions && (
        <div data-testid={`${key}-instructions`} className={classNames('mb-4')}>
          {typeof instructions === 'string' ? (
            <Text style={style}>{instructions}</Text>
          ) : (
            <>{instructions}</>
          )}
        </div>
      )}
    </>
  );
};
