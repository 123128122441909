import classNames from 'classnames';
import { ReactNode } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { MdLockClock } from 'react-icons/md';
import './badge.css';

export enum BADGE {
  UPCOMING = 'UPCOMING',
  INELIGIBLE = 'INELIGIBLE',
  VALUE = 'VALUE',
}

export const Badge = ({
  children,
  name,
  className,
  value,
}: {
  children?: ReactNode;
  name?: BADGE;
  className?: string;
  value?: number;
}) => {
  const renderBadge = () => {
    switch (name) {
      case BADGE.UPCOMING:
        return (
          <div className={className}>
            <MdLockClock />
          </div>
        );
      case BADGE.INELIGIBLE:
        return (
          <div className={classNames('red', className)}>
            <AiFillCloseCircle />
          </div>
        );
      case BADGE.VALUE:
        return (
          <div className={classNames('badge-value', className)}>{value}</div>
        );
      default:
        return null;
    }
  };

  return (
    <div className='relative'>
      {renderBadge()}
      {children}
    </div>
  );
};
