import { ButtonRow, Card, InputGroup } from '@tokensoft-web/common-ui';
import {
  getTruncatedAddress,
  getTxUrl,
  useAccount,
  useAnalytics,
  useAuth,
  useNetworks,
  utcToLocalDate,
} from '@tokensoft-web/common-utils';
import { VscLinkExternal } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { useSale } from '../../contexts/sale/sale-context';
import { PAYMENT_STEP } from '../../utils/enums';

const ReceiptStep = () => {
  const {
    user: { walletAddress },
  } = useAuth();
  const { getNetworkDetails } = useNetworks();
  const { account } = useAccount();
  const {
    sale,
    receipt: {
      paymentMethod,
      receipt,
      tokensPaid,
      selectedPaymentMethod,
      saleTokensPurchased,
    },
    setStep,
  } = useSale();

  useAnalytics(`/sale/${sale.id}/receipt`);

  const getUsernameDisplay = () => {
    const firstName = account?.firstName ? account.firstName.trim() : '';
    const lastName = account?.lastName ? account.lastName.trim() : '';

    return `${firstName} ${lastName}`;
  };

  return (
    <>
      <Card
        title='Payment Receipt'
        actions={
          <a
            target='_blank'
            rel='noreferrer'
            href={getTxUrl(receipt, getNetworkDetails(sale.chainId))}
          >
            <div className='flex items-center space-x-2'>
              <div>{getTruncatedAddress(receipt)}</div>
              <VscLinkExternal />
            </div>
          </a>
        }
        className='mt-8 vertical short'
      >
        <InputGroup label='Method' value={paymentMethod} readonly />

        <InputGroup
          label={`You bought`}
          value={`${saleTokensPurchased} ${sale?.symbol}`}
          readonly
        />

        <InputGroup
          label={`Contribution amount`}
          value={`${tokensPaid} ${selectedPaymentMethod.label}`}
          readonly
        />

        <InputGroup
          label={`Receiving wallet`}
          value={getTruncatedAddress(walletAddress)}
          readonly
        />

        <InputGroup label={`Purchaser`} value={getUsernameDisplay()} readonly />

        <InputGroup
          label={`Date`}
          value={utcToLocalDate(new Date())}
          readonly
        />

        <ButtonRow place={'between'}>
          <button
            onClick={() => setStep(PAYMENT_STEP.PURCHASE)}
            className='btn btn-outline-dark'
          >
            Buy More
          </button>

          <Link to={`/purchases`} className='btn btn-primary'>
            My Purchases
          </Link>
        </ButtonRow>
      </Card>
    </>
  );
};

export default ReceiptStep;
