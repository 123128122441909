import { Card, CardTitle, LoadingIndicator } from '@tokensoft-web/common-ui';
import { VERIFICATION_STATUS } from '@tokensoft-web/common-utils';
import { Link } from 'react-router-dom';
import { useProfile } from '../../contexts/profile/profile-context';
import { StatusIcon } from '../admin/project/status-icon';

export const ProfileSetup = () => {
  const {
    loading,
    hasVerifiedEmail,
    hasSetProfileDetails,
    tier1Status,
    isTier3Eligible,
    tier3Status,
    isTier3Started,
    verificationEmailSent,
    view,
    setView,
  } = useProfile();

  return (
    <Card className='no-padding' id={'profile-setup-card'}>
      <CardTitle
        data-testid='profile-setup-card-title'
        className={'card-header'}
      >
        Profile Setup
      </CardTitle>
      {loading ? (
        <div className={'mb-12'}>
          <LoadingIndicator />
        </div>
      ) : (
        <div className='px-4 pb-6'>
          <ul className={'metismenu'} role='list'>
            <li
              data-testid='contact-information-menu-item'
              role='listitem'
              className={
                view === 'profile-details' ? 'bg-cool-grey-light px-4' : 'px-4'
              }
            >
              <a onClick={() => setView('profile-details')}>
                <StatusIcon
                  id='contact-information-status-icon'
                  status={hasSetProfileDetails}
                />
                <span className={'text-neutral-extra-dark font-normal'}>
                  Contact information
                </span>
              </a>
            </li>

            <li
              data-testid='manage-wallet-addresses-menu-item'
              role='listitem'
              className={
                view === 'profile-wallets' ? 'bg-cool-grey-light px-4' : 'px-4'
              }
            >
              <a onClick={() => setView('profile-wallets')}>
                <StatusIcon
                  id='manage-wallet-addresses-status-icon'
                  status={true}
                />
                <span className={'text-neutral-extra-dark font-normal'}>
                  Manage wallet addresses
                </span>
              </a>
            </li>

            <li
              data-testid='verify-email-menu-item'
              role='listitem'
              className={
                view === 'profile-verify-email'
                  ? 'bg-cool-grey-light px-4'
                  : 'px-4'
              }
            >
              <a onClick={() => setView('profile-verify-email')}>
                <StatusIcon
                  id='verify-email-status-icon'
                  status={
                    hasVerifiedEmail
                      ? VERIFICATION_STATUS.COMPLETED
                      : verificationEmailSent
                        ? VERIFICATION_STATUS.PENDING
                        : VERIFICATION_STATUS.NONE
                  }
                />
                <span className={'text-neutral-extra-dark font-normal'}>
                  Verify email
                </span>
              </a>
            </li>

            <li
              data-testid='identity-verification-menu-item'
              role='listitem'
              className={
                view === 'profile-identity' ? 'bg-cool-grey-light px-4' : 'px-4'
              }
            >
              <Link to={'/account/identity'}>
              {/* <Link className='cursor-default' to={'#'}> */}
                <StatusIcon
                  id='identity-verification-status-icon'
                  status={tier1Status}
                />
                <span className={'text-neutral-extra-dark font-normal'}>
                  Identity verification
                </span>
              </Link>
            </li>

            {isTier3Eligible && (
              <li
                data-testid='accreditation-menu-item'
                role='listitem'
                className={
                  view === 'profile-accreditation'
                    ? 'bg-cool-grey-light px-4'
                    : 'px-4'
                }
              >
                {isTier3Started ? (
                  <a
                    data-testid='accreditation-href'
                    onClick={() => setView('profile-accreditation')}
                  >
                    <StatusIcon
                      id='accreditation-status-icon'
                      status={tier3Status}
                    />
                    <span className={'text-neutral-extra-dark font-normal'}>
                      Accreditation <span className={'text-xs'}>(US only)</span>
                    </span>
                  </a>
                ) : (
                  // <Link data-testid='accreditation-link' to={'/account/accreditation'}>
                  <Link
                    className='cursor-default'
                    data-testid='accreditation-link'
                    to={'#'}
                  >
                    <StatusIcon
                      id='accreditation-status-icon'
                      status={tier3Status}
                    />
                    <span className={'text-neutral-extra-dark font-normal'}>
                      Accreditation <span className={'text-xs'}>(US only)</span>
                    </span>
                  </Link>
                )}
              </li>
            )}
          </ul>
        </div>
      )}
    </Card>
  );
};
