import {
  ButtonRow,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CheckboxInput,
  Col,
  Divider,
  InputGroup,
  MultiSelect,
  Stacked,
} from '@tokensoft-web/common-ui';
import {
  add,
  div,
  gt,
  lt,
  lte,
  min,
  mult,
  sub,
  useAnalytics,
  useAuth,
  useNetworks,
} from '@tokensoft-web/common-utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBalance } from 'wagmi';
import EventEligibilityAllocationSummary from '../../components/event/event-eligibility-allocation-summary';
import { useSale } from '../../contexts/sale/sale-context';
import { usePurchaseTotal } from '../../services/transaction-service';
import { DEFAULT_BASE_CURRENCY_DECIMALS } from '../../utils/constant';
import { PAYMENT_STEP } from '../../utils/enums';
import {
  getPaymentMethods,
  useBuyerTotal,
  useExchangeRate,
} from '../../utils/sale';

const PurchaseStep = () => {
  const navigate = useNavigate();
  const {
    user: { walletAddress },
  } = useAuth();
  const { getNetworkDetails } = useNetworks();
  const {
    sale,
    setStep,
    setReceipt,
    documentsToAcceptOrSign,
    eligibilityData,
  } = useSale();
  const [networkCheckbox, setNetworkCheckbox] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState(
    getPaymentMethods(sale, getNetworkDetails(sale.chainId)),
  );
  const [tokensPaid, setTokensPaid] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    paymentMethods[0],
  );
  const [purchaseError, setPurchaseError] = useState(null);

  const [feesCheckbox, setFeesCheckbox] = useState(false);

  const hasRequiredDocuments =
    documentsToAcceptOrSign && documentsToAcceptOrSign.length > 0;

  const { data } = useBalance({
    address: walletAddress,
    token: selectedPaymentMethod.address as `0x${string}`,
  });

  const { formatted: balance } = data || {};
  const buyerTotal = useBuyerTotal(sale.chainId, sale.id, walletAddress);
  const purchaseTotal = usePurchaseTotal(sale.id);
  const exchangeRate = useExchangeRate(sale?.chainId, selectedPaymentMethod);
  const { name: networkName } = getNetworkDetails(sale.chainId);

  const DISPLAY_DECIMALS = Number(selectedPaymentMethod.decimals);

  useAnalytics(`/sale/${sale.id}/purchase`);

  const validForm = tokensPaid && networkCheckbox && feesCheckbox;

  const handlePaymentMethodSelect = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
    setTokensPaid('');
  };

  const handleTokensPaidChange = ({ value }) => {
    setTokensPaid(value);
  };

  const handleNextButtonClick = () => {
    setReceipt({
      tokensPaid,
      selectedPaymentMethod,
      saleTokensPurchased: tokensPurchased,
    });
    setStep(PAYMENT_STEP.CONFIRMATION);
  };

  useEffect(() => {
    let error = getPurchaseError();
    setPurchaseError(error);
  }, [tokensPaid, sale]);

  const remainingUserAllocation = sub(sale?.userMaximum, buyerTotal);
  const remainingTotalAllocation = sub(sale?.saleMaximum, purchaseTotal);
  const maxPurchase = min(remainingUserAllocation, remainingTotalAllocation);

  const paymentTokenPerSaleToken = div(
    sale.price,
    exchangeRate,
    null,
    DISPLAY_DECIMALS,
  );

  const tokensPurchased = div(
    tokensPaid,
    paymentTokenPerSaleToken,
    DISPLAY_DECIMALS,
  );

  const saleTokenPrice = exchangeRate
    ? `1 ${sale.symbol} = ${paymentTokenPerSaleToken} ${selectedPaymentMethod.symbol}`
    : null;

  // Add a buffer to the purchase minimum (base units) to account price fluctuations
  const minBuffer = Number(sale.decimals) > 6 ? 100 : 1;
  const paddedPurchaseMinimum = add(sale.purchaseMinimum, minBuffer);
  const purchaseMinimumInSelectedPaymentMethod = div(
    paddedPurchaseMinimum,
    exchangeRate,
    null,
    DISPLAY_DECIMALS,
  );

  const purchaseMaximumInSelectedPaymentMethod = div(
    maxPurchase,
    exchangeRate,
    null,
    DISPLAY_DECIMALS,
  );

  const purchaseMin = exchangeRate
    ? `${purchaseMinimumInSelectedPaymentMethod} ${selectedPaymentMethod.symbol}`
    : null;
  const purchaseMax = exchangeRate
    ? `${purchaseMaximumInSelectedPaymentMethod} ${selectedPaymentMethod.symbol}`
    : null;

  const getPurchaseError = () => {
    if (!tokensPaid || !tokensPurchased) return null;

    const baseCurrencyPaid = mult(tokensPaid, exchangeRate);

    const overMaxPurchase = gt(
      baseCurrencyPaid,
      maxPurchase,
      Number(DEFAULT_BASE_CURRENCY_DECIMALS),
    );

    const underMinPurchase =
      gt(baseCurrencyPaid, 0) && lt(baseCurrencyPaid, sale.purchaseMinimum);

    if (lte(remainingUserAllocation, 0)) {
      return 'Total user purchase  amount limit reached';
    } else if (lte(remainingTotalAllocation, 0)) {
      return 'Total sale purchase amount limit reached';
    } else if (overMaxPurchase) {
      return `Purchase too large: the limit is ${purchaseMax}`;
    } else if (underMinPurchase) {
      return `Purchase too small: the minimum is ${purchaseMin}`;
    } else if (gt(tokensPaid, balance)) {
      return `Not enough ${selectedPaymentMethod.symbol} in wallet`;
    } else {
      return null;
    }
  };

  return (
    <>
      <Card className='vertical'>
        <CardHeader>
          <CardTitle data-testid='eligibility-header'>
            Payment Details
          </CardTitle>
        </CardHeader>

        <CardBody>
          <Stacked>
            <div className={'text-center text-2xl'}>{saleTokenPrice}</div>

            <EventEligibilityAllocationSummary
              eligibilityData={eligibilityData}
            />

            <Divider />

            <Col className={'gap-0 md:gap-5'}>
              {paymentMethods?.length > 1 ? (
                <MultiSelect
                  label='Method'
                  options={paymentMethods}
                  value={selectedPaymentMethod}
                  onChange={handlePaymentMethodSelect}
                  isMulti={false}
                />
              ) : null}

              <InputGroup
                label={`${selectedPaymentMethod.label} Value`}
                type='number'
                name='tokensPaid'
                placeholder='0'
                allowNegative={false}
                decimalScale={selectedPaymentMethod.decimals}
                value={tokensPaid}
                onChange={handleTokensPaidChange}
                error={purchaseError}
              />

              <InputGroup
                label={`${sale?.symbol.toUpperCase()} Tokens Purchased`}
                value={tokensPurchased === 'NaN' ? '0' : tokensPurchased}
                readonly
              />
            </Col>

            <Divider />

            <Col>
              <div>
                <CheckboxInput
                  onClick={() => setNetworkCheckbox(!networkCheckbox)}
                  name='networkCheckbox'
                  checked={networkCheckbox}
                >
                  I understand that my payment must be made on the{' '}
                  <b>{networkName}</b> network, and that my payment will be lost
                  if I do not follow instructions exactly
                </CheckboxInput>
              </div>

              <div>
                <CheckboxInput
                  onClick={() => setFeesCheckbox(!feesCheckbox)}
                  name='feesCheckbox'
                  checked={feesCheckbox}
                >
                  I understand that I am responsible for all network fees
                </CheckboxInput>
              </div>
            </Col>

            <ButtonRow place={'between'}>
              <button
                onClick={() =>
                  hasRequiredDocuments
                    ? setStep(PAYMENT_STEP.TERMS)
                    : navigate('/dashboard')
                }
                className='btn btn-outline-dark'
              >
                {hasRequiredDocuments ? 'Back' : 'Cancel'}
              </button>

              <button
                onClick={() => {
                  handleNextButtonClick();
                }}
                className='btn btn-primary'
                disabled={!validForm || purchaseError != null}
              >
                Next
              </button>
            </ButtonRow>
          </Stacked>
        </CardBody>
      </Card>
    </>
  );
};

export default PurchaseStep;
