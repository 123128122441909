import {
  Col,
  FormField,
  FormLabel,
  InputGroup,
  Stretched,
} from '@tokensoft-web/common-ui';
import { useAccount, utcToLocalDate } from '@tokensoft-web/common-utils';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const Signature = ({
  setSignatureValid,
  nameLabel,
}: {
  setSignatureValid(boolean): void;
  nameLabel: string;
}) => {
  const { eventId } = useParams();
  const { account, loading } = useAccount();
  const [signature, setSignature] = useState('');
  const [signatureError, setSignatureError] = useState('');

  const getUsernameDisplay = () => {
    const firstName = account?.firstName ? account.firstName.trim() : '';
    const lastName = account?.lastName ? account.lastName.trim() : '';

    return `${firstName} ${lastName}`;
  };

  const getUsername = () => {
    const usernameDisplay = getUsernameDisplay();
    return `${usernameDisplay}`.toLowerCase().replace(' ', '');
  };

  useEffect(() => {
    setSignature('');
    setSignatureError('');
    setSignatureValid(false);
  }, [eventId]);

  const handleSignatureChange = (event) => {
    const newSignature = event.target.value;
    setSignature(newSignature);
    setSignatureError('');

    if (newSignature.length === 0) {
      setSignatureValid(false);
    } else {
      const username = getUsername();
      if (username === '') {
        // we don't have a username to validate against and the user has typed at least a single character into the signature field
        setSignatureValid(true);
        return;
      }

      const signature = newSignature.toLowerCase().replace(' ', '');
      if (signature === username) {
        setSignatureValid(true);
        return;
      }

      const usernameDisplay = getUsernameDisplay();
      setSignatureError(`You must enter ${usernameDisplay}`);
      setSignatureValid(false);
    }
  };

  return (
    <Col gap={5}>
      <Stretched place={'start'}>
        <Col>
          <FormLabel>{nameLabel}</FormLabel>
          <FormField>{getUsernameDisplay()}</FormField>
        </Col>

        <Col>
          <FormLabel>Date</FormLabel>
          <FormField>{utcToLocalDate(new Date())}</FormField>
        </Col>
      </Stretched>

      <InputGroup
        name={'signature'}
        label='Signature'
        disabled={loading}
        placeholder='First and last name'
        value={signature}
        valid={!signatureError}
        onChange={(event) => handleSignatureChange(event)}
        error={signatureError}
      />
    </Col>
  );
};

export default Signature;
