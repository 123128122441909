import {
  Button,
  ButtonRow,
  Col,
  ModalTitle,
  Text,
} from '@tokensoft-web/common-ui';
import {
  User,
  getTruncatedAddress,
  isEmptyObject,
  useAuth,
  useCheckUserWalletAddress,
  useModal,
  useToast,
  useWallet,
} from '@tokensoft-web/common-utils';
import { useEffect, useState } from 'react';
import { useAccount as useWagmiAccount } from 'wagmi';

enum ETH_MODAL_STEP {
  SIGNATURE_REQUIRED = 'signature-required',
  LAST_ADDRESS = 'last-address',
  SWITCH_BACK = 'switch-back',
  ADD_NEW_WALLET = 'add-new-wallet',
}

export const EthAddWalletModal = ({
  onComplete,
  initialUser,
}: {
  onComplete: Function;
  initialUser: User;
}) => {
  const { closeModal } = useModal();
  const { user } = useAuth();
  let { walletAddress } = user || {};
  walletAddress = walletAddress?.toLowerCase();
  let { address } = useWagmiAccount();
  address = address?.toLowerCase() as `0x${string}`;
  const { connectWallet, disableAddWalletMode } = useWallet();
  const { showSuccessToast } = useToast();
  const { status, checkUserWalletAddress } = useCheckUserWalletAddress();

  const [view, setView] = useState<ETH_MODAL_STEP>(
    ETH_MODAL_STEP.ADD_NEW_WALLET,
  );

  useEffect(() => {
    if (address && walletAddress && address !== walletAddress) {
      checkUserWalletAddress(address);
    }
  }, [address, walletAddress]);

  if (isEmptyObject(user)) {
    disableAddWalletMode();
    onComplete();
  }

  if (
    initialUser?.authId &&
    user?.authId &&
    initialUser?.walletAddress &&
    user?.walletAddress &&
    initialUser?.authId === user?.authId &&
    initialUser.walletAddress !== user.walletAddress
  ) {
    showSuccessToast({
      description: `${getTruncatedAddress(
        user.walletAddress,
      )} successfully added!`,
    });
    disableAddWalletMode();
    onComplete();
  }

  if (
    view === ETH_MODAL_STEP.SWITCH_BACK &&
    address === initialUser.walletAddress
  ) {
    disableAddWalletMode();
    onComplete();
  }

  useEffect(() => {
    if (typeof status === 'string') {
      if (status !== '1') {
        setView(ETH_MODAL_STEP.SIGNATURE_REQUIRED);
      } else {
        setView(ETH_MODAL_STEP.LAST_ADDRESS);
      }
    }
  }, [status]);

  switch (view) {
    case ETH_MODAL_STEP.ADD_NEW_WALLET:
      return (
        <Col gap={4}>
          <ModalTitle>Add New Wallet Address</ModalTitle>
          <Text>
            In your wallet app, switch to the address you want to add to your
            profile
          </Text>
          <Button
            className='w-full'
            onClick={() => {
              disableAddWalletMode();
              closeModal();
            }}
          >
            Cancel
          </Button>
        </Col>
      );
    case ETH_MODAL_STEP.SIGNATURE_REQUIRED:
      return (
        <Col gap={4}>
          <ModalTitle>Signature Required</ModalTitle>
          <Text>
            Click the button below to send a message to your wallet app to sign
            and prove ownership of this address
          </Text>
          <ButtonRow place={'center'}>
            <Button
              fill='outline'
              color='primary'
              onClick={() => setView(ETH_MODAL_STEP.SWITCH_BACK)}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                disableAddWalletMode();
                connectWallet(true);
              }}
            >
              Sign Now
            </Button>
          </ButtonRow>
        </Col>
      );

    case ETH_MODAL_STEP.LAST_ADDRESS:
      return (
        <Col gap={4}>
          <ModalTitle>Are you sure?</ModalTitle>
          <Text>
            {getTruncatedAddress(address)} is the only address associated with
            another account, and you will lose access to that account. Are you
            sure you want to continue?
          </Text>
          <ButtonRow place={'center'}>
            <Button
              fill='outline'
              color='primary'
              onClick={() => setView(ETH_MODAL_STEP.SWITCH_BACK)}
            >
              Cancel
            </Button>
            <Button onClick={() => setView(ETH_MODAL_STEP.SIGNATURE_REQUIRED)}>
              Continue
            </Button>
          </ButtonRow>
        </Col>
      );

    case ETH_MODAL_STEP.SWITCH_BACK:
      return (
        <Col gap={4}>
          <ModalTitle>Switch Back</ModalTitle>
          <Text>
            In your wallet app, switch back to the{' '}
            {getTruncatedAddress(initialUser.walletAddress)} address
          </Text>
        </Col>
      );
  }
};
