import {
  ButtonRow,
  Col,
  FrontArrowIcon,
  InputGroup,
  LoadingIndicator,
  RadioInput,
  Row,
} from '@tokensoft-web/common-ui';
import {
  NULL_ADDRESS,
  useAnalytics,
  useAuth,
  useConfiguration,
  useFindDelegate,
  useGetDelegates,
  useToast,
} from '@tokensoft-web/common-utils';
import classNames from 'classnames';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { HiOutlineExclamation } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import DelegateAcceptButton from './delegate-accept-button';
import DelegateCard from './delegate-card';
import DelegateHeader from './delegate-header';
import './delegate.css';

const Delegate = ({
  delegating = true,
  onContinue = () => {},
  contract,
  disabled = false,
  showHeader = true,
}: {
  delegating?: boolean;
  onContinue?: Function;
  contract?: any;
  disabled?: boolean;
  showHeader?: boolean;
}) => {
  const { showErrorToast } = useToast();
  const { configuration } = useConfiguration();
  const { pageEvent } = useAnalytics();

  const userDidDelegate =
    contract?.delegatedToAddress &&
    contract.delegatedToAddress !== NULL_ADDRESS &&
    contract.delegatedToAddress !== '0x';
  const navigate = useNavigate();
  const {
    user: { walletAddress },
  } = useAuth();

  const [delegateAddressInput, setDelegateAddressInput] = useState('');
  const [delegateAddressSelected, setDelegateAddressSelected] = useState('');
  const [delegateInfoSelected, setDelegateInfoSelected] = useState(null);
  const [delegateAddressInputValid, setDelegateAddressInputValid] =
    useState(false);
  const [delegateSaved, setDelegateSaved] = useState(null);

  const [delegatesOrderBy, setDelegatesOrderBy] = useState('wallet_address');
  const [delegateHasValue, setDelegateHasValue] = useState(false);
  const [delegateByInputWallet, setDelegateByInputWallet] = useState(false);
  const [delegates, setDelegates] = useState([]);
  const [delegatesPage, setDelegatesPage] = useState(0);
  const delegateQueryQty = 6;

  const { mutate: getDelegates, isPending: getDelegatesLoading } =
    useGetDelegates();

  const { mutate: findDelegate, isPending: findDelegateLoading } =
    useFindDelegate();

  useEffect(() => {
    if (contract) {
      if (userDidDelegate) {
        findDelegate(
          {
            walletAddress: contract.delegatedToAddress,
            projectId: configuration?.project?.id,
          },
          {
            onSuccess: (response) => {
              if (response.delegate) {
                setDelegateSaved(response.delegate);
                setDelegateByInputWallet(false);
                setDelegateAddressSelected(response.delegate.walletAddress);
              } else {
                setDelegateByInputWallet(true);
                setDelegateAddressInput(contract.delegatedToAddress);
              }
              loadDelegates();
            },
            onError: (error) => {
              showErrorToast({ description: error.message });
              setDelegateAddressInput(contract.delegatedToAddress);
              setDelegateByInputWallet(true);
              loadDelegates();
            },
          },
        );
      } else {
        setDelegateAddressInput(walletAddress);
        loadDelegates();
      }
      pageEvent('governance', 'delegate');
    }
  }, [contract]);

  useEffect(() => {
    if (delegatesOrderBy) {
      setDelegates([]);
      setDelegatesPage(0);
    }
  }, [delegatesOrderBy]);

  useEffect(() => {
    if (delegatesPage === 0) {
      loadDelegates();
    }
  }, [delegatesPage]);

  const loadDelegates = () => {
    getDelegates(
      {
        offset: delegatesPage * delegateQueryQty,
        queryLimit: delegateQueryQty,
        projectId: configuration?.project?.id,
        orderBy: delegatesOrderBy,
        contractId: `${contract?.address}-${contract?.networkId}`,
      },
      {
        onSuccess: (response) => {
          if (response.delegates.length !== 0) {
            let tempDelegates = [...delegates, ...response.delegates];
            if (!delegateHasValue) {
              setDelegateHasValue(true);
            }
            setDelegates(tempDelegates);
            if (response.totalRecords !== tempDelegates.length) {
              setDelegatesPage(delegatesPage + 1);
            } else {
              setDelegatesPage(-1);
            }
          } else {
            setDelegateByInputWallet(true);
          }
        },
        onError: (error) => {
          showErrorToast({ description: error.message });
          setDelegateByInputWallet(true);
        },
      },
    );
  };

  useEffect(() => {
    if (delegateAddressInput && delegateAddressInput.length) {
      validateInput();
    } else {
      setDelegateAddressInputValid(false);
    }
  }, [delegateAddressInput]);

  useEffect(() => {
    if (delegateAddressSelected) {
      if (delegateSaved) {
        setDelegateAddressSelected(delegateSaved.walletAddress);
      } else {
        setDelegateAddressSelected('');
      }
    }
  }, [delegateByInputWallet]);

  const validateInput = async () => {
    let _validInput = false;
    // validate input
    _validInput = ethers.utils.isAddress(delegateAddressInput);

    if (!_validInput) {
      return setDelegateAddressInputValid(false);
    }

    setDelegateAddressInputValid(true);
  };

  const getDelegateInputPlaceholder = () => {
    if (userDidDelegate) {
      return 'Enter a New Delegate Address';
    }

    return 'Enter Your Address or Custom Address';
  };

  const showInputValidation =
    delegateAddressInput && delegateAddressInput.length;

  const selectDelegateAddress = (delegate) => {
    setDelegateAddressSelected(delegate.walletAddress);
    setDelegateInfoSelected(delegate);
  };

  const delegatesToDisplay = () => {
    if (delegateSaved) {
      if (delegates.find((d) => d.id === delegateSaved.id)) {
        return delegates.filter((d) => d.id !== delegateSaved.id);
      } else {
        return delegates.slice(0, -1);
      }
    } else {
      return delegates;
    }
  };

  const handleContinue = () => {
    setDelegates([]);
    setDelegateHasValue(false);
    if (disabled) {
      onContinue({
        delegateWalletAddress: null,
        delegateInput: null,
        delegateInfo: null,
      });
    } else {
      onContinue({
        delegateWalletAddress: delegateByInputWallet
          ? delegateAddressInput
          : delegateAddressSelected,
        delegateInput: delegateByInputWallet,
        delegateInfo: delegateByInputWallet ? null : delegateInfoSelected,
      });
    }
  };

  return (
    <div className='high-contrast w-full'>
      {showHeader ? (
        <DelegateHeader
          overrideSubTitle={`Your ${contract.tokenSymbol?.toUpperCase()} Tokens include voting power:  you can either participate in DAO governance directly or delegate your votes to someone else you trust.  Delegatees cannot claim or use your tokens - only your votes.`}
          overrideTitle='Delegation'
        />
      ) : null}

      {!delegateHasValue && (getDelegatesLoading || findDelegateLoading) ? (
        <div className='flex justify-center mt-4 h-96 items-center'>
          <LoadingIndicator className={'no-padding'} />
        </div>
      ) : (
        <Col>
          <Col className={'mb-10'}>
            {delegateHasValue ? (
              <div className='delegation-type-container'>
                <RadioInput
                  name='delegationType'
                  className='flex mb-4'
                  checked={delegateByInputWallet == false}
                  disabled={disabled}
                  onClick={() => setDelegateByInputWallet(false)}
                >
                  <div className='pt-1 leading-tight font-semibold'>
                    Select a Delegate:
                  </div>
                </RadioInput>
                <div className='flex gap-4 mb-4 text-neutral-500 items-center delegation-sort-by'>
                  <div className='text-xs font-semibold pb-2'>SORT BY:</div>
                  <RadioInput
                    checked={delegatesOrderBy === 'wallet_address'}
                    onClick={() => setDelegatesOrderBy('wallet_address')}
                    disabled={delegateByInputWallet || disabled}
                  >
                    Wallet Address
                  </RadioInput>
                  <RadioInput
                    checked={delegatesOrderBy === 'created_at'}
                    onClick={() => setDelegatesOrderBy('created_at')}
                    disabled={delegateByInputWallet || disabled}
                  >
                    Newest
                  </RadioInput>
                </div>
                {delegates.length !== 0 ? (
                  <div>
                    <div className='delegates-list grid xs:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 mb-4 '>
                      {delegateSaved ? (
                        <DelegateCard
                          delegate={delegateSaved}
                          action={selectDelegateAddress}
                          disabled={delegateByInputWallet || disabled}
                          delegateAddressSelected={delegateAddressSelected}
                          tokenDecimals={contract.tokenDecimals}
                        />
                      ) : null}
                      {delegatesToDisplay().map((delegate, index) => (
                        <DelegateCard
                          delegate={delegate}
                          action={selectDelegateAddress}
                          disabled={delegateByInputWallet || disabled}
                          delegateAddressSelected={delegateAddressSelected}
                          key={index}
                          tokenDecimals={contract.tokenDecimals}
                        />
                      ))}
                    </div>
                    {delegatesPage !== -1 ? (
                      <button
                        className={
                          'btn btn-outline-primary w-full !flex !items-center !justify-center'
                        }
                        disabled={delegateByInputWallet || disabled}
                        onClick={loadDelegates}
                      >
                        {getDelegatesLoading ? (
                          <LoadingIndicator
                            className={'no-padding'}
                            size={12}
                          />
                        ) : (
                          <span className='py-1'>Load More</span>
                        )}
                      </button>
                    ) : null}
                  </div>
                ) : (
                  <div className='text-sm text-gray-500 h-128 flex justify-center items-center'>
                    <LoadingIndicator text='Loading Delegates' />
                  </div>
                )}
              </div>
            ) : null}
            {delegateHasValue ? (
              <RadioInput
                name='delegationType'
                className='flex mb-4 mt-10'
                checked={delegateByInputWallet == true}
                disabled={disabled}
                onClick={() => setDelegateByInputWallet(true)}
              >
                <div className='pt-1 leading-tight font-semibold'>
                  Delegate to yourself or custom address:
                </div>
              </RadioInput>
            ) : (
              <div className='mb-4 mt-10'>
                Delegate to yourself or custom address:
              </div>
            )}
            <div className='flex'>
              <InputGroup
                type={'text'}
                name='delegation'
                value={delegateAddressInput}
                onChange={(e) => setDelegateAddressInput(e.target.value)}
                placeholder={getDelegateInputPlaceholder()}
                disabled={!delegateByInputWallet || disabled}
              />
            </div>
            {showInputValidation ? (
              <Row
                place={'end-center'}
                className={classNames(
                  `delegation-input-validation`,
                  delegateAddressInputValid
                    ? 'delegation-input-validation-status-valid'
                    : 'delegation-input-validation-status-invalid',
                )}
              >
                <span className='validation-text'>
                  {delegateAddressInputValid
                    ? 'Valid Address'
                    : 'Invalid ethereum address'}
                </span>
                <span className='validation-icon ml-2'>
                  {delegateAddressInputValid ? (
                    <AiFillCheckCircle size={24} />
                  ) : (
                    <HiOutlineExclamation size={24} />
                  )}
                </span>
              </Row>
            ) : null}
          </Col>
          {delegating ? (
            <ButtonRow place={'between'}>
              <DelegateAcceptButton
                onContinue={() => {}}
                disabled={
                  delegateByInputWallet
                    ? !delegateAddressInputValid
                    : !delegateAddressSelected
                }
                contract={contract}
                delegateByInputWallet={delegateByInputWallet}
                delegateWalletAddress={
                  delegateByInputWallet
                    ? delegateAddressInput
                    : delegateAddressSelected
                }
                delegateInfo={
                  delegateByInputWallet ? null : delegateInfoSelected
                }
              />
            </ButtonRow>
          ) : (
            <ButtonRow place={'between'}>
              <button
                className='btn btn-primary btn-lg ml-auto'
                disabled={
                  disabled
                    ? false
                    : delegateByInputWallet
                      ? !delegateAddressInputValid
                      : !delegateAddressSelected
                }
                onClick={() => handleContinue()}
              >
                <div className='flex flex-row items-center'>
                  <span className='mr-2'>Continue</span>
                  <FrontArrowIcon />
                </div>
              </button>
            </ButtonRow>
          )}
        </Col>
      )}
    </div>
  );
};

export default Delegate;
