import { Stacked } from '@tokensoft-web/common-ui';
import {
  FEATURE,
  NULL_ADDRESS,
  getIPFSLink,
} from '@tokensoft-web/common-utils';
import { useEffect, useState } from 'react';
import { useDistributor } from '../../contexts/distributor/distributor-context';
import { CLAIM_STEP } from '../../utils/enums';
import EventBodyWrapper from '../event/event-body-wrapper';
import EventHeader from '../event/event-header';
import EventHeaderWrapper from '../event/event-header-wrapper';
import Delegate from '../governance/delegate';
import ConnextDelegateHeaderText from './connext/connext-delegate-header-text';
import ConnextHeader from './connext/connext-header';

const ClaimDelegate = () => {
  const { event, distributor, setPageState, setDelegateSaved, delegateSaved } =
    useDistributor();
  const connextLayout = !!event?.features?.includes(
    FEATURE.CLAIM_CONNEXT_LAYOUT,
  );
  const [delegateInfo, setDelegateInfo] = useState(null);

  const contentHeaderBgColor = event?.contentHeaderBgColor;
  const contentHeaderImage = event?.contentHeaderImage;
  const contentHeaderFontColor = event?.contentHeaderFontColor;
  const contentHeaderTitle = event?.contentHeaderTitle;
  const contentHeaderBgImage = event?.contentHeaderBgImage;
  const themeLayoutVersion = event?.themeLayoutVersion;

  const handleIntroNextButtonOnClick = (delegate) => {
    setDelegateSaved(delegate);
    setPageState(CLAIM_STEP.CLAIM);
  };

  useEffect(() => {
    if (distributor) {
      setDelegateInfo({
        tokenSymbol: distributor.tokenSymbol,
        tokenDecimals: distributor.tokenDecimals,
        userContractMetadata: {
          ...distributor.userContractMetadata,
          delegateToAddress: delegateSaved.delegateWalletAddress
            ? delegateSaved.delegateWalletAddress
            : NULL_ADDRESS,
        },
        delegatedToAddress: delegateSaved.delegateWalletAddress
          ? delegateSaved.delegateWalletAddress
          : NULL_ADDRESS,
        tokenLogoUri: getIPFSLink(
          distributor?.logo?.uri || distributor?.logo?.cid,
        ),
        address: distributor?.id,
        interfaces: distributor?.interfaces,
        networkId: distributor?.chainId,
        event: event,
        contracts: distributor?.contracts,
      });
    }
  }, [distributor]);

  const renderClaimHeader = () => {
    if (connextLayout) {
      return (
        <>
          <ConnextHeader subText='Step 1: Delegate Your Votes ' />
          <EventBodyWrapper>
            <ConnextDelegateHeaderText />
          </EventBodyWrapper>
        </>
      );
    }

    return (
      <EventHeaderWrapper
        backgroundColor={contentHeaderBgColor}
        backgroundImg={contentHeaderBgImage}
      >
        <EventHeader
          headerTitle={contentHeaderTitle || distributor?.name}
          contentHeaderImage={contentHeaderImage}
          contentHeaderFontColor={contentHeaderFontColor}
          themeLayoutVersion={themeLayoutVersion}
        />
      </EventHeaderWrapper>
    );
  };

  return (
    <Stacked>
      {renderClaimHeader()}

      <EventBodyWrapper>
        <Delegate
          delegating={false}
          onContinue={handleIntroNextButtonOnClick}
          contract={delegateInfo}
          showHeader={false}
        />
      </EventBodyWrapper>
    </Stacked>
  );
};

export default ClaimDelegate;
