import { BLOCKCHAIN_KEY } from '../enum/blockchain-key';

export const capitalize = (value?: string): string | null => {
  if (!value) {
    return null;
  }

  if (value.length === 1) {
    return value.toUpperCase();
  }

  return value[0].toUpperCase() + value.slice(1);
};

export const capitalizeFirstLetter = (value?: string): string => {
  if (!value) {
    return '';
  }

  return value[0].toUpperCase() + value.slice(1).toLowerCase();
};

export const joinArrayToFormattedString = (arr: string[]): string => {
  if (!arr || arr.length === 0) return '';
  if (arr.length === 1) return arr[0];
  const firsts = arr.slice(0, arr.length - 1);
  const last = arr[arr.length - 1];
  return firsts.join(', ') + ' and ' + last;
};

export const walletRequirementsString = (
  walletRequirements: BLOCKCHAIN_KEY[],
) => {
  const requirements = walletRequirements?.map((requirement) =>
    capitalizeFirstLetter(requirement),
  );
  return joinArrayToFormattedString(requirements);
};
