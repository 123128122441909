import {
  Account,
  NetworkDetails,
  TX_DESCRIPTION_TYPE,
  convertBaseUnitsToDecimal,
  div,
  formatValue,
  unixToLocalDateTime,
  useAccount,
  useAsync,
  useAuth,
  useGetClaims,
  useGetPurchases,
  useNetworks,
} from '@tokensoft-web/common-utils';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import SaleContractDefinition from '../contracts/SaleContract.json';
import { TransactionHistory, TransactionHistoryObj } from '../utils/interface';
import { useGetSaleDistributors } from './distribution-service';

export const useGetTransactionData = () => {
  const { account } = useAccount();
  const { supportedNetworks } = useNetworks();
  const {
    user: { walletAddress },
  } = useAuth();
  const { results: purchases, loading: purchasesLoading } = useGetPurchases();
  const { results: claims, loading: claimsLoading } = useGetClaims();
  const { results: distributors, loading: distributorsLoading } =
    useGetSaleDistributors();
  const [transactionData, setTransactionData] = useState({});
  const [claimsBySales, setClaimsBySales] = useState({});
  const [purchaseBySales, setPurchasesBySales] = useState({});
  const [claimableDistributors, setClaimableDistributors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(null);

  useEffect(() => {
    if (!purchases || !purchases.length) {
      return;
    }

    const formattedPurchases = formatPurchases(
      purchases,
      supportedNetworks,
      account,
    );
    setPurchasesBySales(formattedPurchases);
  }, [purchases, reload]);

  useEffect(() => {
    if (purchaseBySales || claimsBySales) {
      const _transactionData = aggregateTransactionHistory(
        purchaseBySales,
        claimsBySales,
      );
      setTransactionData(_transactionData);
    }
  }, [purchaseBySales, claimsBySales]);

  useEffect(() => {
    if (!claims) {
      return;
    }

    const formattedClaims = formatClaims(claims, account);
    setClaimsBySales(formattedClaims);
  }, [claims, reload]);

  useEffect(() => {
    if (!distributors) {
      return;
    }

    setClaimableDistributors(distributors);
  }, [distributors, reload]);

  useEffect(() => {
    if (!purchasesLoading && !claimsLoading && !distributorsLoading) {
      setLoading(false);
    }
  }, [purchasesLoading, claimsLoading, distributorsLoading]);

  const refresh = () => {
    setTransactionData({});
    setPurchasesBySales({});
    setClaimsBySales({});
    setClaimableDistributors([]);
    setLoading(true);
    setReload(new Date());
  };

  useEffect(() => {
    if (walletAddress) {
      refresh();
    }
  }, [walletAddress]);

  return {
    transactionData: transactionData,
    purchasesBySales: purchaseBySales,
    purchasesLoading: purchasesLoading,
    claimsBySales: claimsBySales,
    claimsLoading: claimsLoading,
    claimableDistributors: claimableDistributors,
    loading: loading,
    refresh: refresh,
  };
};

const formatClaims = (claims: any, account?: Account) => {
  const formattedClaims = [];

  claims.forEach((claim) => {
    const convertedValue = convertBaseUnitsToDecimal(
      claim.amount,
      Number(claim.distributionRecord.distributor.tokenDecimals),
    );
    formattedClaims.push({
      id: claim.id,
      value: claim.amount,
      description: formatDescription(
        TX_DESCRIPTION_TYPE.CLAIM,
        claim.distributionRecord.distributor.tokenSymbol,
        null,
        convertedValue,
        null,
      ),
      transactionHash: claim.transactionHash,
      createdAt: unixToLocalDateTime(claim.createdAt, account?.timezone),
      type: TX_DESCRIPTION_TYPE.CLAIM,
      chainId: claim.chainId,
      sale: claim.sale,
    });
  });

  return sortBySales(formattedClaims);
};

const formatPurchases = (
  purchases: any,
  supportedNetworks: NetworkDetails[],
  account?: Account,
) => {
  const formattedPurchases = purchases.map((purchase) => {
    const { symbol: nativeSymbol } = supportedNetworks.find(
      (network) => network.id == purchase.chainId,
    );
    const { symbol, price } = purchase.sale;

    const saleTokensBought = formatValue(
      div(purchase.baseCurrencyValue, price),
    );
    const paymentTokensSpent = formatValue(
      convertBaseUnitsToDecimal(
        purchase.spent,
        purchase.paymentMethod.decimals,
      ),
    );

    const paymentTokenSymbol = purchase.paymentMethod.symbol || nativeSymbol;

    const description = formatDescription(
      TX_DESCRIPTION_TYPE.PURCHASE,
      symbol,
      saleTokensBought,
      paymentTokensSpent,
      paymentTokenSymbol,
    );

    return {
      ...purchase,
      description: description,
      type: TX_DESCRIPTION_TYPE.PURCHASE,
      chainId: purchase.chainId,
      createdAt: unixToLocalDateTime(purchase.createdAt, account?.timezone),
    };
  });

  return sortBySales(formattedPurchases);
};

const formatDescription = (
  type: TX_DESCRIPTION_TYPE,
  saleSymbol?: string,
  bought?: string | number,
  value?: string | number,
  purchaseSymbol?: string,
): string => {
  const formattedValue = formatValue(value, null);
  if (type === TX_DESCRIPTION_TYPE.PURCHASE) {
    return `Purchased ${formatValue(
      bought,
    )} ${saleSymbol} for ${formattedValue} ${purchaseSymbol}`;
  } else if (type === TX_DESCRIPTION_TYPE.CLAIM) {
    return `Claimed ${formattedValue} ${saleSymbol}`;
  } else if (type === TX_DESCRIPTION_TYPE.VOID) {
    return 'Voided';
  } else {
    return String(value);
  }
};

const sortBySales = (items: any) => {
  const sortedData = {};
  items.forEach((item) => {
    if (item) {
      if (sortedData[item.sale.id]) {
        sortedData[item.sale.id].push(item);
      } else {
        sortedData[item.sale.id] = [item];
      }
    }
  });

  return sortedData;
};

export const usePurchaseTotal = (saleId) => {
  const { data, run } = useAsync();

  useEffect(() => {
    if (saleId) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(
        saleId,
        SaleContractDefinition,
        provider,
      );

      run(contract.metrics());

      // query contract every 30 seconds
      const refetchPurchaseTotal = setInterval(() => {
        run(contract.metrics());
      }, 30 * 1000);

      return () => clearInterval(refetchPurchaseTotal);
    }
  }, [run, saleId]);

  return data?.purchaseTotal.toString() || '0';
};

/**
 * Aggregates sales and claim manager data (claims + voids) and orders by createdAt
 * @param purchasesBySales
 * @param claimsBySales
 */
export const aggregateTransactionHistory = (
  purchasesBySales,
  claimsBySales,
): TransactionHistoryObj => {
  if (!purchasesBySales) {
    return {};
  }

  const transactionHistory = {};
  Object.keys(purchasesBySales).forEach((saleId) => {
    const purchaseData = purchasesBySales[saleId];
    const claimData = claimsBySales[saleId];

    let saleActivites = purchaseData;

    if (claimData) {
      saleActivites = purchaseData.concat(claimData);
    }

    const sortedTransactions = saleActivites.sort(
      (d1: TransactionHistory, d2: TransactionHistory) =>
        new Date(d2.createdAt).getTime() - new Date(d1.createdAt).getTime(),
    );
    transactionHistory[saleId] = sortedTransactions;
  });

  return transactionHistory;
};
