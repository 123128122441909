import {
  CheckboxInput,
  Col,
  DocumentLink,
  Stacked,
  Text,
} from '@tokensoft-web/common-ui';
import { EVENT_TYPE } from '@tokensoft-web/common-utils';
import { useState } from 'react';

const EventDocuments = ({
  documents,
  setAcceptedDocs,
  eventType,
  requiresSignature,
}: {
  documents: any;
  setAcceptedDocs(boolean): void;
  eventType: EVENT_TYPE;
  requiresSignature: boolean;
}) => {
  const [checkedState, setCheckedState] = useState([]);

  const handleCheckboxChange = (id, changed) => {
    let updatedCheckedState = checkedState;
    if (changed?.target?.checked) {
      updatedCheckedState.push(true);
    } else {
      updatedCheckedState.pop();
    }

    setCheckedState(updatedCheckedState);
    setAcceptedDocs(checkedState.length === documents.length);
  };

  const renderAgreementIntroText = () => {
    switch (eventType) {
      case EVENT_TYPE.RAISE:
      case EVENT_TYPE.SALE:
        if (requiresSignature) {
          return (
            <Text>
              Please review the following documents to make sure you understand
              the terms of your purchase and to make sure the details are
              correct.
            </Text>
          );
        }

        return (
          <Text>{`You must review and accept the following ${
            documents.length === 1 ? 'document' : 'documents'
          } to participate in this offering:`}</Text>
        );
      default:
        if (requiresSignature) {
          return (
            <Text>
              Please review the following documents to make sure you understand
              the terms of this event and to make sure the details are correct.
            </Text>
          );
        }
        return (
          <Text>{`You must review and accept the following ${
            documents.length === 1 ? 'document' : 'documents'
          } to participate in this event:`}</Text>
        );
    }
  };

  const renderAgreementDocumentText = (doc: any) => {
    if (requiresSignature) {
      return (
        <Text>
          By checking this box I agree to comply with and be bound by all terms
          of the <DocumentLink document={doc} />
        </Text>
      );
    }

    return (
      <Text>
        I have read, understood and agree to the terms listed in the{' '}
        <DocumentLink document={doc} />
      </Text>
    );
  };

  return documents && documents.length > 0 ? (
    <Col gap={5}>
      {renderAgreementIntroText()}

      <Stacked gap={2.5}>
        {documents.map((doc, i) => {
          return (
            <Col yalign={'start'} key={i}>
              <CheckboxInput
                onClick={(changed) => {
                  handleCheckboxChange(i, changed);
                }}
                name={`acceptedDocs-${i}`}
              >
                {renderAgreementDocumentText(doc.eventDocumentVersion)}
              </CheckboxInput>
            </Col>
          );
        })}
      </Stacked>
    </Col>
  ) : null;
};

export default EventDocuments;
