import { SALE_STATUS, getUtcNowUnix } from '@tokensoft-web/common-utils';
import { useEffect, useState } from 'react';
import { useSale } from '../../contexts/sale/sale-context';
import { MAX_DELAY } from '../../utils/constant';
import { SALE_STEP } from '../../utils/enums';
import CurrentSale from './current-sale';
import SaleDetail from './sale-detail';

const Sale = () => {
  const [page, setPage] = useState<SALE_STEP | null>(null);
  const { sale } = useSale();

  useEffect(() => {
    if (sale.proof && sale.status === SALE_STATUS.CURRENT) {
      setPage(SALE_STEP.SALE);
    } else {
      setPage(SALE_STEP.SALE_DETAILS);
    }

    if (sale?.startTime) {
      const now = getUtcNowUnix();
      const refreshDelay = parseInt(sale.startTime) - now;

      if (refreshDelay > 0) {
        const timer = setTimeout(
          () => {
            window.location.reload();
          },
          Math.min(MAX_DELAY, refreshDelay * 1000),
        );

        return () => clearTimeout(timer);
      }
    }
  }, [sale]);

  const renderSaleStep = () => {
    switch (page) {
      case SALE_STEP.SALE_DETAILS:
        return <SaleDetail />;
      case SALE_STEP.SALE:
        return <CurrentSale />;
      default:
        return null;
    }
  };

  if (sale) {
    return renderSaleStep();
  }
};

export default Sale;
