import { NetworkDetails } from '@tokensoft-web/common-utils';
import classNames from 'classnames';
import { NetworkIcon } from '../icon/network-icon';
import { Col } from '../layout/container/col';
import { Flex } from '../layout/container/flex';
import { Text } from '../typography/text';
import './network-select.css';

type NetworkSelectProps = {
  supportedNetworks: NetworkDetails[];
  disabled?: boolean;
  selected?: number;
  setSelected?: Function;
  align?: string;
  subtitle?: string;
  direction: string;
  size: string;
  className?: string;
};

export const NetworkSelect = ({
  supportedNetworks,
  disabled,
  selected,
  setSelected,
  subtitle,
  direction = 'col',
  size = 'lg',
  className = '',
}: NetworkSelectProps) => {
  const key = 'network-select';

  const handleSelectNetwork = (network) => {
    setSelected && setSelected(network.id);
  };

  return (
    <Flex
      className={classNames(
        `${key}-item-container`,
        className,
        direction,
        size,
        disabled ? 'disabled' : '',
      )}
      gap={5}
      data-testid={`${key}-item-container`}
    >
      {subtitle ? (
        <Text textAlign={direction === 'row' ? 'left' : 'center'}>
          {subtitle}
        </Text>
      ) : null}

      <Flex
        className={`${key}-items`}
        width={direction === 'col' ? 'full' : 'fit'}
        direction={direction}
        data-testid={`${key}-items`}
      >
        {supportedNetworks?.length > 0
          ? supportedNetworks.map((network, i) => (
              <div
                key={i}
                onClick={() => {
                  if (!disabled) {
                    handleSelectNetwork(network);
                  }
                }}
              >
                <Col
                  className={classNames(
                    `${key}-item`,
                    selected === network.id ? 'selected' : '',
                  )}
                  data-testid={`${key}-item-${i}`}
                  key={i}
                >
                  <NetworkIcon
                    data-testid={`${key}-item-${i}-icon`}
                    chainId={network.chainId}
                    logoSize={size}
                    width={size === 'lg' ? 'full' : 'fit'}
                    place={size === 'lg' ? 'between-center' : 'end-center'}
                  />
                </Col>
              </div>
            ))
          : null}
      </Flex>
    </Flex>
  );
};
