import { LoadingIndicator } from '@tokensoft-web/common-ui';
import {
  getVerificationStatusForTier,
  isEmptyObject,
  KYC_TIER_STATE,
  TIER_NAME,
  useAccount,
  VERIFICATION_STATUS,
} from '@tokensoft-web/common-utils';
import { useEffect, useState } from 'react';
import { IdentityCompleted } from '../../components/identity/identity-completed';
import { IdentityFailed } from '../../components/identity/identity-failed';
import { IdentityForm } from '../../components/identity/identity-form';
import { IdentityPending } from '../../components/identity/identity-pending';

const IdentityPage = () => {
  const { account, tier1CurrentState } = useAccount();
  let [identityStatus, setIdentityStatus] = useState();

  useEffect(() => {
    if (!isEmptyObject(account)) {
      setIdentityStatus(
        getVerificationStatusForTier(account?.kyc?.tiers, TIER_NAME.IDENTITY),
      );
    }
  }, [account]);

  if (identityStatus) {
    switch (identityStatus) {
      case VERIFICATION_STATUS.COMPLETED:
        return <IdentityCompleted />;
      case VERIFICATION_STATUS.FAILED:
        return <IdentityFailed />;
      case VERIFICATION_STATUS.PENDING:
        if (
          [
            KYC_TIER_STATE.T1_MANUAL_REVIEW,
            KYC_TIER_STATE.T1_DOCUMENT_REVIEW,
          ].includes(tier1CurrentState)
        ) {
          return <IdentityPending />;
        }

        return <IdentityForm />;
      case VERIFICATION_STATUS.NONE:
      case VERIFICATION_STATUS.NEW:
      default:
        return <IdentityForm />;
    }
  } else {
    return <LoadingIndicator />;
  }
};

export default IdentityPage;
