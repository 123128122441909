import classNames from 'classnames';
import { LayoutType } from '../../../../../../payment/src/utils/enums';
import { Text } from '../../typography/text';
import { Col } from '../container/col';
import { ICON_100 } from './icons/icon_100';
import { TITLE_100 } from './icons/title_100';
import { TITLE_ICON_50_50 } from './icons/title_icon_50_50';
import { TITLE_ICON_50_50_REVERSED } from './icons/title_icon_50_50_reversed';
import { TITLE_ICON_CENTERED } from './icons/title_icon_centered';
import { TITLE_ONLY_100_CENTERED } from './icons/title_only_100_centered';
import { TITLE_ONLY_100_LEFT } from './icons/title_only_100_left';
import { TITLE_ONLY_100_RIGHT } from './icons/title_only_100_right';
interface LayoutIconProps {
  layout: any;
  value: any;
  setValue: Function;
}

export const LayoutIcon = ({ layout, value, setValue }: LayoutIconProps) => {
  const renderLayoutIcon = () => {
    switch (layout.type) {
      case LayoutType.TITLE_ICON_50_50:
        return <TITLE_ICON_50_50 />;
      case LayoutType.TITLE_ICON_50_50_REVERSED:
        return <TITLE_ICON_50_50_REVERSED />;
      case LayoutType.ICON_100:
        return <ICON_100 />;
      case LayoutType.TITLE_100:
        return <TITLE_100 />;
      case LayoutType.TITLE_ICON_CENTERED:
        return <TITLE_ICON_CENTERED />;
      case LayoutType.TITLE_ONLY_100_CENTERED:
        return <TITLE_ONLY_100_CENTERED />;
      case LayoutType.TITLE_ONLY_100_LEFT:
        return <TITLE_ONLY_100_LEFT />;
      case LayoutType.TITLE_ONLY_100_RIGHT:
        return <TITLE_ONLY_100_RIGHT />;
    }
  };

  return (
    <Col
      onClick={() => setValue(layout.type)}
      className={'hover:cursor-pointer'}
      ygap={2.5}
    >
      <Text>{layout.name}</Text>
      <div
        className={classNames(
          'rounded border border-solid border-gray-400',
          layout.type === value && 'border-primary-light border-2',
        )}
      >
        {renderLayoutIcon()}
      </div>
    </Col>
  );
};
