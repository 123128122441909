import { ethers } from 'ethers';

export const getEthersContract = (address, abi, provider) =>
  new ethers.Contract(address, abi, provider);

export const getSignatureHash = (txData: any[]) => {
  return ethers.utils.arrayify(
    ethers.utils.solidityKeccak256(
      txData.map((t) => t.type),
      txData.map((t) => t.value),
    ),
  );
};
