import { ReactNode } from 'react';
import { useWizard } from 'react-use-wizard';
import { Col } from '../layout/container/col';
import { ProgressBar } from '../progress-bar/progress-bar';

export const StepFooter = ({
  children,
  hideProgressBar = false,
}: {
  children: ReactNode;
  hideProgressBar?: boolean;
}) => {
  const { activeStep, stepCount } = useWizard();

  return (
    <Col yalign='end' gap={12} className='mt-auto'>
      {children}
      {hideProgressBar || stepCount === 1 ? null : (
        <ProgressBar
          progress={[{ value: ((activeStep + 1) / stepCount) * 100 }]}
        />
      )}
    </Col>
  );
};
