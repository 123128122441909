import {
  BackButton,
  ButtonRow,
  ContinueButton,
  Error,
  PageSubtitle,
  PageTitle,
  Step,
  StepFooter,
  StepHeader,
  WizardBreadcrumb,
} from '@tokensoft-web/common-ui';
import { useModal, useNetworks, useWallet } from '@tokensoft-web/common-utils';
import { createContext, useState } from 'react';
import { HiRocketLaunch } from 'react-icons/hi2';
import { Link, useNavigate } from 'react-router-dom';
import { Wizard } from 'react-use-wizard';
import CreateSaleModal from '../../../components/admin/sales/create-sale-modal';
import SaleDeploy from '../../../components/admin/sales/sale-deploy';
import SaleParticipants from '../../../components/admin/sales/sale-participants';
import SalePaymentConfig from '../../../components/admin/sales/sale-payment-config';
import SaleSetup from '../../../components/admin/sales/sale-setup';
import { useEvent } from '../../../contexts/event/event-context';

const WizardContext = createContext(null);

export const DeploySaleWizard = () => {
  const navigate = useNavigate();
  const { connectedChainId } = useWallet();
  const { getNetworkDetails } = useNetworks();
  const { event, isOnChainEvent } = useEvent();
  const { showModal } = useModal();

  const [wizardState, setWizardState] = useState<any>({
    setupFormValid: false,
    onChainConfigFormValid: false,
    participantsFormValid: false,
    eventId: event?.id,
    projectId: event?.project?.id,
    startTime: event?.startTime,
    endTime: event?.endTime,
    networkId: event?.networkId || connectedChainId,
    includeEligibleEventUsersOnly: true,
  });

  const selectedNetworkDetails = getNetworkDetails(wizardState?.networkId);

  const showDeployModal = () => {
    showModal({
      className: 'md',
      content: <CreateSaleModal context={wizardState} event={event} />,
    });
  };

  // when user clicks deploy button, switch to correct network before opening modal
  const { switchChain } = useWallet();
  const isWrongNetwork = connectedChainId !== wizardState.networkId;
  const handleClick = () =>
    isWrongNetwork
      ? switchChain(wizardState.networkId, { onSuccess: showDeployModal })
      : showDeployModal();

  if (event && isOnChainEvent && !wizardState?.onChainConfigFormValid) {
    return (
      <>
        <Error
          title='Event Already Deployed'
          message='It looks like this event has already been deployed.'
        />
        <ButtonRow place={'center'}>
          <Link
            className='btn btn-primary'
            to={`/admin/project/${event.project.id}/event/${event.id}`}
          >
            Back to Event
          </Link>
        </ButtonRow>
      </>
    );
  }

  return (
    <WizardContext.Provider
      value={{
        wizardState,
        setWizardState,
      }}
    >
      <Wizard>
        <Step>
          <StepHeader>
            <WizardBreadcrumb>STEP 1</WizardBreadcrumb>
            <PageTitle>Network &amp; Payment Configuration</PageTitle>
            <PageSubtitle>
              Now that we have the basics, let’s set up some specific parameters
              before we move on.
            </PageSubtitle>
          </StepHeader>

          <SalePaymentConfig
            context={wizardState}
            setContext={setWizardState}
          />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton
                label={'Cancel'}
                onClick={() =>
                  navigate(
                    `/admin/project/${event.project.id}/event/${event.id}`,
                  )
                }
              />
              <ContinueButton disabled={!wizardState.onChainConfigFormValid} />
            </ButtonRow>
          </StepFooter>
        </Step>

        <Step>
          <StepHeader>
            <WizardBreadcrumb>STEP 2</WizardBreadcrumb>
            <PageTitle>Sale Basics</PageTitle>
            <PageSubtitle>
              First we need to setup some basic details about your sale, before
              we can proceed. Please fill out the fields below to start the
              process.
            </PageSubtitle>
          </StepHeader>

          <SaleSetup context={wizardState} setContext={setWizardState} />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton />
              <ContinueButton disabled={!wizardState.setupFormValid} />
            </ButtonRow>
          </StepFooter>
        </Step>

        <Step>
          <StepHeader>
            <WizardBreadcrumb>STEP 3</WizardBreadcrumb>
            <PageTitle>Participants</PageTitle>
            <PageSubtitle>
              Specify who can participate in the event. Everyone in the
              participant list must also meet all event eligibility requirements
              before they can purchase in this app.
            </PageSubtitle>
          </StepHeader>

          <SaleParticipants context={wizardState} setContext={setWizardState} />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton />
              <ContinueButton disabled={!wizardState.participantsFormValid} />
            </ButtonRow>
          </StepFooter>
        </Step>

        <Step>
          <StepHeader>
            <WizardBreadcrumb>STEP 4</WizardBreadcrumb>
            <PageTitle>Deploy</PageTitle>
            <PageSubtitle>
              It’s time to deploy the sale contract to{' '}
              {selectedNetworkDetails?.name || 'Unknown Network'}. Let’s review
              settings first.
            </PageSubtitle>
          </StepHeader>

          <SaleDeploy context={wizardState} setContext={setWizardState} />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton />
              <ContinueButton
                label='Deploy'
                icon={<HiRocketLaunch />}
                onClick={handleClick}
              />
            </ButtonRow>
          </StepFooter>
        </Step>
      </Wizard>
    </WizardContext.Provider>
  );
};
