import {
  RainbowKitAuthenticationProvider,
  RainbowKitProvider,
  createAuthenticationAdapter,
  lightTheme,
} from '@rainbow-me/rainbowkit';
import { SmartContractNetworkSelect } from '@tokensoft-web/common-ui';
import {
  getProjectPrivacyDocumentsSync,
  getSignStatement,
} from '@tokensoft-web/common-utils';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { SiweMessage } from 'siwe';
import { useAccount as useWagmiAccount } from 'wagmi';
import { useAnonymousAuthApiClient } from '../../client/anonymous-auth-api-client';
import { getNonce, getNonceSync } from '../../service/authentication-service';
import { DEFAULT_PRIMARY_COLOR } from '../../util/constant';
import { useAuth } from '../auth/auth-context';
import { useConfiguration } from '../configuration/configuration-context';
import { useWagmi } from '../wagmi/wagmi-context';

declare const window: any;

export interface WagmiProviderProps {
  children?: ReactNode;
}

export const RainbowkitProvider: FC<WagmiProviderProps> = ({ children }) => {
  const { isAuthenticated, authenticate, disconnect } = useAuth();
  const { defaultChain } = useWagmi();
  const { isDisconnected } = useWagmiAccount();
  const { configuration } = useConfiguration();
  const [resetDate, setResetDate] = useState(0);
  const anonymousAuthClient = useAnonymousAuthApiClient();

  const authenticationAdapter = useMemo(() => {
    console.log('creating new authentication adapter');

    return createAuthenticationAdapter({
      /**
       * Generate a dummy nonce as we won't be using this value anyway.  We will
       * get a nonce from the backend in the createMessage function below.
       */
      getNonce: async () => {
        return getNonce();
      },

      createMessage: ({ nonce, address: messageAddress, chainId }) => {
        console.log(
          'adapter: creating message for wallet address',
          messageAddress,
        );

        nonce = getNonceSync(messageAddress);

        const issuer = configuration?.project?.name;
        const documents = getProjectPrivacyDocumentsSync(
          configuration?.project?.id,
        );

        return new SiweMessage({
          domain: window.location.host,
          address: messageAddress,
          statement: getSignStatement(issuer, documents, window.location.host),
          uri: window.location.origin,
          version: '1',
          chainId: chainId,
          nonce: nonce,
        });
      },

      getMessageBody: ({ message }) => {
        console.log('adapter: preparing message');
        return message.prepareMessage();
      },

      verify: async ({ message, signature }) => {
        console.log('adapter: verifying wallet address', message.address);

        const data = {
          walletAddress: message.address,
          signature,
          message,
          nonce: message.nonce,
        };

        try {
          const response = await anonymousAuthClient('wallets/connect', {
            data: data,
          });

          authenticate({ ...response, walletAddress: message.address });

          console.log(
            'adapter: authentication success for wallet address',
            message.address,
          );
          return Boolean(true);
        } catch (e) {
          console.log({ e });
        }

        return Boolean(false);
      },

      signOut: async () => {
        console.log('adapter: signing out...');
        setResetDate(new Date().getTime());
        disconnect();
      },
    });
  }, []);

  useEffect(() => {
    if (isDisconnected) {
      setResetDate(new Date().getTime());
    }
  }, [isDisconnected]);

  return (
    <>
      <RainbowKitAuthenticationProvider
        adapter={authenticationAdapter}
        status={isAuthenticated ? 'authenticated' : 'unauthenticated'}
      >
        <RainbowKitProvider
          key={`rainbowkit-${resetDate}`}
          initialChain={defaultChain}
          modalSize={'compact'}
          theme={lightTheme({
            accentColor:
              configuration?.project?.theme?.primaryColor ||
              DEFAULT_PRIMARY_COLOR,
            accentColorForeground: 'white',
            borderRadius: 'medium',
          })}
          appInfo={{
            appName: 'Tokensoft',
            learnMoreUrl: 'https://www.tokensoft.io',
            disclaimer: ({ Text, Link }) => (
              <Text>
                <div className={'min-h-[800px] flex justify-center mb-1'}>
                  <SmartContractNetworkSelect />
                </div>
                <span>
                  By connecting your wallet, you agree to the{' '}
                  <Link href='https://www.tokensoft.io/terms'>
                    Terms and Conditions
                  </Link>
                  ,{' '}
                  <Link href='https://www.tokensoft.io/privacy'>
                    Privacy Policy
                  </Link>
                  , and{' '}
                  <Link href='https://www.tokensoft.io/cookies'>
                    Cookie Policy
                  </Link>
                  .
                </span>
              </Text>
            ),
          }}
        >
          {children}
        </RainbowKitProvider>
      </RainbowKitAuthenticationProvider>
    </>
  );
};
