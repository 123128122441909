export const FrontArrowIcon = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.49127 9.70312H12.2886C12.4751 9.70312 12.6539 9.62905 12.7858 9.49718C12.9176 9.36532 12.9917 9.18648 12.9917 9C12.9917 8.81352 12.9176 8.63468 12.7858 8.50282C12.6539 8.37095 12.4751 8.29688 12.2886 8.29688H4.49127C4.30479 8.29688 4.12595 8.37095 3.99409 8.50282C3.86222 8.63468 3.78815 8.81352 3.78815 9C3.78815 9.18648 3.86222 9.36532 3.99409 9.49718C4.12595 9.62905 4.30479 9.70312 4.49127 9.70312V9.70312Z'
        fill='currentColor'
      />
      <path
        d='M10.3653 13.0682C10.4578 13.0685 10.5493 13.0505 10.6348 13.0152C10.7202 12.9799 10.7978 12.928 10.8631 12.8626L13.8514 9.8743C14.0826 9.64245 14.2124 9.32841 14.2124 9.00101C14.2124 8.67362 14.0826 8.35957 13.8514 8.12773L10.8631 5.13945C10.7978 5.07417 10.7203 5.02239 10.635 4.98707C10.5497 4.95175 10.4582 4.93358 10.3659 4.93359C10.2736 4.93361 10.1821 4.95181 10.0968 4.98716C10.0115 5.02252 9.93402 5.07432 9.86874 5.13963C9.80346 5.20493 9.75168 5.28245 9.71636 5.36777C9.68104 5.45309 9.66287 5.54452 9.66288 5.63686C9.6629 5.7292 9.6811 5.82063 9.71645 5.90593C9.75181 5.99124 9.80361 6.06874 9.86892 6.13402L12.7352 9.00101L9.86821 11.868C9.76991 11.9663 9.70297 12.0916 9.67585 12.228C9.64873 12.3644 9.66266 12.5057 9.71586 12.6342C9.76907 12.7626 9.85917 12.8724 9.97477 12.9497C10.0904 13.027 10.2263 13.0682 10.3653 13.0682V13.0682Z'
        fill='currentColor'
      />
      <path
        d='M3.58594 18H14.4141C15.3648 17.9989 16.2762 17.6207 16.9485 16.9485C17.6207 16.2762 17.9989 15.3648 18 14.4141V3.58594C17.9989 2.63523 17.6207 1.72378 16.9485 1.05153C16.2762 0.379278 15.3648 0.00111651 14.4141 0L3.58594 0C2.63523 0.00111651 1.72378 0.379278 1.05153 1.05153C0.379278 1.72378 0.00111651 2.63523 0 3.58594V14.4141C0.00111651 15.3648 0.379278 16.2762 1.05153 16.9485C1.72378 17.6207 2.63523 17.9989 3.58594 18V18ZM14.4141 1.40625C14.992 1.4069 15.546 1.63676 15.9546 2.04539C16.3632 2.45401 16.5931 3.00805 16.5938 3.58594V14.4141C16.5931 14.992 16.3632 15.546 15.9546 15.9546C15.546 16.3632 14.992 16.5931 14.4141 16.5938H3.58594C3.00805 16.5931 2.45401 16.3632 2.04539 15.9546C1.63676 15.546 1.4069 14.992 1.40625 14.4141V3.58594C1.4069 3.00805 1.63676 2.45401 2.04539 2.04539C2.45401 1.63676 3.00805 1.4069 3.58594 1.40625H14.4141Z'
        fill='currentColor'
      />
    </svg>
  );
};
