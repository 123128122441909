import * as Collapsible from '@radix-ui/react-collapsible';
import { useState } from 'react';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import { useDistributor } from '../../contexts/distributor/distributor-context';

const ClaimVestingDetails = () => {
  const { event } = useDistributor();
  const [isOpen, setIsOpen] = useState(false);

  if (!event?.disclaimerText) {
    return <></>;
  }

  return (
    <Collapsible.Root open={isOpen}>
      <div
        className={'flex items-center font-bold text-lg'}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Collapsible.Trigger asChild>
          {isOpen ? (
            <FaMinusCircle className='mr-2 self-center' />
          ) : (
            <FaPlusCircle className='mr-2 self-center' />
          )}
        </Collapsible.Trigger>

        <span>Disclaimers</span>
      </div>
      <Collapsible.Content>
        <div className={'py-4'}>
          <div
            className='pl-6'
            dangerouslySetInnerHTML={{ __html: event?.disclaimerText }}
          ></div>
        </div>
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

export default ClaimVestingDetails;
