import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { generateNonce } from 'siwe';
import { useAnonymousAuthApiClient } from '../client/anonymous-auth-api-client';
import { useAuthApiClient } from '../client/auth-api-client';
import { useAuth } from '../context/auth/auth-context';

export const useCreateReferral = () => {
  const authApiClient = useAuthApiClient();
  const {
    user: { authId, walletAddress },
  } = useAuth();

  return useMutation({
    mutationFn: (referralCode: string) => {
      return authApiClient('referrals', {
        data: {
          userId: authId,
          walletAddress: walletAddress,
          referralCode: referralCode,
        },
      });
    },
  });
};

export const useGetNonce = () => {
  const client = useAnonymousAuthApiClient();

  return useMutation({
    mutationFn: (walletAddress: string) => {
      return client(`wallets/nonce`, {
        data: { walletAddress },
      });
    },
  });
};

export const useGetToken = () => {
  const client = useAnonymousAuthApiClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client('wallets/connect', {
        data: data,
      });
    },
  });
};

export const useRefreshToken = () => {
  const client = useAuthApiClient();

  return useMutation({
    mutationFn: ({
      walletAddress,
      token,
    }: {
      walletAddress: string;
      token: string;
    }) => {
      return client('token/refresh', {
        data: { walletAddress },
      });
    },
  });
};

export const useSetPrimaryWalletAddress = () => {
  const client = useAuthApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`wallets/primary/${data.walletAddress}`, {
        method: 'post',
        data: data,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['account'] }),
  });
};

export const useCheckUserWalletAddress = () => {
  const client = useAuthApiClient();

  const [walletAddress, setWalletAddress] = useState('');

  const result = useQuery({
    queryKey: ['walletAddresses', walletAddress],
    queryFn: () =>
      client(`wallets/check/${walletAddress}`).then((data) => data.status),
    enabled: false,
  });

  useEffect(() => {
    if (result && walletAddress) {
      result.refetch();
    }
  }, [walletAddress]);

  return {
    ...result,
    status: result?.data,
    checkUserWalletAddress: setWalletAddress,
  };
};

export const useCreateUserPolkadotWallet = () => {
  const client = useAuthApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`wallets/polkadot`, {
        method: 'post',
        data: data,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['account'] }),
  });
};

export const useTransferUserWalletAddress = () => {
  const client = useAuthApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`wallets/transfer`, {
        method: 'post',
        data: data,
      });
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['account'] }),
  });
};

export const useRemoveUserWalletAddress = () => {
  const client = useAuthApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`wallets/${data.id}`, {
        method: 'delete',
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['account'] }),
  });
};

/**
 * HACK ALERT! Rainbowkit's authentication provider is not aware of the connected wallet address
 * when fetching a nonce from the backend.  Instead, we are forced to fetch the nonce in the createMessage()
 * function of the authentication provider.  However, the createMessage() function is not async so
 * we have to go old school here and fetch the nonce syncrhonously.
 */
export const getNonceSync = (messageAddress) => {
  const httpRequest = new XMLHttpRequest();
  httpRequest.open(
    'POST',
    `${process.env.REACT_APP_API_URL}/auth/api/v1/wallets/nonce`,
    false,
  );
  httpRequest.setRequestHeader('Content-type', 'application/json');
  httpRequest.send(JSON.stringify({ walletAddress: messageAddress }));

  const nonceResponse = JSON.parse(httpRequest.responseText);
  return nonceResponse.nonce;
};

export const getNonce = () => {
  return generateNonce();
};
