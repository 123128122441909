import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import { createContext, useContext } from 'react';
type WagmiConfig = ReturnType<typeof getDefaultConfig>;

export interface WagmiContextProps {
  activeChains: any[];
  defaultChain: any;
  updateDefaultChain: Function;
  wagmiConfig: WagmiConfig;
}

export const WagmiContext = createContext<WagmiContextProps | undefined>(
  undefined,
);
WagmiContext.displayName = 'WagmiContext';

export const useWagmi = (): WagmiContextProps => {
  const context = useContext(WagmiContext);

  if (!context) {
    throw new Error(
      'WagmiProvider context is undefined, please verify you are calling useWagmi() as child of a <WagmiProvider> component.',
    );
  }

  return context;
};
