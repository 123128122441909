import { Col, EventSubtitle, EventTitle } from '@tokensoft-web/common-ui';
import EventHeaderWrapper from '../../event/event-header-wrapper';

const ConnextHeader = ({ subText }) => {
  return (
    <EventHeaderWrapper backgroundColor={'#000'}>
      <Col
        gap={2}
        width='1/2'
        className={'event-banner-text-container'}
        style={{ color: '#fff' }}
      >
        <EventTitle>{'NEXT'}</EventTitle>
        <EventSubtitle>{subText}</EventSubtitle>
      </Col>
      <Col
        width='1/2'
        className={'event-banner-logo-container'}
        place={'center'}
      >
        <svg
          className='event-banner-logo'
          width='100%'
          height='100%'
          viewBox='0 0 206 206'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M102.652 202.13C157.4 202.13 201.783 157.748 201.783 103C201.783 48.2516 157.4 3.8694 102.652 3.8694C47.9039 3.8694 3.52174 48.2516 3.52174 103C3.52174 157.748 47.9039 202.13 102.652 202.13Z'
            fill='black'
            stroke='url(#paint0_linear_2731_2619)'
            strokeWidth='6.95652'
          />
          <path
            d='M62.3722 112.466L83.19 133.105C84.8055 134.9 85.4786 139.387 82.5617 142.304C77.5356 147.465 71.2576 140.868 69.642 139.253L51.5168 121.213C45.2343 114.935 41.6891 105.556 50.7091 96.3606L72.5904 74.6543C70.5171 72.0201 69.3279 68.0935 72.8686 64.5529C76.9074 60.5096 81.0808 62.1296 83.9977 65.042L83.9124 65.1318L91.2675 72.4465L103.514 84.693C107.373 88.5972 109.797 92.4565 105.758 96.4908C101.719 100.53 96.8725 99.0487 93.0177 95.1894L81.8931 84.0647L64.1717 101.831C60.447 105.376 58.652 108.742 62.3767 112.462L62.3722 112.466ZM151.298 93.2193L133.217 75.3185C131.602 73.703 125.319 67.1063 120.298 72.267C117.381 75.1839 118.054 79.6714 119.669 81.4664L140.487 102.105C144.212 105.829 142.417 109.195 138.692 112.736L120.926 130.502L109.801 119.377C105.942 115.518 101.095 114.037 97.061 118.076C93.0222 122.115 95.4455 125.974 99.3047 129.874L111.551 142.12L118.933 149.502L118.866 149.57C121.783 152.486 125.956 154.237 129.991 150.198C133.581 146.608 132.311 142.632 130.188 139.993L152.11 118.071C161.309 108.872 157.764 99.4974 151.302 93.2149L151.298 93.2193Z'
            fill='white'
          />
          <defs>
            <linearGradient
              id='paint0_linear_2731_2619'
              x1='7'
              y1='198.652'
              x2='198.304'
              y2='198.652'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='#29C1FC' />
              <stop offset='0.265625' stopColor='#587BFD' />
              <stop offset='0.515625' stopColor='#AB00FF' />
              <stop offset='0.796875' stopColor='#D86292' />
              <stop offset='1' stopColor='#FBB03B' />
            </linearGradient>
          </defs>
        </svg>
      </Col>
    </EventHeaderWrapper>
  );
};

export default ConnextHeader;
