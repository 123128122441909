import { Card } from '@tokensoft-web/common-ui';
import { getDurationString, useAnalytics } from '@tokensoft-web/common-utils';
import { useSale } from '../../contexts/sale/sale-context';

const FairQueueStep = () => {
  const { sale, delay } = useSale();
  useAnalytics(`/sale/${sale.id}/fair-queue`);

  return (
    <>
      <Card title='Fair Queue'>
        <div className='flex flex-row justify-end items-center'>
          <div>
            <span>
              Continue in{' '}
              <span className='inline-block text-left'>
                {getDurationString(delay)}
              </span>
            </span>
          </div>
        </div>
      </Card>
    </>
  );
};

export default FairQueueStep;
