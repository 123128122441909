import classNames from 'classnames';
import { ReactNode } from 'react';
import { Row } from '../layout/container/row';

type CardBodyProps = {
  className?: string;
  open?: boolean;
  children?: ReactNode;
  [restProps: string]: any;
};

export const CardBody = ({
  children,
  open = true,
  className = '',
  ...restProps
}: CardBodyProps) => {
  return (
    <Row
      className={classNames(
        'inner-card-body collapsible',
        className,
        open ? 'mt-5' : 'collapsed',
      )}
      {...restProps}
    >
      {children}
    </Row>
  );
};
