export const enum KYC_TIER_STATE {
  T1_START = 1,
  T1_MANUAL_REVIEW = 3,
  T1_AWAITING_INITIAL_CHECKS = 4,
  T1_FAIL = 6,
  T1_COMPLETE = 7,
  T2_START = 8,
  T2_MANUAL_REVIEW = 9,
  T2_COMPLETE = 10,
  T2_FAIL = 11,
  T3_START = 12,
  T3_DOCUMENT_REVIEW = 13,
  T3_COMPLETE = 14,
  T3_FAIL = 15,
  T1_DOCUMENT_REVIEW = 16,
  T1_AWAITING_ADDITONAL_INFO = 17,
  T3_AWAITING_ADDITIONAL_INFO = 18,
}
