import {
  AnimatedCheckmark,
  Button,
  Col,
  ModalTitle,
  Stacked,
  Text,
} from '@tokensoft-web/common-ui';

interface DelegatingModalDelegateFoundProps {
  delegateToAddressOrENS: any;
  delegateSaved: any;
  handleOnContinue: Function;
}

const DelegatingModalDelegateFound = ({
  delegateToAddressOrENS,
  delegateSaved,
  handleOnContinue,
}: DelegatingModalDelegateFoundProps) => {
  return (
    <Stacked>
      <Col gap={5} place={'center'}>
        <AnimatedCheckmark size={'xxLarge'} />
        <ModalTitle>Delegate Wallet Recognized</ModalTitle>
        <Text>{`This Wallet: ${delegateToAddressOrENS} is registered as a delegate for this project.`}</Text>
        <br />
        <Text className='break-words'>{`Delegating votes to: ${delegateSaved.name}`}</Text>
      </Col>

      <Button className='btn-md' onClick={() => handleOnContinue()}>
        Continue
      </Button>
    </Stacked>
  );
};

export default DelegatingModalDelegateFound;
