import {
  Error,
  LoadingIndicator,
  PermissionDeniedError,
} from '@tokensoft-web/common-ui';
import {
  FEATURE,
  FEATURE_RESTRICTION,
  TIER_NAME,
  VERIFICATION_STATUS,
  getVerificationStatusForTier,
  isVerificationFinished,
  isVerificationStarted,
  useAccount,
  useFeature,
  useGetProject,
} from '@tokensoft-web/common-utils';
import { FC, ReactNode, useState } from 'react';
import { useParams } from 'react-router-dom';
import GlobalRestriction from '../../components/feature/global-restriction';
import KycRestriction from '../../components/feature/kyc-restriction';
import RegionRestriction from '../../components/feature/region-restriction';
import WalletAddressRestriction from '../../components/feature/wallet-address-restriction';
import WhitelabelRestriction from '../../components/feature/whitelabel-restriction';
import { ProjectContext } from './project-context';

interface ProjectProviderProps {
  children?: ReactNode;
}

export const ProjectProvider: FC<ProjectProviderProps> = ({ children }) => {
  const { account, loading: accountLoading } = useAccount();
  const { getFeatureRestriction } = useFeature();
  const { id } = useParams();
  const [view, setView] = useState('project-details');
  const {
    project: project,
    loading: projectLoading,
    refresh: refresh,
  } = useGetProject(id);
  const restriction = getFeatureRestriction(FEATURE.ADMIN);

  const requiredComplianceDocs = project?.documents?.filter(
    (doc) => doc.requiresApproval === true,
  )?.length;
  const approvedComplianceDocs = project?.documents?.filter(
    (doc) => doc.approvedAt != null,
  )?.length;
  const hasApprovedComplianceDocs =
    requiredComplianceDocs === approvedComplianceDocs;
  const isAuthorizedSigner =
    project?.authorizedSigner?.authId === account?.authId;

  const hasCompletedKyb =
    !!project?.authorizedSigner &&
    isVerificationFinished(
      project.authorizedSigner.kyc.tiers,
      TIER_NAME.IDENTITY,
    );
  const tier1Status = getVerificationStatusForTier(
    project?.authorizedSigner?.kyc.tiers,
    TIER_NAME.IDENTITY,
  );
  const isTier1Started = isVerificationStarted(
    project?.authorizedSigner?.kyc.tiers,
    TIER_NAME.IDENTITY,
  );
  const isTier1Finished = isVerificationFinished(
    project?.authorizedSigner?.kyc.tiers,
    TIER_NAME.IDENTITY,
  );
  const isTier1Completed = tier1Status === VERIFICATION_STATUS.COMPLETED;

  const hasSetProjectDetails =
    !!project?.name && !!project?.tagline && !!project?.description;
  const projectSetupComplete =
    hasSetProjectDetails && hasCompletedKyb && hasApprovedComplianceDocs;

  if (restriction) {
    switch (restriction) {
      case FEATURE_RESTRICTION.GLOBAL_RESTRICTION:
        return <GlobalRestriction />;
      case FEATURE_RESTRICTION.WHITELABEL_RESTRICTION:
        return <WhitelabelRestriction />;
      case FEATURE_RESTRICTION.KYC_RESTRICTION:
        return <KycRestriction />;
      case FEATURE_RESTRICTION.REGION_RESTRICTION:
        return <RegionRestriction />;
      case FEATURE_RESTRICTION.WALLET_ADDRESS_RESTRICTION:
        return <WalletAddressRestriction />;
    }
  }

  if (projectLoading || accountLoading) {
    return <LoadingIndicator text={'Loading project'} />;
  }

  if (!account?.projects?.find((p) => p.id === id)) {
    return (
      <PermissionDeniedError
        message={'You do not have permission to manage this project.'}
      />
    );
  }

  if (!projectLoading && !project) {
    return (
      <Error
        title='Project Not Found'
        message="It looks like this isn't a valid project."
      />
    );
  }

  return (
    <ProjectContext.Provider
      value={{
        project: project,
        loading: projectLoading,
        view: view,
        setView: setView,
        hasApprovedComplianceDocs: hasApprovedComplianceDocs,
        isAuthorizedSigner: isAuthorizedSigner,
        authorizedSigner: project.authorizedSigner
          ? {
              ...project.authorizedSigner,
              tier1Status,
              isTier1Started,
              isTier1Finished,
              isTier1Completed,
            }
          : undefined,
        hasCompletedKyb: hasCompletedKyb,
        hasSetProjectDetails: hasSetProjectDetails,
        projectSetupComplete: projectSetupComplete,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
