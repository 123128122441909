export const DEFAULT_BASE_CURRENCY = 'USD';
export const DEFAULT_BASE_CURRENCY_DECIMALS = 8;

// browsers store timeout delays as 32 bit signed integers
// values greater than 214783647 ms will cause an integer overflow resulting
// in the timeout being executed immediately
// we are using an arbitrary max delay of 8 hours
export const MAX_DELAY = 28800000;

export const DEFAULT_POLYGON_MAX_PRIORITY_FEE_PER_GAS = 0x6fc23ac00; // 30000000000

export const FAIR_QUEUE_STEPS = [
  'Fair Queue',
  'Terms',
  'Purchase',
  'Confirm',
  'Receipt',
];

export const REGULAR_STEPS = ['Terms', 'Purchase', 'Confirm', 'Receipt'];

export const BLOCKED_COUNTRIES = ['US'];

export const NULL_TRANSFER_ID =
  '0x0000000000000000000000000000000000000000000000000000000000000000';

export const LOW_BALANCE_WARNING_THRESHOLD = 0.0001;
