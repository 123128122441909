import {
  BackButton,
  ButtonRow,
  ContinueButton,
  PageSubtitle,
  PageTitle,
  Step,
  StepFooter,
  StepHeader,
  WizardBreadcrumb,
} from '@tokensoft-web/common-ui';
import { useToast, useUpdateProject } from '@tokensoft-web/common-utils';
import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wizard } from 'react-use-wizard';
import { PreviewPageButton } from '../../../components/admin/event/preview-page-button';
import { PreviewThemePage } from '../../../components/admin/project/preview-theme-page';
import { PreviewWelcomePage } from '../../../components/admin/project/preview-welcome-page';
import { ProjectThemeSetup } from '../../../components/admin/project/project-theme-setup';
import { ProjectWelcomePageSetup } from '../../../components/admin/project/project-welcome-page-setup';
import { useProject } from '../../../contexts/project/project-context';

const WizardContext = createContext(null);

export const EditProjectThemeWizard = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { project } = useProject();
  const { showErrorToast, showSuccessToast } = useToast();

  const { mutate: update, isPending: updateLoading } = useUpdateProject();

  const [wizardState, setWizardState] = useState<any>({
    logo: project?.theme?.logo,
    logoSmall: project?.theme?.logoSmall,
    favicon: project?.theme?.favicon,
    titleFont: project?.theme?.titleFont,
    baseFont: project?.theme?.baseFont,
    titleFontSize: project?.theme?.titleFontSize,
    baseFontSize: project?.theme?.baseFontSize,
    headerBgColor: project?.theme?.headerBgColor,
    sidebarBgColor: project?.theme?.sidebarBgColor,
    contentBgColor: project?.theme?.contentBgColor,
    containerBgColor: project?.theme?.containerBgColor,
    primaryColor: project?.theme?.primaryColor,
    headerFontColor: project?.theme?.headerFontColor,
    sidebarFontColor: project?.theme?.sidebarFontColor,
    secondaryColor: project?.theme?.secondaryColor,
    projectThemeFormValid: false,

    projectWelcomePageFormValid: false,
    welcomeBody: project?.content?.welcomeBody,
    welcomeBgImage: project?.content?.welcomeBgImage,
    welcomeAlignment: project?.content?.welcomeAlignment,
    welcomeBgColor: project?.content?.welcomeBgColor,
    welcomeFontColor: project?.content?.welcomeFontColor,
    welcomeTitle: project?.content?.welcomeTitle,
    welcomeSubtitle: project?.content?.welcomeSubtitle,
    welcomeLogo: project?.content?.welcomeLogo,
    welcomeLayoutVersion: project?.content?.welcomeLayoutVersion,
  });

  const goBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    setLoading(true);
    const payload = {
      ...project,
      theme: {
        logo: wizardState.logo,
        logoSmall: wizardState.logoSmall,
        favicon: wizardState.favicon,
        titleFont: wizardState.titleFont,
        baseFont: wizardState.baseFont,
        titleFontSize: wizardState.titleFontSize,
        baseFontSize: wizardState.baseFontSize,
        headerBgColor: wizardState.headerBgColor,
        sidebarBgColor: wizardState.sidebarBgColor,
        contentBgColor: wizardState.contentBgColor,
        containerBgColor: wizardState.containerBgColor,
        primaryColor: wizardState.primaryColor,
        headerFontColor: wizardState.headerFontColor,
        sidebarFontColor: wizardState.sidebarFontColor,
        secondaryColor: wizardState.secondaryColor,
        projectThemeFormValid: false,
      },
      content: {
        welcomeBody: wizardState.welcomeBody,
        welcomeBgImage: wizardState.welcomeBgImage,
        welcomeAlignment: wizardState.welcomeAlignment,
        welcomeBgColor: wizardState.welcomeBgColor,
        welcomeFontColor: wizardState.welcomeFontColor,
        welcomeTitle: wizardState.welcomeTitle,
        welcomeSubtitle: wizardState.welcomeSubtitle,
        welcomeLogo: wizardState.welcomeLogo,
        welcomeLayoutVersion: wizardState.welcomeLayoutVersion,
      },
    };

    update(payload, {
      onSuccess: (data) => {
        showSuccessToast({ description: 'Successfully updated Project.' });
        goBack();
      },
      onError: (error) => {
        setLoading(false);
        showErrorToast({ description: error.message });
      },
    });
  };

  const handleCancel = () => {
    goBack();
  };

  const renderPreviewWelcomeContent = (onContinue) => {
    return <PreviewWelcomePage context={wizardState} onContinue={onContinue} />;
  };

  const renderPreviewThemeContent = (onContinue) => {
    return <PreviewThemePage context={wizardState} onContinue={onContinue} />;
  };

  return (
    <WizardContext.Provider
      value={{
        wizardState,
        setWizardState,
      }}
    >
      <Wizard>
        <Step>
          <StepHeader>
            <WizardBreadcrumb>STEP 1</WizardBreadcrumb>
            <PageTitle>Theme Settings</PageTitle>
            <PageSubtitle>
              Here you can set your branding for a custom welcome page. Choose
              from the various options below including uploading logos,
              headline, subtext and background colors or images.
            </PageSubtitle>
          </StepHeader>

          <ProjectThemeSetup
            wizardState={wizardState}
            updateWizardState={setWizardState}
          />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton
                label={'Cancel'}
                disabled={updateLoading}
                onClick={handleCancel}
              />
              <ContinueButton />
            </ButtonRow>
          </StepFooter>
        </Step>
        <Step>
          <StepHeader>
            <WizardBreadcrumb>STEP 2</WizardBreadcrumb>
            <PageTitle>Welcome Page Setup</PageTitle>
            <PageSubtitle>
              Here you can set your branding for a custom welcome page. Choose
              from the various options below including uploading logos,
              headline, subtext and background colors or images.
            </PageSubtitle>
          </StepHeader>

          <ProjectWelcomePageSetup
            wizardState={wizardState}
            updateWizardState={setWizardState}
          />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton
                label={'Cancel'}
                onClick={handleCancel}
                disabled={updateLoading}
              />
              <PreviewPageButton
                previewContent={renderPreviewWelcomeContent}
                disabled={!wizardState?.projectWelcomePageFormValid || loading}
                label={'Preview and Continue'}
                fill={''}
                onContinue={handleSave}
              />
            </ButtonRow>
          </StepFooter>
        </Step>
      </Wizard>
    </WizardContext.Provider>
  );
};
