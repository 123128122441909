export enum PAYMENT_METHOD {
  NONE = 'NONE',
  ETH = 'ETH',
  USDC = 'USDC',
  USDT = 'USDT',
  AVAX = 'AVAX',
  MATIC = 'MATIC',
  MOVR = 'MOVR',
  AGOR = 'AGOR',
  CELO = 'CELO',
  TBNB = 'TBNB',
  BNB = 'BNB',
}
