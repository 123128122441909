import { Col, Stretched, Text, Title } from '@tokensoft-web/common-ui';

const ConnextDelegateHeaderText = () => {
  return (
    <Col gap={6}>
      <Title>Select a Delegate: Shape the Future of Connext</Title>
      <Stretched gap={10}>
        <Col>
          <Text>
            Your NEXT tokens come with voting rights, allowing you to
            participate in the governance of the Connext DAO.
          </Text>
          <br />
          <Text>
            Here, you can select a delegate to actively participate on your
            behalf. If you’re interested in actively participating in
            governance, you can delegate to yourself or another custom address.
          </Text>
          <br />
          <Text>You may change or remove your delegation at any time.</Text>
        </Col>
      </Stretched>
    </Col>
  );
};

export default ConnextDelegateHeaderText;
