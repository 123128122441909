import {
  LoadingIndicator,
  PageHeader,
  PageTitle,
} from '@tokensoft-web/common-ui';
import {
  FEATURE,
  FEATURE_RESTRICTION,
  useFeature,
} from '@tokensoft-web/common-utils';
import DistributorEventCard from '../../components/claims/distributor-event-card';
import GlobalRestriction from '../../components/feature/global-restriction';
import KycRestriction from '../../components/feature/kyc-restriction';
import RegionRestriction from '../../components/feature/region-restriction';
import WalletAddressRestriction from '../../components/feature/wallet-address-restriction';
import WhitelabelRestriction from '../../components/feature/whitelabel-restriction';
import { useGetDistributorSummary } from '../../services/distribution-service';
import './claims.css';

const ClaimsPage = () => {
  const { getFeatureRestriction } = useFeature();
  const {
    results: distributors,
    loading: distributorsLoading,
    merkleDataLoading,
  } = useGetDistributorSummary();
  const restriction = getFeatureRestriction(FEATURE.CLAIMS);

  if (restriction) {
    switch (restriction) {
      case FEATURE_RESTRICTION.GLOBAL_RESTRICTION:
        return <GlobalRestriction />;
      case FEATURE_RESTRICTION.WHITELABEL_RESTRICTION:
        return <WhitelabelRestriction />;
      case FEATURE_RESTRICTION.KYC_RESTRICTION:
        return <KycRestriction />;
      case FEATURE_RESTRICTION.REGION_RESTRICTION:
        return <RegionRestriction />;
      case FEATURE_RESTRICTION.WALLET_ADDRESS_RESTRICTION:
        return <WalletAddressRestriction />;
    }
  }

  return (
    <>
      <PageHeader>
        <PageTitle>Claims</PageTitle>
      </PageHeader>

      {distributors.length > 0 && (
        <>
          <div className='grid gap-6 grid-cols-1'>
            {distributors.map((distributor, i) => {
              return (
                <DistributorEventCard
                  key={i}
                  event={distributor}
                  loading={merkleDataLoading}
                />
              );
            })}
          </div>
        </>
      )}

      {!distributorsLoading && distributors.length === 0 && (
        <span>No claims found.</span>
      )}

      {distributorsLoading && <LoadingIndicator text='Loading Claims' />}
    </>
  );
};

const ClaimsContainer = () => {
  return <ClaimsPage />;
};

export default ClaimsContainer;
