import { ButtonRow, Card, Col } from '@tokensoft-web/common-ui';
import {
  FEATURE,
  convertBaseUnitsToDecimal,
  eq,
  formatValue,
  getIPFSLink,
  gte,
  lte,
  useAccount,
  useAuth,
  useModal,
  useNetworks,
  useWallet,
} from '@tokensoft-web/common-utils';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { GiToken } from 'react-icons/gi';
import { IoChevronDownCircleOutline } from 'react-icons/io5';
import { VscLinkExternal } from 'react-icons/vsc';
import { useDistributor } from '../../contexts/distributor/distributor-context';
import {
  getDistributorInterfaces,
  isContinuousVestingType,
  isCrosschainDistributorType,
  isTrancheVestingType,
} from '../../utils/abi';
import { useClaimableAmount } from '../../utils/claim';
import { getAddressUrl } from '../../utils/network';
import { isSmartContractWallet } from '../../utils/wallet';
import ClaimNetworkSelectionModal from './claim-network-selection-modal';
import SubmitClaimModal from './submit-claim-modal';

interface ClaimAvailabilityProps {
  allowClaiming?: boolean;
}

const ClaimAvailability = ({
  allowClaiming = false,
}: ClaimAvailabilityProps) => {
  const {
    user: { walletAddress },
  } = useAuth();
  const { account } = useAccount();
  const {
    event,
    distributor,
    correctNetworkChainId,
    setDelegateSaved,
    delegateSaved,
    pendingTransaction,
    onPendingTransaction,
    completedTransaction,
  } = useDistributor();
  const { getNetworkDetails } = useNetworks();
  const { connectedChainId, switchChain } = useWallet();
  const { showModal } = useModal();
  const [startCounter, setStartCounter] = useState(0);

  const AVAILABILITY_REFRESH_INTERVAL = 2000;

  const isSmartContract = isSmartContractWallet(
    account?.wallets,
    walletAddress,
  );
  const interfaces = getDistributorInterfaces(distributor);
  const isCrossChain = isCrosschainDistributorType(interfaces);
  const isConnextFlow = !!event?.features?.includes(
    FEATURE.CLAIM_CONNEXT_LAYOUT,
  );

  const showClaimedToDate =
    isContinuousVestingType(interfaces) || isTrancheVestingType(interfaces);
  const scheduleDescription =
    event?.scheduleDescription || distributor.vestingDescription || null;
  const tokenLogoUri = getIPFSLink(
    distributor?.logo?.uri || distributor?.logo?.cid,
  );
  const relayerFeeBips = isConnextFlow ? 5 : undefined;
  const {
    totalAvailableToClaim,
    totalAllocations,
    totalClaimedToDate,
    setRefresh,
  } = useClaimableAmount(
    walletAddress,
    distributor,
    relayerFeeBips,
    correctNetworkChainId,
  );

  const claimableFixedNumber = isContinuousVestingType(interfaces) ? 4 : 2;
  const totalAllocationFormatted = formatValue(
    convertBaseUnitsToDecimal(
      totalAllocations,
      distributor.tokenDecimals,
      claimableFixedNumber,
    ),
    { commas: true },
  );
  const totalClaimedToDateFormatted = formatValue(
    convertBaseUnitsToDecimal(
      totalClaimedToDate,
      distributor.tokenDecimals,
      claimableFixedNumber,
    ),
    { commas: true },
  );
  const distributorTotalFormatted = formatValue(
    convertBaseUnitsToDecimal(
      distributor.total,
      distributor.tokenDecimals,
      claimableFixedNumber,
    ),
    { commas: true },
  );
  const totalAvailableToClaimFormatted = formatValue(
    convertBaseUnitsToDecimal(
      totalAvailableToClaim,
      distributor.tokenDecimals,
      claimableFixedNumber,
    ),
    { commas: true },
  );

  const userOnCorrectNetwork =
    isConnextFlow && !isSmartContract
      ? true
      : connectedChainId === correctNetworkChainId;
  const fullyClaimed = gte(totalClaimedToDate, totalAllocations);

  useEffect(() => {
    if (distributor) {
      setRefresh(new Date());
    }
  }, [distributor]);

  useEffect(() => {
    if (completedTransaction) {
      setRefresh(new Date());
    }
  }, [completedTransaction]);

  useEffect(() => {
    if (isContinuousVestingType(interfaces)) {
      const intervalId = setInterval(() => {
        // Start a counter for refresh on continuous vesting
        setStartCounter(startCounter + 1);
        setRefresh(startCounter);
      }, AVAILABILITY_REFRESH_INTERVAL);

      // clear interval on re-render to avoid memory leaks
      return () => clearInterval(intervalId);
    }
  }, [startCounter]);

  const showTokenAllocation = (): boolean => {
    const hideTotalAllocation = !!event?.features?.includes(
      FEATURE.CLAIM_HIDE_TOTAL_ALLOCATION,
    );
    if (hideTotalAllocation) {
      return false;
    }

    if (eq(totalAvailableToClaim, totalAllocations)) {
      return false;
    }

    if (eq(totalClaimedToDate, totalAllocations)) {
      return false;
    }

    return (
      isContinuousVestingType(interfaces) || isTrancheVestingType(interfaces)
    );
  };

  const handleMinedTransaction = (receipt) => {
    console.log('Successfully mined transaction', receipt);
    onPendingTransaction(receipt);
  };

  const handleDelegateFinished = () => {
    console.log('Successfully delegated voting power');
    // TODO: should we clear the selected delegate information?
  };

  const handleClaimOnClick = () => {
    const showNetworkSelection = !isSmartContract && isCrossChain;

    // Show multi-network selection modal if crosschain and EOA
    if (showNetworkSelection) {
      showModal({
        className: 'max-w-lg',
        content: (
          <ClaimNetworkSelectionModal
            event={event}
            distributor={distributor}
            formattedClaimableAmount={totalAvailableToClaimFormatted}
            onMinedTransaction={handleMinedTransaction}
            delegateSaved={delegateSaved}
            setDelegateSaved={setDelegateSaved}
            onDelegateFinished={handleDelegateFinished}
            correctNetworkChainId={correctNetworkChainId}
          />
        ),
        disableClose: true,
      });
      return;
    }

    showModal({
      className: 'max-w-lg',
      content: (
        <SubmitClaimModal
          event={event}
          distributor={distributor}
          isSmartContractWallet={isSmartContract}
          claimableAmount={totalAvailableToClaim}
          formattedClaimableAmount={totalAvailableToClaimFormatted}
          onMinedTransaction={handleMinedTransaction}
          delegateSaved={{
            ...delegateSaved,
            delegatedToNetwork: connectedChainId,
          }}
          onDelegateFinished={handleDelegateFinished}
          correctNetworkChainId={correctNetworkChainId}
        />
      ),
      disableClose: true,
    });
  };

  if (!distributor) {
    return null;
  }

  return (
    <div className={'flex flex-col items-center'}>
      <Card>
        <div className={'flex flex-row justify-between'}>
          <div>
            <span className='font-semibold'>Available to Claim</span>
          </div>
          <div>
            <div>
              <a
                href={getAddressUrl(
                  distributor.token,
                  getNetworkDetails(distributor.chainId),
                )}
                target='_blank'
                rel='noreferrer'
                className='default-contrast-link'
              >
                <div className='flex flex-row items-center'>
                  <span className={'mr-2 text-xs'}>{'Token Contract'}</span>
                  <VscLinkExternal size={16} />
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className={'token-amount-box'}>
          <div className='flex flex-row items-center'>
            {tokenLogoUri ? (
              <img className='rounded-full w-8 h-8 mr-2' src={tokenLogoUri} />
            ) : (
              <div className='allocation-icon-background rounded-full p-1 mr-2'>
                <GiToken color='white' />
              </div>
            )}
            <span className='font-semibold text-lg'>
              {distributor.tokenSymbol}
            </span>
          </div>
          {pendingTransaction !== null && completedTransaction == null ? (
            <div className='flex flex-col items-end justify-center'>
              <div>
                <span className='font-semibold text-3xl'>0</span>
              </div>
              <div className={'flex flex-row'}>
                <div className='animate-spin'>
                  <AiOutlineLoading3Quarters size={18} />
                </div>
                <div className={'text-xs ml-2'}>Updating...</div>
              </div>
            </div>
          ) : (
            <div className='flex flex-row items-center'>
              <span className='font-semibold text-3xl'>
                {totalAvailableToClaimFormatted}
              </span>
            </div>
          )}
        </div>

        {showClaimedToDate ? (
          <div
            className={classNames('flex flex-row', allowClaiming ? 'mb-3' : '')}
          >
            <div>
              <span className='font-semibold'>Total Claimed to Date:</span>
            </div>
            <div className={'ml-2'}>
              <span>
                {totalClaimedToDateFormatted}&nbsp;{distributor.tokenSymbol}
              </span>
            </div>
          </div>
        ) : null}

        {allowClaiming ? (
          <ButtonRow className={'no-padding'}>
            {fullyClaimed ? (
              <button
                className={'btn btn-success w-full'}
                disabled={true}
                onClick={() => {}}
              >
                <div className='flex flex-row justify-center'>
                  <span className='self-center font-semibold'>Claimed!</span>
                </div>
              </button>
            ) : (
              <>
                {!userOnCorrectNetwork ? (
                  <button
                    className={'btn btn-primary w-full'}
                    onClick={() => switchChain(correctNetworkChainId)}
                  >
                    <div className='flex flex-row justify-center'>
                      <span className='self-center font-semibold'>
                        Switch Network
                      </span>
                    </div>
                  </button>
                ) : (
                  <button
                    className={'btn btn-primary w-full'}
                    onClick={handleClaimOnClick}
                    disabled={
                      !totalAvailableToClaim ||
                      lte(totalAvailableToClaim, 0) ||
                      pendingTransaction
                    }
                  >
                    <div className='flex flex-row justify-center'>
                      <IoChevronDownCircleOutline className='text-xl mr-1 mt-[2px]' />{' '}
                      <span className='self-center font-semibold'>Claim</span>
                    </div>
                  </button>
                )}
              </>
            )}
          </ButtonRow>
        ) : null}
      </Card>

      {allowClaiming ? (
        <Col>
          <div className='mt-4'>
            {scheduleDescription && (
              <span className='text-sm italic'>{scheduleDescription}</span>
            )}
          </div>
        </Col>
      ) : null}
    </div>
  );
};

export default ClaimAvailability;
