import { createContext, useContext } from 'react';

interface ConfigurationContextProps {
  configuration?: any;
  loading?: boolean;
}

export const ConfigurationContext = createContext<
  ConfigurationContextProps | undefined
>(undefined);
ConfigurationContext.displayName = 'ConfigurationContext';

export const useConfiguration = (): ConfigurationContextProps => {
  const context = useContext(ConfigurationContext);

  if (!context) {
    throw new Error(
      'ConfigurationContext context is undefined, please verify you are calling useConfiguration() as child of a <ConfigurationContext> component.',
    );
  }

  return context;
};
