import { AnimatedCheckmark, Col } from '@tokensoft-web/common-ui';

const ConnextClaimSuccessBanner = ({ tokenName }: { tokenName: string }) => {
  return (
    <Col place={'center'} className='after-claim-banner'>
      <div className='font-semibold text-lg flex items-center'>
        <div className='pr-3'>
          <AnimatedCheckmark size={'medium'} />
        </div>
        <div>Airdrop Claimed</div>
      </div>
      <div className='text-sm pt-2 text-center px-4 sm:px-0'>{`Congratulations! You have claimed your ${tokenName} tokens!`}</div>
      <div className='background-sparkle hidden sm:block'>
        <svg
          width='113'
          height='82'
          viewBox='0 0 113 82'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M44.0084 22.8127C42.9859 25.4068 42.4886 28.7855 42.9617 31.5899C40.7635 29.1106 37.7833 27.8883 34.4876 27.9967C37.0275 26.155 37.2028 21.7262 36.4735 18.9717C37.9232 21.2945 41.2182 22.7382 44.0084 22.8127Z'
            stroke='#FAFF00'
            strokeWidth='1.38939'
            strokeMiterlimit='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M86.5696 8.99451C84.8697 9.66493 83.0289 10.9379 81.8971 12.4188C82.1016 10.2569 81.3541 8.28269 79.8282 6.75247C81.8464 7.14543 84.0463 5.24857 85.0395 3.66727C84.5752 5.40056 85.3574 7.6246 86.5696 8.99451Z'
            stroke='#FAFF00'
            strokeWidth='0.910546'
            strokeMiterlimit='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M13.4412 20.2205C14.0688 21.2277 15.0979 22.2542 16.1765 22.8034C14.7717 22.9264 13.5983 23.6354 12.7999 24.7896C12.8142 23.4544 11.3445 22.2717 10.2179 21.8228C11.3795 21.9161 12.7084 21.1555 13.4412 20.2205Z'
            stroke='#FAFF00'
            strokeWidth='0.591334'
            strokeMiterlimit='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M64.5868 2.24212C64.9902 2.8895 65.6516 3.54929 66.345 3.90229C65.442 3.98134 64.6878 4.43707 64.1746 5.17895C64.1838 4.32073 63.2391 3.56057 62.515 3.27203C63.2616 3.33195 64.1158 2.84311 64.5868 2.24212Z'
            stroke='#FAFF00'
            strokeWidth='0.380087'
            strokeMiterlimit='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M15.5047 79.8976C15.8246 79.2051 16.0132 78.2901 15.9279 77.5167C16.4895 78.2283 17.2843 78.6087 18.1861 78.6307C17.4636 79.0939 17.3464 80.3008 17.5025 81.0645C17.1429 80.4074 16.2656 79.9616 15.5047 79.8976Z'
            stroke='#FAFF00'
            strokeWidth='0.380087'
            strokeMiterlimit='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M99.3975 23.9939L101.672 0.564941'
            stroke='white'
            strokeWidth='0.363945'
          />
          <path
            d='M112.772 9.66357L109.815 20.0815'
            stroke='white'
            strokeWidth='0.363945'
          />
          <path
            d='M61.3196 32.7743L45.6699 2.83984'
            stroke='white'
            strokeWidth='0.363945'
          />
          <path
            d='M38.0893 53.3763L13.0742 41.1162'
            stroke='white'
            strokeWidth='0.363945'
          />
          <path
            d='M28.6535 64.5868L15.1182 61.2847'
            stroke='white'
            strokeWidth='0.363945'
          />
        </svg>
        <div className='logo'>
          <svg
            width='100%'
            height='100%'
            viewBox='0 0 206 206'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M102.652 202.13C157.4 202.13 201.783 157.748 201.783 103C201.783 48.2516 157.4 3.8694 102.652 3.8694C47.9039 3.8694 3.52174 48.2516 3.52174 103C3.52174 157.748 47.9039 202.13 102.652 202.13Z'
              fill='black'
              stroke='url(#paint0_linear_2731_2619)'
              strokeWidth='6.95652'
            />
            <path
              d='M62.3722 112.466L83.19 133.105C84.8055 134.9 85.4786 139.387 82.5617 142.304C77.5356 147.465 71.2576 140.868 69.642 139.253L51.5168 121.213C45.2343 114.935 41.6891 105.556 50.7091 96.3606L72.5904 74.6543C70.5171 72.0201 69.3279 68.0935 72.8686 64.5529C76.9074 60.5096 81.0808 62.1296 83.9977 65.042L83.9124 65.1318L91.2675 72.4465L103.514 84.693C107.373 88.5972 109.797 92.4565 105.758 96.4908C101.719 100.53 96.8725 99.0487 93.0177 95.1894L81.8931 84.0647L64.1717 101.831C60.447 105.376 58.652 108.742 62.3767 112.462L62.3722 112.466ZM151.298 93.2193L133.217 75.3185C131.602 73.703 125.319 67.1063 120.298 72.267C117.381 75.1839 118.054 79.6714 119.669 81.4664L140.487 102.105C144.212 105.829 142.417 109.195 138.692 112.736L120.926 130.502L109.801 119.377C105.942 115.518 101.095 114.037 97.061 118.076C93.0222 122.115 95.4455 125.974 99.3047 129.874L111.551 142.12L118.933 149.502L118.866 149.57C121.783 152.486 125.956 154.237 129.991 150.198C133.581 146.608 132.311 142.632 130.188 139.993L152.11 118.071C161.309 108.872 157.764 99.4974 151.302 93.2149L151.298 93.2193Z'
              fill='white'
            />
            <defs>
              <linearGradient
                id='paint0_linear_2731_2619'
                x1='7'
                y1='198.652'
                x2='198.304'
                y2='198.652'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#29C1FC' />
                <stop offset='0.265625' stopColor='#587BFD' />
                <stop offset='0.515625' stopColor='#AB00FF' />
                <stop offset='0.796875' stopColor='#D86292' />
                <stop offset='1' stopColor='#FBB03B' />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </Col>
  );
};

export default ConnextClaimSuccessBanner;
