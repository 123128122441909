import {
  Card,
  Col,
  Divider,
  Flex,
  KycStatus,
  LoadingIndicator,
  NetworkAddress,
  Row,
  Stretched,
  TableData,
  TableHeader,
  Text,
  Title,
  VerticalDivider,
} from '@tokensoft-web/common-ui';
import {
  INVESTOR_TYPE,
  NULL_ADDRESS,
  countryList,
  formatAddress,
  utcToLocalDate,
} from '@tokensoft-web/common-utils';

import classNames from 'classnames';
import './user-details.css';

export const UserDetails = ({ data }: { data: any }) => {
  const primaryWalletAddress = () => {
    if (!data || !data?.wallets.length || data?.consent === false) {
      return {
        walletAddress: NULL_ADDRESS,
        networkId: 1,
      };
    }

    return data.wallets.find((wallet) => wallet.primary);
  };

  const getFullName = () => {
    let name = '-';
    if (data.firstName) {
      name = data.firstName;
      if (data.lastName) {
        name += ` ${data.lastName}`;
      }
    }
    return `${name}`;
  };

  return (
    <Card
      className={classNames('user-details', data?.consent === false && 'blur')}
    >
      {data ? (
        <Stretched nowrap='true' className={classNames('gap-9')}>
          <Col className='w-52 self-center items-center' gap={2}>
            <div className='user-avatar'>
              <div className='user-icon'>
                {`${getFullName().toUpperCase().charAt(0)}`}
              </div>
            </div>
            <Title className='capitalize' textAlign={'left'}>
              {`${getFullName()}`}
            </Title>
            <Row gap={2}>
              <Text className={'high-contrast'}>Last Login:</Text>
              <Text className={'high-contrast'}>
                {data.lastLoginAt ? utcToLocalDate(data.lastLoginAt) : '-'}
              </Text>
            </Row>
            <Text>
              <div className='capitalize'>
                {formatAddress(data, data.investorType, countryList).join(', ')}
              </div>
            </Text>
          </Col>
          <VerticalDivider className={'hidden md:flex'} />
          <Divider className={'md:hidden flex'} />
          <Flex className='w-auto' yalign={'center'}>
            <Row ygap={10}>
              <Col className='w-1/2 md:w-1/3' gap={2.5}>
                <TableHeader>Primary Wallet Address</TableHeader>
                <TableData>
                  <NetworkAddress
                    address={primaryWalletAddress().walletAddress}
                    network={primaryWalletAddress().networkId}
                  />
                </TableData>
              </Col>
              <Col className='w-1/2 md:w-1/3' gap={2.5}>
                <TableHeader>Identity Verification</TableHeader>
                <TableData>
                  <KycStatus status={data.kyc?.tiers[0]?.status} />
                </TableData>
              </Col>
              <Col className='w-1/2 md:w-1/3' gap={2.5}>
                <TableHeader>Investor Accreditation</TableHeader>
                <TableData>
                  <KycStatus status={data.kyc?.tiers[1]?.status} />
                </TableData>
              </Col>
              <Col className='w-1/2 md:w-1/3' gap={2.5}>
                <TableHeader>Investor type</TableHeader>
                <TableData className='capitalize'>
                  {(data.investorType === INVESTOR_TYPE.MYSELF
                    ? 'INDIVIDUAL'
                    : data.investorType || '-'
                  ).toLowerCase()}
                </TableData>
              </Col>
              <Col className='w-auto' gap={2.5}>
                <TableHeader>Email</TableHeader>
                <TableData>{data.email}</TableData>
              </Col>
            </Row>
          </Flex>
        </Stretched>
      ) : (
        <Row className='h-64' yalign={'center'}>
          <LoadingIndicator />
        </Row>
      )}
    </Card>
  );
};
