import {
  Alert,
  AnimatedCheckmark,
  CrosschainPendingIcon,
  FrontArrowIcon,
} from '@tokensoft-web/common-ui';
import {
  FEATURE,
  getTruncatedAddress,
  getTxUrl,
  lt,
  useAccount,
  useAnalytics,
  useAuth,
  useClaimsDelegateSet,
  useFindDelegate,
  useGetRelayerClaimStatus,
  useModal,
  useNetworks,
  useToast,
  useWallet,
} from '@tokensoft-web/common-utils';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { AiFillExclamationCircle } from 'react-icons/ai';
import { FaRegTimesCircle } from 'react-icons/fa';
import { VscLinkExternal } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import { useBalance, useWaitForTransactionReceipt } from 'wagmi';
import {
  isCrosschainDistributorType,
  isDistributorDelegationEnabled,
  isVotingType,
} from '../../utils/abi';
import { useSubmitDelegate } from '../../utils/claim';
import { LOW_BALANCE_WARNING_THRESHOLD } from '../../utils/constant';
import { isSmartContractWallet } from '../../utils/wallet';
import DelegatingModal from '../governance/delegating-modal';
import DelegatingModalDelegateFound from '../governance/delegating-modal-delegate-found';
import ClaimModalStep from './claim-modal-step';
import ConnextClaimProgressAlert from './connext/connext-claim-progress-alert';

interface SubmitClaimSuccessProps {
  interfaces: any[];
  transactionHash?: string;
  claimableAmount: number;
  network?: any;
  delegateSaved?: any;
  event?: any;
  distributor?: any;
  isSatellite?: boolean;
  isCrossChainTransaction?: boolean;
  onDelegateFinished?: Function;
  onMinedTransaction?: Function;
  onClaimError?: Function;
}

enum MODAL_STEP {
  FOUND_DELEGATE = 'FOUND_DELEGATE',
  SUBMIT_DELEGATE = 'SUBMIT_DELEGATE',
  COMPLETE = 'COMPLETE',
}

const SubmitClaimSuccess = ({
  interfaces,
  transactionHash,
  claimableAmount,
  network,
  delegateSaved,
  event,
  distributor,
  isSatellite,
  isCrossChainTransaction,
  onDelegateFinished,
  onMinedTransaction,
  onClaimError,
}: SubmitClaimSuccessProps) => {
  const [transactionMined, setTransactionMined] = useState(false);
  const { closeModal } = useModal();
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();
  const { pageEvent } = useAnalytics();
  const { account } = useAccount();
  const {
    user: { walletAddress },
  } = useAuth();
  const { switchChain, connectedChainId } = useWallet();
  const { getNetworkDetails } = useNetworks();
  const delegationEnabled = isDistributorDelegationEnabled(distributor);

  const [differentChain, setDifferentChain] = useState(
    delegateSaved.delegatedToNetwork !== connectedChainId,
  );

  const [delegateFound, setDelegateFound] = useState(null);
  const [modalStep, setModalStep] = useState(null);

  const contract = distributor?.contracts?.find((c) => {
    return (
      c.networkId == delegateSaved.delegatedToNetwork &&
      isVotingType(c.interfaces)
    );
  });

  const { mutate: claimDelegateSet } = useClaimsDelegateSet();
  // @ts-ignore
  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash as `0x${string}`,
  });

  const showDelegateButton =
    contract &&
    delegationEnabled &&
    delegateSaved.delegateWalletAddress &&
    event.features?.includes(FEATURE.CLAIM_DELEGATE_PRESELECT_REQUIRED);
  const isCrossChain = isCrosschainDistributorType(interfaces);

  const isConnextFlow = !!event.features?.includes(
    FEATURE.CLAIM_CONNEXT_LAYOUT,
  );
  const isSmartContract = isSmartContractWallet(
    account?.wallets,
    walletAddress,
  );

  const {
    error: submitDelegateError,
    write: submitDelegate,
    data: submitDelegateReceipt,
  } = useSubmitDelegate(isSatellite, walletAddress);

  const { data: relayerClaimData } = useGetRelayerClaimStatus(
    isConnextFlow && !isSmartContract && !transactionMined,
  );

  const { data: nativeBalanceInfo } = useBalance({
    address: walletAddress,
    chainId: delegateSaved.delegatedToNetwork,
    query: {
      enabled: delegateSaved.delegatedToNetwork && !differentChain,
    },
  });
  const showLowNativeBalanceWarning =
    connectedChainId === delegateSaved?.delegatedToNetwork &&
    lt(nativeBalanceInfo?.formatted, LOW_BALANCE_WARNING_THRESHOLD);

  const { mutate: findDelegate } = useFindDelegate();

  useEffect(() => {
    if (relayerClaimData) {
      const { status, transactionHash: relayerTransactionHash } =
        relayerClaimData;
      if (
        status === 'CheckPending' ||
        status === 'ExecPending' ||
        status === 'WaitingForConfirmation' ||
        status === 'NotFound'
      ) {
        return;
      }

      if (status === 'ExecSuccess') {
        onMinedTransaction && onMinedTransaction(relayerTransactionHash);
        return setTransactionMined(true);
      }

      const errorMessage = `Claim status - ${status}`;
      console.log(errorMessage);
      setModalStep(null);
      onClaimError && onClaimError(errorMessage);
    }
  }, [relayerClaimData]);

  useEffect(() => {
    if (!transactionHash) {
      return;
    }

    if (waitForTransactionResponse.status === 'error') {
      onClaimError && onClaimError(waitForTransactionResponse?.error?.message);
    }

    if (waitForTransactionResponse.status === 'success' && !transactionMined) {
      onMinedTransaction && onMinedTransaction(waitForTransactionResponse.data);
      setTransactionMined(true);
    }
  }, [waitForTransactionResponse, transactionHash]);

  useEffect(() => {
    if (transactionMined && !showDelegateButton) {
      // show claim success if the transaction is mined
      setModalStep(MODAL_STEP.COMPLETE);
    }
  }, [transactionMined]);

  useEffect(() => {
    if (delegateSaved.delegateByInputWallet) {
      findDelegate(
        {
          walletAddress: delegateSaved.delegateWalletAddress,
          projectId: contract.project_id,
        },
        {
          onSuccess: (response) => {
            if (response.delegate) {
              setDelegateFound(response.delegate);
            }
          },
          onError: (error) => showErrorToast({ description: error.message }),
        },
      );
    }
  }, []);

  useEffect(() => {
    setDifferentChain(delegateSaved.delegatedToNetwork !== connectedChainId);
  }, [connectedChainId, delegateSaved]);

  useEffect(() => {
    if (submitDelegateReceipt) {
      console.log('submit delegate receipt', submitDelegateReceipt);
      showSuccessToast({
        description: (
          <div className='flex flex-row'>
            Successfully submitted delegation.
            <a
              target='_blank'
              rel='noreferrer'
              href={getTxUrl(
                submitDelegateReceipt.transactionHash,
                getNetworkDetails(contract.networkId),
              )}
              className='w-[30px] flex items-center justify-center text-white'
              onClick={(e) => e.stopPropagation()}
            >
              <VscLinkExternal color='white' />
            </a>
          </div>
        ),
      });
      claimDelegateSet(
        {
          distributorId: contract.address,
        },
        {
          onSuccess: () => {
            console.log('Claim Delegate set send Success');
            setModalStep(MODAL_STEP.COMPLETE);
          },
          onError: (e) => console.log('Claim Delegate set send Error', e),
        },
      );
      pageEvent('governance', 'submitDelegate');
      onDelegateFinished && onDelegateFinished();
    }
  }, [submitDelegateReceipt]);

  useEffect(() => {
    if (submitDelegateError) {
      showErrorToast({ description: submitDelegateError.message });
      setModalStep(MODAL_STEP.SUBMIT_DELEGATE);
    }
  }, [submitDelegateError]);

  const handleOnContinue = async () => {
    setModalStep(MODAL_STEP.SUBMIT_DELEGATE);
    try {
      await submitDelegate(
        contract.networkId,
        contract.address,
        delegateSaved.delegateWalletAddress,
      );
    } catch (error) {
      console.error(error.message);
      showErrorToast({
        description:
          'The transaction could not be completed, please try again later.',
      });
      setModalStep(null);
    }
  };

  const executeDelegate = () => {
    if (delegateSaved.delegateByInputWallet) {
      if (delegateFound) {
        setModalStep(MODAL_STEP.FOUND_DELEGATE);
      } else {
        handleOnContinue();
      }
    } else {
      handleOnContinue();
    }
  };

  const renderBody = () => {
    if (modalStep === MODAL_STEP.COMPLETE) {
      const txHash = relayerClaimData?.transactionHash || transactionHash;

      return (
        <div
          className='mt-6 text-center flex flex-col items-center'
          data-testid='submit-claim-success-body'
        >
          <div>
            <p data-testid='submit-claim-success-message'>
              You have successfully submitted a claim for {claimableAmount}{' '}
              {distributor?.tokenSymbol} tokens on the {network.name} Network!
              {delegateSaved.delegateWalletAddress
                ? ` You delegated ${claimableAmount} Votes to ${getTruncatedAddress(
                    delegateSaved.delegateWalletAddress,
                  )}.`
                : ''}
            </p>
          </div>
          {isCrossChain && isCrossChainTransaction && txHash && (
            <div
              className='mt-4'
              data-testid='submit-claim-connext-success-body'
            >
              <ConnextClaimProgressAlert txHash={txHash} />
            </div>
          )}
        </div>
      );
    }

    return (
      <span data-testid='submit-claim-pending-body'>
        <p
          data-testid='submit-claim-pending-message'
          className='text-center mt-6'
        >
          You have successfully submitted a{' '}
          {isCrossChain && isCrossChainTransaction ? 'crosschain' : ''} claim
          for {claimableAmount} {distributor?.tokenSymbol} tokens on the{' '}
          {network.name} Network!
        </p>
        {!transactionMined && transactionHash && (
          <div className='mt-6'>
            <Alert type='alert-success'>
              <div className='flex flex-row items-center text-white'>
                Your claim is being submitted. View your transaction here
                <a
                  target='_blank'
                  rel='noreferrer'
                  href={getTxUrl(
                    transactionHash,
                    getNetworkDetails(connectedChainId),
                  )}
                  className='self-center ml-2'
                >
                  <VscLinkExternal color='white' />
                </a>
              </div>
            </Alert>
          </div>
        )}
      </span>
    );
  };

  const openGovernanceHandler = () => {
    closeModal();
    navigate('/governance');
  };

  const renderNextButton = () => {
    if (modalStep === MODAL_STEP.COMPLETE) {
      if (
        delegationEnabled &&
        !event.features?.includes(FEATURE.CLAIM_DELEGATE_PRESELECT_REQUIRED)
      ) {
        return (
          <button
            data-testid='submit-claim-success-complete-action'
            className='w-full btn btn-primary'
            onClick={() => openGovernanceHandler()}
          >
            <div className='flex flex-row justify-center items-center'>
              Go to Governance
            </div>
          </button>
        );
      } else {
        return (
          <button
            data-testid='submit-claim-success-complete-action'
            className='w-full btn btn-primary'
            onClick={() => closeModal()}
          >
            <div className='flex flex-row justify-center items-center'>
              Close
            </div>
          </button>
        );
      }
    }

    if (!showDelegateButton) {
      return (
        <button
          className={classNames(
            'w-full btn',
            transactionMined ? 'btn-primary' : 'btn-dark',
          )}
          disabled={!transactionMined}
          onClick={() => closeModal()}
        >
          <div className='flex flex-row justify-center items-center'>
            <span>{transactionMined ? 'Finish' : 'Submitting...'}</span>
          </div>
        </button>
      );
    }

    if (!transactionMined) {
      return (
        <button className={'w-full btn btn-dark'} disabled={!transactionMined}>
          <div className='flex flex-row justify-center items-center'>
            <span>Submitting...</span>
          </div>
        </button>
      );
    }

    const delegatingToNetworkDetails = getNetworkDetails(
      delegateSaved.delegatedToNetwork,
    );

    if (differentChain) {
      return (
        <button
          className='w-full btn btn-primary'
          onClick={() => switchChain(delegateSaved.delegatedToNetwork)}
        >
          {`Delegate (Switch to ${delegatingToNetworkDetails.name})`}
        </button>
      );
    }

    const delegatingToChainId = Number(delegatingToNetworkDetails.chainId);
    // Hide connext bridge if on polygon or matic
    const hideBridgeUsingConnext =
      !isConnextFlow ||
      delegatingToChainId === 137 ||
      delegatingToChainId === 80001;

    return (
      <>
        {showLowNativeBalanceWarning ? (
          <div className='my-4'>
            <Alert
              icon={
                <AiFillExclamationCircle fill='var(--warning-dark)' size={24} />
              }
              className='bg-warning-100 !border-warning-dark'
            >
              <div className='flex flex-col justify-center text-warning-dark text-sm space-y-2 pl-2'>
                <span>
                  You do not have sufficient {delegatingToNetworkDetails.symbol}{' '}
                  to pay for transaction fees on{' '}
                  {delegatingToNetworkDetails.name}.
                </span>
                {!hideBridgeUsingConnext && (
                  <div className='w-full flex'>
                    <a
                      href='https://bridge.connext.network/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Bridge using Connext →
                    </a>
                  </div>
                )}
              </div>
            </Alert>
          </div>
        ) : null}
        <button
          className={classNames(
            'w-full btn',
            transactionMined ? 'btn-primary' : 'btn-dark',
          )}
          disabled={!transactionMined || showLowNativeBalanceWarning}
          onClick={() => executeDelegate()}
        >
          <div className='flex flex-row justify-center items-center'>
            <span className='mr-2'>Finish Claiming</span>
            <FrontArrowIcon />
          </div>
        </button>
      </>
    );
  };

  const toDisplay = () => {
    const delegatingToSelf =
      walletAddress?.toLowerCase() ===
      delegateSaved?.delegateWalletAddress?.toLowerCase();
    const hexSplit = delegateSaved?.delegateWalletAddress?.split('0x');
    const isHex = hexSplit?.length > 1;
    const delegateToAddressOrENS = isHex
      ? getTruncatedAddress(delegateSaved?.delegateWalletAddress)
      : delegateSaved?.delegateWalletAddress;
    switch (modalStep) {
      case MODAL_STEP.COMPLETE:
        return (
          <div data-testid='submit-claim-complete' className='px-3'>
            <div className='flex flex-row mt-4 justify-center'>
              <AnimatedCheckmark />
            </div>
            <div className='mt-4 text-center'>
              <span className='text-2xl font-bold'>Claim Successful!</span>
            </div>
            <ClaimModalStep onStep={2} />
            {renderBody()}
            <div className='my-6'>{renderNextButton()}</div>
          </div>
        );
      case MODAL_STEP.FOUND_DELEGATE:
        return (
          <DelegatingModalDelegateFound
            delegateToAddressOrENS={delegateToAddressOrENS}
            delegateSaved={delegateFound ? delegateFound : delegateSaved}
            handleOnContinue={handleOnContinue}
          />
        );
      case MODAL_STEP.SUBMIT_DELEGATE:
        return (
          <DelegatingModal
            delegatingToSelf={delegatingToSelf}
            delegateByInputWallet={delegateSaved.delegateByInputWallet}
            delegateSaved={delegateSaved.delegateInfo}
            delegateToAddressOrENS={delegateToAddressOrENS}
            delegateInfo={delegateSaved.delegateInfo}
          />
        );
      default:
        return (
          <div data-testid='submit-claim-pending' className='px-3'>
            <div className='flex flex-row justify-between mt-4'>
              <span className='w-[20px]'></span>
              <div className='flex flex-row mt-4 justify-center'>
                <CrosschainPendingIcon />
              </div>
              {transactionMined ? (
                <div onClick={() => closeModal()} className='w-[20px]'>
                  <FaRegTimesCircle className='text-neutral-medium cursor-pointer' />
                </div>
              ) : (
                <span></span>
              )}
            </div>
            <div className='mt-4 text-center'>
              <span className='text-2xl font-bold high-contrast'>
                {isCrossChain
                  ? 'Crosschain Claim Initiated'
                  : 'Claim Initiated'}
              </span>
            </div>
            <ClaimModalStep onStep={1} />
            {renderBody()}
            <div className='my-6'>{renderNextButton()}</div>
          </div>
        );
    }
  };

  return toDisplay();
};

export default SubmitClaimSuccess;
