import classNames from 'classnames';
import { FC, ReactNode, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Col } from '../../layout/container/col';
import { Row } from '../../layout/container/row';
import { FormLabel } from '../../typography/form-label';
import './input-group.css';

type InputGroupProps = {
  value: any;
  label: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  readonly?: boolean;
  valid?: boolean;
  type?: string;
  prefix?: string;
  thousandSeparator?: boolean;
  width?: string;
  max?: any;
  min?: any;
  allowNegative?: boolean;
  decimalScale?: any;
  onFocus?: any;
  className?: string;
  // Used to forward the event up. Normal event propagation doesn't work because the handler here isn't bound to the
  // underlying input directly; it's treated as a React property.
  onChange?: any;
  isAllowed?: any;
  append?: ReactNode;
  prepend?: ReactNode;
  error?: ReactNode;
  labelSuffix?: ReactNode;
  dir?: string;
  onKeyPress?: any;
  id?: string;
  maxLength?: number;
  disabled?: boolean;
};

export const InputGroup: FC<InputGroupProps> = ({
  value,
  label,
  name,
  width = 'full',
  placeholder,
  required = false,
  readonly = false,
  valid = true,
  thousandSeparator = false,
  type = 'text',
  prefix,
  min,
  max,
  allowNegative = false,
  decimalScale = 0,
  className,
  onChange = () => {},
  onFocus = () => {},
  isAllowed,
  append,
  prepend,
  error,
  labelSuffix,
  dir = 'ltr',
  onKeyPress,
  id,
  maxLength,
  disabled,
}) => {
  const [touched, setTouched] = useState(false);
  const [requiredValidationMessage, setRequiredValidationMessage] =
    useState(null);

  const key = id || name;

  const handleNumberFormatChange = ({ value }, event) => {
    onChange && onChange({ value }, event);
  };

  useEffect(() => {
    if (!readonly && required && !value && touched) {
      setRequiredValidationMessage(`${label || name} is required`);
    } else {
      setRequiredValidationMessage(null);
    }
  }, [value, touched]);

  return (
    <>
      <Row
        width={width}
        className={classNames(
          'input-group-container',
          className,
          requiredValidationMessage || error ? 'invalid' : '',
          !requiredValidationMessage && !valid ? 'invalid' : '',
        )}
      >
        <Col>
          {label && (
            <FormLabel
              data-testid={`${key}-label`}
              className={'flex flex-row  mb-4'}
            >
              <label className={labelSuffix ? 'pr-2' : ''} htmlFor={name}>
                {label}
                {required && ' *'}
              </label>
              {labelSuffix}
            </FormLabel>
          )}
        </Col>
        <Col className={classNames('input-group', readonly ? 'readonly' : '')}>
          <div>
            {prepend}
            {type === 'number' ? (
              <NumberFormat
                data-testid={`${key}`}
                data-lpignore='true'
                name={name}
                className='form-control'
                displayType='input'
                type='text'
                allowNegative={allowNegative}
                decimalScale={decimalScale}
                value={value}
                placeholder={placeholder}
                onValueChange={handleNumberFormatChange}
                readOnly={readonly}
                isAllowed={isAllowed}
                prefix={prefix}
                thousandSeparator={thousandSeparator}
                dir={dir}
                onBlur={() => setTouched(true)}
                disabled={disabled}
              />
            ) : type === 'textarea' ? (
              <textarea
                data-testid={`${key}`}
                className='textarea form-control'
                placeholder={placeholder || ''}
                required={required}
                readOnly={readonly}
                id={key}
                name={name}
                value={value || ''}
                maxLength={maxLength}
                onChange={onChange}
                onFocus={onFocus}
                dir={dir}
                onBlur={() => setTouched(true)}
                disabled={disabled}
              />
            ) : (
              <input
                data-testid={`${key}`}
                data-lpignore='true'
                className='form-control'
                placeholder={placeholder || ''}
                required={required}
                readOnly={readonly}
                id={key}
                name={name}
                value={value || ''}
                onChange={onChange}
                type={type}
                max={max}
                min={min}
                onFocus={onFocus}
                dir={dir}
                onKeyPress={onKeyPress}
                maxLength={maxLength}
                onBlur={() => setTouched(true)}
                disabled={disabled}
              />
            )}
            {append}
          </div>
          <div>
            {requiredValidationMessage && (
              <span
                data-testid={`${key}-required-error`}
                className='form-control-error'
              >
                {requiredValidationMessage}
              </span>
            )}
            {error && !requiredValidationMessage && (
              <span data-testid={`${key}-error`} className='form-control-error'>
                {error}
              </span>
            )}
            {/* this holds space below the input for an error message when there is no error */}
            {!error && !requiredValidationMessage && (
              <span data-testid={`${key}-valid`} className='transparent mb-2'>
                valid
              </span>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};
