import { AnalyticsConfiguration } from './analytics-configuration';
import { SeoConfiguration } from './seo-configuration';
import { ThemeConfiguration } from './theme-configuration';

export const Configuration = () => {
  return (
    <>
      <SeoConfiguration />
      <ThemeConfiguration />
      <AnalyticsConfiguration />
    </>
  );
};
