import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  EditIcon,
  Row,
  Section,
  Stacked,
  Stretched,
  TableData,
  TableHeader,
  Text,
  TextLink,
} from '@tokensoft-web/common-ui';
import { getTruncatedAddress, useClipboard } from '@tokensoft-web/common-utils';
import { useGetContractOwner } from '@tokensoft-web/common-utils/src/service/contract-service';
import { useEffect, useState } from 'react';
import { AiOutlineCopy } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import {
  getContinuousVestingMerkleABI,
  getTrancheVestingMerkleABI,
} from '../../utils/abi';

export const DistributorOwnerSummary = ({
  context,
  setContext,
  className = '',
}) => {
  const navigate = useNavigate();
  const { copyToClipboard } = useClipboard();
  const [isOpen, setIsOpen] = useState(!Object.keys(context).includes('id'));

  const contractAbi = context.continuousVesting
    ? getContinuousVestingMerkleABI()
    : getTrancheVestingMerkleABI();

  const contractAddress = context.event.distributor.id;
  const chainId = context.chainId;
  const { owner, isPending } = useGetContractOwner(
    contractAddress,
    chainId,
    contractAbi,
  );

  useEffect(() => {
    if (owner) {
      setContext({
        ...context,
        advanced: { ...context.advanced, owner: { id: owner } },
      });
    }
  }, [owner]);

  const key = 'distributor-owner-summary';

  return (
    <Card className={className} id={`${key}-card`}>
      <CardHeader place={'center'}>
        <CardTitle
          open={isOpen}
          onOpen={context.id ? setIsOpen : null}
          data-testid={`${key}-title`}
        >
          Owner &amp; Recipient
        </CardTitle>
        {context.id ? (
          <TextLink
            width={'w-fit'}
            onClick={() => {
              navigate(
                `/admin/project/${context.event.project.id}/event/${context.event.id}/distributor/owner`,
              );
            }}
          >
            <EditIcon />
            <Text>Edit Details</Text>
          </TextLink>
        ) : null}
      </CardHeader>

      <CardBody open={isOpen}>
        <Stacked>
          {context.id ? (
            <Section gap={5} place={'start'}>
              <Stretched gap={5}>
                <Stretched ygap={5}>
                  <Col gap={2.5} className={'w-full md:w-1/2'}>
                    <TableHeader>Current Owner</TableHeader>
                    {!isPending ? (
                      <Row nowrap gap={2} yalign={'center'}>
                        <div
                          className='text-primary-medium cursor-pointer'
                          onClick={() => copyToClipboard(owner)}
                        >
                          <AiOutlineCopy size={16} />
                        </div>
                        <TableData
                          className='break-anywhere'
                          data-testid={`${key}-owner-data`}
                        >
                          {getTruncatedAddress(owner)}
                        </TableData>
                      </Row>
                    ) : (
                      <TableData
                        className='break-anywhere'
                        data-testid={`${key}-owner-data`}
                      >
                        {'Loading...'}
                      </TableData>
                    )}
                  </Col>

                  <Col gap={2.5} className={'w-full md:w-1/2'}>
                    <TableHeader>Current Recipient</TableHeader>
                    {context?.advanced?.sweepRecipient?.id ? (
                      <Row nowrap gap={2} yalign={'center'}>
                        <div
                          className='text-primary-medium cursor-pointer'
                          onClick={() =>
                            copyToClipboard(
                              context?.advanced?.sweepRecipient?.id,
                            )
                          }
                        >
                          <AiOutlineCopy size={16} />
                        </div>
                        <TableData
                          className='break-anywhere'
                          data-testid={`${key}-recipient-data`}
                        >
                          {getTruncatedAddress(
                            context?.advanced?.sweepRecipient?.id,
                          )}
                        </TableData>
                      </Row>
                    ) : (
                      <TableData
                        className='break-anywhere'
                        data-testid={`${key}-recipient-data`}
                      >
                        {'Loading...'}
                      </TableData>
                    )}
                  </Col>
                </Stretched>

                <Stretched>
                  <Col gap={2.5} width={'1/2'} className={'hidden md:block'}>
                    <TableHeader>&nbsp;</TableHeader>
                    <TableData>&nbsp;</TableData>
                  </Col>

                  <Col gap={2.5} width={'1/2'} className={'hidden md:block'}>
                    <TableHeader>&nbsp;</TableHeader>
                    <TableData>&nbsp;</TableData>
                  </Col>
                </Stretched>
              </Stretched>
            </Section>
          ) : null}
        </Stacked>
      </CardBody>
    </Card>
  );
};
