import '@rainbow-me/rainbowkit/styles.css';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  AccountProvider,
  AnalyticsProvider,
  AuthProvider,
  ConfigurationProvider,
  ModalProvider,
  NetworkProvider,
  ToastProvider,
  WalletProvider,
} from '@tokensoft-web/common-utils';
import { RainbowkitProvider } from '@tokensoft-web/common-utils/src/context/rainbowkit/rainbowkit-provider';
import { WagmiProvider } from '@tokensoft-web/common-utils/src/context/wagmi/wagmi-provider';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './routes';
import ScrollToTop from './utils/scrollToTop';

const replay = process.env.REACT_APP_SENTRY_REPLAY || 'false';
console.log('Session replay:', replay);

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_PROCESS_ENV,
    tracesSampleRate:
      process.env.REACT_APP_PROCESS_ENV === 'development' ? 0.0 : 1.0,
    release: process.env.REACT_APP_VERSION,
    // see common filters here: https://docs.sentry.io/platforms/javascript/configuration/filtering/
    ignoreErrors: [
      'closeTransport called before connection was established',
      'To use QR modal, please install @web3modal/standalone package',
      // generic Axios network error https://tokensoft.sentry.io/issues/4331138801/
      'Network Error',
      'No matching key. keychain',
      'No matching key. history',
      // walletconnect websocket issue
      /WebSocket connection failed for host: wss:\/\/relay\.walletconnect\.com/,
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: replay === 'true' ? [new Sentry.Replay()] : [],
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const Root = () => (
  // <StrictMode>
  <QueryClientProvider client={queryClient}>
    <ConfigurationProvider>
      <NetworkProvider>
        <WagmiProvider>
          <ModalProvider>
            <ToastProvider>
              <Router>
                <ScrollToTop />
                <AnalyticsProvider>
                  <AuthProvider>
                    <AccountProvider>
                      <RainbowkitProvider>
                        <WalletProvider>
                          <Routes />
                        </WalletProvider>
                      </RainbowkitProvider>
                    </AccountProvider>
                  </AuthProvider>
                </AnalyticsProvider>
              </Router>
            </ToastProvider>
          </ModalProvider>
        </WagmiProvider>
      </NetworkProvider>
    </ConfigurationProvider>
  </QueryClientProvider>
  // </StrictMode>
);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(<Root />);
