import classNames from 'classnames';
import { Row } from '../layout/container/row';

export const TextLink = ({
  className = '',
  place = 'center',
  children,
  ...restProps
}) => {
  return (
    <Row
      nowrap
      gap={2.5}
      place={place}
      className={classNames('text-link whitespace-nowrap', className)}
      {...restProps}
    >
      {children}
    </Row>
  );
};
