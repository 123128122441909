import axios, { AxiosRequestConfig, Method } from 'axios';
import { useCallback } from 'react';
import { useAuth } from '../context/auth/auth-context';

interface RequestConfig extends AxiosRequestConfig {
  token?: string;
  signout?: Function;
}

const distributorApiClient = (
  endpoint: string,
  {
    data,
    token,
    signout,
    headers: customHeaders,
    ...customConfig
  }: RequestConfig,
) => {
  const method: Method = data ? 'post' : 'get';

  const config = {
    method,
    url: `${process.env.REACT_APP_API_URL}/distributor/api/v1/${endpoint}`,
    data: data || undefined,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      'Content-Type': data ? 'application/json' : undefined,
      ...customHeaders,
    },
    ...customConfig,
  };

  return axios(config).then(
    (res) => res.data,
    (error) => {
      console.log(error.toJSON());
      const { status: statusCode } = error.toJSON();
      if (statusCode === 403) {
        signout();
      } else if (statusCode === 500) {
        return Promise.reject({
          message: error.response.data.message,
        });
      }

      return Promise.reject(error);
    },
  );
};

const useDistributorApiClient = () => {
  const {
    user: { token },
    disconnect: signout,
  } = useAuth();

  return useCallback(
    (endpoint, config = {}) =>
      distributorApiClient(endpoint, { ...config, token, signout }),
    [token],
  );
};

export { distributorApiClient, useDistributorApiClient };
