import { VERIFICATION_STATUS } from '@tokensoft-web/common-utils';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import { HiDotsCircleHorizontal } from 'react-icons/hi';
import { PiArrowCircleUpRightFill } from 'react-icons/pi';

export const KycStatus = ({
  id = 'status-icon',
  status,
  size = 'sm',
  ...restProps
}) => {
  const SIZES = {
    xs: 16,
    sm: 20,
    md: 24,
    lg: 48,
    xl: 56,
  };
  const iconSize = SIZES[size];

  const key = id || 'status-icon';

  const renderIcon = () => {
    switch (status) {
      case VERIFICATION_STATUS.NONE:
      case VERIFICATION_STATUS.NEW:
      case false:
      default:
        return (
          <PiArrowCircleUpRightFill
            className={`status-icon-NEW`}
            data-testid={`${key}`}
            color='var(--info-light)'
            size={iconSize}
            {...restProps}
          />
        );

      case VERIFICATION_STATUS.PENDING:
        return (
          <HiDotsCircleHorizontal
            className={`status-icon-${VERIFICATION_STATUS.PENDING}`}
            data-testid={`${key}`}
            color='var(--warning)'
            size={iconSize}
            {...restProps}
          />
        );

      case VERIFICATION_STATUS.COMPLETED:
      case true:
        return (
          <AiFillCheckCircle
            className={`status-icon-${VERIFICATION_STATUS.COMPLETED}`}
            data-testid={`${key}`}
            color='var(--success)'
            size={iconSize}
            {...restProps}
          />
        );

      case VERIFICATION_STATUS.FAILED:
        return (
          <AiFillCloseCircle
            className={`status-icon-${VERIFICATION_STATUS.FAILED}`}
            data-testid={`${key}`}
            color='var(--danger)'
            size={iconSize}
            {...restProps}
          />
        );
    }
  };

  return (
    <div className='flex flex-row gap-2 capitalize items-center'>
      {renderIcon()}
      {(status
        ? VERIFICATION_STATUS[status.toUpperCase()] || status
        : 'Not Started'
      ).toLowerCase()}
    </div>
  );
};
