import { Col, Stretched, Text, Title } from '@tokensoft-web/common-ui';

const ConnextIntroHeaderText = () => {
  return (
    <Col gap={6} className={'intro-background'}>
      <Title>More Than a Bridge: Crosschain, Reimagined.</Title>
      <Stretched gap={10}>
        <Col>
          <Text>
            Connext is an open protocol that allows developers to build
            applications that go beyond single blockchains and interact with any
            smart contract on any chain. Users can access these Apps from
            everywhere and with any token, leveraging the security of the
            underlying chains themselves! 
          </Text>
          <br />
          <Text>
            An ecosystem of builders, researchers, and web3 enthusiasts, Connext
            builds a true internet of chains through public goods and open
            standards.
          </Text>
          <br />
          <Text>
            Leading projects like Metamask, Alchemix, Gelato, Superfluid,
            Keep3r, Gnosis, and more, choose Connext to build next-gen
            applications.
          </Text>
        </Col>
        <Col>
          <iframe
            width='530'
            height='300'
            src='https://www.youtube.com/embed/BKcilw9KK54'
          ></iframe>
        </Col>
      </Stretched>
    </Col>
  );
};

export default ConnextIntroHeaderText;
