import { PageHeader, PageSubtitle, PageTitle } from '@tokensoft-web/common-ui';
import { getTruncatedAddress, useAuth } from '@tokensoft-web/common-utils';

const ClaimIneligible = ({ style = null }) => {
  const { user } = useAuth();

  const renderEligibilityMessage = () => {
    if (user.walletAddresses?.length > 1) {
      return (
        <PageHeader>
          <PageTitle style={style}>
            This wallet is not eligible for this event.
          </PageTitle>
          <PageSubtitle style={style}>
            However, our records indicate that you've used multiple wallets with
            the Tokensoft platform.
          </PageSubtitle>
          <PageSubtitle style={style}>
            Please make sure to try each of the following wallets:
          </PageSubtitle>
          <ul className='list-disc pl-8' style={style}>
            {user.walletAddresses.map((userWalletAddress, i) => (
              <li className={'text-lg font-bold'} key={i}>
                {getTruncatedAddress(userWalletAddress.walletAddress)}
              </li>
            ))}
          </ul>
        </PageHeader>
      );
    }

    return (
      <PageHeader>
        <PageTitle style={style}>
          This wallet is not eligible for this event.
        </PageTitle>
        <PageSubtitle style={style}>
          Please try with a different wallet.
        </PageSubtitle>
      </PageHeader>
    );
  };

  return <>{renderEligibilityMessage()}</>;
};

export default ClaimIneligible;
