import { Card, InputGroup, MultiSelect } from '@tokensoft-web/common-ui';
import {
  AccountDetails,
  STATES_AND_TERRITORIES,
  countryList,
  getMaxFormationDate,
} from '@tokensoft-web/common-utils';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { validateEntityInformation } from '../../utils/form-validation';

type EntityInformationPanelProps = {
  accountDetails: AccountDetails;
  onChange: (AccountDetails) => void;
  onValidChange?: (boolean) => void;
};

export const EntityInformationPanel: FC<EntityInformationPanelProps> = ({
  accountDetails,
  onChange,
  onValidChange,
}) => {
  const countryOptions = useMemo(() => countryList().getData(), []);
  const stateOptions = useMemo(() => STATES_AND_TERRITORIES, []);

  const [selectedCountry, setSelectedCountry] = useState(
    accountDetails?.entityCountry
      ? countryOptions.find(
          (options) => options.value === accountDetails?.entityCountry,
        )
      : null,
  );
  const [selectedState, setSelectedState] = useState(
    accountDetails?.entityState
      ? stateOptions.find(
          (options) => options.value === accountDetails?.entityState,
        )
      : null,
  );
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    const valid = validateEntityInformation({ ...accountDetails });
    setFormValid(valid);
    onValidChange && onValidChange(valid);
  }, [accountDetails]);

  // Propagate the change upward by providing the new userDetails object.
  const handleChange = (name) => (e: ChangeEvent<HTMLInputElement>) => {
    onChange({ ...accountDetails, [name]: e.target.value });
  };

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    onChange({ ...accountDetails, ['entityCountry']: country.value });
  };

  const handleStateChange = (state) => {
    setSelectedState(state);
    onChange({ ...accountDetails, ['entityState']: state.value });
  };

  return (
    <div id='entity-information'>
      <Card title='Entity Information' subtitle='Tell us about your entity.'>
        <form>
          <div className='grid gap-6 grid-cols-1 xl:grid-cols-2'>
            <InputGroup
              value={accountDetails?.entityName}
              label='Entity name'
              name='entityName'
              required={true}
              onChange={handleChange('entityName')}
            />
            <InputGroup
              value={accountDetails?.entityDba}
              label='DBA name'
              name='entityDba'
              required={false}
              onChange={handleChange('entityDba')}
            />
          </div>
          <InputGroup
            label='Formation date'
            name='entityFormationDate'
            type='date'
            value={accountDetails?.entityFormationDate || ''}
            required={true}
            onChange={handleChange('entityFormationDate')}
            max={getMaxFormationDate()}
          />
          <InputGroup
            value={accountDetails?.entityTaxId}
            label='EIN or TIN'
            name='entityTaxId'
            required={true}
            onChange={handleChange('entityTaxId')}
          />
          <MultiSelect
            label='Country'
            options={countryOptions}
            value={selectedCountry}
            onChange={handleCountryChange}
            required
            isMulti={false}
          />
          <InputGroup
            label='Building / Street number'
            name='entityBuildingNumber'
            value={accountDetails?.entityBuildingNumber}
            required={true}
            onChange={handleChange('entityBuildingNumber')}
          />
          <div className='grid gap-6 grid-cols-1 xl:grid-cols-2'>
            <InputGroup
              label='Street line 1'
              name='entityStreetLineOne'
              value={accountDetails?.entityStreetLineOne}
              required={true}
              onChange={handleChange('entityStreetLineOne')}
            />
            <InputGroup
              label='Street line 2'
              name='entityStreetLineTwo'
              value={accountDetails?.entityStreetLineTwo}
              onChange={handleChange('entityStreetLineTwo')}
            />
          </div>
          <InputGroup
            label='City'
            name='entityCity'
            value={accountDetails?.entityCity}
            required={true}
            onChange={handleChange('entityCity')}
          />
          <div className='grid gap-6 grid-cols-1 xl:grid-cols-2 pb-8'>
            {selectedCountry?.value === 'US' ? (
              <MultiSelect
                label='State / Province / Region'
                options={stateOptions}
                value={selectedState}
                onChange={handleStateChange}
                required
                isMulti={false}
              />
            ) : (
              <InputGroup
                label='State / Province / Region'
                name='entityState'
                value={accountDetails?.entityState}
                required={true}
                onChange={handleChange('entityState')}
              />
            )}
            <InputGroup
              label='Zip / Postal code'
              name='entityZipCode'
              value={accountDetails?.entityZipCode}
              required={true}
              onChange={handleChange('entityZipCode')}
            />
          </div>
          <div className='flex justify-end pt-4'>
            {formValid ? (
              <p className='text-success'>Complete</p>
            ) : (
              <p className='text-primary-medium'>
                Required field missing or invalid
              </p>
            )}
          </div>
        </form>
      </Card>
    </div>
  );
};
