import { SALE_STATUS } from '@tokensoft-web/common-utils';
import { createContext, useContext } from 'react';
import { PAYMENT_STEP } from '../../utils/enums';
import { Receipt, Sale } from '../../utils/interface';

interface SaleContextProps {
  sale: Sale;
  refresh: Function;
  correctNetwork: boolean;
  status: SALE_STATUS;
  handleSaleCapMet: Function;
  hasQueue: boolean;
  step: PAYMENT_STEP;
  setStep: Function;
  receipt: Receipt;
  handleGetReceipt: Function;
  eligibilityData: any;
  delay: any;
  documentsToAcceptOrSign: any[];
  setReceipt: Function;
}

export const SaleContext = createContext<SaleContextProps>(undefined);
SaleContext.displayName = 'SaleContext';

export const useSale = (): SaleContextProps => {
  const context = useContext(SaleContext);

  if (!context) {
    throw new Error(
      'SaleProvider context is undefined, please verify you are calling useSale() as child of a <SaleProvider> component.',
    );
  }

  return context;
};
