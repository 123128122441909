import { EVENT_TYPE } from '@tokensoft-web/common-utils/src/enum/event-type';
import { boolean, mixed, object, string } from 'yup';

const basicInformationFormSchema = object({
  firstName: string().trim().required(),
  lastName: string().trim().required(),
  middleName: string().nullable().trim(),
  dob: string().required(),
  phoneNumber: string()
    .nullable()
    .matches(/^[0-9\-\(\)\.\+\s]*$/)
    .trim(),
  email: string().trim().email().required(),
  country: string().trim().required(),
  buildingNumber: string().trim().required(),
  streetLineOne: string().trim().required(),
  streetLineTwo: string().nullable().trim(),
  city: string().trim().required(),
  state: string().trim().required(),
  zipCode: string().trim().required(),
});

export const validateBasicInformation = (input: object) => {
  return basicInformationFormSchema.isValidSync(input || {});
};

const eventInformationFormSchema = object({
  name: string().trim().required(),
  description: string().trim().required(),
  startTime: string().trim().required(),
  endTime: string().trim().required(),
});

export const validateEventInformation = (input: object) => {
  return eventInformationFormSchema.isValidSync(input || {});
};

const entityInformationFormSchema = object({
  entityName: string().trim().required(),
  entityDba: string().nullable().trim(),
  entityFormationDate: string().required(),
  entityTaxId: string().trim().required(),
  entityCountry: string().trim().required(),
  entityBuildingNumber: string().trim().required(),
  entityStreetLineOne: string().trim().required(),
  entityStreetLineTwo: string().nullable().trim(),
  entityCity: string().trim().required(),
  entityState: string().trim().required(),
  entityZipCode: string().trim().required(),
});

export const validateEntityInformation = (input: object) => {
  return entityInformationFormSchema.isValidSync(input || {});
};

export const validateRequiredString = (input: string) => {
  const requiredString = string().trim().required();
  return requiredString.isValidSync(input);
};

export const validateEmail = (input: string) => {
  const email = string().trim().email().required();
  return email.isValidSync(input);
};

export const validatePhoneNumber = (input: string) => {
  const phoneNumber = string()
    .nullable()
    .matches(/^[0-9\-\(\)\.\+\s]*$/)
    .trim();
  return phoneNumber.isValidSync(input);
};

const projectOwnerFormSchema = object({
  firstName: string().trim().required(),
  lastName: string().trim().required(),
  email: string().trim().required().email(),
  phoneNumber: string()
    .nullable()
    .matches(/^[0-9\-\(\)\.\+\s]*$/)
    .trim(),
});

export const validateProjectOwnerInformation = (input: object) => {
  return projectOwnerFormSchema.isValidSync(input);
};

const projectDetailsFormSchema = object({
  name: string().trim().required(),
  tagline: string().trim().required(),
  description: string().trim().required(),
});

export const validateProjectDetailsFormSchema = (input: object) => {
  return projectDetailsFormSchema.isValidSync(input);
};
const identityEventDetailsFormSchema = object({
  contentHeaderTitle: string().trim().nullable(),
  contentHeaderSubtitle: string().trim().nullable(),
  contentHeaderFontColor: string()
    .trim()
    .matches(/^#([A-F0-9]{8}|[A-F0-9]{6})$/i)
    .required(),
  contentHeaderBgColor: string()
    .trim()
    .matches(/^#([A-F0-9]{8}|[A-F0-9]{6})$/i)
    .required(),
  bodyTitle: string().trim().nullable(),
  contentBodyUpcoming: string().trim().required(),
  contentBodyOpen: string().trim().required(),
  contentBodyClosed: string().trim().required(),
});

export const validateIdentityEventDetailsFormSchema = (input: object) => {
  return identityEventDetailsFormSchema.isValidSync(input);
};

const eventGeneralFormSchema = object({
  name: string().trim().required(),
  description: string().trim().required(),
  startTime: string().trim().required(),
  endTime: string().trim().nullable(),
});

export const validateEventGeneralFormSchema = (input: object) => {
  return eventGeneralFormSchema.isValidSync(input);
};

const projectWelcomeFormSchema = object({
  welcomeSubtitle: string().trim().required(),
  welcomeBgColor: string().trim().required(),
  welcomeFontColor: string().trim().required(),
  welcomeTitle: string().trim().required(),
});

export const validateProjectWelcomeFormSchemaFormSchema = (input: object) => {
  return projectWelcomeFormSchema.isValidSync(input);
};

const documentUploadInfoSchema = object({
  documentTitle: string().trim().required(),
  uploadedFile: mixed().required(),
  showDocumentTo: mixed().required(),
  signatureRequired: boolean(),
});

export const validateDocumentUploadinfo = (input: object) => {
  // Logs validation errors
  // try {
  //   documentUploadInfoSchema.validateSync(input);
  // } catch (e) {
  //   console.log("Validation error: ", e);
  // }

  return documentUploadInfoSchema.isValidSync(input);
};

const getDocumentSignatureSchema = (eventType: EVENT_TYPE) => {
  const isEventTypeSpecific = [
    EVENT_TYPE.RAISE,
    EVENT_TYPE.DISTRIBUTE,
  ].includes(eventType);

  return object({
    company: string().required(),
    companyName: string().required(),
    signerName: string().required(),
    signerTitle: string().required(),
    // Conditional validations
    recipient: isEventTypeSpecific ? string().required() : string().nullable(),
    addendum: isEventTypeSpecific ? string().required() : string().nullable(),
  });
};

export const validateDocumentSignatureInfo = (
  eventType: EVENT_TYPE,
  input: object,
) => {
  const documentSignatureSchema = getDocumentSignatureSchema(eventType);

  // Logs validation errors
  // try {
  //   documentSignatureSchema.validateSync(input);
  // } catch (e) {
  //   console.log("Validation error: ", e);
  // }

  return documentSignatureSchema.isValidSync(input);
};
