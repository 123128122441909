import {
  Alert,
  ButtonLink,
  Col,
  DownloadIcon,
  FileUploadButton,
  InputGroup,
  LoadingIndicator,
  Section,
  SectionTitle,
  Stacked,
  Stretched,
  Text,
  UploadIcon,
} from '@tokensoft-web/common-ui';
import {
  convertBaseUnitsToDecimal,
  formatValue,
  useToast,
  useValidateParticipants,
} from '@tokensoft-web/common-utils';
import { useEffect, useState } from 'react';
import { useProject } from '../../../contexts/project/project-context';

interface DistributorParticipantsProps {
  context: any;
  setContext: Function;
}

const DistributorParticipants = ({
  context,
  setContext,
}: DistributorParticipantsProps) => {
  const { project } = useProject();
  const { showErrorToast } = useToast();

  const [participantsFile, setParticipantsFile] = useState(
    context?.participantsFile || null,
  );
  const [totalParticipants, setTotalParticipants] = useState(
    context?.totalParticipants || 0,
  );
  const [totalAllocations, setTotalAllocations] = useState(
    context?.totalAllocations || 0,
  );
  const [validParticipantsFile, setValidParticipantsFile] = useState(
    context?.validParticipantsFile || false,
  );
  const [errorMessage, setErrorMessage] = useState(null);
  const [verificationPending, setVerificationPending] = useState(
    context?.verificationPending || false,
  );

  const { mutate: validateParticipants, isPending } = useValidateParticipants();

  const formValid = validParticipantsFile;

  const formattedTotalParticipants = formatValue(totalParticipants, {
    commas: true,
  });
  const formattedTotalAllocations = formatValue(
    convertBaseUnitsToDecimal(
      totalAllocations,
      context?.tokenInfo?.decimals || 18,
    ),
    { commas: true },
  );

  const handleUploadError = (message?: string) => {
    setParticipantsFile(null);
    setTotalParticipants(0);
    setTotalAllocations(0);
    setValidParticipantsFile(false);
    setErrorMessage(message);
    setVerificationPending(false);
  };

  const handleFileUploaded = (file) => {
    setParticipantsFile(file);
    setTotalParticipants(0);
    setTotalAllocations(0);
    setValidParticipantsFile(false);
    setErrorMessage(null);
    setVerificationPending(true);
  };

  useEffect(() => {
    setContext({
      ...context,
      participantsFile,
      totalParticipants,
      totalAllocations,
      validParticipantsFile,
      participantsFormValid: formValid && !isPending,
    });
  }, [verificationPending, participantsFile, formValid]);

  useEffect(() => {
    if (verificationPending) {
      // validate file
      validateParticipants(
        { uri: participantsFile.uri },
        {
          onSuccess: (result) => {
            if (result.valid) {
              setTotalParticipants(result.totalParticipants);
              setTotalAllocations(result.totalAmount);
              setValidParticipantsFile(true);
              setErrorMessage(null);
              setVerificationPending(false);
            } else {
              handleUploadError(result.errorMessage);
            }
          },
          onError: (error) => {
            showErrorToast({
              description: 'Error validating file.  Please try again.',
            });
            handleUploadError();
          },
        },
      );
    }
  }, [verificationPending]);

  return (
    <>
      <Stacked data-testid={'distributor-token-selection'}>
        <Section>
          <SectionTitle>Upload a participant list</SectionTitle>
          <Stretched xgap={5} ygap={5} place={'between'}>
            <Text width={'3/4'}>
              Your CSV file must include a column labeled{' '}
              <span className={'font-bold'}>address</span> and a column labeled{' '}
              <span className={'font-bold'}>amount</span> (as an integer in base
              units). You are limited to one file upload, any additional upload
              will override you previous files.
            </Text>

            <ButtonLink
              fill='outline'
              href={
                'https://s3.amazonaws.com/media.tokensoft.io/sample_participants.csv'
              }
              target={'blank'}
              data-testid={'download-template-button'}
            >
              <Text>Download Template</Text>
              <DownloadIcon />
            </ButtonLink>
          </Stretched>
        </Section>

        <Section>
          <Stretched xgap={2.5} ygap={0} place={'center'}>
            <InputGroup
              label='File Name *'
              name={'participantsFile'}
              value={participantsFile?.filename}
              placeholder={'Select a File'}
            />

            <FileUploadButton
              className={'w-full md:w-fit'}
              projectId={project.id}
              fileTypes={'.csv'}
              maxSize={10485760}
              disabled={isPending}
              data-testid={'upload-file-button'}
              onFileUploaded={(file) => {
                handleFileUploaded(file);
              }}
            >
              <UploadIcon />
              <Text>Upload File</Text>
            </FileUploadButton>
          </Stretched>
        </Section>

        {isPending ? (
          <Col place={'center'}>
            <LoadingIndicator
              text={'Verifying Uploaded File'}
              className={'no-padding'}
            />
          </Col>
        ) : null}

        {!isPending && validParticipantsFile ? (
          <Stretched place={'start'} gap={2.5}>
            <Section
              gap={2.5}
              width={'1/2'}
              place={'start'}
              data-testid={'participants-file-summary'}
            >
              <SectionTitle data-testid={'total-participants-title'}>
                Total Participants
              </SectionTitle>
              <Text data-testid={'total-participants-value'}>
                {formattedTotalParticipants} addresses
              </Text>
            </Section>

            <Section gap={2.5} width='fit' place={'start'}>
              <SectionTitle data-testid={'total-allocations-title'}>
                Total Distribution Amount
              </SectionTitle>
              <Text data-testid={'total-allocations-value'}>
                {formattedTotalAllocations} {context?.tokenInfo?.symbol}
              </Text>
            </Section>
          </Stretched>
        ) : null}

        {!isPending && !validParticipantsFile && errorMessage ? (
          <Alert
            data-testid={'invalid-participants-file'}
            type='alert-danger light'
          >
            {errorMessage}
          </Alert>
        ) : null}
      </Stacked>
    </>
  );
};

export default DistributorParticipants;
