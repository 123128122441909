import { useEffect, useState } from 'react';
import { useAnonymousAuthApiClient } from '../client/anonymous-auth-api-client';
import { useAsync } from '../hook/async';
import { projectThemeModified } from './project-service';

export const useGetConfiguration = () => {
  const client = useAnonymousAuthApiClient();
  const { run, data } = useAsync();
  const [configuration, setConfiguration] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(null);

  useEffect(() => {
    run(
      client(`configuration?cacheId=${window.location.hostname}`, {
        method: 'get',
      }),
    );
  }, [run, reload]);

  useEffect(() => {
    if (!data) {
      return;
    }
    if (data.project) {
      data.project.isProjectThemeModified = projectThemeModified(data.project);
    }

    setConfiguration(data);
    setLoading(false);
  }, [data]);

  const refresh = () => {
    setConfiguration(null);
    setLoading(true);
    setReload(new Date());
  };

  return { configuration: configuration, loading: loading, refresh: refresh };
};
