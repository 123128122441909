import classNames from 'classnames';
import { DangerAlertIcon } from '../icon/danger-alert-icon';
import { InfoAlertIcon } from '../icon/info-alert-icon';
import { SuccessAlertIcon } from '../icon/success-alert-icon';
import { WarningAlertIcon } from '../icon/warning-alert-icon';
import { Flex } from '../layout/container/flex';
import { Row } from '../layout/container/row';
import './alert.css';

export const Alert = ({
  type = 'alert-success',
  className = '',
  icon = null,
  children,
  ...restProps
}) => {
  return (
    <Row
      className={classNames(className || '', type || '', 'alert leading-none')}
      nowrap
      {...restProps}
    >
      {icon ? (
        <div>{icon}</div>
      ) : (
        <div>
          {type.includes('alert-info') && <InfoAlertIcon />}
          {type.includes('alert-danger') && <DangerAlertIcon />}
          {type.includes('alert-warning') && <WarningAlertIcon />}
          {type.includes('alert-success') && <SuccessAlertIcon />}
        </div>
      )}
      <Flex width='fit' textAlign='left' className='pl-2 font-semibold'>
        {children}
      </Flex>
    </Row>
  );
};
