import { Col, LoadingIndicator, Title } from '@tokensoft-web/common-ui';
import { Layout } from '@tokensoft-web/common-ui/src/components/ui/layout/layout';
import {
  buildDocumentNames,
  useConfiguration,
  useGetProjectPrivacyDocuments,
  viewComplianceDocumentVersion,
} from '@tokensoft-web/common-utils';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProjectPolicy = () => {
  const navigate = useNavigate();
  const { configuration } = useConfiguration();
  const { data: documentsData, isLoading } = useGetProjectPrivacyDocuments(
    configuration?.project?.id,
  );
  const documents = documentsData?.documents || [];

  const issuer = configuration?.project?.name;

  useEffect(() => {
    if (!isLoading && !documents?.length) {
      // Redirect to home page
      navigate('/');
    }
  }, [isLoading]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const documentNames = buildDocumentNames(documents);

  const handleViewDocument = (document) => {
    viewComplianceDocumentVersion(document);
  };

  return (
    <Layout fullWidth>
      <Title title={'Legal'} />

      <div className='max-w-6xl high-contrast mx-auto'>
        <div className='mt-12'>
          <span className='text-4xl font-bold'>Legal</span>
        </div>
        <div className='mt-6'>
          <span className='text-md'>
            Before registering with {issuer}, we ask that you review our{' '}
            {documentNames}. Links to the current version of each of these
            Policies are listed below, and we urge you to review each Policy in
            its entirety. Feel free to contact us with any questions.
          </span>
        </div>
        <Col className='mt-6' place='center'>
          {documents.map((document) => {
            return (
              <a
                className='high-contrast-link'
                onClick={() => handleViewDocument(document)}
                target='_blank'
                rel='noreferrer'
              >
                <div className={'flex flex-row items-center'}>
                  {document.documentVersion.document.name}
                </div>
              </a>
            );
          })}
        </Col>
      </div>
    </Layout>
  );
};

export default ProjectPolicy;
