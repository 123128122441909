import {
  BackButton,
  ButtonRow,
  PageSubtitle,
  PageTitle,
  SaveButton,
  Step,
  StepFooter,
  StepHeader,
} from '@tokensoft-web/common-ui';
import {
  allowedRegionsToCountryList,
  blockedRegionsFromCountryList,
  complianceTiertoKycRestriction,
  getWalletRequirementsFromFeatures,
  kycRestrictionToComplianceTier,
  useToast,
  useUpdateEvent,
} from '@tokensoft-web/common-utils';
import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wizard } from 'react-use-wizard';
import EventEligibilitySetup from '../../../components/admin/event/event-eligibility-setup';
import { useEvent } from '../../../contexts/event/event-context';
import {
  eventDocumentsToEditEventDocuments,
  formatCreateEventDocumentsPayload,
  getDocumentCategoryForEventType,
} from '../../../utils/document';

const WizardContext = createContext(null);

export const EditEventEligibilityWizard = () => {
  const navigate = useNavigate();
  const { event } = useEvent();
  const { showErrorToast, showSuccessToast } = useToast();

  const { mutate: update, isPending: updateLoading } = useUpdateEvent();

  const allowedCountryList = allowedRegionsToCountryList(event?.allowedRegions);
  const blockedCountryList = blockedRegionsFromCountryList(allowedCountryList);
  const walletRequirements = getWalletRequirementsFromFeatures(event.features);

  const [wizardState, setWizardState] = useState<any>({
    eligibilityFormValid: false,
    eventId: event?.id,
    projectId: event?.project?.id,
    startTime: event?.startTime,
    cliffTime: event?.startTime,
    endTime: event?.endTime,
    complianceTier: kycRestrictionToComplianceTier(event?.kycRestriction),
    walletRequirements: walletRequirements,
    allowedCountryList: allowedCountryList,
    blockedCountryList: blockedCountryList,
    eventType: event?.type,
  });

  const goBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    const allowedRegions = wizardState.allowedCountryList.map(
      (country) => country.value,
    );

    const editedableDocuments = eventDocumentsToEditEventDocuments(
      event?.documents,
    );
    const documentCategory = getDocumentCategoryForEventType(event?.type);
    const formatedEventDocuemntPayload = formatCreateEventDocumentsPayload(
      documentCategory,
      editedableDocuments,
      allowedRegions,
    );

    const payload = {
      id: event.id,
      ...event,
      documents: formatedEventDocuemntPayload,
      kycRestriction: complianceTiertoKycRestriction(
        wizardState?.complianceTier,
      ),
      allowedRegions,
      walletRequirements: wizardState.walletRequirements,
    };

    update(payload, {
      onSuccess: (data) => {
        showSuccessToast({ description: 'Successfully updated event.' });
        goBack();
      },
      onError: (error) => {
        showErrorToast({ description: error.message });
      },
    });
  };

  const handleCancel = () => {
    goBack();
  };

  return (
    <WizardContext.Provider
      value={{
        wizardState,
        setWizardState,
      }}
    >
      <Wizard>
        <Step>
          <StepHeader>
            <PageTitle>Eligibility</PageTitle>
            <PageSubtitle>
              Decide who can participate in your event. Gather the requirements
              from your compliance manual and set them here.
            </PageSubtitle>
          </StepHeader>

          <EventEligibilitySetup
            wizardState={wizardState}
            setWizardState={setWizardState}
          />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton
                label={'Cancel'}
                onClick={handleCancel}
                disabled={updateLoading}
              />
              <SaveButton
                disabled={!wizardState?.eligibilityFormValid || updateLoading}
                saving={updateLoading}
                onClick={handleSave}
              />
            </ButtonRow>
          </StepFooter>
        </Step>
      </Wizard>
    </WizardContext.Provider>
  );
};
