import {
  Col,
  Configuration,
  PageHeader,
  PageSubtitle,
  PageTitle,
} from '@tokensoft-web/common-ui';
import { Layout } from '@tokensoft-web/common-ui/src/components/ui/layout/layout';

const ErrorPage = () => {
  return (
    <>
      <Configuration />

      <div id='main-wrapper'>
        <Layout fullWidth>
          <Col place={'center'}>
            <PageHeader>
              <PageTitle>We're sorry!</PageTitle>
              <PageSubtitle>
                It looks like this isn't a valid domain. Please check the URL
                and try again.
              </PageSubtitle>
            </PageHeader>
          </Col>
        </Layout>
      </div>
    </>
  );
};

export default ErrorPage;
