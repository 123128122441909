import { createContext, useContext } from 'react';

interface ProjectContextProps {
  project: any;
  loading: boolean;
  view: string;
  setView: Function;
  hasApprovedComplianceDocs: boolean;
  isAuthorizedSigner: boolean;
  authorizedSigner: any;
  hasCompletedKyb: boolean;
  hasSetProjectDetails: boolean;
  projectSetupComplete: boolean;
}

export const ProjectContext = createContext<ProjectContextProps>(undefined);
ProjectContext.displayName = 'ProjectContext';

export const useProject = (): ProjectContextProps => {
  return useContext(ProjectContext);
};
