import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import {
  DATE_TIME_EDIT_FORMAT,
  DEFAULT_DATE_DISPLAY,
  DEFAULT_DATE_TIME_DISPLAY,
  DEFAULT_DATE_TIME_DISPLAY_WITH_SECONDS,
  DEFAULT_TIMEZONE,
  DEFAULT_TIME_DISPLAY,
  MAX_DATE,
  MIN_AGE_YEARS,
} from './constant';

import { LOCALES } from './locales';
import { TIMEZONES } from './timezones';

// day js extensions
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(relativeTime);

// identify the user's locale and try to load the appropriate dayjs config files
let locale =
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;
locale = locale.toLowerCase();
if (Object.keys(LOCALES).includes(locale)) {
  console.log('Using locale:', locale);
  const localeConfig = LOCALES[locale];
  localeConfig.loadLocale();
  dayjs.locale(locale);
}

const YYYY_MM_DD = 'YYYY-MM-DD';

export const getTimezoneAbbreviation = (
  timezone: string = DEFAULT_TIMEZONE,
) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  return TIMEZONES.find((tz) => tz.value === actualTimezone).abbr;
};

/********************** FORMATTING DATES FOR BACKEND  ***********************/

/**
 * Format a local date object to a UTC unix timestamp.
 * Used for transforming datetime-local input fields to on-chain timestamps
 */
export const localToUtcUnixDateTime = (
  d: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
  keepLocalTime?: boolean,
) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  const date = dayjs.tz(d, actualTimezone).utc(keepLocalTime);
  return date.isValid() ? date.unix() : null;
};

/**
 * Format a local date object to a UTC date/time string.
 * Used for transforming datetime-local input fields to api formatted strings
 */
export const localToUtcDateTime = (
  d: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  const date = dayjs.tz(d, actualTimezone).utc();
  return date.isValid() ? date.toISOString() : '';
};

/**
 * Format a utc date to a UTC unix timestamp.
 */
export const utcToUtcUnixDateTime = (
  d: string | Date | dayjs.Dayjs,
  format = DEFAULT_DATE_DISPLAY,
) => {
  const date = dayjs.tz(d, format, DEFAULT_TIMEZONE);
  return date.isValid() ? date.unix() : null;
};

/********************** FORMATTING DATES FOR DISPLAY  ***********************/

/**
 * Format a UTC date object so that it can be used in a datetime-local input field.
 * Used for transforming api formatted strings to datetime-local input formatted strings.
 */
export const utcToEditableDateTime = (
  d: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  const date = dayjs.utc(d).tz(actualTimezone);
  return date.isValid() ? date.format(DATE_TIME_EDIT_FORMAT) : '';
};

/**
 * Format a UTC date object as a local time string.
 * Used to display api formatted strings to local values.
 */
export const utcToLocalTime = (
  d: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
  format: string = DEFAULT_TIME_DISPLAY,
) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  const date = dayjs.utc(d).tz(actualTimezone);
  return date.isValid()
    ? date.format(format) + ' ' + getTimezoneAbbreviation(actualTimezone)
    : '-';
};

/**
 * Format a UTC date object as a local date string
 * Used to display api formatted strings to local values.
 */
export const utcToLocalDate = (
  d: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
  format: string = DEFAULT_DATE_DISPLAY,
) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  const date = dayjs.utc(d).tz(actualTimezone);
  return date.isValid() ? date.format(format) : '-';
};

export const utcToRelativeLocalDateTimeToDate = (
  d: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
  format: string = DEFAULT_DATE_DISPLAY,
) => {
  const date = dayjs().to(dayjs.utc(d));
  return date ? date : '-';
};

export const utcToRelativeLocalDateTimeFromDate = (
  d: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
  format: string = DEFAULT_DATE_DISPLAY,
) => {
  const date = dayjs().from(dayjs.utc(d));
  return date ? date : '-';
};

/**
 * Format a UTC date object as a local date/time string
 * Used to display api formatted strings to local values.
 */
export const utcToLocalDateTime = (
  d: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
  format: string = DEFAULT_DATE_TIME_DISPLAY,
  showTimezone = true,
) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  const date = dayjs.utc(d).tz(actualTimezone);
  return date.isValid()
    ? showTimezone
      ? date.format(format) + ' ' + getTimezoneAbbreviation(actualTimezone)
      : date.format(format)
    : '-';
};

export const unixToLocalDateTime = (
  date: string | number,
  timezone?: string,
): string => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  return utcToLocalDateTime(dayjs.unix(Number(date)), actualTimezone);
};

export const unixToLocalDate = (
  date: string | number,
  timezone?: string,
  format = DEFAULT_DATE_DISPLAY,
): string => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  return utcToLocalDate(dayjs.unix(Number(date)), actualTimezone, format);
};

export const utcUnixToUtcDate = (
  d: string | number,
  format = DEFAULT_DATE_DISPLAY,
) => {
  const date = dayjs.unix(Number(d)).utc();
  return date.isValid() ? date.format(format) : null;
};

export const unixToUTCString = (d: string | number) => {
  return dayjs.unix(Number(d)).utc().toISOString();
};

export const unixToEditableDateTime = (
  d: string | number,
  timezone?: string,
): string => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  return utcToEditableDateTime(dayjs.unix(Number(d)), actualTimezone);
};

export const editableToLocalDateTime = (
  d: string | number,
  timezone = DEFAULT_TIMEZONE,
  format = DEFAULT_DATE_TIME_DISPLAY,
) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  const date = dayjs(d);
  return date.isValid()
    ? date.format(format) + ' ' + getTimezoneAbbreviation(actualTimezone)
    : '-';
};

export const getDurationString = (delay) => {
  const remaining = dayjs.duration(delay.delayRemaining, 'second');
  let durationStrings = [];

  const years = remaining.years();
  years && durationStrings.push(`${years} year${years > 1 ? 's' : ''}`);

  const months = remaining.months();
  months && durationStrings.push(`${months} month${months > 1 ? 's' : ''}`);

  const days = remaining.days();
  days && durationStrings.push(`${days} day${days > 1 ? 's' : ''}`);

  const hours = remaining.hours();
  hours && durationStrings.push(`${hours} hour${hours > 1 ? 's' : ''}`);

  const minutes = remaining.minutes();
  minutes && durationStrings.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);

  const seconds = remaining.seconds();
  seconds && durationStrings.push(`${seconds} second${seconds > 1 ? 's' : ''}`);

  return durationStrings.splice(0, 3).join(', ');
};

export const getDurationObject = (time) => {
  const remaining = dayjs.duration(time, 'second');

  const years = remaining.years();
  const months = remaining.months();
  const days = remaining.days();
  const totalDays = Math.floor(remaining.asDays());
  const hours = remaining.hours();
  const minutes = remaining.minutes();
  const seconds = remaining.seconds();

  return {
    years,
    months,
    days,
    hours,
    minutes,
    seconds,
    totalDays,
  };
};

export const getLocalDateTimeFromDuration = (
  seconds: number,
  timezone: string = DEFAULT_TIMEZONE,
) => {
  const currentLocalTime = dayjs();
  const updatedLocalTime = currentLocalTime.add(seconds, 'second');
  return updatedLocalTime.format(DEFAULT_DATE_TIME_DISPLAY_WITH_SECONDS);
};

export const getRemainingFromUnix = (unixTime: number) => {
  const now = getUtcNowUnix();
  return now > unixTime ? 0 : unixTime - now;
};

/********************** DATE/TIME VALIDATION HELPER FUNCTIONS ***********************/

/**
 * Create a date/time string for limiting the start date input field.
 * Used by the create/edit sale flow
 */
export const getMinStartDate = (timezone: string = DEFAULT_TIMEZONE) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  return utcToEditableDateTime(getUtcNow().add(1, 'day'), actualTimezone);
};

/**
 * Create a date/time string for limiting the end date input field.
 * Used by the create/edit sale flow
 */
export const getMinEndDate = (
  startDate: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  return startDate
    ? startDate
    : utcToEditableDateTime(getUtcNow(), actualTimezone);
};

/**
 * Create a date/time string for limiting the start date input field.
 * Used by the create/edit sale flow
 */
export const getMaxStartDate = (
  endDate: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  return endDate ? endDate : getMaxEndDate(actualTimezone);
};

/**
 * Create a date/time string for limiting the end date input field.
 * Used by the create/edit sale flow
 */
export const getMaxEndDate = (timezone: string = DEFAULT_TIMEZONE) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  return utcToEditableDateTime(dayjs.utc(MAX_DATE), actualTimezone);
};

/**
 * Validate a start date.
 * Used by the create/edit sale flow
 */
export const validateStartDate = (
  start: string | Date | dayjs.Dayjs,
  end: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
): string | null => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  if (!start) {
    return null;
  }

  const maxDate = dayjs.utc(MAX_DATE).tz(actualTimezone);

  if (!isBefore(start, end)) {
    return 'Event must start before it ends';
  } else if (!isBefore(start, maxDate)) {
    return 'Event must start before Jan. 1, 2100';
  }

  return null;
};

/**
 * Validate an end date.
 * Used by the create/edit sale flow
 */
export const validateEndDate = (
  start: string | Date | dayjs.Dayjs,
  end: string | Date | dayjs.Dayjs,
  timezone: string = DEFAULT_TIMEZONE,
): string | null => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  if (!end) {
    return null;
  }

  const now = getLocalNow(actualTimezone);
  const maxDate = dayjs.utc(MAX_DATE).tz(actualTimezone);

  if (!isBefore(end, maxDate)) {
    return 'Event must end before Jan. 1, 2100';
  } else if (!isAfterOrSame(end, now)) {
    return 'Event cannot end in the past';
  } else if (!isAfterOrSame(end, start)) {
    return 'Event must end after it starts';
  }

  return null;
};

/**
 * Create a date string for limiting date of birth input fields
 */
export const getMaxDob = () => {
  return dayjs().subtract(MIN_AGE_YEARS, 'year').format(YYYY_MM_DD);
};

/**
 * Create a date string for limiting entity formation date input fields
 */
export const getMaxFormationDate = () => {
  return dayjs().format(YYYY_MM_DD);
};

/********************** DAYJS UTILITY FUNCTIONS ***********************/

export const isValidDate = (d: string | Date | dayjs.Dayjs) => {
  return dayjs(d).isValid();
};

// NOTE: This function can possibly adjust the time due to daylight savings boundaries.
// See addToUtcDate to add units to a date without timezone considerations.
export const addToDate = (
  d: string | Date | dayjs.Dayjs,
  length: number,
  units,
) => {
  if (!d) {
    return '';
  }

  const date = dayjs(d);
  return date.isValid() ? date.add(length, units) : '';
};

export const addToUtcDate = (
  d: string | Date | dayjs.Dayjs,
  length: number,
  units,
  format = DEFAULT_DATE_DISPLAY,
) => {
  if (!d) {
    return '';
  }

  const date = dayjs(d);
  return date.isValid() ? date.add(length, units).format(format) : '';
};

export const addToUtcUnixDateTime = (
  d: string | number,
  length: number,
  units,
) => {
  if (!d) {
    return '';
  }

  const date = dayjs.unix(Number(d)).utc();
  return date.isValid() ? date.add(length, units).unix() : '';
};

export const getDifference = (
  d1: string | Date | dayjs.Dayjs,
  d2: string | Date | dayjs.Dayjs,
  units: any = 'day',
  decimals: boolean = false,
) => {
  return dayjs(d2).diff(dayjs(d1), units, decimals);
};

export const subtractFromDate = (
  d: string | Date | dayjs.Dayjs,
  length: number,
  units: any = 'months',
) => {
  return dayjs(d).subtract(length, units);
};

// assumes date is in UTC
export const getDayOfMonth = (d: string | Date | dayjs.Dayjs) => {
  return dayjs.utc(d).get('D');
};

export const isBefore = (
  d: string | Date | dayjs.Dayjs,
  target: string | Date | dayjs.Dayjs,
) => {
  return target ? dayjs(d).isBefore(dayjs(target)) : true;
};

export const isBeforeOrSame = (
  d: string | Date | dayjs.Dayjs,
  target: string | Date | dayjs.Dayjs,
) => {
  return target ? dayjs(d).isSameOrBefore(dayjs(target)) : true;
};

export const isAfter = (
  d: string | Date | dayjs.Dayjs,
  target: string | Date | dayjs.Dayjs,
) => {
  return target ? dayjs(d).isAfter(dayjs(target)) : true;
};

export const isAfterOrSame = (
  d: string | Date | dayjs.Dayjs,
  target: string | Date | dayjs.Dayjs,
) => {
  return target ? dayjs(d).isSameOrAfter(dayjs(target)) : true;
};

export const isSame = (
  d1: string | Date | dayjs.Dayjs,
  d2: string | Date | dayjs.Dayjs,
) => {
  return dayjs(d1).isSame(dayjs(d2));
};

export const getUtcNow = () => {
  return dayjs.utc();
};

export const getLocalNow = (timezone: string = DEFAULT_TIMEZONE) => {
  let actualTimezone = timezone;
  if (!actualTimezone) {
    actualTimezone = DEFAULT_TIMEZONE;
  }

  return getUtcNow().tz(actualTimezone).format(DATE_TIME_EDIT_FORMAT);
};

export const getUtcNowUnix = () => {
  return getUtcNow().unix();
};

export const getYear = (): string => {
  return getUtcNow().format('YYYY');
};

export const isAfterNow = (d: string | Date | dayjs.Dayjs) => {
  return isAfter(dayjs.utc(d), getUtcNow());
};

export const formatUtcDate = (
  d: string | Date | dayjs.Dayjs,
  format: string = DEFAULT_DATE_DISPLAY,
) => {
  const date = dayjs.utc(d);
  return date.isValid() ? date.format(format) : '';
};
