import { PageHeader, PageSubtitle, PageTitle } from '@tokensoft-web/common-ui';

const RegionRestriction = ({ className = '', style = null }) => {
  return (
    <>
      <PageHeader className={className}>
        <PageTitle style={style}>We're sorry!</PageTitle>
        <PageSubtitle style={style}>
          This feature is not available in your region.
        </PageSubtitle>
      </PageHeader>
    </>
  );
};

export default RegionRestriction;
