import { useModal, useNetworks, useWagmi } from '@tokensoft-web/common-utils';
import { Col } from '../layout/container/col';
import { ModalTitle } from '../typography/modal-title';
import { NetworkSelect } from './network-select';

export const SmartContractNetworkSelect = () => {
  const { updateDefaultChain, defaultChain } = useWagmi();
  const { showModal, closeModal } = useModal();
  const { supportedNetworks } = useNetworks();

  const handleNetworkChange = (networkId) => {
    if (networkId === defaultChain?.id) {
      closeModal();
      return;
    }

    updateDefaultChain(networkId);
    closeModal();
  };

  const showNetworkModal = () => {
    showModal({
      content: (
        <Col place={'center'} className={'max-w-[350px] p-4'}>
          <ModalTitle>{'Select Network'}</ModalTitle>
          <NetworkSelect
            supportedNetworks={supportedNetworks}
            subtitle={
              'Please select the correct network before connecting your smart contract wallet.'
            }
            direction={'col'}
            size={'lg'}
            selected={defaultChain?.id}
            setSelected={handleNetworkChange}
          />
        </Col>
      ),
    });
  };

  return (
    <Col yalign={'center'}>
      <p className={'text-lg font-semibold high-contrast'}>
        Connecting with a smart contract?
      </p>
      <div className={'p-1'}>
        We will attempt to verify your smart contract using the{' '}
        <span className={'high-contrast font-semibold'}>
          {defaultChain?.name || 'Unknown'} network
        </span>
        . Click the button below if your smart contract is deployed on a
        different network.
      </div>
      <button
        className='btn btn-outline-primary btn-sm'
        data-bs-toggle='dropdown'
        onClick={() => showNetworkModal()}
      >
        Select Smart Contract Network
      </button>
    </Col>
  );
};
