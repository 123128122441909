import {
  Alert,
  Card,
  Col,
  NetworkIcon,
  Row,
  SectionTitle,
  Text,
} from '@tokensoft-web/common-ui';
import {
  convertBaseUnitsToDecimal,
  formatValue,
} from '@tokensoft-web/common-utils';

interface TokenInfoCardProps {
  tokenInfo: any;
  networkId: number;
  showSuccess: boolean;
}

const TokenInfoCard = ({
  tokenInfo,
  networkId,
  showSuccess,
}: TokenInfoCardProps) => {
  const formattedTotalSupply = formatValue(
    convertBaseUnitsToDecimal(
      tokenInfo?.totalSupply,
      tokenInfo?.decimals || 18,
      2,
    ),
    { commas: true },
  );
  const nameFormatted = tokenInfo?.name
    ? `${tokenInfo?.name} (${tokenInfo?.symbol})`
    : '';
  const totalSupplyFormatted = tokenInfo?.totalSupply
    ? `${formattedTotalSupply} ${tokenInfo?.symbol}`
    : '';
  const decimals = tokenInfo?.decimals || '';

  return (
    <Card id={'token-info-card'}>
      <Col gap={2.5}>
        <Row place={'between-center'} gap={5}>
          <SectionTitle data-testid={'token-info-card-title'}>
            {nameFormatted}
          </SectionTitle>
          <NetworkIcon
            data-testid={`token-info-card-icon`}
            chainId={networkId}
            logoSize={'sm'}
            width={'fit'}
            place={'end-center'}
          />
        </Row>

        <Row place={'between-center'} gap={5}>
          <Text data-testid={'token-info-token-supply'} breakAnywhere>
            Total Supply: {totalSupplyFormatted}
          </Text>
          <Text data-testid={'token-info-decimals'}>Decimals: {decimals}</Text>
        </Row>

        {showSuccess ? (
          <Alert data-testid='valid-token-address' type='alert-success light'>
            Valid Address
          </Alert>
        ) : null}
      </Col>
    </Card>
  );
};

export default TokenInfoCard;
