import { EventCountdown, Stacked } from '@tokensoft-web/common-ui';
import { useEvent } from '../../contexts/event/event-me-context';
import EventBodyWrapper from './event-body-wrapper';
import EventContent from './event-content';
import EventEligibility from './event-eligibility';
import EventHeader from './event-header';
import EventHeaderWrapper from './event-header-wrapper';

const EventUpcoming = () => {
  const { event, refresh } = useEvent();

  const description = event?.contentBodyUpcoming;
  const contentHeaderBgColor = event?.contentHeaderBgColor;
  const contentHeaderBgImage = event?.contentHeaderBgImage;
  const contentHeaderImage = event?.contentHeaderImage;
  const contentHeaderFontColor = event?.contentHeaderFontColor;
  const contentHeaderSubtitle = event?.contentHeaderSubtitle;
  const contentHeaderTitle = event?.contentHeaderTitle;
  const themeLayoutVersion = event?.themeLayoutVersion;

  return (
    <Stacked>
      <EventHeaderWrapper
        backgroundColor={contentHeaderBgColor}
        backgroundImg={contentHeaderBgImage}
      >
        <EventHeader
          headerTitle={contentHeaderTitle}
          headerSubtitle={contentHeaderSubtitle}
          contentHeaderImage={contentHeaderImage}
          contentHeaderFontColor={contentHeaderFontColor}
          themeLayoutVersion={themeLayoutVersion}
        />
      </EventHeaderWrapper>

      <EventBodyWrapper>
        <EventCountdown
          id={'event-upcoming-countdown-bottom'}
          startTime={event?.startTime}
          className={'block md:self-center'}
          showStatus={false}
        />

        <EventContent bodyContent={description} />

        <EventEligibility event={event} refreshEvent={refresh} />
      </EventBodyWrapper>
    </Stacked>
  );
};

export default EventUpcoming;
