import {
  ButtonRow,
  Card,
  CheckboxInput,
  InputGroup,
} from '@tokensoft-web/common-ui';
import {
  getTruncatedAddress,
  useAnalytics,
  useAuth,
  usePurchaseCompleted,
  useSignEventDocuments,
} from '@tokensoft-web/common-utils';
import { useEffect, useState } from 'react';
import EventDocuments from '../../components/event/event-documents';
import PurchaseButton from '../../components/purchase/purchase-button';
import PurchaseNativeButton from '../../components/purchase/purchase-native-button';
import { useSale } from '../../contexts/sale/sale-context';
import { PAYMENT_STEP } from '../../utils/enums';
import { Receipt } from '../../utils/interface';
import Signature from './signature';

const PurchaseConfirmStep = () => {
  const {
    user: { walletAddress },
  } = useAuth();
  const { sale, setStep, handleGetReceipt, receipt } = useSale();
  const { pageEvent } = useAnalytics();
  const [signatureValid, setSignatureValid] = useState(false);
  const [acceptedDocs, setAcceptedDocs] = useState(false);
  const [rightToCancel, setRightToCancel] = useState(false);
  const [allPurchasesFinal, setAllPurchasesFinal] = useState(false);

  const { mutate: purchaseCompleted } = usePurchaseCompleted();
  const { mutate: sign } = useSignEventDocuments();

  const validForm =
    signatureValid && acceptedDocs && allPurchasesFinal && rightToCancel;

  let documents = sale?.event?.documents
    ?.filter((doc) => doc.requiresSignature === true)
    .map((doc) => {
      return { eventDocumentVersion: doc };
    });

  useAnalytics(`/sale/${sale.id}/confirm`);

  useEffect(() => {
    if (documents.length === 0) {
      // If no documents are required to sign, set accepted docs to true
      setAcceptedDocs(true);
    }
  }, [documents]);

  const signDocuments = (mergedReceipt: Receipt) => {
    sign(
      {
        eventId: sale.event.id,
        documents: documents,
        transactionHash: mergedReceipt.receipt,
      },
      {
        onSuccess: () => {
          // do nothing
        },
        onError: (error) => {
          // do nothing, we don't want to prevent the user from completing their purchase.
        },
      },
    );
  };

  return (
    <>
      <Card title='Payment Confirmation' className='mt-8'>
        <p>
          Please make sure your purchase details are correct before making a
          payment.
        </p>

        <div
          className={'border border-neutral-light my-8 p-4 rounded vertical'}
        >
          <InputGroup
            label={`You are buying`}
            value={`${receipt.saleTokensPurchased} ${sale?.symbol}`}
            readonly
          />

          <InputGroup
            label={`Contribution amount`}
            value={`${receipt.tokensPaid} ${receipt.selectedPaymentMethod.label}`}
            readonly
          />

          <InputGroup
            label={`Receiving wallet`}
            value={getTruncatedAddress(walletAddress)}
            readonly
          />
        </div>

        <EventDocuments
          documents={documents}
          setAcceptedDocs={setAcceptedDocs}
          eventType={sale.event?.type}
          requiresSignature={true}
        />

        <div className='my-4'>
          <CheckboxInput
            onClick={() => setAllPurchasesFinal(!allPurchasesFinal)}
            name='allPurchasesFinal'
            checked={allPurchasesFinal}
          >
            {`I acknowledge and accept that all purchases from the Company (as defined in the purchase agreement) are final and there are no refunds or cancellations except as may be required by applicable law or regulations.`}
          </CheckboxInput>
        </div>

        <div className='my-4'>
          <CheckboxInput
            onClick={() => setRightToCancel(!rightToCancel)}
            name='rightToCancel'
            checked={rightToCancel}
          >
            {`I further acknowledge and accept that the Company (as defined in the purchase agreement) reserves the right to refuse or cancel these documents at any time at its sole discretion.`}
          </CheckboxInput>
        </div>

        <div className='mt-4 mb-8'>
          <Signature
            setSignatureValid={setSignatureValid}
            nameLabel={'Purchaser'}
          />
        </div>

        <ButtonRow place={'between'}>
          <button
            onClick={() => {
              setStep(PAYMENT_STEP.PURCHASE);
            }}
            className='btn btn-outline-dark'
          >
            Back
          </button>

          {receipt.selectedPaymentMethod.native ? (
            <PurchaseNativeButton
              disabled={!validForm}
              paymentMethod={receipt.selectedPaymentMethod}
              toAddress={sale.id}
              proof={sale.proof}
              value={receipt.tokensPaid}
              saleId={sale.id}
              getReceipt={(purchaseReceipt) => {
                pageEvent('altPurchase', 'buy');

                const receiptDetails = {
                  ...purchaseReceipt,
                  ...receipt,
                };
                purchaseCompleted(
                  {
                    receipt: receiptDetails,
                    sale,
                  },
                  {
                    onSuccess: () =>
                      console.log('Purchase completed send Success'),
                    onError: () => console.log('Purchase completed send Error'),
                  },
                );
                signDocuments(receiptDetails);
                handleGetReceipt(receiptDetails);
              }}
            />
          ) : (
            <PurchaseButton
              disabled={!validForm}
              paymentMethod={receipt.selectedPaymentMethod}
              toAddress={sale.id}
              proof={sale.proof}
              value={receipt.tokensPaid}
              saleId={sale.id}
              getReceipt={(purchaseReceipt) => {
                pageEvent('altPurchase', 'buy');

                const receiptDetails = {
                  ...purchaseReceipt,
                  ...receipt,
                };
                purchaseCompleted(
                  {
                    receipt: receiptDetails,
                    sale,
                  },
                  {
                    onSuccess: () =>
                      console.log('Purchase completed send Success'),
                    onError: () => console.log('Purchase completed send Error'),
                  },
                );
                signDocuments(receiptDetails);
                handleGetReceipt(receiptDetails);
              }}
            />
          )}
        </ButtonRow>
      </Card>
    </>
  );
};

export default PurchaseConfirmStep;
