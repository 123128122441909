import {
  EVENT_STATUS,
  getUtcNowUnix,
  localToUtcUnixDateTime,
  lte,
} from '@tokensoft-web/common-utils';
import { Row } from '../layout/container/row';

export const EventStatusBadge = ({ startTime, status, className }) => {
  return (
    <Row
      data-testid='event-status-badge'
      yalign={'center'}
      className={className}
    >
      {status === EVENT_STATUS.CURRENT ? (
        <span className='badge badge-success no-hover'>Live</span>
      ) : status === EVENT_STATUS.UPCOMING &&
        lte(localToUtcUnixDateTime(startTime), getUtcNowUnix()) ? (
        <span className='badge badge-success no-hover'>Live</span>
      ) : status === EVENT_STATUS.UPCOMING ? (
        <span className='badge badge-light no-hover'>Coming Soon</span>
      ) : status === EVENT_STATUS.COMPLETED ? (
        <span className='badge badge-dark no-hover'>Closed</span>
      ) : null}
    </Row>
  );
};
