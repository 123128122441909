export enum NETWORK_NAME {
  LOCALHOST = 'Localhost',
  ARBITRUM = 'Arbitrum',
  CELO = 'Celo',
  CELO_ALFAJORES = 'Alfajores',
  ETHEREUM = 'Ethereum',
  MOONBEAM = 'Moonbeam',
  MOONRIVER = 'Moonriver',
  OPTIMISM = 'Optimism',
  AVALANCHE = 'Avalanche',
  FUJI = 'Fuji',
  POLYGON = 'Polygon',
  POLKADOT = 'Polkadot',
  MUMBAI = 'Mumbai',
  BINANCE = 'Binance',
  BSC_TESTNET = 'BSC Testnet',
  GNOSIS = 'Gnosis',
  UNKNOWN = 'Unknown',
  SEPOLIA = 'Sepolia',
  BASE = 'Base',
  BASE_SEPOLIA = 'Base Sepolia',
  POLYGON_AMOY = 'Polygon Amoy',
  ZKSYNC = 'zkSync',
  ZKSYNC_SEPOLIA = 'zkSync Sepolia',
}
