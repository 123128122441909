export const DEFAULT_THEME_VERSION = 'light';
export const DEFAULT_TYPOGRAPHY = 'inter';
export const DEFAULT_CONTAINER_STYLE = 'wide';
export const DEFAULT_CONTENT_BG_COLOR = '#fff';
export const DEFAULT_PRIMARY_COLOR = '#0350d7';
export const DEFAULT_WELCOME_ALIGNMENT = 'center-left';
export const DEFAULT_WELCOME_BG_COLOR_LEGACY = '#f9fafb';
export const DEFAULT_WELCOME_BG_COLOR = '#0350d7';
export const DEFAULT_EVENT_HEADER_BG_COLOR = '#ffffff';
export const DEFAULT_EVENT_HEADER_FONT_COLOR = '#000000';

export const DEFAULT_PROJECT_HEADER_FONT_COLOR = '#8E9BB1';
export const DEFAULT_PROJECT_HEADER_BG_COLOR = '#fff';
export const DEFAULT_PROJECT_PRIMARY_COLOR = '#0350d7';
export const DEFAULT_PROJECT_SECONDARY_COLOR = '0350d7';
export const DEFAULT_PROJECT_SIDEBAR_BG_COLOR = '#F6F5FC';
export const DEFAULT_PROJECT_SIDEBAR_FONT_COLOR = '#8E9BB1';
export const DEFAULT_PROJECT_CONTENT_BG_COLOR = '#fff';
export const DEFAULT_PROJECT_CONTAINER_BG_COLOR = '#fff';
export const DEFAULT_PROJECT_WELCOME_BG_COLOR = '#0350d7';
export const DEFAULT_PROJECT_WELCOME_FONT_COLOR = '#fff';

export const BLOCKED_COUNTRIES = ['US'];

export const PUBLIC_MERKLE_ROOT =
  '0x0000000000000000000000000000000000000000000000000000000000000000';

export const PUBLIC_MERKLE_ROOT_IPFS =
  'ipfs://QmPP3e6Z7RCEkgeBef7JP8EbFFPhzT8J62MhzfrdE29cDa';

export const TEMP_MERKLE_ROOT =
  '0x0000000000000000000000000000000000000000000000000000000000000001';

export const GENESIS_ADDRESS = '0x0000000000000000000000000000000000000000';

export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000';

export const MIN_AGE_YEARS = 18;
export const MAX_DATE = '2099-12-31';
export const DEFAULT_TIMEZONE = 'Etc/UTC';
export const DEFAULT_DATE_DISPLAY = 'L';
export const DEFAULT_TIME_DISPLAY = 'LT';
export const DEFAULT_TIME_DISPLAY_WITH_SECONDS = 'LTS';
export const DEFAULT_DATE_TIME_DISPLAY = `${DEFAULT_DATE_DISPLAY} ${DEFAULT_TIME_DISPLAY}`;
export const DEFAULT_DATE_TIME_DISPLAY_WITH_SECONDS = `${DEFAULT_DATE_DISPLAY} ${DEFAULT_TIME_DISPLAY_WITH_SECONDS}`;
export const DATE_TIME_EDIT_FORMAT = 'YYYY-MM-DDTHH:mm';
export const DATE_TIME_ISO_FORMAT = 'YYYY-MM-DDTHH:mm.ss.SSSZ';

export const SKIP_TRADING_INTRO_SESSION_KEY = '__skip_trading_intro__';

export const STATES_AND_TERRITORIES = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VI', label: 'Virgin Islands' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

export const DEFAULT_BLOCKED_COUNTRIES = [
  {
    value: 'SY',
    label: 'Syria',
  },
  {
    value: 'KP',
    label: 'North Korea',
  },
  {
    value: 'SD',
    label: 'Sudan',
  },
  {
    value: 'CU',
    label: 'Cuba',
  },
  {
    value: 'UA-43',
    label: 'Crimea',
  },
  {
    value: 'IR',
    label: 'Iran',
  },
];

export const ETHEREUM_LOGO_URL =
  'https://s3.amazonaws.com/media.tokensoft.io/images/eth.png';
export const POLKADOT_LOGO_URL =
  'https://s3.amazonaws.com/media.tokensoft.io/images/polkadot.svg';
