import { Card, IconLogo, LoadingIndicator } from '@tokensoft-web/common-ui';
import { useAccount, useGetProjects } from '@tokensoft-web/common-utils';
import {
  getResourceUri,
  getValidIPFSHash,
} from '@tokensoft-web/common-utils/src/util/resource';
import classNames from 'classnames';
import { FC, useEffect, useMemo } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './projects.css';

interface ProjectProps {
  adminOnly: boolean;
}

const Projects: FC<ProjectProps> = ({ adminOnly }) => {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const {
    results: projects,
    loading: projectLoading,
    refresh: refreshProjects,
  } = useGetProjects(adminOnly);
  const { account, loading: accountLoading } = useAccount();
  const navigate = useNavigate();

  const refreshQueryParam = query?.get('refresh');

  useEffect(() => {
    if (refreshQueryParam) {
      refreshProjects();
    }
  }, [refreshQueryParam]);

  if (projectLoading || accountLoading) {
    return <LoadingIndicator text='Loading Projects' />;
  }

  return (
    <>
      {projects?.length > 0 && (
        <>
          <div className='grid gap-4 grid-cols-1'>
            {projects.map((project, i) => {
              const projectUrl = `/admin/project/${project.id}`;
              const projectLogo = getValidIPFSHash(project.theme.logoSmall)
                ? getResourceUri(project.theme.logoSmall)
                : project.theme.logoSmall;

              return (
                <div key={i}>
                  <Card className={'hover-card small-padding'}>
                    <div className={'grid grid-cols-8 gap-x-8 gap-y-4'}>
                      <div className='flex flex-col items-start col-span-5'>
                        <div className='flex space-x-2'>
                          <Link to={projectUrl}>
                            <IconLogo className='large' logo={projectLogo} />
                          </Link>
                          <div className={'flex flex-col self-center'}>
                            <Link
                              to={projectUrl}
                              className={classNames(
                                'font-semibold high-contrast-link no-underline',
                              )}
                            >
                              {project.name}
                            </Link>
                            <span className={'text-sm'}>{project.tagline}</span>
                          </div>
                        </div>
                        {project.description && (
                          <p
                            className={'pt-4 text-xs'}
                            dangerouslySetInnerHTML={{
                              __html: project.description,
                            }}
                          ></p>
                        )}
                      </div>

                      <div
                        className={'flex items-center justify-end col-span-3'}
                      >
                        {adminOnly && (
                          <button
                            className='btn btn-xs btn-primary'
                            onClick={() => navigate(projectUrl)}
                          >
                            <div className='flex flex-row items-center'>
                              <AiOutlineEdit />
                              <span className='pl-2'>Manage Project</span>
                            </div>
                          </button>
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
              );
            })}
          </div>
        </>
      )}

      {!projectLoading && projects?.length === 0 && (
        <div className='subtitle mb-4'>
          <p className='text-xs md:text-base'>
            {`There aren't any active projects right now. Please check back soon.`}
          </p>
        </div>
      )}
    </>
  );
};

export default Projects;
