import classNames from 'classnames';
import './event-layout.css';
import { Layout } from './layout';

interface EventLayoutProps {
  className?: string;
  children: React.ReactNode;
}

export const EventLayout = ({
  className,
  children,
  ...restProps
}: EventLayoutProps) => {
  return (
    <Layout
      fullWidth
      className={classNames('event-layout', className)}
      {...restProps}
    >
      {children}
    </Layout>
  );
};
