import { Col, Text, Title } from '@tokensoft-web/common-ui';

const ConnextDetailsClaimedHeaderText = () => {
  return (
    <Col gap={5} className='small-section-header'>
      <Title>Congratulations! You’ve successfully claimed your NEXT!</Title>
      <Col>
        <Text>What now?</Text>
        <ul className='text-neutral-500 list-disc'>
          <li className='ml-6'>
            <Text>
              Learn more about the{' '}
              <a href='https://dao-docs.connext.network/' target='_blank'>
                Connext DAO
              </a>
            </Text>
          </li>
          <li className='ml-6'>
            <Text>
              <a href='https://discord.com/invite/connext' target='_blank'>
                Join the Connext Discord
              </a>{' '}
              - DAO activities starting soon!
            </Text>
          </li>
          <li className='ml-6'>
            <Text>
              Explore the{' '}
              <a href='https://www.connext.network/ecosystem' target='_blank'>
                Connext Ecosystem
              </a>
            </Text>
          </li>
        </ul>
      </Col>
    </Col>
  );
};

export default ConnextDetailsClaimedHeaderText;
