import * as Sentry from '@sentry/react';
import {
  Col,
  Configuration,
  Error,
  Flex,
  LoadingIndicator,
  Modal,
  PageHeader,
  Toast,
} from '@tokensoft-web/common-ui';
import { Layout } from '@tokensoft-web/common-ui/src/components/ui/layout/layout';
import {
  useAuth,
  useConfiguration,
  useCreateReferral,
} from '@tokensoft-web/common-utils';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ConnectPage from '../pages/connect/connect';

const App = () => {
  const { loading, configuration } = useConfiguration();
  const { isAuthenticated } = useAuth();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const { mutate: createReferral } = useCreateReferral();
  const [referralCode, setReferralCode] = useState(undefined);

  useEffect(() => {
    if (query) {
      const referralCode = query.get('referral_code');
      if (referralCode) {
        setReferralCode(referralCode);
      }
    }
  }, [query]);

  useEffect(() => {
    if (isAuthenticated) {
      if (referralCode) {
        try {
          createReferral(referralCode);
        } catch (err) {
          console.log('Error saving referral code!', err);
        } finally {
          setReferralCode(undefined);
        }
      }
    }
  }, [isAuthenticated, referralCode]);

  const sentryBoundary = (child: ReactNode) => {
    if (process.env.REACT_APP_SENTRY_DSN) {
      return (
        // @ts-ignore
        <Sentry.ErrorBoundary
          fallback={(error) => {
            return <Error exception={error} />;
          }}
        >
          {child}
        </Sentry.ErrorBoundary>
      );
    }

    return <>{child}</>;
  };

  if (loading) {
    return (
      <>
        <Configuration />

        <div id='main-wrapper'>
          <Layout fullWidth>
            <Flex place={'center'} className='mt-12'>
              <LoadingIndicator text={'Loading'} />
            </Flex>
          </Layout>
        </div>
      </>
    );
  }

  if (configuration?.errors?.invalid_domain) {
    return (
      <>
        <Configuration />

        <div id='main-wrapper'>
          <Layout fullWidth>
            <Col place={'center'}>
              <PageHeader
                title={"We're sorry!"}
                instructions="It looks like this isn't a valid domain. Please check the URL and try again."
              />
            </Col>
          </Layout>
        </div>
      </>
    );
  }

  return (
    <>
      <Configuration />

      <div id='main-wrapper' className={`show`}>
        {isAuthenticated
          ? sentryBoundary(<Outlet />)
          : sentryBoundary(<ConnectPage />)}
        <Toast />
        <Modal />
      </div>
    </>
  );
};

export default App;
