import { Col, PageHeader, Section, Stretched } from '@tokensoft-web/common-ui';

import { EVENT_TYPE } from '@tokensoft-web/common-utils';
import { EventTypeCard } from '../../../components/admin/event/event-type-card';
import { useProject } from '../../../contexts/project/project-context';

const SelectEventType = ({
  setSelectedEventType,
  selectedEventType,
}: {
  setSelectedEventType: Function;
  selectedEventType: EVENT_TYPE;
}) => {
  const { project } = useProject();
  const eventTypesList = [
    {
      type: EVENT_TYPE.IDENTITY,
      name: 'Identity',
      description: 'Connect Identities & Wallets.',
    },
    {
      type: EVENT_TYPE.RAISE,
      name: 'Sale',
      description: 'Fundraise from the General Public.',
      helpText:
        'Anyone who meets your compliance requirements will be able to participate.',
    },
    {
      type: EVENT_TYPE.DISTRIBUTE,
      name: 'Distribute',
      description: 'Distribute Tokens with Time- or Price-Based Vesting.',
    },
  ];

  return (
    <Col>
      <PageHeader
        title={'Event Type'}
        subtitle='Please select the type of event you would like to set up and launch.'
      />

      <Section>
        <Stretched gap={5} place={'between-start'}>
          {eventTypesList.map((eventTypeListEntry, index) => (
            <EventTypeCard
              key={index}
              type={eventTypeListEntry.type}
              name={eventTypeListEntry.name}
              description={eventTypeListEntry.description}
              helpText={eventTypeListEntry.helpText}
              className={
                selectedEventType === eventTypeListEntry.type ? 'selected' : ''
              }
              setSelectedEventType={setSelectedEventType}
            />
          ))}
        </Stretched>
      </Section>
    </Col>
  );
};

export default SelectEventType;
