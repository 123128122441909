import { AnimatedCheckmark, Card } from '@tokensoft-web/common-ui';
import {
  add,
  convertBaseUnitsToDecimal,
  formatValue,
  getTruncatedAddress,
} from '@tokensoft-web/common-utils';
import classNames from 'classnames';
import { VscLinkExternal } from 'react-icons/vsc';

interface DelegateCardProps {
  delegate: any;
  delegateAddressSelected: string;
  action: Function;
  disabled?: boolean;
  tokenDecimals?: number;
}

const DelegateCard = ({
  delegate,
  delegateAddressSelected,
  disabled = false,
  action,
  tokenDecimals = 16,
}: DelegateCardProps) => {
  const delegateVotes = formatValue(
    add(
      convertBaseUnitsToDecimal(
        delegate.userContractMetadata?.votes,
        tokenDecimals,
        4,
      ),
      convertBaseUnitsToDecimal(
        delegate.userContractMetadata?.balance,
        tokenDecimals,
        4,
      ),
    ),
    { commas: true },
  );

  return (
    <Card
      className={classNames(
        `delegate-card hover-card`,
        delegateAddressSelected === delegate.walletAddress && 'selected ',
        disabled ? 'disabled' : '',
      )}
    >
      <div className='flex flex-col h-full'>
        <div className='flex items-center gap-4'>
          <div className='delegate-icon w-14 h-14 overflow-hidden relative flex justify-center items-center'>
            <img
              src={delegate.imageUri}
              className='w-full rounded-full'
              alt=''
            />
          </div>
          <div className='w-full'>
            <div className='font-bold text-lg flex items-center w-full'>
              <div className='grow'>
                {delegate.name
                  ? delegate.name
                  : getTruncatedAddress(delegate.walletAddress)}
              </div>
              <a
                target='_blank'
                href={`https://etherscan.io/address/${delegate.walletAddress}`}
              >
                <VscLinkExternal />
              </a>
            </div>
          </div>
        </div>
        <div className='text-sm py-2 text-neutral-500'>
          {delegate.description}
        </div>
        <div className='flex justify-between mt-auto pt-4'>
          <div></div>

          {delegateAddressSelected !== delegate.walletAddress ? (
            <button
              className='btn btn-outline-primary'
              onClick={() => action(delegate)}
              disabled={
                disabled || delegateAddressSelected === delegate.walletAddress
              }
            >
              <div className='flex flex-row justify-center items-center text-sm'>
                <div>Select</div>
              </div>
            </button>
          ) : (
            <div className='self-end'>
              <AnimatedCheckmark
                size={'40px'}
                color={`${disabled ? 'var(--neutral)' : 'var(--primary)'}`}
                className={''}
              />
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default DelegateCard;
