import {
  ButtonRow,
  PageHeader,
  PageSubtitle,
  PageTitle,
} from '@tokensoft-web/common-ui';
import { Link } from 'react-router-dom';

const WalletAddressRestriction = ({ className = '', style = null }) => {
  return (
    <>
      <PageHeader className={className}>
        <PageTitle style={style}>We're sorry!</PageTitle>
        <PageSubtitle style={style}>
          Unfortunately, your profile is currently restricted from viewing this
          feature.
        </PageSubtitle>
      </PageHeader>

      <ButtonRow place={'center'}>
        <Link className='btn btn-primary' to='/'>
          Dashboard
        </Link>
      </ButtonRow>
    </>
  );
};

export default WalletAddressRestriction;
