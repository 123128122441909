import { FaExclamationCircle } from 'react-icons/fa';
import { getConnextScanUrl } from '../../../utils/network';

const ConnextClaimProgressAlert = ({ txHash }: { txHash?: string }) => {
  if (!txHash) {
    return null;
  }

  return (
    <div className='flex justify-center'>
      <div className='success-claim-transfer-box flex flex-row'>
        <div className='mt-1'>
          <FaExclamationCircle color='var(--warning-500)' size='16px' />
        </div>
        <p className='ml-3 text-left text-sm'>
          It may take up to 30 minutes for your transaction to finish the
          bridging process to your destination chain. Please view the progress
          on the{' '}
          <a
            href={getConnextScanUrl(txHash)}
            target='_blank'
            className='primary-button'
          >
            Connext Explorer &rarr;
          </a>
        </p>
      </div>
    </div>
  );
};

export default ConnextClaimProgressAlert;
