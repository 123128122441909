import classNames from 'classnames';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useWizard } from 'react-use-wizard';
import { Button } from '../button/button';
import { FrontArrowIcon } from '../icon/front-arrow-icon';
import { Text } from '../typography/text';

export const SaveButton = ({
  disabled = false,
  label = 'Save',
  icon = <FrontArrowIcon />,
  saving = false,
  savingLabel = 'Saving...',
  className = '',
  onClick = null,
  ...restProps
}) => {
  const { nextStep, isLoading } = useWizard();

  return (
    <Button
      className={classNames('md:w-36 w-full', className)}
      onClick={(e) => (onClick ? onClick(e) : nextStep())}
      disabled={disabled || isLoading || saving}
      {...restProps}
    >
      {saving ? (
        <>
          <div className='animate-spin'>
            <AiOutlineLoading3Quarters size={24} />
          </div>
          <Text>{savingLabel}</Text>
        </>
      ) : (
        <>
          <Text>{label}</Text>
          {icon}
        </>
      )}
    </Button>
  );
};
