import { ButtonRow, PageHeader } from '@tokensoft-web/common-ui';
import { useAnalytics } from '@tokensoft-web/common-utils';
import { Link } from 'react-router-dom';

export const IdentityFailed = () => {
  useAnalytics('/account/identity-failed');

  return (
    <PageHeader
      title={"We're Sorry!"}
      instructions={
        <>
          <p className='text-xs md:text-base mb-8 text-center'>
            We could not approve your application.
          </p>

          <ButtonRow place={'center'}>
            <Link className='btn btn-primary' to='/account'>
              My Profile
            </Link>
            <Link className='btn btn-primary' to='/'>
              Dashboard
            </Link>
          </ButtonRow>
        </>
      }
    />
  );
};
