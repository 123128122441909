import { useNetworks, useWallet } from '@tokensoft-web/common-utils';
import { Button } from '../button/button';
import { ButtonRow } from '../form/button-row/button-row';
import { PageHeader } from '../layout/page-header/page-header';
import { PageSubtitle } from '../typography/page-subtitle';
import { PageTitle } from '../typography/page-title';

export const SwitchChainWarning = ({
  chainId,
  title,
  subtitle,
  style = null,
}: {
  chainId?: number;
  subtitle?: string;
  title?: string;
  style?: string;
}) => {
  const { switchChain } = useWallet();
  const { getNetworkDetails } = useNetworks();

  const network = getNetworkDetails(chainId);

  const titleText = title || 'Switch Network';
  const instructionText =
    subtitle || `Please connect to the ${network.name} network to continue`;

  if (!chainId) {
    return <div></div>;
  }

  return (
    <div>
      <PageHeader>
        <PageTitle style={style}>{titleText}</PageTitle>
        <PageSubtitle style={style}>{instructionText}</PageSubtitle>
      </PageHeader>

      <ButtonRow justify={'center'} className={''}>
        <Button color='primary' onClick={() => switchChain(chainId)}>
          Switch Now
        </Button>
      </ButtonRow>
    </div>
  );
};
