import { ButtonRow, PageHeader } from '@tokensoft-web/common-ui';
import {
  TIER_NAME,
  getKycTier,
  useAccount,
  useAnalytics,
  utcToLocalDate,
} from '@tokensoft-web/common-utils';
import { Link } from 'react-router-dom';

export const AccreditationCompleted = () => {
  useAnalytics('/account/accreditation-completed');
  const { account } = useAccount();

  const accreditation = getKycTier(
    account?.kyc?.tiers,
    TIER_NAME.ACCREDITATION,
  );
  const expirationDate = utcToLocalDate(accreditation.expiresAt);

  return (
    <PageHeader
      title={'Congratulations!'}
      instructions={
        <>
          <p className='text-xs md:text-base mb-8 text-center'>
            {`You are accredited. This accreditation expires ${
              expirationDate
                ? `on ${expirationDate}.`
                : '90 days from the date of approval.'
            }`}
          </p>

          <ButtonRow place={'center'}>
            <Link className='btn btn-primary' to='/account'>
              My Profile
            </Link>
            <Link className='btn btn-primary' to='/'>
              Dashboard
            </Link>
          </ButtonRow>
        </>
      }
    />
  );
};
