import { Card, PageHeader } from '@tokensoft-web/common-ui';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ProjectAuthorizedSigner } from '../../../components/admin/project/project-authorized-signer';
import { ProjectDetails } from '../../../components/admin/project/project-details';
import { ProjectDomains } from '../../../components/admin/project/project-domains';
import { ProjectEvents } from '../../../components/admin/project/project-events';
import { ProjectMembers } from '../../../components/admin/project/project-members';
import { ProjectQuicklinks } from '../../../components/admin/project/project-quicklinks';
import { ProjectReviewComplainceDocuments } from '../../../components/admin/project/project-review-compliance-documents';
import { ProjectSetup } from '../../../components/admin/project/project-setup';
import { useProject } from '../../../contexts/project/project-context';
import './project-dashboard.css';

const ProjectDashboardPage = () => {
  const { view, project, setView } = useProject();
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      setView(hash.replace('#', ''));
    }
  }, [hash]);

  const renderView = () => {
    switch (view) {
      case 'project-domains':
        return <ProjectDomains />;
      case 'project-members':
        return <ProjectMembers />;
      case 'project-compliance':
        return <ProjectReviewComplainceDocuments />;
      case 'project-authorized-signer':
        return <ProjectAuthorizedSigner />;
      default:
        return <ProjectDetails />;
    }
  };

  return (
    <>
      <PageHeader title={`${project?.name}`} />

      <div className={'grid grid-cols-3 gap-x-8 gap-y-8'}>
        <div className={'col-span-3 lg:col-span-2'}>{renderView()}</div>

        <div className={'col-span-3 lg:col-span-1'}>
          <Card className='no-padding'>
            <ProjectSetup />
            <ProjectQuicklinks />
          </Card>
        </div>

        <div className={'col-span-3'}>
          <ProjectEvents events={project.events} />
        </div>
      </div>
    </>
  );
};

export default ProjectDashboardPage;
